import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { FormField, OnboardingLayoutContent } from '@getro/rombo';

import { put } from 'api';
import { trackException } from 'helpers/errorTracker';

import OnboardingContext from 'components/layout/EmployerOnboarding/onboardingContext';
import { urlWithoutProtocolRegEx } from 'helpers/regExs';

const SOCIAL_NETWORKS = {
  linkedinUrl: {
    placeholder: 'http://www.linkedin.com/...',
    title: 'LinkedIn',
    schema: Yup.string().matches(urlWithoutProtocolRegEx, 'LinkedIn URL should be a valid URL'),
  },
  angellistUrl: {
    placeholder: 'https://www.angel.co/...',
    title: 'AngelList',
    schema: Yup.string().matches(urlWithoutProtocolRegEx, 'AngelList URL should be a valid URL'),
  },
  crunchbaseUrl: {
    placeholder: 'http://www.crunchbase.com/...',
    title: 'Crunchbase',
    schema: Yup.string().matches(urlWithoutProtocolRegEx, 'Crunchbase URL should be a valid URL'),
  },
  twitterUrl: {
    placeholder: 'https://www.twitter.co/...',
    title: 'Twitter',
    schema: Yup.string().matches(urlWithoutProtocolRegEx, 'Twitter URL should be a valid URL'),
  },
  instagramUrl: {
    placeholder: 'http://www.instagram.com/...',
    title: 'Instagram',
    schema: Yup.string().matches(urlWithoutProtocolRegEx, 'Instagram URL should be a valid URL'),
  },
  facebookUrl: {
    placeholder: 'https://www.facebook.com/...',
    title: 'Facebook',
    schema: Yup.string().matches(urlWithoutProtocolRegEx, 'Facebook URL should be a valid URL'),
  },
};

const SOCIAL_NETWORKS_KEYS = Object.keys(SOCIAL_NETWORKS);

const formSchema = () => {
  const obj = SOCIAL_NETWORKS_KEYS.reduce((acc, k) => ({ ...acc, [k]: SOCIAL_NETWORKS[k].schema }), {});
  return Yup.object().shape(obj);
};

const handleSubmit =
  (step, updateStep, org, net, updateOrganization) =>
  async (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    setStatus({ error: false });

    try {
      const response = await put(
        `/organizations/${org.id}`,
        {
          ...values,
        },
        { params: { collectionId: net.id } },
      );

      if (response && response.status === 200) {
        updateOrganization(response.data);
        updateStep(step + 1);
      }
    } catch (error) {
      trackException(error);
      setStatus({ error: true });
    }

    setSubmitting(false);
  };

const content = () => {
  const rows = [];

  for (let i = 0; i < SOCIAL_NETWORKS_KEYS.length; i += 2) {
    rows.push(
      <div key={i} className="EmployerOnboarding__row">
        <div className="EmployerOnboarding__cell">
          <Field
            type="website"
            name={SOCIAL_NETWORKS_KEYS[i]}
            label={SOCIAL_NETWORKS[SOCIAL_NETWORKS_KEYS[i]].title}
            placeholder={SOCIAL_NETWORKS[SOCIAL_NETWORKS_KEYS[i]].placeholder}
            component={FormField}
          />
        </div>

        <div className="EmployerOnboarding__cell">
          <Field
            type="website"
            name={SOCIAL_NETWORKS_KEYS[i + 1]}
            label={SOCIAL_NETWORKS[SOCIAL_NETWORKS_KEYS[i + 1]].title}
            placeholder={SOCIAL_NETWORKS[SOCIAL_NETWORKS_KEYS[i + 1]].placeholder}
            component={FormField}
          />
        </div>
      </div>,
    );
  }
  return rows;
};

const SocialProfiles = ({ onBack, title }) => {
  const { organization, network, updateOrganization, step, updateStep } = useContext(OnboardingContext);

  return (
    <Formik
      enableReinitialize
      initialValues={SOCIAL_NETWORKS_KEYS.reduce((acc, k) => ({ ...acc, [k]: organization[k] || '' }), {})}
      validationSchema={formSchema()}
      onSubmit={handleSubmit(step, updateStep, organization, network, updateOrganization)}
    >
      {({ isSubmitting }) => (
        <Form>
          <OnboardingLayoutContent title={title} onBack={onBack} nextStepSubmit loading={isSubmitting}>
            {content()}
          </OnboardingLayoutContent>
        </Form>
      )}
    </Formik>
  );
};

SocialProfiles.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.string.isRequired,
};

SocialProfiles.defaultProps = {
  onBack: () => {},
};

export default SocialProfiles;
