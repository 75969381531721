import React, { useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Flex, Box } from 'rebass/styled-components';

import { UploadImage, FormField, ProfilePicture } from '@getro/rombo';
import { getUserDisplayName } from 'helpers/users';
import { SavePanel } from '../../../components/molecules/savePanel';

export const ProfileDescription = forwardRef(
  ({ errorMessage, filestackApiKey, isLoading, onUserUpdate, userProfile, Picker, isUpdating }, ref) => {
    const formRef = useRef(null);

    const renderTitle = () => null;

    return (
      <Box ref={ref} sx={{ color: 'text.dark', pb: 4, mb: 2 }}>
        <SavePanel
          errorMessage={errorMessage}
          title={renderTitle()}
          isPanelLoading={isLoading}
          isButtonLoading={isUpdating}
          onClick={() => {
            if (formRef.current) {
              formRef.current.handleSubmit();
            }
          }}
        >
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={userProfile}
            onSubmit={(values) => {
              onUserUpdate(values);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Flex flexDirection="column">
                  <Box width={[1]} mb={[16]}>
                    <Flex flexDirection="row">
                      <ProfilePicture
                        width="64px"
                        imageUrl={values && values.avatarUrl}
                        name={getUserDisplayName(values)}
                      />
                      <Box
                        textAlign="left"
                        alignSelf="center"
                        px={[3]}
                        sx={{ '.UploadImage__button': { color: '#090925!important', fontSize: '14px' } }}
                      >
                        <UploadImage
                          Picker={Picker}
                          filestackApiKey={filestackApiKey}
                          imageType="profile"
                          size="sm"
                          onSuccess={({ filesFailed, filesUploaded }) => {
                            if (filesFailed.length < 1) {
                              setFieldValue('avatarUrl', filesUploaded[0].url);
                            }
                          }}
                          onClear={() => setFieldValue('avatarUrl', null)}
                          currentValue={values.avatarUrl}
                        />
                      </Box>
                    </Flex>
                  </Box>
                  <Box width={[1]}>
                    <Flex flexDirection={['column', 'row']}>
                      <Box width={[1, 1 / 2]} mr={[0, 2]} mb={[3]}>
                        <Field
                          type="text"
                          label="Name"
                          name="firstName"
                          placeholder="First name"
                          component={FormField}
                        />
                      </Box>
                      <Box width={[1, 1 / 2]} ml={[0, 2]} mb={[3]}>
                        <Field
                          label="Last name"
                          type="text"
                          className="ProfileMain__info-field"
                          name="lastName"
                          placeholder="Last name"
                          component={FormField}
                        />
                      </Box>
                    </Flex>
                    <Field
                      label="LinkedIn profile URL"
                      placeholder="https://linkedin.com/in/"
                      type="text"
                      name="linkedinUrl"
                      component={FormField}
                    />
                  </Box>
                </Flex>
                <input type="submit" hidden />
              </Form>
            )}
          </Formik>
        </SavePanel>
      </Box>
    );
  },
);

ProfileDescription.propTypes = {
  filestackApiKey: PropTypes.string.isRequired,
  onUserUpdate: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  userProfile: PropTypes.object,
  Picker: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

ProfileDescription.defaultProps = {
  isUpdating: false,
  isLoading: false,
  errorMessage: null,
  userProfile: {},
};
