import produce from 'immer';
import { handleActions } from 'redux-actions';

import { errorAllTopics, loadAllTopics, loadedAllTopics } from './actions';

export const initialState = {
  topics: [],
  error: false,
};

/* eslint-disable default-case, no-param-reassign */
const allTopicsReducer = handleActions(
  {
    [loadAllTopics]: produce(() => initialState),
    [loadedAllTopics]: produce((draft, { payload }) => {
      draft.topics = payload;
      return draft;
    }),

    [errorAllTopics]: produce((draft, action) => {
      draft.success = true;
      draft.error = true;
      draft.company = action.payload.company;
      return draft;
    }),
  },
  initialState,
);

export default allTopicsReducer;
