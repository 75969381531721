import React from 'react';
import PropTypes from 'prop-types';
import { FastField, Field } from 'formik';
import { Flex, Box } from 'rebass/styled-components';
import strings from 'strings';

import { FormInput, FormSelect } from '@getro/rombo';

import { CURRENCY_OPTIONS, PERIOD_OPTIONS } from '../constants';
import CurrencyOptionLabel from './CurrencyOptionLabel';

const Compensation = ({ values }) => (
  <Flex
    pl={[0, 2, 2]}
    flexDirection={['column', 'row']}
    sx={{
      '.ui.input input': {
        width: '100% !important',
      },
      "input[type='number']": {
        '-moz-appearance': 'textfield',
      },
      'input::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      'input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
      justifyContent: ['unset', 'space-around'],
      marginLeft: ['unset', '-8px'],
      marginRight: ['unset', '-8px'],
    }}
  >
    <Box pr={[0, 2]} mb={[2, 0]} flexBasis={['100%', '25%']}>
      <FastField
        className="Compensation__input"
        component={FormInput}
        label="From"
        size="medium"
        name="compensationMin"
        placeholder="Min"
        type="number"
        min="0"
      />
    </Box>
    <Box pr={[0, 2]} mb={[2, 0]} flexBasis={['100%', '25%']}>
      <Field
        className="Compensation__input"
        component={FormInput}
        label="To"
        name="compensationMax"
        placeholder="Max"
        type="number"
        min="0"
      />
    </Box>

    <Box
      sx={{
        '.Select__menu, .Select__menu > div': {
          width: '250px',
        },
        '.Select__menu > div': {
          '& > div:hover': {
            bg: 'neutral.50',
          },
        },
        '.Select__single-value': {
          '.currency-option-label': {
            pt: 0,
          },
          '.description': {
            display: 'none',
          },
        },
      }}
      pr={[0, 2]}
      mb={[2, 0]}
      flexBasis={['100%', '25%']}
    >
      <Field
        component={FormSelect}
        label="Currency"
        name="compensationCurrency"
        options={CURRENCY_OPTIONS}
        customFormatOptionLabel={CurrencyOptionLabel}
        value={values.compensationCurrency}
      />
    </Box>

    <Box pr={[0, 2]} flexBasis={['100%', '25%']}>
      <Field
        component={FormSelect}
        label="Per"
        name="compensationPeriod"
        options={PERIOD_OPTIONS}
        orientation="horizontal"
        placeholder={strings.jobs.compensationPeriod[values.compensationPeriod]}
        value={values.compensationPeriod}
      />
    </Box>
  </Flex>
);

Compensation.propTypes = {
  values: PropTypes.object.isRequired,
};

export default Compensation;
