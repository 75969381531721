import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Flex } from 'rebass/styled-components';

import { AsyncButton, FormField, Message, Button } from '@getro/rombo';

import strings from 'strings';

import { put } from 'api';
import { trackException } from 'helpers/errorTracker';
import helperGet from 'helpers/get';
import { trackEvent } from 'helpers/analytics';

const PasswordFormSchema = Yup.object().shape({
  currentPassword: Yup.string().min(8, strings.validation.passwordLength).required(),
  newPassword: Yup.string().min(8, strings.validation.passwordLength).required(),
});

const PasswordForm = () => {
  const [editingPassword, setEditingPassword] = useState(false);
  const cancelTokenSource = useRef(axios.CancelToken.source());

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    const params = {
      current_password: values.currentPassword,
      password: values.newPassword,
      password_confirmation: values.newPassword,
    };
    setSubmitting(true);
    setStatus({ error: null });
    try {
      await put('/users/me', params, { cancelToken: cancelTokenSource.current.token });
      setSubmitting(false);
      setEditingPassword(false);

      trackEvent('user_account:edit_password');
    } catch (error) {
      let errorMessage = strings.genericError;
      if (helperGet(error, 'response.data.currentPassword[0]') === 'is invalid') {
        errorMessage = 'Current password is incorrect.';
      }
      trackException(error);
      setStatus({ error: errorMessage });
      setSubmitting(false);
    }
  };

  useEffect(
    () => () => {
      cancelTokenSource.current.cancel();
    },
    [],
  );

  return (
    <>
      {!editingPassword && (
        <div>
          <span>******</span>
          <Button variant="underlineLink" onClick={() => setEditingPassword(true)}>
            Change password
          </Button>
        </div>
      )}
      {editingPassword && (
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
          }}
          validationSchema={PasswordFormSchema}
          onSubmit={handleSubmit}
        >
          {({ status, isSubmitting }) => (
            <Form>
              <Field type="password" name="currentPassword" label="Current password" component={FormField} />
              <Field type="password" name="newPassword" label="New password" component={FormField} />
              {status && status.error && (
                <Message type="error" className="PasswordForm__error-message" content={status.error} />
              )}

              <div className="PasswordForm__buttons">
                <Flex>
                  <Button variant="secondary" onClick={() => setEditingPassword(false)} mr={1}>
                    Cancel
                  </Button>
                  <AsyncButton variant="primary" type="submit" loading={isSubmitting} ml={1}>
                    Save
                  </AsyncButton>
                </Flex>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default PasswordForm;
