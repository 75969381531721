import { Message } from '@getro/rombo';
import React from 'react';
import PropTypes from 'prop-types';

export const ContactsImportBanner = ({ network, ...rest }) => {
  const { contactsImportInProgress = false, matchesBeingGenerated = false } = network;

  return contactsImportInProgress || matchesBeingGenerated ? (
    <Message {...rest}>We’re syncing your LinkedIn connections, this may take up to 3 hours.</Message>
  ) : (
    false
  );
};

ContactsImportBanner.propTypes = {
  network: PropTypes.object.isRequired,
};
