// eslint-disable-next-line import/extensions,import/no-unresolved
import { featureToggles } from '../featureToggles';

const useFeatureToggle = (feature) => {
  const featuresInEnv = featureToggles;
  if (!featuresInEnv) return false;

  return featuresInEnv[feature];
};

export default useFeatureToggle;
