import { call, put, select, takeLatest } from 'redux-saga/effects';
import { activeNetworkSelector } from '../../redux/selectors';
import { request } from '../../api';
import { errorJobRules, loadedJobRules, loadJobRules } from './actions';

function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

function* loadJobRulesEvent() {
  const activeNetwork = yield select(activeNetworkSelector);
  try {
    const results = yield call(request, {
      url: `/collections/${activeNetwork.id}/settings/jb_filters`,
      options: {},
    });
    const { jbJobTitlesFilterExclude, jbJobTitlesFilterInclude, jbLocationsFilterInclude } = results.data;

    // TODO: remove this as its a hack to fix RETO-70
    const caseFix = jbLocationsFilterInclude?.map((loc) => {
      const words = loc.split(', ');
      for (let i = 0; i < words.length; i += 1) {
        words[i] = titleCase(words[i]);
        if (words[i].length <= 3) {
          words[i] = words[i].toUpperCase();
        }
      }
      return words.join(', ');
    });

    yield put(
      loadedJobRules({
        titles: jbJobTitlesFilterInclude,
        locations: caseFix,
        keywords: jbJobTitlesFilterExclude,
      }),
    );
  } catch (error) {
    yield put(errorJobRules({ error }));
  }
}

export default function* jobRulesSaga() {
  yield takeLatest(loadJobRules().type, loadJobRulesEvent);
}
