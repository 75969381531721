import { createBrowserHistory } from 'history';
import routerLogger from 'helpers/routerLogger';

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

routerLogger(history);

export default history;
