import { createActions } from 'redux-actions';

export const { loadSaveInvitedMemberInfo, loadedSaveInvitedMemberInfo } = createActions(
  'LOAD_SAVE_INVITED_MEMBER_INFO',
  'LOADED_SAVE_INVITED_MEMBER_INFO',
);

export const { loadSaveInvitedMemberVouch, loadedSaveInvitedMemberVouch, errorSaveInvitedMemberVouch } = createActions(
  'LOAD_SAVE_INVITED_MEMBER_VOUCH',
  'LOADED_SAVE_INVITED_MEMBER_VOUCH',
  'ERROR_SAVE_INVITED_MEMBER_VOUCH',
);

export const { loadSaveInvitedMemberInvite, loadedSaveInvitedMemberInvite, errorSaveInvitedMemberInvite } =
  createActions(
    'LOAD_SAVE_INVITED_MEMBER_INVITE',
    'LOADED_SAVE_INVITED_MEMBER_INVITE',
    'ERROR_SAVE_INVITED_MEMBER_INVITE',
  );

export const { navigateBackInvitedMemberVouch, dismissSuccessInviteMember } = createActions(
  'NAVIGATE_BACK_INVITED_MEMBER_VOUCH',
  'DISMISS_SUCCESS_INVITE_MEMBER',
);
