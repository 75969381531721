import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Filter from 'components/molecules/Filter';
import { useAllOrganizations } from '../../../hooks/useAllOrganizations';
import { activeNetworkSelector, userSelector } from '../../../redux/selectors';

const CompanyFilter = ({ onChange, value, embeddedInModal, onDropDownStateChange, showLabel }) => {
  const currentNetwork = useSelector(activeNetworkSelector);
  const { id: activeNetworkId, isManager: isNetworkManager } = currentNetwork;
  const currentUser = useSelector(userSelector);
  const { managingOrganizations } = currentUser;
  const { allOrganizationsList } = useAllOrganizations();
  const options = useMemo(() => {
    let organizations;

    if (isNetworkManager) {
      organizations = allOrganizationsList.map(({ id, name }) => ({ value: id, label: name }));
    } else {
      organizations = managingOrganizations
        .filter(({ collectionIds }) => collectionIds.includes(activeNetworkId))
        .map(({ id, name }) => ({ value: id, label: name }));
    }

    return [{ label: 'Any', value: '' }, ...organizations];
  }, [allOrganizationsList, isNetworkManager, managingOrganizations, activeNetworkId]);

  const selectedCompany = useMemo(
    () => options.find((i) => i.value.toString() === value?.toString()),
    [options, value],
  );

  return (
    <Filter
      showLabel={showLabel}
      label="Company"
      name="company"
      value={selectedCompany}
      placeholder="Add companies"
      onChange={onChange}
      options={options}
      isSearchable
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

CompanyFilter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
  showLabel: PropTypes.bool,
};

CompanyFilter.defaultProps = {
  value: null,
  embeddedInModal: false,
  onDropDownStateChange: () => {},
  showLabel: true,
};

export default CompanyFilter;
