import * as Yup from 'yup';

export const useSocialNetwork = (company) => {
  const SOCIAL_NETWORKS = {
    linkedinUrl: {
      placeholder: 'http://www.linkedin.com/...',
      title: 'LinkedIn',
      schema: Yup.string().label('LinkedIn URL').url(),
      key: 'linkedin',
    },
    angellistUrl: {
      placeholder: 'https://www.angel.co/...',
      title: 'AngelList',
      schema: Yup.string().label('AngelList URL').url(),
      key: 'angellist',
    },
    crunchbaseUrl: {
      placeholder: 'http://www.crunchbase.com/...',
      title: 'Crunchbase',
      schema: Yup.string().label('Crunchbase URL').url(),
      key: 'crunchbase',
    },
    twitterUrl: {
      placeholder: 'https://www.twitter.co/...',
      title: 'Twitter',
      schema: Yup.string().label('Twitter URL').url(),
      key: 'twitter',
    },
    instagramUrl: {
      placeholder: 'http://www.instagram.com/...',
      title: 'Instagram',
      schema: Yup.string().label('Instagram URL').url(),
      key: 'instagram',
    },
    facebookUrl: {
      placeholder: 'https://www.facebook.com/...',
      title: 'Facebook',
      schema: Yup.string().label('Facebook URL').url(),
      key: 'facebook',
    },
  };

  const SOCIAL_NETWORKS_KEYS = Object.keys(SOCIAL_NETWORKS);

  const socials = SOCIAL_NETWORKS_KEYS.filter((key) => company[key]).reduce((acc, key) => {
    acc[SOCIAL_NETWORKS[key].key] = company[key];
    return acc;
  }, {});

  return {
    SOCIAL_NETWORKS,
    SOCIAL_NETWORKS_KEYS,
    socials,
  };
};
