import { call, takeLatest, put } from 'redux-saga/effects';
import { errorAllTopics, loadAllTopics, loadedAllTopics } from './actions';
import { trackException } from '../../helpers/errorTracker';
import { request } from '../../api';
import APIError from '../../api/APIError';

function* loadAllTopicsEvent({ payload: { networkId } }) {
  try {
    const requestUrl = `/collections/${networkId}/company_tags`;
    const { data } = yield call(request, {
      url: requestUrl,
    });

    yield put(loadedAllTopics(data.items));
  } catch (e) {
    yield put(errorAllTopics(e));
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }
  }
}

export default function* saga() {
  yield takeLatest(loadAllTopics().type, loadAllTopicsEvent);
}
