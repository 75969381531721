import { handleActions } from 'redux-actions';
import produce from 'immer';

import {
  loadAddRemoveMemberToList,
  loadedAddRemoveMemberToList,
  loadedMemberLocationOptions,
  loadedMembers,
  errorMembers,
  loadedMemberSkillOptions,
  loadedMemberVouchedByOptions,
  loadMembers,
  updateMemberById,
  loadedCreateAndAddMemberToList,
  errorCreateAndAddMemberToList,
  loadedCreateBoard,
  loadCreateBoard,
  errorCreateBoard,
  loadCreateAndAddMemberToList,
  createNewBoard,
  showBoardSettings,
  editBoard,
  setCurrentBoard,
  loadedBoardSettings,
  deleteBoard,
  loadedDeleteBoard,
  loadExportMembers,
  loadedExportMembers,
  errorExportMembers,
  loadMemberDetails,
  loadedMemberDetails,
  resetVouchMember,
  loadVouchMember,
  loadedVouchMember,
  errorVouchMember,
} from './actions';
import strings from '../../strings';

export const initialState = {
  skills: {
    options: [],
  },
  location: {
    options: [],
  },
  vouchedBy: {
    options: [],
  },
  vouchMember: {
    result: {},
    error: null,
  },
  members: [],
  memberDetails: {},
  total: null,
  totalPages: 0,
  talentGroups: [],
  createBoardError: null,
  errorFetchingMembers: false,
  isCreatingNewBoard: false,
  showBoardSettingsModal: false,
  showDeleteBoardModal: false,
  wasNewBoardCreated: false,
  currentBoard: null,
  wasBoardDeleted: false,
  exportedMembers: false,
  errorExportingMembers: false,
};

/* eslint-disable default-case, no-param-reassign */
const membersReducer = handleActions(
  {
    [loadedBoardSettings]: produce((draft, action) => {
      draft.createBoardError = null;
      draft.currentBoard = { ...draft.currentBoard, filters: { ...draft.currentBoard.filters, ...action.payload } };
      return draft;
    }),
    [setCurrentBoard]: produce((draft, action) => {
      draft.currentBoard = action.payload;
      return draft;
    }),
    [editBoard]: produce((draft) => {
      draft.createBoardError = null;
      draft.isCreatingNewBoard = false;
      draft.showBoardSettingsModal = true;
      return draft;
    }),
    [deleteBoard]: produce((draft, action) => {
      draft.showBoardSettingsModal = false;
      draft.showDeleteBoardModal = action.payload;
      return draft;
    }),
    [loadedDeleteBoard]: produce((draft) => {
      draft.showDeleteBoardModal = false;
      draft.wasBoardDeleted = true;
      return draft;
    }),
    [createNewBoard]: produce((draft, action) => {
      draft.createBoardError = null;
      draft.isCreatingNewBoard = action.payload;
      draft.currentBoard = { name: '', description: '' };
      return draft;
    }),
    [showBoardSettings]: produce((draft, action) => {
      draft.showBoardSettingsModal = action.payload;
      return draft;
    }),
    [loadedMemberSkillOptions]: produce((draft, action) => {
      draft.skills.options = action.payload.options;
      return draft;
    }),
    [loadedMemberLocationOptions]: produce((draft, action) => {
      draft.location.options = action.payload.options;
      return draft;
    }),
    [loadedMemberVouchedByOptions]: produce((draft, action) => {
      draft.vouchedBy.options = action.payload.options;
      return draft;
    }),
    [loadMembers]: produce((draft) => {
      draft.members = initialState.members;
      draft.isCreatingNewBoard = false;
      draft.errorFetchingMembers = initialState.errorFetchingMembers;
      draft.showBoardSettingsModal = false;
      draft.wasNewBoardCreated = false;
      draft.wasBoardDeleted = false;
      return draft;
    }),
    [loadedMembers]: produce((draft, action) => {
      const { payload } = action;
      const { members, total, totalPages } = payload;
      draft.members = members;
      draft.total = total;
      draft.totalPages = totalPages;
      return draft;
    }),
    [errorMembers]: produce((draft) => {
      draft.errorFetchingMembers = true;
      return draft;
    }),
    [loadAddRemoveMemberToList]: produce((draft, action) => {
      const { memberId, talentGroup, checked } = action.payload;
      const newTalentGroup = {
        id: talentGroup.value,
        name: talentGroup.label,
        description: talentGroup.description,
        color: talentGroup.color,
        featured: false,
        visibility: talentGroup.visibility,
        filters: talentGroup.filters,
        systemBoard: talentGroup.systemBoard,
        isLoading: true,
      };
      if (checked) {
        draft.members.map((member) => {
          if (member.id === memberId) {
            member.talentGroups = [...member.talentGroups, ...[newTalentGroup]];
          }
          return member;
        });
      } else {
        draft.members.map((member) => {
          if (member.id === memberId) {
            member.talentGroups.map((group) => {
              if (group.id === talentGroup.value) {
                Object.assign(group, { isLoading: true });
              }
              return group;
            });
          }
          return member;
        });
      }
      return draft;
    }),
    [loadedAddRemoveMemberToList]: produce((draft, action) => {
      const { memberId, checked, talentGroup } = action.payload;
      if (checked) {
        // add the new talentGroup to the user
        draft.members.map((member) => {
          if (member.id === memberId) {
            member.talentGroups.map((group) => {
              if (group.id === talentGroup.value) {
                Object.assign(group, { isLoading: false });
              }
              return group;
            });
          }
          member.createListErrorMessage = null;
          return member;
        });
      } else {
        // remove a group from the user
        draft.members.map((member) => {
          if (member.id === memberId) {
            member.talentGroups = member.talentGroups.filter((group) => group.id !== talentGroup.value);
          }
          member.createListErrorMessage = null;

          return member;
        });
      }
      return draft;
    }),
    // updateMemberById works by providing a memberId and a series or updated props. we find the member by the id provided
    // and using a deep merge combine there old properties with the new ones resulting in an updated member.
    [updateMemberById]: produce((draft, action) => {
      const { memberId, updatedProps } = action.payload;
      draft.members = draft.members.map((member) => {
        if (member.id === memberId) {
          Object.assign(member, updatedProps);
        }
        return member;
      });
      return draft;
    }),
    [loadCreateAndAddMemberToList]: produce((draft) => {
      draft.createBoardError = null;
      return draft;
    }),
    [loadedCreateAndAddMemberToList]: produce((draft, action) => {
      const { memberId, talentGroup } = action.payload;
      // add the new talentGroup to the user
      draft.members.map((member) => {
        const { talentGroups, id } = member;

        if (id === memberId) {
          const found = talentGroups.find((element) => element.id === talentGroup.id);
          if (!found) {
            member.talentGroups = [
              ...talentGroups,
              {
                ...talentGroup,
                isLoading: false,
                createListErrorMessage: null,
              },
            ];
          }
        }
        return member;
      });
      return draft;
    }),
    [errorCreateAndAddMemberToList]: produce((draft, action) => {
      const { error, memberId, name } = action.payload;
      const member = draft.members.find(({ id }) => id === memberId);

      if (error.response && error.response.status === 422) {
        member.createListErrorMessage = `The list "${name}" already exists.`;
      } else {
        member.createListErrorMessage = strings.genericError;
      }
      return draft;
    }),
    [loadCreateBoard]: produce((draft) => {
      draft.createBoardError = null;
      draft.talentGroups = initialState.talentGroups;
      return draft;
    }),
    [loadedCreateBoard]: produce((draft, action) => {
      const { board } = action.payload;
      draft.talentGroups.push(board);
      draft.showBoardSettingsModal = false;
      draft.wasNewBoardCreated = true;
      return draft;
    }),
    [errorCreateBoard]: produce((draft, action) => {
      draft.createBoardError = action.payload.error;
      return draft;
    }),
    [loadExportMembers]: produce((draft) => {
      draft.exportedMembers = false;
      draft.errorExportingMembers = false;
      return draft;
    }),
    [loadedExportMembers]: produce((draft) => {
      draft.exportedMembers = true;
      return draft;
    }),
    [errorExportMembers]: produce((draft) => {
      draft.errorExportingMembers = true;
      return draft;
    }),
    [loadMemberDetails]: produce((draft) => {
      draft.memberDetails = initialState.memberDetails;
      return draft;
    }),
    [loadedMemberDetails]: produce((draft, action) => {
      const { payload } = action;
      draft.memberDetails = payload;
      return draft;
    }),
    [resetVouchMember]: produce((draft) => {
      draft.vouchMember = { ...initialState.vouchMember };
      return draft;
    }),
    [loadVouchMember]: produce((draft) => {
      draft.vouchMember = {
        ...initialState.vouchMember,
      };
      return draft;
    }),
    [loadedVouchMember]: produce((draft, action) => {
      const { payload } = action;
      draft.vouchMember = {
        ...initialState.vouchMember,
        result: payload,
      };
      return draft;
    }),
    [errorVouchMember]: produce((draft, action) => {
      const { payload } = action;
      draft.vouchMember = {
        ...initialState.vouchMember,
        error: payload.error,
      };
      return draft;
    }),
  },
  initialState,
);

export default membersReducer;
