import React, { useMemo, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Search } from 'lucide-react';
import { Input } from '@rebass/forms/styled-components';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { FormLabel } from '@getro/rombo';

const KeywordFilter = ({ value, onChange, showLabel }) => {
  const [term, setTerm] = useState(value);

  const filterBySearchTerm = useMemo(() => debounce(300, onChange), [onChange]);

  return (
    <Flex flexDirection="column" display="flex" data-testid="Search Input" width="100%">
      {showLabel && <FormLabel>Keyword</FormLabel>}
      <Box color="neutral.300" display="flex" alignItems="center" sx={{ position: 'relative' }} width={1}>
        <Box
          variant="icon"
          sx={{ stroke: 'currentColor', position: 'absolute', left: 2 }}
          height="16px"
          width="16px"
          strokeWidth="1.5"
          aria-hidden="true"
          as={Search}
          mr={1}
        />
        <Input
          sx={{ borderColor: 'neutral.400' }}
          variant="search"
          aria-label="Search Input"
          value={term}
          type="text"
          name="keyword"
          placeholder="Search by name, description or location"
          onChange={(e) => {
            setTerm(e.target.value);
            filterBySearchTerm(e.target.value);
          }}
        />
      </Box>
    </Flex>
  );
};

KeywordFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
};

KeywordFilter.defaultProps = {
  value: '',
  showLabel: true,
};

export default KeywordFilter;
