import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import { useInjectSaga } from '../useInjectSaga';
import { useInjectReducer } from '../useInjectReducer';
import { key, topicsSelector } from './selectors';
import reducer from './reducer';
import saga from './sagas';
import { loadAllTopics } from './actions';

const useTopics = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const topics = useSelector(topicsSelector);
  const activeNetwork = useSelector(activeNetworkSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllTopics({ networkId: activeNetwork.id }));
  }, [activeNetwork, dispatch]);

  return {
    topics,
  };
};

export default useTopics;
