import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Message } from '@getro/rombo';
import { Box } from 'rebass/styled-components';

import { FiltersContext } from 'pages/Analytics/context';
import { METRICS } from 'pages/Analytics/filtersOptions';

function RowLimit({ data, fetching, onDownloadLink }) {
  const { filters } = useContext(FiltersContext);
  const metric = METRICS.find((m) => m.value === filters.metric.value);

  if (!metric.showRowLimit || fetching || data.length < 20) {
    return null;
  }

  return (
    <div className="RowLimit">
      <Message type="info">
        Only showing the first 20 results, you can{' '}
        <Box
          as="span"
          color="text.dark"
          fontSize="inherit"
          px={0}
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          fontWeight="body"
          onClick={onDownloadLink}
        >
          download the full report
        </Box>{' '}
        to see all the results.
      </Message>
    </div>
  );
}

RowLimit.propTypes = {
  data: PropTypes.array,
  fetching: PropTypes.bool,
  onDownloadLink: PropTypes.func,
};

RowLimit.defaultProps = {
  data: [],
  fetching: false,
  onDownloadLink: () => {},
};

export default RowLimit;
