import React from 'react';
import { Flex, Image, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import List from '../../../assets/icons-list.svg';
import strings from '../../../strings';

const EmptyView = ({ error }) =>
  !error ? (
    <Flex mt="72px" alignItems="center" width="100%" justifyContent="center">
      <Flex alignItems="center" flexDirection="column" maxWidth="360px">
        <Image width="64px" src={List} />
        <Text fontSize="16px" lineHeight="1.4" color="text.main" as="h1">
          You&lsquo;re up to date
        </Text>
        <Text color="text.subtle" mt="8px" fontSize="16px" textAlign="center" as="p">
          New companies in your portfolio will be automatically added here.
        </Text>
      </Flex>
    </Flex>
  ) : (
    <Flex width={1} justifyContent="center" mt={5}>
      <Text>{strings.genericError}</Text>
    </Flex>
  );

export default EmptyView;

EmptyView.propTypes = {
  error: PropTypes.bool,
};

EmptyView.defaultProps = {
  error: false,
};
