import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { useCompanyQuery } from 'services/companiesV2';
import { companySlugParser } from 'helpers/companySlugParser';
import { activeNetworkSelector } from '../../redux/selectors';
import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import MainLayout from '../../components/layout/Main';
import { JobEditor } from '../../components/organisms/jobEditor';
import usePageHook from '../../hooks/usePageHook';

const Job = () => {
  const params = useParams();
  const { companyId, action } = params;
  const title = useMemo(() => {
    if (action === 'edit-job') {
      return 'Edit job';
    }
    if (action === 'duplicate-job') {
      return 'Duplicate job';
    }

    return 'Post a job';
  }, [action]);

  usePageHook(title);

  const history = useHistory();
  const activeNetwork = useSelector(activeNetworkSelector);
  const backPathname = `/networks/${activeNetwork.slug}/companies/${companyId}/jobs`;

  const [parsedCompanyId] = useMemo(() => companySlugParser(companyId), [companyId]);
  const { data: company, isFetching } = useCompanyQuery({
    companyId: parsedCompanyId,
  });

  const goBackToCompanyProfile = useCallback(() => {
    history.push({
      pathname: backPathname,
    });
  }, [backPathname, history]);

  return (
    <MainLayout>
      <MainLayoutHeader
        title={title}
        breadcrumbs={[
          {
            label: isFetching || !company ? 'Loading...' : `${company.name} Jobs`,
            to: backPathname,
          },
        ]}
      />
      <Box pt="24px" px="16px">
        <JobEditor onCancelClick={goBackToCompanyProfile} action={action} analyticsPage="employer_profile" />
      </Box>
    </MainLayout>
  );
};

export default Job;
