import React from 'react';
import PropTypes from 'prop-types';
import { VouchesList, Box, Loader } from '@getro/rombo';

const VouchesReceived = ({
  blankContent,
  relationshipLevelOptions,
  scoreOptions,
  getUserDisplayName,
  fetching,
  user,
}) => (
  <Box
    title="Vouches received"
    helpPopoverTitle="Vouches received"
    helpPopoverText="These are the people in your corner who recognize your professional strengths and potential enough to put their name behind you. When you are connected to other members in your network, they will see who vouched for you and how they know you. Queue a fantastic first impression!"
  >
    {fetching && <Loader />}
    {!fetching && user && (
      <VouchesList
        blankContent={blankContent}
        relationshipLevelOptions={relationshipLevelOptions}
        scoreOptions={scoreOptions}
        getUserDisplayName={getUserDisplayName}
        vouches={user.referralsReceived}
      />
    )}
  </Box>
);

VouchesReceived.propTypes = {
  blankContent: PropTypes.string,
  relationshipLevelOptions: PropTypes.object.isRequired,
  scoreOptions: PropTypes.object.isRequired,
  getUserDisplayName: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  user: PropTypes.object,
};

VouchesReceived.defaultProps = {
  blankContent: 'Nothing to show.',
  fetching: false,
  user: null,
};

export default VouchesReceived;
