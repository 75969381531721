import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@getro/rombo';
import permissions from './permissions';

const PermissionsSelector = ({ name, placeholder, value, onChange }) => (
  <Select name={name} options={permissions} value={value} onChange={onChange} placeholder={placeholder} anchorToBody />
);

PermissionsSelector.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

PermissionsSelector.defaultProps = {
  value: null,
  placeholder: '',
};

export default PermissionsSelector;
