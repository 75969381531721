import { useEffect, useRef } from 'react';

export const useComponentWillUnmount = (callback) => {
  const willUnmount = useRef(false);

  useEffect(
    () => () => {
      willUnmount.current = true;
    },
    [],
  );

  useEffect(
    () => () => {
      if (willUnmount.current) {
        callback();
      }
    },
    [callback],
  );
};
