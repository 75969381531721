import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const ContactsProgressCount = ({ contactsCount, limit, progressText }) => {
  const completion = (contactsCount / limit) * 100;
  let barBg = 'purple.300';

  if (completion > 90) {
    barBg = 'icon.amber';
  } else if (completion >= 100) {
    barBg = 'red.600';
  }

  return (
    <Box width="100%">
      <Flex pb="8px" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text pr="4px" fontSize="14px" color={contactsCount >= limit ? 'red.600' : 'text.main'}>
            {contactsCount.toLocaleString('en-US')}
          </Text>
          <Text fontSize="14px">{`/ ${limit.toLocaleString('en-US')} ${progressText}`}</Text>
        </Flex>
        {contactsCount > limit && (
          <Text fontSize="14px" fontWeight="medium" color="text.error">{`${(contactsCount - limit).toLocaleString(
            'en-US',
          )} exceeded`}</Text>
        )}
      </Flex>
      <Box sx={{ position: 'relative' }}>
        <Box width="100%" height="8px" bg="neutral.100" sx={{ borderRadius: '9px' }} />
        <Flex
          width="100%"
          sx={{ borderRadius: '9px', position: 'absolute', top: 0, left: 0, zIndex: 2, overflow: 'hidden' }}
        >
          {contactsCount > limit ? (
            <>
              <Box height="8px" bg="red.600" flexGrow="1" mr="2px" />
              <Box width={`${100 - (limit / contactsCount) * 100}%`} height="8px" bg="red.200" />
            </>
          ) : (
            <Box width={`${Math.min(completion, 100)}%`} height="8px" bg={barBg} />
          )}
        </Flex>
      </Box>
    </Box>
  );
};

ContactsProgressCount.propTypes = {
  contactsCount: PropTypes.number,
  limit: PropTypes.number,
  progressText: PropTypes.string,
};

ContactsProgressCount.defaultProps = {
  contactsCount: 0,
  limit: 2000,
  progressText: 'contacts used',
};
