import { createApi } from '@reduxjs/toolkit/query/react';

import { get } from 'api';
import { networkBaseQuery } from './queries';

export const skillsApi = createApi({
  reducerPath: 'skillsApi',
  baseQuery: networkBaseQuery,
  tagTypes: ['skills'],
  endpoints: (builder) => ({
    getSkills: builder.query({
      queryFn: async ({ query }) => {
        try {
          const { data } = await get('/skills', {
            params: {
              q: query,
            },
          });

          return { data };
        } catch (axiosError) {
          const err = axiosError;
          return {
            error: {
              status: err.response?.status,
              data: err.response?.data || err.message,
            },
          };
        }
      },
      providesTags: ['skills'],
    }),
  }),
});

export const { useGetSkillsQuery, useLazyGetSkillsQuery } = skillsApi;
