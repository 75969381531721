import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import { Message, MessageBlock, Select } from '@getro/rombo';
import { useField } from 'formik';
import OrganizationSchema from '../../../schemas/organization';
import strings from '../../../strings';
import { EXPIRATION_OPTIONS } from './constants';

const RenewJob = ({ title, company, error, succeed, onClose, ...props }) => {
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  const { name: companyName } = company;

  const expiresIn = useMemo(() => parseInt(value, 10), [value]);
  const successMessage = useMemo(() => strings.expirationDate[expiresIn], [expiresIn]);
  const selectedOption = useMemo(() => EXPIRATION_OPTIONS.find((option) => option.value === value), [value]);

  return (
    <Flex flexDirection="column">
      {!succeed && (
        <Box data-testid="expiration-date-selector">
          <Flex mb={3} fontSize={2} color="text.main" width={1}>
            <Text>
              Set a new expiration date for{' '}
              <Text as="b" fontWeight="semibold">
                {title}
              </Text>{' '}
              at{' '}
              <Text as="b" fontWeight="semibold">
                {companyName}
              </Text>
            </Text>
          </Flex>
          <Text my={2} fontSize={2} color="text.dark" fontWeight="medium">
            New expiration date
          </Text>
          <Text color="text.subtle" fontSize={1} mb={2}>
            Expiration dates help you keep your job board fresh.{' '}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://help.getro.com/support/solutions/articles/65000168382-post-a-job-from-the-admin-portal"
            >
              Learn more
            </Link>
            .
          </Text>
          <Select
            {...props}
            value={selectedOption}
            onChange={({ value: selectedValue }) => setValue(selectedValue)}
            options={EXPIRATION_OPTIONS}
            anchorToBody
          />
          {error && (
            <Message type="error" data-testid="renew-job-error-message">
              Please try again or contact us at <a href="mailto:help@getro.com">help@getro.com</a>.
            </Message>
          )}
        </Box>
      )}
      {succeed && (
        <MessageBlock
          type="success"
          buttonText="Ok"
          onClick={onClose}
          title={successMessage}
          data-testid="renew-job-success-message"
        >
          <Text fontSize="2" color="text.main" data-testid="renew-job-expiration-warning">
            {expiresIn > 0 && <>We will email you before it expires.</>}
            {expiresIn === 0 && <>We will email you occasionally to review it.</>}
          </Text>
        </MessageBlock>
      )}
    </Flex>
  );
};

RenewJob.propTypes = {
  company: OrganizationSchema.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.bool,
  succeed: PropTypes.bool,
};

RenewJob.defaultProps = {
  error: false,
  succeed: false,
};

export default RenewJob;
