import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { Message } from '@getro/rombo';
import NetworkSchema from '../../../../schemas/network';

const JobRulesSection = ({ network, onShowJobRulesModal }) => {
  const { name, hasJbFilters } = network;

  if (!hasJbFilters) return null;

  return (
    <Box as={Message} mb={4} p="16px">
      {name} is using job rules. Jobs not matching those rules will not be shown on the job board.{' '}
      <Box sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onShowJobRulesModal} as="span">
        See job rules in use
      </Box>
    </Box>
  );
};

JobRulesSection.propTypes = {
  network: NetworkSchema.isRequired,
  onShowJobRulesModal: PropTypes.func.isRequired,
};

export default JobRulesSection;
