import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { Flex, Text } from 'rebass/styled-components';

import { FormInput, Message, AsyncButton } from '@getro/rombo';

import strings from 'strings';
import { trackException } from 'helpers/errorTracker';

import { login } from 'actions/userActions';
import pageHoc from 'hocs/pageHoc';
import settings from 'settings';

import SessionLayout from 'components/layout/Session';

import formSchema from './formSchema';

const pageTitle = 'Login';

const Login = () => {
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    const { email, password } = values;
    setStatus({ error: null });

    try {
      const actions = await login(email, password);

      if (actions) {
        actions.forEach(dispatch);
      }
    } catch (error) {
      const invalidCredentials = error && error.response && error.response.status === 401;

      setSubmitting(false);
      if (invalidCredentials) {
        setStatus({ error: 'Invalid login information' });
      } else {
        trackException(error);
        setStatus({ error: strings.genericError });
      }
    }
  };

  return (
    <SessionLayout title={strings.session.login.title} subtitle={strings.session.login.subtitle}>
      <Formik initialValues={{ email: '', password: '' }} validationSchema={formSchema} onSubmit={handleSubmit}>
        {({ status, isSubmitting }) => (
          <Form>
            <Field
              component={FormInput}
              data-cy="login-email"
              type="email"
              name="email"
              label="Email address"
              size="large"
            />
            <Field
              component={FormInput}
              data-cy="login-password"
              type="password"
              name="password"
              label={
                <Flex justifyContent="space-between" width="100%!important">
                  <Text>Password</Text>
                  <Text
                    as={Link}
                    to="/recover-password"
                    fontSize="1"
                    color="text.subtle"
                    sx={{ textDecoration: 'none', ':hover': { textDecoration: 'underline' } }}
                  >
                    Forgot password?
                  </Text>
                </Flex>
              }
              size="large"
              containerProps={{
                mt: '16px',
                sx: {
                  label: {
                    span: {
                      width: '100%',
                    },
                  },
                },
              }}
            />
            <AsyncButton
              fontWeight="semibold"
              width={1}
              justifyContent="center"
              type="submit"
              variant="primary"
              fontSize={3}
              loading={isSubmitting}
              size="large"
              my="4"
            >
              Log in
            </AsyncButton>
            {status && status.error && (
              <Message type="error" className="Login__error-message" content="Invalid login information" />
            )}
          </Form>
        )}
      </Formik>

      <div className="Login__no-account">
        <div className="Login__no-account-title">Don&apos;t have a login?</div>
        <div className="Login__no-account-text">
          {settings.companyName} is currently invite-only. Check in with your network admin to receive an invitation.
        </div>
      </div>
    </SessionLayout>
  );
};

export default pageHoc({ title: pageTitle })(Login);
