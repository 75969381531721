import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { marked } from 'marked';
import dompurify from 'dompurify';
import { withRouter } from 'react-router-dom';

import { MessageBlock, Button } from '@getro/rombo';

import OnboardingContext from 'components/layout/EmployerOnboarding/onboardingContext';

const handleClick = (href) => () => window.location.replace(href);

const Success = ({ href, isTalentNetworkActive }) => {
  const { organization, network } = useContext(OnboardingContext);

  const messageBlockTitle = `You are a company admin for ${organization.name}!`;

  if (network.employerOnboardingSuccessText) {
    const message = dompurify.sanitize(marked(network.employerOnboardingSuccessText));

    return (
      <>
        <MessageBlock type="success" title={messageBlockTitle} />
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <div className="EmployerOnboarding__success-button-wrapper">
          <Button onClick={handleClick(href)} variant="primary">
            Members
          </Button>
        </div>
      </>
    );
  }

  let messageBlockContent =
    'You can now edit your company information, post jobs, and invite others to help manage your company profile.';
  let buttonText = 'Ok';
  if (isTalentNetworkActive) {
    messageBlockContent =
      'Update your company info anytime. For now, see who is in the network. You may find a great fit for your team!';
    buttonText = 'Search members';
  }

  return (
    <MessageBlock
      type="success"
      title={messageBlockTitle}
      content={messageBlockContent}
      buttonText={buttonText}
      onClick={handleClick(href)}
    />
  );
};

Success.propTypes = {
  href: PropTypes.string.isRequired,
  isTalentNetworkActive: PropTypes.bool.isRequired,
};

export default withRouter(Success);
