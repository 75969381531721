import React from 'react';
import { ProfilePicture, Tooltip } from '@getro/rombo';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';

import { ChevronRight } from 'lucide-react';
import { AvatarGroup } from '../../../components/molecules/avatarGroup';

export const JobListItem = ({ data, onClick }) => (
  <Flex
    p="16px"
    flex="1"
    justifyContent="space-between"
    sx={{
      cursor: 'pointer',
      alignItems: 'center',
      border: '1px solid',
      borderColor: 'border.subtle',
    }}
    onClick={() => onClick(data)}
    height="80px"
    mx="40px"
  >
    <Box width="40%">
      <Tooltip
        position="top"
        content={data.job.title}
        trigger={
          <Text
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            fontSize="14px"
            fontWeight="medium"
            color="text.dark"
          >
            {data.job.title}
          </Text>
        }
      />
      <Flex mt="4px" alignItems="center" sx={{ gap: '8px ' }}>
        <Box width="24px" height="24px">
          <ProfilePicture imageUrl={data.organization.logoUrl} name={data.organization.name} variant="square" />
        </Box>
        <Text fontSize="12px" color="text.subtle">
          {data.organization.name}
        </Text>
      </Flex>
    </Box>

    <Flex sx={{ flexDirection: 'column', gap: '8px', width: '234px' }}>
      {data.matchList.status === 'none' && (
        <Text fontSize="12px" color="subtle">
          Matches not pre-generated, create a list to get matches.
        </Text>
      )}

      {['suggested', 'active', 'generating_matches'].includes(data.matchList.status) && !data.matches.length && (
        <Text fontStyle="italic" fontSize="12px" color="subtle">
          No matches found.
        </Text>
      )}
      {!!data.matches?.length && ['suggested', 'active'].includes(data.matchList.status) && (
        <Flex alignItems="center" sx={{ gap: '8px' }}>
          {['suggested', 'active', 'generating_matches'].includes(data.matchList.status) && (
            <AvatarGroup matchList={data.matches.slice(0, 15)} />
          )}
          <Text
            fontSize={['suggested', 'active', 'generating_matches'].includes(data.matchList.status) ? '14px' : '12px'}
            fontWeight={['suggested', 'active', 'generating_matches'].includes(data.matchList.status) ? '500' : '400'}
          >{`${Math.min(data.matches.length, 15)} ${pluralize('match', data.matches.length)}`}</Text>
        </Flex>
      )}
    </Flex>

    <Flex
      sx={{
        color: 'text.main',
        fontWeight: '500',
        lineHeight: '16px',
        fontSize: '14px',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <Text fontSize="12px" color="text.subtle">
        Select
      </Text>
      <Box sx={{ color: 'text.subtle' }} size="16px" as={ChevronRight} />
    </Flex>
  </Flex>
);

JobListItem.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

JobListItem.defaultProps = {
  onClick: null,
};
