import React from 'react';
import { Tooltip } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { RefreshCcw } from 'lucide-react';

const PageHeader = ({ description, autoUpdate }) => (
  <Flex as="header" alignItems="flex-start" pb="40px" sx={{ gap: '4px', mt: '8px', flexDirection: ['column', 'row'] }}>
    {autoUpdate && (
      <Tooltip
        position="top"
        sx={{
          mb: '8px',
        }}
        content="Auto-update adds and removes contacts according to your filters daily, while preserving manual edits. Get updates anytime from the ‘Actions’ menu."
        trigger={
          <Flex color="purple.400" alignItems="center" flex="0 0 auto" sx={{ gap: '4px', cursor: 'pointer' }}>
            <Box as={RefreshCcw} width="16px" height="16px" aria-hidden="true" />
            <Text fontSize="14px" fontWeight="medium">
              Auto-update
            </Text>
          </Flex>
        }
      />
    )}
    {autoUpdate && description && <Box sx={{ display: ['none', 'block'] }}>·</Box>}
    <Flex sx={{ alignItems: 'flex-end' }}>
      {description && (
        <Box id="header-description" as="p" fontSize="14px" lineHeight="1.4" color="text.subtle" flexGrow="1">
          {description}
        </Box>
      )}
    </Flex>
  </Flex>
);

PageHeader.propTypes = {
  description: PropTypes.string,
  autoUpdate: PropTypes.bool,
};

PageHeader.defaultProps = {
  description: '',
  autoUpdate: false,
};

export default PageHeader;
