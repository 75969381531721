import React from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'styled-components';

export const UpdateListSkeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={600}
      height={900}
      viewBox="0 0 600 900"
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
    >
      <rect x="0" y="0" rx="8" ry="8" width="204" height="21" />
      <rect x="551" y="3" rx="8" ry="8" width="50" height="26" />
      <rect x="0" y="59" rx="0" ry="0" width="116" height="20" />
      <rect x="0" y="92" rx="0" ry="0" width="600" height="40" />
      <rect x="224" y="103" rx="0" ry="0" width="30" height="4" />
      <rect x="0" y="165" rx="0" ry="0" width="116" height="20" />
      <rect x="0" y="234" rx="0" ry="0" width="600" height="80" />
      <rect x="1" y="196" rx="0" ry="0" width="425" height="20" />
      <rect x="2" y="356" rx="0" ry="0" width="600" height="5" />
      <rect x="0" y="457" rx="0" ry="0" width="37" height="20" />
      <rect x="60" y="528" rx="0" ry="0" width="164" height="40" />
      <rect x="60" y="483" rx="0" ry="0" width="550" height="31" />
      <rect x="60" y="456" rx="0" ry="0" width="116" height="20" />
      <rect x="0" y="599" rx="0" ry="0" width="37" height="20" />
      <rect x="60" y="670" rx="0" ry="0" width="164" height="40" />
      <rect x="60" y="625" rx="0" ry="0" width="550" height="31" />
      <rect x="60" y="598" rx="0" ry="0" width="116" height="20" />
      <rect x="-2" y="746" rx="0" ry="0" width="600" height="5" />
      <rect x="0" y="778" rx="0" ry="0" width="164" height="40" />
      <rect x="0" y="401" rx="0" ry="0" width="116" height="20" />
    </ContentLoader>
  );
};
