import React, { useMemo } from 'react';
import moment from 'moment';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { formatCurrency } from 'helpers/money';
import { UpdateBox } from './updateBox';

const formatPlan = ({ billingPeriodUnit, billingPeriod, amount }) => {
  if (billingPeriod <= 1) {
    return `${amount} /${billingPeriodUnit}`;
  }

  return `${amount} every ${billingPeriod} ${pluralize(billingPeriodUnit)}`;
};

export const PlansBox = ({
  subscription: { stairstep, amount: price, itemPriceFamilyId } = {},
  connectTier,
  onUpgradeClicked,
  networkSlug,
  billingPeriodUnit,
  billingPeriod,
  currencyCode,
  nextBillingAt,
  upcomingInvoiceTotal,
  coupon,
  status,
  currentTermEnd,
}) => {
  const amount = useMemo(() => formatCurrency(price / 100, currencyCode), [currencyCode, price]);
  const couponDiscount = coupon && (
    <Box as="span">
      <Box
        as="span"
        sx={{ color: 'purple.400', fontWeight: '500', fontSize: '14px', lineHeight: '19.6px' }}
      >{`· ${coupon.name}`}</Box>{' '}
      <Box as="span">discount</Box>
    </Box>
  );

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: ['32px', '16px'],
        width: '100%',
        borderRadius: '8px',
        backgroundColor: 'neutral.20',
        padding: '16px',
        flexWrap: ['wrap', 'nowrap'],
      }}
    >
      <Flex sx={{ flexDirection: ['column', 'row', 'row'], rowGap: '32px' }}>
        <Box
          sx={{
            maxWidth: '400px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',

            h2: {
              fontSize: '18px',
              color: 'text.dark',
              fontWeight: '600',
            },
            p: {
              fontSize: '14px',
              fontWeight: '400',
            },
          }}
        >
          <Flex sx={{ gap: '8px', alignItems: 'center' }}>
            <Text as="h2">{itemPriceFamilyId}</Text>
            {['non_renewing', 'future'].includes(status) && (
              <Text
                sx={{
                  color: status === 'non_renewing' ? 'orange.600' : 'purple.400',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
                as="span"
              >
                {status === 'non_renewing' ? 'Will not renew' : 'Starting next'}
              </Text>
            )}
          </Flex>
          <Text as="p">{`${
            stairstep ? `${Number(stairstep.total).toLocaleString()} ${stairstep.unit} · ` : ''
          } ${`${formatPlan({ billingPeriodUnit, billingPeriod, amount })}`}`}</Text>
        </Box>
        {stairstep ? (
          <UpdateBox
            networkSlug={networkSlug}
            connectTier={connectTier}
            contactsCount={stairstep.used}
            contactsLimit={stairstep.total}
            onUpgradeClicked={onUpgradeClicked}
            progressText={stairstep.unit}
          />
        ) : null}
      </Flex>
      <Flex sx={{ gap: '4px', flexDirection: 'column' }}>
        <Text
          sx={{
            fontSize: '12px',
            lineHeight: '16.8px',
            color: 'text.subtle',
            letterSpacing: '1px',
            textTransform: 'uppercase',
          }}
        >
          Next invoice
        </Text>
        {['active', 'future'].includes(status) && (
          <Text sx={{ fontSize: '14px', lineHeight: '19.6px', color: 'text.main' }}>
            {moment(nextBillingAt).format('MMMM D, Y')} {couponDiscount} · Total{' '}
            {coupon && (
              <>
                <Box
                  sx={{
                    textDecoration: 'line-through',
                  }}
                  as="span"
                >
                  {amount}
                </Box>{' '}
              </>
            )}
            <Box as="span">{formatCurrency(upcomingInvoiceTotal / 100, currencyCode)} </Box>{' '}
          </Text>
        )}

        {status === 'non_renewing' && (
          <Text sx={{ fontSize: '14px', lineHeight: '19.6px', color: 'text.main' }}>
            No next invoices, this plan will be cancelled by {moment(currentTermEnd).format('MMMM D, Y')}.
            <Box as="br" /> Contact <Box as="a"> help@getro.com </Box> to renew it.
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

PlansBox.defaultProps = {
  connectTier: null,
};

PlansBox.propTypes = {
  subscription: PropTypes.shape({
    itemPriceFamilyId: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    stairstep: PropTypes.shape({
      unit: PropTypes.string,
      total: PropTypes.number,
      used: PropTypes.number,
    }),
  }).isRequired,
  connectTier: PropTypes.string,
  onUpgradeClicked: PropTypes.func.isRequired,
  networkSlug: PropTypes.string.isRequired,
  billingPeriodUnit: PropTypes.string.isRequired,
  billingPeriod: PropTypes.number.isRequired,
  nextBillingAt: PropTypes.number.isRequired,
  upcomingInvoiceTotal: PropTypes.number.isRequired,
  currencyCode: PropTypes.string.isRequired,
  coupon: PropTypes.object.isRequired,
  status: PropTypes.oneOf(['active', 'non_renewing']).isRequired,
  currentTermEnd: PropTypes.string.isRequired,
};
