import { createActions } from 'redux-actions';

export const { loadRemoveCompany, loadedRemoveCompany, errorRemoveCompany } = createActions(
  'LOAD_REMOVE_COMPANY',
  'LOADED_REMOVE_COMPANY',
  'ERROR_REMOVE_COMPANY',
);
export const { loadTopicOptions, loadedTopicOptions, errorTopicOptions } = createActions(
  'LOAD_TOPIC_OPTIONS',
  'LOADED_TOPIC_OPTIONS',
  'ERROR_TOPIC_OPTIONS',
);

export const { loadSaveCompanyTopics, loadedSaveCompanyTopics, errorSaveCompanyTopics } = createActions(
  'LOAD_SAVE_COMPANY_TOPICS',
  'LOADED_SAVE_COMPANY_TOPICS',
  'ERROR_SAVE_COMPANY_TOPICS',
);

export const { triggerTrackEvent } = createActions('TRIGGER_TRACK_EVENT');

export const { resetCompanyProfile } = createActions('RESET_COMPANY_PROFILE');
