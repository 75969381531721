import { EyeOff, X } from 'lucide-react';
import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { matchesApi } from 'services/matches';
import { useDispatch } from 'react-redux';

export const NoAtMatch = ({ match, matchListId, positionVertical }) => {
  const dispatch = useDispatch();
  const close = async () => {
    dispatch(
      matchesApi.util.updateQueryData('getMatchListById', { matchListId: `${matchListId}` }, ({ matchList }) => {
        Object.assign(matchList, {
          matches: matchList.matches.filter((item) => item.id !== match.id),
        });
      }),
    );
  };

  return (
    <Flex sx={{ gap: '16px ', position: 'relative', alignItems: 'center' }}>
      {!positionVertical && (
        <Box sx={{ color: 'neutral.300' }} as={EyeOff} width="16px" height="16px" aria-hidden="true" />
      )}
      <Flex flexGrow="1" justifyContent="space-between" alignItems="center" sx={{ gap: '8px' }}>
        <Flex alignItems="flex-start" sx={{ gap: '8px' }}>
          {positionVertical && (
            <Flex
              flex="0 0 auto"
              p="8px"
              alignItems="center"
              justifyContent="center"
              color="neutral.300"
              bg="neutral.50"
              sx={{ borderRadius: 'circle' }}
            >
              <Box sz={{ p: '8px' }} as={EyeOff} size="24px" aria-hidden="true" />
            </Flex>
          )}
          <Text
            fontSize="14px"
            lineHeight="1.4"
            fontWeight={positionVertical ? '500' : '400'}
            color={positionVertical ? 'text.dark' : 'text.subtle'}
          >{`${match.contact.fullName} has been removed from this matches search.`}</Text>
        </Flex>
        {!positionVertical && (
          <Flex alignItems="center" onClick={close} sx={{ cursor: 'pointer' }}>
            <Box sx={{ color: 'neutral.300' }} as={X} size="16px" aria-hidden="true" />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

NoAtMatch.propTypes = {
  match: PropTypes.object.isRequired,
  positionVertical: PropTypes.bool,
  matchListId: PropTypes.number.isRequired,
};

NoAtMatch.defaultProps = {
  positionVertical: false,
};
