import pluralize from 'pluralize';
import { useMemo } from 'react';
import { mapCompanyFundType } from 'services/organizations/organizationFundTypes';

export const useJobDetail = ({ skills, locations, industryTags, stage, approxEmployees, job, organizationName }) => {
  const skill = useMemo(() => {
    const skillToShow = skills.slice(0, 2).reduce((carry, item) => (carry ? `${carry} · ${item.name}` : item.name), '');
    const otherSkillCount = skills.slice(2).length;
    return otherSkillCount
      ? `${skillToShow} · and ${otherSkillCount} more ${pluralize('skill', otherSkillCount)}`
      : skillToShow;
  }, [skills]);

  const location = useMemo(() => {
    const matchLocations = locations.map((item) => item.name);
    if (!matchLocations.length) return '';

    return matchLocations.reduce(
      (acc, item, index) => {
        if (item.toLowerCase() === 'remote') {
          acc.hasRemote = true;
        } else {
          acc.value = acc.value ? `${acc.value}; ${item}` : item;
        }

        if (index === matchLocations.length - 1 && acc.hasRemote) {
          acc.value += acc.value ? ' · Remote' : 'Remote';
        }

        return acc;
      },
      {
        value: '',
        hasRemote: false,
      },
    ).value;
  }, [locations]);

  const tags = industryTags.reduce((carry, tag) => {
    const description = typeof tag === 'string' ? tag : tag.description;

    return carry ? `${carry} · ${description}` : description;
  }, '');

  let stageAndEmployees = stage ? mapCompanyFundType(stage) : '';
  if (approxEmployees) {
    stageAndEmployees = `${stageAndEmployees ? `${stageAndEmployees} · ` : ''} ${approxEmployees} employees`;
  }

  return {
    location,
    skill,
    stageAndEmployees,
    tags,
    jobName: job.title ? `${job.title}${organizationName ? ` at ${organizationName}` : ''}` : '',
  };
};
