import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_INTRODUCTION_REQUESTS,
  fetchIntroductionRequestsSuccess,
  fetchIntroductionRequestsFailure,
} from 'actions/introductionRequestsActions';
import { get } from 'api';

function* fetchIntroductionRequestsWatcher() {
  yield takeLatest(FETCH_INTRODUCTION_REQUESTS, fetchIntroductionRequestsWorker);
}

const fetchIntroductionRequests = (id) =>
  get(`/collections/${id}/introduction_requests`, {
    params: {
      status: ['poc_asked', 'gk_asked'],
    },
  });

function* fetchIntroductionRequestsWorker(action) {
  const { collectionId } = action;
  try {
    const response = yield call(fetchIntroductionRequests, collectionId);
    if (response && response.data) {
      const { data } = response;
      const { items } = data;
      yield put(fetchIntroductionRequestsSuccess({ id: collectionId, items }));
    }
  } catch (error) {
    yield put(fetchIntroductionRequestsFailure());
  }
}

export { fetchIntroductionRequestsWorker };
export default fetchIntroductionRequestsWatcher;
