import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import MetricIcon from './Sidebar/MetricIcon';
import { METRICS, INTERVALS } from './filtersOptions';

export const FiltersContext = createContext();

const getDefaultFilters = () => ({
  metric: {
    value: METRICS[0].value,
    label: METRICS[0].label,
    icon: <MetricIcon reportType={METRICS[0].reportType} />,
    tooltipText: METRICS[0].tooltipText,
  },
  interval: INTERVALS[0],
  startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
});

function AnalyticsContext({ children }) {
  const [filters, setFilters] = useState(getDefaultFilters());

  const updateFilters = (newState, reset = false) => {
    if (reset) {
      return setFilters({});
    }

    return setFilters((prevState) => ({ ...prevState, ...newState }));
  };

  return <FiltersContext.Provider value={{ filters, updateFilters }}>{children}</FiltersContext.Provider>;
}

AnalyticsContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnalyticsContext;
