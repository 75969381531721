import React, { useEffect, useState } from 'react';
import { Box, Loader } from '@getro/rombo';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import BlankLayout from '../../components/layout/Blank';
import RenewJob from '../../components/molecules/RenewJob';
import useJob from '../../hooks/useJob';
import { activeNetworkSelector } from '../../redux/selectors';
import { usePrevious } from '../../hooks/usePrevious';
import useJobActions from '../../hooks/useJobActions';

const RenewJobPage = () => {
  const { jobActions, jobActionsLoading, jobActionsErrors } = useJobActions({ source: 'renewJob' });
  const { onExtendJob } = jobActions;
  const { isExtending } = jobActionsLoading;
  const { extendJob: extendJobError } = jobActionsErrors;
  const network = useSelector(activeNetworkSelector);
  const params = useParams();
  const history = useHistory();
  const { job, isJobLoading } = useJob(params);
  const [succeed, setSucceed] = useState(false);
  const previousLoadingRenewJob = usePrevious(isExtending);

  const { title, organization } = job;

  useEffect(() => {
    if (previousLoadingRenewJob && !isExtending) {
      setSucceed(!extendJobError);
    }
  }, [isExtending, previousLoadingRenewJob, extendJobError]);

  const handleSubmit = ({ expiresIn }) => {
    onExtendJob({ job, extension: expiresIn });
  };

  const handleClose = () => {
    history.push(`/networks/${network.slug}/companies/${organization.slug}/jobs`);
  };

  const initialValues = {
    expiresIn: 60,
  };

  return (
    <BlankLayout>
      {(isJobLoading || !job.id) && <Loader />}
      {!isJobLoading && job.id && (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <Box
              title={succeed ? null : 'Extend job expiration date'}
              saveAsSubmit
              editing={!succeed}
              loading={isExtending}
              onCancelClick={handleClose}
              cancelText="Cancel"
              saveText="Extend expiration date"
            >
              <RenewJob
                id="expiresIn"
                name="expiresIn"
                title={title}
                company={organization}
                error={extendJobError}
                succeed={succeed}
                onClose={handleClose}
              />
            </Box>
          </Form>
        </Formik>
      )}
    </BlankLayout>
  );
};

export default RenewJobPage;
