export const addProtocolToUrl = (url) => {
  if (!url || url === '') return url;

  if (url.startsWith('http')) {
    return url;
  }

  return `https://${url}`;
};

export const getDomainName = () => {
  const domainTokens = window.location.hostname.split('.');
  let domain = domainTokens[domainTokens.length - 1];

  if (domainTokens.length > 1) {
    domain = `${domainTokens[domainTokens.length - 2]}.${domain}`;
  }

  return domain;
};
