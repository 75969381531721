import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Select } from '@getro/rombo';
import PropTypes from 'prop-types';
import { fundOptions } from 'components/organisms/contactsFilters/defaultFilters';

export const FundSelector = ({ onChange, width, value, disableByIndex, menuWidth, maxIndex }) => (
  <Box
    sx={{
      width,
      '& > div': {
        width,
        '& .Select__menu ': {
          width: menuWidth,
          fontSize: '14px',
        },
        '& .Select__control > div': {
          height: '32px',
          fontSize: '14px',
        },

        '& .Select__menu-list': {
          width: menuWidth,
        },
      },
    }}
  >
    <Select
      value={value || fundOptions[0]}
      onChange={(option) => {
        onChange(
          option,
          fundOptions.find((item) => item.index > option.index),
        );
      }}
      options={fundOptions.filter((item) => !maxIndex || item.index <= maxIndex)}
      customFormatOptionLabel={(d) => {
        const disabled = typeof disableByIndex === 'number' && disableByIndex + 1 > d.index;
        return (
          <Flex
            onClick={(e) => {
              if (disabled) {
                e.stopPropagation();
              }
            }}
            disabled
            sx={{
              width: '100%',
              height: '28px',
              px: '16px',
              pt: '8px',
              color: disabled ? 'text.placeholder' : 'text.main',
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
          >
            {d.label}
          </Flex>
        );
      }}
    />
  </Box>
);

FundSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  disableByIndex: PropTypes.number,
  menuWidth: PropTypes.string,
  maxIndex: PropTypes.number,
};

FundSelector.defaultProps = {
  width: '100%',
  disableByIndex: null,
  menuWidth: '100%',
  maxIndex: null,
};

export default FundSelector;
