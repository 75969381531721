import produce from 'immer';
import { handleActions } from 'redux-actions';
import { loadJob, loadedJob, errorJob } from './actions';
import { loadedExtendJob } from '../useJobActions/actions';

export const initialState = {
  initialized: false,
  error: false,
  details: {},
};

/* eslint-disable default-case, no-param-reassign */
const jobReducer = handleActions(
  {
    [loadJob]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [loadedJob]: produce((draft, action) => {
      const { payload } = action;
      draft.initialized = true;
      draft.error = false;
      draft.details = payload;
    }),
    [errorJob]: produce((draft) => {
      draft.initialized = true;
      draft.error = true;
    }),
    [loadedExtendJob]: produce((draft, action) => {
      const { payload } = action;
      const { job } = payload;
      if (draft.details.id === job.id) {
        draft.details = job;
      }
      return draft;
    }),
  },
  initialState,
);

export default jobReducer;
