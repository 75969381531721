import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './queries';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery,
  tagTypes: ['user'],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => ({ url: '/users/me' }),
      providesTags: ['user'],
    }),
  }),
});

export const { useGetUserQuery, useLazyGetUserQuery } = userApi;
