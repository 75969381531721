import PropTypes from 'prop-types';

const NetworkSchema = PropTypes.shape({
  domain: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number.isRequired,
  isConnector: PropTypes.bool,
  isManager: PropTypes.bool,
  name: PropTypes.string.isRequired,
  isOrganizationManager: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  slug: PropTypes.string.isRequired,
  logo: PropTypes.string,
  url: PropTypes.string,
  jobBoardFilters: PropTypes.shape({
    jobFunctionFilter: PropTypes.bool.isRequired,
    stageFilter: PropTypes.bool.isRequired,
    industryTagsFilter: PropTypes.bool.isRequired,
    companySizeFilter: PropTypes.bool.isRequired,
    locationFilter: PropTypes.bool.isRequired,
  }).isRequired,
});

export default NetworkSchema;
