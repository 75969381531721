import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';

import { get, post } from 'api';
import { trackException } from 'helpers/errorTracker';

import { setUser } from 'actions/userActions';

import OnboardingContext from 'components/layout/EmployerOnboarding/onboardingContext';

import VerifyYourAccount from 'pages/ClaimYourProfile/VerifyYourAccount';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CareersPage from './CareersPage';
import GeneraInformation from './GeneraInformation';
import Managers from './Managers';
import SocialProfiles from './SocialProfiles';
import Success from './Success';
import { TalentBoards } from './talentBoards';
import { userSelector } from '../../redux/selectors';

const handleBack = (step, goToStep) => () => goToStep(step - 1);

const STEPS = {
  generalInformation: {
    title: 'Company information',
    component: (title) => <GeneraInformation title={title} />,
  },
  socialProfiles: {
    title: 'Social profiles',
    // eslint-disable-next-line react/no-multi-comp
    component: (title, user, step, goToStep) => (
      <SocialProfiles title={title} user={user} onBack={handleBack(step, goToStep)} />
    ),
  },
  careersPage: {
    title: 'Job source',
    // eslint-disable-next-line react/no-multi-comp
    component: (title, user, step, goToStep) => (
      <CareersPage title={title} user={user} onBack={handleBack(step, goToStep)} />
    ),
  },
  managers: {
    title: 'Invite company admins',
    // eslint-disable-next-line react/no-multi-comp
    component: (title, user, step, goToStep) => (
      <Managers title={title} user={user} onBack={handleBack(step, goToStep)} />
    ),
  },
  subscribe: {
    title: 'Get updates from talent lists',
    // eslint-disable-next-line react/no-multi-comp
    component: (title, user, step, goToStep) => (
      <TalentBoards title={title} user={user} onBack={handleBack(step, goToStep)} />
    ),
  },
  success: {
    // eslint-disable-next-line react/no-multi-comp
    component: (title, user, step, goToStep, isTalentNetworkActive) => (
      <Success href="/" isTalentNetworkActive={isTalentNetworkActive} />
    ),
  },
};

const setLikeOrganizationManager = async (organizationId, network, user, setLoading, updateNetwork) => {
  setLoading(true);
  const { firstName, lastName, email } = user;
  try {
    const res = await post(
      `/organizations/${organizationId}/poc`,
      {
        firstName,
        lastName,
        email,
      },
      {
        params: {
          collectionId: network.id,
        },
      },
    );

    updateNetwork({
      ...network,
      isOrganizationManager: res.data.managingOrganizations.reduce((acc, { id, name, logoUrl, slug }) => {
        acc[id] = {
          id,
          name,
          logoUrl,
          slug,
        };

        return acc;
      }, network.isOrganizationManager || {}),
    });
  } catch (error) {
    trackException(error);
  }
  setLoading(false);
};

const setUserConfirmed = async (user, confirmationToken, dispatch, setLoading) => {
  setLoading(true);
  try {
    await get(`/users/confirmation`, {
      params: {
        confirmationToken,
      },
    });

    dispatch(
      setUser({
        ...user,
        confirmed: true,
      }),
    );
  } catch (error) {
    trackException(error);
  }
  setLoading(false);
};

const EmployerOnboarding = () => {
  const [isLoadingConfirmed, setIsLoadingConfirmed] = useState(undefined);
  const [isLoadingManager, setIsLoadingManager] = useState(undefined);
  const [showVerifyYourAccount, setShowVerifyYourAccount] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const history = useHistory();

  const { organization, network, steps, step, updateStep, updateNetwork, isManager } = useContext(OnboardingContext);
  const isTalentNetworkActive = network.features.includes('talent_network');

  const { confirmation_token: confirmationToken, confirm, ...params } = qs.parse(history.location.search);

  useEffect(() => {
    if (user.id && !user.confirmed) {
      if (confirmationToken && !isLoadingConfirmed) {
        history.push({
          search: qs.stringify(params),
        });

        setUserConfirmed(user, confirmationToken, dispatch, setIsLoadingConfirmed);

        return;
      }

      if (!isLoadingConfirmed) {
        setShowVerifyYourAccount(true);
      }
    }

    // eslint-disable-next-line
  }, [history.location.search])

  useEffect(() => {
    if (isManager === false && user.confirmed && !isLoadingManager) {
      setLikeOrganizationManager(organization.id, network, user, setIsLoadingManager, updateNetwork, dispatch);
    }
    // eslint-disable-next-line
  }, [user])

  if (showVerifyYourAccount && !isManager) {
    return <VerifyYourAccount email={user.email} organization={organization} networkId={network.id} />;
  }

  const goToClaimYourProfile = !user.id || !user.onboardingCompleted || user.forcePasswordUpdate;
  if (goToClaimYourProfile) {
    return <Redirect to={`/claim-your-profile/${network.slug}/company/${organization.slug}`} />;
  }

  if (step < 1) {
    return null;
  }

  const { title } = STEPS[steps[step].id];

  return STEPS[steps[step].id].component(title, user, step, updateStep, isTalentNetworkActive);
};

export default EmployerOnboarding;
