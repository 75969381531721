export const RESET_PAGE = 'RESET_PAGE';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export const setPageTitle = (title) => ({
  type: SET_PAGE_TITLE,
  payload: title,
});

export const resetPage = () => ({
  type: RESET_PAGE,
});
