import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Loader } from '@getro/rombo';
import { trackException } from 'helpers/errorTracker';
import { post } from 'api';
import NetworkSchema from 'schemas/network';

const Request = ({ activeNetwork, navigateForward, records }) => {
  const { id: collectionId } = activeNetwork;
  const bulkImportUrl = useMemo(() => `/collections/${collectionId}/users/bulk_import`, [collectionId]);
  const params = useMemo(() => {
    const users = records.map((r) => {
      const { permissions, ...otherProps } = r;

      return {
        ...otherProps,
        verified: permissions === 'verified',
      };
    });

    return { users };
  }, [records]);

  useEffect(() => {
    let mounted = true;
    let cancelTokenSource;

    const cleanUp = () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }

      mounted = false;
    };

    const inviteMembers = async () => {
      cancelTokenSource = axios.CancelToken.source();
      try {
        const response = await post(bulkImportUrl, params, {
          cancelToken: cancelTokenSource.token,
        });

        const { status } = response;
        if (status === 200) {
          navigateForward();
        }
      } catch (error) {
        trackException(error);
      }
    };

    (async () => {
      if (mounted) {
        inviteMembers();
      }
    })();

    return cleanUp;
  }, [bulkImportUrl, params, navigateForward]);

  return <Loader />;
};

Request.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
  records: PropTypes.array.isRequired,
  navigateForward: PropTypes.func.isRequired,
};

export default Request;
