import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';

import { BulkNotificationPolling } from '../components/atoms/bulkNotificationPolling';
import { contactsApi } from '../services/contacts';

export const useBulkNotificationToast = (onDone) => {
  const dispatch = useDispatch();
  const types = {
    'adding-contacts-to-list': {
      title: 'Adding contacts',
      description: 'This can take a few seconds...',
      message: {
        success: 'Selected contacts added',
      },
    },
    'removing-contacts-from-list': {
      title: 'Removing contacts',
      description: 'This can take a few seconds...',
      message: {
        success: 'Selected contacts removed',
      },
    },
  };

  const onFinish = (toastId, type) => {
    dispatch(contactsApi.util.invalidateTags(['contacts-shared-lists']));
    dispatch(contactsApi.util.invalidateTags(['shared-lists-contacts']));
    toast.dismiss(toastId);

    if (!onDone) {
      toast(types[type]?.message?.success, { icon: 'success' });
    }

    onDone?.({
      type: type === 'adding-contacts-to-list' ? 'add' : 'remove',
    });
  };

  const customToast = ({ transactionId, type, list }) => {
    const typeData = types[type];

    toast(
      (t) => (
        <BulkNotificationPolling
          toastId={t.id}
          transactionId={transactionId}
          title={typeData.title}
          description={typeData.description}
          onFinish={(toastId, pathname) => onFinish(toastId, type, pathname, list)}
        />
      ),
      {
        duration: Infinity,
        type: 'bulk-notification',
      },
    );
  };

  return { toast: customToast };
};
