import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  errorSendShareEmail,
  loadedSendShareEmail,
  loadedShareUserOptions,
  loadSendShareEmail,
  resetSharePage,
} from './actions';

export const initialState = {
  sharedWith: [],
  note: '',
  userOptions: [],
  boardsToShare: [],
  emailSent: false,
  sendingError: null,
};

/* eslint-disable default-case, no-param-reassign */
const shareReducer = handleActions(
  {
    [resetSharePage]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [loadSendShareEmail]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [loadedShareUserOptions]: produce((draft, action) => {
      const { userOptions } = action.payload;
      draft.userOptions = userOptions;
      return draft;
    }),
    [loadedSendShareEmail]: produce((draft, action) => {
      const { sharedWith } = action.payload;
      draft.sharedWith = sharedWith;
      draft.note = initialState.note;
      draft.userOptions = initialState.userOptions;
      draft.emailSent = true;
      draft.sendingError = null;
      return draft;
    }),
    [errorSendShareEmail]: produce((draft, action) => {
      const { error } = action.payload;
      draft.sendingError = error;
      return draft;
    }),
  },
  initialState,
);

export default shareReducer;
