import { createApi } from '@reduxjs/toolkit/query/react';

import { networkBaseQuery } from './queries';

export const tagsApi = createApi({
  reducerPath: 'tagsApi',
  baseQuery: networkBaseQuery,
  tagTypes: ['tags'],
  endpoints: (builder) => ({
    getTags: builder.query({
      query: () => ({ url: '/tags' }),
      providesTags: ['tags'],
    }),
    createTag: builder.mutation({
      query: ({ tagName }) => ({ url: '/tags', method: 'post', data: { tag: tagName } }),
      invalidatesTags: ['tags'],
    }),
  }),
});

export const { useGetTagsQuery, useLazyGetTagsQuery, useCreateTagMutation } = tagsApi;
