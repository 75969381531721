import React from 'react';
import { HelpCircle } from 'lucide-react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Popover from '../../../atoms/popoverDeprecated';

const Wrapper = styled.div`
  & + & {
    margin-top: var(--space-sm);
  }
`;

const Title = styled.div`
  font-weight: 500;
  margin: 0;
  font-size: var(--text-sm);
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.span`
  margin-left: var(--space-xs);
  line-height: 1;
`;

const Content = styled.div`
  padding: var(--space-sm) 0;
`;

const FilterWrapper = ({ className, children, helpPopoverTitle, helpPopoverText, title }) => (
  <Wrapper className={className}>
    {title && (
      <Title>
        {title}
        {(helpPopoverText || helpPopoverTitle) && (
          <Popover
            trigger={
              <IconWrapper>
                <Box
                  as={HelpCircle}
                  strokeWidth="1.5"
                  aria-hidden="true"
                  height="1em"
                  width="1em"
                  sx={{
                    stroke: ({ colors }) => colors.neutral[400],
                  }}
                />
              </IconWrapper>
            }
            header={helpPopoverTitle}
            content={helpPopoverText}
          />
        )}
      </Title>
    )}
    <Content>{children}</Content>
  </Wrapper>
);

FilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  helpPopoverText: PropTypes.string,
  helpPopoverTitle: PropTypes.string,
  title: PropTypes.string,
};

FilterWrapper.defaultProps = {
  className: '',
  helpPopoverText: '',
  helpPopoverTitle: '',
  title: '',
};

export default FilterWrapper;
