import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import { SidebarOpen } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleMenu } from '../../../features/mainMenu';
import { pageSelector } from '../../../redux/selectors';

export const Title = ({ mainAction, mainActionText, titleProps }) => {
  const page = useSelector(pageSelector);
  const dispatch = useDispatch();
  const { title } = page;

  return (
    <Flex
      as="header"
      height={['auto', 'auto', '48px']}
      minHeight={['48px', '48px', 'auto']}
      alignItems="center"
      px="16px"
      py="8px"
      sx={{ borderBottom: 1, borderBottomColor: 'border.subtle', gap: '8px' }}
      {...titleProps}
    >
      <Box
        display={['flex', 'flex', 'none']}
        alignSelf="flex-start"
        pt={['10px', '10px', 0]}
        alignItems="center"
        justifyContent="center"
        width="22px"
        height="22px"
        onClick={() => dispatch(toggleMenu())}
      >
        <Box as={SidebarOpen} width="16px" height="16px" aria-hidden="true" flex="0 0 auto" />
      </Box>
      <Flex
        alignItems={['flex-start', 'flex-start', 'center']}
        justifyContent="space-between"
        flexWrap="wrap"
        width="100%"
        flexDirection={['column', 'column', 'row']}
        sx={{ gap: '8px' }}
      >
        <Box as="h1" fontSize="16px" fontWeight="semibold" color="text.dark">
          {title}
        </Box>
        {!!mainAction && (
          <Flex alignItems="center" sx={{ gap: '16px' }}>
            <Button size="small" variant="accent" onClick={mainAction} flex="0 0 auto">
              {mainActionText}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

Title.propTypes = {
  mainAction: PropTypes.func,
  mainActionText: PropTypes.node,
  titleProps: PropTypes.shape({
    sx: PropTypes.object,
  }),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

Title.defaultProps = {
  mainAction: null,
  mainActionText: '',
  titleProps: {},
};
