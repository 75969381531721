/* eslint-disable no-console */
import humps from 'humps';
import { trackException } from 'helpers/errorTracker';
import posthog from 'posthog-js';

let reduxStore = null;

export const setStore = (store) => {
  reduxStore = store;
};

export function loadAnalytics() {
  if (!process.env.REACT_APP_SEGMENT_KEY) {
    return;
  }

  try {
    window.analytics.load(process.env.REACT_APP_SEGMENT_KEY);
    if (process.env.REACT_APP_ENV === 'production' && process.env.REACT_APP_POST_HOG_KEY) {
      window.analytics.ready(() => {
        posthog.init(process.env.REACT_APP_POST_HOG_KEY, {
          api_host: 'https://app.posthog.com',
          segment: window.analytics,
          capture_pageview: false,
          loaded: () => window.analytics.page(),
        });
      });
    }
  } catch (error) {
    trackException(error);
  }
}

export function identify(user) {
  const data = {
    is_network_ambassador: !!user.connectingCollections.length,
    is_network_admin: !!user.managingCollections.length,
    is_employer_admin: !!user.managingOrganizations.length,
    is_jb_network_admin: !!user.managingCollections.length,
    is_tn_network_admin:
      !!user.managingCollections.length && user.managingCollections.some((c) => c.features.includes('talent_network')),
    internal_user: !!user?.email?.includes('@getro.com'),
  };

  // Don't track events in development
  if (process.env.NODE_ENV === 'development') {
    console.log('\nanalytics identify');
    console.log(data);
    return;
  }

  try {
    window.analytics.identify(user.id, data);
  } catch (error) {
    trackException(error);
  }
}

export function trackPage() {
  // Don't track events in development
  if (process.env.NODE_ENV === 'development') {
    console.log('\nanalytics trackPage');
    return;
  }

  try {
    const store = reduxStore?.getState();
    const activeNetwork = activeNetworkSelector(store?.networks);

    if (activeNetwork?.isManager) {
      window.analytics.page({ $groups: { network: activeNetwork.id } });
    } else {
      window.analytics.page();
    }
  } catch (error) {
    trackException(error);
  }
}

const activeNetworkSelector = (networksStore = {}) => {
  const { networks = [], active } = networksStore;

  return networks === null || networks.length === 0 || !active ? null : networks[active];
};

export function trackEvent(name, properties = {}) {
  const store = reduxStore?.getState();
  const activeNetwork = activeNetworkSelector(store?.networks);
  const eventProps = humps.decamelizeKeys({
    ...(activeNetwork?.isManager ? { $groups: { network: activeNetwork.id } } : undefined),
    ...properties,
  });

  // Don't track events in development nor test
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    console.log(`analytics trackEvent: ${name}: ${JSON.stringify(eventProps)}`);
    return;
  }

  try {
    window.analytics.track(name, eventProps);
  } catch (error) {
    trackException(error);
  }
}

export function reset() {
  // Don't use analytics in development
  if (process.env.NODE_ENV === 'development') {
    console.log('\nanalytics reset');
  }

  try {
    window.analytics.reset();
  } catch (error) {
    trackException(error);
  }
}
