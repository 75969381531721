import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { trackEvent } from 'helpers/analytics';
import strings from 'strings';

import { Select } from '@getro/rombo';

import PageLayout from 'components/layout/Page';

import DatePicker from 'components/atoms/DatePicker';
import FilterWrapper from 'components/molecules/Sidebar/FilterWrapper';
import SidebarFilters from 'components/molecules/Sidebar/SidebarFilters';

import MetricIcon from './MetricIcon';
import { FiltersContext } from '../context';
import { INTERVALS, METRICS } from '../filtersOptions';
import NetworkSchema from '../../../schemas/network';

const Analytics = ({ activeNetwork }) => {
  const { filters, updateFilters } = useContext(FiltersContext);
  const selectedMetric = METRICS.find((m) => m.value === filters.metric.value);

  const cancelTokenSource = useRef(axios.CancelToken.source());

  useEffect(
    () => () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cancelTokenSource.current.cancel();
    },
    // eslint-disable-next-line
    []
  );

  const handleChange = ({ target }) => {
    const trackEventParams = {
      filter: target.name,
      value: target.value.value,
    };

    if (Array.isArray(trackEventParams.value)) {
      trackEventParams.value = trackEventParams.value.map((v) => v.key).join(', ');
    }
    trackEvent('analytics:filters_change', trackEventParams);

    updateFilters({ [target.name]: target.value });
  };

  const handleDatesChange = ({ startDate, endDate }) => updateFilters({ startDate, endDate });

  // Manage options
  const [options, setOptions] = useState([]);

  const getGroupedOptions = () => {
    // Code for grouped options
    const groupedOptions = [];

    METRICS.forEach((m) => {
      const groupLabel = strings.analytics.products[m.product];

      const group = groupedOptions.find((groupedOption) => groupedOption.label === groupLabel);

      if (!group) {
        groupedOptions.push({
          label: groupLabel,
          options: [
            {
              value: m.value,
              label: m.label,
              tooltipText: m.tooltipText,
              icon: <MetricIcon reportType={m.reportType} />,
            },
          ],
        });
      }

      if (group) {
        group.options.push({
          value: m.value,
          label: m.label,
          tooltipText: m.tooltipText,
          icon: <MetricIcon reportType={m.reportType} />,
        });
      }
    });

    return groupedOptions;
  };

  const updateOptions = () => {
    if (!activeNetwork.features.includes('talent_network')) {
      const filteredOptions = METRICS.filter((o) => o.product === 'jobBoard');

      setOptions(
        filteredOptions.map(({ label, value, reportType, tooltipText }) => ({
          label,
          value,
          icon: <MetricIcon reportType={reportType} />,
          tooltipText,
        })),
      );
      return;
    }

    setOptions(getGroupedOptions(METRICS));
  };

  useEffect(
    () => {
      updateOptions();
    },
    // eslint-disable-next-line
    [activeNetwork]
  );

  return (
    <PageLayout.Sidebar>
      <SidebarFilters title="Configure report">
        <FilterWrapper title="Metric">
          <Select
            name="metric"
            options={options}
            onChange={(value) => handleChange({ target: { name: 'metric', value } })}
            value={filters.metric}
            className="Analytics__metrics-filter"
          />
        </FilterWrapper>

        <FilterWrapper title="Timeframe">
          <DatePicker startDate={filters.startDate} endDate={filters.endDate} onSelectDates={handleDatesChange} />
        </FilterWrapper>

        <FilterWrapper title="Interval">
          <Select
            name="interval"
            options={INTERVALS}
            onChange={(value) => handleChange({ target: { name: 'interval', value } })}
            disabled={selectedMetric.disableInterval}
            value={filters.interval}
            className="Analytics__interval-filter"
          />
        </FilterWrapper>
      </SidebarFilters>
    </PageLayout.Sidebar>
  );
};

Analytics.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
};

export default Analytics;
