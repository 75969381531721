import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { activeNetworkSelector } from '../../redux/selectors';
import { DEFAULT_BOARD, AMBASSADORS_DEFAULT_BOARD } from './constants';

export const key = 'members';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const makeSelectMemberSkillOptions = createSelector(rootSelector, (state) => state.skills.options);

export const makeSelectMemberLocationOptions = createSelector(rootSelector, (state) => state.location.options);

export const makeSelectMemberVouchedByOptions = createSelector(rootSelector, (state) => state.vouchedBy.options);

export const makeSelectMembers = createSelector(rootSelector, (state) => state.members);

export const makeSelectMemberById = ({ memberId }) =>
  createSelector(rootSelector, (state) => state.members.find((member) => member.id === memberId));

export const makeSelectMembersTotal = createSelector(rootSelector, (state) => state.total);

export const makeSelectMembersTotalPages = createSelector(rootSelector, (state) => state.totalPages);

export const makeSelectNewTalentGroup = createSelector(rootSelector, (state) => state.talentGroups);

export const makeSelectCreateBoardError = createSelector(rootSelector, (state) => state.createBoardError);

export const makeSelectErrorFetchingMembers = createSelector(rootSelector, (state) => state.errorFetchingMembers);

export const makeSelectIsCreatingNewBoard = createSelector(rootSelector, (state) => state.isCreatingNewBoard);

export const makeSelectCurrentBoard = createSelector(rootSelector, (state) => {
  const board = {};
  Object.assign(board, state.currentBoard);
  if (!board.description) {
    board.description = '';
  }
  if (!board.name) {
    board.name = board.label;
  }
  if (!board.id) {
    board.id = board.value;
  }
  return board;
});

export const makeSelectShowBoardSettings = createSelector(rootSelector, (state) => state.showBoardSettingsModal);

export const makeSelectWasNewBoardCreated = createSelector(rootSelector, (state) => state.wasNewBoardCreated);

export const makeSelectShowDeleteBoardModal = createSelector(rootSelector, (state) => state.showDeleteBoardModal);

export const makeSelectWasBoardDeleted = createSelector(rootSelector, (state) => state.wasBoardDeleted);

export const makeSelectExportedMembers = createSelector(rootSelector, (state) => state.exportedMembers);

export const makeSelectErrorExportingMembers = createSelector(rootSelector, (state) => state.errorExportingMembers);

export const makeSelectMemberDetails = createSelector(rootSelector, (state) => state.memberDetails);

export const makeSelectVouchMemberResult = createSelector(rootSelector, (state) => state.vouchMember);

export const makeDefaultBoard = createSelector(activeNetworkSelector, (activeNetwork) => {
  const { isConnector: isNc } = activeNetwork;
  if (isNc) {
    return AMBASSADORS_DEFAULT_BOARD;
  }

  return DEFAULT_BOARD;
});
