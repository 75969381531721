import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@getro/rombo';
import { Text } from 'rebass/styled-components';
import ShareLink from '../ShareLink';

export const ShareJobModal = ({ onCancel, isOpen, job }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Modal data-testid="share-jobs-modal" onCancel={onCancel} isOpen={isOpen} title={`Share ${job.title}`}>
      <Text mb={3}>Use this link to share your job. Anyone with the link will be able to view.</Text>
      <ShareLink url={job.url} />
    </Modal>
  );
};

ShareJobModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  job: PropTypes.object,
};

ShareJobModal.defaultProps = {
  isOpen: false,
  job: {},
};
