import { List, ListPlus, ListX } from 'lucide-react';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import pluralize from 'pluralize';
import { Box } from 'rebass/styled-components';
import { trackEvent } from 'helpers/analytics';
import { contactDataSelection } from 'services/contacts/selectedRows';
import toast from 'react-hot-toast';
import { useBulkNotificationToast } from '../../../hooks/useBulkNotificationToast';
import { activeNetworkSelector } from '../../../redux/selectors';
import {
  useAddContactsToSharedListMutation,
  useCreateSharedListMutation,
  useLazyGetSharedListsQuery,
  useRemoveContactsFromSharedListMutation,
} from '../../../services/contacts';
import { addContactsToSharedList, removeContactsFromSharedLists } from './contactsInSharedLists';
import BulkActionsModal from './bulkActionsModal';

export const ActionLists = ({ queryParams, contactIds, selectedRows, meta, sharedList, page, setSelectedRows }) => {
  const [addContactsToSharedListApi, { isLoading: isAdding }] = useAddContactsToSharedListMutation();
  const [removeContactsFromSharedListApi, { isLoading: isRemoving }] = useRemoveContactsFromSharedListMutation();
  const { toast: notificationToast } = useBulkNotificationToast(({ type }) => {
    const { totalCount } = contactDataSelection(selectedRows, contactIds, meta.queryTotal);

    toast(`${totalCount} ${pluralize('contact', totalCount)} ${type === 'add' ? 'added to' : 'removed from'} list`, {
      icon: 'success',
      id: 'bulk-actions',
    });
  });
  const activeNetwork = useSelector(activeNetworkSelector);
  const { listId } = useParams();
  const [getSharedLists, { data, isFetching }] = useLazyGetSharedListsQuery();
  const [createSharedList, { isLoading: isCreating }] = useCreateSharedListMutation();
  const ref = useRef();

  const onAddContactsFromSharedLists = async (list) => {
    await addContactsToSharedList({
      list,
      queryParams,
      activeNetwork,
      contactIds,
      meta,
      selectedRows,
      addContactsToSharedList: addContactsToSharedListApi,
      notificationToast,
      listId: page === 'list' ? listId : null,
    });
    ref.current?.close?.();
  };

  const onRemoveContactsFromSharedLists = async (list) => {
    await removeContactsFromSharedLists({
      list,
      queryParams,
      activeNetwork,
      contactIds,
      meta,
      selectedRows,
      removeContactsFromSharedList: removeContactsFromSharedListApi,
      notificationToast,
      listId,
    });
    ref.current?.close?.();
  };

  const onCreateSharedList = async (listName) => {
    const { data: list } = await createSharedList({ name: listName });
    trackEvent('contacts:list:create_list', {
      collection_id: activeNetwork.id,
    });
    await onAddContactsFromSharedLists(list.sharedList);
  };

  const actions = [
    ...(sharedList?.id && page === 'list:add'
      ? [
          {
            icon: ListPlus,
            onClick: () => {
              onAddContactsFromSharedLists(sharedList);
            },
            text: `Add to ${sharedList.name}`,
            disableModal: true,
          },
        ]
      : []),
    ...(sharedList?.id
      ? [
          {
            icon: ListX,
            onClick: () => {
              onRemoveContactsFromSharedLists(sharedList);

              if (page !== 'list:add') {
                setSelectedRows([]);
              }
            },
            text: `Remove from ${sharedList.name}`,
            disableModal: true,
          },
        ]
      : []),
    ...(sharedList?.id
      ? [
          {
            text: 'divider',
          },
        ]
      : []),
    {
      icon: ListPlus,
      onClick: getSharedLists,
      text: 'Add to list',
      title: `Add ${selectedRows.length} ${pluralize('contact', selectedRows.length)} to...`,
      onSelect: ({ value }) => onAddContactsFromSharedLists(value),
      showOnCreateIfNotExists: true,
      emptyText: (
        <>
          <Box as="p">No lists created yet.</Box>
          <Box as="p">Start typing to create a new list.</Box>
        </>
      ),
    },
    {
      icon: ListX,
      onClick: getSharedLists,
      text: 'Remove from list',
      title: `Remove ${selectedRows.length} ${pluralize('contact', selectedRows.length)}  from...`,
      onSelect: ({ value }) => onRemoveContactsFromSharedLists(value),
      emptyText: <Box as="p">No lists created yet.</Box>,
      showInputOnEmptyList: false,
    },
  ];

  return (
    <BulkActionsModal
      ref={ref}
      onCreateIfNotExists={onCreateSharedList}
      isCreating={isCreating || isRemoving || isAdding}
      title="Manage lists"
      icon={List}
      isLoading={isFetching}
      useLocalSearch={false}
      onSearch={(value) =>
        getSharedLists({
          keyword: value,
        })
      }
      options={data?.sharedLists?.map((list) => ({
        label: list.name,
        value: list,
      }))}
      actions={actions}
      newItemText="list"
    />
  );
};

ActionLists.propTypes = {
  queryParams: PropTypes.object,
  contactIds: PropTypes.array,
  meta: PropTypes.object,
  selectedRows: PropTypes.array,
  sharedList: PropTypes.object,
  page: PropTypes.string.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
};

ActionLists.defaultProps = {
  queryParams: {},
  contactIds: [],
  meta: {},
  selectedRows: [],
  sharedList: null,
};
