import { Field, Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormInput } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import * as Yup from 'yup';
import { trackEvent } from 'helpers/analytics';

const AuthModal = ({ networkName, onEnterPassword }) => {
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const formSchema = Yup.object().shape({
    password: Yup.string().required('Password is a required field'),
  });

  const onSubmit = async ({ password }) => {
    setIsLoading(true);

    const { isError } = await onEnterPassword(password);

    setIsLoading(false);

    if (isError) {
      formRef.current?.setErrors({ password: 'Password is not correct, please try again' });
    } else {
      trackEvent('contacts:shared_list:submit_password_successfully');
    }
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      formRef.current?.handleSubmit();
    }
  };

  return (
    <Flex sx={{ justifyContent: 'center', alignItems: 'center', mt: '120px' }}>
      <Flex sx={{ width: '400px', flexDirection: 'column', gap: '32px' }}>
        <Flex sx={{ flexDirection: 'column', gap: '4px' }}>
          <Box as="h1" fontSize="22px" fontWeight="600">
            This list is password-protected
          </Box>
          <Box>Enter the password or reach out to your contact at {networkName} for assistance if needed.</Box>
        </Flex>
        <Formik innerRef={formRef} initialValues={{ password: '' }} validationSchema={formSchema} onSubmit={onSubmit}>
          <Form>
            <Flex
              sx={{
                flexDirection: 'column',
                gap: '16px',
                button: {
                  'div:last-child': {
                    width: '100%',
                    justifyContent: 'center',
                  },
                },
              }}
            >
              <Field
                component={FormInput}
                name="password"
                type="password"
                placeholder="Enter password"
                onKeyPress={onKeyPress}
              />
              <Button loading={isLoading} type="submit">
                Submit
              </Button>
            </Flex>
          </Form>
        </Formik>
      </Flex>
    </Flex>
  );
};

AuthModal.propTypes = {
  networkName: PropTypes.string,
  onEnterPassword: PropTypes.func,
};

AuthModal.defaultProps = {
  networkName: '',
  onEnterPassword: () => {},
};

export default AuthModal;
