import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Message, Popover } from '@getro/rombo';
import ContentLoader from 'react-content-loader';
import { Info } from 'lucide-react';
import { useTheme } from 'styled-components';

import { ContactsUpgradeBanner } from '../../components/molecules/contactsUpgradeBanner';
import { ContactsImportBanner } from '../../components/molecules/contactsImportBanner';

export const Hero = ({ network, meta, isLoading }) => {
  const { colors } = useTheme();

  return (
    <>
      <ContactsUpgradeBanner id="hires" mb="16px" network={network} />
      <Flex sx={{ gap: '16px' }} flexDirection={['column', 'row']}>
        <Box flex="1" p="24px" bg="neutral.20" sx={{ borderRadius: 'default', position: 'relative' }}>
          {isLoading ? (
            <Flex height="40px" pt="12px">
              <ContentLoader
                speed={2}
                width={400}
                height={160}
                viewBox="0 0 400 160"
                backgroundColor={colors.neutral['100']}
                foregroundColor={colors.neutral['200']}
              >
                <rect x="0" y="0" rx="3" ry="3" width="64" height="16" />
              </ContentLoader>
            </Flex>
          ) : (
            <Text data-testid="data-hires-influenced" fontSize="28px" fontWeight="semibold" color="text.dark">
              {meta?.influenced || '-'}
            </Text>
          )}
          <Text mt="8px" fontWeight="medium">
            Team assisted hires
          </Text>
          <Text mt="4px" fontSize="14px" color="text.subtle">
            Contacts your team helped hire.
          </Text>
          <Box sx={{ position: 'absolute', top: '16px', right: '16px' }}>
            <Popover
              content={
                <>
                  <Text fontSize="14px">Track what hires your team directly influenced.</Text>
                  <Text mt="8px" fontSize="14px">
                    Ex.: You made an introduction, you sourced candidates for a company, you were part of the interview
                    process.
                  </Text>
                </>
              }
              trigger={
                <Flex
                  width="32px"
                  height="32px"
                  alignItems="center"
                  justifyContent="center"
                  color="text.subtle"
                  sx={{ cursor: 'pointer' }}
                >
                  <Box as={Info} width="16px" height="16px" aria-hidden="true" />
                </Flex>
              }
            />
          </Box>
        </Box>
        <Box flex="1" p="24px" bg="neutral.20" sx={{ borderRadius: 'default', position: 'relative' }}>
          {isLoading ? (
            <Flex height="40px" pt="12px">
              <ContentLoader
                speed={2}
                width={400}
                height={160}
                viewBox="0 0 400 160"
                backgroundColor={colors.neutral['100']}
                foregroundColor={colors.neutral['200']}
              >
                <rect x="0" y="0" rx="3" ry="3" width="64" height="16" />
              </ContentLoader>
            </Flex>
          ) : (
            <Text data-testid="data-hires-total" fontSize="28px" fontWeight="semibold" color="text.dark">
              {meta.hiresTotal || '-'}
            </Text>
          )}
          <Text mt="8px" fontWeight="medium">
            Total hires
          </Text>
          <Text mt="4px" fontSize="14px" color="text.subtle">
            All Contacts hired by a company in your network.
          </Text>
          <Box sx={{ position: 'absolute', top: '16px', right: '16px' }}>
            <Popover
              content={
                <>
                  <Text fontSize="14px">
                    Total Hires gives you a complete view of all Contacts that were hired by one of your listed
                    companies.
                  </Text>
                  <Text mt="8px" fontSize="14px">
                    We only report hires from your Contacts (incl. talent sign ups). We cannot report if visitors to
                    your job board were hired.
                  </Text>
                </>
              }
              trigger={
                <Flex
                  width="32px"
                  height="32px"
                  alignItems="center"
                  justifyContent="center"
                  color="text.subtle"
                  sx={{ cursor: 'pointer' }}
                >
                  <Box as={Info} width="16px" height="16px" aria-hidden="true" />
                </Flex>
              }
            />
          </Box>
        </Box>
      </Flex>
      <ContactsImportBanner mt="16px" network={network} />
      {meta?.pendingReview ? (
        <Message mt="16px" type="warning">
          {`${meta.pendingReview} hire${
            meta.pendingReview === 1 ? '' : 's'
          } pending review below - Review the table below to update your Hires Report.`}
        </Message>
      ) : null}
    </>
  );
};

Hero.propTypes = {
  meta: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  network: PropTypes.object,
};

Hero.defaultProps = {
  isLoading: false,
  network: {},
};
