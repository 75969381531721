import React, { useEffect, useRef, useState } from 'react';
import { Search } from '@getro/rombo';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Box } from 'rebass/styled-components';
import { post } from '../../../api';
import { trackException } from '../../../helpers/errorTracker';

const LOCATIONS = { page: 1, perPage: 20 };

const LocationsFilter = ({ value, onChange, embeddedInModal, onDropDownStateChange }) => {
  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(false);

  // Cancel api calls on unmount
  const cancelTokenSource = useRef(axios.CancelToken.source());
  useEffect(
    () => () => {
      cancelTokenSource.current.cancel();
    },
    [],
  );

  const handleLocationsSearch = async (query) => {
    if (!query.length) {
      return setLocations([]);
    }

    setLocationsLoading(true);
    try {
      const response = await post(
        `/locations/search/`,
        {
          q: {
            name: query,
            ...LOCATIONS,
          },
        },
        { cancelToken: cancelTokenSource.current.token },
      );

      return response.data.items.map(({ placeId, name }) => ({
        value: placeId,
        label: name,
      }));
    } catch (error) {
      trackException(error);
      setLocations([]);
      setLocationsLoading(false);

      return [];
    }
  };

  return (
    <Box onMouseEnter={() => onDropDownStateChange('open')} onMouseLeave={() => onDropDownStateChange('close')}>
      <Search
        data-testid="Locations"
        loading={locationsLoading}
        multiple
        name="locations"
        label="Locations"
        arial-label="Locations"
        onChange={onChange}
        onSearch={handleLocationsSearch}
        options={locations}
        placeholder="Add locations"
        value={value}
        orientation="horizontal"
        anchorToBody={embeddedInModal}
        sx={{ '.Search__control': { borderColor: 'neutral.400' } }}
      />
    </Box>
  );
};

LocationsFilter.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
};

LocationsFilter.defaultProps = {
  value: [],
  embeddedInModal: false,
  onDropDownStateChange: () => {},
};

export default LocationsFilter;
