import { createActions } from 'redux-actions';

export const { loadMemberSkillOptions, loadedMemberSkillOptions, errorMemberSkillOptions } = createActions(
  'LOAD_MEMBER_SKILL_OPTIONS',
  'LOADED_MEMBER_SKILL_OPTIONS',
  'ERROR_MEMBER_SKILL_OPTIONS',
);

export const { loadMemberLocationOptions, loadedMemberLocationOptions, errorMemberLocationOptions } = createActions(
  'LOAD_MEMBER_LOCATION_OPTIONS',
  'LOADED_MEMBER_LOCATION_OPTIONS',
  'ERROR_MEMBER_LOCATION_OPTIONS',
);

export const { loadMemberVouchedByOptions, loadedMemberVouchedByOptions, errorMemberVouchedByOptions } = createActions(
  'LOAD_MEMBER_VOUCHED_BY_OPTIONS',
  'LOADED_MEMBER_VOUCHED_BY_OPTIONS',
  'ERROR_MEMBER_VOUCHED_BY_OPTIONS',
);

export const { loadMembers, loadedMembers, errorMembers } = createActions(
  'LOAD_MEMBERS',
  'LOADED_MEMBERS',
  'ERROR_MEMBERS',
);

export const { loadAddRemoveMemberToList, loadedAddRemoveMemberToList, errorAddRemoveMemberToList } = createActions(
  'LOAD_ADD_REMOVE_MEMBER_TO_LIST',
  'LOADED_ADD_REMOVE_MEMBER_TO_LIST',
  'ERROR_ADD_REMOVE_MEMBER_TO_LIST',
);

export const { updateMemberById } = createActions('UPDATE_MEMBER_BY_ID');

export const { loadCreateAndAddMemberToList, loadedCreateAndAddMemberToList, errorCreateAndAddMemberToList } =
  createActions(
    'LOAD_CREATE_AND_ADD_MEMBER_TO_LIST',
    'LOADED_CREATE_AND_ADD_MEMBER_TO_LIST',
    'ERROR_CREATE_AND_ADD_MEMBER_TO_LIST',
  );

export const { loadCreateBoard, loadedCreateBoard, errorCreateBoard } = createActions(
  'LOAD_CREATE_BOARD',
  'LOADED_CREATE_BOARD',
  'ERROR_CREATE_BOARD',
);

export const { setCurrentBoard, editBoard, createNewBoard, showBoardSettings } = createActions(
  'SET_CURRENT_BOARD',
  'EDIT_BOARD',
  'CREATE_NEW_BOARD',
  'SHOW_BOARD_SETTINGS',
);

export const { loadBoardSettings, loadedBoardSettings, errorBoardSettings } = createActions(
  'LOAD_BOARD_SETTINGS',
  'LOADED_BOARD_SETTINGS',
  'ERROR_BOARD_SETTINGS',
);

export const { deleteBoard, loadDeleteBoard, loadedDeleteBoard, errorDeleteBoard } = createActions(
  'DELETE_BOARD',
  'LOAD_DELETE_BOARD',
  'LOADED_DELETE_BOARD',
  'LOADED_DELETE_BOARD',
);

export const { loadExportMembers, loadedExportMembers, errorExportMembers } = createActions(
  'LOAD_EXPORT_MEMBERS',
  'LOADED_EXPORT_MEMBERS',
  'ERROR_EXPORT_MEMBERS',
);

export const { loadMemberDetails, loadedMemberDetails, errorMemberDetails } = createActions(
  'LOAD_MEMBER_DETAILS',
  'LOADED_MEMBER_DETAILS',
  'ERROR_MEMBER_DETAILS',
);

export const { resetVouchMember, loadVouchMember, loadedVouchMember, errorVouchMember } = createActions(
  'RESET_VOUCH_MEMBER',
  'LOAD_VOUCH_MEMBER',
  'LOADED_VOUCH_MEMBER',
  'ERROR_VOUCH_MEMBER',
);

export const { boardChange, setFiltersModal, updatedBoard, createdNewBoard } = createActions(
  'BOARD_CHANGE',
  'SET_FILTERS_MODAL',
  'UPDATED_BOARD',
  'CREATED_NEW_BOARD',
);

export const { shareBoard } = createActions('SHARE_BOARD');
