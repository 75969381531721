import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from '../useInjectReducer';
import { key, storedValueSelector } from './selectors';
import reducer from './reducer';
import { setLoadStorageValue } from './actions';

export const useConnectedLocalStorage = (storeKey, initialValue, options = { updateState: true }) => {
  useInjectReducer({ key, reducer });

  const dispatch = useDispatch();
  const reduxValue = useSelector(storedValueSelector(storeKey));
  const storedValue = useMemo(() => reduxValue || initialValue, [reduxValue, initialValue]);

  const retrieveValueFromLocalStorage = useCallback(() => {
    const item = localStorage.getItem(storeKey);
    return item ? JSON.parse(item) : initialValue;
  }, [initialValue, storeKey]);

  useEffect(() => {
    const value = retrieveValueFromLocalStorage();
    dispatch(setLoadStorageValue({ key: storeKey, value }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const storeValue = useCallback(
    (value) => {
      localStorage.setItem(storeKey, JSON.stringify(value));

      if (options?.updateState) {
        dispatch(setLoadStorageValue({ key: storeKey, value }));
      }
    },
    [storeKey, options?.updateState, dispatch],
  );

  return [storedValue, storeValue];
};
