import React from 'react';
import { Text, Button } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { openChat } from '../../../helpers/supportChat';

const ContactUs = ({ title }) => (
  <Text fontSize="1" color="text.subtle" marginTop={[2]} sx={{ fontStyle: 'italic' }}>
    If the {title} has changed,{' '}
    <Button
      type="button"
      variant="link"
      fontSize="1"
      color="purple.300"
      sx={{
        textDecoration: 'underline',
        fontStyle: 'italic',
      }}
      p="0"
      onClick={openChat}
    >
      contact us
    </Button>
    .
  </Text>
);

ContactUs.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ContactUs;
