import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass/styled-components';

const CurrencyOptionLabel = ({ label, description }) => (
  <Flex
    alignment="flex-start"
    flexDirection="row"
    px="3"
    py="3"
    alignItems="baseline"
    className="currency-option-label"
    sx={{ cursor: 'pointer' }}
  >
    <Text
      sx={{
        color: 'text.main',
        fontSize: 2,
        fontWeight: 'body',
      }}
    >
      {label}
    </Text>
    <Text
      ml={2}
      className="description"
      sx={{
        color: 'text.subtle',
        fontSize: 1,
        overflow: 'hidden',
        fontWeight: 'body',
      }}
    >
      {description}
    </Text>
  </Flex>
);

CurrencyOptionLabel.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default CurrencyOptionLabel;
