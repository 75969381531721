import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass/styled-components';

const HeaderButtonContent = ({ children }) => (
  <Flex
    sx={{
      flexDirection: 'row',
      justifyContent: ['center', 'flex-start', 'flex-start'],
      width: '100%',
    }}
    alignItems="center"
    alignContent="center"
  >
    {children}
  </Flex>
);

HeaderButtonContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default HeaderButtonContent;
