import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Panel } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Download } from 'lucide-react';

import { activeNetworkSelector } from '../../../../redux/selectors';
import { post } from '../../../../api';
import { trackEvent } from '../../../../helpers/analytics';
import { trackException } from '../../../../helpers/errorTracker';
import ExportModal from '../../../../components/molecules/ExportModal';

const ExportData = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { id: activeNetworkId } = activeNetwork;
  const talentNetworkActive = activeNetwork.features.includes('talent_network');

  // Handle Download Click
  const exportCancelToken = useRef(axios.CancelToken.source());
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportSuccess, setExportSuccess] = useState(false);
  const [exportError, setExportError] = useState(false);

  const handleDownloadClick = async () => {
    setShowExportModal(true);
    try {
      let apiUrl = `/collections/${activeNetworkId}/settings/job_board/export_admins`;
      if (talentNetworkActive) {
        apiUrl = `/collections/${activeNetworkId}/settings/talent_network/export_members`;
      }
      await post(apiUrl, {
        cancelToken: exportCancelToken.current.token,
      });
      setExportSuccess(true);
      let eventName = 'network_settings:export_admins_data';
      if (talentNetworkActive) {
        eventName = 'network_settings:export_members_data';
      }
      trackEvent(eventName);
    } catch (error) {
      trackException(error);
      setExportError(true);
    }
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    setExportSuccess(false);
    setExportError(false);
  };

  // Prepare content
  let boxTitle = 'Admin list export';
  let content = (
    <Text>
      Export a list of everyone who can manage information displayed on your job board. This includes all network admins
      and company admins.{' '}
      <a
        href="https://help.getro.com/support/solutions/articles/65000168380-how-to-get-a-list-of-all-getrojobs-admins"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
      .
    </Text>
  );
  let buttonText = 'Export admin list';
  let dataCy = 'export-data-admins';
  if (talentNetworkActive) {
    boxTitle = 'Member list export';
    content = (
      <Text>
        Export a list of all network admins, company admins, ambassadors and talent members in your network.{' '}
        <a
          href="https://help.getro.com/support/solutions/articles/65000168387-how-to-get-a-list-of-all-getronetwork-members"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
        .
      </Text>
    );
    buttonText = 'Export members list';
    dataCy = 'export-data-members';
  }

  return (
    <Panel title={boxTitle}>
      {content}
      <Button mt={3} variant="secondary" onClick={handleDownloadClick} data-cy={dataCy}>
        <Flex sx={{ columnGap: '10px' }} alignItems="center">
          <Box as={Download} height="16px" width="16px" aria-hidden="true" />
          {buttonText}
        </Flex>
      </Button>
      {showExportModal && (
        <ExportModal
          error={exportError}
          success={exportSuccess}
          successTitle="An export is coming your way!"
          successContent="We’ve sent you an email. It may take a few minutes."
          onClose={closeExportModal}
        />
      )}
    </Panel>
  );
};

export default ExportData;
