import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Message, MessageBlock, Search, Button } from '@getro/rombo';

import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';
import helperGet from 'helpers/get';
import { openChat } from 'helpers/supportChat';

import Modal from 'components/atoms/Modal';

import { get } from 'api';

import VerifyYourAccount from '../VerifyYourAccount';

import { networksSelector, userSelector } from '../../../redux/selectors';
import NetworkSchema from '../../../schemas/network';

function SelectYourCompany({ network }) {
  const networksStore = useSelector(networksSelector);
  const user = useSelector(userSelector);
  const history = useHistory();
  const [chatError, setChatError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [showVerifyYourAccount, setShowVerifyYourAccount] = useState(false);
  const isTalentNetworkActive = network.features.includes('talent_network');

  const { email, id, confirmed } = user;
  const { networks } = networksStore;

  const title = `Claim your company profile on the ${network.name} ${
    isTalentNetworkActive ? 'talent network' : 'job board'
  }`;
  let subtitle = 'Manage company information and job opportunities to get discovered by job seekers.';
  if (isTalentNetworkActive) {
    subtitle = 'Claim your company profile and discover professionals we trust.';
  }

  const handleSearch = async (query) => {
    try {
      const response = await get(`/collections/${network.id}/organizations_preview`, {
        params: {
          keyword: query,
          exclude_organizations_without_domain: true,
          page: 1,
          perPage: 200,
        },
      });

      // eslint-disable-next-line no-shadow
      return response.data.items.map(({ id, slug, logoUrl, name, domain }) => ({
        value: id,
        label: name,
        slug,
        description: domain,
        avatarUrl: logoUrl,
      }));
    } catch (error) {
      trackException(error);
      return [];
    }
  };

  const handleContactUs = () => {
    openChat(() => setChatError(true));
  };

  const handleChange = ({ target }) => {
    // Rename value and label keys and set the new object
    const { value, label, ...rest } = target.value;
    setSelected({ id: value, name: label, ...rest });
  };

  const handleSelectedChange = () => {
    let isManager = false;

    const managingOrganizations = helperGet(networks, `${network.id}.isOrganizationManager`, false);
    if (managingOrganizations && Object.values(managingOrganizations).some((n) => n.id === selected.id)) {
      isManager = true;
    }

    if (!isManager) {
      if (email && !new RegExp(`@${selected.description}$`).test(email)) {
        trackEvent('employer_onboarding:different_domain', {
          organization_id: selected.id,
          collection_id: network.id,
        });
        return setShowModal(true);
      }

      if (id && !isManager && !confirmed && selected) {
        return setShowVerifyYourAccount(true);
      }
    }

    return history.push(`/claim-your-profile/${network.slug}/company/${selected.slug}`);
  };

  useEffect(() => {
    if (!selected || !selected.id) {
      return;
    }

    handleSelectedChange();
    // eslint-disable-next-line
  }, [selected])

  const closeModal = () => {
    setSelected(null);
    setShowModal(false);
  };

  const footerContent = (
    <>
      If you do not have a {selected ? selected.name : ''} email, but think you should be able to claim this company
      profile, please contact{' '}
      <a href="mailto:help@getro.com" target="_blank" rel="noopener noreferrer">
        help@getro.com
      </a>
      .
    </>
  );

  const content = 'To claim the [company] profile you must use your company email address.'.replace(
    '[company]',
    selected ? selected.name : '',
  );

  if (showVerifyYourAccount) {
    return <VerifyYourAccount email={email} organization={selected} networkId={network.id} />;
  }

  return (
    <div className="SelectYourCompany">
      <Modal open={showModal} simple>
        <MessageBlock
          title="We need to verify your permissions"
          type="warning"
          content={content}
          footerContent={footerContent}
          buttonText="Ok"
          onClick={closeModal}
        />
      </Modal>
      <div className="SelectYourCompany__header">
        <h2 className="SelectYourCompany__headline">{title}</h2>
      </div>
      <div className="SelectYourCompany__subheader">{subtitle}</div>

      <div className="EmployerOnboarding__row">
        <div className="EmployerOnboarding__full">
          {/* eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */}
          <label className="SelectYourCompany__label">Select your company</label>
          <Search
            name="org"
            onChange={handleChange}
            onSearch={handleSearch}
            placeholder="Start typing..."
            size="lg"
            value={selected}
            showAvatar="company"
          />

          <p className="SelectYourCompany__tip">
            {`Can’t find your company? If it belongs to ${network.name} `}
            <Button variant="underlineLink" p={0} m={0} display="inline-flex" onClick={handleContactUs}>
              contact us
            </Button>
            .
          </p>
        </div>
      </div>

      {chatError && (
        <div className="EmployerOnboarding__row">
          <div className="EmployerOnboarding__full">
            <Message type="info">
              There was an error openning our chat, please contact us at{' '}
              <a href="mailto:help@getro.com" target="_blank" rel="noopener noreferrer">
                help@getro.com
              </a>
              .
            </Message>
          </div>
        </div>
      )}
    </div>
  );
}

SelectYourCompany.propTypes = {
  network: NetworkSchema.isRequired,
};

export default SelectYourCompany;
