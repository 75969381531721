import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Checkbox, Loader, AsyncButton, Button, OnboardingLayoutContent } from '@getro/rombo';
import OnboardingContext from 'components/layout/EmployerOnboarding/onboardingContext';
import { Box, Card, Flex, Image, Text } from 'rebass/styled-components';
import { Minus, Plus } from 'lucide-react';
import EffortlessBell from '../../../assets/icon-effortless-bell.svg';
import {
  loadSetAutoSubscribe,
  loadSetDailyAlerts,
  loadSubscribeAll,
  loadSubscriptions,
  loadUnsubscribeAll,
  setShowDeactivateConfirmation,
  toggleBoardSubscription,
} from '../../manageSubscriptions/actions';
import { BoardSubscription } from '../../../components/molecules/boardSubscription';
import { Toggle } from '../../../components/atoms/toggleDeprecated';
import { activeNetworkSelector, makeHasAutoSubscribe, makeHasDailyAlerts } from '../../../redux/selectors';
import { makeSelectIsLoading } from '../../../redux/loadingSelector';
import { key, makeSelectBoards, makeShowDeactivateConfirmation } from '../../manageSubscriptions/selectors';
import { useInjectReducer } from '../../../hooks/useInjectReducer';
import { useInjectSaga } from '../../../hooks/useInjectSaga';
import reducer from '../../manageSubscriptions/reducer';
import saga from '../../manageSubscriptions/saga';
import useTalentGroups from '../../../hooks/useTalentGroups';
import { SimplifiedModal } from '../../../components/atoms/simplifiedModal';

const handleSubmit = (step, updateStep) => () => updateStep(step + 1);

export const TalentBoards = ({ title, onBack }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const { updateStep, step } = useContext(OnboardingContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const hasDailyAlerts = useSelector(makeHasDailyAlerts);
  const activeNetwork = useSelector(activeNetworkSelector);
  const isLoadingTalentGroups = useSelector(makeSelectIsLoading('talentGroups'));
  const showDeactivateConfirmation = useSelector(makeShowDeactivateConfirmation);
  const isLoadingSetDailyAlerts = useSelector(makeSelectIsLoading('setDailyAlerts'));
  const boards = useSelector(makeSelectBoards);
  const isLoadingSetAutoSubscribe = useSelector(makeSelectIsLoading('setAutoSubscribe'));
  const hasAutoSubscribe = useSelector(makeHasAutoSubscribe);
  const isLoadingSetSubscribeAll = useSelector(makeSelectIsLoading('subscribeAll'));
  const isLoadingSetUnsubscribeAll = useSelector(makeSelectIsLoading('unsubscribeAll'));
  useTalentGroups({ id: activeNetwork?.id });

  useEffect(() => {
    dispatch(loadSubscriptions());
  }, [dispatch]);

  const showDailyAlertsToggle = () => {
    if (showDeactivateConfirmation) {
      return true;
    }
    return !isLoadingSetDailyAlerts;
  };

  return (
    <OnboardingLayoutContent title={title} onBack={onBack} onNextStep={handleSubmit(step, updateStep)}>
      <p>
        You can subscribe to lists of talent relevant to you. Select a few to stay updated on new talent that comes your
        way!
      </p>
      <Card variant="card.default">
        <Flex flexDirection="column">
          <Box pb={3} width={[1]} variant="header.bottomBorder">
            <Text as="h2">Get alerts when new talent are added to the network</Text>
          </Box>
          {hasDailyAlerts && (
            <Card bg="gray.5" p={3} mt={4} sx={{ boxShadow: 'none' }}>
              <Flex alignItems="stretch" m={1}>
                <Box pr={2} alignItems="center" justifyContent="center" alignContent="center" display="flex">
                  <Image src={EffortlessBell} width="80px" />
                </Box>
                <Box flexGrow={1} pl={2}>
                  <Text variant="default" fontSize={3} fontWeight="semibold" color="text.dark" as="h3" pb={2}>
                    Hooray! You’re now subscribed to alerts from the {activeNetwork.name} network
                  </Text>
                  <Text variant="default" fontSize={2} color="text.dark">
                    While you’re here, select more lists to follow:
                  </Text>
                </Box>
              </Flex>
            </Card>
          )}
          <Box pt={3}>
            <Text pb={2}>
              You can subscribe to lists of talent relevant to you. Select a few to stay updated on new talent that
              comes your way!
            </Text>
            {isLoadingTalentGroups && (
              <Box mt={3}>
                <Loader />
              </Box>
            )}
            {!isLoadingTalentGroups && (
              <Card p={0} pb="1px" mt={3} variant="card.innerWell">
                <Flex flexDirection="column">
                  <Box px={3} py={3} pb={hasDailyAlerts ? 3 : 2}>
                    {showDailyAlertsToggle() && (
                      <Toggle
                        name="hasDailyAlerts"
                        label="Get daily alerts of members added to lists"
                        onChange={(e) => {
                          const { checked } = e.target;
                          if (checked || boards.subscribed === 0) {
                            dispatch(loadSetDailyAlerts({ checked, dispatch }));
                          } else {
                            dispatch(setShowDeactivateConfirmation(true));
                          }
                        }}
                        checked={hasDailyAlerts}
                      />
                    )}
                    {!showDailyAlertsToggle() && (
                      <Flex>
                        <Box mr={3}>
                          <Loader style={{ height: '24px', width: '24px' }} />{' '}
                        </Box>
                        <Text>Get daily alerts of members added to lists</Text>
                      </Flex>
                    )}
                  </Box>
                  {hasDailyAlerts && boards && boards.options && (
                    <Flex mt={3} mb={boards.options.length > 0 ? 0 : 2} flexDirection="column">
                      <Box px={3} width={[1]}>
                        <Checkbox
                          isLoading={isLoadingSetAutoSubscribe}
                          pb={0}
                          checked={hasAutoSubscribe}
                          name="autoSubscribe"
                          id="autoSubscribe"
                          label="Automatically subscribe to new lists when they are created"
                          onChange={(e) => {
                            const { checked } = e.target;
                            dispatch(loadSetAutoSubscribe({ checked }));
                          }}
                        />
                      </Box>
                      {boards.options.length > 0 && (
                        <Box px={3} pt={2} pb={2} width={[1]}>
                          <Flex alignItems="center">
                            <Text flexGrow={1}>
                              LISTS ({boards.subscribed} OF {boards.total})
                            </Text>
                            <Box>
                              <AsyncButton
                                display="inline-flex"
                                loading={isLoadingSetSubscribeAll}
                                onClick={() => dispatch(loadSubscribeAll())}
                                mr={1}
                              >
                                <Box mr={1} height="1em" width="1em" strokeWidth="3" aria-hidden="true" as={Plus} />
                                Add all
                              </AsyncButton>
                              <AsyncButton
                                display="inline-flex"
                                loading={isLoadingSetUnsubscribeAll}
                                onClick={() => dispatch(loadUnsubscribeAll())}
                                ml={1}
                                variant="secondary"
                              >
                                <Box mr={1} height="1em" width="1em" strokeWidth="3" aria-hidden="true" as={Minus} />
                                Remove all
                              </AsyncButton>
                            </Box>
                          </Flex>
                        </Box>
                      )}
                      {boards.options.length > 0 && (
                        <Flex mt={3} flexDirection="column">
                          <Box width={[1]}>
                            {boards.options.map((board) => (
                              <BoardSubscription
                                key={board.id}
                                board={board}
                                onSubscribe={() => dispatch(toggleBoardSubscription({ board, subscribe: true }))}
                                onUnsubscribe={() => dispatch(toggleBoardSubscription({ board, subscribe: false }))}
                              />
                            ))}
                          </Box>
                        </Flex>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Card>
            )}
          </Box>
        </Flex>
      </Card>
      {showDeactivateConfirmation && (
        <SimplifiedModal
          variant="small"
          data-testid="deactivate-confirmation"
          onCancel={() => {
            dispatch(setShowDeactivateConfirmation(false));
          }}
          isOpen
          title="Deactivate daily digest?"
          actions={
            <Flex justifyContent={['flex-end']}>
              <Button
                mr={[2]}
                onClick={() => {
                  dispatch(setShowDeactivateConfirmation(false));
                }}
                variant="secondary"
                sx={{ border: 0 }}
              >
                No, don&apos;t clear my subscriptions
              </Button>
              <AsyncButton
                loading={isLoadingSetDailyAlerts}
                ml={[2]}
                onClick={() => {
                  dispatch(loadSetDailyAlerts({ checked: false, dispatch }));
                  history.replace({ search: '' });
                }}
              >
                Yes, clear my subscriptions
              </AsyncButton>
            </Flex>
          }
        >
          <Text>This will clear all of your subscription preferences. Are you sure you would like to continue?</Text>
        </SimplifiedModal>
      )}
    </OnboardingLayoutContent>
  );
};
TalentBoards.propTypes = {
  title: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};
