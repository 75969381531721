import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Message, Modal } from '@getro/rombo';
import { Clock, EyeOff, Lock, UserCheck, Zap } from 'lucide-react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { setCookie } from 'nookies';

import { EXTENSION_MESSAGE_TYPES, useLaunchScraper } from 'hooks/useLaunchScraper';
import { hasMatchesSelector, userSelector } from '../../../redux/selectors';
import { InstallChromeExtension } from './installChromeExtension';
import { ContactsFromLinkedinImportSuccessful } from './contactsFromLinkedinImportSuccessful';

export const ContactsModalImportFromLinkedin = ({ onClose, hasExtension, showFinishInfo }) => {
  const user = useSelector(userSelector) || {};
  const { launchScraper, importing, responseType, isExtensionInstalled } = useLaunchScraper(hasExtension);

  const handleOnLaunchExtension = async () => {
    setCookie(null, `${user.email}-getro_contacts_import_success`, 'true', {
      maxAge: 365 * 24 * 60 * 60,
    });

    const message = await launchScraper();

    if (message === EXTENSION_MESSAGE_TYPES.SCRAPER_INITIALIZED) {
      onClose();
    }
  };

  const items = [
    {
      icon: Zap,
      title: 'All your LinkedIn connections will be added as contacts.',
    },
    {
      icon: EyeOff,
      title: 'Added contacts will be visible to network admins only.',
    },
    {
      icon: UserCheck,
      title: 'Professional profiles will be enriched for every contact.',
    },
    {
      icon: Lock,
      title: 'Your password is not stored.',
    },
    {
      icon: Clock,
      title: 'The initial process takes a few seconds. Enriching their professional profiles takes longer.',
    },
  ];

  const showImportProgress = responseType === EXTENSION_MESSAGE_TYPES.SEND_COOKIES_SUCCESS || showFinishInfo;
  const isMatchesEnabled = useSelector(hasMatchesSelector);

  return (
    <Modal
      isOpen
      variant="large"
      onCancel={onClose}
      actions={
        <Box>
          <Flex justifyContent="flex-end" mt="24px">
            {showImportProgress && <Button onClick={onClose}>Ok</Button>}
            {!showImportProgress && (
              <>
                <Box mr="16px">
                  <Button variant="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                </Box>
                <Button loading={importing} disabled={!isExtensionInstalled} onClick={handleOnLaunchExtension}>
                  Add my connections
                </Button>
              </>
            )}
          </Flex>
        </Box>
      }
    >
      {showImportProgress && <ContactsFromLinkedinImportSuccessful isMatchesEnabled={isMatchesEnabled} />}
      {!showImportProgress && (
        <>
          <Flex mt="8px" justifyContent="space-between">
            <Box flexGrow="1" pr="120px">
              <Text fontSize="18px" color="text.dark" fontWeight="600">
                Add your LinkedIn connections
              </Text>
              <Text mt="8px" fontWeight="400">
                Organize and automatically get matches for all your team’s network in Getro.
              </Text>
            </Box>
          </Flex>
          <Flex as="ul" mt="40px" flexDirection="column" sx={{ gap: '16px', listStyle: 'none', pl: 0 }}>
            {items.map(({ icon, title }) => (
              <Flex key={title} as="li" alignItems="baseline">
                <Flex
                  flex="0 0 auto"
                  mr="16px"
                  alignItems="center"
                  color="purple.300"
                  sx={{ position: 'relative', top: '3px' }}
                >
                  <Box as={icon} width="16px" height="16px" aria-hidden="true" />
                </Flex>
                <Text as="span" fontSize="14px">
                  {title}
                </Text>
              </Flex>
            ))}
          </Flex>
          {responseType === EXTENSION_MESSAGE_TYPES.IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR && (
            <Box data-testid="linkedin-cookies-warning" pt="40px" sx={{ '& > div': { alignItems: 'center' } }}>
              <Message
                type="warning"
                content={
                  <Box fontSize="14px" lineHeight="1.4">
                    <Text as="p" fontWeight="500" mb="8px">
                      Make sure you’re logged in to LinkedIn
                    </Text>
                    <Text as="p">
                      Please go to{' '}
                      <a href="https://linkedin.com" target="__blank">
                        www.linkedin.com
                      </a>
                      , log in, and then try again.
                    </Text>
                  </Box>
                }
              />
            </Box>
          )}
          {!isExtensionInstalled && <InstallChromeExtension />}
        </>
      )}
    </Modal>
  );
};

ContactsModalImportFromLinkedin.propTypes = {
  showFinishInfo: PropTypes.bool,
  onClose: PropTypes.func,
  hasExtension: PropTypes.bool,
};

ContactsModalImportFromLinkedin.defaultProps = {
  showFinishInfo: false,
  hasExtension: false,
  onClose: () => {},
};
