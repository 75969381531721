import { Message } from '@getro/rombo';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Box, Link, Text } from 'rebass/styled-components';

import { activeNetworkSelector } from '../../redux/selectors';
import { talentNetworkSelector } from '../Settings/selectors';

export const BannerEnableTalentNetwork = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const talentNetwork = useSelector(talentNetworkSelector);

  return talentNetwork?.data?.tnHidden ? (
    <Message mb="40px">
      <Box pl="8px" py="8px">
        <Text fontWeight="medium" fontSize="1" color="text.dark">
          Talent network signup is disabled
        </Text>
        <Text mt="8px" fontSize="1" fontWeight="body">
          You can see people who signed up before but job board visitors can not signup. To keep growing your network
          passively, enable the signup from the{' '}
          <Link as={NavLink} color="text.main" to={`/networks/${activeNetwork.slug}/settings/contacts`} exact>
            settings screen
          </Link>
          .
        </Text>
      </Box>
    </Message>
  ) : (
    false
  );
};
