import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { activeNetworkSelector } from 'redux/selectors';
import PageLayout from 'components/layout/Page';

import NoAccess from './NoAccess';
import withMainLayout from '../../hocs/withMainLayout';
import AddBulkCompanyWithDomain from './addBulkCompanyWithDomain';
import usePageHook from '../../hooks/usePageHook';
import AddCompanyWithoutDomain from './addCompanyWithoutDomain';
import { resetAddCompany } from './redux/actions';
import { useInjectSaga } from '../../hooks/useInjectSaga';
import saga from './redux/sagas';
import { useInjectReducer } from '../../hooks/useInjectReducer';
import reducer from './redux/reducer';
import { key } from './redux/selectors';

const AddCompany = () => {
  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });
  usePageHook('Add company');
  const history = useHistory();
  const dispatch = useDispatch();
  const activeNetwork = useSelector(activeNetworkSelector);
  const [addWithoutDomain, setAddWithoutDomain] = useState(false);

  const goToCompaniesList = () => history.push(`/networks/${activeNetwork.slug}/companies`);
  const showAddCompanyWithoutDomain = () => setAddWithoutDomain(true);
  const showAddCompanyWithDomain = () => setAddWithoutDomain(false);

  useEffect(
    () => () => {
      dispatch(resetAddCompany());
    },
    [dispatch],
  );

  if (!activeNetwork.isManager) {
    return (
      <PageLayout>
        <PageLayout.Content>
          <NoAccess />
        </PageLayout.Content>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <PageLayout.Content>
        {!addWithoutDomain && (
          <AddBulkCompanyWithDomain
            activeNetwork={activeNetwork}
            goToCompaniesList={goToCompaniesList}
            showAddCompanyWithoutDomain={showAddCompanyWithoutDomain}
          />
        )}
        {addWithoutDomain && (
          <AddCompanyWithoutDomain
            activeNetwork={activeNetwork}
            goToCompaniesList={goToCompaniesList}
            showAddCompanyWithDomain={showAddCompanyWithDomain}
          />
        )}
      </PageLayout.Content>
    </PageLayout>
  );
};

export default withMainLayout(AddCompany);
