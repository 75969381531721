import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

const ColorPicker = ({ className, onClose, open, ...rest }) => {
  if (!open) {
    return null;
  }

  return (
    <div className={`ColorPicker ${className}`}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="ColorPicker__overlay" onClick={onClose} />
      <ChromePicker disableAlpha {...rest} />
    </div>
  );
};

ColorPicker.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

ColorPicker.defaultProps = {
  className: '',
  onClose: () => {},
  open: false,
};

export default ColorPicker;
