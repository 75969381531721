import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { AsyncButton, FormInput, Modal } from '@getro/rombo';
import { Field, Form, Formik } from 'formik';
import { Radio } from '@rebass/forms/styled-components';

const items = [
  'This company is not a focus right now',
  'This company is not hiring at the moment',
  'Concerned about potentially increasing job board cost',
  `Incorrect suggestion, this company isn't in our portfolio`,
  'Other',
];
const SkipSuggestedModal = ({ companyName, onSkip, companyId, close }) => {
  const [selected, setSelected] = useState();
  const [otherValue, setOtherValue] = useState();

  return (
    <Box sx={{ '& > div': { '& > div:last-child': { maxWidth: '800px' } } }}>
      <Modal
        onCancel={close}
        isOpen
        title="Skip suggested company"
        actions={
          <Flex justifyContent="flex-end" alignItems="baseLine" data-testid="interactive-renew-action-buttons">
            <AsyncButton onClick={close} variant="secondary" mr={2}>
              Cancel
            </AsyncButton>
            <AsyncButton
              onClick={() => {
                onSkip({ companyId, reason: selected, otherValue });
                close();
              }}
            >
              Skip company
            </AsyncButton>
          </Flex>
        }
      >
        <Text mb="24px" color="text.main" fontSize="16px" fontWeight="400" lineHeight="1.4">
          {companyName} won’t be added to your network and won’t be suggested again.
        </Text>
        <Box>
          <Text color="text.dark" fontSize="16px" fontWeight="500" lineHeight="24px">
            Why are you skipping this company?
          </Text>
          <Flex mt="16px" flexDirection="column" sx={{ gap: '16px' }}>
            {items.map((item) => (
              <Flex
                key={item}
                alignItems="center"
                onClick={() => setSelected(item)}
                sx={{ cursor: 'pointer', gap: '8px' }}
              >
                <Radio name={item} label={item} checked={selected === item} value={selected === item} />
                <Text>{item}</Text>
              </Flex>
            ))}
          </Flex>
        </Box>
        {selected === items[items.length - 1] && (
          <Formik initialValues={{ other: '' }}>
            {() => (
              <Form data-testid="alert-form">
                <Box mt="16px" sx={{ 'label + div': { color: 'text.main' } }}>
                  <Field
                    name="other"
                    type="text"
                    component={FormInput}
                    required
                    value={otherValue}
                    placeholder="Why you wan to skip this company?"
                    onChange={({ target }) => setOtherValue(target.value)}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </Box>
  );
};

SkipSuggestedModal.propTypes = {
  companyName: PropTypes.string.isRequired,
  onSkip: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default SkipSuggestedModal;
