import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AsyncButton, Message, Modal } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';

export const DeleteJobModal = ({ onCancel, isOpen, onDeleteJob, onCloseJob, isClosing, isDeleting }) => {
  const modalActions = useMemo(
    () => (
      <Flex justifyContent="flex-end" alignItems="baseLine" data-testid="interactive-renew-action-buttons">
        <AsyncButton onClick={onDeleteJob} variant="danger" mr={2} loading={isDeleting}>
          Delete permanently
        </AsyncButton>
        <AsyncButton variant="secondary" onClick={onCloseJob} loading={isClosing}>
          Close Job
        </AsyncButton>
      </Flex>
    ),
    [isClosing, isDeleting, onCloseJob, onDeleteJob],
  );
  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      actions={modalActions}
      data-testid="delete-jobs-modal"
      onCancel={onCancel}
      isOpen={isOpen}
      title="This will delete the job permanently"
    >
      <Box mb={3} as={Message} content="If this position has been filled, we recommend you close the job instead" />
      <Text mb={2}>We recommend only deleting jobs posted by mistake or for GDPR reasons.</Text>
      <Text mb={2}>Please make sure you understand:</Text>
      <Box m={0} as="ul">
        <Text as="li"> You will lose access to the job completely</Text>
        <Text as="li"> It will not be included in the analytics</Text>
        <Text as="li"> This cannot be undone</Text>
      </Box>
    </Modal>
  );
};

DeleteJobModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onDeleteJob: PropTypes.func.isRequired,
  onCloseJob: PropTypes.func.isRequired,
  isClosing: PropTypes.bool,
  isDeleting: PropTypes.bool,
};

DeleteJobModal.defaultProps = {
  isOpen: false,
  isClosing: false,
  isDeleting: false,
};
