import { takeLatest, put, call, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import strings from 'strings';
import {
  errorPublishSuggestedCompany,
  errorSkipSuggestedCompany,
  errorSuggestedCompanies,
  loadedPublishSuggestedCompany,
  loadedSkipSuggestedCompany,
  loadedSuggestedCompanies,
  loadPublishSuggestedCompany,
  loadSkipSuggestedCompany,
  loadSuggestedCompanies,
} from './actions';
import { activeNetworkSelector } from '../../../../redux/selectors';
import { get, del, post } from '../../../../api';
import { trackException } from '../../../../helpers/errorTracker';
export const PAGE_SIZE = 20;

function* loadSuggestedCompaniesEvent({ payload }) {
  const { page: currentPage } = payload;
  const { id: networkId } = yield select(activeNetworkSelector);
  const page = parseInt(currentPage || '1', 10);
  try {
    const response = yield call(get, `/collections/${networkId}/organizations`, {
      params: {
        status: 'suggested',
        perPage: PAGE_SIZE,
        page,
      },
    });
    const { data } = response;
    yield put(loadedSuggestedCompanies({ data, page }));
  } catch (error) {
    yield put(errorSuggestedCompanies());
    yield call(trackException, error);
  }
}

function* loadPublishSuggestedCompanyEvent({ payload: { companyId } }) {
  try {
    const { id } = yield select(activeNetworkSelector);

    yield post(
      `/collections/${id}/organizations/${companyId}/publish`,
      {},
      {
        baseURL: process.env.REACT_APP_API_V2_BASE_URL,
      },
    );
    yield put(loadedPublishSuggestedCompany({ companyId }));
    yield call(toast, `Company added to your network`, { icon: 'success', id: 'suggested_company_skipped' });
  } catch (error) {
    yield put(errorPublishSuggestedCompany({ companyId }));
    yield call(trackException, error);
    yield call(toast, strings.genericError, { icon: 'error', id: 'suggested_company_skipped' });
  }
}

function* loadSkipSuggestedCompanyEvent({ payload: { companyId } }) {
  try {
    const { id } = yield select(activeNetworkSelector);
    yield del(`/collections/${id}/organizations/${companyId}/skip`, {
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });
    yield put(loadedSkipSuggestedCompany({ companyId }));
    yield call(toast, `Company skipped`, { icon: 'success', id: 'suggested_company_skipped' });
  } catch (error) {
    yield put(errorSkipSuggestedCompany({ companyId }));
    yield call(trackException, error);
    yield call(toast, strings.genericError, { icon: 'error', id: 'suggested_company_skipped' });
  }
}

export default function* saga() {
  yield takeLatest(loadSuggestedCompanies().type, loadSuggestedCompaniesEvent);
  yield takeLatest(loadSkipSuggestedCompany().type, loadSkipSuggestedCompanyEvent);
  yield takeLatest(loadPublishSuggestedCompany().type, loadPublishSuggestedCompanyEvent);
}
