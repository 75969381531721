import React from 'react';
import { createPortal } from 'react-dom';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const ContactDetailPortal = ({ onCloseView, children, type }) =>
  createPortal(
    <Flex
      data-testid="contact-detail-view"
      height="100%"
      width="100%"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10,
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Box flexGrow="1" bg="neutral.20" onClick={onCloseView} sx={{ opacity: 0.8 }} />
      <Flex
        bg="neutral.0"
        px={['16px', '40px']}
        py="40px"
        width={['100%', '100%', 'calc(100% - 40px)']}
        maxWidth={type === 'red' ? '800px' : '1100px'}
        sx={{ position: 'relative', boxShadow: '-4px 0px 20px rgba(0, 0, 0, 0.08)', overflowY: 'auto' }}
      >
        {children}
      </Flex>
    </Flex>,
    document.getElementById('portals'),
  );

ContactDetailPortal.propTypes = {
  onCloseView: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};
