import { configureStore as configureStoreToolkit } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import { destroyCookie } from 'nookies';

import { removeItem } from 'helpers/store';
import { companiesApi } from 'services/companies';
import { analyticsApi } from 'services/analytics';
import { networkApi } from 'services/network';
import { networkV2Api } from 'services/networkV2';
import { contactsApi } from '../services/contacts';
import { setActiveNetwork } from '../actions/networksActions';
import { networkAdminsApi } from '../services/networkAdmins';
import { organizationApi } from '../services/organizations';
import { hiresApi } from '../services/hires';
import { tagsApi } from '../services/tags';
import { skillsApi } from '../services/skills';
import { locationsApi } from '../services/locations';
import createReducer from './reducers';
import { contactsV2Api } from '../services/contactsV2';
import { companiesV2Api } from '../services/companiesV2';
import { userApi } from '../services/user';
import { jobsV2Api } from '../services/jobsV2';
import { matchesApi } from '../services/matches';
import { connectJobsAPI } from '../services/connectJobs';
import { searchApi } from '../services/search';
import { sharedListsApi } from '../services/sharedLists';
import { sharedListsApiV2 } from '../services/sharedListV2';

const restrictedKeys = [
  'user',
  'jobfunctions',
  'firstName',
  'lastName',
  'email',
  'currentLocation',
  'introductionRequests',
];

const removeKeys = (obj, keys) =>
  // eslint-disable-next-line no-nested-ternary
  obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.map((item) => removeKeys(item, keys))
    : Object.keys(obj)
        .filter((k) => !keys.includes(k))
        .reduce((acc, x) => Object.assign(acc, { [x]: removeKeys(obj[x], keys) }), {});

export default function configureStore(initialState = {}, history) {
  const reduxSagaMonitorOptions = {};

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
    actionTransformer: (action) => {
      switch (action.type) {
        case '@@INIT':
        case setActiveNetwork().type:
          return null;
        default:
          if (!action.payload) {
            return null;
          }
          return {
            ...action,
            ...{
              payload: removeKeys(action.payload, restrictedKeys),
            },
          };
      }
    },
    stateTransformer: (state) => removeKeys(state, restrictedKeys),
  });

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const store = configureStoreToolkit({
    preloadedState: initialState,
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        sagaMiddleware,
        routerMiddleware(history),
        contactsApi.middleware,
        companiesApi.middleware,
        contactsV2Api.middleware,
        tagsApi.middleware,
        networkAdminsApi.middleware,
        organizationApi.middleware,
        hiresApi.middleware,
        analyticsApi.middleware,
        userApi.middleware,
        jobsV2Api.middleware,
        companiesV2Api.middleware,
        matchesApi.middleware,
        skillsApi.middleware,
        locationsApi.middleware,
        connectJobsAPI.middleware,
        searchApi.middleware,
        sharedListsApi.middleware,
        sharedListsApiV2.middleware,
        networkApi.middleware,
        networkV2Api.middleware,
      ),
    enhancers: [sentryReduxEnhancer],
  });

  store.subscribe(() => {
    const { user } = store.getState();
    const { ready, id } = user;
    if (ready && !id) {
      removeItem('authToken');
      destroyCookie(null, 'authToken', { path: '/' });
    }
  });

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
