import { createApi } from '@reduxjs/toolkit/query/react';
import { networkBaseQuery } from './queries';
import removeEmptyProps from '../helpers/removeEmptyProps';

export const connectJobsAPI = createApi({
  reducerPath: 'connectJobsAPI',
  baseQuery: networkBaseQuery,
  tagTypes: ['connectJobs'],
  endpoints: (builder) => ({
    getJobs: builder.query({
      query: ({ page = 1, hitsPerPage = 20, keywords }) => ({
        url: `contacts/jobs`,
        params: {
          page,
          perPage: hitsPerPage,
          ...removeEmptyProps({ keywords }),
        },
      }),
      providesTags: ['connectJobs'],
    }),
  }),
});

export const { useGetJobsQuery, useLazyGetJobsQuery } = connectJobsAPI;
