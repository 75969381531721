import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  errorRequestExportJobAlertSubscribers,
  loadedRequestExportJobAlertSubscribers,
  loadRequestExportJobAlertSubscribers,
  resetExportJobAlertSubscribers,
} from './actions';

export const initialState = {
  success: false,
  error: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = handleActions(
  {
    [loadRequestExportJobAlertSubscribers]: produce((draft) => {
      draft.success = initialState.success;
      draft.error = initialState.error;
      return draft;
    }),
    [resetExportJobAlertSubscribers]: produce((draft) => {
      draft.success = initialState.success;
      draft.error = initialState.error;
      return draft;
    }),
    [loadedRequestExportJobAlertSubscribers]: produce((draft) => {
      draft.success = true;
      draft.error = false;
      return draft;
    }),
    [errorRequestExportJobAlertSubscribers]: produce((draft) => {
      draft.success = false;
      draft.error = true;
      return draft;
    }),
  },
  initialState,
);

export default reducer;
