import React from 'react';
import { Button } from '@getro/rombo';
import { Box, Text } from 'rebass/styled-components';
import { X } from 'lucide-react';
import { Modal } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import 'semantic-ui-css/components/dimmer.min.css';
import 'semantic-ui-css/components/modal.min.css';
import 'semantic-ui-css/components/icon.min.css';

export const SimplifiedModal = (props) => {
  const { 'data-testid': dataTestId, title, isOpen, onCancel, content, actions, variant, children } = props;
  return (
    <Box
      scale="modal"
      variant={variant}
      as={Modal}
      data-testid={dataTestId}
      onClose={onCancel}
      open={isOpen}
      size={variant}
      closeIcon={
        <Button
          variant="link"
          display="flex"
          color="text.subtle"
          sx={{ position: 'absolute', right: 0, ':hover': { color: 'text.main', bg: 'transparent' } }}
          p="13.5px"
        >
          <Box data-item="close-icon" height="1em" width="1em" strokeWidth="1.5" aria-hidden="true" as={X} />
        </Button>
      }
      closeOnDimmerClick
    >
      {title && (
        <Text as={Modal.Header} fontWeight="semibold">
          {title}
        </Text>
      )}
      <Box as={Modal.Content} scrolling>
        {content || children}
      </Box>
      {actions && <Box as={Modal.Actions}>{actions}</Box>}
    </Box>
  );
};

SimplifiedModal.propTypes = {
  isOpen: PropTypes.bool,
  'data-testid': PropTypes.string,
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line consistent-return
  content: (props, propName, componentName) => {
    if (!props.content && !props.children) {
      return new Error(`One of props 'content' or 'children' was not specified in '${componentName}'.`);
    }
  },
  // eslint-disable-next-line consistent-return
  children: (props, propName, componentName) => {
    if (!props.content && !props.children) {
      return new Error(`One of props 'content' or 'children' was not specified in '${componentName}'.`);
    }
  },
  actions: PropTypes.node,
  variant: PropTypes.oneOf(['large', 'small']),
};

SimplifiedModal.defaultProps = {
  'data-testid': null,
  title: null,
  isOpen: true,
  variant: 'large',
  actions: null,
  content: '',
  children: '',
};
