import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from '../useInjectReducer';
import {
  isOrganizationsErroredSelector,
  isOrganizationsInitializedSelector,
  key,
  organizationsListSelector,
} from './selectors';
import reducer from './reducer';
import { useInjectSaga } from '../useInjectSaga';
import saga from './saga';
import { makeSelectIsLoading } from '../../redux/loadingSelector';
import { loadAllOrganizations } from './actions';
import { activeNetworkSelector } from '../../redux/selectors';

export const useAllOrganizations = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const activeNetwork = useSelector(activeNetworkSelector);

  const isAllOrganizationsLoading = useSelector(makeSelectIsLoading('allOrganizations'));

  const isAllOrganizationsInitialized = useSelector(isOrganizationsInitializedSelector);
  const hasAllOrganizationsInitializationFailed = useSelector(isOrganizationsErroredSelector);
  const allOrganizationsList = useSelector(organizationsListSelector);

  const dispatch = useDispatch();

  const shouldRetrieveOrganizations = useMemo(
    () => !isAllOrganizationsInitialized && !isAllOrganizationsLoading,
    [isAllOrganizationsInitialized, isAllOrganizationsLoading],
  );

  useEffect(() => {
    if (shouldRetrieveOrganizations) {
      dispatch(loadAllOrganizations({ activeNetwork }));
    }
  }, [activeNetwork, dispatch, shouldRetrieveOrganizations]);

  return {
    allOrganizationsList,
    isAllOrganizationsLoading,
    isAllOrganizationsInitialized,
    hasAllOrganizationsInitializationFailed,
  };
};
