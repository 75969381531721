import React from 'react';

import strings from 'strings';

import { MessageBlock } from '@getro/rombo';
import BlankLayout from 'components/layout/Blank';

import pageHoc from 'hocs/pageHoc';

const pageTitle = 'Stay Tuned';

const StayTuned = () => (
  <div data-cy="stay-tuned">
    <BlankLayout>
      <MessageBlock type="info" title={strings.session.noAccess.title} content={strings.session.noAccess.text} />
    </BlankLayout>
  </div>
);

export default pageHoc({ title: pageTitle })(StayTuned);
