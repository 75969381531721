import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box as FlexBox, Text } from 'rebass/styled-components';
import { Message, MessageBlock, Box, AsyncButton, Button } from '@getro/rombo';
import Modal from 'components/atoms/Modal';
import { Modal as SemanticModal } from 'semantic-ui-react';

const Settings = ({ removeCompany, isRemoveCompanyLoading, companySettings, company, history, network }) => {
  const hasTalentNetwork = network.features.includes('talent_network');

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(false);
  }, [companySettings.isRemoved]);
  if (!network.isManager) {
    return null;
  }
  return (
    <Box title="Remove from your network" data-testid="company_settings">
      <Flex width={[1]} flexDirection={['column']}>
        <FlexBox width={[1]} mb={2}>
          {!hasTalentNetwork ? (
            <Text as="p" data-testid="has_talent_network">
              Remove the <b>{company.name}</b> profile and associated jobs from your job board.
            </Text>
          ) : (
            <Text as="p">
              Remove the <b>{company.name}</b> profile and associated jobs from your job board. Company admins
              won&apos;t have access to your talent network.
            </Text>
          )}
        </FlexBox>
        <FlexBox width={[1]} mt={2}>
          <SemanticModal
            data-testid="delete_confirmation"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            closeOnDimmerClick
            closeIcon
            size="small"
            trigger={
              <Button data-testid="modal_trigger" pl={[0]} variant="link" color="danger">
                Remove company
              </Button>
            }
          >
            <SemanticModal.Header>
              Remove {company.name} from {network.name}
            </SemanticModal.Header>
            <SemanticModal.Content>
              <Flex flexDirection="column">
                <FlexBox width={[1]} mb={2}>
                  <Message type="warning">
                    <Text fontSize={2}>Please pay close attention to the following:</Text>
                  </Message>
                </FlexBox>
                <FlexBox mt={2} width={[1]}>
                  <Flex pl={3} as="ul" flexDirection="column">
                    <FlexBox as="li" variant="listItem">
                      {hasTalentNetwork ? (
                        <>
                          This company profile will be removed from your job board. Job seekers will not be able to
                          favorite or request introductions to <b>{company.name}</b>.
                        </>
                      ) : (
                        <>This company profile will be removed from your job board.</>
                      )}
                    </FlexBox>
                    <FlexBox as="li" variant="listItem">
                      New jobs at <b>{company.name}</b> will no longer appear on the job board. Existing jobs will be
                      removed.
                    </FlexBox>
                    <FlexBox as="li" variant="listItem">
                      Related company and job data will only be included in your analytics during the timeframe{' '}
                      <b>{company.name}</b> was part of your network.
                    </FlexBox>
                    <FlexBox as="li" variant="listItem">
                      All historic hires associated with <b>{company.name}</b> will be permanently removed from the
                      system.
                    </FlexBox>
                    <FlexBox as="li" variant="listItem">
                      {hasTalentNetwork ? (
                        <>
                          Company admins of <b>{company.name}</b> will not be able to manually post jobs or access your
                          talent network.
                        </>
                      ) : (
                        <>
                          Company admins of <b>{company.name}</b> will not be able to manually post jobs to the job
                          board.
                        </>
                      )}
                    </FlexBox>
                  </Flex>
                </FlexBox>
                {companySettings.error && !!companySettings.error.length && (
                  <FlexBox data-testid="modal_error_message" width={[1]}>
                    <Message type="error" className="Box__error-message">
                      <Text fontSize={2}>{companySettings.error}</Text>
                    </Message>
                  </FlexBox>
                )}
              </Flex>
            </SemanticModal.Content>
            <SemanticModal.Actions>
              <Flex justifyContent={['flex-end']}>
                <Button onClick={() => setIsOpen(false)} variant="secondary" mr={2}>
                  No, keep company
                </Button>

                <AsyncButton
                  variant="danger"
                  loading={isRemoveCompanyLoading}
                  onClick={() => {
                    removeCompany({ networkId: network.id, company });
                  }}
                  ml={2}
                >
                  Yes, remove company
                </AsyncButton>
              </Flex>
            </SemanticModal.Actions>
          </SemanticModal>
        </FlexBox>
      </Flex>

      <Modal data-testid="confirmation_message" open={companySettings.isRemoved} simple>
        <MessageBlock
          type="success"
          title={`${company.name} has been removed from your network`}
          buttonText="Go to companies"
          onClick={() => history.push(`/networks/${network.slug}/companies`)}
        />
      </Modal>
    </Box>
  );
};

Settings.propTypes = {
  removeCompany: PropTypes.func,
  company: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  network: PropTypes.object.isRequired,
  companySettings: PropTypes.object.isRequired,
  isRemoveCompanyLoading: PropTypes.bool.isRequired,
};

Settings.defaultProps = {
  removeCompany: () => {},
};
export default Settings;
