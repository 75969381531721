import React from 'react';
import { Button, FormInput, FormRadios, FormTextEditor } from '@getro/rombo';
import * as Yup from 'yup';
import { Box, Text } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useCreateNoteMutation, useUpdateNoteMutation } from '../../../../services/contacts';

export const EditMode = ({ queryParams, contactId, currentNote, setCurrentNote, setShowNotes, setShowEditMode }) => {
  const [createNote, { isLoading: isLoadingCreateNote }] = useCreateNoteMutation();
  const [updateNote, { isLoading: isLoadingUpdateNote }] = useUpdateNoteMutation();

  const formSchema = Yup.object().shape({
    title: Yup.string().required('Note title is a required field'),
    body: Yup.string().required('Note is a required field'),
  });
  const externalOptions = [
    {
      label: (
        <Box>
          <Text fontSize="1" fontWeight="medium" color="text.dark">
            Internal
          </Text>
          <Text mt="4px" fontSize="1">
            Only visible to your team.
          </Text>
        </Box>
      ),
      value: false,
    },
    {
      label: (
        <Box>
          <Text fontSize="1" fontWeight="medium" color="text.dark">
            Visible to companies
          </Text>
          <Text mt="4px" fontSize="1">
            Visible to your team and on shared lists.
          </Text>
        </Box>
      ),
      value: true,
    },
  ];
  const initialValues = currentNote
    ? { ...currentNote, external: externalOptions.find((opt) => opt.value === currentNote.external) }
    : { title: '', external: externalOptions[0], body: '' };

  const onSuccess = () => {
    setShowEditMode(false);
    setShowNotes(false);
    setCurrentNote(undefined);
  };

  const onSubmit = async (values) => {
    try {
      if (currentNote?.id) {
        await updateNote({ queryParams, note: currentNote, contactId, values, onSuccess });
      } else {
        await createNote({ queryParams, contactId, values, onSuccess });
      }

      onSuccess();
    } catch {
      // Do nothing
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit}>
      <Form>
        <Field name="title" label="New note" placeholder="Note title" component={FormInput} />
        <Field
          tabIndex="0"
          name="external"
          label="Visibility"
          component={FormRadios}
          columns={2}
          options={externalOptions}
          containerProps={{ mt: '24px' }}
        />
        <Field
          name="body"
          placeholder="Add screening notes or general context about this contact"
          component={FormTextEditor}
          containerProps={{ mt: '24px' }}
        />
        <Button mt="24px" type="submit" loading={isLoadingCreateNote || isLoadingUpdateNote}>
          Save
        </Button>
      </Form>
    </Formik>
  );
};

EditMode.propTypes = {
  queryParams: PropTypes.object,
  contactId: PropTypes.number.isRequired,
  setCurrentNote: PropTypes.func.isRequired,
  setShowNotes: PropTypes.func.isRequired,
  setShowEditMode: PropTypes.func.isRequired,
  currentNote: PropTypes.object,
};

EditMode.defaultProps = {
  queryParams: {},
  currentNote: null,
};
