/* eslint-disable max-lines */
import { put, call, debounce, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { request } from 'api';
import { addProtocolToUrl } from '../../helpers/urlHelpers';
import GooglePlacesAPI from '../../api/GooglePlacesAPI';
import { getUserDisplayName } from '../../helpers/users';
import { activeNetworkSelector, userSelector } from '../../redux/selectors';
import { talentGroupsSelector } from '../../redux/talentGroups.selector';
import {
  errorMemberLocationOptions,
  errorMemberSkillOptions,
  errorMemberVouchedByOptions,
  loadedMemberLocationOptions,
  loadedMemberSkillOptions,
  loadedMemberVouchedByOptions,
  loadMemberLocationOptions,
  loadMemberSkillOptions,
  loadMemberVouchedByOptions,
  loadAddRemoveMemberToList,
  loadedAddRemoveMemberToList,
  errorAddRemoveMemberToList,
  loadMembers,
  loadedMembers,
  errorMembers,
  loadCreateAndAddMemberToList,
  loadedCreateAndAddMemberToList,
  errorCreateAndAddMemberToList,
  loadCreateBoard,
  loadedCreateBoard,
  errorCreateBoard,
  loadBoardSettings,
  errorBoardSettings,
  loadedBoardSettings,
  editBoard,
  loadDeleteBoard,
  loadedDeleteBoard,
  errorDeleteBoard,
  loadedExportMembers,
  loadExportMembers,
  errorExportMembers,
  loadMemberDetails,
  loadedMemberDetails,
  errorMemberDetails,
  loadVouchMember,
  loadedVouchMember,
  errorVouchMember,
  updateMemberById,
  updatedBoard,
  createdNewBoard,
} from './actions';
import { PAGE_SIZE } from './constants';
import { prepareMembersFilter } from '../../helpers/membersFilter';
import { loadedTalentGroups } from '../../actions/talentGroupActions';
import strings from '../../strings';
import { makeSelectIsCreatingNewBoard } from './selectors';
import SkillsAPI from '../../api/SkillsAPI';
import { trackEvent } from '../../helpers/analytics';
import { parsePreferredWorkLocations, parseUserProfile, parseUsWorkStatus } from '../../helpers/userProfileHelpers';
import get from '../../helpers/get';

function* loadSkillsEvent({ payload }) {
  const { term } = payload;
  try {
    const results = yield call(request, {
      url: `/skills`,
      options: {
        params: {
          q: term,
        },
      },
    });
    yield put(
      loadedMemberSkillOptions({
        options: results.data.items.map(({ id: value, name }) => ({
          value,
          label: name,
        })),
      }),
    );
  } catch (err) {
    yield put(errorMemberSkillOptions(err));
  }
}

function* loadLocationsEvent({ payload }) {
  const { term } = payload;
  try {
    let results = [];
    if (term) {
      results = yield call(GooglePlacesAPI.searchGoogle, term);
    }
    yield put(
      loadedMemberLocationOptions({
        options: results.map(({ value, label }) => ({
          value,
          label,
        })),
      }),
    );
  } catch (err) {
    yield put(errorMemberLocationOptions(err));
  }
}

function* loadVouchedByEvent({ payload }) {
  const { term, networkId } = payload;
  try {
    const results = yield call(request, {
      url: `/collections/${networkId}/connectors`,
      options: {
        params: {
          q: term,
          page: 1,
          perPage: 20,
        },
      },
    });
    yield put(
      loadedMemberVouchedByOptions({
        options: results.data.items.map(({ id: value, firstName, lastName, avatarUrl }) => ({
          value,
          label: getUserDisplayName({ firstName, lastName }),
          avatarUrl,
        })),
      }),
    );
  } catch (err) {
    yield put(errorMemberVouchedByOptions(err));
  }
}

function* loadAddRemoveMemberToListEvent({ payload }) {
  const { origin, memberId, talentGroup, checked } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: collectionId } = activeNetwork;
  try {
    if (checked) {
      yield call(request, {
        url: `/users/${memberId}/talent_groups/add`,
        options: {
          method: 'post',
          params: {
            collectionId,
          },
          data: {
            talentGroupId: talentGroup.value,
          },
        },
      });
    } else {
      yield call(request, {
        url: `/users/${memberId}/talent_groups/${talentGroup.value}`,
        options: {
          method: 'delete',
          params: {
            collectionId,
          },
        },
      });
    }
    yield put(
      loadedAddRemoveMemberToList({
        origin,
        memberId,
        checked,
        talentGroup,
        activeNetworkId: collectionId,
      }),
    );
  } catch (e) {
    yield put(errorAddRemoveMemberToList({ memberId, error: e }));
  }
}

function* loadMembersEvent({ payload }) {
  const { activeNetworkId, page, filters } = payload;

  try {
    const response = yield call(request, {
      url: '/members',
      options: {
        params: {
          ...prepareMembersFilter({ ...filters, collectionId: activeNetworkId }),
          perPage: PAGE_SIZE,
          page,
        },
      },
    });

    const members = response.data.items.map((member) => {
      const { websiteUrl, linkedinUrl, twitterUrl, githubUrl, dribbbleUrl, ...otherMemberProps } = member;

      return {
        active: activeNetworkId,
        websiteUrl: addProtocolToUrl(websiteUrl),
        linkedinUrl: addProtocolToUrl(linkedinUrl),
        twitterUrl: addProtocolToUrl(twitterUrl),
        githubUrl: addProtocolToUrl(githubUrl),
        dribbbleUrl: addProtocolToUrl(dribbbleUrl),
        ...otherMemberProps,
      };
    });

    yield put(
      loadedMembers({
        members,
        total: response.data.meta.total,
        totalPages: Math.ceil(response.data.meta.total / PAGE_SIZE),
      }),
    );
  } catch (e) {
    yield put(errorMembers(e));
  }
}

function* loadCreateAndAddMemberToListEvent({ payload }) {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: activeNetworkId } = activeNetwork;
  const { member, name, origin } = payload;
  const { id: memberId } = member;

  try {
    const response = yield call(request, {
      url: `/collections/${activeNetworkId}/talent_groups`,
      options: {
        method: 'post',
        data: {
          name,
        },
      },
    });
    const { data } = response;
    const { talentGroup } = data;

    yield call(request, {
      url: `/users/${memberId}/talent_groups/add`,
      options: {
        method: 'post',
        params: {
          collectionId: activeNetworkId,
        },
        data: {
          talentGroupId: talentGroup.id,
        },
      },
    });

    const talentGroups = yield select(talentGroupsSelector) || [];
    const found = talentGroups.find((element) => element.value === talentGroup.id);
    if (!found) {
      const options = [
        ...talentGroups,
        {
          ...talentGroup,
          value: talentGroup.id,
          label: talentGroup.name,
        },
      ].sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      yield put(loadedTalentGroups({ id: activeNetworkId, talentGroups: options }));
    }

    yield put(
      loadedCreateAndAddMemberToList({
        origin,
        memberId,
        talentGroup,
        activeNetworkId,
      }),
    );
  } catch (e) {
    yield put(errorCreateAndAddMemberToList({ memberId, error: e, name }));
  }
}

function* loadCreateBoardEvent({ payload }) {
  const {
    description,
    employment,
    hasSmartList,
    id,
    jobSearchStatuses,
    keyword,
    locationIds,
    mentorship,
    name,
    origin,
    referralIds,
    remoteWork,
    seniorities,
    skillIds,
    usWorkStatus,
    verifiedMembers,
    vouchedBySomeone,
    willWorkAnywhere,
  } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  const talentGroups = yield select(talentGroupsSelector) || [];
  const isCreatingNewBoard = yield select(makeSelectIsCreatingNewBoard);
  try {
    let response;
    let options = [...talentGroups];
    let newTalentGroup;
    const filters = {
      keyword: '',
      locations: [],
      skills: null,
      seniorities: [],
      remoteWork: null,
      willWorkAnywhere: null,
      mentorship: null,
      employment: [],
      jobSearchStatuses: [],
      usWorkStatus: [],
      verifiedMembers: null,
      referralIds: [],
      vouchedBySomeone: null,
    };
    if (hasSmartList) {
      filters.keyword = keyword || '';
      filters.seniorities = seniorities || null;
      if (locationIds) {
        filters.locations = locationIds;
      }
      if (skillIds) {
        filters.skills = skillIds.map((skill) => skill.value);
      }
      if (remoteWork) {
        filters.remoteWork = remoteWork;
      }
      if (willWorkAnywhere) {
        filters.willWorkAnywhere = willWorkAnywhere;
      }
      if (mentorship) {
        filters.mentorship = mentorship;
      }
      if (verifiedMembers) {
        filters.verifiedMembers = verifiedMembers;
      }
      if (vouchedBySomeone) {
        filters.vouchedBySomeone = vouchedBySomeone;
      }
      filters.referralIds = referralIds || [];
      filters.employment = employment || [];
      filters.jobSearchStatuses = jobSearchStatuses || [];
      filters.usWorkStatus = usWorkStatus || [];
    }

    if (!isCreatingNewBoard) {
      // update and existing list
      response = yield call(request, {
        url: `/collections/${activeNetwork.id}/talent_groups/${id}`,
        options: {
          method: 'put',
          data: {
            name,
            description,
            filters,
          },
        },
      });
      newTalentGroup = response.data.talentGroup;
      // find and update the option in the list
      options = options.map((group) => {
        if (group.value === id) {
          return {
            ...newTalentGroup,
            value: newTalentGroup.id,
            label: newTalentGroup.name,
          };
        }
        return group;
      });
      yield put(updatedBoard({ board: newTalentGroup, activeNetworkId: activeNetwork.id, hasSmartList }));
    } else {
      // create a new list
      response = yield call(request, {
        url: `/collections/${activeNetwork.id}/talent_groups`,
        options: {
          method: 'post',
          data: {
            name,
            description,
            filters,
          },
        },
      });
      newTalentGroup = response.data.talentGroup;
      // add a new option to the list
      options.push({
        ...newTalentGroup,
        value: newTalentGroup.id,
        label: newTalentGroup.name,
      });
      // navigate to the new group
      yield put(createdNewBoard({ origin, board: newTalentGroup, activeNetworkId: activeNetwork.id, hasSmartList }));
    }
    // sort alphabetically
    yield put(
      loadedTalentGroups({
        id: activeNetwork.id,
        talentGroups: options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
      }),
    );
    yield put(loadedCreateBoard({ origin, board: newTalentGroup, collectionId: activeNetwork.id, hasSmartList }));
    // reload the members in the case of an update is done (new filters)
    if (!isCreatingNewBoard) {
      yield put(
        loadMembers({ activeNetworkId: activeNetwork.id, page: 1, filters: { talentGroups: [{ value: id }] } }),
      );
    }
  } catch (err) {
    let errorMessage = strings.genericError;
    if (
      err.response &&
      err.response.data &&
      err.response.data.errors &&
      err.response.data.errors.name &&
      err.response.data.errors.name.length
    ) {
      errorMessage = err.response.data.errors.name[0];
    }
    yield put(errorCreateBoard({ error: errorMessage }));
  }
}

function* loadBoardSettingsEvent({ payload }) {
  const { filters } = payload;
  const { skills } = filters || {};

  try {
    // get the skills
    const response = {};
    if (skills && skills.length > 0) {
      response.skillIds = yield call(SkillsAPI.searchByIds, skills);
    }
    // update the list
    yield put(loadedBoardSettings(response));
    yield put(editBoard());
  } catch (err) {
    yield put(errorBoardSettings({ error: err }));
  }
}

function* loadDeleteBoardEvent({ payload }) {
  const { talentGroupId } = payload;
  try {
    const activeNetwork = yield select(activeNetworkSelector);
    const { id: collectionId } = activeNetwork;
    yield call(request, {
      url: `/collections/${collectionId}/talent_groups/${talentGroupId}`,
      options: {
        method: 'delete',
      },
    });

    yield put(
      loadedDeleteBoard({
        collectionId,
        talentGroupId,
      }),
    );
  } catch (error) {
    yield put(errorDeleteBoard({ error }));
  }
}

function* loadExportMembersEvent({ payload }) {
  try {
    const activeNetwork = yield select(activeNetworkSelector);
    const { id: collectionId } = activeNetwork;
    const { filters } = payload;

    yield call(trackEvent, 'members:export_search_results');

    yield call(request, {
      url: '/members/export_csv',
      options: {
        params: {
          ...prepareMembersFilter({ ...filters, collectionId }),
        },
      },
    });

    yield put(loadedExportMembers());
  } catch (error) {
    yield put(errorExportMembers({ error }));
  }
}

function* loadMemberDetailsEvent({ payload }) {
  const { member } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  try {
    const response = yield call(request, {
      url: `/members/${member.id}`,
      options: {
        params: { collectionId: activeNetwork.id },
      },
    });

    const { data: memberDetails } = response;
    memberDetails.usWorkAuthorizationStatus = parseUsWorkStatus({
      usWorkAuthorization: memberDetails.usWorkAuthorization,
      requireSponsorshipVisa: memberDetails.requireSponsorshipVisa,
    });
    memberDetails.workLocations = parsePreferredWorkLocations({
      remoteWork: memberDetails.remoteWork,
      locations: memberDetails.locations.map((item) => ({
        label: item.name,
      })),
    });
    memberDetails.referralsReceived = memberDetails.referralsReceived || [];
    const parsedMember = parseUserProfile({ ...member, ...memberDetails });

    yield put(loadedMemberDetails(parsedMember));
  } catch (error) {
    yield put(errorMemberDetails({ error }));
  }
}

function* loadVouchMemberEvent({ payload }) {
  const { member, verify, values } = payload;
  try {
    const activeNetwork = yield select(activeNetworkSelector);
    const currentUser = yield select(userSelector);
    const previousVouch = member.referralsReceived.find(({ voucher }) => voucher.id === currentUser.id);
    const vouchResponse = yield call(request, {
      url: `/users/${member.id}/vouch`,
      options: {
        method: 'post',
        params: {
          collectionId: activeNetwork.id,
        },
        data: {
          relationshipLevel: values.relationshipLevel.value,
          relationshipText: values.customMessage,
          sendEmail: !previousVouch,
        },
      },
    });
    const vouch = vouchResponse.data;
    const eventParams = {
      medium: 'web',
      origin: 'members',
      from: get(vouch, 'voucher.email', undefined),
      to: get(vouch, 'vouched.email', undefined),
      collection_id: activeNetwork.id,
    };
    const eventName = previousVouch ? 'vouch_edit' : 'vouch';
    yield call(trackEvent, eventName, {
      ...eventParams,
      vouch_id: vouch.id,
      relationship_level: values.relationshipLevel.value,
    });

    let verifyResult = {};
    if (verify) {
      verifyResult = yield call(request, {
        url: `/users/${member.id}/verify`,
        options: {
          method: 'post',
          data: {
            collectionId: activeNetwork.id,
          },
        },
      });

      yield call(trackEvent, 'verify', eventParams);
    }

    yield put(loadedVouchMember({ vouch, verify: !!Object.keys(verifyResult).length }));

    const referralsReceived = member.referralsReceived.filter((r) => r.id !== vouch.id);
    if (verify) {
      yield put(
        updateMemberById({
          memberId: member.id,
          updatedProps: {
            referralsReceived: [vouch, ...referralsReceived],
            verified: !!Object.keys(verifyResult).length,
          },
        }),
      );
    } else {
      yield put(
        updateMemberById({
          memberId: member.id,
          updatedProps: {
            referralsReceived: [vouch, ...referralsReceived],
          },
        }),
      );
    }
  } catch (error) {
    yield put(errorVouchMember({ error }));
  }
}

export default function* membersSaga() {
  yield debounce(500, loadMemberSkillOptions().type, loadSkillsEvent);
  yield debounce(500, loadMemberLocationOptions().type, loadLocationsEvent);
  yield debounce(500, loadMemberVouchedByOptions().type, loadVouchedByEvent);
  yield takeEvery(loadAddRemoveMemberToList().type, loadAddRemoveMemberToListEvent);
  yield takeLatest(loadMembers().type, loadMembersEvent);
  yield takeLatest(loadCreateAndAddMemberToList().type, loadCreateAndAddMemberToListEvent);
  yield takeLatest(loadCreateBoard().type, loadCreateBoardEvent);
  yield takeLatest(loadBoardSettings().type, loadBoardSettingsEvent);
  yield takeLatest(loadDeleteBoard().type, loadDeleteBoardEvent);
  yield takeLatest(loadExportMembers().type, loadExportMembersEvent);
  yield takeLatest(loadMemberDetails().type, loadMemberDetailsEvent);
  yield takeLatest(loadVouchMember().type, loadVouchMemberEvent);
}
