import React from 'react';
import { Flex, Image, Text } from 'rebass/styled-components';

import IconHandEmptyIcon from '../../assets/icon-hand-empty.svg';

export const EmptyState = () => (
  <Flex mt="40px" flexDirection="column" alignItems="center" maxWidth="520px" mx="auto" textAlign="center">
    <Image src={IconHandEmptyIcon} width="64px" maxWidth="64px" />
    <Text mt="16px" fontWeight="semibold">
      This list is empty
    </Text>
    <Text as="span" mt="8px" color="text.subtle">
      Subscribe to get notifications when new people are added.
    </Text>
  </Flex>
);
