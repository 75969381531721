import React, { useEffect, useMemo, useCallback } from 'react';

import { Box, Flex, Text } from 'rebass/styled-components';
import { CheckCircle, AlertTriangle } from 'lucide-react';
import { Button } from '@getro/rombo';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { AddCompanyProcess } from 'components/atoms/AddCompanyProcess';
import { resetAddCompany } from './redux/actions';

const CompaniesAdded = ({ meta, onDone }) => {
  const dispatch = useDispatch();
  const { created, published, failed, notActive } = meta;

  const organizationsCreated = created + published;
  const showOnlyError = !created && !published && (failed.length || notActive.length);

  const formatDomainList = useCallback((list) => {
    const { length } = list;

    if (length === 1) {
      return list[0];
    }

    return list
      .reduce((acc, value, index) => {
        if (index === length - 1) {
          acc.push('and');
          acc.push(value);

          return acc;
        }

        acc.push(`${value},`);

        return acc;
      }, [])
      .join(' ');
  }, []);

  const formatFailed = useMemo(() => formatDomainList(failed.map(({ domain }) => domain)), [failed, formatDomainList]);
  const formatNotActive = useMemo(() => formatDomainList(notActive), [notActive, formatDomainList]);

  useEffect(() => () => dispatch(resetAddCompany()));

  return (
    <Box maxWidth={460} m="0 auto">
      <Flex color={showOnlyError ? 'orange' : 'success'} alignItems="center">
        <Box
          width="80px"
          height="80px"
          strokeWidth="0.75px"
          as={showOnlyError ? AlertTriangle : CheckCircle}
          aria-hidden="true"
        />
      </Flex>

      <Text mt={38} mb={16} as="h1" color="text.dark" fontWeight="600" fontSize={18} lineHeight="25.2px">
        {organizationsCreated > 0 ? organizationsCreated : 'No'}{' '}
        {`${organizationsCreated === 1 ? 'company' : 'companies'} added`}
      </Text>

      <Flex mt={3} flexDirection="column" sx={{ rowGap: 4 }}>
        <Flex
          flexDirection="column"
          pl={24}
          as="ul"
          color="text.dark"
          fontSize={16}
          sx={{ li: { lineHeight: '22.4px' }, rowGap: '8px' }}
        >
          {published > 0 && (
            <Box as="li">
              {published} {published === 1 ? 'is' : 'are'} already visible on your job board.
            </Box>
          )}

          {created.length > 0 && (
            <Box as="li">
              {created.length} not published because we don’t have info: our team will add info and jobs, and publish
              them. <AddCompanyProcess />
            </Box>
          )}
          {failed.length > 0 && (
            <Box as="li">
              {failed.length} {failed.length === 1 ? 'domain was' : 'domains were'} not added because they’re not
              hosting a website: {formatFailed}.
            </Box>
          )}
          {notActive.length > 0 && (
            <Box as="li">
              {notActive.length} could not be added: {formatNotActive}. Contact us at{' '}
              <a href="mailto:help@getro.com">help@getro.com</a>.
            </Box>
          )}
        </Flex>
        {!showOnlyError && (
          <Text lineHeight="22.4px" color="tex.main" as="p" fontSize={16}>
            We will notify when information and jobs are updated, it usually takes less than 2 business days.
          </Text>
        )}
      </Flex>
      <Button onClick={onDone} width={70} height={40} mt={4} href="#" variant="primary">
        Ok
      </Button>
    </Box>
  );
};

CompaniesAdded.propTypes = {
  meta: PropTypes.objectOf({
    created: PropTypes.number.isRequired,
    failed: PropTypes.arrayOf(
      PropTypes.shape({
        domain: PropTypes.string.isRequired,
        error: PropTypes.string,
      }),
    ).isRequired,
    published: PropTypes.number.isRequired,
    notActive: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,

  onDone: PropTypes.func.isRequired,
};

export default CompaniesAdded;
