import React from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

export const SkeletonRow = ({ width, height, ...rest }) => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
      {...rest}
    >
      <rect x="0" y="0" rx="8" ry="8" width={width} height={height} />
    </ContentLoader>
  );
};

SkeletonRow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

SkeletonRow.defaultProps = {
  width: 120,
  height: 12,
};
