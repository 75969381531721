import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import reducer from './reducer';
import saga from './saga';
import { useInjectReducer } from '../useInjectReducer';
import { useInjectSaga } from '../useInjectSaga';
import { key, makeSelectJobRules } from './selectors';
import { makeSelectIsLoading } from '../../redux/loadingSelector';
import { loadJobRules } from './actions';

const useJobRules = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const jobRules = useSelector(makeSelectJobRules);
  const isJobRulesLoading = useSelector(makeSelectIsLoading('jobRules'));
  const dispatch = useDispatch();

  const loadJobRulesRequest = useCallback(() => {
    dispatch(loadJobRules());
  }, [dispatch]);

  return { jobRules, isJobRulesLoading, loadJobRulesRequest };
};

export default useJobRules;
