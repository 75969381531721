import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useInjectReducer } from 'hooks/useInjectReducer';
import { useInjectSaga } from 'hooks/useInjectSaga';
import pageHoc from 'hocs/pageHoc';
import { activeNetworkSelector, userSelector } from 'redux/selectors';
import { makeSelectIsLoading } from 'redux/loadingSelector';

import PageLayout from 'components/layout/Page';
import memberRoutes from 'router/membersRoutes';
import bulkMembersInviteRoutes from 'router/bulkMembersInviteRoutes';
import Info from './Info';
import Vouch from './Vouch';
import Success from './Success';
import compose from '../../helpers/compose';
import {
  key,
  isNetworkManagerSelector,
  canSetPermissionsSelector,
  managingOrganizationsSelector,
  isManagingOrganizationsSelector,
  inviteMemberInfoSelector,
  showVouchSelector,
  showSuccessSelector,
  inviteMemberVouchSelector,
  errorSelector,
  invitedUserSelector,
} from './selectors';
import {
  loadSaveInvitedMemberInfo,
  navigateBackInvitedMemberVouch,
  loadSaveInvitedMemberInvite,
  loadSaveInvitedMemberVouch,
  dismissSuccessInviteMember,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import useTalentGroups from '../../hooks/useTalentGroups';
import withMainLayout from '../../hocs/withMainLayout';

const pageTitle = 'Invite a member';

const MembersInvite = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const history = useHistory();
  const activeNetwork = useSelector(activeNetworkSelector);
  const currentUser = useSelector(userSelector);
  const [talentGroups] = useTalentGroups(activeNetwork);
  const showVouch = useSelector(showVouchSelector);
  const showSuccess = useSelector(showSuccessSelector);
  const isNetworkManager = useSelector(isNetworkManagerSelector);
  const canSetPermissions = useSelector(canSetPermissionsSelector);
  const isManagingOrganizations = useSelector(isManagingOrganizationsSelector);
  const managingOrganizations = useSelector(managingOrganizationsSelector);
  const inviteMemberInfo = useSelector(inviteMemberInfoSelector);
  const inviteMemberVouch = useSelector(inviteMemberVouchSelector);
  const saveInvitedMemberVouchSubmitting = useSelector(makeSelectIsLoading('saveInvitedMemberVouch'));
  const hasError = useSelector(errorSelector);
  const invitedUser = useSelector(invitedUserSelector);
  const { id: activeNetworkId, slug } = activeNetwork;

  let companies = [];
  if (!isNetworkManager && isManagingOrganizations) {
    companies = managingOrganizations.map((o) => ({ value: o.id, label: o.name }));
  }

  const canSetRoles = isNetworkManager || isManagingOrganizations;
  const canSetEmployer = isManagingOrganizations || isNetworkManager;

  const finishInfoStep = (values) => {
    const { talentGroupIds = [], ...otherValueProps } = values;

    dispatch(
      loadSaveInvitedMemberInfo({
        activeNetworkId,
        values: {
          ...otherValueProps,
          talentGroupIds: talentGroupIds.map((g) => g.value),
        },
      }),
    );
  };

  const onCancelInfoStep = () => {
    history.push({ pathname: `/networks/${slug}${memberRoutes.path}` });
  };

  const handleUploadCSV = () => {
    history.push({
      pathname: `/networks/${slug}${bulkMembersInviteRoutes.path}`,
    });
  };

  const onNavigateBackVouch = (payload) => {
    dispatch(navigateBackInvitedMemberVouch(payload));
  };

  const onInviteMember = () => {
    dispatch(loadSaveInvitedMemberInvite({ activeNetworkId }));
  };

  const onVouchMember = (values) => {
    dispatch(loadSaveInvitedMemberVouch({ activeNetworkId, values }));
  };

  const onDismissSuccessClick = () => {
    dispatch(dismissSuccessInviteMember());
    history.push({ pathname: `/networks/${slug}${memberRoutes.path}` });
  };

  return (
    <PageLayout>
      <PageLayout.Content>
        {!showSuccess && !showVouch && (
          <Info
            activeNetwork={activeNetwork}
            currentUser={currentUser}
            canInviteEmployers={isNetworkManager}
            canSearchCompanies={isNetworkManager}
            canSetConnector={isNetworkManager}
            canSetAdmin={isNetworkManager}
            canSetPermissions={canSetPermissions}
            canSetRoles={canSetRoles}
            canSetEmployer={canSetEmployer}
            companies={companies}
            isManagingOrganizations={isManagingOrganizations}
            onSubmit={finishInfoStep}
            inviteMemberInfo={inviteMemberInfo}
            onCancel={onCancelInfoStep}
            handleUploadCSV={handleUploadCSV}
            talentGroups={talentGroups}
          />
        )}
        {!showSuccess && showVouch && (
          <Vouch
            inviteMemberInfo={inviteMemberInfo}
            inviteMemberVouch={inviteMemberVouch}
            submitting={saveInvitedMemberVouchSubmitting}
            onNavigateBack={onNavigateBackVouch}
            onInviteMember={onInviteMember}
            onVouchMember={onVouchMember}
            hasError={hasError}
          />
        )}
        {showSuccess && <Success onDismissClick={onDismissSuccessClick} invitedUser={invitedUser} />}
      </PageLayout.Content>
    </PageLayout>
  );
};

export default compose(withMainLayout, pageHoc({ title: pageTitle }))(MembersInvite);
