import React, { useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { PickerOverlay } from 'filestack-react';
import { UploadImage, Loader, MessageBlock, Box, BoxRow, ProfilePicture } from '@getro/rombo';
import strings from '../../../../strings';
import { GenericError } from '../../../../components/molecules/genericError';

const formSchema = Yup.object().shape({
  logoUrl: Yup.string().label('Logo URL').url().nullable(),
});

const General = ({ generalSettings, fetchError, editing, onCancel, onEditClick, saveError, submitting, onSubmit }) => {
  const formRef = useRef(null);
  const errorMessage = saveError ? strings.genericError : null;

  useEffect(() => {
    const { current: form } = formRef;
    if (form !== null) {
      form.setSubmitting(editing && submitting);
    }
  }, [editing, submitting]);

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const handleCancel = () => {
    const { current: form } = formRef;
    form.resetForm();
    onCancel();
  };

  if (fetchError) {
    return (
      <Box title="Network information">
        <MessageBlock>{strings.genericError}</MessageBlock>
      </Box>
    );
  }

  if (generalSettings === null) {
    return (
      <Box title="Network information">
        <Loader />
      </Box>
    );
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={generalSettings}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, status, isSubmitting, setFieldValue }) => (
        <Form>
          <Box
            title="Network information"
            onEditClick={onEditClick}
            editLinkText="Edit"
            editing={editing}
            errorText={errorMessage}
            onCancelClick={handleCancel}
            loading={isSubmitting}
            saveAsSubmit
          >
            <BoxRow title="Logo">
              <ProfilePicture
                imageUrl={editing ? values.logoUrl : generalSettings.logoUrl}
                name={values.name}
                variant="square"
                sx={{
                  width: '32px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              />
              {editing && (
                <>
                  <UploadImage
                    Picker={PickerOverlay}
                    filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
                    className="Settings__upload-image"
                    imageType="logo"
                    onSuccess={({ filesFailed, filesUploaded }) => {
                      if (filesFailed.length < 1) {
                        setFieldValue('logoUrl', filesUploaded[0].url);
                      }
                    }}
                    onClear={() => setFieldValue('logoUrl', null)}
                    currentValue={values.logoUrl}
                  />
                </>
              )}
            </BoxRow>

            {status && status.error && <GenericError />}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

General.propTypes = {
  fetchError: PropTypes.string,
  generalSettings: PropTypes.shape({
    logoUrl: PropTypes.string,
  }),
  editing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  saveError: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

General.defaultProps = {
  fetchError: null,
  generalSettings: null,
};

export default General;
