import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'hooks/useInjectReducer';
import { useInjectSaga } from 'hooks/useInjectSaga';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FORMAT, PRESETS } from 'components/atoms/DatePicker';

import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';
import { makeSelectIsLoading } from 'redux/loadingSelector';
import { useLazyContactsQuery, useLazySharedListContactsQuery, useLazySharedListQuery } from 'services/analytics';
import { useLazyDownloadLikelyAppliedQuery } from 'services/organizations';
import { activeNetworkSelector } from 'redux/selectors';
import { toast } from 'react-hot-toast';
import { useLazyGetNetworkCountsQuery } from '../../../../services/search';
import {
  loadAllJobAlertSubscribers,
  loadJobAlertSubscribers,
  loadJobsApplicants,
  loadJobsClicks,
  loadJobsLikelyApplied,
  loadSuggestedCompaniesCount,
  loadUniqueVisitors,
  trackHomeLeftBeforeLoad,
} from './actions';
import { analyticSelector, key } from './selector';
import saga from './saga';
import reducer from './reducer';

export const useAnalytics = () => {
  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });
  const [
    loadJobsWithMatches,
    { isLoading: sharedListContactsLoading, data: sharedListContacts, sharedListContactsError },
  ] = useLazySharedListContactsQuery();
  const [loadMatchesShared, { isLoading: sharedListsLoading, data: sharedLists, isError: sharedListError }] =
    useLazySharedListQuery();
  const [loadContacts, { isLoading: contactsLoading, data: contacts, isError: contactsError }] = useLazyContactsQuery();
  const [loadNetworkCounts, { isLoading: networkCountsLoading, data: networkCounts, isError: networkCountsError }] =
    useLazyGetNetworkCountsQuery();
  const dispatch = useDispatch();
  const analyticData = useSelector(analyticSelector);
  const [dates, setStartDate] = useState({
    startDate: PRESETS[2].start,
    endDate: PRESETS[2].end,
  });
  const initialLoadRef = useRef(null);
  const [download, { isFetching }] = useLazyDownloadLikelyAppliedQuery();
  const currentNetwork = useSelector(activeNetworkSelector);

  const startDate = useMemo(() => dates.startDate.format(FORMAT), [dates.startDate]);
  const endDate = useMemo(() => dates.endDate.format(FORMAT), [dates.endDate]);
  const loadingState = {
    uniqueVisitorsLoading: useSelector(makeSelectIsLoading('uniqueVisitors')),
    jobClicksLoading: useSelector(makeSelectIsLoading('jobsClicks')),
    jobAlertLoading: useSelector(makeSelectIsLoading('allJobAlertSubscribers')),
    allJobAlertLoading: useSelector(makeSelectIsLoading('suggestedCompaniesCount')),
    companiesLoading: useSelector(makeSelectIsLoading('jobAlertSubscribers')),
    jobsApplicantsLoading: useSelector(makeSelectIsLoading('jobsApplicants')),
    downloadLikelyAppliedLoading: isFetching,
    jobsLikelyAppliedLoading: useSelector(makeSelectIsLoading('jobsLikelyApplied')),
    sharedListContactsLoading,
    contactsLoading,
    sharedListsLoading,
    networkCountsLoading,
  };

  useEffect(() => {
    dispatch(loadUniqueVisitors({ startDate, endDate }));
    dispatch(loadJobsClicks({ startDate, endDate }));
    dispatch(loadJobAlertSubscribers({ startDate, endDate }));
    dispatch(loadJobsApplicants({ startDate, endDate }));
    dispatch(loadJobsLikelyApplied({ startDate, endDate }));

    if (!initialLoadRef.current) {
      dispatch(loadSuggestedCompaniesCount());
      dispatch(loadAllJobAlertSubscribers());
      loadMatchesShared();
      loadContacts();
      loadJobsWithMatches();
      loadNetworkCounts();
    }

    initialLoadRef.current = true;
  }, [dispatch, startDate, endDate, loadJobsWithMatches, loadMatchesShared, loadContacts, loadNetworkCounts]);

  const onDownloadLikelyApplied = useCallback(async () => {
    await download({ networkId: currentNetwork.id, startDate, endDate });
    toast('We’ll email you the download, it can take a few minutes.', {
      icon: 'success',
      id: 'download_job_application',
    });
  }, [currentNetwork.id, download, endDate, startDate]);

  useComponentWillUnmount(() => {
    const stillLoading = Object.values(loadingState).some((loading) => loading);
    if (stillLoading) {
      dispatch(trackHomeLeftBeforeLoad());
    }
  }, [analyticData, dispatch, loadingState]);

  return {
    ...analyticData,
    sharedList: {
      count: sharedLists?.count || 0,
      error: sharedListError,
    },
    sharedListContacts: {
      count: sharedListContacts?.count || 0,
      error: sharedListContactsError,
    },
    contacts: {
      ...contacts,
      error: contactsError,
    },
    networkCounts: {
      ...networkCounts,
      error: networkCountsError,
    },
    dates,
    setStartDate,
    onDownloadLikelyApplied,
    loadingState,
  };
};
