import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { FormError, FormLabel } from '@getro/rombo';
import { ChevronDown } from 'lucide-react';

export const FormColourPicker = ({ label, labelExtra, helpText, field, form, containerProps }) => {
  const { id, name, value } = field;
  const { setFieldValue } = form;
  const error = form.touched?.[name] && form.errors?.[name];
  const inputRef = useRef();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const toggleInput = (e) => {
    e.stopPropagation();
    if (inputRef.current && !isColorPickerOpen) {
      inputRef.current.click();
    }

    if (inputRef.current && isColorPickerOpen) {
      inputRef.current.blur();
    }

    setIsColorPickerOpen(!isColorPickerOpen);
  };

  return (
    <Box width="100%" {...containerProps}>
      {label && (
        <FormLabel htmlFor={name || id} extra={labelExtra}>
          {label}
        </FormLabel>
      )}
      {helpText && (
        <Text fontSize="1" mb="2" lineHeight="20px">
          {helpText}
        </Text>
      )}

      <Flex alignItems="center" sx={{ cursor: 'pointer', gap: '24px' }}>
        <Text color="text.main" fontSize="14px" lineHeight="24px">
          Color:
        </Text>
        <Flex onClick={toggleInput} sx={{ alignItems: 'center', gap: '4px', textTransform: 'uppercase' }}>
          <Box
            sx={{
              pointerEvents: 'none',
              '-webkit-appearance': 'none',
              border: 'none',
              padding: '1px',
              width: '24px',
              height: '24px',
              borderRadius: '4px',
              '&::-webkit-appearance': {
                border: 'none',
              },
              '&::-webkit-color-swatch': {
                padding: 0,
                borderRadius: '4px',
              },
              '&::-webkit-color-swatch-wrapper': {
                border: 'none',
                padding: 0,
              },
            }}
            as="input"
            ref={inputRef}
            onChange={(e) => {
              setFieldValue(name, e.target.value);
            }}
            type="color"
            value={value}
          />
          <Text color="text.main" fontSize="14px" lineHeight="24px">
            {value}
          </Text>
          <Box as={ChevronDown} sx={{ color: 'neutral.500' }} />
        </Flex>
      </Flex>

      {error && <FormError>{error}</FormError>}
    </Box>
  );
};

FormColourPicker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelExtra: PropTypes.string,
  helpText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  containerProps: PropTypes.object,
  field: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    touched: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }),
};

FormColourPicker.defaultProps = {
  label: null,
  labelExtra: '',
  helpText: null,
  containerProps: {},
  field: {},
  form: { errors: [] },
};
