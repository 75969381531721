import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Box, Flex, Card, Text } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';
import { Button, FormField, AsyncButton, Message, FormikTextArea } from '@getro/rombo';

import { Toggle } from '../../atoms/toggleDeprecated';
import { SimplifiedModal } from '../../atoms/simplifiedModal';
import { MemberFilters } from '../memberFilters';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required').max(32, "Can't exceed 32 characters"),
  description: Yup.string().max(140, "Can't exceed 140 characters"),
});

const hasAnyFilters = (filters) => {
  let result = false;
  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key].length !== 0) {
        result = true;
      }
    });
  }
  return result;
};

export const BoardSettingsModal = ({
  preferredLocations,
  vouchedBy,
  skills,
  error,
  isSubmitting,
  onSubmit,
  initValues,
  isNewBoard,
  onCancel,
  onDeleteBoard,
}) => {
  const title = isNewBoard ? 'New list' : 'List settings';
  const formRef = useRef(null);
  const defaultSmartFilterValues = {
    hasSmartList: hasAnyFilters(initValues.filters),
    keyword: '',
    skillIds: [],
    seniorities: [],
    locationIds: [],
    remoteWork: null,
    willWorkAnywhere: null,
    employment: [],
    mentorship: null,
    jobSearchStatuses: [],
    usWorkStatus: [],
    verifiedMembers: null,
    referralIds: [],
    vouchedBySomeone: null,
  };

  if (Object.prototype.hasOwnProperty.call(initValues, 'filters')) {
    defaultSmartFilterValues.keyword = initValues.filters.keyword || '';
    defaultSmartFilterValues.seniorities = initValues.filters.seniorities || [];
    defaultSmartFilterValues.skillIds = initValues.filters.skillIds || [];
    defaultSmartFilterValues.locationIds = initValues.filters.locations || [];
    defaultSmartFilterValues.remoteWork = initValues.filters.remoteWork || null;
    defaultSmartFilterValues.willWorkAnywhere = initValues.filters.willWorkAnywhere || null;
    defaultSmartFilterValues.employment = initValues.filters.employment || [];
    defaultSmartFilterValues.mentorship = initValues.filters.mentorship || null;
    defaultSmartFilterValues.jobSearchStatuses = initValues.filters.jobSearchStatuses || [];
    defaultSmartFilterValues.usWorkStatus = initValues.filters.usWorkStatus || [];
    defaultSmartFilterValues.verifiedMembers = initValues.filters.verifiedMembers || null;
    defaultSmartFilterValues.referralIds = initValues.filters.referralIds || [];
    defaultSmartFilterValues.vouchedBySomeone = initValues.filters.vouchedBySomeone;
  }

  const defaultValues = { ...defaultSmartFilterValues, ...initValues };

  return (
    <SimplifiedModal
      data-testid="board-settings-modal"
      onCancel={onCancel}
      variant="large"
      isOpen
      title={title}
      content={
        <>
          <Formik
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize
            initialValues={defaultValues}
            onSubmit={onSubmit}
          >
            {({ errors, values, setFieldValue }) => (
              <Flex as={Form} sx={{ flexDirection: ['column'] }}>
                <Box mb={2}>
                  <Field
                    label="Name *"
                    error={errors.name}
                    value={values.name}
                    name="name"
                    placeholder="List name"
                    type="text"
                    component={FormField}
                    required
                  />
                </Box>
                <Box my={2}>
                  <FormikTextArea label="Description" name="description" placeholder="Description" />
                </Box>
                <Box my={2}>
                  <Box as="legend" variant="legend" fontWeight="medium">
                    Smart list
                  </Box>
                  <Toggle
                    name="hasSmartList"
                    label={values.hasSmartList ? 'On' : 'Off'}
                    onChange={(e) => {
                      setFieldValue('hasSmartList', e.target.checked);
                    }}
                    checked={values.hasSmartList}
                  />
                  <Box pl={4} ml={2}>
                    {values.hasSmartList && (
                      <Text>
                        The members on this list will be automatically updated based on your filters. You can also
                        manually add or remove members.
                      </Text>
                    )}
                    {!values.hasSmartList && (
                      <Text>Switch this ON to automatically update this list based on a set of filters.</Text>
                    )}
                  </Box>
                </Box>
                {values.hasSmartList && (
                  <Card mt={2} bg="gray.5" sx={{ borderRadius: 'default', boxShadow: 'none' }} p={3}>
                    <MemberFilters
                      values={values}
                      setFieldValue={setFieldValue}
                      isCompanyAdmin
                      formRef={formRef}
                      initValues={defaultValues}
                      preferredLocations={preferredLocations}
                      vouchedBy={vouchedBy}
                      skills={skills}
                      onSubmit={() => {}}
                    />
                  </Card>
                )}
                <Button variant="variants.srOnly" type="submit">
                  Save
                </Button>
              </Flex>
            )}
          </Formik>
        </>
      }
      actions={
        <Flex flexDirection={['column']}>
          {error && (
            <Box width={[1]} mb={3}>
              <Message type="error" content={error} />
            </Box>
          )}
          <Box width={[1]}>
            <Flex justifyContent={['flex-end']} flexDirection={['row']}>
              {!isNewBoard && !initValues.systemBoard && (
                <Flex justifyContent={['flex-start']} flexDirection={['row']} flex="1">
                  <Button variant="danger" onClick={onDeleteBoard} type="button">
                    Delete list
                  </Button>
                </Flex>
              )}

              <Button mx={[2]} onClick={onCancel} variant="secondary">
                Cancel
              </Button>
              <AsyncButton
                loading={isSubmitting}
                variant="primary"
                ml={[2]}
                onClick={() => {
                  formRef.current.handleSubmit();
                }}
              >
                Save
              </AsyncButton>
            </Flex>
          </Box>
        </Flex>
      }
    />
  );
};

BoardSettingsModal.propTypes = {
  filters: PropTypes.object,
  error: PropTypes.string,
  isNewBoard: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  initValues: PropTypes.shape({
    filters: PropTypes.object,
    name: PropTypes.string,
    description: PropTypes.string,
    systemBoard: PropTypes.bool,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDeleteBoard: PropTypes.func.isRequired,
  skills: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
  preferredLocations: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
  vouchedBy: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
};

BoardSettingsModal.defaultProps = {
  filters: null,
  error: null,
  isNewBoard: false,
  isSubmitting: false,
};
