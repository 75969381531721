import React, { useMemo, useRef, useCallback } from 'react';
import { Form, Formik } from 'formik';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { Eye, X } from 'lucide-react';
import { Flex } from 'rebass/styled-components';

import ActionsIcon from 'pages/contactsExtended/bulkActions/actionsIcon';
import { toggleAllJobsSelections } from 'hooks/useJobs/actions';
import useJobActions from '../../../hooks/useJobActions';
import ForceJobVisibilityModal from '../../molecules/forceJobVisibilityModal';
import { toggleJobActionDialog } from '../../../hooks/useJobActions/actions';
import { BulkActionsBar } from '../../atoms/BulkActionsBar';
import { selectAllJobsSelector, selectedJobsSelector, totalJobsSelector } from '../../../hooks/useJobs/selectors';
import OrganizationSchema from '../../../schemas/organization';

export const JobsBulkActions = ({ source, company, children }) => {
  const selectedJobs = useSelector(selectedJobsSelector);
  const updatableJobsIds = useMemo(
    () => selectedJobs.filter((job) => !['expired', 'closed'].includes(job.attributes.status)).map((job) => job.id),
    [selectedJobs],
  );
  const totalJobs = useSelector(totalJobsSelector);
  const selectAllJobs = useSelector(selectAllJobsSelector);
  const selectedJobsCount = useMemo(() => {
    if (selectAllJobs) return totalJobs;

    return selectedJobs.length;
  }, [selectAllJobs, selectedJobs.length, totalJobs]);
  const updatableJobsIdsCount = useMemo(() => {
    if (selectAllJobs) return totalJobs;

    return updatableJobsIds.length;
  }, [selectAllJobs, totalJobs, updatableJobsIds.length]);
  const location = useLocation();
  const jobsForm = useRef(null);

  const modalTitle = useMemo(() => {
    if (selectAllJobs) {
      return 'Set multiple jobs visibility';
    }

    return `Set ${updatableJobsIdsCount} ${pluralize('job', updatableJobsIdsCount)} visibility`;
  }, [selectAllJobs, updatableJobsIdsCount]);

  const { jobActions, jobActionsLoading, jobActionsErrors, dialogStates } = useJobActions({ source });
  const dispatch = useDispatch();

  const onBulkForceVisibilitySubmit = useCallback(
    (v) => {
      if (selectAllJobs) {
        const searchParams = qs.parse(location.search);
        const { locations, ...otherSeachParams } = searchParams;
        let locationIds = [];

        if (locations) {
          locationIds = JSON.parse(atob(locations)).map(({ value }) => value);
        }

        jobActions.onBulkForceAllJobsVisibility({
          forcedVisibility: v,
          companyId: company?.id,
          locationIds,
          ...otherSeachParams,
        });
      } else {
        jobActions.onBulkForceJobVisibility({
          ids: updatableJobsIds,
          forcedVisibility: v,
          visibilityFilter: qs.parse(location.search).visibility,
        });
      }
    },
    [company, jobActions, location.search, selectAllJobs, updatableJobsIds],
  );

  const onSelectAllClick = () => {
    dispatch(toggleAllJobsSelections({ selectAll: false }));
  };

  return (
    <>
      <Formik innerRef={jobsForm} initialValues={{}}>
        {() => (
          <Form>
            {children}
            <BulkActionsBar
              count={selectedJobsCount}
              itemLabel="job"
              sx={{
                maxWidth: 'max-content',
                width: ['368px', '368px', '100%'],
                gap: '24px',
              }}
              actions={
                <Flex sx={{ gap: ['0px', '0px', '24px'], cursor: 'pointer', height: '32px' }}>
                  <ActionsIcon
                    onClick={() => jobActions.onBulkForceJobVisibilityRequest()}
                    text="Set visibility"
                    icon={Eye}
                  />
                  <ActionsIcon onClick={onSelectAllClick} text="Clear selection" icon={X} />
                </Flex>
              }
            />
          </Form>
        )}
      </Formik>

      <ForceJobVisibilityModal
        isOpen={dialogStates.bulkForceJobVisibility}
        title={modalTitle}
        selectedJobsCount={selectedJobsCount}
        updatableJobsIdsCount={updatableJobsIdsCount}
        visibilityValue="hidden_forced"
        onClose={() => {
          dispatch(toggleJobActionDialog({ dialog: 'bulkForceJobVisibility', isOpen: false }));
        }}
        onSubmit={onBulkForceVisibilitySubmit}
        isSubmitting={jobActionsLoading.isBulkForcingVisibility}
        error={jobActionsErrors.bulkForceJobVisibility}
        selectAllJobs={selectAllJobs}
      />
    </>
  );
};

JobsBulkActions.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  source: PropTypes.string,
  company: OrganizationSchema,
};

JobsBulkActions.defaultProps = {
  source: 'jobs',
  company: null,
};
