import React from 'react';
import PropTypes from 'prop-types';
import { Lock } from 'lucide-react';
import { Box, Flex } from 'rebass/styled-components';

export const PrivateLock = ({ text }) => (
  <Flex height="18px" alignItems="center" sx={{ gap: '4px', svg: { color: 'purple.300' } }}>
    <Box as={Lock} size="16px" />
    <Box as="p" fontSize="12px" fontWeight="500" lineHeight="1.4" color="purple.300">
      {text}
    </Box>
  </Flex>
);

PrivateLock.defaultProps = {
  text: 'Private',
};

PrivateLock.propTypes = {
  text: PropTypes.string,
};
