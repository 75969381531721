import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text, Card } from 'rebass/styled-components';
import { Checkbox } from '@getro/rombo';
import { BoardCreator } from '../boardCreator';

export const BoardListItem = ({ board, onClick, checked, ...rest }) => (
  <Card p={3} variant="card.boardSubscription" bg={checked ? 'gray.5' : ''} {...rest}>
    <Flex my={2} flexDirection={['row']}>
      <Box flex={1}>
        <Flex flexDirection={['column']}>
          <Box display="flex" flexDirection={['row']} mb={2}>
            <Text as="h3" fontSize={2} color="text.dark">
              {board.label}
            </Text>
            {board.members && (
              <Text px={2} fontSize={1} color="text.subtle" sx={{ fontStyle: 'italic' }}>
                14 members
              </Text>
            )}
          </Box>
          {board.description && board.description.length && (
            <Text fontSize={1} mb={2} pr={3}>
              {board.description}
            </Text>
          )}
          <Box fontSize={0} color="text.subtle">
            <BoardCreator creator={board.creator} />
          </Box>
        </Flex>
      </Box>
      {onClick && (
        <Box pl={3} pb="3">
          <Checkbox
            value={board.value}
            checked={checked}
            onChange={(e) => {
              onClick(e);
            }}
          />
        </Box>
      )}
    </Flex>
  </Card>
);

BoardListItem.propTypes = {
  board: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
};

BoardListItem.defaultProps = {
  onClick: null,
  checked: false,
};
