import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { activeNetworkSelector } from '../../redux/selectors';
import usePageHook from '../../hooks/usePageHook';
import { Success } from './success';
import { Upgrade } from './upgrade';

export const ContactsUpgrade = () => {
  usePageHook('Upgrade GetroConnect');

  const network = useSelector(activeNetworkSelector);
  const { connectTier, isManager } = network;

  if (!connectTier || !isManager) {
    return <Redirect to="/" />;
  }

  if (connectTier === 'premium') return <Success network={network} />;

  return <Upgrade network={network} />;
};
