import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Flex, Heading, Text } from 'rebass/styled-components';
import { UploadImage, ProfilePicture, Checkbox, FormField, Button } from '@getro/rombo';

import { getUserDisplayName } from 'helpers/users';
import { PickerOverlay } from 'filestack-react';

const handleSubmit =
  (onSubmit) =>
  async (values, { setErrors, setSubmitting }) => {
    setSubmitting(true);
    await onSubmit(values, { setErrors, setSubmitting });
    setSubmitting(false);
  };

const RegisterForm = ({
  callToAction,
  company,
  formSchema,
  initialValues,
  network,
  onSubmit,
  subtitle,
  title,
  withPassword,
  isConnect,
}) => {
  const withCompanyStyle = useMemo(() => {
    if (company.logoUrl && company.logoUrl.length) {
      return {
        position: 'absolute',
        zIndex: 9,
        width: '40px',
        height: '40px',
        left: '60px',
        top: '40px',
        boxShadow: '0 1px 8px rgba(0, 0, 0, 0.1), 0 8px 24px rgba(0, 0, 0, 0.15)',
      };
    }

    return {};
  }, [company]);

  return (
    <>
      <Flex alignItems="center" sx={{ position: 'relative' }}>
        {network.logoUrl && !!network.logoUrl.length && (
          <>
            <ProfilePicture
              imageUrl={network.logoUrl}
              name={network.name}
              mr={4}
              variant="square"
              sx={{
                flex: '0 0',
                flexBasis: '80px',
                ...withCompanyStyle,
              }}
            />

            {company && company.logoUrl && (
              <ProfilePicture
                imageUrl={company.logoUrl}
                name={company.name}
                variant="square"
                mr={4}
                sx={{
                  flex: '0 0',
                  flexBasis: '80px',
                }}
              />
            )}
          </>
        )}
        <Heading fontWeight="bold" fontSize="6">
          {title}
        </Heading>
      </Flex>

      {subtitle.length && (
        <Text py="32px" fontSize="3" fontWeight="medium">
          {subtitle}
        </Text>
      )}

      <div className="RegisterForm__form">
        <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit(onSubmit)}>
          {({ values, setFieldValue, handleChange, isSubmitting, errors }) => (
            <Form>
              {!isConnect && (
                <div className="RegisterForm__image">
                  <ProfilePicture
                    imageUrl={values.avatarUrl}
                    name={getUserDisplayName(values)}
                    mr={4}
                    sx={{
                      width: '80px',
                    }}
                  />
                  <UploadImage
                    Picker={PickerOverlay}
                    filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
                    imageType="profile"
                    onSuccess={({ filesFailed, filesUploaded }) => {
                      if (filesFailed.length < 1) {
                        setFieldValue('avatarUrl', filesUploaded[0].url);
                      }
                    }}
                    onClear={() => setFieldValue('avatarUrl', null)}
                    currentValue={values.avatarUrl}
                  />
                </div>
              )}

              <div className="RegisterForm__row">
                <div className="RegisterForm__cell">
                  <Field type="text" name="firstName" label="First name" component={FormField} />
                </div>
                <div className="RegisterForm__cell">
                  <Field type="text" name="lastName" label="Last name" component={FormField} />
                </div>
              </div>

              {!isConnect && (
                <div className="RegisterForm__row">
                  <div className="RegisterForm__cell">
                    <Field
                      type="url"
                      name="linkedinUrl"
                      label="LinkedIn profile URL (optional)"
                      component={FormField}
                      placeholder="https://www.linkedin.com/in/..."
                    />
                  </div>
                </div>
              )}

              <div className="RegisterForm__row">
                <div className="RegisterForm__cell">
                  <Field
                    type="email"
                    name="email"
                    label="Email"
                    component={FormField}
                    disabled={!!initialValues.email}
                  />
                </div>
                {withPassword && (
                  <div className="RegisterForm__cell">
                    <Field
                      autoComplete="new-password"
                      type="password"
                      name="password"
                      label="Password"
                      component={FormField}
                    />
                  </div>
                )}
              </div>

              <div className="RegisterForm__terms">
                <Checkbox
                  checked={values.termsAccepted}
                  onChange={handleChange}
                  name="termsAccepted"
                  label={
                    <span>
                      I have read and I do accept the{' '}
                      <a href="https://www.getro.com/terms" target="_blank" rel="noopener noreferrer">
                        terms of service
                      </a>{' '}
                      and{' '}
                      <a href="https://www.getro.com/privacy" target="_blank" rel="noopener noreferrer">
                        privacy policy
                      </a>
                    </span>
                  }
                />
                {errors.termsAccepted && <div className="RegisterForm__error-message">{errors.termsAccepted}</div>}
              </div>

              <div className="RegisterForm__submit">
                <Button fontWeight="semibold" type="submit" variant="primary" loading={isSubmitting}>
                  {callToAction}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

RegisterForm.propTypes = {
  formSchema: PropTypes.object.isRequired,
  callToAction: PropTypes.string,
  company: PropTypes.shape({
    logoUrl: PropTypes.string,
    name: PropTypes.string,
  }),
  network: PropTypes.shape({
    logoUrl: PropTypes.string,
    name: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  initialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    linkedinUrl: PropTypes.string,
    password: PropTypes.string,
    termsAccepted: PropTypes.bool,
  }),
  withPassword: PropTypes.bool,
  isConnect: PropTypes.bool,
  onSubmit: PropTypes.func,
};

RegisterForm.defaultProps = {
  callToAction: 'Create account',
  company: {
    logoUrl: '',
  },
  network: {
    logoUrl: '',
  },
  subtitle: '',
  initialValues: {
    firstName: '',
    lastName: '',
    email: '',
    linkedinUrl: '',
    password: '',
    termsAccepted: false,
  },
  withPassword: false,
  isConnect: false,
  onSubmit: () => {},
};

export default RegisterForm;
