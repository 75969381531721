import React from 'react';
import { Button } from '@getro/rombo';
import { makeSelectIsLoading } from 'redux/loadingSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Download } from 'lucide-react';
import { activeNetworkSelector } from '../../../redux/selectors';
import { loadJobApplicationDownload } from '../../../hooks/useJobActions/actions';

export const DownloadJobApplications = () => {
  const currentNetwork = useSelector(activeNetworkSelector);
  const dispatch = useDispatch();
  const isDownloading = useSelector(makeSelectIsLoading('jobApplicationDownload'));

  return (
    <Button
      sx={{ border: 'none' }}
      iconGap="8px"
      loading={isDownloading}
      icon={Download}
      size="small"
      variant="secondary"
      onClick={() => dispatch(loadJobApplicationDownload({ bulk: true, activeNetwork: currentNetwork }))}
    >
      Applicants
    </Button>
  );
};

export default DownloadJobApplications;
