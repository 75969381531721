import React from 'react';
import PropTypes from 'prop-types';
import { SimplifiedModal } from '../../../atoms/simplifiedModal';
import { JobRules } from '../../../molecules/jobRules';

const ShowJobRulesModal = ({ onClose, loading, jobRules }) => (
  <SimplifiedModal isOpen onCancel={onClose} data-testid="company-job-rules-modal" variant="small" title="Job rules">
    <JobRules
      isLoading={loading}
      keywords={jobRules.keywords}
      titles={jobRules.titles}
      locations={jobRules.locations}
    />
  </SimplifiedModal>
);

ShowJobRulesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  jobRules: PropTypes.shape({
    titles: PropTypes.array,
    keywords: PropTypes.array,
    locations: PropTypes.array,
  }),
};

ShowJobRulesModal.defaultProps = {
  loading: false,
  jobRules: {
    titles: [],
    keywords: [],
    locations: [],
  },
};

export default ShowJobRulesModal;
