import { getItem } from 'helpers/store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { activeNetworkSelector } from 'redux/selectors';
import { useGetContactByIdQuery } from 'services/contactsV2';
import { useGetSharedListContactByIdQuery } from 'services/sharedListV2';
import { useGetSharedListBySlugQuery } from 'services/sharedLists';

export const useGetContactDetailContactById = ({ contactId, type }) => {
  const isNotPublic = ['default', 'read', 'matches'].includes(type);
  const { data: privateContact, isLoading: isFetchingPrivateContact } = useGetContactByIdQuery(contactId, {
    skip: type === 'public',
  });
  const network = useSelector(activeNetworkSelector);

  const { slug } = useParams();
  const {
    data: listInfo,
    isFetching: isFetchingList,
    status,
  } = useGetSharedListBySlugQuery(
    { slug },
    {
      skip: isNotPublic,
    },
  );

  const { data: publicContact, isLoading: isFetchingPublicContact } = useGetSharedListContactByIdQuery(
    {
      contactId,
      slug,
      password: listInfo?.sharedList?.passwordProtected
        ? getItem(`sharedListPwd-${slug}-${listInfo?.sharedList?.id}`)
        : '',
    },
    {
      skip: isNotPublic || isFetchingList,
    },
  );

  return {
    contact: type === 'public' ? publicContact : privateContact,
    isFetching: isFetchingPrivateContact || isFetchingPublicContact,
    status,
    network: network || listInfo?.sharedList?.collection,
  };
};
