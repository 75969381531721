import React, { useEffect, useRef, useState } from 'react';
import qs from 'query-string';
import { Flex, Box, Text, Image } from 'rebass/styled-components';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AsyncButton, ReduxSelect, Message, FormFieldError, FormikTextArea, Button } from '@getro/rombo';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Label } from '@rebass/forms/styled-components';
import { useInjectReducer } from '../../hooks/useInjectReducer';
import RocketShipIcon from '../../assets/icon-effortless-rocketship.svg';
import { useInjectSaga } from '../../hooks/useInjectSaga';
import reducer from './reducer';
import saga from './saga';
import {
  key,
  makeSelectEmailSendingError,
  makeSelectEmailSentState,
  makeSelectSharedWith,
  makeSelectShareMessage,
  makeSelectUserOptions,
} from './selectors';
import pageHoc from '../../hocs/pageHoc';
import { FocusLayout } from '../../components/layout/focus';
import {
  loadedShareUserOptions,
  loadSendShareEmail,
  loadShareUserOptions,
  noUserFoundInvite,
  resetSharePage,
} from './actions';
import { makeSelectIsLoading } from '../../redux/loadingSelector';
import { UserListItem } from '../../components/molecules/userListItem';
import { activeNetworkSelector } from '../../redux/selectors';
import useTalentGroups from '../../hooks/useTalentGroups';
import { BoardListItem } from '../../components/molecules/boardListItem';

const validationSchema = Yup.object().shape({
  shareWith: Yup.array()
    .min(1, 'Select at least one person to share your network with.')
    .required('Select at least one person to share your network with.'),
  note: Yup.string(),
  boardsToShare: Yup.array()
    .min(1, 'Select at least one list to share.')
    .required('Select at least one list to share.'),
});

const pageTitle = 'Share your talent network';

const Share = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const history = useHistory();
  const shareForm = useRef();
  const dispatch = useDispatch();

  let boardIds = qs.parse(history.location.search).talentGroups || [];
  if (!boardIds) {
    boardIds = [];
  }
  if (!Array.isArray(boardIds)) {
    boardIds = [boardIds];
  }

  const defaultValues = {
    shareWith: [],
    note: '',
    boardsToShare: boardIds.map((value) => ({ value: parseInt(value, 10) })),
  };
  // if boardsToShare is [] that needs to be re-written to be selectAll
  const activeNetwork = useSelector(activeNetworkSelector);
  const [networkTalentGroups] = useTalentGroups({ id: activeNetwork.id });
  const isEmailSuccessfullySent = useSelector(makeSelectEmailSentState);
  const isLoadingUsers = useSelector(makeSelectIsLoading('shareUserOptions'));
  const isSending = useSelector(makeSelectIsLoading('sendShareEmail'));
  const userOptions = useSelector(makeSelectUserOptions);
  const sharedWith = useSelector(makeSelectSharedWith);
  const shareMessage = useSelector(makeSelectShareMessage);
  const sendingError = useSelector(makeSelectEmailSendingError);
  const [initValues] = useState(defaultValues);

  const emptyResults = ({ inputValue }) => (
    <Flex flexDirection="column" px={3} py={4} textAlign="center">
      <Text pb={2} variant="default" fontSize={3}>
        Can&apos;t find any results for &ldquo;{inputValue}&rdquo;
      </Text>
      <Text variant="default" fontSize={2} color="text.subtle">
        To share with someone who doesn&apos;t belong to the network yet,&nbsp;
        <Button
          color="primary"
          variant="secondary"
          sx={{ textDecoration: 'none', p: 0, border: 0 }}
          as={NavLink}
          to={`/networks/${activeNetwork.slug}/members/invite`}
          onClick={() => {
            dispatch(noUserFoundInvite({ query: inputValue }));
          }}
        >
          invite them
        </Button>
        &nbsp;first
      </Text>
    </Flex>
  );

  useEffect(
    () =>
      function cleanup() {
        dispatch(resetSharePage());
      },
    [dispatch],
  );

  const onSubmit = (values) => {
    const { note, shareWith, boardsToShare } = values;
    dispatch(loadSendShareEmail({ note, shareWith, boardsToShare }));
  };

  const onShareWithInputChange = (value) => {
    dispatch(loadShareUserOptions({ query: value }));
  };

  return (
    <FocusLayout
      title={pageTitle}
      footer={
        isEmailSuccessfullySent ? null : (
          <>
            <Flex flexDirection="column">
              {sendingError && (
                <Box width={[1]} mb={3}>
                  <Message type="error" content={sendingError} />
                </Box>
              )}
              <Flex width={[1]} justifyContent={['flex-end']}>
                <AsyncButton
                  loading={isSending}
                  variant="primary"
                  ml={[2]}
                  onClick={() => {
                    shareForm.current.handleSubmit();
                  }}
                >
                  Send
                </AsyncButton>
              </Flex>
            </Flex>
          </>
        )
      }
    >
      {isEmailSuccessfullySent && (
        <Flex flexDirection="column" textAlign="center" alignItems="center">
          <Image width="80px" src={RocketShipIcon} pb={[3]} />
          <Text variant="default" mt={1} mb={2} fontSize={3} fontWeight="semibold" color="text.dark">
            The network has been shared with {sharedWith.length} {sharedWith.length === 1 ? 'person' : 'people'}
          </Text>
          <Text variant="default" fontSize={2} mb={3}>
            {shareMessage}
          </Text>
          <Button mt={1} as={NavLink} to={`/networks/${activeNetwork.slug}/members`}>
            Done
          </Button>
        </Flex>
      )}
      {!isEmailSuccessfullySent && (
        <Flex flexDirection="column">
          <Text mb={2}>
            Share your talent network with other network administrators and companies in your network to let them know
            and recommend that they subscribe to network updates.
          </Text>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            innerRef={shareForm}
            onSubmit={onSubmit}
            initialValues={initValues}
          >
            {({ errors, values, setFieldValue, setFieldError }) => (
              <Flex as={Form} sx={{ flexDirection: ['column'] }}>
                <Box width={[1]} pt={3} mb={2}>
                  <Label htmlFor="shareWith">Share with</Label>
                  <ReduxSelect
                    value={values.shareWith}
                    id="shareWith"
                    name="shareWith"
                    placeholder="Search network and company admins"
                    label="Share With"
                    isMulti
                    components={{ ClearIndicator: null }}
                    error={errors.shareWith}
                    onChange={(e) => {
                      setFieldValue('shareWith', e, false);
                      setFieldError('shareWith', null);
                      dispatch(loadedShareUserOptions({ userOptions: [] }));
                    }}
                    noOptionsMessage={({ inputValue }) => {
                      if (!inputValue || inputValue.length === 0) {
                        return 'Start typing to search';
                      }
                      return emptyResults({ inputValue });
                    }}
                    formatOptionLabel={(user) => <UserListItem user={user} />}
                    onInputChange={onShareWithInputChange}
                    isLoading={isLoadingUsers}
                    options={userOptions}
                  />
                </Box>
                {values.shareWith && values.shareWith.length > 0 && (
                  <Flex width={[1]} flexDirection="column">
                    {values.shareWith.map((user) => (
                      <UserListItem
                        key={user.value || user.id}
                        my={1}
                        width={[1]}
                        user={user}
                        onRemove={(member) => {
                          const newUsers = values.shareWith.filter((u) => u.value !== member.value);
                          setFieldValue('shareWith', newUsers, false);
                        }}
                      />
                    ))}
                  </Flex>
                )}
                <Box width={[1]} pt={3}>
                  <FormikTextArea label="Personal note" name="note" placeholder="Add a personal note to your email" />
                </Box>
                {networkTalentGroups && (
                  <Box width={[1]} pt={3}>
                    <Text fontWeight="medium" fontSize={2} mb={2}>
                      Select lists
                    </Text>
                    <Text fontSize={1} mb={3}>
                      Select one or more lists to invite receivers to subscribe.
                    </Text>
                    <Text fontSize={1}>
                      LISTS ({values.boardsToShare.length} OF {networkTalentGroups && networkTalentGroups.length})
                    </Text>
                    {errors.boardsToShare && <FormFieldError error={errors.boardsToShare} />}

                    {networkTalentGroups.map((board, index) => (
                      <BoardListItem
                        key={JSON.stringify(board)}
                        sx={{ boxShadow: index === networkTalentGroups.length - 1 ? 'none' : '' }}
                        onClick={(e) => {
                          const { checked } = e.target;
                          let selectedItems = values.boardsToShare;
                          if (checked) {
                            selectedItems.push(board);
                          } else {
                            selectedItems = selectedItems.filter((b) => b.value !== board.value);
                          }
                          setFieldError('boardsToShare', null);
                          setFieldValue('boardsToShare', selectedItems, false);
                        }}
                        board={board}
                        checked={values.boardsToShare.filter((b) => b.value === board.value).length > 0}
                      />
                    ))}
                  </Box>
                )}
                <Button variant="variants.srOnly" type="submit">
                  Send
                </Button>
              </Flex>
            )}
          </Formik>
        </Flex>
      )}
    </FocusLayout>
  );
};

export default pageHoc({ useMainLayout: false, title: pageTitle })(Share);
