import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { defaultFilters } from 'components/organisms/contactsFilters/defaultFilters';
import { useGetContactsQuery } from 'services/contactsV2';
import { useContactsSearchParam } from './useContactsSearchParam';

export const useFetchContacts = (allList = true) => {
  const { listId } = useParams();
  const [queryParams, setQueryParams] = useContactsSearchParam({
    filters: defaultFilters,
    listId: allList ? null : listId,
    ...(listId ? { contextualizedToSharedListId: listId } : undefined),
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    data = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetContactsQuery(queryParams, { refetchOnMountOrArgChange: true });
  return {
    listId: listId ? parseInt(listId, 10) : null,
    queryParams,
    setQueryParams,
    selectedRows,
    setSelectedRows,
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
