export const useListTitle = ({ isEdit, smartOption }) => {
  let title = isEdit ? 'List settings' : 'Create list';

  if (smartOption === 'matches') {
    title = 'Set up AI matches';
  }

  if (smartOption === 'autoUpdate') {
    title = 'Set up Auto-update';
  }
  return title;
};
