import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Message, Button, FormToggle } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import { Eye, EyeOff } from 'lucide-react';
import { useSelector } from 'react-redux';

import { trackEvent } from '../../../helpers/analytics';
import { activeNetworkSelector } from '../../../redux/selectors';
import SharedListLink from './sharedListLink';
import PointOfContact from './pointOfContact';
import { useFocusTrapState } from '../../../hooks/useFocusTrapState';
import ContactsSharedListSchema from '../../../schemas/contactsSharedList';

export const ShareSharedListModal = ({ list, isLoading, onEditSharedList, onClose }) => {
  const { pauseFocusTrap, onDropDownStateChange } = useFocusTrapState();
  const activeNetwork = useSelector(activeNetworkSelector);
  const [shareable, setShareable] = useState(list.shareable);
  const [pwdVisible, setPwdVisible] = useState(false);
  const [pwdValue, setPwdValue] = useState('');
  const [pointOfContact, setPointOfContact] = useState({
    label: list.pointOfContact.fullName,
    value: list.pointOfContact.id,
  });
  const listUrl = `${window.location.origin}/app/shared-lists/${list?.slug}`;
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [showPassword, setShowPassword] = useState(list.passwordProtected);
  const [showPointOfContact, setShowPointOfContact] = useState(list.showPointOfContact);

  const onSave = async () => {
    const done = await onEditSharedList(
      {
        shareable,
        passwordProtected: true,
        password: pwdValue,
        pointOfContactId: pointOfContact.value,
        showPointOfContact,
      },
      {
        closeModal: false,
        updateMessage: 'List updated',
      },
    );

    if (done) {
      setPasswordChanged(true);
      trackEvent('contacts:list:enable_password', {
        collection_id: activeNetwork.id,
        list_id: list.id,
      });
    }
  };

  const onPasswordChange = (e) => {
    const { checked } = e.target;

    if (!checked && list.passwordProtected) {
      onEditSharedList(
        {
          shareable: list.shareable,
          passwordProtected: false,
          password: '',
          pointOfContactId: pointOfContact.value,
          showPointOfContact,
        },
        {
          closeModal: false,
        },
      );
      setShowPassword(false);
      setPwdValue('');
      return;
    }

    setShowPassword(!showPassword);
  };

  const onChangeShareable = ({ target }) => {
    setShareable(target.checked);
    onEditSharedList(
      {
        shareable: target.checked,
        passwordProtected: false,
        password: '',
        pointOfContactId: pointOfContact.value,
        showPointOfContact,
      },
      {
        closeModal: false,
      },
    );
    setPwdValue('');
    setShowPassword(false);

    if (target.checked) {
      trackEvent('contacts:list:enable_shareable_url', {
        collection_id: activeNetwork.id,
        list_id: list.id,
      });
    }
  };

  const onChangePointOfContact = (selectedPointOfContact) => {
    setPointOfContact(selectedPointOfContact);
    onEditSharedList(
      {
        shareable,
        passwordProtected: showPassword,
        password: pwdValue,
        pointOfContactId: selectedPointOfContact.value,
        showPointOfContact,
      },
      {
        closeModal: false,
        updateMessage: 'List updated',
      },
    );
  };

  const onShowPointOfContactChange = ({ target }) => {
    setShowPointOfContact(target.checked);
    onEditSharedList(
      {
        shareable,
        passwordProtected: showPassword,
        password: pwdValue,
        pointOfContactId: pointOfContact.value,
        showPointOfContact: target.checked,
      },
      {
        closeModal: false,
        updateMessage: 'List updated',
      },
    );
  };

  const onCloseModal = () => {
    setPwdValue('');
    onClose();
  };

  return (
    <Modal onCancel={onCloseModal} isOpen title={`Share ${list.name}`} pauseFocusTrap={pauseFocusTrap}>
      <>
        <Box sx={{ borderRadius: '4px' }}>
          <FormToggle
            label="Shared list view"
            onChange={onChangeShareable}
            helpText="A real-time view for external sharing. Accessible only to those with the link (and password if set), and won't appear in search engines."
            field={{
              id: 'shareable-url',
              name: 'shareable-url',
              value: shareable,
            }}
            form={{
              setFieldValue: (_, value) => onChangeShareable({ target: { checked: value } }),
            }}
          />
          {shareable && (
            <>
              <Box mt="8px">
                <SharedListLink listUrl={listUrl} />
                <PointOfContact
                  value={pointOfContact}
                  onChange={onChangePointOfContact}
                  onDropDownStateChange={onDropDownStateChange}
                  showPointOfContact={showPointOfContact}
                  onShowPointOfContactChange={onShowPointOfContactChange}
                />
                <FormToggle
                  label="Password protection"
                  onChange={onChangeShareable}
                  helpText="Visitors will have to enter a password to see the list."
                  field={{
                    id: 'pwd-protection',
                    name: 'pwd-protection',
                    value: showPassword,
                  }}
                  form={{
                    setFieldValue: (_, value) => onPasswordChange({ target: { checked: value } }),
                  }}
                />
                {showPassword && (
                  <Box mt="8px">
                    <Flex sx={{ gap: '8px' }}>
                      <Box sx={{ position: 'relative', flexGrow: '1' }}>
                        <Input
                          type={pwdVisible ? 'text' : 'password'}
                          value={pwdValue}
                          onChange={(event) => {
                            setPasswordChanged(false);
                            setPwdValue(event.target.value);
                          }}
                          data-testid="password"
                        />
                        <Box
                          onClick={() => setPwdVisible((current) => !current)}
                          sx={{
                            position: 'absolute',
                            top: '0',
                            bottom: '0',
                            right: '16px',
                            height: '16px',
                            margin: 'auto',
                            cursor: 'pointer',
                          }}
                        >
                          <Box as={pwdVisible ? Eye : EyeOff} width="16px" height="16px" aria-hidden="true" />
                        </Box>
                      </Box>
                      <Box maxWidth="87px">
                        <Button
                          variant="primary"
                          disabled={!pwdValue || passwordChanged}
                          data-testid="button-save"
                          loading={isLoading && !!pwdValue}
                          type="submit"
                          onClick={onSave}
                        >
                          Save
                        </Button>
                      </Box>
                    </Flex>
                  </Box>
                )}
              </Box>
              {list.passwordProtected && pwdValue && !passwordChanged && (
                <Box mt="8px">
                  <Message content="The old password won’t work anymore after updating it." />
                </Box>
              )}
            </>
          )}
        </Box>
      </>
    </Modal>
  );
};

ShareSharedListModal.propTypes = {
  list: ContactsSharedListSchema.isRequired,
  isLoading: PropTypes.bool,
  onEditSharedList: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ShareSharedListModal.defaultProps = {
  isLoading: false,
};
