import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { convertStringBackToQuery, useContactsSearchParam } from 'pages/contactsExtended/hooks/useContactsSearchParam';
import { setPageTitle } from '../../actions/pageActions';
import { setActiveNetwork } from '../../actions/networksActions';
import { defaultFilters } from '../../components/organisms/contactsFilters/defaultFilters';
import { getItem, storeItem } from '../../helpers/store';
import { useGetSharedListBySlugQuery } from '../../services/sharedLists';
import { useLazyGetContactsFromSharedListQuery } from '../../services/sharedListV2';

const useSharedLists = () => {
  const defaultQueryParams = { filters: defaultFilters };
  const { slug } = useParams();
  const { data: listInfo, isFetching: isFetchingPreview, status } = useGetSharedListBySlugQuery({ slug });
  const [getContactsFromSharedList, { data, isFetching }] = useLazyGetContactsFromSharedListQuery();
  const [isPreviewReady, setIsPreviewReady] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [queryParams, setQueryParams] = useContactsSearchParam(defaultQueryParams);
  const [showAuthentication, setShowAuthentication] = useState(false);
  const dispatch = useDispatch();
  const activeNetwork = listInfo?.sharedList?.collection;

  useEffect(() => {
    if (!isFetchingPreview && !isPreviewReady && listInfo?.sharedList?.id) {
      setIsPreviewReady(true);
      dispatch(setPageTitle(listInfo?.sharedList?.name));
      dispatch(setActiveNetwork(listInfo?.sharedList?.collection?.id));

      if (listInfo?.sharedList?.passwordProtected) {
        const shareListPwd = getItem(`sharedListPwd-${slug}-${listInfo?.sharedList?.id}`);

        if (shareListPwd) {
          getContactsFromSharedList({ slug, params: queryParams, password: shareListPwd });
          setIsInitialized(true);
        } else {
          setShowAuthentication(true);
        }
      } else {
        getContactsFromSharedList({ slug, params: queryParams });
        setIsInitialized(true);
      }
    }
  }, [isFetchingPreview, listInfo, isPreviewReady, activeNetwork, queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handler = () => {
      const shareListPwd = getItem(`sharedListPwd-${slug}-${listInfo?.sharedList?.id}`);

      getContactsFromSharedList(
        {
          slug,
          params: window.location.search ? convertStringBackToQuery(window.location.search) : queryParams,
          password: shareListPwd,
        },
        {},
      );
    };

    window.addEventListener('popstate', handler);

    return () => {
      window.removeEventListener('popstate', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listInfo, slug]);

  const onSetQueryParams = (params) => {
    const shareListPwd = getItem(`sharedListPwd-${slug}-${listInfo?.sharedList?.id}`);

    getContactsFromSharedList({ slug, params, password: shareListPwd });
    setQueryParams(params);
  };

  const onNextPage = () => {
    if (isFetchingPreview || data?.meta?.queryTotal <= data?.contacts?.length) return;

    onSetQueryParams({ ...queryParams, page: Math.floor(data?.contacts?.length / 20) + 1 });
  };

  const onSubmitPassword = async (value) => {
    const result = await getContactsFromSharedList({ slug, params: queryParams, password: value });

    if (!result.isError) {
      storeItem(`sharedListPwd-${slug}-${listInfo?.sharedList?.id}`, value);
      setIsInitialized(true);
      setShowAuthentication(false);
    }

    return result;
  };

  return {
    isInitialized,
    isFetching,
    listInfo: listInfo ? listInfo.sharedList : {},
    contacts: data?.contacts ?? [],
    meta: data?.meta || {},
    slug,
    queryParams: queryParams || defaultQueryParams,
    setQueryParams: onSetQueryParams,
    nextPage: onNextPage,
    showAuthentication,
    setShowAuthentication,
    onSubmitPassword,
    status,
    activeNetwork,
  };
};

export default useSharedLists;
