import React from 'react';
import { MoreVertical } from 'lucide-react';
import PropTypes from 'prop-types';
import { Card, Flex, Box, Text } from 'rebass/styled-components';
import { Checkbox, ProfilePicture, Social, Tag, Button } from '@getro/rombo';
import { getUserDisplayName } from '../../../helpers/users';
import { trackEvent } from '../../../helpers/analytics';
import NetworkSchema from '../../../schemas/network';
import AddToBoards from '../AddToBoards';
import { isLoadingApi } from '../../../pages/Members/helpers';
import GroupSchema from '../../../schemas/group';
import Dropdown from '../../atoms/Dropdown';
import DropdownItem from '../../atoms/DropdownItem';
import MakeIntroModal from '../MakeIntroModal';
import RequestIntroModal from '../RequestIntroModal/RequestIntroModal';

export const MemberListCard = ({
  member,
  skillsDeactivated,
  activeNetwork,
  networkTalentGroups,
  onCreateAndAddMemberToList,
  onBoardSelectionChanged,
  isCreating,
  addToBoardError,
  openVouch,
  openVerify,
  onMemberClick,
  updateIntroduction,
}) => {
  const { isConnector: isAm, isManager: isNm } = activeNetwork || {};

  const canVerifyMembers = isAm || isNm;
  const hasDropDownMenu =
    (canVerifyMembers && !member.verified) || isNm || (isNm && !member.introduction) || !member.vouch;
  const handleSocialClick = (platform) => {
    trackEvent('members:click_profile_link', {
      member_id: member.id,
      origin: 'search_members',
      platform,
      collection_id: member.active,
    });
  };

  let memberSkills = [];
  if (!skillsDeactivated && member.skills && !!member.skills.length) {
    memberSkills = member.skills.map((skill) => skill.name);
  }

  let memberBoards = [];
  if (member.talentGroups && member.talentGroups.length) {
    memberBoards = member.talentGroups.map((board) => ({ label: board.name, id: board.id }));
  }

  return (
    <Card variant="card.link">
      <Flex flexDirection="column">
        <Box width={[1]} pb={[4]}>
          <Flex flexDirection="row">
            <Box width="80px" height="80px">
              <ProfilePicture
                imageUrl={member.avatarUrl}
                name={getUserDisplayName(member)}
                verified={member.verified}
                sx={{ backgroundSize: 'cover!important' }}
              />
            </Box>

            <Box pl={3} flex="1">
              <Flex flexDirection="column">
                <Box display="flex">
                  <Button
                    flexGrow={1}
                    textAlign="left"
                    onClick={onMemberClick}
                    variant="cardLink"
                    sx={{ p: 0, fontSize: 3 }}
                  >
                    {getUserDisplayName(member)}
                  </Button>
                  <Box>
                    {hasDropDownMenu && (
                      <Dropdown
                        direction="left"
                        className="MemberV2__dropdown"
                        trigger={
                          <button type="button" className="MemberV2__dropdown-button">
                            <Box
                              as={MoreVertical}
                              strokeWidth="1.5"
                              aria-hidden="true"
                              className="MemberV2__dropdown-icon"
                            />
                          </button>
                        }
                      >
                        <DropdownItem onClick={openVouch} disabled={member.vouch}>
                          Vouch
                        </DropdownItem>

                        {canVerifyMembers && !member.verified && (
                          <DropdownItem onClick={openVerify}>Verify</DropdownItem>
                        )}

                        {isNm && member.sharePermission && (
                          <MakeIntroModal member={member} activeNetwork={activeNetwork}>
                            <DropdownItem>Share</DropdownItem>
                          </MakeIntroModal>
                        )}

                        {isNm && !member.introductionRequested && (
                          <RequestIntroModal
                            member={member}
                            updateIntroduction={updateIntroduction}
                            activeNetwork={activeNetwork}
                          >
                            <DropdownItem>Request introduction</DropdownItem>
                          </RequestIntroModal>
                        )}
                      </Dropdown>
                    )}
                  </Box>
                </Box>
                {memberSkills.length > 0 && (
                  <Text color="gray.1" mb={2}>
                    {memberSkills.join(', ')}
                  </Text>
                )}
                {memberBoards.length > 0 && (
                  <Flex flexWrap="wrap" mt={[-2]} pb={[2]} pt={[memberSkills.length ? 0 : 2]} width={[1]}>
                    {memberBoards.map((board) => (
                      <Tag fontSize={1} mt={2} key={board.id}>
                        {board.label}
                      </Tag>
                    ))}
                  </Flex>
                )}

                {member.bio && <Text py={2}>{member.bio}</Text>}

                <Box
                  as={Social}
                  email={member.isNetworkManager ? member.email : null}
                  github={member.githubUrl}
                  linkedin={member.linkedinUrl}
                  resume={member.resumeUrl}
                  twitter={member.twitterUrl}
                  website={member.websiteUrl}
                  dribbble={member.dribbbleUrl}
                  onClick={handleSocialClick}
                />
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box width={[1]} pt={[4]} sx={{ borderTop: 1, borderColor: 'gray.3' }}>
          <Flex flexDirection={['column']}>
            <Box alignSelf="flex-end">
              {isNm && (
                <AddToBoards
                  itemTemplate={({ onChange, label, value }) => (
                    <Checkbox
                      isLoading={!!isLoadingApi(member, value)}
                      value={value}
                      checked={member.talentGroups.find((g) => g.id === value)}
                      onChange={onChange}
                      label={label}
                      containerProps={{ mt: 2 }}
                    />
                  )}
                  member={member}
                  networkTalentGroups={networkTalentGroups}
                  onCreateAndAddMemberToList={({ name }) => {
                    onCreateAndAddMemberToList({ member, name });
                  }}
                  onBoardSelectionChanged={onBoardSelectionChanged}
                  creating={isCreating}
                  error={addToBoardError}
                />
              )}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};

MemberListCard.propTypes = {
  member: PropTypes.object.isRequired,
  skillsDeactivated: PropTypes.bool,
  activeNetwork: NetworkSchema.isRequired,
  networkTalentGroups: PropTypes.arrayOf(GroupSchema),
  onCreateAndAddMemberToList: PropTypes.func.isRequired,
  onBoardSelectionChanged: PropTypes.func.isRequired,
  isCreating: PropTypes.bool,
  addToBoardError: PropTypes.string,
  openVouch: PropTypes.func.isRequired,
  openVerify: PropTypes.func.isRequired,
  updateIntroduction: PropTypes.func.isRequired,
  onMemberClick: PropTypes.func.isRequired,
};

MemberListCard.defaultProps = {
  networkTalentGroups: null,
  skillsDeactivated: false,
  isCreating: false,
  addToBoardError: null,
};
