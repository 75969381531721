import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/molecules/Filter';
import OptionsSchema from '../../../schemas/options';

const VisibilityFilter = ({
  value,
  onChange,
  visibilityOptions,
  embeddedInModal,
  onDropDownStateChange,
  showLabel,
}) => {
  const selectedOption = useMemo(() => visibilityOptions.find((i) => i.value === value), [value, visibilityOptions]);
  return (
    <Filter
      showLabel={showLabel}
      label="Visibility"
      placeholder="Visibility"
      name="visibility"
      value={selectedOption}
      onChange={onChange}
      options={[...visibilityOptions]}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

VisibilityFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  visibilityOptions: OptionsSchema.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
  showLabel: PropTypes.bool,
};

VisibilityFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
  showLabel: true,
};

export default VisibilityFilter;
