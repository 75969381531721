export const mapCompanyHeadCountIntervals = (data) => {
  const value = parseInt(data, 10);

  if (value < 11) {
    return '1 - 10';
  }
  if (value < 51) {
    return '11 - 50';
  }
  if (value < 201) {
    return '51 - 200';
  }
  if (value < 501) {
    return '201 - 500';
  }
  if (value < 5001) {
    return '1001 - 5000';
  }
  return '5001+';
};
