import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown as DropdownSemantic } from 'semantic-ui-react';

import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/transition.min.css';

const handleChange =
  (onChange) =>
  (e, { value, name }) =>
    onChange({ target: { value, name } });

const Dropdown = ({
  children,
  className,
  labeled,
  name,
  onChange,
  options,
  placeholder,
  size,
  trigger,
  value,
  ...rest
}) => {
  if (trigger) {
    return (
      <div className={`Dropdown ${className} ${labeled ? 'Dropdown--labeled' : ''}`}>
        <DropdownSemantic trigger={trigger} {...rest}>
          <DropdownSemantic.Menu className="Dropdown__menu">{children}</DropdownSemantic.Menu>
        </DropdownSemantic>
      </div>
    );
  }

  return (
    <DropdownSemantic
      className={`${className} Dropdown__${size} ${labeled ? 'Dropdown--labeled' : ''}`}
      placeholder={placeholder}
      fluid
      selection
      name={name}
      options={options}
      value={value}
      selectOnBlur={false}
      onChange={handleChange(onChange)}
      {...rest}
    />
  );
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  labeled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.shape(),
    }),
  ),
  placeholder: PropTypes.string,
  size: PropTypes.string,
  trigger: PropTypes.node,
  value: PropTypes.string,
};

Dropdown.defaultProps = {
  children: null,
  className: '',
  labeled: false,
  name: null,
  onChange: () => {},
  options: [],
  placeholder: '',
  size: 'mini',
  trigger: null,
  value: '',
};

export default Dropdown;
