export function clearStorage() {
  window.localStorage.clear();
}

export function getItem(key) {
  if (!window.localStorage) {
    return;
  }

  try {
    // eslint-disable-next-line consistent-return
    return window.localStorage.getItem(key);
  } catch (err) {
    throw new Error(`Error getting item ${key} from localStorage`);
  }
}

export function storeItem(key, item) {
  if (!window.localStorage) {
    return;
  }

  try {
    // eslint-disable-next-line consistent-return
    return window.localStorage.setItem(key, item);
  } catch (err) {
    throw new Error(`Error storing item ${key} to localStorage`);
  }
}

export function removeItem(key) {
  if (!window.localStorage) {
    return;
  }

  try {
    // eslint-disable-next-line consistent-return
    return window.localStorage.removeItem(key);
  } catch (err) {
    throw new Error(`Error removing item ${key} from localStoragee`);
  }
}
