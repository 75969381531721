import React from 'react';
import PropTypes from 'prop-types';
import { TableVirtuoso } from 'react-virtuoso';
import { Box } from 'rebass/styled-components';

import { TableHeader } from './header';
import { TableRow } from './row';

export const Table = ({ data, onLoadMore }) => (
  <Box
    as={TableVirtuoso}
    width="100%"
    height="400"
    data={data}
    itemContent={(index, hire) => <TableRow index={index} hire={hire} />}
    fixedHeaderContent={TableHeader}
    increaseViewportBy={400}
    endReached={onLoadMore}
    useWindowScroll
    sx={{
      overflowX: ['auto', 'unset'],
      '&& table': { width: '100%' },
      '&& th, && td': { pr: '8px' },
      '&& th:last-child, && td:last-child': { pr: 0 },
    }}
  />
);

Table.propTypes = {
  data: PropTypes.array,
  onLoadMore: PropTypes.func.isRequired,
};

Table.defaultProps = {
  data: [],
};
