import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/molecules/Filter';
import OptionsSchema from '../../../schemas/options';

const StatusFilter = ({ value, statusOptions, onChange, embeddedInModal, onDropDownStateChange, showLabel }) => {
  const selectedOption = useMemo(() => statusOptions.find((option) => option.value === value), [statusOptions, value]);
  const options = [...statusOptions];

  return (
    <Filter
      showLabel={showLabel}
      label="Status"
      name="status"
      placeholder="Status"
      value={selectedOption}
      onChange={onChange}
      options={options}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

StatusFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  statusOptions: OptionsSchema.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
  showLabel: PropTypes.bool,
};

StatusFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
  showLabel: true,
};

export default StatusFilter;
