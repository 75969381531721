import strings from '../strings';

export default function getOcpStatus(status) {
  switch (status) {
    case 'automatic':
      return {
        statusColor: 'success',
        status: 'Daily update',
        explanationText: strings.careerPageStatusExplanations[status],
      };
    case 'manual':
      return {
        statusColor: 'success',
        status: 'Monthly update',
        explanationText: strings.careerPageStatusExplanations[status],
      };
    case 'brand_new':
      return {
        statusColor: 'blue',
        status: 'Under review',
        explanationText: strings.careerPageStatusExplanations[status],
      };
    case 'requires_verification':
      return {
        statusColor: 'blue',
        status: 'Under review',
        explanationText: strings.careerPageStatusExplanations[status],
      };
    case 'blocked':
      return {
        statusColor: 'blue',
        status: 'Under review',
        explanationText: strings.careerPageStatusExplanations[status],
      };
    case 'requires_retry':
      return {
        statusColor: 'blue',
        status: 'Under review',
        explanationText: strings.careerPageStatusExplanations[status],
      };
    case 'errored':
      return {
        statusColor: 'blue',
        status: 'Under review',
        explanationText: strings.careerPageStatusExplanations[status],
      };
    case 'unidentifiable':
      return {
        statusColor: 'blue',
        status: 'Under review',
        explanationText: strings.careerPageStatusExplanations[status],
      };
    case 'bypassed':
      return {
        statusColor: 'gray.2',
        status: 'Excluded',
        explanationText: strings.careerPageStatusExplanations[status],
      };
    default:
      return { statusColor: 'transparent', status, explanationText: null };
  }
}
