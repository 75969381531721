import React, { forwardRef } from 'react';
import { AlertTriangle, CheckCircle, Info, XCircle } from 'lucide-react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

export const MessageIcon = forwardRef(({ type, ...rest }, ref) => {
  const style = {
    icon: Info,
    color: 'info',
  };

  if (type === 'success') {
    style.icon = CheckCircle;
    style.color = 'success';
  } else if (type === 'warning') {
    style.icon = AlertTriangle;
    style.color = 'warning';
  } else if (type === 'error') {
    style.icon = XCircle;
    style.color = 'danger';
  }

  return (
    <Flex ref={ref} color={style.color} alignItems="center" flex="0 0 auto">
      <Box as={style.icon} strokeWidth="1.5" aria-hidden="true" {...rest} />
    </Flex>
  );
});

MessageIcon.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
};

MessageIcon.defaultProps = {
  type: 'info',
};
