import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'networkSettings';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const makeSelectGeneralSettings = createSelector(rootSelector, (state) => state.general.settings);
export const makeSelectGeneralSettingsError = createSelector(rootSelector, (state) => state.general.errorLoad);
export const makeSelectGeneralSettingsLoaded = createSelector(
  rootSelector,
  makeSelectGeneralSettingsError,
  (state, loadError) => !loadError && state.general.settings,
);
export const makeSelectGeneralSettingsSaveSucceed = createSelector(rootSelector, (state) => state.general.successSave);
export const makeSelectGeneralSettingsSaveError = createSelector(
  rootSelector,
  (state) => state.general.errorSave !== null,
);
export const makeSelectGdprSettingsSaveSucceed = createSelector(rootSelector, (state) => state.general.successGdprSave);
export const makeSelectGdprSettingsSaveError = createSelector(rootSelector, (state) => !!state.general.errorGdprSave);

export const talentNetworkSelector = createSelector(rootSelector, (state) => state.talentNetwork);
