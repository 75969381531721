import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Label } from '@rebass/forms';
import { FormLabel, Select } from '@getro/rombo';
import OptionsSchema from '../../../schemas/options';

const Filter = ({
  name,
  value,
  onChange,
  options,
  placeholder,
  embeddedInModal,
  onDropDownStateChange,
  label,
  showLabel,
  ...otherSelectAttributes
}) => (
  <Box>
    {showLabel && label && (
      <Flex as={Label} flexDirection="column" htmlFor={name} data-testid={label}>
        <FormLabel>{label}</FormLabel>
      </Flex>
    )}

    <Select
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      anchorToBody={embeddedInModal}
      onMenuOpen={() => onDropDownStateChange('open')}
      onMenuClose={() => onDropDownStateChange('close')}
      {...otherSelectAttributes}
    />
  </Box>
);

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: OptionsSchema.isRequired,
  placeholder: PropTypes.string,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
  showLabel: PropTypes.bool,
};

Filter.defaultProps = {
  label: '',
  placeholder: 'Any',
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
  showLabel: true,
};

export default Filter;
