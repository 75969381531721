import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { jobFunctionsSelector } from 'redux/selectors';
import { fetchJobFunctionsSuccess } from 'actions/jobFunctionsActions';
import { get } from 'api';
import { trackException } from 'helpers/errorTracker';

const useJobFunctions = () => {
  const jobFunctions = useSelector(jobFunctionsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    let cancelTokenSource;

    const cleanUp = () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }
      mounted = false;
    };

    const fetchJobFunctions = async () => {
      cancelTokenSource = axios.CancelToken.source();

      try {
        const response = await get('/job_functions', {
          cancelToken: cancelTokenSource.token,
        });
        if (response && response.data && mounted) {
          const jobFunctionsOptions = response.data.items
            .map((j) => ({ value: j.id, label: j.name }))
            .sort((a, b) => a.label.localeCompare(b.label));
          dispatch(fetchJobFunctionsSuccess(jobFunctionsOptions));
        }
      } catch (error) {
        trackException(error);
      }
    };

    (async () => {
      if (jobFunctions.length === 0) {
        fetchJobFunctions();
      }
    })();

    return cleanUp;
  }, [jobFunctions, dispatch]);

  return [jobFunctions];
};

export default useJobFunctions;
