import React from 'react';
import { Box, Flex } from 'rebass/styled-components';

export const GeneratingMatchesLoader = () => (
  <Flex
    sx={{
      position: 'relative',
      width: '64px',
      height: '64px',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className="loader-wand"
    >
      <g opacity="0.5">
        <path
          d="M18.6666 9.33268L22.6666 13.3327M6.66665 7.99935V13.3327M25.3333 18.666V23.9993M13.3333 2.66602V5.33268M9.33331 10.666H3.99998M28 21.3327H22.6666M14.6666 3.99935H12M28.8533 4.85268L27.1466 3.14602C26.9966 2.99444 26.818 2.87411 26.6212 2.79199C26.4244 2.70987 26.2132 2.66758 26 2.66758C25.7867 2.66758 25.5756 2.70987 25.3787 2.79199C25.1819 2.87411 25.0033 2.99444 24.8533 3.14602L3.14665 24.8527C2.99507 25.0027 2.87474 25.1813 2.79262 25.3781C2.7105 25.5749 2.66821 25.7861 2.66821 25.9993C2.66821 26.2126 2.7105 26.4238 2.79262 26.6206C2.87474 26.8174 2.99507 26.996 3.14665 27.146L4.85331 28.8527C5.00241 29.0059 5.18069 29.1277 5.37764 29.2108C5.57458 29.294 5.7862 29.3368 5.99998 29.3368C6.21376 29.3368 6.42537 29.294 6.62232 29.2108C6.81927 29.1277 6.99755 29.0059 7.14665 28.8527L28.8533 7.14602C29.0065 6.99692 29.1283 6.81864 29.2115 6.62169C29.2946 6.42474 29.3374 6.21313 29.3374 5.99935C29.3374 5.78557 29.2946 5.57395 29.2115 5.37701C29.1283 5.18006 29.0065 5.00178 28.8533 4.85268Z"
          stroke="#4900FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
    <Box
      as="svg"
      className="loader-circle"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      color="#8A75F0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
      }}
    >
      <defs>
        <linearGradient id="spinner-secondHalf2">
          <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0" stopColor="currentColor" />
        </linearGradient>
        <linearGradient id="spinner-firstHalf2">
          <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0" stopColor="currentColor" />
        </linearGradient>
      </defs>

      <g strokeWidth="4">
        <path stroke="url(#spinner-secondHalf2)" d="M 2 32 A 30 30 0 0 1 62 32" />
        <path stroke="url(#spinner-firstHalf2)" d="M 62 32 A 30 30 0 0 1 2 32" />

        <path stroke="currentColor" strokeLinecap="round" d="M 2 32 A 30 30 0 0 1 2 30" />
      </g>

      <animateTransform
        from="0 0 0"
        to="360 0 0"
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1300ms"
      />
    </Box>
  </Flex>
);
