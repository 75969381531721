import React from 'react';

import { MessageBlock, Box } from '@getro/rombo';

const NoAccess = () => (
  <Box>
    <MessageBlock
      type="warning"
      title="Adding companies unavailable"
      content="Only network admins can see this screen."
    />
  </Box>
);

export default NoAccess;
