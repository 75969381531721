import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import Popover from '../../atoms/popoverDeprecated';
import LayoutSecondaryActionsSchema from '../../../schemas/layoutSecondaryActions';

const renderSecondaryAction = ({ action, icon, text, popoverContent }, index) => {
  const button = action ? (
    <Button ml="auto" pt="2px" pr="0" size="small" variant="styleless" onClick={action} key={text}>
      {icon && (
        <Box
          as={icon}
          strokeWidth="1.5"
          aria-hidden="true"
          mr="1"
          width="1em"
          height="1em"
          sx={{ verticalAlign: 'middle' }}
        />
      )}
      {text}
    </Button>
  ) : (
    <Text key={text}>{text}</Text>
  );

  if (popoverContent) {
    return <Popover trigger={button} content={popoverContent} key={index} data-cy="page-layout-popover" />;
  }

  return button;
};

const PageLayoutContent = ({
  buttonOnClick,
  buttonText,
  children,
  fullWidth,
  header,
  preHeader,
  secondaryActions,
  secondaryActionProps,
  contentHeader,
}) => {
  const showButton = buttonOnClick && buttonText;
  return (
    <Flex flexDirection="column" flex="1" px="16px">
      {contentHeader}
      <Box
        sx={!fullWidth ? { maxWidth: 800, width: '100%', margin: '0px auto' } : {}}
        className={`PageLayoutContent ${fullWidth && 'PageLayoutContent--full-width'}`}
        data-cy="page-layout"
      >
        <Box className="PageLayoutContent__inner" mx="auto">
          {preHeader && <div className="PageLayoutContent__pre-header">{preHeader}</div>}

          {(header || showButton || !!secondaryActions.length) && (
            <Flex pb={3} pt="2px" fontSize={1} data-cy="page-layout-header">
              {header}
              {showButton && (
                <Button
                  size="small"
                  variant="secondary"
                  ml={3}
                  display={['none', 'none', 'flex']}
                  alignItems="center"
                  onClick={buttonOnClick}
                >
                  {buttonText}
                </Button>
              )}
              {!!secondaryActions.length && (
                <Box sx={{ marginLeft: 'auto' }} {...secondaryActionProps}>
                  {secondaryActions.map(renderSecondaryAction)}
                </Box>
              )}
            </Flex>
          )}

          {children}
        </Box>
      </Box>
    </Flex>
  );
};

PageLayoutContent.propTypes = {
  buttonOnClick: PropTypes.func,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  header: PropTypes.node,
  preHeader: PropTypes.node,
  secondaryActions: LayoutSecondaryActionsSchema,
  contentHeader: PropTypes.node,
  secondaryActionProps: PropTypes.object,
};

PageLayoutContent.defaultProps = {
  buttonOnClick: null,
  buttonText: null,
  fullWidth: false,
  header: null,
  preHeader: null,
  secondaryActions: [],
  children: null,
  contentHeader: null,
  secondaryActionProps: {},
};

export default PageLayoutContent;
