import { Button, Modal } from '@getro/rombo';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { createPortal } from 'react-dom';
import { ArrowLeft, X } from 'lucide-react';

import { matchesApi } from 'services/matches';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { trackEvent } from '../../../helpers/analytics';
import { useDeleteNoteMutation } from '../../../services/contacts';
import { ContactInfo } from './contactInfo';
import { ContactNotes } from './contactNotes';
import { ContactDetailPortal } from './contactDetailPortal';
import { useGetContactDetailContactById } from './hooks/useGetContactDetailContactById';
import { ContactDetailLoader } from './contactDetailLoader';

export const ContactDetail = ({
  queryParams,
  contact: contactProp,
  type,
  match,
  matchListInfo,
  scoreDetails,
  isTalentNetworkEnabled,
  showAddNote,
  list,
  onClickHighlight,
  onClose,
  loading,
  deleteContactFromList,
  fullContactView,
  addedToSharedList,
  page,
}) => {
  const { contactId } = useParams();
  const { contact, isFetching, network } = useGetContactDetailContactById({
    type,
    contactId: contactId ? parseInt(contactId, 10) : contactProp.id,
  });

  const [deleteNote, { isLoading: isLoadingDeleteNote }] = useDeleteNoteMutation();
  const [currentNote, setCurrentNote] = useState();
  const [showNotes, setShowNotes] = useState(showAddNote);
  const [notesEditMode, setNotesEditMode] = useState(showAddNote);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const onCloseView = (triggerClose = true) => {
    document.getElementsByTagName('body')[0].style.overflowY = '';

    if (triggerClose) {
      onClose();
    }

    if (match.deleted) {
      dispatch(
        matchesApi.util.updateQueryData('getMatchListById', { matchListId: `${matchListInfo.id}` }, ({ matchList }) => {
          Object.assign(matchList, {
            matches: matchList.matches.filter((item) => item.id !== match.id),
          });
        }),
      );
    }
  };

  const trackPageEvent = useCallback(
    (event) => {
      if (contact) {
        trackEvent(event, {
          collectionId: network?.id,
          contactId: contact.id,
          page,
        });
      }
    },
    [contact, network?.id, page],
  );

  useEffect(() => {
    if (contact?.id >= 0) {
      trackPageEvent('contacts:open_contact_full_profile');
    }
  }, [contact?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';

    return () => {
      onCloseView(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onBackFromNotes = () => {
    setNotesEditMode(false);
    setCurrentNote(undefined);
    setShowNotes(false);
  };

  const onCancelDeleteNote = () => {
    setShowDeleteModal(false);
    setCurrentNote(undefined);
  };

  const onDeleteNote = async () => {
    await deleteNote({ queryParams, contactId: contact.id, note: currentNote });

    setShowNotes(false);
    setCurrentNote(undefined);
    setShowDeleteModal(false);

    trackEvent('contacts:notes:delete', {
      origin: 'full_profile',
    });
  };

  if (loading || isFetching || !contact) {
    return (
      <ContactDetailPortal onCloseView={onCloseView}>
        <ContactDetailLoader />
      </ContactDetailPortal>
    );
  }

  return createPortal(
    <Flex
      data-testid="contact-detail-view"
      height="100%"
      width="100%"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10,
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Box data-testid="close-button" flexGrow="1" bg="neutral.20" onClick={onCloseView} sx={{ opacity: 0.8 }} />
      <Flex
        bg="neutral.0"
        px={['16px', '40px']}
        py="40px"
        width={['100%', '100%', 'calc(100% - 40px)']}
        maxWidth={type === 'red' ? '800px' : '1100px'}
        sx={{ position: 'relative', boxShadow: '-4px 0px 20px rgba(0, 0, 0, 0.08)', overflowY: 'auto' }}
      >
        {showNotes ? (
          <Flex
            color="text.subtle"
            alignItems="center"
            sx={{ position: 'absolute', top: '16px', left: '16px', cursor: 'pointer' }}
            onClick={onBackFromNotes}
          >
            <Box as={ArrowLeft} width="16px" height="16px" aria-hidden="true" />
            <Text ml="8px" color="text.subtle" fontSize="14px">
              Back
            </Text>
          </Flex>
        ) : (
          <Box
            color="text.subtle"
            sx={{
              position: 'absolute',
              top: '16px',
              left: ['auto', 'auto', '16px'],
              right: ['16px', '16px', 'auto'],
              cursor: 'pointer',
            }}
            onClick={onCloseView}
          >
            <Box as={X} width="16px" height="16px" aria-hidden="true" />
          </Box>
        )}
        <Flex flexGrow="1" minHeight="100%" height="fit-content" flexDirection={['column', 'column', 'row']}>
          {showNotes ? (
            <ContactNotes
              queryParams={queryParams}
              contact={contact}
              type={type}
              currentNote={currentNote}
              editMode={notesEditMode}
              setNotesEditMode={setNotesEditMode}
              setCurrentNote={setCurrentNote}
              setShowNotes={setShowNotes}
              setShowDeleteModal={setShowDeleteModal}
            />
          ) : (
            <ContactInfo
              queryParams={queryParams}
              contact={contact}
              type={type}
              match={match}
              matchListInfo={matchListInfo}
              scoreDetails={scoreDetails}
              isTalentNetworkEnabled={isTalentNetworkEnabled}
              list={list}
              setCurrentNote={setCurrentNote}
              setShowNotes={setShowNotes}
              setNotesEditMode={setNotesEditMode}
              setShowDeleteModal={setShowDeleteModal}
              onClickHighlight={onClickHighlight}
              deleteContactFromList={deleteContactFromList}
              fullContactView={fullContactView}
              addedToSharedList={addedToSharedList}
              page={page}
              trackPageEvent={trackPageEvent}
              networkId={network?.id}
            />
          )}
        </Flex>
      </Flex>
      {showDeleteModal && (
        <Modal
          title="Delete note?"
          actions={
            <Flex justifyContent="flex-end" sx={{ columnGap: '8px' }}>
              <Button variant="secondary" onClick={onCancelDeleteNote}>
                Cancel
              </Button>
              <Button variant="danger" loading={isLoadingDeleteNote} onClick={onDeleteNote}>
                Delete note
              </Button>
            </Flex>
          }
          onCancel={onCancelDeleteNote}
        >
          The note will be deleted for every user.
        </Modal>
      )}
    </Flex>,
    document.getElementById('portals'),
  );
};

ContactDetail.propTypes = {
  queryParams: PropTypes.object,
  contact: PropTypes.object,
  type: PropTypes.oneOf(['default', 'read', 'matches', 'public']),
  match: PropTypes.object,
  matchListInfo: PropTypes.object,
  scoreDetails: PropTypes.object,
  isTalentNetworkEnabled: PropTypes.bool,
  showAddNote: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onClickHighlight: PropTypes.func,
  loading: PropTypes.bool,
  list: PropTypes.object,
  deleteContactFromList: PropTypes.bool,
  fullContactView: PropTypes.bool,
  addedToSharedList: PropTypes.bool,
  page: PropTypes.string.isRequired,
};

ContactDetail.defaultProps = {
  queryParams: {},
  contact: {},
  type: 'default',
  match: {},
  matchListInfo: {},
  scoreDetails: {},
  isTalentNetworkEnabled: false,
  showAddNote: false,
  onClickHighlight: null,
  loading: false,
  list: null,
  deleteContactFromList: false,
  fullContactView: false,
  addedToSharedList: false,
};
