import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'share';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const makeSelectSharedWith = createSelector(rootSelector, (state) => state.sharedWith);

export const makeSelectShareNote = createSelector(rootSelector, (state) => state.note);

export const makeSelectEmailSendingError = createSelector(rootSelector, (state) => state.sendingError);

export const makeSelectEmailSentState = createSelector(rootSelector, (state) => state.emailSent);

export const makeSelectUserOptions = createSelector(rootSelector, (state) => state.userOptions);

export const makeSelectShareMessage = createSelector(makeSelectSharedWith, (sharedWith) => {
  let middlePart = '';
  let sharedString = '';
  if (sharedWith.length) {
    if (sharedWith.length < 2) {
      middlePart = `${sharedWith
        .slice(0, 2)
        .map(({ firstName }) => firstName)
        .join(', ')}`;
    } else if (sharedWith.length >= 2) {
      middlePart = `${sharedWith
        .slice(0, 2)
        .map(({ firstName }) => firstName)
        .join(' and ')}`;
    }
    if (sharedWith.length > 2) {
      middlePart = `${middlePart} and ${sharedWith.length - 2} more`;
    }
    sharedString = `We have sent an email to ${middlePart} with your recommendations that they subscribe to network updates.`;
  }
  return sharedString;
});
