import { createSelector } from 'reselect';
import { initialState, key } from './reducers/talentGroupsReducer';
import { activeNetworkSelector } from './selectors';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const talentGroupsSelector = createSelector(rootSelector, activeNetworkSelector, (state, activeNetwork) => {
  if (!activeNetwork) return null;
  const { id, isManager } = activeNetwork;
  if (isManager || !state[id]) {
    return state[id];
  }
  return state[id].filter(({ visibility }) => visibility === 'visible');
});
