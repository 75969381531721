import React from 'react';
import { Flex } from 'rebass/styled-components';
import { Checkbox, Label } from '@rebass/forms/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAllJobsSelections } from '../../../hooks/useJobs/actions';
import { selectAllJobsSelector } from '../../../hooks/useJobs/selectors';
import { isNetworkAdminSelector } from '../../../hooks/usePermissions/selectors';

const TitleHeader = () => {
  const selectAllJobs = useSelector(selectAllJobsSelector);
  const dispatch = useDispatch();
  const isNetworkAdmin = useSelector(isNetworkAdminSelector);

  const onSelectAllClick = () => {
    dispatch(toggleAllJobsSelections({ selectAll: !selectAllJobs }));
  };

  if (!isNetworkAdmin) {
    return <>Title</>;
  }

  return (
    <Flex as={Label} flexDirection="row" htmlFor="selectAllJobs" color="neutral.500" sx={{ letterSpacing: '1px' }}>
      <Checkbox mx={[2]} checked={selectAllJobs} onChange={onSelectAllClick} id="selectAllJobs" />
      Title
    </Flex>
  );
};

export default TitleHeader;
