import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormSelect } from '@getro/rombo';
import { Box } from 'rebass/styled-components';

const CompanyTags = ({ value, setValue, options }) => (
  <Box>
    <FormLabel htmlFor="Stage" data-testid="Stage">
      Company Tags
    </FormLabel>
    <FormSelect
      name="topics"
      placeholder="Add company tag"
      options={options}
      field={{
        onChange: ({ target: { value: newValue } }) => {
          setValue(newValue);
        },
        value,
      }}
      multiple
      isSearchable
    />
  </Box>
);

CompanyTags.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default CompanyTags;
