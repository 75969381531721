import { createApi } from '@reduxjs/toolkit/query/react';
import deserialize from '../helpers/getroPropertyMapper';

import { networkBaseQueryV2 } from './queries';

export const networkV2Api = createApi({
  reducerPath: 'networkV2Api',
  baseQuery: networkBaseQueryV2,
  tagTypes: ['network'],
  endpoints: (builder) => ({
    updateJobBoardSettings: builder.mutation({
      query: (values) => ({
        url: '/settings/job_board',
        method: 'patch',
        data: values,
      }),
      providesTags: ['network-job-board'],
      transformResponse: deserialize,
    }),
    getJobBoardSettings: builder.query({
      query: () => ({
        url: '/settings/job_board',
      }),
      transformResponse: deserialize,
      invalidatesTags: ['network-job-board'],
    }),
  }),
});

export const { useUpdateJobBoardSettingsMutation, useGetJobBoardSettingsQuery } = networkV2Api;
