import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Tooltip } from '@getro/rombo';
import { Copy, ExternalLink } from 'lucide-react';
import copy from 'copy-to-clipboard';

const SharedListLink = ({ listUrl }) => {
  const timer = useRef(null);
  const [urlTooltipOpen, setUrlTooltipOpen] = useState(false);

  useEffect(() => () => clearTimeout(timer.current), []);

  const copyURLToClipboard = (urlToCopy) => {
    copy(urlToCopy);
    setUrlTooltipOpen(true);

    timer.current = setTimeout(() => {
      setUrlTooltipOpen(false);
    }, 2000);
  };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      mb="24px"
      py="2"
      px="3"
      bg="neutral.0"
      sx={{
        borderRadius: '4px',
        border: '1px solid',
        borderColor: 'neutral.400',
        gap: '24px',
      }}
    >
      <Text
        color="text.main"
        as="div"
        sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '14px', overflow: 'hidden' }}
      >
        {listUrl}
      </Text>
      <Flex alignItems="center">
        <Box mr="1" sx={{ cursor: 'pointer', lineHeight: '16px' }}>
          {!urlTooltipOpen ? (
            <Tooltip
              content="Copy list link"
              trigger={
                <Box
                  as={Copy}
                  width="16px"
                  height="16px"
                  aria-hidden="true"
                  onClick={() => copyURLToClipboard(listUrl)}
                />
              }
            />
          ) : (
            <Tooltip
              content="Copied"
              delayHide={1000}
              isOpen
              trigger={<Box as={Copy} width="16px" height="16px" aria-hidden="true" />}
            />
          )}
        </Box>
        <Box ml="2" sx={{ cursor: 'pointer', lineHeight: '16px' }}>
          <Tooltip
            content="Open in a new tab"
            trigger={
              <Box
                as={ExternalLink}
                width="16px"
                height="16px"
                aria-hidden="true"
                onClick={() => window.open(listUrl)}
              />
            }
          />
        </Box>
      </Flex>
    </Flex>
  );
};

SharedListLink.propTypes = {
  listUrl: PropTypes.string.isRequired,
};

export default SharedListLink;
