import { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetMatchListByIdQuery } from 'services/matches';
import { activeNetworkSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { getCompanyFundTypeFormOptions } from 'services/organizations/organizationFundTypes';
import { mapOrganizationHeadCount } from 'helpers/organizations';
import { useCreateSharedListMutation, useUpdateSharedListMatchMutation } from 'services/contacts';
import { trackEvent } from 'helpers/analytics';
import strings from '../../../strings';
import { useSearchGoogleLocations } from './useProjectSearchGoogleLocations';
import { useProjectSkillsOptions } from './useProjectSkillsOptions';
import { useOrganizationSize } from './useOrganizationSize';

export const usePrivateList = (isEdit = false) => {
  const [isCompanyFromPortfolio, setIsCompanyFromPortfolio] = useState(true);
  const skills = useProjectSkillsOptions();
  const locations = useSearchGoogleLocations();
  const { slug, id: networkId } = useSelector(activeNetworkSelector);
  const { push } = useHistory();
  const params = useParams();
  const {
    data: match,
    isFetching,
    isLoading,
  } = useGetMatchListByIdQuery(
    {
      matchListId: params.matchListId,
    },
    {
      skip: !isEdit,
    },
  );
  const [updateMatch, { error: updateError, isLoading: isUpdating }] = useUpdateSharedListMatchMutation();
  const { options } = useOrganizationSize();
  const [createSharedList, { isLoading: isSubmitting, error: submitError }] = useCreateSharedListMutation();

  const employmentTypes = useMemo(
    () => Object.keys(strings.profile.employment).map((k) => ({ value: k, label: strings.profile.employment[k] })),
    [],
  );

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().notRequired(),
        remote: Yup.bool().required(),
        locations: Yup.array()
          .when('remote', {
            is: true,
            then: (schema) => schema.min(0),
            otherwise: (schema) => schema.min(1),
          })
          .min(1),
        skills: Yup.array().min(1).notRequired(),
        employmentType: Yup.object().required(),
      }),
    [],
  );

  const submit = async (values) => {
    const payload = {
      jobTitle: values.title,
      jobDescription: values.description,
      locationIds: values.locations.map((l) => l.value),
      remotePosition: values.remote,
      skillIds: values.skills.map((s) => s.value),
      employmentType: values.employmentType.value,
      ...(values?.company?.value ? { organizationId: values.company.value } : undefined),
      ...(values?.organizationName ? { organizationName: values.organizationName } : undefined),
      ...(values?.organizationSize?.value ? { organizationSize: values.organizationSize.value } : undefined),
      ...(values?.organizationStage?.value ? { organizationStage: values.organizationStage.value } : undefined),
      ...(values?.organizationIndustryTagIds?.length
        ? { organizationIndustryTagIds: values.organizationIndustryTagIds.map((s) => s.value) }
        : undefined),
    };

    const requestPromise = isEdit
      ? updateMatch({
          data: payload,
          matchListId: params.matchListId,
        })
      : createSharedList({
          name: payload.jobTitle,
          description: strings.sharedLists.defaultDescription,
          matchingProject: payload,
        });
    const { data, error } = await requestPromise;
    if (!error) {
      push(`/networks/${slug}/list/${data?.sharedList?.id ?? params.listId}/${isEdit ? 'ai-matches' : 'contacts'}`);
      toast(`List ${isEdit ? 'updated' : 'created'}`, {
        icon: 'success',
        id: 'create_match_project_success',
      });
    }

    if (!error && !isEdit) {
      trackEvent('contacts:list:create_list', {
        collection_id: networkId,
        list_name: data.sharedList.name,
        type: 'private_job',
        company: isCompanyFromPortfolio ? 'in_portfolio' : 'outside_portfolio',
      });
    }

    if (!error && isEdit) {
      trackEvent('contacts:list:edit_matches_criteria', {
        collection_id: networkId,
        listId: params.listId,
      });
    }
  };

  const initialValues = useMemo(() => {
    if (isEdit && match?.matchList) {
      const { matchList } = match;
      const companySize = mapOrganizationHeadCount(matchList.organization.headCount);
      return {
        title: matchList.job.title,
        description: matchList.job.description ?? '',
        remote: !!matchList.job.locations.find(({ placeId }) => placeId === 'remote'),
        locations:
          matchList.job.locations
            .filter(({ placeId }) => placeId !== 'remote')
            .map(({ placeId, name }) => ({ value: placeId, label: name })) ?? [],
        skills: matchList.job.skills.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        employmentType: {
          value: matchList.job.employmentType,
          label: strings.profile.employment[matchList.job.employmentType],
        },
        company: matchList.organization
          ? {
              showAvatar: true,
              avatarUrl: matchList.organization.logoUrl,
              icon: matchList.organization.logoUrl,
              value: matchList.organization.id,
              label: matchList.organization.name,
            }
          : null,
        organizationName: matchList.organization.name,
        organizationSize: options.find((s) => s.value === companySize),
        organizationStage: getCompanyFundTypeFormOptions().find((s) => s.value === matchList.organization.stage),
        organizationIndustryTagIds:
          matchList?.organization.industryTags?.map(({ id, description }) => ({ value: id, label: description })) ?? [],
      };
    }

    return {
      company: null,
      organizationName: '',
      skills: [],
      locations: [],
      description: '',
      remote: false,
      organizationIndustryTagIds: [],
      employmentType: {
        value: 'full_time',
        label: 'Full time',
      },
      title: '',
    };
  }, [isEdit, match, options]);

  return {
    skills,
    employmentTypes,
    locations,
    formSchema: isCompanyFromPortfolio
      ? formSchema.concat(
          Yup.object().shape({
            company: Yup.object().required(),
          }),
        )
      : formSchema,
    submit,
    isSubmitting: isEdit ? isUpdating : isSubmitting,
    error: isEdit ? updateError : submitError,
    isCompanyFromPortfolio,
    setIsCompanyFromPortfolio,
    match: match?.matchList,
    initialValues,
    loadingMatch: isLoading && isFetching,
    matchIsOutsidePortfolioCompany: !match?.matchList?.organization?.id,
  };
};
