import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { FormError, FormLabel, ProfilePicture, ReduxSelect } from '@getro/rombo';

export const ReduxFormSelect = ({
  value,
  labelExtra,
  name,
  id,
  label,
  placeholder,
  helpText,
  onInputChange,
  loading,
  options,
  onChange,
  isMulti,
  form,
  field,
  customFormatOptionLabel,
  ...rest
}) => {
  const fieldName = field?.name || name;
  const error = form?.touched?.[fieldName] && form?.errors?.[fieldName];

  const formatOptionLabel = (params, { selectValue, context }) => {
    const selected = selectValue?.[0]?.value === params.value;
    if (customFormatOptionLabel) {
      return customFormatOptionLabel({ ...params }, { context, selected });
    }

    return (
      <Flex alignItems="center" sx={{ ':hover, & >': { bg: 'neutral.50', cursor: 'pointer' } }}>
        {!selected && params.showAvatar && (
          <Box
            sx={{ text: { fontSize: '3em', color: 'text.main', fontWeight: 500 } }}
            width="32px"
            height="32px"
            mr={2}
          >
            <ProfilePicture variant="square" imageUrl={params.avatarUrl} name={params.label} />
          </Box>
        )}
        <Text color={selected ? 'text.dark' : 'text.main'}>{params.label}</Text>
      </Flex>
    );
  };

  return (
    <Box>
      {label && (
        <FormLabel htmlFor={name || id} extra={labelExtra}>
          {label}
        </FormLabel>
      )}
      {helpText && (
        <Text fontSize="1" lineHeight="20px">
          {helpText}
        </Text>
      )}
      <ReduxSelect
        name={fieldName}
        placeholder={placeholder}
        value={value}
        components={{ ClearIndicator: null }}
        onChange={onChange}
        onInputChange={onInputChange}
        isLoading={loading}
        options={options}
        formatOptionLabel={formatOptionLabel}
        orientation="horizontal"
        onBlur={() => form?.setFieldTouched(fieldName, true)}
        label={label}
        isMulti={isMulti}
        styles={{
          placeholder: (_, state) => ({
            paddingLeft: '12px',
            color: state.theme.colors.text.subtle,
            marginLeft: 0,
            marginRight: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '100%',
          }),
          input: (provided, state) => ({
            ...provided,
            color: state.theme.colors.text,
            fontWeight: state.theme.fontWeights.body,
            margin: '0px',
          }),
          control: (provided, state) => {
            const borderColor = state.isFocused ? state.theme.colors.blue[600] : state.theme.colors.gray[2];
            return {
              backgroundColor: state.theme.colors.neutral[0],
              borderRadius: state.theme.borderRadius,
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: !error ? borderColor : state.theme.colors.red[600],
            };
          },
        }}
        {...rest}
      />
      {error && <FormError mt="2">{error}</FormError>}
    </Box>
  );
};

ReduxFormSelect.defaultProps = {
  isMulti: false,
  helpText: '',
  loading: false,
  id: null,
  error: null,
  customFormatOptionLabel: null,
  labelExtra: '',
};

ReduxFormSelect.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelExtra: PropTypes.string,
  helpText: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  form: PropTypes.shape({
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    touched: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }).isRequired,
  customFormatOptionLabel: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      logoUrl: PropTypes.string,
    }).isRequired,
  ).isRequired,
};
export default ReduxFormSelect;
