import React from 'react';
import PropTypes from 'prop-types';
import { BellOff, Check } from 'lucide-react';
import { Flex, Box, Text, Card } from 'rebass/styled-components';
import { AsyncButton } from '@getro/rombo';
import { BoardCreator } from '../boardCreator';

export const BoardSubscription = ({ board, onSubscribe, onUnsubscribe }) => (
  <Card width={[1]} p={3} variant="card.boardSubscription" bg={board.subscribed ? 'gray.5' : ''}>
    <Flex>
      <Box width="40px">
        <Box
          sx={{ color: board.subscribed ? 'primary' : 'text.subtle' }}
          height="24px"
          width="24px"
          strokeWidth="1.5"
          aria-hidden="true"
          as={board.subscribed ? Check : BellOff}
        />
      </Box>
      <Box flex={1} px={3}>
        <Text fontSize={3} color="text.dark" pb={1} font as="h4">
          {board.label}
        </Text>
        {board.description && (
          <Text py={1} fontSize={1} color="text.main">
            {board.description}
          </Text>
        )}
        <BoardCreator creator={board.creator} />
      </Box>
      <Box alignItems="flex-start" justifyContent="flex-end" display="flex" width={[1 / 5]}>
        <AsyncButton
          width={1}
          loading={board.isSubmitting}
          onClick={() => {
            if (board.subscribed) {
              onUnsubscribe();
            } else {
              onSubscribe();
            }
          }}
          variant={board.subscribed ? 'text' : 'accent'}
          sx={{ fontSize: 1, flexGrow: 1, justifyContent: 'center', border: 0, color: 'primary' }}
        >
          {board.subscribed ? 'Subscribed' : 'Subscribe'}
        </AsyncButton>
      </Box>
    </Flex>
  </Card>
);

BoardSubscription.propTypes = {
  board: PropTypes.shape({
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    subscribed: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    creator: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
    }),
  }).isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
};
