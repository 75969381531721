import produce from 'immer';
import { handleActions } from 'redux-actions';
import { loadAllOrganizations, loadedAllOrganizations, errorAllOrganizations } from './actions';
import Sort from '../../helpers/sort';
import { setActiveNetwork } from '../../actions/networksActions';

export const initialState = {
  initialized: false,
  error: false,
  list: [],
};

/* eslint-disable default-case, no-param-reassign */
const OrganizationsReducer = handleActions(
  {
    [loadAllOrganizations]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [loadedAllOrganizations]: produce((draft, { payload }) => {
      draft.initialized = true;
      draft.error = false;
      draft.list = Sort.alphabetic(payload.items);
    }),
    [errorAllOrganizations]: produce((draft) => {
      draft.initialized = true;
      draft.error = true;
    }),
    [setActiveNetwork]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
  },
  initialState,
);

export default OrganizationsReducer;
