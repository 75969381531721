import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Loader, MessageBlock } from '@getro/rombo';
import { Box } from 'rebass/styled-components';
import withMainLayout from '../../hocs/withMainLayout';
import { setPageTitle } from '../../actions/pageActions';
import useCompany from '../../hooks/useCompany';
import { CompanyProfileLayout } from '../../components/layout/CompanyProfileLayout';
import strings from '../../strings';
import JobsTableWithFilters from '../../components/organisms/jobsTableWithFilters';
import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';

const CompanyJobs = () => {
  const dispatch = useDispatch();
  const { company, error, loading, initialized } = useCompany();

  useEffect(() => {
    if (loading) {
      dispatch(setPageTitle('Loading...'));
    } else if (initialized) {
      dispatch(setPageTitle(`${company.name} profile`));
    } else {
      dispatch(setPageTitle(''));
    }
  }, [loading, initialized, company, dispatch]);

  return error ? (
    <>
      <MainLayoutHeader title="Company profile" />
      <Box>
        {error !== 404 && <MessageBlock type="error" content={strings.genericError} />}
        {error === 404 && (
          <MessageBlock
            type="warning"
            title="Company not found"
            content="It looks like the URL is not correct or the company doesn’t belong to the active network."
          />
        )}
      </Box>
    </>
  ) : (
    <>
      {!initialized ? (
        <>
          <MainLayoutHeader title="Company profile" />
          <Loader />
        </>
      ) : (
        <CompanyProfileLayout fullWidth>
          <Box flex="1" px="16px">
            <JobsTableWithFilters company={company} source="employer_profile" />
          </Box>
        </CompanyProfileLayout>
      )}
    </>
  );
};

export default withMainLayout(CompanyJobs);
