import React, { useCallback, useImperativeHandle, useMemo, useState, forwardRef } from 'react';
import { Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { Card } from 'components/atoms/Card';
import useOnClickOutside from 'hooks/useClickOutside';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import ActionsIcon from './actionsIcon';
import BulkActionsTriggers from './bulkActionsTriggers';
import ActionsSearch from './actionsSearch';

const BulkActionsModal = forwardRef(
  (
    {
      isLoading,
      options,
      actions,
      useLocalSearch,
      icon,
      onSearch,
      title,
      onCreateIfNotExists,
      isCreating,
      newItemText,
    },
    componentRef,
  ) => {
    const [showCard, setShowCard] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [value, setValue] = useState();
    const [action, setAction] = useState();

    const close = useCallback(() => {
      setShowSearch(false);
      setShowCard(false);
      setAction(null);
      setValue('');
    }, []);

    useImperativeHandle(componentRef, () => ({
      close,
    }));

    const ref = useOnClickOutside(() => {
      close();
    });

    const filtered = useMemo(() => {
      if (!useLocalSearch) return options ?? [];

      const regex = RegExp(value, 'gmi');
      return (options ?? []).filter((item) => regex.test(item.label));
    }, [options, useLocalSearch, value]);

    useDebouncedEffect(
      () => {
        onSearch?.(value);
      },
      599,
      value,
    );

    return (
      <Flex
        ref={ref}
        sx={{
          flex: 'none',
          position: showSearch ? ['initial', 'initial', 'relative'] : 'relative',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {showCard && (
          <Card
            width={showSearch ? '320px' : '200px'}
            height={showSearch ? '300px' : 'initial'}
            mb="0"
            p={showSearch ? '16px' : '8px 16px'}
            bg="neutral.0"
            sx={{
              position: 'absolute',
              bottom: showSearch ? ['50px', '50px', '40px'] : '40px',
              left: showSearch ? ['20px', '20px', '0'] : ['initial', 'initial', '0px'],
              right: showSearch ? 'initial' : ['0px', '0px', 'initial'],
            }}
          >
            {showSearch && action && (
              <ActionsSearch
                newItemText={newItemText}
                onCreateIfNotExists={action.showOnCreateIfNotExists ? onCreateIfNotExists : null}
                isLoading={isLoading}
                isCreating={isCreating}
                title={action.title}
                value={value ?? ''}
                options={filtered}
                onSearch={setValue}
                onSelect={action.onSelect}
                {...action}
              />
            )}
            {!showSearch && (
              <BulkActionsTriggers
                actions={actions.map((item) => ({
                  ...item,
                  onClick: () => {
                    item?.onClick?.();
                    if (item.disableModal) {
                      close();
                      return;
                    }
                    setAction(item);
                    setShowSearch(true);
                  },
                }))}
              />
            )}
          </Card>
        )}
        <ActionsIcon
          icon={icon}
          text={title}
          onClick={() => {
            if (showCard) {
              close();
            } else {
              setShowCard(!showCard);
            }
          }}
        />
      </Flex>
    );
  },
);

BulkActionsModal.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool,
  options: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  useLocalSearch: PropTypes.array,
  icon: PropTypes.node.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCreateIfNotExists: PropTypes.func,
  newItemText: PropTypes.string,
};

BulkActionsModal.defaultProps = {
  useLocalSearch: true,
  onCreateIfNotExists: null,
  isCreating: false,
  newItemText: '',
};

export default BulkActionsModal;
