import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ProfilePicture, Button } from '@getro/rombo';
import { X } from 'lucide-react';

export const UserListItem = ({ user, onRemove, ...rest }) => (
  <Flex {...rest}>
    <Box width={['32px']}>
      <ProfilePicture imageUrl={user.avatarUrl} name={user.displayName} />
    </Box>
    <Flex fontSize={3} pl={2} flexGrow={1} flexDirection="row" alignItems="center">
      <Text color="text.dark" variant="default">
        {user.displayName}&nbsp;
      </Text>
      <Text color="text.main" variant="default">
        {user.role} at {user.companiesName}
      </Text>
    </Flex>
    {onRemove && (
      <Button
        variant="secondary"
        sx={{ border: 0 }}
        display="flex"
        onClick={() => {
          onRemove(user);
        }}
      >
        <Box height="1em" width="1em" strokeWidth="1.5" aria-hidden="true" as={X} />
        <Text variant="variants.srOnly">Remove</Text>
      </Button>
    )}
  </Flex>
);

UserListItem.propTypes = {
  user: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
};

UserListItem.defaultProps = {
  onRemove: null,
};
