import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import strings from 'strings';

import { MessageBlock, Loader, FormTextarea, FormSelect } from '@getro/rombo';
import Modal from 'components/atoms/Modal';

import { getUserDisplayName } from 'helpers/users';
import get from 'helpers/get';
import MemberSchema from 'schemas/member';
import UserSchema from 'schemas/user';

import PermissionsSelector from './components/PermissionsSelector';
import CONFIG from './components/config';
import formSchema from './components/formSchema';

export const VERIFY_CONFIG = 'VERIFY';
export const VOUCH_CONFIG = 'VOUCH';

const VouchModal = ({
  member,
  showPermissions,
  action,
  open,
  setOpen,
  currentUser,
  onSubmit,
  vouchResult,
  isVouchingMember,
  onCloseVouchModal,
  isLoading,
}) => {
  const [config, setConfig] = useState(action);
  const formRef = useRef(null);
  const { result } = vouchResult;

  useEffect(() => {
    setConfig(action);
  }, [action]);

  useEffect(() => {
    if (formRef.current) {
      const { error } = vouchResult;
      formRef.current.setSubmitting(isVouchingMember);
      if (error) {
        if (error.response && error.response.status === 403) {
          formRef.current.setStatus({ error: strings.genericAuthError });
        } else if (error.response && error.response.data && error.response.data.errors) {
          formRef.current.setStatus({ error: error.response.data.errors[0] });
        } else {
          formRef.current.setStatus({ error: strings.genericError });
        }
      } else {
        formRef.current.setStatus({ error: null });
      }
    }
  }, [isVouchingMember, vouchResult]);

  const isAVoucherUpdated =
    member && member.referralsReceived && member.referralsReceived.find(({ voucher }) => voucher.id === currentUser.id);

  const handleSuccessClose = () => {
    setOpen(false);
    onCloseVouchModal();
  };

  const onSubmitForm = (values) => {
    onSubmit({
      member,
      verify: config === VERIFY_CONFIG,
      values,
    });
  };

  const handleChangePermissions = ({ target }) =>
    setConfig(Object.keys(CONFIG).find((a) => CONFIG[a].permission === +target.value));

  if (Object.keys(result).length) {
    return (
      <Modal open={open} simple>
        <MessageBlock
          title={CONFIG[config].success.title.replace('[Member Name]', member.firstName || 'a new member')}
          type="success"
          buttonText="Ok"
          onClick={handleSuccessClose}
        >
          {CONFIG[config].success.desc()}
        </MessageBlock>
      </Modal>
    );
  }

  const relationshipText = get(isAVoucherUpdated, 'relationshipText', undefined);
  const relationshipLevel = get(isAVoucherUpdated, 'relationshipLevel', undefined);
  let initialRelationshipLevel;

  if (relationshipLevel) {
    initialRelationshipLevel = {
      value: relationshipLevel.toString(),
      label: strings.vouch.relationshipLevelOptions[relationshipLevel].replace('[Member Name]', member.firstName),
    };
  }

  return (
    <Modal
      cancelText="Cancel"
      formik
      formikProps={{
        initialValues: {
          customMessage: relationshipText,
          relationshipLevel: initialRelationshipLevel,
        },
        validationSchema: formSchema,
        ref: formRef,
      }}
      open={open}
      onSubmit={onSubmitForm}
      onCancelClick={() => setOpen(false)}
      saveText={CONFIG[config].submitText}
      title={CONFIG[config].title.replace('[Member Name]', getUserDisplayName(member))}
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          {CONFIG[config].desc()}

          {showPermissions && (
            <PermissionsSelector onChange={handleChangePermissions} selected={CONFIG[config].permission} showLabel />
          )}

          <Field
            component={FormSelect}
            name="relationshipLevel"
            label="How do you know each other?"
            placeholder="Select one option"
            options={Object.entries(strings.vouch.relationshipLevelOptions).map(([k, v]) => ({
              value: k,
              label: v.replace('[Member Name]', member.firstName || 'this person'),
            }))}
            containerProps={{ mt: 4 }}
          />

          <Field
            component={FormTextarea}
            label="Tell us more"
            name="customMessage"
            placeholder="Write 1-2 sentences that we can share with companies who want to connect with this person."
            containerProps={{ mt: 4 }}
          />
        </>
      )}
    </Modal>
  );
};

VouchModal.propTypes = {
  isLoading: PropTypes.bool,
  member: MemberSchema.isRequired,
  currentUser: UserSchema.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  showPermissions: PropTypes.bool,
  action: PropTypes.oneOf(Object.keys(CONFIG)).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isVouchingMember: PropTypes.bool.isRequired,
  vouchResult: PropTypes.shape({
    error: PropTypes.object,
    result: PropTypes.object.isRequired,
  }).isRequired,
  onCloseVouchModal: PropTypes.func.isRequired,
};

VouchModal.defaultProps = {
  isLoading: true,
  open: false,
  setOpen: () => {},
  showPermissions: false,
};

export default VouchModal;
