import { createApi } from '@reduxjs/toolkit/query/react';

import { createEntityAdapter } from '@reduxjs/toolkit';
import { networkBaseQuery } from '../queries';

const itemsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: false,
});

const itemsSelector = itemsAdapter.getSelectors();

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery: networkBaseQuery,
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: ({ filters }) => ({
        url: 'organizations',
        params: {
          ...filters,
          perPage: 20,
        },
      }),
      transformResponse: (response) => ({
        meta: response.meta,
        ...itemsAdapter.addMany(itemsAdapter.getInitialState(), response.items),
        companies: [],
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentState, incomingState) => {
        itemsAdapter.addMany(currentState, itemsSelector.selectAll(incomingState));
      },
    }),
  }),
});

export const { useLazyGetCompaniesQuery, useGetCompaniesQuery } = companiesApi;
