import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import { activeNetworkSelector, hasContactsSelector } from '../../redux/selectors';
import { ContactsBasic } from '../contactsBasic';
import { ContactsExtended } from '../contactsExtended';

export const Contacts = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { isManager, features } = activeNetwork;
  const showConnect = useSelector(hasContactsSelector);
  const showUpJobsTnContactsPage = isManager && features.includes('tn_onboarding_v2') && !showConnect;

  if (showUpJobsTnContactsPage) {
    return <ContactsBasic />;
  }

  if (showConnect) {
    return <ContactsExtended />;
  }

  return <Redirect to="/" />;
};
