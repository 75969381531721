import { createActions } from 'redux-actions';

export const { viewJob } = createActions('VIEW_JOB', 'SHARE_JOB');

export const { loadExtendJob, loadedExtendJob, errorExtendJob } = createActions(
  'LOAD_EXTEND_JOB',
  'LOADED_EXTEND_JOB',
  'ERROR_EXTEND_JOB',
);
export const { loadCloseJob, loadedCloseJob, errorCloseJob } = createActions(
  'LOAD_CLOSE_JOB',
  'LOADED_CLOSE_JOB',
  'ERROR_CLOSE_JOB',
);
export const { loadDeleteJob, loadedDeleteJob, errorDeleteJob } = createActions(
  'LOAD_DELETE_JOB',
  'LOADED_DELETE_JOB',
  'ERROR_DELETE_JOB',
);

export const { loadForceJobVisibility, loadedForceJobVisibility, errorForceJobVisibility } = createActions(
  'LOAD_FORCE_JOB_VISIBILITY',
  'LOADED_FORCE_JOB_VISIBILITY',
  'ERROR_FORCE_JOB_VISIBILITY',
);

export const { loadFeatureJob, loadedFeatureJob, errorFeatureJob } = createActions(
  'LOAD_FEATURE_JOB',
  'LOADED_FEATURE_JOB',
  'ERROR_FEATURE_JOB',
);

export const { loadBulkForceJobVisibility, loadedBulkForceJobVisibility, errorBulkForceJobVisibility } = createActions(
  'LOAD_BULK_FORCE_JOB_VISIBILITY',
  'LOADED_BULK_FORCE_JOB_VISIBILITY',
  'ERROR_BULK_FORCE_JOB_VISIBILITY',
);

export const { loadBulkForceAllJobsVisibility, loadedBulkForceAllJobsVisibility, errorBulkForceAllJobsVisibility } =
  createActions(
    'LOAD_BULK_FORCE_ALL_JOBS_VISIBILITY',
    'LOADED_BULK_FORCE_ALL_JOBS_VISIBILITY',
    'ERROR_BULK_FORCE_ALL_JOBS_VISIBILITY',
  );

export const { toggleJobActionDialog } = createActions('TOGGLE_JOB_ACTION_DIALOG');

export const { loadJobApplicationDownload, loadedJobApplicationDownload, errorJobApplicationDownload } = createActions(
  'LOAD_JOB_APPLICATION_DOWNLOAD',
  'LOADED_JOB_APPLICATION_DOWNLOAD',
  'ERROR_JOB_APPLICATION_DOWNLOAD',
);

export const { closeJobApplicationDownloadModal } = createActions('CLOSE_JOB_APPLICATION_DOWNLOAD_MODAL');
