import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Pagination as SemanticPagination } from 'semantic-ui-react';
import 'semantic-ui-css/components/menu.min.css';

const Pagination = ({ className, totalPages, onPageChange, activePage, defaultActivePage, ...rest }) => (
  <SemanticPagination
    size="tiny"
    borderless
    className={`Pagination ${className}`}
    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
    // firstItem={totalPages > 4 ? { content: <Icon name="angle double left" />, icon: true } : null}
    // lastItem={totalPages > 4 ? { content: <Icon name="angle double right" />, icon: true } : null}
    // prevItem={totalPages > 4 ? { content: <Icon name="angle left" />, icon: true } : null}
    // nextItem={totalPages > 4 ? { content: <Icon name="angle right" />, icon: true } : null}
    firstItem={null}
    lastItem={null}
    prevItem={null}
    nextItem={null}
    boundaryRange={1}
    siblingRange={1}
    {...{
      totalPages,
      onPageChange,
      activePage,
      defaultActivePage,
    }}
    {...rest}
  />
);

Pagination.propTypes = {
  className: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  defaultActivePage: PropTypes.number,
};

Pagination.defaultProps = {
  className: '',
  defaultActivePage: 1,
};

export default Pagination;
