import React from 'react';
import PageLayout from 'components/layout/Page';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import ExportModal from 'components/molecules/ExportModal';
import { activeNetworkSelector } from 'redux/selectors';
import { useHistory } from 'react-router';
import { CompaniesDataTable } from '../CompaniesTable';
import CompaniesFilter from '../CompaniesFilter';
import { closeExportCompanies } from '../hooks/useActiveCompanies/actions';
import LayoutSecondaryActionsSchema from '../../../schemas/layoutSecondaryActions';

const ActiveCompanies = ({
  loadMore,
  companies,
  loading,
  meta,
  hasCompanyInitializedError,
  hasCompanyInitialized,
  exportCompanyError,
  exportCompanySuccess,
  filters,
  applyFilter,
  secondaryActions,
  filterCount,
  topics,
}) => {
  const network = useSelector(activeNetworkSelector);
  const dispatch = useDispatch();
  const {
    location: { pathname, search },
  } = useHistory();

  return (
    <>
      <Box px="16px">
        <CompaniesFilter
          topics={topics}
          filters={filters}
          setFilter={(valueKey, value) => applyFilter({ [valueKey]: value, page: 1 })}
          setFilters={(allFilters) => applyFilter(allFilters)}
          filterCount={filterCount}
        />
      </Box>
      <PageLayout.Content
        fullWidth
        secondaryActionProps={{
          sx: {
            width: '100%',
            display: 'flex',
            ml: '0',
            justifyContent: 'space-between',
            alignItems: 'center',
            button: {
              margin: '0px',
              padding: '0px',
            },
          },
        }}
        secondaryActions={secondaryActions}
      >
        {exportCompanySuccess && network.isManager && (
          <ExportModal
            error={exportCompanyError}
            success={exportCompanySuccess}
            onClose={() => dispatch(closeExportCompanies())}
          />
        )}

        <CompaniesDataTable
          hasInitialized={hasCompanyInitialized}
          hasInitializedError={hasCompanyInitializedError}
          loadMore={loadMore}
          loading={loading}
          total={meta.total}
          companies={companies}
          filterCount={filterCount}
          pathname={pathname}
          search={search}
          showTags={network.isManager && topics.length > 0}
        />
      </PageLayout.Content>
    </>
  );
};

ActiveCompanies.propTypes = {
  meta: PropTypes.object.isRequired,
  hasCompanyInitialized: PropTypes.bool.isRequired,
  hasCompanyInitializedError: PropTypes.bool.isRequired,
  exportCompanyError: PropTypes.bool.isRequired,
  exportCompanySuccess: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadMore: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  secondaryActions: LayoutSecondaryActionsSchema.isRequired,
  filterCount: PropTypes.number.isRequired,
  topics: PropTypes.array.isRequired,
};

export default ActiveCompanies;
