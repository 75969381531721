import { useEffect, useState } from 'react';

export const useIsMobile = (callback) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    // dev node messes with innerWidth
    const width = window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;
    const mobile = width <= 991;
    setIsMobile(mobile);

    if (callback) {
      callback(mobile);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return isMobile;
};
