import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import { ContactAvatar } from '../../../molecules/contactAvatar';
import { EditMode } from './editMode';
import { ViewMode } from './viewMode';

export const ContactNotes = ({
  queryParams,
  contact,
  type,
  currentNote,
  editMode,
  setNotesEditMode,
  setCurrentNote,
  setShowNotes,
  setShowDeleteModal,
}) => (
  <Box flex="1">
    <Flex pb="16px" sx={{ borderBottom: 1, borderBottomColor: 'neutral.100' }}>
      <ContactAvatar type={type} contact={contact} />
    </Flex>
    <Box mt="40px">
      {editMode ? (
        <EditMode
          queryParams={queryParams}
          contactId={contact.id}
          currentNote={currentNote}
          setCurrentNote={setCurrentNote}
          setShowNotes={setShowNotes}
          setShowEditMode={setNotesEditMode}
        />
      ) : (
        <ViewMode
          type={type}
          currentNote={currentNote}
          setShowEditMode={setNotesEditMode}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
    </Box>
  </Box>
);

ContactNotes.propTypes = {
  queryParams: PropTypes.object,
  contact: PropTypes.object,
  type: PropTypes.oneOf(['default', 'read', 'matches', 'public']),
  editMode: PropTypes.bool,
  currentNote: PropTypes.object,
  setNotesEditMode: PropTypes.func.isRequired,
  setCurrentNote: PropTypes.func.isRequired,
  setShowNotes: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
};

ContactNotes.defaultProps = {
  queryParams: {},
  contact: {},
  type: 'default',
  editMode: false,
  currentNote: null,
};
