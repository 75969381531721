import React from 'react';
import { Flex, Image, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { Button } from '@getro/rombo';
import { ListPlus } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import IconMagicWand from '../../assets/icon-hand-empty.svg';

export const EmptyView = ({ slug }) => (
  <Flex
    mt="40px"
    width="100%"
    height="100%"
    justifyContent="center"
    sx={{
      'a, svg': {
        color: 'text.main',
      },
    }}
  >
    <Flex alignItems="center" flexDirection="column" sx={{ gap: '16px' }}>
      <Image flex="0 0 auto" src={IconMagicWand} width="64px" maxWidth="64px" />
      <Text color="text.main" fontWeight="600" textAlign="center">
        No lists created yet
      </Text>
      <Text
        maxWidth="520px"
        fontWeight="400"
        fontSize="16px"
        textAlign="center"
        lineHeight="22.4px"
        color="text.subtle"
      >
        Organize your contacts using lists, and optionally share them with companies.{' '}
      </Text>
      <Button
        as={NavLink}
        to={`/networks/${slug}/lists/create`}
        variant="secondary"
        icon={ListPlus}
        iconGap="8px"
        color="text.subtle"
        textAlign="center"
      >
        Create list
      </Button>
    </Flex>
  </Flex>
);

EmptyView.propTypes = {
  slug: PropTypes.string.isRequired,
};
