import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'manageSubscriptions';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const makeSelectBoards = createSelector(rootSelector, (state) => state.boards);

export const makeShowDeactivateConfirmation = createSelector(rootSelector, (state) => state.showDeactivateConfirmation);
