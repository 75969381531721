import { trackException } from 'helpers/errorTracker';

const getGooglePlacePredictions = (query) => {
  if (typeof window !== 'undefined') {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      fetch(`${process.env.REACT_APP_API_V2_BASE_URL}/places/predictions?query=${query}`, {
        headers,
      }).then((response) => {
        if (!response.ok) {
          return reject(Error(`Google Places Error - Status: ${response.status}`));
        }
        return response.json().then(({ predictions }) => resolve(predictions));
      });
    });
  }
  return Promise.resolve(null);
};

class GooglePlacesAPI {
  static async searchGoogle(query) {
    if (!query.length) {
      return Promise.resolve([]);
    }
    try {
      const result = await getGooglePlacePredictions(query);

      return Promise.resolve(
        result.map(({ description, place_id: placeId, terms, types }) => ({
          id: placeId,
          value: placeId,
          label: description,
          country: { name: terms.pop().value },
          type: types[0],
        })),
      );
    } catch (e) {
      trackException(e);
      return Promise.resolve([]);
    }
  }
}

export default GooglePlacesAPI;
