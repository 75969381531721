import isEqual from 'lodash.isequal';
import { useRef, useEffect } from 'react';

function useDebouncedEffect(effect, delay, value) {
  const timeoutRef = useRef(null);
  const valueRef = useRef();

  useEffect(() => {
    if (!isEqual(valueRef.current, value)) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        effect();
      }, delay);
    }

    valueRef.current = value;
  }, [effect, delay, value]);
}

export default useDebouncedEffect;
