import { call, put, takeEvery } from 'redux-saga/effects';
import { DECLINE_INTRODUCTION_REQUEST, markAsDoneIntroductionRequest } from 'actions/introductionRequestsActions';
import { post } from 'api';

function* declineIntroductionRequestsWatcher() {
  yield takeEvery(DECLINE_INTRODUCTION_REQUEST, declineIntroductionRequestWorker);
}

const declineIntroductionRequest = (collectionId, introductionRequestId) =>
  post(`/collections/${collectionId}/introduction_requests/${introductionRequestId}/decline`);

function* declineIntroductionRequestWorker(action) {
  const { payload } = action;
  const { id: collectionId, introductionRequest } = payload;
  const { id: introductionRequestId } = introductionRequest;

  const response = yield call(declineIntroductionRequest, collectionId, introductionRequestId);
  if (response && response.status === 200) {
    yield put(markAsDoneIntroductionRequest(payload));
  }
}

export { declineIntroductionRequestWorker };
export default declineIntroductionRequestsWatcher;
