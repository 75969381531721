import produce from 'immer';
import { handleActions } from 'redux-actions';
import strings from 'strings';
import {
  loadGeneralSettings,
  loadedGeneralSettings,
  errorGeneralSettings,
  loadSaveGeneralSettings,
  loadedSaveGeneralSettings,
  errorSaveGeneralSettings,
  loadSaveGdprSettings,
  loadedSaveGdprSettings,
  errorSaveGdprSettings,
  loadTalentNetworkSettings,
  loadedTalentNetworkSettings,
  errorTalentNetworkSettings,
  loadedUpdateTalentNetworkSettings,
  errorUpdateTalentNetworkSettings,
} from './actions';
import { setActiveNetwork } from '../../actions/networksActions';

// The initial state of the Reducer
export const initialState = {
  id: null,
  general: {
    settings: null,
    errorLoad: null,
    successSave: null,
    errorSave: null,
  },
  talentNetwork: {
    data: {
      tnHidden: true,
      tnV2TabIntroductionText: '',
      tnV2TabIntroductionTitle: '',
      tnSignUpWelcomeText: '',
      tnSignUpWelcomeTitle: '',
    },
    error: null,
  },
};

/* eslint-disable default-case, no-param-reassign */
const companyProfileReducer = handleActions(
  {
    [loadGeneralSettings]: produce((draft) => {
      const { general } = initialState;
      draft.general = {
        ...general,
      };
      return draft;
    }),
    [loadedGeneralSettings]: produce((draft, action) => {
      const { settings, id } = action.payload;
      draft.general.settings = settings;
      draft.id = id;
      draft.general.errorLoad = null;
      return draft;
    }),
    [errorGeneralSettings]: produce((draft, action) => {
      draft.general.errorLoad = action.payload.error;
      return draft;
    }),
    [loadSaveGeneralSettings]: produce((draft) => {
      draft.general.successSave = null;
      draft.general.errorSave = null;
      return draft;
    }),
    [loadedSaveGeneralSettings]: produce((draft, action) => {
      draft.general.settings = {
        ...draft.general.settings,
        ...action.payload.values,
      };
      draft.general.successSave = true;
      draft.general.errorSave = null;
      return draft;
    }),
    [errorSaveGeneralSettings]: produce((draft, action) => {
      draft.general.general.successSave = null;
      draft.general.errorSave = action.payload.error;
      return draft;
    }),
    [loadSaveGdprSettings]: produce((draft) => {
      draft.general.successGdprSave = null;
      draft.general.errorGdprSave = null;
      return draft;
    }),
    [loadedSaveGdprSettings]: produce((draft, action) => {
      draft.general.settings = {
        ...draft.general.settings,
        ...action.payload.values,
      };
      draft.general.successGdprSave = true;
      draft.general.errorgGdprSave = null;
      return draft;
    }),
    [errorSaveGdprSettings]: produce((draft, action) => {
      draft.general.general.successGdprSave = null;
      draft.general.errorGdprSave = action.payload.error;
      return draft;
    }),
    [setActiveNetwork]: produce((draft, action) => {
      const { payload } = action;
      if (payload !== draft.id) {
        return initialState;
      }

      return draft;
    }),
    [loadTalentNetworkSettings]: produce((draft) => {
      draft.talentNetwork = initialState.talentNetwork;
    }),
    [loadedTalentNetworkSettings]: produce((draft, { payload }) => {
      const { id, ...data } = payload;

      draft.id = id;
      draft.talentNetwork = {
        data,
        error: null,
      };

      return draft;
    }),
    [errorTalentNetworkSettings]: produce((draft) => {
      draft.talentNetwork = {
        ...draft.talentNetwork,
        error: strings.genericError,
      };
      return draft;
    }),
    [loadedUpdateTalentNetworkSettings]: produce((draft, { payload }) => {
      const { id, ...data } = payload;
      draft.talentNetwork = {
        data,
        error: null,
      };

      return draft;
    }),
    [errorUpdateTalentNetworkSettings]: produce((draft) => {
      draft.talentNetwork = {
        ...draft.talentNetwork,
        error: strings.genericError,
      };
      return draft;
    }),
  },
  initialState,
);

export default companyProfileReducer;
