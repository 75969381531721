import { takeLatest, put, call, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import {
  loadAddCompanyByName,
  loadedAddCompanyByName,
  errorAddCompanyByName,
  loadedBulkAddCompanyByDomain,
  loadBulkAddCompanyByDomain,
  errorBulkAddCompanyByDomain,
} from './actions';
import { activeNetworkSelector } from '../../../redux/selectors';
import { request } from '../../../api';
import { trackException } from '../../../helpers/errorTracker';

function* loadAddCompanyByNameEvent({ payload }) {
  const { name } = payload;
  const network = yield select(activeNetworkSelector);
  try {
    const response = yield call(request, {
      url: `/collections/${network.id}/organizations`,
      options: { method: 'post', baseURL: process.env.REACT_APP_API_V2_BASE_URL, data: { name } },
    });

    const { data } = response;
    const { data: company } = data;
    const { id, attributes } = company;

    yield put(loadedAddCompanyByName({ id, ...attributes }));
    yield call(toast, 'Company successfully added.', { icon: 'success', id: 'create_company_result' });
  } catch (e) {
    yield put(errorAddCompanyByName());
    yield call(trackException, e);
  }
}

function* loadBulkAddCompanyByDomainVerifyEvent({ payload }) {
  const { domains } = payload;
  const activeNetwork = yield select(activeNetworkSelector);

  try {
    const response = yield call(request, {
      url: `/collections/${activeNetwork.id}/organizations/bulk_create`,
      options: {
        method: 'post',
        baseURL: process.env.REACT_APP_API_V2_BASE_URL,
        data: {
          domains,
        },
      },
    });
    const { data } = response;

    yield put(loadedBulkAddCompanyByDomain(data));
  } catch (error) {
    yield put(errorBulkAddCompanyByDomain());
    yield call(trackException, error);
  }
}

export default function* saga() {
  yield takeLatest(loadAddCompanyByName().type, loadAddCompanyByNameEvent);
  yield takeLatest(loadBulkAddCompanyByDomain().type, loadBulkAddCompanyByDomainVerifyEvent);
}
