import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Panel, Loader, MessageBlock } from '@getro/rombo';
import { Text } from 'rebass/styled-components';
import strings from 'strings';

const JobMessage = ({ type, editing, boxTitle, expirationDate, onClick }) => {
  const title = useMemo(() => {
    if (type === 'loading_error') {
      return strings.genericError;
    }

    if (type === 'success' && !editing) {
      if (!expirationDate) {
        return 'Job successfully posted';
      }

      return strings.expirationDate[expirationDate.value];
    }

    return 'Job successfully updated';
  }, [editing, expirationDate, type]);

  const messageType = useMemo(() => {
    if (type === 'loading_error') {
      return 'error';
    }

    return 'success';
  }, [type]);

  return (
    <Panel maxWidth={800} title={boxTitle}>
      {type === 'loading' && <Loader data-testid="job-message-loader" />}
      {type !== 'loading' && (
        <MessageBlock type={messageType} title={title} buttonText="Ok" onClick={onClick}>
          {expirationDate && (
            <Text fontSize="2" color="text.main">
              {expirationDate.value > 0 && <>We will email you before it expires.</>}

              {expirationDate.value === 0 && <>We will email you occasionally to review it.</>}
            </Text>
          )}
        </MessageBlock>
      )}
    </Panel>
  );
};

JobMessage.propTypes = {
  boxTitle: PropTypes.string,
  editing: PropTypes.bool,
  onClick: PropTypes.func,
  expirationDate: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
  type: PropTypes.oneOf(['loading', 'loading_error', 'success']).isRequired,
};

JobMessage.defaultProps = {
  boxTitle: null,
  editing: false,
  expirationDate: null,
  onClick: () => {},
};

export default JobMessage;
