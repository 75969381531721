import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EmployerDetails from './EmployerDetails';
import NetworkSchema from '../../../schemas/network';

const EmployerCard = ({ employer, network }) => {
  const { slug: networkSlug } = network;
  const { slug } = employer;

  return (
    <Link to={`/networks/${networkSlug}/companies/${slug}`} className="Employer" data-testid="employer-card">
      <EmployerDetails employer={employer} />
    </Link>
  );
};

EmployerCard.propTypes = {
  employer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  network: NetworkSchema.isRequired,
};

export default EmployerCard;
