import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLayout from '../../components/layout/Page';
import { activeNetworkSelector, isGetroUserSelector } from '../../redux/selectors';

export const SettingsLayout = ({ children, fullWidth }) => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const canManageNetworkAdmins = activeNetwork.isManager;
  const jobBoardSignup = activeNetwork.features.includes('tn_onboarding_v2');
  const isGetroUser = useSelector(isGetroUserSelector);

  return (
    <PageLayout data-cy="settings-content">
      <PageLayout.Content
        fullWidth={fullWidth}
        contentHeader={
          <Box
            mt="16px"
            width={1}
            as="nav"
            role="navigation"
            variant="navigation.subnav"
            mb={4}
            sx={{
              'a:hover': { backgroundColor: 'transparent' },
              overflow: 'hidden',
              position: 'relative',
              height: '40px',
              li: {
                display: 'inline-block',
                whiteSpace: 'nowrap',
                px: 0,
              },
              overflowX: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              WebkitOverflowScrolling: 'touch',
            }}
          >
            <Flex
              as="ul"
              sx={{
                position: 'absolute',
                gap: '24px',
              }}
            >
              <Box as="li">
                <Button
                  as={NavLink}
                  variant="activeUnderline"
                  to={`/networks/${activeNetwork.slug}/settings/general`}
                  exact
                >
                  General
                </Button>
              </Box>
              <Box as="li">
                <Button
                  as={NavLink}
                  variant="activeUnderline"
                  to={`/networks/${activeNetwork.slug}/settings/job-board`}
                  exact
                >
                  Job board
                </Button>
              </Box>
              {canManageNetworkAdmins && jobBoardSignup && (
                <Box as="li">
                  <Button
                    as={NavLink}
                    variant="activeUnderline"
                    to={`/networks/${activeNetwork.slug}/settings/contacts`}
                    exact
                  >
                    Contacts
                  </Button>
                </Box>
              )}
              {canManageNetworkAdmins && (
                <Box as="li">
                  <Button
                    as={NavLink}
                    variant="activeUnderline"
                    to={`/networks/${activeNetwork.slug}/settings/admins`}
                    exact
                  >
                    Network admins
                  </Button>
                </Box>
              )}
              {isGetroUser && (
                <Box as="li">
                  <Button
                    as={NavLink}
                    variant="activeUnderline"
                    to={`/networks/${activeNetwork.slug}/settings/plans-and-billing`}
                    exact
                  >
                    Plans and billing
                  </Button>
                </Box>
              )}
            </Flex>
          </Box>
        }
      >
        {children}
      </PageLayout.Content>
    </PageLayout>
  );
};

SettingsLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  fullWidth: PropTypes.bool,
};

SettingsLayout.defaultProps = {
  fullWidth: false,
};
