import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@getro/rombo';
import { Formik, Form } from 'formik';
import { Flex } from 'rebass/styled-components';
import RenewJob from '../RenewJob';
import OrganizationSchema from '../../../schemas/organization';
import { usePrevious } from '../../../hooks/usePrevious';

export const RenewJobModal = ({ isOpen, title, company, onClose, onSubmit, isSubmitting, error }) => {
  const ref = useRef();
  const [succeed, setSucceed] = useState(false);
  const previousSubmitting = usePrevious(isSubmitting);
  const wasSubmitting = useMemo(() => previousSubmitting && isOpen, [previousSubmitting, isOpen]);
  const modalTitle = useMemo(() => (succeed ? null : 'Extend job expiration date'), [succeed]);

  const handleClose = () => {
    setSucceed(false);
    onClose();
  };

  const handleSubmit = ({ expirationDate }) => {
    onSubmit(expirationDate);
  };

  const initialValues = {
    expirationDate: 60,
  };

  const modalActions = useMemo(() => {
    if (succeed) {
      return null;
    }

    return (
      <Flex justifyContent="flex-end" alignItems="baseLine" data-testid="interactive-renew-action-buttons">
        <Button onClick={handleClose} variant="secondary" mr={1} sx={{ border: 'none' }}>
          Cancel
        </Button>
        <Button onClick={() => ref.current?.handleSubmit()} loading={isSubmitting}>
          Extend expiration date
        </Button>
      </Flex>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [succeed, isSubmitting]);

  useEffect(() => {
    if (wasSubmitting && !isSubmitting) {
      setSucceed(!error);
    }
  }, [isSubmitting, wasSubmitting, error]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      data-testid="interactive-renew-modal"
      onCancel={handleClose}
      isOpen={isOpen}
      title={modalTitle}
      actions={modalActions}
    >
      <Formik initialValues={initialValues} innerRef={ref} onSubmit={handleSubmit}>
        <Form>
          <RenewJob
            id="expirationDate"
            name="expirationDate"
            title={title}
            company={company}
            error={error}
            succeed={succeed}
            onClose={handleClose}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

RenewJobModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  company: OrganizationSchema,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  error: PropTypes.bool.isRequired,
};

RenewJobModal.defaultProps = {
  isOpen: false,
  title: '',
  company: { id: 0, name: '', domain: '' },
  isSubmitting: false,
};

export default RenewJobModal;
