import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormField, Message, AsyncButton, Button } from '@getro/rombo';

import strings from 'strings';
import get from 'helpers/get';
import { put } from 'api';
import { trackException } from 'helpers/errorTracker';
import { useSelector } from 'react-redux';
import { networksSelector, userSelector } from '../../../redux/selectors';

const emailFormSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const EmailForm = () => {
  const networks = useSelector(networksSelector);
  const user = useSelector(userSelector);
  const { email } = user;
  const { active: activeNetworkId } = networks;
  const [editingEmail, setEditingEmail] = useState(false);
  const [success, setSuccess] = useState(false);
  const cancelTokenSource = useRef(axios.CancelToken.source());

  const handleEditClick = () => {
    setEditingEmail(true);
    setSuccess(false);
  };

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    setStatus({ error: null });

    if (values.email.toLowerCase() === email.toLowerCase()) {
      setEditingEmail(false);
      return;
    }

    try {
      const params = {
        collection_id: activeNetworkId,
        email: values.email,
      };

      setSubmitting(true);
      await put('/users/me/email', params, {
        cancelToken: cancelTokenSource.current.token,
      });
      setSubmitting(false);
      setEditingEmail(false);
      setSuccess(true);
    } catch (error) {
      // Generic error by default
      let errorMessage = strings.genericError;

      // Catch known errors
      if (get(error, 'response.status') === 422 && get(error, 'response.data.email[0]') === 'has already been taken') {
        errorMessage = strings.account.emailAlreadyInUse;
      }

      trackException(error);
      setStatus({ error: errorMessage });
      setSubmitting(false);
    }
  };

  useEffect(
    () => () => {
      cancelTokenSource.current.cancel();
    },
    [],
  );

  return (
    <>
      {!editingEmail && (
        <>
          <div>
            <span>{email}</span>
            <Button variant="underlineLink" onClick={handleEditClick}>
              Edit
            </Button>
          </div>
          {success && (
            <Message
              type="success"
              className="EmailForm__message"
              content="We have sent you a confirmation email to the new address, please follow the intructions in the email."
            />
          )}
        </>
      )}
      {editingEmail && (
        <Formik initialValues={{ email }} validationSchema={emailFormSchema} onSubmit={handleSubmit}>
          {({ status, isSubmitting }) => (
            <Form>
              <Field type="email" name="email" component={FormField} />

              {status && status.error && <Message type="error" className="EmailForm__message" content={status.error} />}

              <div className="EmailForm__buttons">
                <Button mr={1} variant="secondary" onClick={() => setEditingEmail(false)}>
                  Cancel
                </Button>
                <AsyncButton ml={1} variant="primary" type="submit" loading={isSubmitting}>
                  Save
                </AsyncButton>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default EmailForm;
