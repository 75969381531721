export default function rgbaToHex(rgba) {
  const values = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

  if (!values || values.length !== 4) {
    return '';
  }

  return `#${`0${parseInt(values[1], 10).toString(16)}`.slice(-2)}${`0${parseInt(values[2], 10).toString(16)}`.slice(
    -2,
  )}${`0${parseInt(values[3], 10).toString(16)}`.slice(-2)}`;
}
