import { Button, Modal } from '@getro/rombo';
import React from 'react';
import { Box, Flex, Text, Image } from 'rebass/styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import MatchesIntroModalBackground from '../../assets/matches-intro-modal-background.png';
import { trackEvent } from '../../helpers/analytics';
import { activeNetworkSelector } from '../../redux/selectors';

export const MatchesIntroModal = ({ onCancel }) => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const history = useHistory();

  const onClickGetStarted = () => {
    trackEvent('connect_upsell:get_started_click', {
      origin: 'upsell_get_started_modal',
      page_type: 'admin_portal_home',
    });
    history.push(`/networks/${activeNetwork.slug}/import-contacts`);
  };

  return (
    <Box
      sx={{
        '[data-testid="body"]': {
          ml: 0,
          mr: 0,
          pt: 0,
          pb: 0,
          p: '24px',
        },
      }}
    >
      <Modal variant="xLarge" isOpen onCancel={onCancel}>
        <Flex
          flexWrap="wrap-reverse"
          flexDirection={['column-reverse', 'row']}
          sx={{ rowGap: '32px' }}
          alignItems="center"
        >
          <Box maxWidth={['100%', '100%', '50%']}>
            <Text
              fontSize="12px"
              fontWeight="medium"
              color="purple.300"
              sx={{ letterSpacing: '1px', textTransform: 'uppercase' }}
            >
              Introducing GetroConnect
            </Text>
            <Text mt="8px" fontSize="24px" fontWeight="semibold" color="text.dark" lineHeight="28px">
              Get AI-powered matches between all your team’s contacts and companies
            </Text>
            <Text mt="8px" color="neutral.700" fontWeight="medium">
              Never miss a great intro.
            </Text>
            <Text
              mt="40px"
              fontSize="12px"
              color="text.subtle"
              sx={{ letterSpacing: '1px', textTransform: 'uppercase' }}
            >
              How it works
            </Text>
            <Flex mt="16px" alignItems="center">
              <Box mr="8px" bg="purple.300" width="8px" height="8px" sx={{ borderRadius: 'circle' }} />
              <Text fontWeight="medium" color="text.dark">
                Add your team&apos;s contacts
              </Text>
            </Flex>
            <Flex mt="16px" alignItems="center">
              <Box mr="8px" bg="purple.300" width="8px" height="8px" sx={{ borderRadius: 'circle' }} />
              <Text fontWeight="medium" color="text.dark">
                Get contacts and jobs matched with AI
              </Text>
            </Flex>
            <Flex mt="16px" alignItems="center">
              <Box mr="8px" bg="purple.300" width="8px" height="8px" sx={{ borderRadius: 'circle' }} />
              <Text fontWeight="medium" color="text.dark">
                Easily share matches with companies
              </Text>
            </Flex>
            <Flex mt="40px" sx={{ gap: '16px' }}>
              <Button onClick={onClickGetStarted}>Get started</Button>
              <Button variant="tertiary" onClick={onCancel}>
                Maybe later
              </Button>
            </Flex>
          </Box>

          <Image
            src={MatchesIntroModalBackground}
            flex="0 0 auto"
            width={['100%', '100%', '100%']}
            height={['auto', 'auto', '313px']}
            alt="Never miss a great intro."
            maxWidth={['100%', '100%', '50%']}
          />
        </Flex>
      </Modal>
    </Box>
  );
};

MatchesIntroModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
