import React from 'react';
import PropTypes from 'prop-types';
import { StatusIndicator } from '@getro/rombo';

const JobStatus = ({ job }) => {
  const { status } = job;
  if (status === 'closed') return <StatusIndicator status="Closed" />;
  if (status === 'active') return <StatusIndicator status="Open" statusColor="success" />;
  if (status === 'expired') return <StatusIndicator status="Expired" statusColor="warning" />;

  return <StatusIndicator status={status} />;
};

JobStatus.propTypes = {
  job: PropTypes.shape({
    status: PropTypes.string.isRequired,
    passesFilter: PropTypes.bool,
  }).isRequired,
};

export default JobStatus;
