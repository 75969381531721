import { useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';

const useOneTimeRender = ({ user, keySuffix }) => {
  const [visible, setVisible] = useState(false);
  const [doNotRender, setDoNotRender] = useLocalStorage(`${user.id}-${keySuffix}`, false);

  useEffect(() => {
    if (user && !doNotRender) {
      setDoNotRender(true);
      setVisible(true);
    }
  }, [doNotRender, setDoNotRender, user]);

  return {
    visible,
    setVisible,
  };
};

export default useOneTimeRender;
