import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Message, Panel, AsyncButton, Loader } from '@getro/rombo';

export const SavePanel = forwardRef(
  ({ isPanelLoading, children, title, isButtonLoading, onClick, errorMessage }, ref) => {
    const renderFooter = useCallback(() => {
      if (isPanelLoading) {
        return null;
      }

      return (
        <Flex width={[1]} flexDirection={['column']}>
          {errorMessage && errorMessage.length && (
            <Box mb={3} width={[1]} as={Message} type="error">
              {errorMessage}
            </Box>
          )}
          <Flex justifyContent={['flex-end']}>
            <AsyncButton type="button" ml={2} variant="primary" loading={isButtonLoading} onClick={onClick}>
              Save
            </AsyncButton>
          </Flex>
        </Flex>
      );
    }, [isPanelLoading, errorMessage, isButtonLoading, onClick]);

    return (
      <Panel title={title} footer={renderFooter()} ref={ref}>
        {isPanelLoading ? <Loader /> : children}
      </Panel>
    );
  },
);

SavePanel.propTypes = {
  isPanelLoading: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  errorMessage: PropTypes.string,
  isButtonLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

SavePanel.defaultProps = {
  isPanelLoading: false,
  title: null,
  errorMessage: '',
};
