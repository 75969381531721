import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  updateTalentGroup,
  loadTalentGroups,
  loadedTalentGroups,
  errorTalentGroups,
  loadSubscribeGroup,
  loadedSubscribeGroup,
  errorSubscribeGroup,
} from '../../actions/talentGroupActions';
import { request } from '../../api';
import talentGroupsDecorator from '../../helpers/talentGroupsDecorator';
import { activeNetworkSelector } from '../selectors';

function* loadTalentGroupsEvent({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(request, {
      url: `/collections/${id}/talent_groups`,
    });

    const { data } = response;
    const { items } = data || {};
    yield put(
      loadedTalentGroups({
        id,
        talentGroups: talentGroupsDecorator(items),
      }),
    );
  } catch (error) {
    yield put(errorTalentGroups({ error }));
  }
}

function* loadSubscribeGroupEvent({ payload }) {
  const { talentGroup } = payload;
  const { id, subscribed } = talentGroup;

  try {
    const activeNetwork = yield select(activeNetworkSelector);
    const { id: collectionId } = activeNetwork;
    yield call(request, {
      url: `/collections/${collectionId}/talent_groups/${id}/subscription`,
      options: {
        method: subscribed ? 'delete' : 'post',
      },
    });

    talentGroup.subscribed = !talentGroup.subscribed;
    talentGroup.subscribtionsCount += talentGroup.subscribed ? 1 : -1;
    yield put(updateTalentGroup({ id: collectionId, talentGroup }));

    yield put(
      loadedSubscribeGroup({
        id,
        collectionId,
        subscribed: !subscribed,
        subscribe: !subscribed,
        origin: 'board_header',
        board: talentGroup,
        activeNetwork,
      }),
    );
  } catch (error) {
    yield put(errorSubscribeGroup({ error }));
  }
}

export default function* talentGroupsSaga() {
  yield takeLatest(loadTalentGroups().type, loadTalentGroupsEvent);
  yield takeLatest(loadSubscribeGroup().type, loadSubscribeGroupEvent);
}
