import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ProfilePicture, Button } from '@getro/rombo';
import { X } from 'lucide-react';

export const MemberTag = ({ sx, member, onRemove }) => (
  <Flex
    data-testid="member-tag"
    pb={2}
    flexDirection="row"
    sx={sx}
    alignItems="stretch"
    key={`vouched_by_${member.value}`}
  >
    <Box pr={2} width={[1 / 10]}>
      <ProfilePicture imageUrl={member.avatarUrl} name={member.label} />
    </Box>
    <Box display="flex" alignItems="center" flexGrow={[1]}>
      {member.label}
    </Box>
    {onRemove && (
      <Box width={[1 / 10]} display="flex" justifyContent={['flex-end']}>
        <Button
          data-testid="remove-button"
          variant="secondary"
          color="gray.1"
          sx={{ border: 'none', ':hover': { bg: 'transparent', color: 'text.main' } }}
          display="flex"
          alignItems="center"
          onClick={onRemove}
          type="button"
        >
          <Text variant="variants.srOnly">Remove</Text>
          <Box data-item="close-icon" height="1em" width="1em" strokeWidth="1.5" aria-hidden="true" as={X} />
        </Button>
      </Box>
    )}
  </Flex>
);

MemberTag.propTypes = {
  member: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
  }).isRequired,
  onRemove: PropTypes.func,
  sx: PropTypes.object,
};

MemberTag.defaultProps = {
  onRemove: null,
  sx: {},
};
