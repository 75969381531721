import React, { useRef, useEffect } from 'react';
import tldjs from 'tldjs';
import { Box, FormError, FormTextarea } from '@getro/rombo';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { Box as RebassBox, Text } from 'rebass/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { urlWithoutProtocolRegEx } from 'helpers/regExs';
import helperGet from '../../helpers/get';

import strings from '../../strings';
import { loadBulkAddCompanyByDomain } from './redux/actions';
import { makeSelectIsLoading } from '../../redux/loadingSelector';
import { bulkCompanyMetaSelector, hasErrorSelector, needsVerificationSelector } from './redux/selectors';
import CompaniesAdded from './companiesAdded';

const initialValues = {
  websites: [],
};

const getInvalidDomains = (websites) =>
  websites
    .filter((website) => website && website.length)
    .filter((website) => !RegExp(urlWithoutProtocolRegEx).test(website));

const AddBulkCompanyWithDomain = ({ goToCompaniesList, showAddCompanyWithoutDomain }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const isSubmitting = useSelector(makeSelectIsLoading('bulkAddCompanyByDomain'));
  const needsVerification = useSelector(needsVerificationSelector);
  const addedCompanyMeta = useSelector(bulkCompanyMetaSelector);
  const hasError = useSelector(hasErrorSelector);

  useEffect(() => {
    if (hasError && !needsVerification && formRef.current) {
      formRef.current.setStatus({ error: strings.genericError });
    }
  }, [hasError, needsVerification, formRef]);

  const handleSubmit = async ({ websites }) => {
    const domains = await websites.map((website) => tldjs.getDomain(website));
    dispatch(loadBulkAddCompanyByDomain({ domains }));
  };

  if (addedCompanyMeta) {
    return <CompaniesAdded onDone={goToCompaniesList} meta={addedCompanyMeta} />;
  }

  const onFormValidation = (values) => {
    const { websites } = values;

    if (!websites || !websites.filter((website) => website.trim().length > 0).length) {
      return { websites: 'Required' };
    }

    const invalidDomains = getInvalidDomains(values.websites);
    if (invalidDomains.length) {
      return { invalidDomains };
    }

    return {};
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={formRef} validate={onFormValidation}>
      {({ status, setFieldValue, values, errors }) => (
        <Form>
          <RebassBox
            sx={{
              '.Box__title': {
                fontWeight: 600,
              },
              '.Box__footer-back-button': {
                fontWeight: 400,
              },
            }}
          >
            <Box
              className="AddCompany"
              errorText={helperGet(status, 'error', null)}
              onCancelClick={goToCompaniesList}
              loading={isSubmitting}
              editing
              saveAsSubmit
              title="New companies websites"
              saveText="Add companies"
              onBackClick={showAddCompanyWithoutDomain}
              backText="Add company without website"
            >
              <Field
                type="text"
                name="websites"
                component={FormTextarea}
                value={values.websites?.join(',').replace(/,/g, '\n')}
                onChange={(e) => {
                  setFieldValue('websites', e.target.value.split(/[\n,]+/));
                }}
                height="120px"
                placeholder={`website1.com\nwebsite2.com\nwebsite3.com\n...`}
                label="Companies websites"
                helpText="Add the websites of the companies you want to add, one per line. You can copy and paste them directly from a spreadsheet."
              />
              {errors.invalidDomains && (
                <RebassBox sx={{ padding: 10, marginTop: 2 }} backgroundColor="red.20">
                  <FormError>Review the format of the following websites:</FormError>
                  <RebassBox as="ul" margin={0}>
                    {errors.invalidDomains.map((website) => (
                      <Text fontSize="14px" as="li" color="red.600">
                        {website}
                      </Text>
                    ))}
                  </RebassBox>
                </RebassBox>
              )}
            </Box>
          </RebassBox>
        </Form>
      )}
    </Formik>
  );
};

AddBulkCompanyWithDomain.propTypes = {
  goToCompaniesList: PropTypes.func.isRequired,
  showAddCompanyWithoutDomain: PropTypes.func,
};

AddBulkCompanyWithDomain.defaultProps = {
  showAddCompanyWithoutDomain: null,
};

export default AddBulkCompanyWithDomain;
