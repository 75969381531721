import React from 'react';
import {
  Button,
  Checkbox,
  FormInput,
  FormSectionTitle,
  FormSelect,
  FormTextEditor,
  Loader,
  Message,
  ProfilePicture,
} from '@getro/rombo';
import { FastField, Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Lock } from 'lucide-react';
import { usePrivateList } from './hooks/usePrivateList';
import { ReduxMultiFormSelect } from './components/ReduxMultiFormSelect';
import { CompanyDetailsForm } from './components/companyDetailsForm';

export const PrivateList = ({ edit }) => {
  const {
    skills,
    employmentTypes,
    locations,
    formSchema,
    submit,
    isSubmitting,
    error,
    isCompanyFromPortfolio,
    setIsCompanyFromPortfolio,
    initialValues,
    match,
    loadingMatch,
    matchIsOutsidePortfolioCompany,
  } = usePrivateList(edit);

  if (edit && !match && loadingMatch) {
    return (
      <Flex justifyContent="center" alignItems="center" height="70vh">
        <Loader />
      </Flex>
    );
  }

  return (
    <Box sx={{ maxWidth: '800px', width: '100%', m: '0 auto' }}>
      <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
        {edit && (
          <Message
            content={
              <Box>
                <Box as="p" fontWeight="500" lineHeight="1.4" fontSize="14px" mb="4px">
                  Our AI will generate new matches without modifying your list.
                </Box>
                <Box as="p" fontWeight="500" lineHeight="1.4" fontSize="14px" mb="4px">
                  All information will remain only visible to your team and people you share this list with, never on
                  your job board.{' '}
                </Box>
              </Box>
            }
          />
        )}
        {!edit && (
          <>
            <Box as="h3" fontSize="16px" fontWeight="500">
              Enter the private job details{' '}
            </Box>
            <Flex
              sx={{
                'p, svg': { color: 'text.main' },
                borderRadius: '8px',
                backgroundColor: 'neutral.20',
                justifyContent: 'flex-start',
                alignItems: edit ? 'flex-start' : 'center',
                mb: '24px',
                p: '16px',
              }}
            >
              <Box width="16px" height="16px" mr="16px">
                <Box as={Lock} size="16px" />
              </Box>

              <Box as="p" fontWeight="500" lineHeight="1.4" fontSize="14px">
                Only visible to your team and people you share this list with, never on your job board.{' '}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submit}
        validateOnBlur
        validateOnChange
        validationSchema={formSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Flex flexDirection="column" sx={{ gap: '24px' }}>
              <Flex flexDirection="column" sx={{ gap: '24px' }}>
                <FormSectionTitle>1. Company details</FormSectionTitle>

                {match && edit && !matchIsOutsidePortfolioCompany && (
                  <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
                    <Flex sx={{ alignItems: 'center', gap: '8px' }}>
                      <ProfilePicture
                        height="40px"
                        width="40px"
                        imageUrl={match?.organization?.logoUrl}
                        name={match?.organization?.name}
                        variant="square"
                      />
                      <Text
                        as="p"
                        color="text.dark"
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="1.4"
                        height="fit-content"
                      >
                        {match?.organization?.name}
                      </Text>
                    </Flex>
                    <Box as="p" color="text.main" lineHeight="20px">
                      Company details can’t be change for companies in the portfolio.
                    </Box>
                  </Flex>
                )}
                {(!edit || matchIsOutsidePortfolioCompany) && (
                  <CompanyDetailsForm
                    edit={edit}
                    values={values}
                    isCompanyFromPortfolio={isCompanyFromPortfolio}
                    setFieldValue={setFieldValue}
                    setIsCompanyFromPortfolio={setIsCompanyFromPortfolio}
                  />
                )}
              </Flex>
              <Flex flexDirection="column" sx={{ gap: '24px' }}>
                <FormSectionTitle>2. Job details</FormSectionTitle>
                <FastField
                  component={FormInput}
                  name="title"
                  placeholder="Ex: Senior Account Manager"
                  label="Title"
                  labelExtra="Required"
                />
                <FastField
                  component={FormTextEditor}
                  name="description"
                  placeholder="Enter a position details"
                  label="Description"
                  helpText="A job description aids in generating high-quality matches. Since it won’t be publish it doesn’t need to be well formatted, what’s important is having the most important keywords."
                />
                <Field
                  component={ReduxMultiFormSelect}
                  name="skills"
                  label="Skills"
                  loading={skills.loading}
                  placeholder="Start typing..."
                  options={skills.options}
                  labelExtra="Required"
                  onInputChange={skills.search}
                />
                <Field
                  component={ReduxMultiFormSelect}
                  name="locations"
                  label="Location"
                  labelExtra="Required"
                  loading={locations.loading}
                  options={locations.options}
                  onInputChange={locations.search}
                  orientation="horizontal"
                  placeholder="Start typing..."
                />
                <Checkbox
                  label="Remote position"
                  name="remote"
                  value={values.remote}
                  checked={values.remote}
                  onChange={() => setFieldValue('remote', !values.remote)}
                />
                <FastField
                  component={FormSelect}
                  name="employmentType"
                  label="Employment type"
                  options={employmentTypes}
                  labelExtra="Required"
                  placeholder="Select"
                />
              </Flex>

              {error && (
                <Message type="error" title="Invalid website format">
                  There was an unexpected error, please try again or contact us.
                </Message>
              )}
              <Flex justifyContent="flex-end">
                <Button loading={isSubmitting} type="submit">
                  {edit ? 'Save and get new matches' : 'Create list'}
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

PrivateList.defaultProps = {
  edit: false,
};

PrivateList.propTypes = {
  edit: PropTypes.bool,
};
