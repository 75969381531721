import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  errorPublishSuggestedCompany,
  errorSkipSuggestedCompany,
  errorSuggestedCompanies,
  loadedPublishSuggestedCompany,
  loadedSkipSuggestedCompany,
  loadedSuggestedCompanies,
  loadPublishSuggestedCompany,
  loadSkipSuggestedCompany,
  loadSuggestedCompanies,
} from './actions';

export const initialState = {
  addingIds: [],
  skippingIds: [],
  suggested: {
    error: false,
    initialized: false,
    companies: [],
    meta: {
      total: 0,
    },
  },
};

/* eslint-disable default-case, no-param-reassign */
const suggestedCompanyReducer = handleActions(
  {
    [loadSuggestedCompanies]: produce((draft, { payload: { page } }) => {
      if (page > 1) {
        return draft;
      }

      return {
        ...initialState,
        suggested: {
          ...initialState.suggested,
          meta: draft.suggested.meta ?? initialState.suggested.meta,
        },
      };
    }),
    [loadedSuggestedCompanies]: produce((draft, { payload: { data, page } }) => {
      if (page > 0) {
        draft.suggested.companies = [...draft.suggested.companies, ...data.items];
      } else {
        draft.suggested.companies = data.items;
      }
      draft.suggested.meta = data.meta;
      draft.suggested.initialized = true;
      draft.suggested.error = false;

      return draft;
    }),
    [errorSuggestedCompanies]: produce((draft) => {
      draft.suggested.error = true;
      draft.suggested.initialized = true;
      return draft;
    }),
    [loadSkipSuggestedCompany]: produce((draft, { payload: { companyId } }) => {
      draft.skippingIds = [...draft.skippingIds, companyId];

      return draft;
    }),
    [loadedSkipSuggestedCompany]: produce((draft, { payload: { companyId } }) => {
      draft.skippingIds = draft.skippingIds.filter((item) => item !== companyId);
      draft.suggested.companies = draft.suggested.companies.map((item) => {
        if (item.id === companyId) {
          return {
            ...item,
            status: 'skipped',
          };
        }
        return item;
      });
      draft.suggested.meta.total -= 1;
      return draft;
    }),
    [errorSkipSuggestedCompany]: produce((draft, { payload: { companyId } }) => {
      draft.skippingIds = draft.skippingIds.filter((item) => item !== companyId);
    }),
    [loadPublishSuggestedCompany]: produce((draft, { payload: { companyId } }) => {
      draft.addingIds = [...draft.addingIds, companyId];

      return draft;
    }),
    [loadedPublishSuggestedCompany]: produce((draft, { payload: { companyId } }) => {
      draft.addingIds = draft.addingIds.filter((item) => item !== companyId);
      draft.suggested.companies = draft.suggested.companies.map((item) => {
        if (item.id === companyId) {
          return {
            ...item,
            status: 'published',
          };
        }
        return item;
      });
      draft.suggested.meta.total -= 1;
      return draft;
    }),
    [errorPublishSuggestedCompany]: produce((draft, { payload: { companyId } }) => {
      draft.addingIds = draft.addingIds.filter((item) => item !== companyId);
    }),
  },
  initialState,
);

export default suggestedCompanyReducer;
