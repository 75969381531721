import dompurify from 'dompurify';
import { marked } from 'marked';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex } from 'rebass/styled-components';
import { Eye, EyeOff } from 'lucide-react';
import { Button, ProfilePicture } from '@getro/rombo';

export const EditableActionItemNotes = ({ isPublic, extendMethods, item, onRemove, ...rest }) => {
  const { author, body, external, title, updatedAt } = item;

  return (
    <Box
      p="8px"
      mt="16px"
      sx={{
        border: 1,
        borderColor: 'neutral.100',
        borderRadius: 'default',
        cursor: 'pointer',
        ':hover': {
          '&& > [data-title="true"]': {
            textDecoration: 'underline',
          },
        },
      }}
      onClick={() => extendMethods.onViewNote(item)}
      {...rest}
    >
      <Text data-title="true" fontSize="14px" fontWeight="medium">
        {title}
      </Text>
      <Text mt="8px" fontSize="14px" maxHeight="64px" sx={{ overflow: 'hidden' }}>
        <Box
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(marked(body)),
          }}
          sx={{
            '&& *': {
              fontSize: 1,
              fontWeight: 'body',
            },
            '&& *:first-child': {
              mt: 0,
              marginBlockStart: '0 !important',
            },
          }}
        />
      </Text>
      {!isPublic && (
        <Flex mt="16px" alignContent="center" color="text.subtle">
          {external ? (
            <>
              <Box as={Eye} width="16px" height="16px" aria-hidden="true" />
              <Text ml="8px" fontSize="0">
                Visible to companies
              </Text>
            </>
          ) : (
            <>
              <Box as={EyeOff} width="16px" height="16px" aria-hidden="true" />
              <Text ml="8px" fontSize="0" color="text.subtle">
                Internal
              </Text>
            </>
          )}
        </Flex>
      )}
      <Flex mt="8px" alignItems="center" justifyContent="space-between" sx={{ columnGap: '16px' }}>
        <Flex alignItems="center">
          <Box width="24px" height="24px" mr="8px" flex="0 0 auto">
            <ProfilePicture name={author.fullName} />
          </Box>
          <Text
            fontSize="0"
            fontWeight="medium"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {author.fullName}
          </Text>
          <Text ml="8px" fontSize="0" color="text.subtle" flex="0 0 auto">
            {new Date(updatedAt).toLocaleDateString('en-EN', { year: 'numeric', month: 'short' })}
          </Text>
        </Flex>
        <Flex flex="0 0 auto">
          {!!extendMethods?.onEditNote && (
            <Button
              px="8px"
              variant="tertiary"
              size="small"
              onClick={(event) => {
                event.stopPropagation();

                extendMethods.onEditNote(item);
              }}
            >
              Edit
            </Button>
          )}
          {!!extendMethods?.setCurrentNote && extendMethods.setShowDeleteModal && (
            <Button
              px="8px"
              variant="tertiary"
              size="small"
              onClick={(event) => {
                event.stopPropagation();

                extendMethods.setCurrentNote(item);
                extendMethods.setShowDeleteModal(true);
              }}
            >
              Delete
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

EditableActionItemNotes.propTypes = {
  isPublic: PropTypes.bool,
  extendMethods: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
};

EditableActionItemNotes.defaultProps = {
  isPublic: false,
  onRemove: null,
};
