import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { useGetBulkContactOperationByIdQuery } from '../../../services/contacts';

export const BulkNotificationPolling = ({ toastId, transactionId, title, description, onFinish }) => {
  const { data } = useGetBulkContactOperationByIdQuery({ id: transactionId }, { pollingInterval: 5000 });
  const location = useLocation();
  const status = data?.bulkContactsOperation?.status;

  useEffect(() => {
    if (status === 'finished') {
      onFinish(toastId, location.pathname);
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Text
        color="text.dark"
        sx={{
          fontWeight: 'medium',
          fontSize: ['16px', '16px', '14px'],
        }}
      >
        {title}
      </Text>
      <Text mt="8px" color="text.main" sx={{ fontSize: ['16px', '16px', '14px'] }}>
        {description}
      </Text>
    </Box>
  );
};

BulkNotificationPolling.propTypes = {
  toastId: PropTypes.string.isRequired,
  transactionId: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  onFinish: PropTypes.func,
};

BulkNotificationPolling.defaultProps = {
  title: '',
  description: '',
  onFinish: null,
};
