import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Download, Info } from 'lucide-react';
import { Button, Loader, Tooltip } from '@getro/rombo';

export const InfoBox = ({
  count,
  description,
  title,
  actionText,
  actionOnClick,
  actionIcon,
  actionLoading,
  infoText,
  width,
  height,
  loading,
  comingSoon,
  sx = {},
  showCount,
  children,
}) => (
  <Flex
    data-testid={title}
    sx={{
      bg: 'neutral.20',
      p: '16px',
      flexDirection: 'column',
      gap: '8px',
      borderRadius: '8px',
      height: '100%',
      width: '100%',
      maxWidth: width,
      maxHeight: height,
      ...sx,
    }}
  >
    {(comingSoon || (!comingSoon && showCount) || infoText) && (
      <Flex justifyContent="space-between">
        {comingSoon && (
          <Flex height="32px" alignItems="center">
            <Text
              as="p"
              fontWeight="400"
              fontSize="14px"
              lineHeight="1.4"
              color="text.subtle"
              sx={{ fontStyle: 'italic' }}
            >
              Coming soon
            </Text>
          </Flex>
        )}

        {!comingSoon && showCount && (
          <>
            {!loading ? (
              <Text as="h2" fontWeight="600" fontSize="24px" lineHeight="32px">
                {Number(count).toLocaleString()}
              </Text>
            ) : (
              <Box height="32px" py="8px">
                <Loader size="small" sx={{ borderTopColor: '#4C5566', '& > div': { margin: '0px' } }} />
              </Box>
            )}
          </>
        )}
        {infoText && (
          <Box
            sx={{
              'p, div': {
                fontSize: '14px',
              },
              svg: {
                color: 'neutral.300',
              },
            }}
          >
            <Tooltip
              sx={{
                bg: '#fff',
                color: 'text.main',
                p: '16px',
                '.tooltip-arrow:after': { borderTopColor: '#fff!important' },
              }}
              position="top-start"
              content={<Text as="p">{infoText}</Text>}
              trigger={<Box width="16px" height="16px" as={Info} />}
            />
          </Box>
        )}
      </Flex>
    )}
    {(title || description) && (
      <Box>
        {title && (
          <Text as="h2" color="text.main" fontWeight="500" fontSize="14px" lineHeight="1.2">
            {title}
          </Text>
        )}
        {description && (
          <Text as="p" fontSize="12px" lineHeight="1.4" color="text.main">
            {description}
          </Text>
        )}
      </Box>
    )}
    {actionText && actionOnClick && !comingSoon && (
      <Button
        sx={{
          backgroundColor: 'transparent',
          svg: {
            color: 'text.main',
          },
          boxShadow: 'none',
        }}
        padding="0"
        height="32px"
        width="fit-content"
        color="text.main"
        iconGap={2}
        variant="tertiary"
        icon={actionIcon || Download}
        onClick={actionOnClick}
        loading={actionLoading}
      >
        {actionText}
      </Button>
    )}
    {children}
  </Flex>
);

InfoBox.defaultProps = {
  title: '',
  description: '',
  actionIcon: null,
  actionText: '',
  actionOnClick: () => {},
  infoText: null,
  width: 'fit-content',
  height: 'fit-content',
  loading: false,
  actionLoading: false,
  comingSoon: false,
  sx: {},
  count: 0,
  showCount: true,
  children: null,
};

InfoBox.propTypes = {
  count: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionIcon: PropTypes.element,
  actionText: PropTypes.string,
  actionOnClick: PropTypes.func,
  infoText: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  height: PropTypes.string,
  loading: PropTypes.bool,
  actionLoading: PropTypes.bool,
  comingSoon: PropTypes.bool,
  showCount: PropTypes.bool,
  sx: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
};
