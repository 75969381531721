import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Button, Modal } from '@getro/rombo';
import LocationsFilter from 'components/organisms/jobsTableWithFilters/locationsFilter';
import { useFocusTrapState } from 'hooks/useFocusTrapState';
import { FilterButton } from 'components/atoms/FilterButton';
import { useDisableRootOnMobileScroll } from 'hooks/useDisableRootOnMobileScroll';
import KeywordFilter from './keywordFilter';
import TagsFilter from './tagsFilter';
import JobSourceFilter from './jobSourceFilter';
import AdminFilter from './adminFilter';
import PublishedFilter from './publishedFilter';
import { DEFAULT_FILTERS } from '../helper';

const AllFiltersModal = ({ companyFilters, applyFilters, tags, filterCount }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { pauseFocusTrap, onDropDownStateChange } = useFocusTrapState();
  const [filters, setFilters] = useState({ ...companyFilters, page: 0 });

  const setFilter = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  useDisableRootOnMobileScroll(isOpen);

  return (
    <Flex
      width={['100%', 'initial']}
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        '& > div:first-of-type': {
          width: ['100vw', '100%'],
          height: ['100dvh', '100%'],
        },

        button: {
          width: 'fit-content',
        },
      }}
    >
      <Modal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        variant="large"
        title="All filters"
        sx={{ width: '100vw', 'div[data-testid="overlay"]': { display: 'none' } }}
        pauseFocusTrap={pauseFocusTrap}
        actions={
          <Flex flexDirection="row" justifyContent="flex-end">
            <Button
              mr={1}
              fontSize={1}
              variant="secondary"
              onClick={() => {
                applyFilters(DEFAULT_FILTERS);
                setIsOpen(false);
              }}
            >
              Clear filters
            </Button>
            <Button
              ml={1}
              fontSize={1}
              fontWeight="semibold"
              onClick={() => {
                applyFilters(filters);
                setIsOpen(false);
              }}
            >
              View results
            </Button>
          </Flex>
        }
      >
        <Flex flexDirection="column" sx={{ gap: [3] }}>
          <Flex flexDirection="row" width={[1]}>
            <KeywordFilter onChange={(value) => setFilter('keyword', value)} value={filters.keyword} />
          </Flex>
          <Flex flexDirection={['column', 'row']} width={[1]} sx={{ gap: [3] }}>
            <Box width={[1, 1 / 2]}>
              <LocationsFilter
                onDropDownStateChange={onDropDownStateChange}
                onChange={({ target }) =>
                  setFilter('places', target.value?.length ? JSON.stringify(target.value) : null)
                }
                value={JSON.parse(filters.places)}
                embeddedInModal
              />
            </Box>
            <Box width={[1, 1 / 2]}>
              <TagsFilter
                tags={tags}
                value={filters.topicIds}
                onChange={(value) => setFilter('topicIds', value)}
                embeddedInModal
                onDropDownStateChange={onDropDownStateChange}
              />
            </Box>
          </Flex>
          <Flex flexDirection={['column', 'row']} width={[1]} sx={{ gap: [3] }}>
            <Box width={[1, 1 / 2]}>
              <JobSourceFilter
                onChange={({ value }) => setFilter('ocpStatus', value)}
                value={filters.ocpStatus || ''}
                embeddedInModal
                onDropDownStateChange={onDropDownStateChange}
              />
            </Box>
            <Box width={[1, 1 / 2]}>
              <AdminFilter
                onDropDownStateChange={onDropDownStateChange}
                onChange={({ value }) => setFilter('missingManager', value)}
                value={filters.missingManager || ''}
                embeddedInModal
              />
            </Box>
          </Flex>
          <Flex flexDirection={['column', 'row']} width={[1]} sx={{ gap: [3] }}>
            <Box width={[1, 1 / 2]}>
              <PublishedFilter
                onDropDownStateChange={onDropDownStateChange}
                value={filters.qa || ''}
                onChange={({ value }) => setFilter('qa', value)}
                embeddedInModal
              />
            </Box>
            <Box width={[1 / 2]}></Box>
          </Flex>
        </Flex>
      </Modal>

      <FilterButton
        onClick={() => setIsOpen(true)}
        onAllFiltersClicked={() => {
          setFilters({ ...companyFilters, page: 0 });
          setIsOpen(true);
        }}
        filterCount={filterCount}
      />
    </Flex>
  );
};

AllFiltersModal.propTypes = {
  companyFilters: PropTypes.object.isRequired,
  applyFilters: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  filterCount: PropTypes.number.isRequired,
};

export default AllFiltersModal;
