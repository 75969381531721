import { useDispatch, useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import { useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useGetCustomFiltersQuery, useUpdateFilterVisibilityMutation } from '../../../../../services/organizations';
import { updateNetwork } from '../../../../../actions/networksActions';

export const allJobBoardFilters = {
  locationFilter: {
    label: 'Location',
    helpText: 'Display a filter for job locations.',
    order: 1,
  },
  jobFunctionFilter: {
    label: 'Job functions',
    helpText: 'Display a filter for job functions.',
    order: 2,
  },
  industryTagsFilter: {
    label: 'Industry',
    helpText: 'Display a filter and details about the industries in which company operates.',
    order: 3,
  },
  companySizeFilter: {
    label: 'Company size',
    helpText: 'Display a filter and details about the employee count, presented ranges.',
    order: 4,
  },
  stageFilter: {
    label: 'Company Stage',
    helpText: 'Display a filter and details about the funding stage of each company.',
    order: 5,
  },
};

export const useFilterVisibility = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { data: filters, isFetching: isFiltersLoading } = useGetCustomFiltersQuery(activeNetwork.id);
  const [updateVisibility] = useUpdateFilterVisibilityMutation({ networkId: activeNetwork.id });
  const dispatch = useDispatch();
  const customFilters = useMemo(() => {
    if (!filters) return [];

    return filters.map((filter) => ({
      id: filter.id,
      name: filter.attributes.name,
      label: filter.attributes.name,
      helpText: filter.attributes.values.map((value) => value.name)?.join(', '),
      value: filter.attributes.visibility === 'visible',
    }));
  }, [filters]);

  const jobBoardFilters = useMemo(() => {
    if (!activeNetwork) return [];

    return Object.keys(activeNetwork.jobBoardFilters)
      .map((key) => ({
        ...allJobBoardFilters[key],
        value: activeNetwork.jobBoardFilters[key],
        name: key,
      }))
      .sort((a, b) => a.order - b.order);
  }, [activeNetwork]);

  const initialValues = useMemo(() => {
    const values = {};
    if (jobBoardFilters.length) {
      jobBoardFilters.forEach((filter) => {
        values[filter.name] = filter.value;
      });
    }
    if (customFilters.length) {
      customFilters.forEach((filter) => {
        values[customFilters.name] = filter.value;
      });
    }
    return values;
  }, [jobBoardFilters, customFilters]);

  const updateFilterVisibility = async ({ name, value, id }) => {
    const data = {
      jobBoardFilters: {},
      customFilters: [],
    };
    jobBoardFilters.forEach((filter) => {
      data.jobBoardFilters[filter.name] = name === filter.name ? value : filter.value;
    });
    data.customFilters = customFilters.map((filter) => {
      const filterValue = filter.id === id ? value : filter.value;
      return {
        id: parseInt(filter.id, 10),
        visibility: filterValue ? 'visible' : 'hidden',
      };
    });

    await updateVisibility({ data, networkId: activeNetwork.id });
    dispatch(updateNetwork({ ...activeNetwork, jobBoardFilters: data.jobBoardFilters }));
    toast('Settings updated', { icon: 'success', id: 'job_board_filters_success' });
  };

  return {
    jobBoardFilters,
    customFilters,
    loading: isFiltersLoading,
    initialValues,
    updateFilterVisibility,
    networkId: activeNetwork.id,
  };
};
