import React, { useState } from 'react';
import { Button, Modal, Loader } from '@getro/rombo';
import { ExternalLink, Info } from 'lucide-react';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import { List } from 'react-content-loader';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import MainLayout from '../../components/layout/Main';
import useOneTimeRender from '../../hooks/useOneTimeRender';
import usePageHook from '../../hooks/usePageHook';
import { activeNetworkSelector, userSelector } from '../../redux/selectors';
import { useGetHiresQuery } from '../../services/hires';
import { Actions } from './actions';
import { Hero } from './hero';
import { HowItWorks } from './howItWorks';
import { Table } from './table';

export const Hires = () => {
  usePageHook('Hires');
  const [queryParams, setQueryParams] = useState({ page: 1, perPage: 20 });
  const { data, isLoading, isFetching } = useGetHiresQuery(queryParams);
  const activeNetwork = useSelector(activeNetworkSelector);
  const user = useSelector(userSelector);
  const { visible: showHowItWorksModal, setVisible: setShowHowItWorksModal } = useOneTimeRender({
    user,
    keySuffix: 'contacts-howitworks-modal',
  });
  const onLoadMore = () => {
    const currentPage = Math.ceil(data?.items?.length / 20);

    const total = data?.meta?.hiresTotal || 0;

    if (currentPage + 1 <= Math.ceil(total / 20)) {
      setQueryParams({
        page: currentPage + 1,
        perPage: 20,
      });
    }
  };

  return (
    <MainLayout>
      <MainLayoutHeader title="Hires" />
      <Box pt="24px" px="16px" mx="auto" width="100%" maxWidth="1600px">
        <Flex mt="8px" pb="24px" alignItems={['flex-start', 'center']} flexDirection={['column', 'row']}>
          <Text fontSize="14px" color="text.subtle">
            {`We monitor job changes across your `}
            <Link as={NavLink} to={`/networks/${activeNetwork.slug}/contacts`} sx={{ textDecoration: 'none' }}>
              {data?.meta ? `${data.meta.contactsTotal} ${pluralize('contact', data.meta.contactsTotal)}` : '-'}
            </Link>
            {` and report new hires when they start working at one of your `}
            <Link as={NavLink} to={`/networks/${activeNetwork.slug}/companies`} sx={{ textDecoration: 'none' }}>
              {data?.meta ? `${data.meta.companiesTotal} ${pluralize('company', data.meta.companiesTotal)}` : '-'}
            </Link>
            .
          </Text>
          <Flex mt={['16px', 0]} flex="0 0 auto">
            <Flex
              ml={[0, '16px']}
              alignItems="center"
              color="text.subtle"
              sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
              onClick={() => setShowHowItWorksModal(true)}
            >
              <Box as={Info} width="16px" height="16px" aria-hidden="true" flex="0 0 auto" />
              <Text ml="8px" fontSize="14px">
                How it works
              </Text>
            </Flex>
            <Flex
              as={Link}
              ml="16px"
              alignItems="center"
              fontSize="14px"
              color="text.subtle"
              href="mailto:product@getro.com?subject=Report on hires feedback"
              sx={{ whiteSpace: 'nowrap', textDecoration: 'none' }}
            >
              <Box as={ExternalLink} mr="8px" width="16px" height="16px" aria-hidden="true" flex="0 0 auto" />
              Share feedback
            </Flex>
          </Flex>
        </Flex>
        <Hero network={activeNetwork} meta={data?.meta} isLoading={isLoading} />
        <Box mt="48px" pb="40px">
          {isLoading && (
            <Box maxWidth="520px" pt="40px">
              <List />
            </Box>
          )}
          {!isLoading && data?.items?.length ? (
            <>
              <Actions />
              <Box mt="16px" pb="40px">
                <Table data={data.items} onLoadMore={onLoadMore} />
              </Box>
            </>
          ) : null}
          {!isLoading && isFetching && (
            <Flex height="184px" py="40px" flexDirection="column" alignItems="center" maxWidth="480px" mx="auto">
              <Loader />
            </Flex>
          )}
          {!isLoading && !data?.items?.length && (
            <Box px={[0, '40px']} py="24px">
              <HowItWorks />
            </Box>
          )}
        </Box>
        {showHowItWorksModal && (
          <Modal
            variant="xLarge"
            isOpen
            actions={
              <Flex justifyContent={['flex-end']}>
                <Button onClick={() => setShowHowItWorksModal(false)}>Got it</Button>
              </Flex>
            }
            onCancel={() => setShowHowItWorksModal(false)}
          >
            <Box px="16px" py="24px">
              <HowItWorks />
            </Box>
          </Modal>
        )}
      </Box>
    </MainLayout>
  );
};
