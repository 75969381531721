import React from 'react';
import { Button, Modal } from '@getro/rombo';
import { Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

const PauseLinkedinSync = ({ isSubmitting, onCancel, onPauseLinkedinSync }) => (
  <Modal
    title="Pause LinkedIn sync"
    isOpen
    onCancel={onCancel}
    actions={
      <Flex justifyContent={['flex-end']}>
        <Button onClick={onCancel} variant="tertiary">
          Cancel
        </Button>
        <Button loading={isSubmitting} onClick={onPauseLinkedinSync}>
          Pause
        </Button>
      </Flex>
    }
    data-testid="pause-linkedin-sync"
  >
    <Text width={1} mb={2}>
      The sync will be paused, meaning new LinkedIn connections won&apos;t be added. No existing contacts will be
      deleted.
    </Text>
  </Modal>
);

PauseLinkedinSync.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onPauseLinkedinSync: PropTypes.func.isRequired,
};

PauseLinkedinSync.defaultProps = {
  isSubmitting: false,
};

export default PauseLinkedinSync;
