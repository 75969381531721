import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useAddNetworkAdminMutation,
  useGetNetworkAdminsQuery,
  useRemoveNetworkAdminMutation,
  useResendNetworkAdminInviteMutation,
  useUpdateLinkedinSyncMutation,
} from 'services/networkAdmins';
import { useGetUserQuery } from 'services/user';
import { trackEvent } from 'helpers/analytics';
import { logOut, removeUser } from 'actions/userActions';
import { activeNetworkSelector } from '../../redux/selectors';

export const useNetworkAdmins = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const network = useSelector(activeNetworkSelector);
  const {
    data: admins = [],
    isLoading: isLoadingNetworkAdmins,
    isError: hasLoadAdminsListError,
  } = useGetNetworkAdminsQuery({ networkId: network.id });
  const [resend, { isLoading: isLoadingResendInvite, isError: hasResendInviteError }] =
    useResendNetworkAdminInviteMutation();
  const [remove, { isLoading: isLoadingRemoveAdmin, isError: hasRemoveAdminError }] = useRemoveNetworkAdminMutation();
  const { data } = useGetUserQuery();
  const [add, { error: hasAddAdminError, isLoading: isLoadingAddAdmin }] = useAddNetworkAdminMutation();
  const [updateLinkedinSync, { error: hasUpdateLinkedinSyncError, isLoading: isLoadingLinkedinSync }] =
    useUpdateLinkedinSyncMutation();

  const resendInvite = useCallback(
    async (admin) => {
      const { error, data: responseData } = await resend({ networkId: network.id, adminId: admin.id });
      if (error) return null;

      trackEvent('admins:resend_invite', {
        collectionId: network.id,
        isNetworkAdmin: !!data.managingCollections.length,
        isOrganizationAdmin: !!data.managingOrganizations.length,
        invitedUserNetworkAdmin: true,
        invitedUserCompanyAdmin: false,
      });

      return responseData;
    },
    [resend, network, data],
  );

  const removeAdmin = useCallback(
    async (admin) => {
      const { error, data: responseData } = await remove({ adminId: admin.id });
      if (error) return null;

      trackEvent('admins:remove', {
        collectionId: network.id,
        isNetworkAdmin: !!data.managingCollections.length,
        isOrganizationAdmin: !!data.managingOrganizations.length,
        removedUserNetworkAdmin: true,
        removedUserCompanyAdmin: false,
      });

      if (responseData.id === admin.id) {
        dispatch(removeUser());
        logOut();
        dispatch(push('/login'));
      }

      return responseData;
    },
    [data, dispatch, network, remove, push],
  );

  const addAdmin = useCallback(
    async (admin) => {
      const { error, data: responseData } = await add({ admin, networkId: network.id });
      if (error) return null;

      trackEvent('admins:invite_admin', {
        collectionId: network.id,
        isNetworkAdmin: !!data.managingCollections.length,
        isOrganizationAdmin: !!data.managingOrganizations.length,
        invitedUserNetworkAdmin: true,
        invitedUserCompanyAdmin: false,
      });

      return responseData;
    },
    [network, data, add],
  );

  const updateLinkedinSyncState = useCallback(
    async (admin, state) => {
      const { error, data: responseData } = await updateLinkedinSync({ adminId: admin.id, state });
      if (error) return null;

      return responseData;
    },
    [updateLinkedinSync],
  );

  return {
    hasLoadAdminsListError,
    hasResendInviteError,
    hasRemoveAdminError,
    hasAddAdminError,
    hasUpdateLinkedinSyncError,
    admins,
    isLoadingNetworkAdmins,
    isLoadingResendInvite,
    isLoadingRemoveAdmin,
    isLoadingAddAdmin,
    isLoadingLinkedinSync,
    resendInvite,
    removeAdmin,
    addAdmin,
    updateLinkedinSync: updateLinkedinSyncState,
  };
};
