import React, { useEffect, useMemo } from 'react';
import { Loader, MessageBlock } from '@getro/rombo';
import { useHistory, useLocation, useParams } from 'react-router';
import { Box } from 'rebass/styled-components';
import qs from 'query-string';

import { trackEvent } from '../../helpers/analytics';
import { FocusLayout } from '../../components/layout/focus';
import { useUnsubscribeFromSharedListMutation } from '../../services/sharedLists';
import { useConnectedLocalStorage } from '../../hooks/useConnectedLocalStorage';

export const ContactsSharedListsUnsubscribe = () => {
  const { slug } = useParams();
  const [storedValue, storeValue] = useConnectedLocalStorage(`shared-lists-${slug}`, null);
  const [unsubscribe, { isLoading, isError, error }] = useUnsubscribeFromSharedListMutation();
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = useMemo(() => qs.parse(search), [search]);

  const errorVisible = useMemo(() => {
    if (!isError) return false;

    return !(
      error.status === 400 &&
      error.data &&
      error.data.error &&
      Array.isArray(error.data.error) &&
      error.data.error[0].indexOf('none of the tokens belong to an active subscriber') !== -1
    );
  }, [isError, error]);

  useEffect(() => {
    const triggerUnsubscribe = async () => {
      const { list_token: singleToken } = queryParams;

      if (slug && singleToken) {
        await unsubscribe({ data: { tokens: [singleToken] } });
        trackEvent('contacts:shared_list:unsubscribed_successfully', {
          list_slug: slug,
          origin: 'email_single_list',
        });

        storeValue(null);
      }
    };

    if (!isError && !isLoading) {
      triggerUnsubscribe();
    }
  }, [isError, isLoading, queryParams, slug, storeValue, storedValue, unsubscribe]);

  return (
    <FocusLayout title="" backUrl={slug ? `/shared-lists/${slug}` : '/'}>
      <Box pb="5" maxWidth="600px" mx="auto">
        {isLoading && <Loader />}
        {!isLoading && !errorVisible && (
          <MessageBlock
            type="success"
            buttonText="Ok"
            title="Succesfully unsubscribed"
            content="You will not get notified for new contacts anymore."
            onClick={() => {
              history.push(`/shared-lists/${slug}`);
            }}
          />
        )}
        {!isLoading && errorVisible && (
          <MessageBlock
            type="error"
            title="There was an unexpected error"
            buttonText="Ok"
            onClick={() => {
              history.push(`/shared-lists/${slug}`);
            }}
          >
            Please try again or contact us at <a href="mailto:help@getro.com">help@getro.com</a>.
          </MessageBlock>
        )}
      </Box>
    </FocusLayout>
  );
};
