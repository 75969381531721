import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass/styled-components';

import { useLazyGetSharedListsQuery } from 'services/contacts';
import MultiSelect from '../../atoms/MultiSelect';

export const SharedList = ({ value, onChange }) => {
  const [getList, { isFetching, data }] = useLazyGetSharedListsQuery();
  return (
    <Flex width="100%" flexDirection="column" sx={{ gap: '8px' }}>
      <MultiSelect
        title="Lists"
        options={data?.sharedLists?.map((item) => ({ label: item.name, value: item.id })) || []}
        selected={value}
        loading={isFetching}
        onSearch={(keyword) => getList({ keyword }, {})}
        onSelect={onChange}
        onInitialOpenDropDown={() => getList()}
        debounceTime={300}
      />
    </Flex>
  );
};

SharedList.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

SharedList.defaultProps = {
  value: [],
};
