import React from 'react';
import strings from 'strings';
import sloganImgSvg from './slogan-img.svg';

const Slogan = () => (
  <div className="Slogan">
    <img className="Slogan__image" src={sloganImgSvg} alt="" />
    <p className="Slogan__textHeader">{strings.sloganHeader}</p>
    <p className="Slogan__text" style={{}}>
      {strings.slogan}
    </p>
  </div>
);

export default Slogan;
