import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { post } from 'api';
import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';

import { ListItem } from '@getro/rombo';
import Modal from 'components/atoms/Modal';
import MemberSchema from '../../../schemas/member';

const ConfirmStep = ({ open, onCancel, onSubmit, activeNetworkId, member, members, customMessage }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmitRequestConfirmStep = async () => {
    setLoading(true);

    try {
      await post('introduction_requests/make_intro', {
        collectionId: activeNetworkId,
        memberId: member.id,
        members_ids: members.map((m) => m.value),
        content: customMessage,
      });

      trackEvent('introduction_requests:make_intro', {
        collection_id: activeNetworkId,
        member_id: member.id,
        members_ids: members.map((m) => m.value),
        content: customMessage,
      });

      setLoading(false);
      onSubmit();
    } catch (error) {
      trackException(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Confirm company recipients"
      saveText="Send Emails"
      cancelText="Go back"
      onSubmit={handleSubmitRequestConfirmStep}
      open={open}
      onCancelClick={onCancel}
      simple={false}
      loading={loading}
      submitDisabled={loading}
      data-testid="make-intro-confirm-step"
    >
      <div className="MakeIntroModal__confirm MakeIntroModal__confirm__content">
        <p>
          {`You are about to send `}
          <b>{`${members.length} `}</b>
          {`email${members.length > 1 ? 's' : ''} for these recipients`}. Click &quot;Send Emails&quot; to send all, or
          &quot;Go Back&quot; to continue to make edits.
        </p>
      </div>
      {Array.isArray(members) &&
        members.map((s) => (
          <div key={s.value} className="MakeIntroModal__confirm">
            <ListItem content={s.label} avatarUrl={s.avatarUrl} showAvatar />
          </div>
        ))}
    </Modal>
  );
};

ConfirmStep.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  member: MemberSchema.isRequired,
  activeNetworkId: PropTypes.number.isRequired,
  customMessage: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
      role: PropTypes.string.isRequired,
      companiesName: PropTypes.string.isRequired,
    }),
  ),
};

ConfirmStep.defaultProps = {
  open: false,
  customMessage: '',
  members: [],
};

export default ConfirmStep;
