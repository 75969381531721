import React from 'react';
import MainLayout from '../components/layout/Main';

const withMainLayout = (Component) => {
  const Wrapped = ({ ...params }) => (
    <MainLayout>
      <Component {...params} />
    </MainLayout>
  );
  Wrapped.displayName = `withMainLayout(${Component.displayName || Component.name || 'Component'})`;
  return Wrapped;
};

export default withMainLayout;
