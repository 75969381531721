import React from 'react';
import { Panel, PanelRow } from '@getro/rombo';

import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';

const LoginInfo = () => (
  <Panel title="Login Information" mb={4}>
    <PanelRow title="Email" mb={3}>
      <EmailForm />
    </PanelRow>
    <PanelRow title="Password" mb={3}>
      <PasswordForm />
    </PanelRow>
  </Panel>
);

export default LoginInfo;
