export const onCheckboxChange = ({ values, target, key, setFieldValue }) => {
  let newValues = [];
  Object.assign(newValues, values[key]);
  if (target.checked) {
    newValues.push(target.value);
  } else {
    newValues = newValues.filter((v) => v !== target.value);
  }
  setFieldValue(key, newValues);
};

export const sectionCount = ({ formRef, fields }) => {
  let count = null;
  if (formRef.current) {
    const { values } = formRef.current;
    fields.forEach((field) => {
      if (
        Object.prototype.hasOwnProperty.call(values, field) &&
        (values[field] === true || (values[field] !== null && values[field] !== undefined && !!values[field].length))
      ) {
        count += 1;
      }
    });
  }
  return count;
};
