import { Loader } from '@getro/rombo';
import { Download } from 'lucide-react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import { trackEvent } from '../../../helpers/analytics';
import { activeNetworkSelector } from '../../../redux/selectors';
import { useExportContactsToCsvMutation, useGetSharedListsQuery } from '../../../services/contacts';
import { contactDataSelection } from '../../../services/contacts/selectedRows';
import ActionsIcon from './actionsIcon';

export const ActionExport = ({ queryParams, contactIds, selectedRows, meta }) => {
  const [exportContactsToCsv, { isLoading }] = useExportContactsToCsvMutation();
  const { data: { sharedLists = [] } = {} } = useGetSharedListsQuery();
  const activeNetwork = useSelector(activeNetworkSelector);
  const totalSelectedRowsCount = selectedRows.length;
  const activeList = useMemo(
    () => sharedLists.find((item) => item.id === parseInt(queryParams.listId, 10)),
    [queryParams, sharedLists],
  );

  const onClickExportContacts = async () => {
    if (!isLoading) {
      const { selectedDataRows, excludedDataRows } = contactDataSelection(selectedRows, contactIds, meta.queryTotal);

      trackEvent('contacts:export_csv', {
        collection_id: activeNetwork.id,
        list_slug: activeList?.slug,
        total_selected_rows: totalSelectedRowsCount,
      });

      try {
        await exportContactsToCsv({
          filters: queryParams.filters,
          contactIds: selectedDataRows,
          excludedContactIds: excludedDataRows,
          listId: queryParams.listId,
        });

        toast('We will email your data in a few minutes.', { icon: 'success', id: 'export-csv-success' });
      } catch {
        toast('There was an unexpected error, try again or send us a chat.', {
          icon: 'danger',
          id: 'export-csv-error',
        });
      }
    }
  };

  return isLoading ? (
    <Flex sx={{ alignItems: 'center', justifyContent: 'center' }} width="24px" height="24px">
      <Loader size="small" />
    </Flex>
  ) : (
    <ActionsIcon onClick={onClickExportContacts} icon={Download} text="Export" />
  );
};

ActionExport.propTypes = {
  queryParams: PropTypes.object,
  contactIds: PropTypes.array,
  filters: PropTypes.object,
  meta: PropTypes.object,
  selectedRows: PropTypes.array,
};

ActionExport.defaultProps = {
  queryParams: {},
  contactIds: [],
  filters: {},
  meta: {},
  selectedRows: [],
};
