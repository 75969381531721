import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Badge } from '@getro/rombo';

const CompaniesNav = ({ isSuggested, navigate, suggestedCount }) => (
  <Flex
    sx={{
      fontSize: '16px',
      borderColor: 'border.subtle',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      gap: '24px',
      p: {
        pb: '12px',
      },
      mb: '32px',
      '& > div': {
        cursor: 'pointer',
      },
      '.active': {
        borderBottom: '4px solid',
        borderBottomColor: 'purple.400',
        color: 'text.dark',
        fontWeight: '600',
      },
    }}
  >
    <Box onClick={() => navigate()}>
      <Text className={!isSuggested ? 'active' : ''} as="p">
        Active
      </Text>
    </Box>
    <Flex onClick={() => navigate('suggested')} sx={{ columnGap: '8px' }}>
      <Text className={isSuggested ? 'active' : ''} as="p">
        Suggested
      </Text>
      {suggestedCount > 0 ? (
        <Badge
          sx={{
            color: 'purple.400',
            backgroundColor: 'purple.100',
            fontWeight: '700',
            boxShadow: 'none',
            fontSize: '13px',
            lineHeight: '16px',
            height: '24px',
            width: '24px',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: '12px',
          }}
        >
          {suggestedCount}
        </Badge>
      ) : null}
    </Flex>
  </Flex>
);

CompaniesNav.propTypes = {
  isSuggested: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  suggestedCount: PropTypes.number.isRequired,
};

export default CompaniesNav;
