import { ProfilePicture } from '@getro/rombo';
import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const AvatarGroup = ({ matchList, size }) => {
  const currentLength = matchList.length > 4 ? 3 : 4;
  const extraMatches = Math.max(0, matchList.length - currentLength);
  const visibleMatches = [...matchList].slice(0, currentLength);

  return (
    <Flex>
      {!!visibleMatches.length &&
        visibleMatches.map((match, index) => (
          <Box
            key={`match-avatar-${match.id || match.fullName}`}
            width={`${size}px`}
            height={`${size}px`}
            p="2px"
            ml={index === 0 ? '0' : '-10px'}
            flex="0 0 auto"
            sx={{ bg: 'neutral.0', borderRadius: 'circle', zIndex: 1 }}
          >
            <ProfilePicture name={match.fullName} imageUrl={match.profilePicUrl} />
          </Box>
        ))}
      {extraMatches > 0 && (
        <Flex
          alignItems="center"
          justifyContent="center"
          bg="neutral.100"
          width={`${size}px`}
          height={`${size}px`}
          ml="-14px"
          flex="0 0 auto"
          sx={{ borderRadius: 'circle', zIndex: 1, borderWidth: '2px', borderColor: 'neutral.0', borderStyle: 'solid' }}
        >
          <Text fontSize="0" fontWeight="medium">
            {`+${extraMatches}`}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

AvatarGroup.propTypes = {
  matchList: PropTypes.array,
  size: PropTypes.number,
};

AvatarGroup.defaultProps = {
  matchList: [],
  size: 28,
};
