import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';

import { Loader, MessageBlock, Box, FormTextarea, FormSelect } from '@getro/rombo';

import BlankLayout from 'components/layout/Blank';

import MemberCard from 'components/molecules/MemberCard';

import { get, post } from 'api';
import { networkRoutes } from 'router/routes';
import strings from 'strings';
import { getUserDisplayName } from 'helpers/users';

import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';
import helperGet from 'helpers/get';
import { activeNetworkSelector, userSelector } from 'redux/selectors';
import { useHistory } from 'react-router-dom';

const formSchema = Yup.object().shape({
  customMessage: Yup.string()
    .label('Custom message')
    // eslint-disable-next-line
    .min(60, 'Please write at least ${min} characters.')
    .required(),
  relationshipLevel: Yup.object().required('Must select one relationship'),
});

export const pageTitle = 'Vouches';

const Vouches = () => {
  const history = useHistory();
  const activeNetwork = useSelector(activeNetworkSelector);
  const user = useSelector(userSelector);
  const [queryParams] = useState(queryString.parse(history.location.search));
  const [alsoVerify, setAlsoVerify] = useState(false);
  const [receiver, setReceiver] = useState(null);
  const [alreadyVouched, setAlreadyVouched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [formError, setFormError] = useState(null);
  const { features } = activeNetwork;
  const skillsDeactivated = features && features.includes('skills_deactivated');

  // First load
  useEffect(() => {
    const { action, userId } = queryParams;

    if (!['vouch', 'verify'].includes(action) || !userId) {
      setError(true);
      return;
    }

    if (queryParams.userId) {
      (async () => {
        try {
          const response = await get(`users/${queryParams.userId}`);
          setReceiver(response.data);
          setAlsoVerify(queryParams.action === 'verify' && !response.data.verified);
        } catch (e) {
          trackException(e);
          setError(true);
        }
      })();
    }
  }, [queryParams]);

  useEffect(() => {
    if (!user || !receiver) {
      return;
    }

    if (receiver.vouchesReceived && receiver.vouchesReceived.some((r) => r.voucher && r.voucher.id === user.id)) {
      if (queryParams.action === 'verify') {
        verifyUser();
        return;
      }

      setAlreadyVouched(true);
    }

    setLoading(false);

    // eslint-disable-next-line
  }, [user, receiver])

  const handleSubmit = async (values) => {
    setSubmitting(true);
    setFormError(null);

    // Create Vouch
    try {
      const params = {
        relationshipLevel: values.relationshipLevel.value,
        relationshipText: values.customMessage,
        sendEmail: !alsoVerify,
      };

      const vouch = await post(`/users/${queryParams.userId}/vouch`, params);

      const eventParams = {
        medium: 'email',
        from: helperGet(vouch, 'data.voucher.email', undefined),
        to: helperGet(vouch, 'data.vouched.email', undefined),
      };

      trackEvent('vouch', {
        ...eventParams,
        vouch_id: vouch.data.id,
        relationship_level: values.relationshipLevel.value,
      });

      if (!alsoVerify) {
        setSuccess(true);
        setSubmitting(false);
        return;
      }

      verifyUser(eventParams);
    } catch (e) {
      trackException(e);
      setSuccess(false);
      setSubmitting(false);
      if (e.response && e.response.status === 403) {
        setFormError(strings.genericAuthError);
      } else if (e.response && e.response.data && e.response.data.errors) {
        setFormError(e.response.data.errors[0]);
      } else {
        setFormError(strings.genericError);
      }
    }
  };

  const verifyUser = async (eventParams) => {
    try {
      const params = {
        verifyAllNetworks: false,
      };

      await post(`/users/${queryParams.userId}/verify`, params);

      trackEvent('verify', eventParams);

      setSuccess(true);
    } catch (e) {
      trackException(error);
      if (receiver.vouchesReceived.some((r) => r.voucher && r.voucher.id === user.id)) {
        // Show main error if receiver was already vouched, because we are not showing the form
        setError(true);
        return;
      }

      if (e.response && e.response.status === 403) {
        setFormError(strings.genericAuthError);
      } else if (e.response && e.response.data && e.response.data.errors) {
        setFormError(e.response.data.errors[0]);
      } else {
        setFormError(strings.genericError);
      }
    }
    setLoading(false);
    setSubmitting(false);
  };

  const handleCancel = () => {
    history.push(networkRoutes.networkHome.path);
  };

  if (error) {
    return (
      <BlankLayout>
        <Box>
          <MessageBlock title="Something went wrong" type="error" content={strings.genericError} />
        </Box>
      </BlankLayout>
    );
  }

  if (loading) {
    return (
      <BlankLayout>
        <Loader />
      </BlankLayout>
    );
  }

  if (alreadyVouched) {
    return (
      <BlankLayout>
        <Box>
          <MessageBlock
            title="You have already vouched this member"
            buttonHref={networkRoutes.networkHome.path}
            buttonText="Ok"
          >
            <p>You can only vouch once for each member.</p>
          </MessageBlock>
        </Box>
      </BlankLayout>
    );
  }

  if (success) {
    return (
      <BlankLayout>
        <Box>
          <MessageBlock
            type="success"
            title={`Thanks for ${alsoVerify ? 'verifying' : 'vouching for'} ${getUserDisplayName(receiver)}!`}
            buttonText="Ok"
            buttonHref={networkRoutes.networkHome.path}
          >
            <p>
              {alsoVerify
                ? 'They can now request introductions directly to companies in the network. Your vouch will be added to their profile for companies to see.'
                : 'Your vouch has been added to their profile for companies in the network to reference.'}
            </p>
            <p>You can see the information you added at anytime.</p>
          </MessageBlock>
        </Box>
      </BlankLayout>
    );
  }

  return (
    <BlankLayout>
      <Formik
        initialValues={{
          customMessage: '',
          relationshipLevel: queryParams.relationshipLevel || undefined,
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box
              editing
              errorText={formError}
              onCancelClick={handleCancel}
              saveAsSubmit
              saveText={alsoVerify ? 'Vouch and verify' : 'Complete vouch'}
              loading={submitting}
              title={`${alsoVerify ? 'Add vouch to verify' : 'Complete your vouch for'} ${getUserDisplayName(
                receiver,
              )}`}
            >
              {alsoVerify && (
                <p>
                  When you vouch for members of the network you signal to companies they are stand out. Verify people
                  you trust to unlock their ability to request introductions directly to companies.
                </p>
              )}
              {!alsoVerify && (
                <p>
                  When you vouch for members of the network you signal to companies they are stand out. Tell us how you
                  know this person and why they are awesome.
                </p>
              )}

              <MemberCard className="VouchForm__UserSummary" member={receiver} skillsDeactivated={skillsDeactivated} />

              <Field
                component={FormSelect}
                name="relationshipLevel"
                label="How do you know each other?"
                placeholder="Select one option"
                options={Object.entries(strings.vouch.relationshipLevelOptions).map(([k, v]) => ({
                  value: k,
                  label: v.replace('[Member Name]', receiver.firstName || 'this person'),
                }))}
                containerProps={{ mt: 4 }}
              />

              <Field
                component={FormTextarea}
                label="Tell us more"
                name="customMessage"
                placeholder="Write 1-2 sentences that we can share with companies who want to connect with this person."
                containerProps={{ mt: 4 }}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </BlankLayout>
  );
};

export default Vouches;
