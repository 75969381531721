import { useContactsFilterCount } from 'hooks/useContactsFilterCount';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Loader } from '@getro/rombo';

export const SharedListsFooter = ({ filters, contactsTotal, isLoading, allContactsCount }) => {
  const filterCount = useContactsFilterCount(filters);
  const showFooter = contactsTotal > 0 && contactsTotal === allContactsCount;

  if (isLoading) return <Loader data-testid="shared-lists-footer-loader" mt="24px" />;

  if (!showFooter) return null;

  return (
    <Flex
      data-testid="shared-list-footer"
      flexDirection="column"
      lineHeigh="1.4"
      justifyContent="center"
      pt="80px"
      width="100%"
      fontSize="14px"
      alignItems="center"
    >
      <Box fontWeight="500" color="text.main">
        {!filterCount ? `All contacts in this list shown` : `All results for this search in this list shown`}
      </Box>
    </Flex>
  );
};

SharedListsFooter.propTypes = {
  filters: PropTypes.object.isRequired,
  contactsTotal: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  allContactsCount: PropTypes.number.isRequired,
};

SharedListsFooter.defaultProps = {
  isLoading: false,
};
