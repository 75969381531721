/**
 * Rombo Candidate: Search
 *
 * This component could be a good replacement for the Search component that is currently
 * in Rombo. Things to keep in mind:
 *
 * - The dropdown is filled with rows containing checkboxes that trigger the on change event.
 *   This is different to the current Search component so maybe a better idea could be
 *   releasing the component with a different name.
 * - All the options are rendered in the dropdown and it could be a problem in
 *   terms of performance. Using Virtuoso could fix the issue but it's something that needs
 *   to be tested.
 */

import { Checkbox, Input, Loader } from '@getro/rombo';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Plus } from 'lucide-react';
import isEqual from 'lodash.isequal';
import { Virtuoso } from 'react-virtuoso';

export const Search = ({ placeholder, isLoading, options, value, onChange, onCreate, onClose, onSearch, ...rest }) => {
  const [filteredOptions, setFilteredOptions] = useState(sortOptionsByCheckedValue(options, value));
  const [currentValue, setCurrentValue] = useState(value);
  const [term, setTerm] = useState('');
  const inputRef = useRef(null);
  const showOnCreate =
    onCreate && !!term.length && !options.find((item) => item.label.toLowerCase() === term.toLowerCase());

  useEffect(() => {
    const handleKeyboard = (event) => {
      if (event.keyCode === 27 && onClose) {
        onClose();
      } else if ((event.keyCode === 13 || event.key === 'Enter') && showOnCreate) {
        onCreate(term);
        setTerm('');
      } else if (event.keyCode !== 40 && event.keyCode !== 38) {
        if (inputRef.current) {
          inputRef.current.focus({ preventScroll: true });
        }
      }
    };
    window.addEventListener('keydown', handleKeyboard);

    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }

    return () => {
      window.removeEventListener('keydown', handleKeyboard);
    };
  }, [term]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const resortedFilteredOptions = sortOptionsByCheckedValue(filteredOptions, value);
    const sortedOptions = sortOptionsByCheckedValue(
      options.filter((item) => item.label.toLowerCase().includes(term.toLowerCase())),
      value,
    );

    if (!isEqual(sortedOptions, resortedFilteredOptions)) {
      setFilteredOptions(sortedOptions);
    }
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  function sortOptionsByCheckedValue(opts = [], val = []) {
    const checked = opts
      .filter((opt) => val.find((item) => item.value === opt.value))
      .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
    const notChecked = opts
      .filter((opt) => !val.find((item) => item.value === opt.value))
      .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

    return [...checked, ...notChecked];
  }

  const onChangeInput = ({ target }) => {
    setTerm(target.value);
    if (onSearch) onSearch(target.value);
    if (!onSearch) {
      const filteredOpts = options.filter((item) => item.label.toLowerCase().includes(target.value.toLowerCase()));
      setFilteredOptions(sortOptionsByCheckedValue(filteredOpts, value));
    }
  };

  const onChangeChecked = (opt, checked) => {
    let result;

    if (checked) {
      result = [...currentValue, opt];
    } else {
      result = currentValue.filter((item) => item.value !== opt.value);
    }

    setCurrentValue(result);
    onChange(result, opt, checked, term);
  };

  return (
    <Box height="100%" {...rest}>
      <Input ref={inputRef} size="small" placeholder={placeholder} value={term} onChange={onChangeInput} />
      {isLoading ? (
        <Box pt="24px" pb="8px">
          <Loader size="medium" />
        </Box>
      ) : (
        <Flex
          height={showOnCreate ? 'calc(100% - 32px)' : '100%'}
          flexDirection="column"
          justifyContent="space-between"
          pt="12px"
        >
          <Flex flexDirection="column">
            {filteredOptions.length ? (
              <Box
                as={Virtuoso}
                data={filteredOptions}
                style={{ height: filteredOptions.slice(0, 8).length * 28 - 12 }}
                totalCount={filteredOptions.length}
                itemContent={(_, item) => (
                  <Box mb="12px">
                    <Checkbox
                      label={item.label}
                      checked={!!currentValue.find((selected) => item.value === selected.value)}
                      onChange={(event) => onChangeChecked(item, event.currentTarget.checked)}
                    />
                  </Box>
                )}
              />
            ) : (
              <Text pb="8px" fontSize="14px" color="text.subtle">
                No results available
              </Text>
            )}
          </Flex>
          {showOnCreate && filteredOptions?.length <= 0 && (
            <Flex
              height="30px"
              pt="8px"
              alignItems="center"
              color="text.subtle"
              sx={{ borderTop: 1, borderTopColor: 'border.subtle', cursor: 'pointer' }}
              onClick={() => {
                onCreate(term);
                setTerm('');
              }}
            >
              <Box as={Plus} mr="8px" width="16px" height="16px" aria-hidden="true" />
              <Text fontSize="14px" color="text.main">{`${term} (Create new)`}</Text>
            </Flex>
          )}
        </Flex>
      )}
    </Box>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.array,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
  onSearch: PropTypes.func,
};

Search.defaultProps = {
  placeholder: 'Search',
  value: [],
  isLoading: false,
  options: [],
  onCreate: null,
  onClose: null,
  onSearch: null,
};
