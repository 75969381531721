import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ChevronLeft } from 'lucide-react';

export const Sidebar = ({ mobileTriggerText, mobileHeaderText, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const height = isOpen ? '100%' : 'auto';

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('sidebar_open', 'overflow__hidden');
    } else {
      document.body.classList.remove('sidebar_open', 'overflow__hidden');
    }
    return function cleanUp() {
      document.body.classList.remove('sidebar_open', 'overflow__hidden');
    };
  }, [isOpen]);

  return (
    <Flex
      bg="white"
      flexDirection="column"
      mr={[0, 0, '40px']}
      mb={[3, 3, 0]}
      pb={[isOpen ? 3 : 0, isOpen ? 3 : 0, 0]}
      px={[isOpen ? 3 : 0, isOpen ? 3 : 0, 0]}
      width={[1, 1, '240px']}
      data-testid="sidebar"
      height={[height, height, '100%']}
      sx={{
        zIndex: [999, isOpen ? 999 : 1, 1],
        position: [isOpen ? 'absolute' : 'relative', isOpen ? 'absolute' : 'relative', 'relative'],
        top: 0,
        left: 0,
      }}
    >
      {!isOpen && (
        <Button
          variant="secondary"
          textAlign="left"
          data-testid="mobile-trigger"
          mb={isOpen ? 3 : 0}
          display={['block', 'block', 'none']}
          onClick={() => setIsOpen(!isOpen)}
          sx={{
            ':hover': {
              background: 'white',
            },
          }}
        >
          {mobileTriggerText}
        </Button>
      )}
      {isOpen && (
        <Flex
          flexShrink={0}
          pt={3}
          mb={3}
          width={1}
          display={['block', 'block', 'none']}
          alignItems="center"
          data-testid="mobile-header"
          flexDirection="row"
          sx={{ borderBottom: 1, borderColor: 'gray.3' }}
        >
          <Button variant="secondary" sx={{ border: 0 }} onClick={() => setIsOpen(false)}>
            <Box mr={1} height="24px" strokeWidth="1.5" aria-hidden="true" as={ChevronLeft} />
          </Button>
          <Text fontSize={3} color="text.dark" fontWeight="medium" flexGrow={1} textAlign="center">
            {mobileHeaderText}
          </Text>
        </Flex>
      )}
      <Box
        data-testid="children-warapper"
        flexDirection="column"
        flexGrow={1}
        sx={{
          overflow: 'hidden',
          display: [isOpen ? 'flex' : 'none', isOpen ? 'flex' : 'none', 'flex'],
        }}
      >
        {children}
      </Box>
      {isOpen && (
        <Flex display={['block', 'block', 'none']} width={1} flexShrink={0}>
          <Button width={1}>Submit</Button>
        </Flex>
      )}
    </Flex>
  );
};

Sidebar.propTypes = {
  mobileTriggerText: PropTypes.string.isRequired,
  mobileHeaderText: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
