import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { AsyncButton, Button } from '@getro/rombo';
import { SimplifiedModal } from '../../atoms/simplifiedModal';
import GroupSchema from '../../../schemas/group';

const BoardDeleteConfirmationModal = ({ board, onCancel, isSubmitting, onSubmit }) => (
  <SimplifiedModal
    data-testid="board-settings-modal"
    onCancel={onCancel}
    variant="small"
    isOpen
    title={`Delete ${board.name}`}
    actions={
      <Flex flexDirection={['column']}>
        <Box width={[1]}>
          <Flex justifyContent={['flex-end']} flexDirection={['row']}>
            <Button mx={[2]} onClick={onCancel} variant="secondary">
              No, keep list
            </Button>
            <AsyncButton loading={isSubmitting} variant="danger" ml={[2]} onClick={onSubmit}>
              Yes, delete list
            </AsyncButton>
          </Flex>
        </Box>
      </Flex>
    }
  >
    <Text as="p" variant="default">
      If you delete this list, it will not be available to companies. This will not delete any users from your network.
    </Text>
    <Text as="p" variant="default">
      Are you sure you want to delete this list?
    </Text>
  </SimplifiedModal>
);

BoardDeleteConfirmationModal.propTypes = {
  board: GroupSchema.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.bool.isRequired,
};

export default BoardDeleteConfirmationModal;
