import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

export const BulkActionsTriggers = ({ actions }) => (
  <Box
    sx={{
      '& > div': {
        color: 'text.main',
        textDecoration: 'none',
        fontSize: '14px',
        lineHeight: '24px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
        '&:hover': {
          bg: 'neutral.10',
          opacity: 0.8,
        },
      },
    }}
  >
    {actions.map((action) => (
      <Fragment key={action.text}>
        {action.text === 'divider' ? (
          <Box
            sx={{
              height: '1px',
              backgroundColor: 'border.subtle',
              margin: '8px 0',
            }}
          />
        ) : (
          <Flex sx={{ py: '4px', alignItems: 'center', gap: '8px' }} key={action.text} onClick={action.onClick}>
            <Box minWidth="16px" height="16px">
              <Box as={action.icon} size="16px" color="#00002E" />
            </Box>
            <Box
              sx={{
                color: 'text.main',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                ':first-letter': {
                  textTransform: 'capitalize',
                },
              }}
            >
              {action.text}
            </Box>
          </Flex>
        )}
      </Fragment>
    ))}
  </Box>
);

BulkActionsTriggers.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      text: PropTypes.string,
      icon: PropTypes.element.isRequired,
    }),
  ),
};

BulkActionsTriggers.defaultProps = {
  actions: [],
};

export default BulkActionsTriggers;
