import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Mail, X, Check } from 'lucide-react';
import { useParams } from 'react-router';
import { Button } from '@getro/rombo';
import { toast } from 'react-hot-toast';
import HeaderButton from '../headerButton';
import { trackEvent } from '../../../helpers/analytics';
import ContactsSharedListSchema from '../../../schemas/contactsSharedList';
import {
  useSubscribeEmailToSharedListMutation,
  useUnsubscribeFromSharedListMutation,
} from '../../../services/sharedLists';
import { useConnectedLocalStorage } from '../../../hooks/useConnectedLocalStorage';
import HeaderButtonContent from '../headerButtonContent';
import SubscribeModal from './subscribeModal';

const SubscribeViaEmailButton = ({ listInfo, sticky, ...otherProps }) => {
  const [showModal, setShowModal] = useState(false);
  const [subscribe, { error: updateError, isLoading: isUpdating }] = useSubscribeEmailToSharedListMutation();
  const [unsubscribe, { isLoading: isUnsubscribing }] = useUnsubscribeFromSharedListMutation();
  const { slug } = useParams();
  const [storedValue, storeValue] = useConnectedLocalStorage(`shared-lists-${slug}`, null);

  const modalTitle = useMemo(
    () => (storedValue ? `Unsubscribe from ${listInfo.name}` : 'Stay in the loop'),
    [listInfo.name, storedValue],
  );

  const onUnsubscribe = useCallback(async () => {
    const { token } = storedValue;
    await unsubscribe({ data: { tokens: [token] } });

    trackEvent('contacts:shared_list:unsubscribed_successfully', {
      list_slug: slug,
      origin: 'shared_list_page',
      collection_id: listInfo.collection.id,
    });

    storeValue(null);
    setShowModal(false);
    toast('Successfully unsubscribed', {
      icon: 'success',
      id: 'subscribe-to-list-toast',
    });
  }, [listInfo, slug, storeValue, storedValue, unsubscribe]);

  const modalActions = useMemo(() => {
    if (!storedValue) return null;

    return (
      <Flex flexDirection="row-reverse" flexAlign="flexEnd">
        <Button variant="primary" loading={isUnsubscribing} onClick={onUnsubscribe}>
          Unsubscribe
        </Button>
        <Button variant="secondary" onClick={() => setShowModal(false)} mr="8px">
          Cancel
        </Button>
      </Flex>
    );
  }, [isUnsubscribing, onUnsubscribe, storedValue]);

  const onClickSubscribe = async () => {
    trackEvent('contacts:shared_list:subscribe_cta_click', {
      list_slug: slug,
      origin: sticky ? 'fixed_header' : 'page_header',
      collection_id: listInfo.collection.id,
    });
    setShowModal(true);
  };

  const onClickUnsubscribe = async () => {
    trackEvent('contacts:shared_list:unsubscribe_click', {
      list_slug: slug,
      origin: 'shared_list_page',
      collection_id: listInfo.collection.id,
    });
    setShowModal(true);
  };

  const onSubmit = async (data) => {
    const response = await subscribe({ slug, data });
    trackEvent('contacts:shared_list:subscribed_successfully', {
      list_slug: slug,
      collection_id: listInfo.collection.id,
    });

    storeValue(response.data);
    setShowModal(false);
    toast('You are subscribed', {
      icon: 'success',
      id: 'subscribe-to-list-toast',
    });
  };

  return (
    <>
      {storedValue && (
        <HeaderButton
          tooltip="Get daily emails when new people are added to this list."
          {...otherProps}
          onClick={onClickUnsubscribe}
          sx={{
            '& > :last-child': {
              display: 'none',
            },
            '&:hover': {
              '& > :first-child': {
                display: 'none',
              },
              '& > :last-child': {
                display: 'flex',
              },
            },
          }}
        >
          <HeaderButtonContent>
            <Box as={Check} width="16px" height="16px" mr="4px" />
            <Box as="span">Subscribed</Box>
          </HeaderButtonContent>
          <HeaderButtonContent>
            <Box as={X} width="16px" height="16px" mr="4px" />
            <Box as="span">Unsubscribe</Box>
          </HeaderButtonContent>
        </HeaderButton>
      )}
      {!storedValue && (
        <HeaderButton
          tooltip="Get daily emails when new people are added to this list."
          {...otherProps}
          onClick={onClickSubscribe}
        >
          <HeaderButtonContent>
            <Box as={Mail} width="16px" height="16px" mr="4px" />
            <Box as="span">Subscribe via email</Box>
          </HeaderButtonContent>
        </HeaderButton>
      )}
      <SubscribeModal
        title={modalTitle}
        showModal={showModal}
        setShowModal={setShowModal}
        modalActions={modalActions}
        storedValue={storedValue}
        updateError={updateError}
        isUpdating={isUpdating}
        onSubmit={onSubmit}
      />
    </>
  );
};

SubscribeViaEmailButton.propTypes = {
  listInfo: ContactsSharedListSchema,
  sticky: PropTypes.bool,
};

SubscribeViaEmailButton.defaultProps = {
  sticky: false,
  listInfo: null,
};

export default SubscribeViaEmailButton;
