import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/molecules/Filter';
import OptionsSchema from '../../../schemas/options';

const FeaturedFilter = ({ value, onChange, featuredOptions, embeddedInModal, onDropDownStateChange }) => {
  const selectedOption = useMemo(() => featuredOptions.find((i) => i.value === value), [featuredOptions, value]);
  return (
    <Filter
      label="Featured"
      name="featured"
      placeholder="Featured"
      value={selectedOption}
      onChange={onChange}
      options={[...featuredOptions]}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

FeaturedFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  featuredOptions: OptionsSchema.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
};

FeaturedFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
};

export default FeaturedFilter;
