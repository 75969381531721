import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Message, Modal } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import { useDeleteContactMutation, useDeleteContactsMutation } from 'services/contacts';
import { Trash } from 'lucide-react';
import { createPortal } from 'react-dom';
import pluralize from 'pluralize';
import { contactDataSelection } from 'services/contacts/selectedRows';
import toast from 'react-hot-toast';
import strings from 'strings';
import { DeleteContactModal } from 'components/organisms/contactActionPanel/deleteContactModal';
import { trackEvent } from 'helpers/analytics';
import { activeNetworkSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import ActionsIcon from './actionsIcon';

export const ActionDeleteContacts = ({ setSelectedRows, contactIds, queryParams, selectedRows, meta }) => {
  const [deleteContacts, { isLoading }] = useDeleteContactsMutation();
  const [showModal, setShowModal] = useState(false);
  const [deleteContact, { isLoading: deleting }] = useDeleteContactMutation();
  const activeNetwork = useSelector(activeNetworkSelector);

  const handleDelete = async () => {
    const { selectedDataRows, excludedDataRows } = contactDataSelection(selectedRows, contactIds, meta.queryTotal);

    if (selectedRows.length === 1) {
      const { error } = await deleteContact({
        contactId: selectedDataRows[0],
        queryParams,
      });

      if (error) {
        toast(strings.genericError, { icon: 'error', id: 'delete-contact-success' });
        return;
      }

      toast('Contact deleted', { icon: 'success', id: 'delete-contact-success' });

      trackEvent('contacts:delete_contacts', {
        collectionId: activeNetwork.id,
        numberOfDeletedContacts: selectedDataRows.length,
        method: 'single_delete',
        origin: queryParams.listId ? 'list' : 'contacts',
        contactId: selectedDataRows[0],
        contactSource: null,
      });

      setSelectedRows([]);
      setShowModal(false);
      return;
    }

    const { error } = await deleteContacts({
      queryParams,
      contactIds: selectedDataRows,
      excludedContactIds: excludedDataRows,
      listId: queryParams.listId,
    });

    trackEvent('contacts:delete_contacts', {
      collectionId: activeNetwork.id,
      numberOfDeletedContacts: selectedDataRows.length,
      method: 'bulk_delete',
      origin: queryParams.listId ? 'list' : 'contacts',
    });

    if (error) {
      toast(strings.genericError, { icon: 'error', id: 'delete-contact-success' });
      return;
    }

    toast(`${selectedRows.length} ${pluralize('contact', selectedDataRows.length)} deleted`, {
      icon: 'success',
      id: 'delete-contacts-success',
    });

    setSelectedRows([]);
    setShowModal(false);
  };

  return (
    <>
      {showModal &&
        createPortal(
          <>
            {selectedRows.length === 1 ? (
              <DeleteContactModal
                onCloseModal={() => setShowModal(false)}
                onDeleteContact={handleDelete}
                deleting={deleting}
              />
            ) : (
              <Modal
                title={` Delete ${selectedRows.length} ${pluralize('contact', selectedRows.length)}?`}
                isOpen={showModal}
                onCancel={() => setShowModal(false)}
                actions={
                  <Flex sx={{ gap: '8px', justifyContent: 'flex-end' }}>
                    <Button data-testid="cancel-btn" variant="secondary" onClick={() => setShowModal(false)}>
                      Cancel
                    </Button>
                    <Button data-testid="delete-btn" variant="danger" onClick={handleDelete} loading={isLoading}>
                      Delete {selectedRows.length} {pluralize('contact', selectedRows.length)}
                    </Button>
                  </Flex>
                }
              >
                <Box as="ul" pl="24px" sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <Box as="li">Remove these profiles from your network.</Box>
                  <Box as="li">
                    Permanently delete all associated network data (lists, tags, notes, matches, and hiring reports).
                  </Box>
                  <Box as="li">These profiles will not be re-added in future CSV or LinkedIn imports.</Box>
                  <Box as="li">
                    You can re-add them later via single contact import or Chrome extension, associated network data
                    will start fresh.
                  </Box>
                </Box>

                {selectedRows.length >= 50 && (
                  <Box mt="24px">
                    <Message content="It can take up to one or two minutes to completely delete all these contacts, you might spot some during the process." />
                  </Box>
                )}
              </Modal>
            )}
          </>,
          document.body,
        )}
      <ActionsIcon data-testid="delete-modal-icon" onClick={() => setShowModal(true)} icon={Trash} text="Delete" />
    </>
  );
};

ActionDeleteContacts.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  contactIds: PropTypes.array.isRequired,
  queryParams: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  selectedRows: PropTypes.array.isRequired,
};
