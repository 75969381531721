import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'jobs';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const isJobsInitializedSelector = createSelector(rootSelector, ({ initialized }) => initialized);
export const isJobsErroredSelector = createSelector(rootSelector, ({ error }) => error);
export const jobsSelector = createSelector(rootSelector, ({ data }) => data);
export const hasMoreJobsSelector = createSelector(rootSelector, ({ hasMore }) => hasMore);
export const selectedJobsSelector = createSelector(jobsSelector, (jobs) => jobs.filter((job) => job.selected));
export const selectedJobsIdsSelector = createSelector(selectedJobsSelector, (jobs) => jobs.map((job) => job.id));
export const totalJobsSelector = createSelector(rootSelector, ({ meta }) => meta.total || 0);
export const selectAllJobsSelector = createSelector(rootSelector, ({ selectAll }) => selectAll);
