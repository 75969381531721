import { takeLatest, select, call, put } from 'redux-saga/effects';
import { loadJobs, loadedJobs, errorJobs } from './actions';
import { activeNetworkSelector } from '../../redux/selectors';
import { get } from '../../api';
import { trackException } from '../../helpers/errorTracker';
import APIError from '../../api/APIError';
import removeEmptyProps from '../../helpers/removeEmptyProps';

export function* loadJobsEvent({ payload }) {
  const { page = 1, hitsPerPage = 20, filters } = payload;
  const { id: collectionId } = yield select(activeNetworkSelector);
  try {
    const response = yield call(get, `/collections/${collectionId}/jobs`, {
      params: {
        page,
        perPage: hitsPerPage,
        ...removeEmptyProps(filters),
      },
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });
    yield put(loadedJobs({ ...response.data, page, hasMoreJobs: response.data.data.length === hitsPerPage }));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }
    yield put(errorJobs(e));
  }
}

export default function* saga() {
  yield takeLatest(loadJobs().type, loadJobsEvent);
}
