import { Button } from '@getro/rombo';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { Shuffle, Wand2 } from 'lucide-react';
import { trackEvent } from 'helpers/analytics';
import { activeNetworkSelector } from '../../../redux/selectors';

export const ButtonGetMoreMatches = ({ children, variant, origin }) => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const history = useHistory();

  const renderButtonProps = () => {
    switch (variant) {
      case 'secondary': {
        return {
          icon: Shuffle,
          variant: 'tertiary',
        };
      }
      default:
        return {
          icon: Wand2,
          variant: 'secondary',
        };
    }
  };

  return (
    <Button
      onClick={() => {
        trackEvent('matches:create_project_click', {
          origin,
          collection_id: activeNetwork.id,
        });
        history.push(`/networks/${activeNetwork.slug}/lists/create`);
      }}
      size="small"
      flex="0 0 auto"
      data-testid="button-matches-project"
      {...renderButtonProps()}
    >
      {children}
    </Button>
  );
};

ButtonGetMoreMatches.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.string,
  origin: PropTypes.string.isRequired,
};

ButtonGetMoreMatches.defaultProps = {
  variant: 'primary',
};
