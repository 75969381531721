export const getCompaniesColumns = () => [
  {
    Header: 'Name',
    accessor: 'name',
    width: '20%',
    disableSortBy: true,
  },
  {
    Header: 'Website',
    accessor: 'website',
    width: '20%',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'options',
    width: '20%',
    disableSortBy: true,
  },
];
