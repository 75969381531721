import React from 'react';
import { Message, Button, Loader } from '@getro/rombo';
import { FocusLayout } from 'components/layout/focus';
import { Formik, Form } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import { openChat } from 'helpers/supportChat';
import toast from 'react-hot-toast';
import { GeneralJobBoardSettings } from './generalJobBoardSettings';
import { HeroContentSettings } from './heroContentSettings';
import { HeroBackgroundSettings } from './heroBackgroundSettings';
import { useJobBoardAppearance } from './hooks/useJobBoardAppearance';
import { PublishChanges } from './component/publishingChanges';

export const JobBoardAppearance = () => {
  const {
    initialValues,
    slug,
    isLoading,
    customHeroAndAD,
    standardDesign,
    tabOrderOptionsToUse,
    handleSubmit,
    isUpdating,
    url,
    heroOptions,
    advancedDesign,
    isGCOrTN,
  } = useJobBoardAppearance();

  return (
    <FocusLayout
      backUrl={`/networks/${slug}/settings/job-board`}
      title="Job board appearance"
      bodyProps={{
        mt: '56px',
      }}
    >
      {isLoading && <Loader isLoading={isLoading} />}
      {!isLoading && (
        <>
          <Formik
            onSubmit={(values) => {
              handleSubmit(values).then(() => {
                toast(<PublishChanges domain={url} />, {
                  icon: 'success',
                  id: 'job-board-settings-updated',
                });
              });
            }}
            initialValues={initialValues}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Box sx={{ maxWidth: '600px', margin: '0px auto', mb: '120px' }}>
                  <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '32px' }}>
                    <GeneralJobBoardSettings
                      heroOptions={heroOptions}
                      values={values}
                      tabOrderOptionsToUse={tabOrderOptionsToUse}
                    />
                    {customHeroAndAD && (
                      <Box sx={{ maxWidth: '600px', margin: '0px auto', width: '100%' }}>
                        <Message variant="info">
                          Your job board features a highly customized advanced design. For updates, please{' '}
                          <Box as="span" sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={openChat}>
                            contact us
                          </Box>
                          .
                        </Message>
                      </Box>
                    )}
                    {standardDesign && (
                      <>
                        <Box sx={{ height: '1px', width: '100%', backgroundColor: 'border.subtle' }} />
                        <HeroContentSettings
                          isAdvancedDesign={advancedDesign}
                          isTalentNetwork={isGCOrTN}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                        <Box sx={{ height: '1px', width: '100%', backgroundColor: 'border.subtle' }} />
                        <HeroBackgroundSettings setFieldValue={setFieldValue} values={values} />
                      </>
                    )}
                    <Box sx={{ height: '1px', width: '100%', backgroundColor: 'border.subtle' }} />
                  </Box>
                  <Flex sx={{ gap: '16px', mt: '16px', flexDirection: 'column' }}>
                    <Box>
                      <Button type="submit" size="small" loading={isUpdating}>
                        Save and publish
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </FocusLayout>
  );
};

export default JobBoardAppearance;
