import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'suggestedCompanies';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const suggestedCompaniesMetaSelector = createSelector(rootSelector, ({ suggested }) => suggested.meta);
export const suggestedCompaniesSelector = createSelector(rootSelector, ({ suggested }) => suggested.companies);
export const suggestedCompaniesError = createSelector(rootSelector, ({ suggested }) => suggested.error);
export const suggestedCompaniesInitialized = createSelector(rootSelector, ({ suggested }) => suggested.initialized);
export const skippingSuggestedCompaniesIds = createSelector(rootSelector, ({ skippingIds }) => skippingIds);
export const addingSuggestedCompaniesIds = createSelector(rootSelector, ({ addingIds }) => addingIds);
