import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'hooks/useInjectReducer';
import { useInjectSaga } from 'hooks/useInjectSaga';
import { useHistory } from 'react-router';
import useTopics from 'hooks/useTopics';
import { companiesApi, useLazyGetCompaniesQuery } from 'services/companies';
import { NAVIGATED_FROM_COMPANY_BREADCRUMB } from 'helpers/constants';
import { key, exportCompanyErrorSelector, exportCompanySuccessSelector } from './selector';

import saga from './saga';
import reducer from './reducer';

export const DEFAULT_FILTERS = {
  keyword: '',
  places: null,
  topicIds: [],
  ocpStatus: null,
  missingManager: null,
  qa: null,
  page: 1,
};

const cleanFilters = (filtersToClean) =>
  Object.entries(filtersToClean).reduce((acc, [filterKey, value]) => {
    if (filterKey === 'topicIds' && typeof value === 'string') {
      acc[filterKey] = value.split(',').map(Number);
      return acc;
    }

    if (filterKey === 'page' && value === 1) return acc;

    if ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value) || typeof value === 'boolean') {
      acc[filterKey] = value;
    }

    return acc;
  }, {});

const initialFilter = (search) => {
  const params = new URLSearchParams(search);
  params.delete('status');

  return {
    ...DEFAULT_FILTERS,
    ...cleanFilters(Object.fromEntries(params)),
    page: 1,
  };
};

export const useActiveCompanies = () => {
  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });
  const dispatch = useDispatch();
  const [loadAllCompanies, { isLoading, isFetching, currentData, error }] = useLazyGetCompaniesQuery();
  const companies = useMemo(() => currentData?.ids?.map((item) => currentData.entities[item]) ?? [], [currentData]);

  const meta = currentData?.meta ?? { total: 0 };
  const hasCompanyInitialized = !isLoading;
  const hasCompanyInitializedError = Boolean(error);
  const exportCompanyError = useSelector(exportCompanyErrorSelector);
  const exportCompanySuccess = useSelector(exportCompanySuccessSelector);
  const isLoadingCompanies = isFetching;
  const { topics } = useTopics();
  const initialLoad = useRef(false);
  const {
    replace,
    location: { pathname, search },
  } = useHistory();

  const [filters, setFilters] = useState(initialFilter(search));

  const applyFilter = useCallback(
    (newFilters, options) => {
      const updatedFilters = { ...filters, ...newFilters };
      setFilters(updatedFilters);
      const params = new URLSearchParams(cleanFilters(updatedFilters));

      if (!options?.isPagination && !(search === params.toString())) {
        dispatch(companiesApi.util.resetApiState());
      }
      replace({ pathname, search: params.toString() });
    },
    [dispatch, filters, pathname, replace, search],
  );

  const navigate = useCallback(() => {
    applyFilter({}, { isPagination: true });
  }, [applyFilter]);

  const loadMore = useCallback(() => {
    applyFilter({ page: Math.ceil(companies.length / 20) + 1 }, { isPagination: true });
  }, [applyFilter, companies.length]);

  const loadAllCompaniesRequest = useCallback(
    (request, preferCacheValue) => {
      const { filters: requestFilters } = request;
      const { topicIds, places, ...otherRequestFilters } = requestFilters;

      const placeIds = places ? JSON.parse(places).map((p) => p.value) : null;

      loadAllCompanies(
        {
          filters: {
            ...otherRequestFilters,
            placeIds,
            topicIds: topicIds ? topicIds.split(',') : null,
          },
        },
        preferCacheValue,
      );
    },
    [loadAllCompanies],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.get('status') === 'suggested') return;
    const navigatedFromCompanyBreadcrumb = localStorage.getItem(NAVIGATED_FROM_COMPANY_BREADCRUMB);
    if (navigatedFromCompanyBreadcrumb) {
      localStorage.removeItem(NAVIGATED_FROM_COMPANY_BREADCRUMB);
      initialLoad.current = true;
      loadAllCompaniesRequest({ filters: Object.fromEntries(searchParams) }, true);
      return;
    }

    const hasReloadWithPagination = !initialLoad.current && !searchParams.get('status') && searchParams.has('page');
    if (hasReloadWithPagination) {
      initialLoad.current = true;
      searchParams.delete('page');
      replace({ pathname, search: searchParams.toString() });
      return;
    }

    const searchFilters = Object.fromEntries(searchParams);
    loadAllCompaniesRequest({ filters: searchFilters }, false);
    initialLoad.current = true;
  }, [dispatch, loadAllCompanies, loadAllCompaniesRequest, pathname, replace, search]);

  const filterCount = useMemo(() => Array.from(new URLSearchParams(search)).length, [search]);
  return {
    loadMore,
    companies,
    loading: isLoadingCompanies,
    meta,
    hasCompanyInitialized,
    hasCompanyInitializedError,
    exportCompanyError,
    exportCompanySuccess,
    filters,
    cleanFilters: cleanFilters(filters),
    applyFilter,
    filterCount,
    topics,
    navigate,
  };
};
