import { createActions, createAction } from 'redux-actions';

export const {
  loadRequestExportJobAlertSubscribers,
  loadedRequestExportJobAlertSubscribers,
  errorRequestExportJobAlertSubscribers,
} = createActions(
  'LOAD_REQUEST_EXPORT_JOB_ALERT_SUBSCRIBERS',
  'LOADED_REQUEST_EXPORT_JOB_ALERT_SUBSCRIBERS',
  'ERROR_REQUEST_EXPORT_JOB_ALERT_SUBSCRIBERS',
);

export const resetExportJobAlertSubscribers = createAction('RESET_EXPORT_JOB_ALERT_SUBSCRIBERS');
