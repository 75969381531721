import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { Input } from '@rebass/forms/styled-components';

const Range = ({ rangeValues, min, max, onRangeChange }) => {
  const range = useRef(null);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);

  const getPercent = useCallback((value) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(rangeValues.min);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [getPercent, rangeValues.min]);

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(rangeValues.max);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [getPercent, rangeValues.max]);

  const onChange = (event, reverse = false) => {
    const value = reverse
      ? Math.min(+event.target.value, rangeValues.max - 1)
      : Math.max(+event.target.value, rangeValues.min + 1);

    // eslint-disable-next-line no-param-reassign
    event.target.value = value;
    onRangeChange({ min: reverse ? value : rangeValues.min, max: !reverse ? value : rangeValues.max });
  };

  return (
    <Box sx={{ height: '20px' }}>
      <Box
        sx={{
          position: 'relative',
          input: {
            border: 0,
            position: 'absolute',
            outline: 'none',
            px: 0,
            pointerEvents: 'none',
            backgroundColor: 'transparent',
            zIndex: 3,
            '&::-webkit-slider-thumb': {
              appearance: 'none',
              background: '#8A75F0',
              border: 'none',
              borderRadius: '50%',
              cursor: 'pointer',
              height: '16px',
              width: '16px',
              pointerEvents: 'all',
              position: 'relative',
            },
            '::-moz-range-thumb': {
              appearance: 'none',
              background: '#8A75F0',
              border: 'none',
              borderRadius: '50%',
              cursor: 'pointer',
              height: '20px',
              width: '20px',
              pointerEvents: 'all',
              position: 'relative',
            },
          },
        }}
      >
        <Input
          data-testid="min-range"
          type="range"
          ref={minValRef}
          min={min}
          max={max}
          height="0"
          value={rangeValues.min}
          onChange={(event) => onChange(event, true)}
        />
        <Input
          data-testid="max-range"
          type="range"
          ref={maxValRef}
          min={min}
          max={max}
          height="0"
          value={rangeValues.max}
          onChange={onChange}
        />
      </Box>
      <Box pt="6px" sx={{ position: 'relative' }}>
        <Box height="4px" width="100%" sx={{ zIndex: 1, top: '6px', bg: 'neutral.100' }} />
        <Box height="4px" ref={range} sx={{ position: 'absolute', zIndex: 2, top: '6px', bg: '#8A75F0' }} />
      </Box>
    </Box>
  );
};

Range.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  rangeValues: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
};

Range.defaultProps = {};

export default Range;
