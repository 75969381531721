import React from 'react';
import { Modal, Button, Tooltip } from '@getro/rombo';
import { Box, Text, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Copy } from 'lucide-react';
import copy from 'copy-to-clipboard';

const ConnectToLinkedin = ({ link, onCancel, onButtonClicked }) => {
  const [copied, setCopied] = React.useState(false);

  return (
    <Modal
      title="Connect a team member’s LinkedIn account"
      isOpen
      onCancel={onCancel}
      data-testid="connect-linkedin-sync"
    >
      <Flex sx={{ gap: '24px', flexDirection: 'column', fontSize: '14px' }}>
        <Flex sx={{ gap: '16px', flexDirection: 'column' }}>
          <Text>Each user needs to connect their own LinkedIn account, you can help them following these steps:</Text>
          <Box as="ul" pl="32px">
            <Box as="li">Make sure they are invited as admins and have accept the invitation</Box>
            <Box as="li">Share the link below and ask them to complete the process (less than a minute)</Box>
          </Box>
        </Flex>
        <Tooltip
          position="top-end"
          content="Copied"
          options={{
            visible: copied,
          }}
          sx={{
            '.tooltip-arrow': {
              transform: 'translate(60px, 0px) !important',
            },
          }}
          trigger={
            <Flex
              sx={{
                alignItems: 'center',
                padding: '8px',
                minHeight: '48px',
                bg: 'neutral.20',
                width: '100%',
                justifyContent: 'space-between',
                gap: '8px',
              }}
            >
              <Box
                as="p"
                onClick={onButtonClicked}
                sx={{
                  fontSize: 14,
                  lineHeight: '24px',
                  textDecoration: 'underline',
                  color: 'text.main',
                  wordWrap: 'break-word',
                  cursor: 'pointer',
                }}
              >
                {`${window.location.host}${link}`}
              </Box>
              <Button
                bg="transparent"
                variant="tertiary"
                size="small"
                width="108px"
                minWidth="108px"
                iconGap="8px"
                icon={Copy}
                display="flex"
                justifyContent="center"
                p="8px"
                onClick={() => {
                  setCopied(true);
                  copy(`${window.location.origin}${link}`);
                }}
              >
                Copy link
              </Button>
            </Flex>
          }
        />
      </Flex>
    </Modal>
  );
};

ConnectToLinkedin.propTypes = {
  link: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onButtonClicked: PropTypes.func.isRequired,
};

export default ConnectToLinkedin;
