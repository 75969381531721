import React from 'react';
import { Radio } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { PrivateList } from './privateList';
import { JobList } from './jobList';

export const AiMatches = ({ onMatchSelected, matchType, setMatchType, matchId, setSmartOption }) => (
  <>
    <Box px="40px">
      <Box as="p" fontSize="14px" lineHeight="20px" mb="32px">
        Let our AI do the lifting: select a job and get ongoing suggestions with the best matches in your contacts.
      </Box>
      <Flex sx={{ gap: '16px', flexDirection: 'column', pb: '16px' }}>
        <Flex sx={{ gap: '16px' }}>
          <Radio
            name="matchType"
            value="private"
            label="Select a job from your job board"
            onChange={() => setMatchType('job')}
            checked={matchType === 'job'}
          />
          <Radio
            name="matchType"
            value="job"
            label="Enter details for a private role"
            onChange={() => setMatchType('private')}
            checked={matchType === 'private'}
          />
        </Flex>
      </Flex>
      {matchType === 'private' && (
        <PrivateList matchId={matchId} onMatchSelected={onMatchSelected} setSmartOption={setSmartOption} />
      )}
    </Box>
    {matchType === 'job' && <JobList onMatchSelected={onMatchSelected} />}
  </>
);

AiMatches.propTypes = {
  onMatchSelected: PropTypes.func.isRequired,
  matchType: PropTypes.string.isRequired,
  setMatchType: PropTypes.func.isRequired,
  matchId: PropTypes.string,
  setSmartOption: PropTypes.func.isRequired,
};

AiMatches.defaultProps = {
  matchId: null,
};
