import PropTypes from 'prop-types';
import NetworkSchema from './network';

const UserSchema = PropTypes.shape({
  avatarUrl: PropTypes.string,
  confirmed: PropTypes.bool,
  connectingCollections: PropTypes.arrayOf(NetworkSchema),
  email: PropTypes.string,
  firstName: PropTypes.string,
  forcePasswordUpdate: PropTypes.bool,
  id: PropTypes.number,
  lastName: PropTypes.string,
  managingCollections: PropTypes.arrayOf(NetworkSchema),
  managingOrganizations: PropTypes.arrayOf(
    PropTypes.shape({
      collectionIds: PropTypes.arrayOf(PropTypes.number),
      collections: PropTypes.arrayOf(NetworkSchema),
      id: PropTypes.number,
      logoUrl: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  onboardingCompleted: PropTypes.bool,
  privacyPolicyVersion: PropTypes.string,
  termsPolicyVersion: PropTypes.string,
});

export default UserSchema;
