import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ProfilePicture } from '@getro/rombo';

import { getUserDisplayName } from '../../../helpers/users';

export const BoardCreator = ({ creator }) => {
  if (!creator) {
    return null;
  }
  return (
    <Box fontSize={0} color="text.subtle" pt={1}>
      <Flex alignItems="center">
        <Text>Created by</Text>
        <Box ml={2} mr={1} display="inline-block" width="24px">
          <ProfilePicture imageUrl={creator.avatarUrl} name={getUserDisplayName(creator)} />
        </Box>
        <Text>{`${creator.firstName} ${creator.lastName}`}</Text>
      </Flex>
    </Box>
  );
};

BoardCreator.propTypes = {
  creator: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
  }),
};

BoardCreator.defaultProps = {
  creator: null,
};
