import React, { useState } from 'react';
import { Modal, Button, Tooltip } from '@getro/rombo';
import { Box, Text, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Copy } from 'lucide-react';
import copy from 'copy-to-clipboard';

const ReconnectLinkedinSync = ({ link, onCancel, networkName, onButtonClicked }) => {
  const [showWhy, setShowWhy] = useState(false);
  const [copied, setCopied] = useState(false);
  return (
    <Modal
      title="Reconnect team member’s LinkedIn account"
      isOpen
      onCancel={onCancel}
      data-testid="connect-linkedin-sync"
    >
      <Flex sx={{ gap: '24px', flexDirection: 'column', fontSize: '14px' }}>
        <Flex sx={{ gap: '16px', flexDirection: 'column' }}>
          <Text>
            Occasionally, users need to reconnect their LinkedIn account, often due to a period of inactivity on
            LinkedIn.
          </Text>
          <Text>
            Share this link with {networkName} and request them to complete the process, (less than a minute).
          </Text>
        </Flex>
        <Tooltip
          position="top-end"
          content="Copied"
          options={{
            visible: copied,
          }}
          sx={{
            '.tooltip-arrow': {
              transform: 'translate(60px, 0px) !important',
            },
          }}
          trigger={
            <Flex
              sx={{
                alignItems: 'center',
                padding: '8px',
                minHeight: '48px',
                bg: 'neutral.20',
                width: '100%',
                justifyContent: 'space-between',
                gap: '8px',
              }}
            >
              <Box
                as="p"
                onClick={onButtonClicked}
                sx={{
                  fontSize: 14,
                  lineHeight: '24px',
                  textDecoration: 'underline',
                  color: 'text.main',
                  wordWrap: 'break-word',
                  cursor: 'pointer',
                }}
              >
                {`${window.location.host}${link}`}
              </Box>
              <Button
                bg="transparent"
                variant="tertiary"
                size="small"
                width="108px"
                minWidth="108px"
                iconGap="8px"
                icon={Copy}
                display="flex"
                justifyContent="center"
                p="8px"
                onClick={() => {
                  setCopied(true);
                  copy(`${window.location.origin}${link}`);
                }}
              >
                Copy link
              </Button>
            </Flex>
          }
        />
      </Flex>

      <Flex sx={{ flexDirection: 'column', gap: '16px', pt: '40px', color: 'text.main', fontSize: '14px' }}>
        <Text
          sx={{
            color: 'text.main',
            fontSize: '14px',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => setShowWhy(!showWhy)}
        >
          Why is this needed?
        </Text>

        {showWhy && (
          <>
            <Text>
              To respect our users’ privacy and security, LinkedIn connections sync happens when these conditions are
              met:
            </Text>
            <Box as="ul" pl="32px">
              <Box as="li">The user has our chrome extension installed</Box>
              <Box as="li">The user is logged into linkedin.com (in the same browser)</Box>
            </Box>
            <Text>Make sure both happen to keep your connections updated.</Text>
          </>
        )}
      </Flex>
    </Modal>
  );
};

ReconnectLinkedinSync.propTypes = {
  link: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  networkName: PropTypes.string.isRequired,
  onButtonClicked: PropTypes.func.isRequired,
};

export default ReconnectLinkedinSync;
