import React from 'react';
import { Flex, Image, Text } from 'rebass/styled-components';

import SearchNoResultsIcon from '../../assets/icon-effortless-search-no-results.svg';

export const NoResults = () => (
  <Flex mt="40px" flexDirection="column" alignItems="center" maxWidth="520px" mx="auto" textAlign="center">
    <Image src={SearchNoResultsIcon} width="64px" maxWidth="64px" />
    <Text mt="16px" fontWeight="semibold">
      No results for this search
    </Text>
    <Text mt="8px" color="text.subtle">
      Review the filters or subscribe to get notified when new people are added.
    </Text>
  </Flex>
);
