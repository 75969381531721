import React from 'react';
import { Box } from 'rebass/styled-components';

import MainLayout from 'components/layout/Main';
import pageHoc from 'hocs/pageHoc';
import { useGetTalentNetworkSettingsQuery, useUpdateTalentNetworkMutation } from 'services/network';
import strings from 'strings';
import { SettingsLayout } from '../layout';
import TalentNetworkSettings from './TalentNetworkSettings';
import TalentNetwork from './TalentNetwork';

export const Contacts = () => {
  const { data, isLoading, isError } = useGetTalentNetworkSettingsQuery();
  const [update, { isLoading: isUpdating }] = useUpdateTalentNetworkMutation();

  return (
    <MainLayout>
      <SettingsLayout>
        <Box
          sx={{
            '.Box__title': {
              fontWeight: 600,
            },
          }}
        >
          <TalentNetwork
            toggleTalentNetwork={() => {
              update({ tnHidden: !data.tnHidden });
            }}
            tnHidden={!data?.tnHidden}
          />
          {!isLoading && !data?.tnHidden && (
            <TalentNetworkSettings
              isUpdating={isUpdating}
              update={update}
              talentNetwork={data}
              error={isError ? strings.genericError : ''}
            />
          )}
        </Box>
      </SettingsLayout>
    </MainLayout>
  );
};

export default pageHoc({ title: 'Settings' })(Contacts);
