import React from 'react';
import { useSelector } from 'react-redux';
import NoAccess from 'components/atoms/NoAccess';

import { activeNetworkSelector, userSelector } from 'redux/selectors';
import Content from './Content';
import withMainLayout from '../../hocs/withMainLayout';
import usePermissions from '../../hooks/usePermissions';
import usePageHook from '../../hooks/usePageHook';

const Members = () => {
  usePageHook('Members');
  const activeNetwork = useSelector(activeNetworkSelector);
  const currentUser = useSelector(userSelector);
  const { canManageMembers } = usePermissions();

  if (!canManageMembers) {
    return <NoAccess cy="members-unavailable" />;
  }

  return <Content activeNetwork={activeNetwork} currentUser={currentUser} />;
};

export default withMainLayout(Members);
