import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@getro/rombo';
import Modal from 'components/atoms/Modal';
import NetworkSchema from '../../../schemas/network';

const DeclineModal = ({ activeNetwork, introductionRequest, onDecline }) => {
  const { id: collectionId } = activeNetwork;

  const trigger = (
    <Button variant="secondary" data-testid="decline-introduction-request-modal">
      Decline
    </Button>
  );
  const onSubmit = () => onDecline(collectionId, introductionRequest);

  return (
    <Modal title="Confirmation" onSubmit={onSubmit} saveText="Decline" cancelText="Cancel" trigger={trigger}>
      Do you want to decline the this introduction request?
    </Modal>
  );
};

DeclineModal.propTypes = {
  introductionRequest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatarUrl: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
    introducible: PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
      name: PropTypes.string.isRequired,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ),
      topics: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
    status: PropTypes.string.isRequired,
    customMessage: PropTypes.string.isRequired,
  }).isRequired,
  onDecline: PropTypes.func.isRequired,
  activeNetwork: NetworkSchema.isRequired,
};

export default DeclineModal;
