import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Flex, Text, Box } from 'rebass/styled-components';
import * as Yup from 'yup';
import {
  Button,
  FormField,
  FormikTextArea,
  OnboardingLayoutContent,
  ProfilePicture,
  Search,
  UploadImage,
} from '@getro/rombo';

import { get, put } from 'api';
import { trackException } from 'helpers/errorTracker';

import OnboardingContext from 'components/layout/EmployerOnboarding/onboardingContext';
import { PickerOverlay } from 'filestack-react';
import { openChat } from '../../../helpers/supportChat';

const formSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string(),
  domain: Yup.string(),
  logoUrl: Yup.string().label('Logo URL').url(),
  founded: Yup.string(),
  approxEmployees: Yup.string().label('Number of employees'),
  locations: Yup.array(),
});

const initialValues = (org) => ({
  name: org.name || '',
  description: org.description || '',
  domain: org.domain || '',
  founded: org.founded || '',
  approxEmployees: org.approxEmployees || '',
  logoUrl: org.logoUrl || '',
  locations: org.locations.map((l) => ({ value: l.placeId, label: l.name })) || [],
});

const handleSubmit =
  (step, updateStep, org, net, updateOrganization) =>
  async (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    setStatus({ error: false });

    try {
      const { locations, ...params } = values;

      const response = await put(
        `/organizations/${org.id}`,
        {
          ...params,
          placeIds: values.locations.map(({ value }) => value),
        },
        { params: { collectionId: net.id } },
      );

      if (response && response.status === 200) {
        updateOrganization(response.data);
        updateStep(step + 1);
      }
    } catch (error) {
      trackException(error);
      setStatus({ error: true });
    }

    setSubmitting(false);
  };

const getSearchLocations = async (query) => {
  if (!query.length) {
    return [];
  }

  try {
    const response = await get(`/locations/search/google`, {
      params: { q: query },
    });

    return response.data.items.map(({ description, placeId }) => ({
      value: placeId,
      label: description,
    }));
  } catch (error) {
    trackException(error);
    return [];
  }
};

const handleChange =
  (setter) =>
  ({ target }) =>
    setter(target.name, target.value);

const GeneralInformation = ({ title }) => {
  const { organization, network, updateOrganization, step, updateStep } = useContext(OnboardingContext);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues(organization)}
      validationSchema={formSchema}
      onSubmit={handleSubmit(step, updateStep, organization, network, updateOrganization)}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <OnboardingLayoutContent title={title} nextStepSubmit loading={isSubmitting}>
            <Flex flexDirection="column" mb="24px">
              <Flex flexDirection={['column', 'row']}>
                <Box py="0" width={[1, 0.5]}>
                  {organization.name === organization.domain && (
                    <Field type="text" name="name" label="Name" placeholder="Add the name" component={FormField} />
                  )}

                  {organization.name !== organization.domain && (
                    <Flex flexDirection="column">
                      <Text fontWeight="medium" color="text.dark">
                        Name
                      </Text>
                      <Text mt={[3]}>{organization.name}</Text>
                      <Field type="hidden" name="name" />
                    </Flex>
                  )}
                </Box>

                <Box py="0" width={[1, 0.5]}>
                  <Flex flexDirection="column">
                    <Text fontWeight="medium" color="text.dark">
                      Domain
                    </Text>
                    <Text mt={[3]}>{values.domain}</Text>
                  </Flex>
                  <Field type="hidden" name="domain" />
                </Box>
              </Flex>
              {organization.name !== organization.domain && (
                <Text fontSize="1" color="text.subtle" my={[3]} sx={{ fontStyle: 'italic' }}>
                  If the name or domain have changed,{' '}
                  <Button
                    fontSize="inherit"
                    type="button"
                    variant="underlineLink"
                    display="inline"
                    p={0}
                    m={0}
                    onClick={openChat}
                  >
                    contact us
                  </Button>
                  .
                </Text>
              )}
            </Flex>
            <div className="EmployerOnboarding__row">
              <Flex py="0" px={[3]} w={[1]} mb="40px" alignItems="center">
                <ProfilePicture
                  sx={{
                    verticalAlign: 'middle',
                    width: '64px',
                    height: '64px',
                  }}
                  variant="square"
                  imageUrl={values.logoUrl}
                  name={values.name}
                />
                <UploadImage
                  Picker={PickerOverlay}
                  filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
                  className="CompanyProfile__upload-logo"
                  imageType="logo"
                  onSuccess={({ filesFailed, filesUploaded }) => {
                    if (filesFailed.length < 1) {
                      setFieldValue('logoUrl', filesUploaded[0].url);
                    }
                  }}
                  onClear={() => setFieldValue('logoUrl', null)}
                  currentValue={values.logoUrl}
                />
              </Flex>
            </div>

            <div className="EmployerOnboarding__row">
              <div className="EmployerOnboarding__full">
                <FormikTextArea name="description" label="Description" placeholder="Description" />
              </div>
            </div>

            <div className="EmployerOnboarding__row">
              <div className="EmployerOnboarding__cell">
                <Field type="text" name="founded" label="Founded date" component={FormField} />
              </div>

              <div className="EmployerOnboarding__cell">
                <Field type="number" min="0" name="approxEmployees" label="Number of employees" component={FormField} />
              </div>
            </div>

            <div className="EmployerOnboarding__row">
              <div className="EmployerOnboarding__cell">
                <div className="field">
                  <Search
                    multiple
                    name="locations"
                    label="Locations"
                    onChange={handleChange(setFieldValue)}
                    value={values.locations}
                    onSearch={getSearchLocations}
                    orientation="horizontal"
                    placeholder="Add location"
                  />
                </div>
              </div>
            </div>
          </OnboardingLayoutContent>
        </Form>
      )}
    </Formik>
  );
};

GeneralInformation.propTypes = {
  title: PropTypes.string.isRequired,
};

export default GeneralInformation;
