import React from 'react';
import { Button, Loader, Message } from '@getro/rombo';
import { Check } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import { Box, Flex, Image, Link, Text } from 'rebass/styled-components';

import ContactsLinkedInSyncImage from '../../assets/contacts-linkedin-sync.png';
import { FocusLayout } from '../../components/layout/focus';
import { openChat } from '../../helpers/supportChat';
import usePageHook from '../../hooks/usePageHook';
import { InstallChromeExtension } from './installChromeExtension';
import { EXTENSION_MESSAGE_TYPES, useLinkedInSync } from './useLinkedInSync';

export const ContactsLinkedInSync = () => {
  const {
    isLoading,
    isLoadingVoyager,
    isExtensionInstalled,
    activeNetwork,
    action,
    responseType,
    featureList: items,
    onConnect,
  } = useLinkedInSync();
  usePageHook(`LinkedIn ${action}`);

  return (
    <FocusLayout
      title="Connect your LinkedIn"
      backUrl={`/networks/${activeNetwork?.slug}/contacts`}
      headerSx={{ opacity: action === 'sync' ? 1 : 0 }}
    >
      <Box width="100%" maxWidth="408px" mx="auto" my="0" pb="40px">
        <Box textAlign="center">
          <Image src={ContactsLinkedInSyncImage} alt="LinkedIn Sync" />
          {!isLoading && activeNetwork?.linkedinSyncStatus !== 'active' && (
            <Text fontSize="18px" color="text.dark" fontWeight="semibold">
              Sync your current and future LinkedIn connections to Getro
            </Text>
          )}
        </Box>
        {isLoading ? (
          <Loader mt="40px" />
        ) : (
          <>
            {activeNetwork?.linkedinSyncStatus === 'active' ? (
              <>
                <Flex mt="40px" alignItems="center" sx={{ gap: '16px' }}>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    bg="purple.100"
                    color="purple.400"
                    width="40px"
                    height="40px"
                    flex="0 0 auto"
                    sx={{ borderRadius: 'circle' }}
                  >
                    <Box as={Check} width="24px" height="24px" aria-hidden="true" />
                  </Flex>
                  <Text color="text.dark" fontWeight="semibold" fontSize="18px">
                    {`Your LinkedIn sync is ${action === 'sync' ? 'active' : 'restored'}`}
                  </Text>
                </Flex>
                <Text mt="16px" fontSize="14px">
                  {action === 'sync'
                    ? `The first contacts will start appearing in batches. Initial sync can take up to 3 hours, we’ll email you when it’s ready.`
                    : `We're updating your newest LinkedIn connections and they will be added soon. New connections will be added automatically.`}
                </Text>
                <Text mt="16px" fontSize="14px" color="text.subtle">
                  You can see your and your team’s LinkedIn sync status in{' '}
                  <Link
                    as={NavLink}
                    to={`/networks/${activeNetwork?.slug}/settings/admins`}
                    sx={{ color: 'text.subtle' }}
                  >
                    settings
                  </Link>
                  .
                </Text>
                <Button
                  as={NavLink}
                  mt="40px"
                  width="100%"
                  to={`/networks/${activeNetwork?.slug}/contacts`}
                  sx={{ justifyContent: 'center' }}
                >
                  OK
                </Button>
              </>
            ) : (
              <>
                <Flex as="ul" pl="0" py="40px" flexDirection="column" sx={{ gap: '16px', listStyle: 'none' }}>
                  {items.map(({ icon, title, description }) => (
                    <Flex key={title} as="li" alignItems="baseline">
                      <Flex
                        flex="0 0 auto"
                        mr="16px"
                        alignItems="center"
                        color="purple.300"
                        sx={{ position: 'relative', top: '3px' }}
                      >
                        <Box as={icon} width="20px" height="20px" aria-hidden="true" />
                      </Flex>
                      <Box flexGrow="1">
                        <Text fontSize="14px" fontWeight="medium" color="text.dark">
                          {title}
                        </Text>
                        <Text fontSize="14px">{description}</Text>
                      </Box>
                    </Flex>
                  ))}
                </Flex>
                {!isExtensionInstalled && <InstallChromeExtension />}
                <Button
                  width="100%"
                  justifyContent="center"
                  loading={isLoadingVoyager}
                  disabled={!isExtensionInstalled}
                  onClick={onConnect}
                >
                  Connect LinkedIn account
                </Button>
                {responseType === EXTENSION_MESSAGE_TYPES.IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR && (
                  <Box data-testid="linkedin-cookies-warning" pt="16px" sx={{ '& > div': { alignItems: 'center' } }}>
                    <Message
                      type="warning"
                      content={
                        <Box fontSize="14px" lineHeight="1.4">
                          <Text as="p" fontWeight="500" mb="8px">
                            Make sure you’re logged in to LinkedIn
                          </Text>
                          <Text as="p">
                            Please go to{' '}
                            <Link
                              href="https://linkedin.com"
                              target="__blank"
                              rel="noopener noreferrer"
                              color="text.main"
                            >
                              www.linkedin.com
                            </Link>
                            {`, log in, and then try again. `}
                            <Text
                              display="inline-block"
                              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                              onClick={() => openChat(null, { name: 'Support' })}
                            >
                              Need help?
                            </Text>
                          </Text>
                        </Box>
                      }
                    />
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </FocusLayout>
  );
};
