import React from 'react';
import { Box, Text } from 'rebass/styled-components';

export const TableHeader = () => {
  const columns = [
    { title: 'Company', size: '216px' },
    { title: 'Contact hired', size: '252px' },
    { title: 'Position', size: '256px' },
    { title: 'Hired date', size: '136px' },
    { title: 'Contact of', size: '216px' },
    { title: 'Talent sign up', size: '130px' },
    { title: 'Team assisted', size: '160px' },
    { title: '', size: '44px' },
  ];

  return (
    <Box as="tr" sx={{ borderBottom: 1, borderBottomColor: 'border.subtle', backgroundColor: '#fff' }}>
      {columns.map((item) => (
        <Box
          key={`hires-table-header-${item.title}`}
          as="th"
          py="8px"
          minWidth={[item.size, 'auto']}
          width={['auto', item.size]}
          textAlign="left"
        >
          <Text color="text.subtle" fontSize="0" sx={{ letterSpacing: '1px', textTransform: 'uppercase' }}>
            {item.title}
          </Text>
        </Box>
      ))}
    </Box>
  );
};
