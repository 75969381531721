import React from 'react';
import { Flex, Image, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import SearchNoResultsIcon from '../../../assets/icon-effortless-search-no-results.svg';

export const ContactsNoResults = ({ isList }) => (
  <Flex mt="40px" flexDirection="column" alignItems="center" maxWidth="520px" mx="auto" textAlign="center">
    <Image src={SearchNoResultsIcon} width="64px" maxWidth="64px" />
    <Text mt="16px" fontWeight="semibold">
      No results {isList ? 'in this list' : ''} for this search
    </Text>
    <Text mt="8px" color="text.subtle">
      Review the filters to see more results.
    </Text>
  </Flex>
);

ContactsNoResults.propTypes = {
  isList: PropTypes.bool,
};

ContactsNoResults.defaultProps = {
  isList: false,
};
