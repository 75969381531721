import React from 'react';
import { useHistory } from 'react-router-dom';
import BlankLayout from 'components/layout/Blank';
import { MessageBlock } from '@getro/rombo';
import { networkRoutes } from 'router/routes';
import usePageHook from '../../hooks/usePageHook';

const pageTitle = 'Network not found';

const NetworkNotFound = () => {
  usePageHook(pageTitle);
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(networkRoutes.networkHome.path);
  };

  return (
    <div data-cy="network-not-found">
      <BlankLayout>
        <MessageBlock
          type="warning"
          title="Network not found"
          content="This network either doesn't exist or you don't have access to it."
          buttonText="Go back"
          onClick={handleButtonClick}
        />
      </BlankLayout>
    </div>
  );
};

export default NetworkNotFound;
