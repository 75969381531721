/* eslint-disable max-lines */
import { Button, Checkbox, DataTable } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Copy, Edit2, ExternalLink, RefreshCw, Share, X, Eye, Star, Download } from 'lucide-react';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation } from 'react-router';
import qs from 'query-string';
import pluralize from 'pluralize';
import GetroConnectIntro from 'components/molecules/GetroConnectIntro';
import { trackEvent } from 'helpers/analytics';
import RenewJobModal from '../../molecules/renewJobModal';
import ForceJobVisibilityModal from '../../molecules/forceJobVisibilityModal';
import { toggleJobActionDialog } from '../../../hooks/useJobActions/actions';
import useJobActions from '../../../hooks/useJobActions';
import { activeNetworkSelector, hasMatchesSelector } from '../../../redux/selectors';
import { ShareJobModal } from '../../molecules/shareJobModal';
import { CloseJobModal } from '../../molecules/closeJobModal';
import { DeleteJobModal } from '../../molecules/deleteJobModal';
import { useCreateMatchProjectMutation } from '../../../services/matches';
import strings from '../../../strings';
import JobStatus from '../../atoms/JobStatus';
import JobVisibility from '../../atoms/JobVisibility';
import { toggleJobSelection } from '../../../hooks/useJobs/actions';
import FeatureJobModal from '../../molecules/featureJobModal';
import { isNetworkAdminSelector } from '../../../hooks/usePermissions/selectors';
import JobFeatured from './jobFeatured';
import { selectedJobsIdsSelector } from '../../../hooks/useJobs/selectors';
import useJobRules from '../../../hooks/jobRules/useJobRules';
import { getJobsColumns } from './jobColumns';
import { getAuthor, getExpirationDate } from './jobHelpers';
import JobRulesModal from './jobRulesModal';
import DownloadApplicants from './downloadApplicants';

export const JobsDataTable = ({
  isJobsLoading,
  isJobsInitialized,
  totalJobs,
  loadNextPage,
  history,
  jobs,
  hasMoreJobs,
  onSort,
  emptyView,
  initialSortBy,
  source,
}) => {
  const [tableRows, setTableRows] = useState([]);
  const { jobActions, jobActionsLoading, jobActionsErrors, dialogStates } = useJobActions({ source });
  const { loadJobRulesRequest } = useJobRules();
  const [createProject, { isLoading }] = useCreateMatchProjectMutation();
  const [selectedJob, setSelectedJob] = useState(null);
  const [showJobRules, setShowJobRules] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const jobsColumns = getJobsColumns();
  const activeNetwork = useSelector(activeNetworkSelector);
  const isNetworkAdmin = useSelector(isNetworkAdminSelector);
  const selectedJobsIds = useSelector(selectedJobsIdsSelector);
  const hasMatches = useSelector(hasMatchesSelector);
  const { push } = useHistory();
  const [connectModal, setConnectModal] = useState(false);
  const getJobById = useCallback(
    (job) => {
      if (job) {
        const {
          original: { id },
        } = job;
        const match = jobs.find((j) => parseInt(j.attributes.id, 10) === parseInt(id, 10));
        if (match) {
          return match.attributes;
        }
      }
      return {};
    },
    [jobs],
  );

  const isJobSourcePostedActiveOrExpired = useCallback(
    (e) => {
      const job = getJobById(e);
      if (job.source === 'posted') {
        if (job.status === 'active' || job.status === 'expired') {
          return false;
        }
      }
      return true;
    },
    [getJobById],
  );

  const onShowJobRulesClick = useCallback(() => {
    loadJobRulesRequest();
    setShowJobRules(true);
  }, [loadJobRulesRequest]);

  const showConnectModal = !hasMatches && activeNetwork.isManager;
  const handleSeeMatches = useCallback(
    async (job) => {
      trackEvent('jobs:get_matches_click', {
        collection_id: activeNetwork.id,
        job_id: job?.id,
        organization_id: job?.organization?.id,
        network_using_connect: activeNetwork.isManager,
        network_using_matches: hasMatches,
      });
      setConnectModal(showConnectModal);
    },
    [activeNetwork.id, activeNetwork.isManager, hasMatches, showConnectModal],
  );

  useEffect(() => {
    setTableRows(
      jobs.map((j) => {
        const job = j.attributes;
        const { name: companyName } = job.organization;
        const { id: authorId } = job.author || {};
        const { selected } = j;

        return {
          id: job.id,
          slug: job.slug,
          authorId,
          title: (
            <Flex alignItems="flex-start" my={[2]} justifyContent="flex-start">
              {isNetworkAdmin && (
                <Box pl={[2]} my={0} sx={{ flexShrink: 0 }}>
                  <Checkbox
                    name="job"
                    value={job.id}
                    onChange={(e) => {
                      dispatch(toggleJobSelection({ job, checked: e.target.checked }));
                    }}
                    checked={selected}
                  />
                </Box>
              )}
              <Flex flexDirection="column">
                <Text color="text.main" fontWeight="semibold" fontSize={[1]}>
                  {job.title}
                </Text>
                <Text fontSize={[1]} color="text.main">
                  {companyName}
                </Text>
              </Flex>
            </Flex>
          ),
          source: (
            <Text fontSize={[1]} color="text.main" my={2}>
              {strings.jobs.job_sources_for_admin_portal[job.source]}
            </Text>
          ),
          status: (
            <Flex fontSize={[1]} my={[2]}>
              <JobStatus job={job} />
            </Flex>
          ),
          visibility: (
            <Flex fontSize={[1]} my={[2]}>
              <JobVisibility visibility={job.visibility} onShowJobRulesClick={onShowJobRulesClick} />
            </Flex>
          ),
          featured: (
            <Flex fontSize={[1]} my={[2]}>
              <JobFeatured isFeatured={job.featured === 'featured'} />
            </Flex>
          ),
          applicants: (
            <Flex justifyContent="flex-end" fontSize={[1]} my={[2]}>
              <Text pr={3} color="text">
                {typeof job.jobApplicationsCount === 'number' ? job.jobApplicationsCount : ' -'}
              </Text>
            </Flex>
          ),
          createdAt: (
            <Flex width={[1]} flexDirection="column">
              <Text fontSize={[1]} color="text.main" mt={[2]}>
                {moment(job.createdAt).format('D MMM YYYY')}
              </Text>
              <Text fontSize={[1]} color="text.subtle" mb={[2]}>
                {getAuthor(job)}
              </Text>
            </Flex>
          ),
          expirationDate: (
            <Flex width={[1]}>
              <Text fontSize={[1]} color="text.main" my={2}>
                {getExpirationDate(job)}
              </Text>
            </Flex>
          ),
          location: (
            <Text fontSize={[1]} color="text.main" my={2}>
              {job.locations && job.locations.length ? job.locations?.join(', ') : '-'}
            </Text>
          ),
          matches: (
            <Box sx={{ button: { bg: 'purple.100' } }} mt="8px">
              <Button
                loading={isLoading && selectedJob.id === job.id}
                onClick={() => handleSeeMatches(job)}
                bg="purple.100"
                size="small"
                variant="accent"
              >
                See matches
              </Button>
            </Box>
          ),
        };
      }),
    );
  }, [
    jobs,
    dispatch,
    onShowJobRulesClick,
    isNetworkAdmin,
    createProject,
    push,
    activeNetwork.slug,
    handleSeeMatches,
    isLoading,
    selectedJob?.id,
  ]);

  const actionMenuItems = useMemo(
    () =>
      [
        {
          isDisabled: (e) => {
            const job = getJobById(e);
            return !(job.status === 'active');
          },
          title: 'Share',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Share} />
              Share
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            setSelectedJob(job);
            jobActions.onShareJob(job);
          },
          onlyNetworkAdmin: false,
        },
        {
          isDisabled: (e) => {
            const job = getJobById(e);
            return !(job.status === 'active');
          },
          isLoading: jobActionsLoading.isViewing,
          title: 'View',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={ExternalLink} />
              View
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            jobActions.onViewJob({ job });
          },
          onlyNetworkAdmin: false,
        },
        {
          isLoading: jobActionsLoading.isRequestingApplicationsDownload,
          title: 'Applicants',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Download} />
              Applicants
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            jobActions.onDownloadJobApplications(job);
          },
          isDisabled: (e) => {
            const job = getJobById(e);
            return !job.jobApplicationsCount;
          },
          onlyNetworkAdmin: false,
        },
        {
          Item: Box,
          px: 3,
          children: <Box as="hr" variant="hr" />,
        },

        {
          isDisabled: (e) => {
            const job = getJobById(e);
            return !(job.status === 'active' && job.source === 'posted');
          },
          isLoading: jobActionsLoading.isEditing,
          title: 'Edit',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Edit2} />
              Edit
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            if (source === 'employer_profile') {
              history.push(
                `/networks/${activeNetwork.slug}/companies/${job.organization.id}-${job.organization.slug}/edit-job/${job.id}`,
              );
            } else {
              history.push(`/networks/${activeNetwork.slug}/jobs/edit-job/${job.id}`);
            }
          },
          onlyNetworkAdmin: false,
        },
        {
          isDisabled: isJobSourcePostedActiveOrExpired,
          isLoading: jobActionsLoading.isExtending,
          title: 'Extend',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={RefreshCw} />
              Extend
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            setSelectedJob(job);
            jobActions.onExtendJobRequest(job);
          },
          onlyNetworkAdmin: false,
        },
        {
          title: 'Duplicate',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Copy} />
              Duplicate
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            if (source === 'employer_profile') {
              history.push(
                `/networks/${activeNetwork.slug}/companies/${job.organization.slug}/duplicate-job/${job.id}`,
              );
            } else {
              history.push(`/networks/${activeNetwork.slug}/jobs/duplicate-job/${job.id}`);
            }
          },
          onlyNetworkAdmin: false,
        },
        {
          Item: Box,
          px: 3,
          children: <Box as="hr" variant="hr" />,
        },
        {
          isLoading: jobActionsLoading.isFeaturingJob,
          title: 'Featured status',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Star} />
              Featured status
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            setSelectedJob(job);
            jobActions.onFeatureJobRequest(job);
          },
          isDisabled: (e) => {
            const job = getJobById(e);
            return job.status !== 'active' || job.visibility.includes('hidden');
          },
          onlyNetworkAdmin: true,
        },
        {
          isLoading: jobActionsLoading.isForcingVisibility,
          title: 'Set visibility',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={Eye} />
              Set visibility
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            setSelectedJob(job);
            jobActions.onForceJobVisibilityRequest(job);
          },
          isDisabled: (e) => {
            const job = getJobById(e);
            return job.status !== 'active' || !isNetworkAdmin;
          },
          onlyNetworkAdmin: true,
        },
        {
          isLoading: jobActionsLoading.isClosing,
          title: 'Close',
          children: (
            <>
              <Box mr={2} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" as={X} />
              Close
            </>
          ),
          onClick: (e) => {
            const job = getJobById(e);
            setSelectedJob(job);
            jobActions.onCloseJobRequest(job);
          },
          isDisabled: (e) => {
            const job = getJobById(e);
            if (job.source === 'posted') {
              if (job.status === 'expired') {
                return true;
              }
              if (job.status === 'active') {
                return false;
              }
            }
            return true;
          },
          onlyNetworkAdmin: false,
        },
      ].filter(({ onlyNetworkAdmin }) => isNetworkAdmin || !onlyNetworkAdmin),
    [
      jobActionsLoading.isViewing,
      jobActionsLoading.isRequestingApplicationsDownload,
      jobActionsLoading.isEditing,
      jobActionsLoading.isExtending,
      jobActionsLoading.isFeaturingJob,
      jobActionsLoading.isForcingVisibility,
      jobActionsLoading.isClosing,
      isJobSourcePostedActiveOrExpired,
      getJobById,
      jobActions,
      activeNetwork,
      source,
      history,
      isNetworkAdmin,
    ],
  );

  const onRowProps = useCallback(
    (row) => {
      const isSelected = selectedJobsIds.includes(row.original.id.toString());
      return {
        backgroundColor: isSelected ? 'purple.100' : 'unset',
        transition: 'all ease .3s',
        sx: {
          '&:hover': {
            backgroundColor: !isSelected ? 'neutral.20' : 'purple.100',
            cursor: isSelected ? 'inherit' : 'pointer',
          },
        },
      };
    },
    [selectedJobsIds],
  );

  return (
    <Flex flexDirection="column" flexGrow="1">
      <Text color="text.main" fontSize={1} my={3}>
        Showing {totalJobs.toLocaleString()} {pluralize('job', totalJobs)}
      </Text>
      <DataTable
        canSort
        sx={{
          pb: 3,
          minWidth: '980px',
          height: ['600px', '600px', '100%'],
          width: ['1000px!important', '1000px!important', '100%!important'],
          flexGrow: 1,
        }}
        initialSortBy={initialSortBy}
        onSort={onSort}
        loadMore={loadNextPage}
        rowActions={{
          height: 330,
          items: actionMenuItems,
        }}
        items={tableRows}
        columnDefinition={jobsColumns.filter((item) => !(item.accessor === 'matches' && !showConnectModal))}
        hasMore={hasMoreJobs}
        isInitialized={isJobsInitialized}
        isLoadingMore={isJobsInitialized && isJobsLoading}
        emptyView={emptyView}
        virtuosoProps={{
          useWindowScroll: true,
        }}
        rowProps={onRowProps}
      />
      <RenewJobModal
        isOpen={dialogStates.extendJob}
        title={selectedJob?.title}
        company={selectedJob?.organization}
        onClose={() => dispatch(toggleJobActionDialog({ dialog: 'extendJob', isOpen: false }))}
        onSubmit={(e) => {
          jobActions.onExtendJob({ job: selectedJob, extension: e });
        }}
        isSubmitting={jobActionsLoading.isExtending}
        error={jobActionsErrors.extendJob}
      />
      {isNetworkAdmin && (
        <ForceJobVisibilityModal
          isOpen={dialogStates.forceJobVisibility}
          title={`Set ${selectedJob?.title} job visibility `}
          visibilityValue={selectedJob?.visibility}
          company={selectedJob?.organization}
          onClose={() => dispatch(toggleJobActionDialog({ dialog: 'forceJobVisibility', isOpen: false }))}
          onSubmit={(v) => {
            jobActions.onForceJobVisibility({
              job: selectedJob,
              forcedVisibility: v,
              visibilityFilter: qs.parse(location.search).visibility,
            });
          }}
          isSubmitting={jobActionsLoading.isForcingVisibility}
          error={jobActionsErrors.forceJobVisibility}
        />
      )}
      {isNetworkAdmin && (
        <FeatureJobModal
          isOpen={dialogStates.featureJob}
          title="Update job feature status"
          featureJobValue={selectedJob?.featured}
          onClose={() => dispatch(toggleJobActionDialog({ dialog: 'featureJob', isOpen: false }))}
          onSubmit={(v) => {
            jobActions.onFeatureJob({
              job: selectedJob,
              featured: v,
              featuredFilter: qs.parse(location.search).featured,
            });
          }}
          isSubmitting={jobActionsLoading.isFeaturingJob}
          error={jobActionsErrors.featureJob}
        />
      )}
      <ShareJobModal
        job={selectedJob}
        isOpen={dialogStates.shareJob}
        onCancel={() => {
          dispatch(toggleJobActionDialog({ dialog: 'shareJob', isOpen: false }));
        }}
      />
      <CloseJobModal
        job={setSelectedJob}
        isOpen={dialogStates.closeJob}
        onCancel={() => {
          dispatch(toggleJobActionDialog({ dialog: 'closeJob', isOpen: false }));
        }}
        isSubmitting={jobActionsLoading.isClosing}
        error={jobActionsErrors.closeJob}
        onSubmit={() => {
          jobActions.onCloseJob({
            jobId: selectedJob.id,
            networkId: activeNetwork.id,
            isNetworkManager: activeNetwork.isManager,
            companyId: selectedJob.organization.id,
          });
        }}
      />
      <DeleteJobModal
        isDeleting={jobActionsLoading.isDeleting}
        isClosing={jobActionsLoading.isClosing}
        isOpen={dialogStates.deleteJob}
        onCancel={() => {
          dispatch(toggleJobActionDialog({ dialog: 'deleteJob', isOpen: false }));
        }}
        onCloseJob={() => {
          jobActions.onDeleteJob({
            jobId: selectedJob.id,
            networkId: activeNetwork.id,
            isNetworkManager: activeNetwork.isManager,
            companyId: selectedJob.organization.id,
          });
        }}
        onDeleteJob={() => {
          jobActions.onDeleteJob({ job: selectedJob });
        }}
      />
      <DownloadApplicants isOpen={dialogStates.jobApplicationDownload} />
      {connectModal && <GetroConnectIntro onCancel={() => setConnectModal(false)} />}
      {showJobRules && <JobRulesModal onCancel={() => setShowJobRules(false)} />}
    </Flex>
  );
};

JobsDataTable.propTypes = {
  isJobsLoading: PropTypes.bool,
  isJobsInitialized: PropTypes.bool,
  totalJobs: PropTypes.number,
  loadNextPage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  jobs: PropTypes.array,
  hasMoreJobs: PropTypes.bool,
  onSort: PropTypes.func,
  emptyView: PropTypes.node,
  initialSortBy: PropTypes.shape({
    id: PropTypes.string.isRequired,
    desc: PropTypes.bool.isRequired,
  }),
  source: PropTypes.string,
};

JobsDataTable.defaultProps = {
  isJobsLoading: false,
  isJobsInitialized: false,
  totalJobs: 0,
  jobs: [],
  hasMoreJobs: false,
  onSort: () => {},
  emptyView: null,
  initialSortBy: { id: 'expirationDate', desc: false },
  source: 'jobs',
};
