import React from 'react';
import { useHistory } from 'react-router-dom';

import { MessageBlock } from '@getro/rombo';

import BlankLayout from 'components/layout/Blank';
import { networkRoutes } from 'router/routes';
import usePageHook from '../../hooks/usePageHook';

const pageTitle = 'Page Not Found';

const PageNotFound = () => {
  usePageHook(pageTitle);
  const history = useHistory();
  const handleClick = () => history.push(networkRoutes.networkHome.path);

  return (
    <BlankLayout>
      <MessageBlock
        type="warning"
        title="404 - Page Not Found"
        content="This page either doesn't exist or you don't have access to it."
        buttonText="Go back"
        onClick={handleClick}
      />
    </BlankLayout>
  );
};

export default PageNotFound;
