import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Box, Text, Flex } from 'rebass/styled-components';
import * as Yup from 'yup';
import isEqual from 'lodash.isequal';

import { get } from 'api';
import { trackException } from 'helpers/errorTracker';
import {
  Search,
  Message,
  Box as RomboBox,
  FormTextarea,
  FormInput,
  FormImage,
  Checkbox,
  FormSectionTitle,
} from '@getro/rombo';
import { AddCompanyProcess } from 'components/atoms/AddCompanyProcess';
import { useHistory } from 'react-router';
import NetworkSchema from 'schemas/network';
import { useDispatch, useSelector } from 'react-redux';
import { loadCompany, loadedCompany } from 'hooks/useCompany/actions';
import capitalize from 'helpers/capitalize';
import ContactUs from './ContactUs';
import { usePrevious } from '../../../hooks/usePrevious';
import Stage from './Stage';
import IndustryTags from './IndustryTags';
import { useSocialNetwork } from './hooks/useSocialNetwork';
import { InfoView } from './InfoView';
import CompanyTags from './CompanyTags';
import { makeSelectTopicOptions } from '../selectors';

const Info = ({ company, editable, onCompanyUpdate, updating, error, network }) => {
  const formRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const nameEditable = useMemo(() => company.name === company.domain && editing, [company, editing]);
  const previousUpdating = usePrevious(updating);
  const history = useHistory();
  const dispatch = useDispatch();
  const { socials, SOCIAL_NETWORKS, SOCIAL_NETWORKS_KEYS } = useSocialNetwork({ company });
  const { jobBoardFilters, isManager } = network;
  const { stageFilter, industryTagsFilter } = jobBoardFilters;
  const topicOptions = useSelector(makeSelectTopicOptions);

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.setSubmitting(updating);
    }
  }, [updating]);

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.setStatus({ error });
    }
  }, [error]);

  useEffect(() => {
    if (previousUpdating && !updating && !error) {
      setEditing(false);
    }
  }, [error, previousUpdating, updating]);

  const getSearchLocations = async (query) => {
    if (!query.length) {
      return [];
    }

    try {
      const response = await get(`/locations/search/google`, {
        params: { q: query },
      });

      return response.data.items.map(({ description, placeId }) => ({
        value: placeId,
        label: description,
      }));
    } catch (e) {
      trackException(e);
      return [];
    }
  };

  const handleSubmit = async (values, { setStatus }) => {
    setStatus({ error: false });

    const { locations, industryTags, topics, ...params } = values;
    const requestPayload = {
      ...params,
      placeIds: locations.map((l) => l.value),
    };

    if (industryTagsFilter) {
      requestPayload.industryTagIds = industryTags.map((t) => t.value);
    }

    if (
      isManager &&
      !isEqual(
        topics?.map(({ value }) => value),
        company.topics?.map(({ id }) => id),
      )
    ) {
      requestPayload.topics = topics;
    }

    onCompanyUpdate(requestPayload, (data) => {
      const resultPayload = { ...data };

      if (industryTagsFilter) {
        resultPayload.industryTags = industryTags.map((item) => item.label);
      }

      dispatch(loadedCompany(resultPayload));
      if (company.slug !== data.slug) {
        history.replace(`/networks/${network.slug}/companies/${data.id}-${data.slug}`);
        dispatch(loadCompany({ companySlug: data.slug }));
      }
    });
  };

  const handleCancel = (resetForm) => {
    resetForm();
    setEditing(false);
  };

  const remote = company.locations.some((l) => l.name === 'Remote');
  const initialValues = {
    name: company.name,
    logoUrl: company.logoUrl,
    domain: company.domain,
    description: company.description || '',
    remote,
    locations: company.locations.filter((l) => l.name !== 'Remote').map((l) => ({ value: l.placeId, label: l.name })),
    founded: company.founded || '',
    approxEmployees: company.approxEmployees || '',
    ...SOCIAL_NETWORKS_KEYS.reduce((acc, k) => ({ ...acc, [k]: company[k] || '' }), {}),
  };

  if (industryTagsFilter) {
    initialValues.industryTags = company.industryTags?.map((tag) => ({ label: capitalize(tag), value: tag })) || [];
  }

  if (stageFilter) {
    initialValues.stage = company.stage || null;
  }

  if (isManager && topicOptions.options.length) {
    initialValues.topics =
      company.topics?.map(({ id, name }) => ({ label: capitalize(name), value: capitalize(id) })) || [];
  }
  const formSchema = Yup.object().shape({
    name: Yup.string().required(),
    domain: Yup.string().nullable(),
    ...SOCIAL_NETWORKS_KEYS.reduce((acc, k) => ({ ...acc, [k]: SOCIAL_NETWORKS[k].schema }), {}),
  });

  const boxClickAction = (() => {
    if (editable) {
      return setEditing.bind(null, true);
    }
    return undefined;
  })();

  const formSectionStyle = {
    flex: 1,
    flexWrap: 'wrap',
    columnGap: '16px',
    rowGap: '24px',
    '&>div': {
      minWidth: '200px',
      width: ['100%', 'calc(50% - 9px)'],
    },
  };

  const saveText = company.qa ? 'Save' : 'Save and publish';

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
      enableReinitialize
    >
      {({ values, status, resetForm, setFieldValue }) => (
        <Form>
          <RomboBox
            title="General information"
            onEditClick={boxClickAction}
            editLinkText="Edit"
            editing={editable ? editing : undefined}
            onCancelClick={() => handleCancel(resetForm)}
            loading={updating}
            saveAsSubmit
            saveText={saveText}
          >
            {!editing && <InfoView network={network} logoUrl={values.logoUrl} company={company} socials={socials} />}
            {editing && (
              <Box>
                <Flex
                  flexDirection="column"
                  width="100%"
                  sx={{
                    gap: '24px',
                  }}
                >
                  <Flex sx={{ '& > div': { flex: 1 }, ...formSectionStyle }}>
                    <Box>
                      <Field
                        component={FormInput}
                        id="name"
                        label="Name"
                        labelExtra={!company.name ? 'Required' : ''}
                        name="name"
                        required={!company.name}
                        disabled={!nameEditable}
                      />
                      {!nameEditable && <ContactUs title="name" />}
                    </Box>
                    <Box>
                      <Field component={FormInput} id="domain" label="Domain" name="domain" disabled />
                      <ContactUs title="domain" />
                    </Box>
                  </Flex>
                  <FormImage
                    field={{ name: 'logoUrl', value: values.logoUrl }}
                    filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
                    id="logoUrl"
                    label="Logo"
                    name={company.name}
                    form={{
                      setFieldValue,
                    }}
                  />
                  <Field component={FormTextarea} id="description" label="Description" name="description" />
                  <Box
                    sx={{
                      '& > div': {
                        '.Search__control': {
                          transition: 'all .3s',
                        },
                        '.Search__control--is-focused': {
                          boxShadow: '0 0 0 1px',
                        },
                      },
                    }}
                    marginBottom="24px"
                  >
                    <Text display="block" marginBottom="8px" as="label" color="text.dark" fontWeight="medium">
                      Locations
                    </Text>
                    <Search
                      multiple
                      name="locations"
                      onChange={(e) => setFieldValue('locations', e.target.value)}
                      value={values.locations}
                      onSearch={getSearchLocations}
                      orientation="horizontal"
                      placeholder="Add location"
                    />

                    <Box marginTop="8px">
                      <Field
                        component={Checkbox}
                        id="remote"
                        name="remote"
                        label="Remote"
                        value={values.remote}
                        checked={values.remote}
                        onChange={() => setFieldValue('remote', !values.remote)}
                      />
                    </Box>
                  </Box>
                </Flex>
                <Flex sx={formSectionStyle}>
                  {industryTagsFilter && (
                    <IndustryTags
                      value={values.industryTags}
                      setValue={(value) => setFieldValue('industryTags', value)}
                    />
                  )}
                  <Field minWidth="200px" component={FormInput} id="founded" label="Founded date" name="founded" />
                  <Field
                    component={FormInput}
                    id="approxEmployees"
                    label="Number of employees"
                    name="approxEmployees"
                  />
                  {stageFilter && <Stage value={values.stage} setValue={(value) => setFieldValue('stage', value)} />}
                  {topicOptions?.options.length > 0 && isManager && (
                    <CompanyTags
                      value={values.topics}
                      setValue={(value) => setFieldValue('topics', value)}
                      options={topicOptions.options}
                    />
                  )}
                </Flex>

                <FormSectionTitle sx={{ mb: '24px' }}>Social Profiles</FormSectionTitle>
                <Flex sx={formSectionStyle}>
                  {SOCIAL_NETWORKS_KEYS.map((item) => (
                    <Box>
                      <Field
                        component={FormInput}
                        key={item}
                        id={item}
                        label={`${SOCIAL_NETWORKS[item].title}`}
                        placeholder={`${SOCIAL_NETWORKS[item].placeholder}`}
                        name={item}
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>
            )}
            {status && status.error && (
              <Message type="error" className="CompanyProfile__error-message" content={status.error} />
            )}
            {!company.qa && editing && (
              <Box
                mt="24px"
                sx={{ '& > div': { padding: '20px', alignItems: 'flex-start', gap: '16px', svg: { marginRight: 0 } } }}
              >
                <Message type="info">
                  If you update the information of this company, our team will stop looking for it and this information
                  will be used. <AddCompanyProcess />
                </Message>
              </Box>
            )}
          </RomboBox>
        </Form>
      )}
    </Formik>
  );
};

Info.propTypes = {
  company: PropTypes.object.isRequired,
  onCompanyUpdate: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  updating: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  network: NetworkSchema.isRequired,
};

Info.defaultProps = {
  updating: false,
  error: false,
};

export default Info;
