import { ContactsProgressCount } from 'components/molecules/contactsProgressCount';
import React from 'react';
import { Button, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const UpdateBox = ({
  contactsCount,
  contactsLimit,
  networkSlug,
  connectTier,
  onUpgradeClicked,
  progressText,
}) => {
  const completion = (contactsCount / contactsLimit) * 100;

  return (
    <Flex width="100%">
      <Flex flexGrow={1} sx={{ '& > div': { width: '100%' } }}>
        <ContactsProgressCount contactsCount={contactsCount} limit={contactsLimit} progressText={progressText} />
      </Flex>

      {connectTier === 'freemium' && completion <= 90 && (
        <Flex sx={{ '& > div': { width: '100%' } }}>
          <Button
            as={Link}
            size="small"
            to={`/networks/${networkSlug}/upgrade`}
            variant="secondary"
            onClick={onUpgradeClicked}
            sx={{ width: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            Upgrade
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

UpdateBox.propTypes = {
  contactsCount: PropTypes.number.isRequired,
  contactsLimit: PropTypes.number.isRequired,
  connectTier: PropTypes.oneOf(['premium', 'freemium']).isRequired,
  networkSlug: PropTypes.string.isRequired,
  onUpgradeClicked: PropTypes.func.isRequired,
  progressText: PropTypes.string.isRequired,
};
