import React from 'react';
import PropTypes from 'prop-types';

const MemberRow = ({ title, text, children, className }) => (
  <div className={className} data-testid="member-row">
    <div className={`${className}-title`}>{title}</div>
    <div className={`${className}-content`}>{text || children}</div>
  </div>
);

MemberRow.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

MemberRow.defaultProps = {
  text: null,
  children: null,
  className: 'Member__row',
};

export default MemberRow;
