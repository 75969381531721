import { Loader } from '@getro/rombo';
import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';

export const HowItWorks = () => (
  <Flex sx={{ gap: '16px' }} flexDirection={['column', 'row']}>
    <Box>
      <Flex alignItems="center">
        <Text fontSize="24px" fontWeight="semibold" color="text.dark">
          Track hires in your network
        </Text>
        <Box>
          <Text
            as="span"
            ml="8px"
            px="4px"
            bg="purple.100"
            fontSize="12px"
            fontWeight="semibold"
            color="purple.400"
            sx={{ borderRadius: 'circle' }}
          >
            Beta
          </Text>
        </Box>
      </Flex>
      <Text mt="12px" fontSize="14px" color="neutral.700">
        Monitor how the support you provide companies translates into outcomes.
      </Text>
      <Text mt="24px" fontSize="12px" color="text.subtle" sx={{ letterSpacing: '1px', textTransform: 'uppercase' }}>
        How it works
      </Text>
      <Flex mt="16px">
        <Box mt="6px" width="8px" height="8px" flex="0 0 auto" bg="purple.300" sx={{ borderRadius: 'circle' }} />
        <Box ml="8px">
          <Text fontSize="14px" fontWeight="medium" color="text.dark">
            Add all companies in your network
          </Text>
          <Text mt="4px" fontSize="14px">
            We use this list of companies to determine where a hire could be made.
          </Text>
        </Box>
      </Flex>
      <Flex mt="16px">
        <Box mt="6px" width="8px" height="8px" flex="0 0 auto" bg="purple.300" sx={{ borderRadius: 'circle' }} />
        <Box ml="8px">
          <Text fontSize="14px" fontWeight="medium" color="text.dark">
            Make sure your Contacts are up-to-date
          </Text>
          <Text mt="4px" fontSize="14px">
            The more complete your Contact database is, the more accurate your Hires Report will be.
          </Text>
        </Box>
      </Flex>
      <Flex mt="16px">
        <Box mt="6px" width="8px" height="8px" flex="0 0 auto" bg="purple.300" sx={{ borderRadius: 'circle' }} />
        <Box ml="8px">
          <Text fontSize="14px" fontWeight="medium" color="text.dark">
            New hires are automatically reported
          </Text>
          <Text mt="4px" fontSize="14px">
            We track job changes across your Contacts quarterly. When a Contact newly works at one of your listed
            companies, we add it to your Total Hires Report.
          </Text>
        </Box>
      </Flex>
      <Flex mt="16px">
        <Box mt="6px" width="8px" height="8px" flex="0 0 auto" bg="purple.300" sx={{ borderRadius: 'circle' }} />
        <Box ml="8px">
          <Text fontSize="1" fontWeight="medium" color="text.dark">
            Monitor your team’s impact
          </Text>
          <Text mt="4px" fontSize="14px">
            Review new hires and indicate if your team assisted with the hire.
          </Text>
        </Box>
      </Flex>
      <Flex mt="16px">
        <Box mt="6px" width="8px" height="8px" flex="0 0 auto" bg="purple.300" sx={{ borderRadius: 'circle' }} />
        <Box ml="8px">
          <Text fontSize="14px" fontWeight="medium" color="text.dark">
            Share with key stakeholders
          </Text>
          <Text mt="4px" fontSize="14px">
            Export the list of hires and share details with key stakeholders to celebrate your impact.
          </Text>
        </Box>
      </Flex>
    </Box>
    <Flex
      width={['100%', '48%']}
      flex="0 0 auto"
      sx={{ position: 'relative', borderRadius: 'default', overflow: 'hidden' }}
    >
      <Box sx={{ position: 'absolute', top: '20%', left: '50%', ml: '-20px' }}>
        <Loader />
      </Box>
      <Box
        pb="55.25%"
        width="100%"
        height="0"
        sx={{ overflow: 'hidden', position: 'relative', borderRadius: 'default' }}
      >
        <Box
          as="iframe"
          width="853"
          height="480"
          src="https://www.youtube.com/embed/QrgRblnIBJ4?rel=0"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          bg="transparent"
          sx={{
            left: 0,
            top: 0,
            height: '100%',
            width: '100%',
            position: 'absolute',
          }}
        />
      </Box>
    </Flex>
  </Flex>
);
