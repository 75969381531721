import PropTypes from 'prop-types';

const GroupSchema = PropTypes.shape({
  color: PropTypes.string,
  count: PropTypes.number,
  description: PropTypes.string,
  label: PropTypes.string,
  subscribed: PropTypes.bool,
  visibility: PropTypes.string,
  systemBoard: PropTypes.bool,
});

export default GroupSchema;
