import React, { Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { Dropdown, ProfilePicture } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';

import { companiesApi } from 'services/companies';
import { Check, CreditCard, ExternalLink, Settings, Users } from 'lucide-react';
import { addProtocolToUrl } from 'helpers/urlHelpers';
import { hiresApi } from 'services/hires';
import { networkV2Api } from 'services/networkV2';
import { networkApi } from 'services/network';
import { toggleMenu } from '../../../features/mainMenu';
import { Card } from '../../atoms/Card';
import { isGetroUserSelector, networksSelector } from '../../../redux/selectors';
import { contactsApi } from '../../../services/contacts';
import { matchesApi } from '../../../services/matches';
import ActiveNetwork from './activeNetwork';
import { networkAdminsApi } from '../../../services/networkAdmins';

export const NetworkSelector = () => {
  const { networks, active } = useSelector(networksSelector);
  const dispatch = useDispatch();
  const { url: jobBoardUrl, logoUrl: logo, isManager, ...rest } = networks[active];
  const current = { logo, ...rest };
  const networkList = Object.values(networks).sort((n1, n2) => {
    const n1Label = n1.name || n1.domain;
    const n2Label = n2.name || n2.domain;

    return n1Label.localeCompare(n2Label);
  });

  const onClickNetwork = () => {
    dispatch(contactsApi.util.resetApiState());
    dispatch(companiesApi.util.resetApiState());
    dispatch(matchesApi.util.resetApiState());
    dispatch(networkAdminsApi.util.resetApiState());
    dispatch(hiresApi.util.resetApiState());
    dispatch(networkApi.util.resetApiState());
    dispatch(networkV2Api.util.resetApiState());
  };

  const isGetroUser = useSelector(isGetroUserSelector);

  const networkLinks = useMemo(() => {
    const links = [];

    if (isManager) {
      links.push(
        {
          label: 'Settings',
          to: `/networks/${rest.slug}/settings/general`,
          icon: Settings,
          target: '_self',
        },
        {
          label: 'Admins',
          to: `/networks/${rest.slug}/settings/admins`,
          icon: Users,
          target: '_self',
        },
      );
    }

    if (isGetroUser) {
      links.push({
        label: 'Plans and billing',
        to: `/networks/${rest.slug}/settings/plans-and-billing`,
        icon: CreditCard,
        target: '_self',
      });
    }

    if (jobBoardUrl) {
      links.push({
        label: 'Open Job Board',
        onClick: () => window.open(addProtocolToUrl(jobBoardUrl), '_blank'),
        icon: ExternalLink,
        target: '_blank',
        to: '',
      });
    }

    return links ?? [];
  }, [isManager, isGetroUser, jobBoardUrl, rest.slug]);

  return (
    <Box>
      <Dropdown
        data-cy="network-selector-multiple"
        placement="bottom-start"
        triggerProps={{ variant: 'styleless', children: <ActiveNetwork current={current} canSelectNetwork /> }}
        dropDownProps={{
          sx: {
            maxHeight: 'calc(100% - 32px)',
            overflowY: 'auto',
            boxShadow: 'rgb(0 0 0 / 10%) 0px 1px 8px, rgb(0 0 0 / 15%) 0px 8px 24px',
            borderRadius: '6xp',
            padding: 0,
          },
        }}
      >
        <Card p="0" py="8px" width="240px">
          {networkList.length > 1 && (
            <Box
              sx={{ textTransform: 'uppercase', letterSpacing: '1px', lineHeight: '1.4' }}
              as="p"
              color="text.subtle"
              mt="8px"
              fontSize="12px"
              pl="16px"
            >
              {rest.name}
            </Box>
          )}
          <Box
            mt="8px"
            px="16px"
            sx={{
              a: {
                color: 'text.main',
                textDecoration: 'none',
                cursor: 'pointer',
                fontSize: '14px',
                lineHeight: '24px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                py: '4px',
                ':hover': {
                  color: 'text.dark',
                },
              },
            }}
          >
            {networkLinks.map(({ label, icon, target = '_blank', ...props }, index) => (
              <Fragment key={label}>
                {index === (isGetroUser ? 3 : 2) && isManager && (
                  <Box my="8px" bg="border.subtle" width="100%" height="1px" />
                )}
                <Link
                  target={target}
                  rel="noopener noreferrer"
                  color="text.main"
                  sx={{}}
                  onClick={() => {
                    const width =
                      window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

                    if (width < 990) {
                      dispatch(toggleMenu());
                    }
                  }}
                  {...props}
                >
                  <Box as={icon} size="16px" />
                  <Box as="span"> {label}</Box>
                </Link>
              </Fragment>
            ))}
          </Box>
          {networkList.length > 1 && (
            <>
              <Box
                mt="32px"
                sx={{ textTransform: 'uppercase', letterSpacing: '1px', lineHeight: '1.4' }}
                as="p"
                color="text.subtle"
                fontSize="12px"
                pl="16px"
                mb="8px"
              >
                YOUR NETWORKS
              </Box>

              {networkList.map(({ id, slug, name, domain, logoUrl }) => (
                <Flex
                  key={id}
                  as={NavLink}
                  to={`/networks/${slug}`}
                  height="48px"
                  px="3"
                  alignItems="center"
                  fontSize={1}
                  sx={{
                    background: 'none',
                    textDecoration: 'none',
                    bg: rest.id === id ? 'purple.100' : 'none',
                    ':hover': {
                      bg: 'purple.100',
                    },
                    '& > svg': {
                      color: 'purple.500',
                    },
                  }}
                  onClick={onClickNetwork}
                  justifyContent="space-between"
                >
                  <Flex alignItems="center">
                    <Box mr="2" width="32px">
                      <ProfilePicture imageUrl={logoUrl} name={name || domain} variant="square" />
                    </Box>
                    <Text
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '150px',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: rest.id === id ? 'text.dark' : 'text.main',
                      }}
                    >
                      {name || domain}
                    </Text>
                  </Flex>
                  {rest.id === id && <Box as={Check} size="16px" />}
                </Flex>
              ))}
            </>
          )}
        </Card>
      </Dropdown>
    </Box>
  );
};
