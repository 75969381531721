import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/molecules/Filter';
import OptionsSchema from '../../../schemas/options';

const SourceFilter = ({ value, onChange, sourceOptions, embeddedInModal, onDropDownStateChange }) => {
  const selectedSource = useMemo(() => sourceOptions.find((option) => option.value === value), [sourceOptions, value]);
  const options = [...sourceOptions];

  return (
    <Filter
      label="Source"
      name="source"
      placeholder="Source"
      value={selectedSource}
      onChange={onChange}
      options={options}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

SourceFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  sourceOptions: OptionsSchema.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
};

SourceFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
};

export default SourceFilter;
