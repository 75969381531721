import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/molecules/Filter';
import OptionsSchema from '../../../schemas/options';

const ExpirationFilter = ({
  value,
  onChange,
  expirationOptions,
  embeddedInModal,
  onDropDownStateChange,
  showLabel,
}) => {
  const selectedOption = useMemo(() => expirationOptions.find((i) => i.value === value), [expirationOptions, value]);
  return (
    <Filter
      showLabel={showLabel}
      label="Expiration"
      name="expiration"
      placeholder="Expiration"
      value={selectedOption}
      onChange={onChange}
      options={[...expirationOptions]}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

ExpirationFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  expirationOptions: OptionsSchema.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
  showLabel: PropTypes.bool,
};

ExpirationFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
  showLabel: true,
};

export default ExpirationFilter;
