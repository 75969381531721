import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { PickerOverlay } from 'filestack-react';

import { activeNetworkSelector } from 'redux/selectors';
import strings from 'strings';
import { get, put } from 'api';
import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';

import { UploadImage, FormField, Loader, MessageBlock, Box, BoxRow, ProfilePicture, FormSelect } from '@getro/rombo';

import HeroImage from './HeroImage';
import { GenericError } from '../../../../components/molecules/genericError';

const formSchema = Yup.object().shape({
  // TODO what's required?
  // heroTitle: Yup.string().required(),
  // windowTitle: Yup.string().required(),
});

const getTabsOrderString = (tabsOrder) => {
  let tabsOrderArray = tabsOrder.split(', ');
  tabsOrderArray = tabsOrderArray.map((o) => strings.settings.tabsOrder[o]);
  return tabsOrderArray.join(', ');
};

const tabsOrderOptionsJobBoard = ['jobs, companies', 'companies, jobs'];

const tabsOrderOptionsTalentNetwork = [
  'jobs, companies, talent_network',
  'jobs, talent_network, companies',
  'companies, jobs, talent_network',
  'companies, talent_network, jobs',
  'talent_network, jobs, companies',
  'talent_network, companies, jobs',
];

const Info = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { id: activeNetworkId } = activeNetwork;

  const [editing, setEditing] = useState(false);
  const [data, setData] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [tabsOrderOptions, setTabsOrderOptions] = useState([]);

  const handleEditClick = () => {
    setEditing(!editing);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setUpdateError(null);

    const { tabsOrder, ...rest } = values;
    const params = {
      tabsOrder: values.tabsOrder.value,
      ...rest,
    };

    try {
      const response = await put(`/collections/${activeNetworkId}/settings/job_board`, params);
      setData(response.data);
      setInitialValues({
        faviconUrl: response.data.faviconUrl || '',
        heroImageUrl: response.data.heroImageUrl || '',
        heroSubtitle: response.data.heroSubtitle || '',
        heroTitle: response.data.heroTitle || '',
        heroColorMask: response.data.heroColorMask || '',
        tabsOrder: {
          value: response.data.tabsOrder,
          label: getTabsOrderString(response.data.tabsOrder),
        },
        windowTitle: response.data.windowTitle || '',
      });
      setEditing(false);

      trackEvent('network_settings:edit_job_board');
    } catch (error) {
      trackException(error);
      setUpdateError(strings.genericError);
    }
    setSubmitting(false);
  };

  const handleCancel = (resetForm) => () => {
    resetForm();
    setEditing(false);
  };

  // Load settings on first load
  useEffect(() => {
    (async () => {
      try {
        const response = await get(`/collections/${activeNetworkId}/settings/job_board`);
        setInitialValues({
          faviconUrl: response.data.faviconUrl || '',
          heroImageUrl: response.data.heroImageUrl || '',
          heroSubtitle: response.data.heroSubtitle || '',
          heroTitle: response.data.heroTitle || '',
          heroColorMask: response.data.heroColorMask || '',
          tabsOrder: {
            value: response.data.tabsOrder,
            label: getTabsOrderString(response.data.tabsOrder),
          },
          windowTitle: response.data.windowTitle || '',
        });
        setData(response.data);
      } catch (error) {
        trackException(error);
        setFetchError(true);
        return;
      }
      setFetching(false);

      // Set tabs order options
      let options = tabsOrderOptionsJobBoard;
      if (activeNetwork.features.includes('talent_network')) {
        options = tabsOrderOptionsTalentNetwork;
      }
      setTabsOrderOptions(options.map((o) => ({ value: o, label: getTabsOrderString(o) })));
    })(activeNetworkId);
  }, [activeNetworkId, activeNetwork.features]);

  if (fetching) {
    return (
      <Box title="Job board settings">
        <Loader />
      </Box>
    );
  }

  if (fetchError) {
    return (
      <Box title="Job board settings">
        <MessageBlock>{strings.genericError}</MessageBlock>
      </Box>
    );
  }

  return (
    <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit} enableReinitialize>
      {({ values, status, isSubmitting, resetForm, setFieldValue }) => (
        <Form>
          <Box
            title="Job board settings"
            onEditClick={handleEditClick}
            editLinkText="Edit"
            editing={editing}
            errorText={updateError}
            onCancelClick={handleCancel(resetForm)}
            loading={isSubmitting}
            saveAsSubmit
          >
            {editing ? (
              <Field component={FormSelect} name="tabsOrder" label="Tabs order" options={tabsOrderOptions} />
            ) : (
              <BoxRow title="Tabs order">{getTabsOrderString(data.tabsOrder) || '-'}</BoxRow>
            )}

            <BoxRow
              title="Favicon"
              helpText={
                editing ? 'Small icon to represent your job board on browser tabs or similar across the web.' : null
              }
            >
              {values.faviconUrl && (
                <ProfilePicture
                  className="Settings__logo-preview"
                  imageUrl={editing ? values.faviconUrl : data.faviconUrl}
                  variant="square"
                />
              )}
              {editing && (
                <>
                  <UploadImage
                    Picker={PickerOverlay}
                    filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
                    className="Settings__upload-image"
                    imageType="favicon"
                    onSuccess={({ filesFailed, filesUploaded }) => {
                      if (filesFailed.length < 1) {
                        setFieldValue('faviconUrl', filesUploaded[0].url);
                      }
                    }}
                    onClear={() => setFieldValue('faviconUrl', null)}
                    currentValue={values.faviconUrl}
                  />
                </>
              )}
            </BoxRow>

            {!activeNetwork.features.includes('custom_hero') && (
              <>
                <BoxRow title="Hero title">
                  {editing ? <Field type="text" name="heroTitle" component={FormField} /> : data.heroTitle || '-'}
                </BoxRow>

                <BoxRow title="Hero subtitle">
                  {editing ? <Field type="text" name="heroSubtitle" component={FormField} /> : data.heroSubtitle || '-'}
                </BoxRow>

                <BoxRow title="Hero image">
                  <HeroImage
                    editing={editing}
                    heroColorMask={values.heroColorMask}
                    imageUrl={editing ? values.heroImageUrl : data.heroImageUrl}
                    setFieldValue={setFieldValue}
                  />
                </BoxRow>
              </>
            )}

            {status && status.error && <GenericError />}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default Info;
