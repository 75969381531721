import React from 'react';
import { Image, Flex, Text } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import { useHistory } from 'react-router';
import NetworkSchema from '../../schemas/network';
import BlankLayout from '../../components/layout/Blank';
import IconRocket from '../../assets/icon-rocket.svg';

export const Success = ({ network }) => {
  const { slug, contactsLimit } = network;
  const history = useHistory();

  const onAddContactsClick = () => {
    history.push(`/networks/${slug}/import-contacts`);
  };

  return (
    <BlankLayout>
      <Flex flexDirection="column" width={['100%', '500px']} ml="auto" mr="auto" alignItems="flex-start">
        <Image src={IconRocket} width="80px" height="80px" mb={[4]} />
        <Text mb={[2]} color="text.dark" fontSize={[3]} fontWeight="semibold">
          You just unlocked the power of your team&apos;s network
        </Text>
        <Text mb={[3]}>
          Your team&apos;s can now add up to {contactsLimit / 1000}k Contacts, make sure all your team&apos;s contacts
          are added to get more matches.
        </Text>
        <Text mb={[4]}>
          Your customer success representative will be in touch shortly to finalize the payment details.
        </Text>

        <Button
          variant="accent"
          size="md"
          onClick={onAddContactsClick}
          display={['flex', 'inline-flex']}
          justifyContent="center"
          width={['100%', 'auto']}
        >
          Add contacts
        </Button>
      </Flex>
    </BlankLayout>
  );
};

Success.propTypes = {
  network: NetworkSchema.isRequired,
};
