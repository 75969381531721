import React from 'react';
import { Box, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { ContactCardSkeleton } from '../../../../components/organisms/contactCard/skeleton';

export const ContentSkeleton = ({ rows }) => (
  <Box>
    <Text color="text.subtle" fontSize="12px" pb="24px">
      Loading...
    </Text>
    {Array.from(Array(rows).keys()).map((key) => (
      <Box
        key={`skeleton-content-${key}`}
        pb={['16px', '32px']}
        mb={[0, '16px']}
        sx={{ borderBottom: 1, borderBottomColor: 'border.subtle' }}
      >
        <ContactCardSkeleton />
      </Box>
    ))}
  </Box>
);

ContentSkeleton.propTypes = {
  rows: PropTypes.number,
};

ContentSkeleton.defaultProps = {
  rows: 4,
};
