import React from 'react';
import * as Yup from 'yup';

import strings from 'strings';

import { linkedinUrlRegEx } from 'helpers/regExs';
import { setOnboardingComplete } from 'actions/userActions';
import pageHoc from 'hocs/pageHoc';

import RegisterForm from 'components/molecules/RegisterForm';

import Loading from 'components/molecules/LoadingFullPage';
import NoAccess from 'pages/NoAccess';
import { useDispatch, useSelector } from 'react-redux';
import { networksSelector, userSelector } from '../../redux/selectors';

const pageTitle = 'Onboarding';

const networkInfo = (network) => {
  let networkInfoStrings = strings.onboarding.jobBoard;

  if (network.features.includes('talent_network')) {
    networkInfoStrings = strings.onboarding.talentNetwork;
  }

  if (network.connectTier === 'premium') {
    networkInfoStrings = strings.onboarding.connect;
  }

  if (network.isManager) {
    const { header, body } = networkInfoStrings.networkManager;

    return [header.replace('[Network Name]', network.name, false), body];
  }

  if (network.isOrganizationManager) {
    const { header, body } = networkInfoStrings.organizationManager;

    return [
      header.replace('[Network Name]', network.name),
      body.replace('[Org Name]', Object.values(network.isOrganizationManager)[0].name),
    ];
  }

  if (network.isConnector) {
    const { header, body } = networkInfoStrings.ambassador;

    return [header.replace('[Network Name]', network.name, false), body];
  }

  const { header, body } = networkInfoStrings.default;

  return [header.replace('[Network Name]', network.name), body];
};

const handleSubmit = (dispatch) => async (values) => {
  const action = await setOnboardingComplete(values);
  if (action) {
    dispatch(action);
  }
};

const formSchema = (withPassword, isConnect) => {
  const validations = {
    email: Yup.string().email().required(),
    firstName: Yup.string().label('First name').required(),
    lastName: Yup.string().label('Last name').required(),
    termsAccepted: Yup.boolean().oneOf([true], strings.validation.termsAccepted),
  };

  if (!isConnect) {
    validations.linkedinUrl = Yup.string().label('LinkedIn URL').matches(linkedinUrlRegEx, {
      message: strings.validation.linkedinUrlError,
    });
  }

  if (withPassword) {
    validations.password = Yup.string().min(8, strings.validation.passwordLength).required();
  }

  return Yup.object().shape(validations);
};

const Onboarding = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const { networks, active } = useSelector(networksSelector);

  if (!networks || !active) {
    return <Loading />;
  }

  if (!Object.values(networks).some((n) => n.isConnector || n.isManager || n.isOrganizationManager)) {
    return <NoAccess />;
  }

  const [headerText, bodyText] = networkInfo(networks[active]);
  const isConnect = networks[active].features.includes('talent_network');

  const initialValues = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email,
    termsAccepted: false,
  };

  if (!isConnect) {
    initialValues.avatarUrl = user.avatarUrl || '';
    initialValues.linkedinUrl = user.linkedinUrl || '';
  }

  if (user.forcePasswordUpdate) {
    initialValues.password = '';
  }

  const netWorkData = {
    logoUrl: networks[active].logoUrl,
    name: networks[active].name,
  };

  let companyData;

  if (networks && networks[active].isOrganizationManager) {
    companyData = Object.values(networks[active].isOrganizationManager)[0];
  }

  return (
    <div className="Onboarding__container">
      <RegisterForm
        initialValues={initialValues}
        formSchema={formSchema(user.forcePasswordUpdate, isConnect)}
        network={netWorkData}
        onSubmit={handleSubmit(dispatch)}
        company={companyData}
        subtitle={bodyText}
        title={headerText}
        withPassword={user.forcePasswordUpdate}
        isConnect={isConnect}
      />
    </div>
  );
};

export default pageHoc({ title: pageTitle })(Onboarding);
