import React from 'react';
import PropTypes from 'prop-types';

import { useLazyGetTagsQuery } from '../../../services/tags';
import { Search } from '../../atoms/search';
import { Dropdown } from '../dropdown';
import { Card } from '../../atoms/Card';

export const SearchTagsDropdown = ({ trigger, value, onChange, onCreate, ...rest }) => {
  const [getTags, { data, isLoading }] = useLazyGetTagsQuery();

  const onCurrentChange = (tags, opt, checked) => {
    const result = tags.map((tag) => data.tags.find((item) => item.id === tag.value));

    onChange(result, opt, checked);
  };

  return (
    <Dropdown placement="bottom-end" trigger={trigger} onOpen={() => getTags()} {...rest}>
      <Card width="320px" height="300px" p="16px" bg="neutral.0">
        <Search
          isLoading={isLoading}
          options={data?.tags?.map((item) => ({ label: item.tag, value: item.id })) || []}
          value={value}
          onChange={onCurrentChange}
          onCreate={onCreate}
        />
      </Card>
    </Dropdown>
  );
};

SearchTagsDropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
};

SearchTagsDropdown.defaultProps = {
  value: [],
  onCreate: null,
};
