import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { UploadImage } from '@getro/rombo';

import hexToRgba from 'helpers/hexToRgba';
import rgbaGetAlpha from 'helpers/rgbaGetAlpha';
import rgbaToHex from 'helpers/rgbaToHex';

import ColorPicker from 'components/atoms/ColorPicker';
import { PickerOverlay } from 'filestack-react';
import Input from '../../../../../components/atoms/input';
import { Toggle } from '../../../../../components/atoms/toggleDeprecated';

const DEFAULT_OVERLAY_COLOR = '#1B2432';
const DEFAULT_OVERLAY_OPACITY = 40;

const HeroImage = ({ editing, imageUrl, heroColorMask, setFieldValue }) => {
  const [overlayActive, setOverlayActive] = useState(() => !!heroColorMask);
  const [overlayColor, setOverlayColor] = useState(heroColorMask ? rgbaToHex(heroColorMask) : DEFAULT_OVERLAY_COLOR);
  const [overlayOpacity, setOverlayOpacity] = useState(
    heroColorMask ? rgbaGetAlpha(heroColorMask) * 100 : DEFAULT_OVERLAY_OPACITY,
  );
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setOverlayActive(!!heroColorMask);
    if (heroColorMask) {
      setOverlayColor(rgbaToHex(heroColorMask));
      setOverlayOpacity(rgbaGetAlpha(heroColorMask) * 100);
    }
    // eslint-disable-next-line
  }, [heroColorMask])

  const handleOverlayColorChange = (color) => {
    setOverlayColor(color.hex);
  };

  const handleOverlayOpacityChange = (event) => {
    setOverlayOpacity(event.target.value);
  };

  useEffect(() => {
    if (!overlayActive) {
      setFieldValue('heroColorMask', '');
      return;
    }

    setFieldValue('heroColorMask', hexToRgba(overlayColor, overlayOpacity / 100));
    // eslint-disable-next-line
  }, [overlayColor, overlayOpacity, overlayActive])

  const handleCheckboxToggle = (event) => {
    setOverlayActive(event.target.checked);
    if (event.target.checked) {
      if (!overlayColor) {
        setOverlayColor(DEFAULT_OVERLAY_COLOR);
      }
      if (!overlayOpacity) {
        setOverlayOpacity(DEFAULT_OVERLAY_OPACITY);
      }
    }
  };

  return (
    <div className="HeroImage">
      {editing && (
        <div className="HeroImage__edit-controls">
          <UploadImage
            Picker={PickerOverlay}
            filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
            className="HeroImage__upload-image"
            imageType="hero"
            onSuccess={({ filesFailed, filesUploaded }) => {
              if (filesFailed.length < 1) {
                setFieldValue('heroImageUrl', filesUploaded[0].url);
              }
            }}
            onClear={() => setFieldValue('heroImageUrl', null)}
            currentValue={imageUrl}
          />
          <Toggle label="Image overlay" checked={overlayActive} onChange={handleCheckboxToggle} />
          {overlayActive && (
            <div className="HeroImage__overlay-settings">
              <div className="HeroImage__overlay-settings-row">
                <div className="HeroImage__overlay-settings-header">Color:</div>
                <div className="HeroImage__color-preview-wrapper">
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div
                    className="HeroImage__color-preview"
                    onClick={() => {
                      setShowPicker(!showPicker);
                    }}
                    style={{ backgroundColor: overlayColor }}
                  />
                  <ColorPicker
                    className="HeroImage__color-picker"
                    open={showPicker}
                    onClose={() => setShowPicker(false)}
                    onChange={handleOverlayColorChange}
                    color={overlayColor}
                  />
                </div>
              </div>
              <div className="HeroImage__overlay-settings-row">
                <div className="HeroImage__overlay-settings-header">Opacity:</div>
                <Input
                  className="HeroImage__opacity-input"
                  type="number"
                  min="0"
                  max="100"
                  size="sm"
                  value={overlayOpacity}
                  onChange={handleOverlayOpacityChange}
                />
                %
              </div>
            </div>
          )}
        </div>
      )}

      <div className="HeroImage__hero-preview" style={{ backgroundImage: `url(${imageUrl})` }}>
        <div className="HeroImage__hero-overlay" style={{ backgroundColor: heroColorMask }} />
      </div>
    </div>
  );
};

HeroImage.propTypes = {
  editing: PropTypes.bool,
  imageUrl: PropTypes.string,
  heroColorMask: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

HeroImage.defaultProps = {
  editing: false,
  imageUrl: '',
  heroColorMask: '',
};

export default HeroImage;
