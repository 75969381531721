import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from 'rebass/styled-components';
import { Tooltip } from '@getro/rombo';

const HeaderButton = ({ children, tooltip, ...otherProps }) => {
  const { sx = {}, ...otherButtonProps } = otherProps || {};
  return (
    <Tooltip
      sx={{
        bg: 'neutral.900',
        color: 'neutral.0',
        px: '8px',
        py: '4px',
        fontSize: '14px',
        visibility: ['hidden', 'visible'],
      }}
      position="top-start"
      content={<Text>{tooltip}</Text>}
      trigger={
        <Button
          variant="secondary"
          size="small"
          px="16px"
          py="8px"
          sx={{
            lineHeight: '16px',
            fontSize: '14px',
            fontWeight: 500,
            width: ['100%', 'auto', 'auto'],
            ...sx,
          }}
          {...otherButtonProps}
        >
          {children}
        </Button>
      }
    />
  );
};

HeaderButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  tooltip: PropTypes.string,
};

HeaderButton.defaultProps = {
  tooltip: null,
};

export default HeaderButton;
