import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { activeNetworkSelector, hasContactsSelector, hasMatchesSelector, userSelector } from '../redux/selectors';

export const useCommandBar = () => {
  const routerHistory = useHistory();
  const activeNetwork = useSelector(activeNetworkSelector);
  const hasContacts = useSelector(hasContactsSelector);
  const hasMatches = useSelector(hasMatchesSelector);
  const user = useSelector(userSelector);
  const routerFunc = (newUrl) => routerHistory.push(newUrl);
  const { pathname } = useLocation();

  useEffect(() => {
    window.CommandBar?.boot();
    window.CommandBar?.addRouter(routerFunc);

    return () => {
      window.CommandBar?.shutdown();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.id) {
      window.CommandBar?.boot(`${user.id}`);
    }
  }, [user.id]);

  useEffect(() => {
    if (activeNetwork && pathname.startsWith(`/networks/${activeNetwork.slug}`)) {
      window.CommandBar?.addMetadata('currentNetworkSlug', activeNetwork.slug);
      window.CommandBar?.addMetadata('currentNetworkName', activeNetwork.name);
      window.CommandBar?.addMetadata('hasConnect', hasContacts);
      window.CommandBar?.addMetadata('hasMatches', hasMatches);
      window.CommandBar?.addMetadata(
        'hasConnectFreemium',
        !!activeNetwork.features?.includes('tn_onboarding_v2') && !activeNetwork.features?.includes('add_contacts'),
      );
    }
  }, [activeNetwork, hasContacts, hasMatches, pathname]);
};
