import React, { useState, useEffect, useMemo } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Button, Modal } from '@getro/rombo';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useFocusTrapState } from 'hooks/useFocusTrapState';
import { FilterButton } from 'components/atoms/FilterButton';
import { useDisableRootOnMobileScroll } from 'hooks/useDisableRootOnMobileScroll';
import KeywordFilter from './keywordFilter';
import CompanyFilter from './companyFilter';
import SourceFilter from './sourceFilter';
import StatusFilter from './statusFilter';
import VisibilityFilter from './visibilityFilter';
import FeaturedFilter from './featuredFilter';
import ExpirationFilter from './expirationFilter';
import RemoteFilter from './remoteFilter';
import { qsParse } from '../../../helpers/queryString';
import OrganizationSchema from '../../../schemas/organization';
import useJobs from '../../../hooks/useJobs';
import LocationsFilter from './locationsFilter';
import PostedJobsFilter from './postedJobsFilter';

const AllFiltersModal = ({ hideCompanyFilter, company }) => {
  const {
    jobsFilters,
    filterOrSortJobs,
    sourceOptions,
    statusOptions,
    visibilityOptions,
    featuredOptions,
    expirationOptions,
  } = useJobs({ forCompany: company });
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const initSearch = qsParse(location).q || '';
  const [search, setSearch] = useState(initSearch);
  const [filters, setFilters] = useState(jobsFilters || {});
  const { pauseFocusTrap, onDropDownStateChange } = useFocusTrapState();
  useDisableRootOnMobileScroll(isOpen);

  useEffect(() => {
    setFilters(jobsFilters);
  }, [jobsFilters]);

  useEffect(() => {
    setSearch(qsParse(location).q || '');
  }, [location]);

  const onClearFilters = () => {
    filterOrSortJobs({ search: company ? { companyId: company.id } : {} });
    setIsOpen(false);
  };

  const onApplyFilters = () => {
    const { locations, remote, ...restFilters } = filters;

    const activeFilters = { ...restFilters };
    const q = (search || '').trim();

    if (q !== '') {
      activeFilters.q = q;
    }

    const filterLocations = locations || [];

    if (remote) {
      filterLocations.push({ value: 'remote', label: 'Remote' });
    }

    if (filterLocations.length) {
      activeFilters.locations = btoa(JSON.stringify(filterLocations));
    }

    filterOrSortJobs({
      search: {
        ...activeFilters,
      },
    });
    setIsOpen(false);
  };

  const filterCount = useMemo(() => {
    const currentFilters = Object.fromEntries(new URLSearchParams(location.search));
    return Object.keys(currentFilters)
      .filter((item) => !['q', 'sortBy', 'sortDirection', 'page'].includes(item))
      .filter((item) => currentFilters[item]).length;
  }, [location.search]);

  return (
    <Flex
      width={['100%', 'initial']}
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        '& > div:first-of-type': {
          width: ['100vw', '100%'],
          height: ['100dvh', '100%'],

          '[data-testid="body"]': {
            margin: 0,
            p: '24px',
          },
        },
      }}
    >
      <Modal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        data-testid="jobs-all-filters-modal"
        variant="large"
        title="All filters"
        pauseFocusTrap={pauseFocusTrap}
        actions={
          <Flex flexDirection="row" justifyContent="flex-end">
            <Button mr={1} fontSize={1} variant="secondary" onClick={onClearFilters}>
              Clear filters
            </Button>
            <Button ml={1} fontSize={1} fontWeight="semibold" onClick={onApplyFilters}>
              View results
            </Button>
          </Flex>
        }
      >
        <Flex flexDirection="column" sx={{ gap: [3] }}>
          <Flex flexDirection="row" width={[1]}>
            <KeywordFilter
              onChange={(e) => {
                const { value } = e.target;
                setSearch(value);
              }}
              value={search}
            />
          </Flex>
          <Flex flexDirection={['column', 'row']} width={[1]} sx={{ gap: [3] }}>
            {!hideCompanyFilter && (
              <Box width={[1, 1 / 2]}>
                <CompanyFilter
                  onChange={({ value: companyId }) => setFilters({ ...filters, companyId: companyId.toString() })}
                  value={filters.companyId}
                  embeddedInModal
                  onDropDownStateChange={onDropDownStateChange}
                />
              </Box>
            )}
            <Box width={[1, 1 / 2]}>
              <ExpirationFilter
                value={filters.expirationWithin || ''}
                onChange={({ value: expirationWithin }) => setFilters({ ...filters, expirationWithin })}
                expirationOptions={expirationOptions}
                embeddedInModal
                onDropDownStateChange={onDropDownStateChange}
              />
            </Box>
            {hideCompanyFilter && <Box width={[1 / 2]} />}
          </Flex>
          <Flex flexDirection={['column', 'row']} width={[1]} sx={{ gap: [3] }}>
            <Box width={[1, 1 / 2]}>
              <LocationsFilter
                onChange={({ target: { value } }) => {
                  setFilters((currentFilters) => {
                    const newFilters = { ...currentFilters };
                    newFilters.locations = value;
                    newFilters.locationIds = value.map((item) => item.value);
                    return newFilters;
                  });
                }}
                value={filters.locations}
                embeddedInModal
                onDropDownStateChange={onDropDownStateChange}
              />
            </Box>
            <Box width={[1, 1 / 2]}>
              <RemoteFilter
                value={filters.remote}
                onChange={({ value: remote }) => setFilters({ ...filters, remote })}
                embeddedInModal
                onDropDownStateChange={onDropDownStateChange}
              />
            </Box>
          </Flex>
          <Flex flexDirection={['column', 'row']} width={[1]} sx={{ gap: [3] }}>
            <Box width={[1, 1 / 2]}>
              <SourceFilter
                onChange={({ value: source }) => setFilters({ ...filters, source })}
                sourceOptions={sourceOptions}
                value={filters.source || ''}
                embeddedInModal
                onDropDownStateChange={onDropDownStateChange}
              />
            </Box>
            <Box width={[1, 1 / 2]}>
              <StatusFilter
                onChange={({ value: status }) => setFilters({ ...filters, status })}
                statusOptions={statusOptions}
                value={filters.status || ''}
                embeddedInModal
                onDropDownStateChange={onDropDownStateChange}
              />
            </Box>
          </Flex>
          <Flex flexDirection={['column', 'row']} width={[1]} sx={{ gap: [3] }}>
            <Box width={[1, 1 / 2]}>
              <VisibilityFilter
                value={filters.visibility || ''}
                onChange={({ value: visibility }) => setFilters({ ...filters, visibility })}
                visibilityOptions={visibilityOptions}
                embeddedInModal
                onDropDownStateChange={onDropDownStateChange}
              />
            </Box>
            <Box width={[1, 1 / 2]}>
              <FeaturedFilter
                value={filters.featured || ''}
                onChange={({ value: featured }) => setFilters({ ...filters, featured })}
                featuredOptions={featuredOptions}
                embeddedInModal
                onDropDownStateChange={onDropDownStateChange}
              />
            </Box>
          </Flex>
          <Flex flexDirection={['column', 'row']} width={[1]} sx={{ gap: [3] }}>
            <Box width={[1, 1 / 2]}>
              <PostedJobsFilter
                onChange={(addedAfter) => setFilters({ ...filters, addedAfter })}
                label="Added after"
                date={filters.addedAfter}
              />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PostedJobsFilter
                onChange={(addedBefore) => setFilters({ ...filters, addedBefore })}
                label="Added before"
                date={filters.addedBefore}
              />
            </Box>
          </Flex>
        </Flex>
      </Modal>

      <FilterButton
        filterCount={filterCount}
        onAllFiltersClicked={() => setIsOpen(true)}
        onClick={() => setIsOpen(true)}
      />
    </Flex>
  );
};

AllFiltersModal.propTypes = {
  hideCompanyFilter: PropTypes.bool,
  company: OrganizationSchema,
};

AllFiltersModal.defaultProps = {
  hideCompanyFilter: false,
  company: null,
};

export default AllFiltersModal;
