import PropTypes from 'prop-types';
import GroupSchema from './group';
import UserSchema from './user';
import OrganizationSchema from './organization';

const MemberSchema = PropTypes.shape({
  active: PropTypes.number,
  avatarUrl: PropTypes.string,
  bio: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  githubUrl: PropTypes.string,
  id: PropTypes.number.isRequired,
  jobFunctions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  jobSearchStatus: PropTypes.string,
  lastName: PropTypes.string,
  linkedinUrl: PropTypes.string,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  requireSponsorshipVisa: PropTypes.bool,
  resumeUrl: PropTypes.string,
  talentGroups: PropTypes.arrayOf(GroupSchema),
  twitterUrl: PropTypes.string,
  usWorkAuthorization: PropTypes.bool,
  verified: PropTypes.bool,
  websiteUrl: PropTypes.string,
  dribbbleUrl: PropTypes.string,
  willWorkAnywhere: PropTypes.bool,
  sharePermission: PropTypes.bool,
  userTalentCollection: PropTypes.shape({
    id: PropTypes.number.isRequired,
    collectionId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    coverLetter: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    questionnaire: PropTypes.string,
    share_permission: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    verified: PropTypes.bool.isRequired,
    verifiedBy: UserSchema,
    verifiedAt: PropTypes.string,
    visibility: PropTypes.string,
    hideFromDomains: PropTypes.arrayOf(PropTypes.string),
    hideFromCompanies: PropTypes.arrayOf(OrganizationSchema),
  }),
  introduction: PropTypes.shape({
    createdAt: PropTypes.string,
  }),
});

export default MemberSchema;
