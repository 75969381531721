import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, OnboardingLayoutContent } from '@getro/rombo';
import { Flex, Box } from 'rebass/styled-components';
import isEqual from 'lodash.isequal';
import { Plus } from 'lucide-react';
import { post } from '../../../api';
import { trackException } from '../../../helpers/errorTracker';
import OnboardingContext from '../../../components/layout/EmployerOnboarding/onboardingContext';
import { AdminList } from '../../../components/molecules/adminList';
import { InviteAdminModal } from '../../../components/organisms/adminPanel/inviteAdminModal';
import { usePrevious } from '../../../hooks/usePrevious';

const handleSubmit = (step, updateStep) => () => updateStep(step + 1);

const Managers = ({ title, onBack }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const formRef = useRef(null);

  const { organization, updateOrganization, network, updateStep, step } = useContext(OnboardingContext);
  const isTalentNetworkActive = network.features.includes('talent_network');

  const addAdmin = async ({ firstName, lastName, email, customMessage }) => {
    try {
      const response = await post(`/organizations/${organization.id}/poc?collection_id=${network.id}`, {
        firstName,
        lastName,
        email,
        customMessage,
        sendInvitation: true,
      });

      if (response.status === 200) {
        updateOrganization({
          ...organization,
          managers: [
            ...(organization.managers || []),
            {
              avatarUrl: response.data.avatarUrl,
              email: response.data.email,
              firstName: response.data.firstName,
              id: response.data.id,
              lastName: response.data.lastName,
              onboardingCompleted: response.data.onboardingCompleted,
            },
          ],
        });
      }
    } catch (error) {
      trackException(error);
    }
  };

  const resendInvitation = async (userId) => {
    try {
      await post(`/users/${userId}/invitation_email/organization_manager`, {
        collectionId: network.id,
        organizationId: organization.id,
      });

      updateOrganization({
        ...organization,
        managers: organization.managers.map((m) => {
          if (m.id === userId) {
            return {
              ...m,
              invitationSent: true,
            };
          }

          return m;
        }),
      });
    } catch (error) {
      trackException(error);
    }
  };

  const previousCompanyManagers = usePrevious(organization.managers);
  useEffect(() => {
    if (!isEqual(organization.managers, previousCompanyManagers)) {
      setModalOpen(false);
    }
  }, [organization.managers, previousCompanyManagers]);

  return (
    <OnboardingLayoutContent title={title} onBack={onBack} onNextStep={handleSubmit(step, updateStep)}>
      <p>
        {isTalentNetworkActive
          ? 'Company admins are able to edit company information, post jobs, and get introduced to top talent. Invite team members responsible for hiring.'
          : 'Company admins are able to edit company information and post jobs. Invite team members responsible for hiring.'}
      </p>

      <>
        {organization.managers.length > 0 && (
          <Flex flexDirection="row-reverse">
            <Button size="small" variant="secondary" onClick={() => setModalOpen(true)}>
              <Box mr={1} height="1em" width="1em" strokeWidth="1.5" aria-hidden="true" as={Plus} />
              Invite admin
            </Button>
          </Flex>
        )}
        <AdminList
          users={organization.managers}
          onResendInvitation={resendInvitation}
          companyName={organization.name}
          onEmptyListClick={() => {
            setModalOpen(true);
          }}
          isTalentNetworkActive={isTalentNetworkActive}
          showEmailAddress={false}
        />
        <InviteAdminModal
          isSubmitting={false}
          onAdminInvite={addAdmin}
          companyName={organization.name}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          formRef={formRef}
        />
      </>
    </OnboardingLayoutContent>
  );
};

Managers.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.string.isRequired,
};

Managers.defaultProps = {
  onBack: () => {},
};

export default Managers;
