import React from 'react';
import { ProfilePicture } from '@getro/rombo';
import moment from 'moment';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { trackEvent } from '../../../helpers/analytics';
import { sortPositionsByCompany } from '../../../services/contacts/postitions';
import { ContactAvatar } from '../../molecules/contactAvatar';
import { ContactActionPanel } from '../contactActionPanel';
import { MatchInfo } from './matchInfo';
import { CompanyInfo } from './companyInfo';
import { ContactInfoWorkDate } from './contactInfoWorkDate';
import { EnrichmentButton } from './enrichmentButton';

export const ContactInfo = ({
  queryParams,
  contact,
  type,
  page,
  match,
  matchListInfo,
  scoreDetails,
  isTalentNetworkEnabled,
  list,
  setCurrentNote,
  setShowNotes,
  setNotesEditMode,
  setShowDeleteModal,
  onClickHighlight,
  deleteContactFromList,
  fullContactView,
  addedToSharedList,
  trackPageEvent,
  networkId,
}) => {
  const pastPositions = sortPositionsByCompany(contact.contactWorkExperiences);

  const onViewNote = (note) => {
    setCurrentNote(note);
    setShowNotes(true);

    trackEvent('contacts:notes:view', {
      origin: 'full_profile',
      page: 'admin_portal',
    });
  };

  const onCreateNote = () => {
    setNotesEditMode(true);
    setShowNotes(true);

    trackEvent('contacts:notes:add', {
      origin: 'full_profile',
    });
  };

  const onEditNote = (note) => {
    setCurrentNote(note);
    setNotesEditMode(true);
    setShowNotes(true);

    trackEvent('contacts:notes:edit', {
      origin: 'full_profile',
    });
  };

  return (
    <>
      <Flex flexGrow={[0, 0, 1]} flexDirection="column">
        <ContactAvatar
          onClickHighlight={onClickHighlight}
          variant="large"
          type={type}
          contact={contact}
          highlighted={match.highlighted}
          trackLinkedinClick={() => trackPageEvent('contacts:view_linkedin_profile')}
        />
        {(contact.enrichmentStatus === 'enriched' || type === 'public') && pastPositions.length > 0 && (
          <>
            {type !== 'public' && (isTalentNetworkEnabled || contact.signupInfo) && (
              <>
                <Text
                  mt="40px"
                  fontSize="0"
                  fontWeight="medium"
                  color="text.subtle"
                  sx={{ textTransform: 'uppercase', letterSpacing: '1px' }}
                >
                  Preferences
                </Text>
                <Box>
                  {contact.signupInfo ? (
                    <Box mt="8px">
                      {contact.signupInfo.jobFunctions?.length ||
                      contact.signupInfo.seniorities?.length ||
                      contact.signupInfo.topics?.length ? (
                        <Box>
                          <Text as="span" fontSize="1" lineHeight="20px">
                            {contact.signupInfo.jobFunctions?.join(', ')}
                            {contact.signupInfo.jobFunctions?.length && contact.signupInfo.seniorities?.length
                              ? ` · `
                              : ''}
                            {contact.signupInfo.seniorities?.join(', ')}
                            {contact.signupInfo.seniorities?.length && contact.signupInfo.topics?.length ? ` · ` : ''}
                            {contact.signupInfo.topics?.length ? (
                              <Text as="span" sx={{ textTransform: 'capitalize' }}>
                                {contact.signupInfo.topics
                                  .reduce((carry, item) => {
                                    carry.push(item.join(', '));

                                    return carry;
                                  }, [])
                                  .join(' · ')}
                              </Text>
                            ) : null}
                          </Text>
                        </Box>
                      ) : null}
                      {contact.signupInfo.workEnvironments?.length || contact.signupInfo.locationPreferences?.length ? (
                        <Box>
                          <Text as="span" fontSize="1" lineHeight="20px">
                            {contact.signupInfo.workEnvironments?.join(', ')}
                            {contact.signupInfo.workEnvironments?.length &&
                              contact.signupInfo.locationPreferences?.length &&
                              ` · `}
                            {contact.signupInfo.locationPreferences?.join('; ')}
                          </Text>
                        </Box>
                      ) : null}
                      {contact.signupInfo.usVisa && (
                        <Box>
                          <Text as="span" fontSize="1" lineHeight="20px">
                            {contact.signupInfo.usVisa}
                          </Text>
                        </Box>
                      )}
                      {contact.signupInfo.jobSearchStatus ? (
                        <Box>
                          <Text
                            fontSize="14px"
                            fontWeight={contact.signupInfo.jobSearchStatus === 'Not looking' ? 400 : 500}
                            color={contact.signupInfo.jobSearchStatus === 'Not looking' ? 'text.subtle' : 'success'}
                          >
                            {contact.signupInfo.jobSearchStatus}
                          </Text>
                        </Box>
                      ) : null}
                      {contact.signupInfo.signupDate && (
                        <Box>
                          <Text as="span" fontSize="12px" color="text.subtle">
                            {`Signed up on ${moment(contact.signupInfo.signupDate).format('MMM DD, YYYY')}`}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Text mt="8px" fontSize="1" color="text.subtle" fontStyle="italic">
                      Has not signed up
                    </Text>
                  )}
                </Box>
              </>
            )}
            <Text
              mt="40px"
              fontSize="0"
              fontWeight="medium"
              color="text.subtle"
              sx={{ textTransform: 'uppercase', letterSpacing: '1px' }}
            >
              Experience
            </Text>
            {pastPositions?.length ? (
              <Flex mt="16px" flexDirection="column" sx={{ gap: '32px' }}>
                {pastPositions.map((companyPositions) => (
                  <Flex
                    key={`past-experience-dv-${companyPositions[0].companyName}-${companyPositions[0].companyLinkedinUrl}`}
                  >
                    <Box mr="2" flex="0 0 auto" width="32px" height="32px">
                      {companyPositions[0].companyLinkedinUrl ? (
                        <Link
                          href={companyPositions[0].companyLinkedinUrl}
                          sx={{ textDecoration: 'none', ':hover': { textDecoration: 'underline' } }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ProfilePicture
                            name={companyPositions[0].companyName || ''}
                            variant="square"
                            imageUrl={companyPositions[0].logoUrl}
                          />
                        </Link>
                      ) : (
                        <ProfilePicture
                          name={companyPositions[0].companyName || ''}
                          variant="square"
                          imageUrl={companyPositions[0].logoUrl}
                        />
                      )}
                    </Box>
                    {companyPositions.length === 1 && (
                      <Box>
                        <Text fontSize="14px" fontWeight="medium" color="text.dark">
                          {companyPositions[0].jobTitle}
                        </Text>
                        <CompanyInfo position={companyPositions[0]} />
                        {companyPositions[0].dateFrom && (
                          <ContactInfoWorkDate mt="4px" position={companyPositions[0]} />
                        )}
                      </Box>
                    )}
                    {companyPositions.length !== 1 && (
                      <Box>
                        <Flex alignItems="center" sx={{ gap: '8px' }}>
                          <CompanyInfo position={companyPositions[0]} />
                        </Flex>
                        {companyPositions.map((pos, index) => (
                          <Box
                            mt={index === 0 ? '8px' : '4px'}
                            key={`past-experience-dv-${companyPositions[0].companyName}-${pos.jobTitle}`}
                          >
                            <Text fontSize="1" fontWeight="medium" color="text.dark">
                              {pos.jobTitle}
                            </Text>
                            {pos.dateFrom && <ContactInfoWorkDate position={pos} />}
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Flex>
                ))}
              </Flex>
            ) : (
              <Text mt="3" fontSize="1" color="text.subtle" fontStyle="italic">
                No experience
              </Text>
            )}
            <Text
              mt="40px"
              fontSize="0"
              fontWeight="medium"
              color="text.subtle"
              sx={{ textTransform: 'uppercase', letterSpacing: '1px' }}
            >
              Skills
            </Text>
            {contact.skills?.length ? (
              <Text mt="3" fontSize="1" lineHeight="24px" sx={{ textTransform: 'capitalize' }}>
                {contact.skills.join(', ')}
              </Text>
            ) : (
              <Text mt="3" fontSize="1" color="text.subtle" fontStyle="italic">
                No skills
              </Text>
            )}
          </>
        )}
        {(contact.enrichmentStatus !== 'enriched' || pastPositions?.length === 0) && type !== 'public' && (
          <EnrichmentButton contact={contact} networkId={networkId} isList={Boolean(list?.id)} isContactDetail />
        )}
      </Flex>
      {['default', 'matches', 'public'].includes(type) && (
        <Box
          flex="0 0 auto"
          ml={[0, 0, '24px']}
          mt={['24px', 0, 0]}
          pl={[0, 0, '24px']}
          width={['100%', '100%', '320px']}
          sx={{ borderLeftWidth: [0, 0, 1], borderLeftStyle: 'solid', borderLeftColor: 'neutral.100' }}
        >
          {type === 'matches' && (
            <MatchInfo
              match={match}
              matchListInfo={matchListInfo}
              scoreDetails={scoreDetails}
              onClickHighlight={onClickHighlight}
            />
          )}
          <ContactActionPanel
            type={type}
            page={page}
            queryParams={queryParams}
            contact={contact}
            matchId={match?.id}
            list={list}
            actions={
              type === 'public'
                ? ['show-importers', 'notes-extended']
                : ['add-importer', 'lists', 'tags', 'notes-extended']
            }
            extendMethods={{
              ...(type !== 'public'
                ? {
                    'notes-extended': {
                      onClickAddMore: onCreateNote,
                      onCreateNote,
                      onViewNote,
                      onEditNote,
                      setCurrentNote,
                      setShowDeleteModal,
                    },
                  }
                : { 'notes-extended': { onViewNote } }),
            }}
            showMoreOptions
            deleteContactFromList={deleteContactFromList}
            fullContactView={fullContactView}
            addedToSharedList={addedToSharedList}
          />
        </Box>
      )}
    </>
  );
};

ContactInfo.propTypes = {
  queryParams: PropTypes.object,
  contact: PropTypes.object,
  type: PropTypes.oneOf(['default', 'read', 'matches', 'public']),
  page: PropTypes.string,
  match: PropTypes.object,
  matchListInfo: PropTypes.object,
  scoreDetails: PropTypes.object,
  isTalentNetworkEnabled: PropTypes.bool,
  list: PropTypes.object,
  setCurrentNote: PropTypes.func.isRequired,
  setShowNotes: PropTypes.func.isRequired,
  setNotesEditMode: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
  onClickHighlight: PropTypes.func,
  deleteContactFromList: PropTypes.bool,
  fullContactView: PropTypes.bool,
  addedToSharedList: PropTypes.bool,
  trackPageEvent: PropTypes.func.isRequired,
  networkId: PropTypes.string.isRequired,
};

ContactInfo.defaultProps = {
  queryParams: {},
  contact: {},
  type: 'default',
  page: '',
  match: {},
  matchListInfo: {},
  scoreDetails: {},
  isTalentNetworkEnabled: false,
  list: null,
  onClickHighlight: null,
  deleteContactFromList: false,
  fullContactView: false,
  addedToSharedList: false,
};
