import strings from 'strings';

export const METRICS = [
  {
    value: 'company_profile_views',
    label: strings.analytics.metrics.company_profile_views.label,
    tooltipText: strings.analytics.metrics.company_profile_views.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'trend',
    product: 'jobBoard',
  },
  {
    value: 'jobs_clicks',
    label: strings.analytics.metrics.jobs_clicks.label,
    tooltipText: strings.analytics.metrics.jobs_clicks.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'trend',
    product: 'jobBoard',
  },
  {
    value: 'new_jobs',
    label: strings.analytics.metrics.new_jobs.label,
    tooltipText: strings.analytics.metrics.new_jobs.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'trend',
    product: 'jobBoard',
  },
  {
    value: 'removed_jobs',
    label: strings.analytics.metrics.removed_jobs.label,
    tooltipText: strings.analytics.metrics.removed_jobs.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'trend',
    product: 'jobBoard',
  },
  {
    value: 'total_jobs',
    label: strings.analytics.metrics.total_jobs.label,
    tooltipText: strings.analytics.metrics.total_jobs.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'trend',
    product: 'jobBoard',
  },
  {
    value: 'unique_visitors',
    label: strings.analytics.metrics.page_visits.label,
    tooltipText: strings.analytics.metrics.page_visits.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'trend',
    product: 'jobBoard',
  },
  {
    value: 'companies_performance',
    label: strings.analytics.metrics.companies_performance.label,
    tooltipText: strings.analytics.metrics.companies_performance.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'detailed',
    hasChartView: false,
    disableInterval: true,
    showRowLimit: true,
    product: 'jobBoard',
  },
  {
    value: 'job_functions_posted',
    label: strings.analytics.metrics.job_functions_posted.label,
    tooltipText: strings.analytics.metrics.job_functions_posted.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'detailed',
    hasChartView: false,
    disableInterval: true,
    showRowLimit: true,
    product: 'jobBoard',
  },
  {
    value: 'job_functions_searched',
    label: strings.analytics.metrics.job_functions_searched.label,
    tooltipText: strings.analytics.metrics.job_functions_searched.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'detailed',
    hasChartView: false,
    disableInterval: true,
    showRowLimit: true,
    product: 'jobBoard',
  },
  {
    value: 'jobs_performance',
    label: strings.analytics.metrics.jobs_performance.label,
    tooltipText: strings.analytics.metrics.jobs_performance.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'detailed',
    hasChartView: false,
    disableInterval: true,
    showRowLimit: true,
    product: 'jobBoard',
  },
  {
    value: 'locations_posted',
    label: strings.analytics.metrics.locations_posted.label,
    tooltipText: strings.analytics.metrics.locations_posted.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'detailed',
    hasChartView: false,
    disableInterval: true,
    showRowLimit: true,
    product: 'jobBoard',
  },
  {
    value: 'locations_searched',
    label: strings.analytics.metrics.locations_searched.label,
    tooltipText: strings.analytics.metrics.locations_searched.description,
    fetchEndpoint: '/analytics/job_board',
    downloadEndpoint: '/analytics/job_board/download',
    reportType: 'detailed',
    hasChartView: false,
    disableInterval: true,
    showRowLimit: true,
    product: 'jobBoard',
  },
  {
    value: 'introductions_accepted',
    label: strings.analytics.metrics.introductions_accepted.label,
    tooltipText: strings.analytics.metrics.introductions_accepted.description,
    fetchEndpoint: '/analytics/talent_network',
    downloadEndpoint: '/analytics/talent_network/download',
    reportType: 'trend',
    product: 'talentNetwork',
  },
  {
    value: 'introductions_requested',
    label: strings.analytics.metrics.introductions_requested.label,
    tooltipText: strings.analytics.metrics.introductions_requested.description,
    fetchEndpoint: '/analytics/talent_network',
    downloadEndpoint: '/analytics/talent_network/download',
    reportType: 'trend',
    product: 'talentNetwork',
  },
  {
    value: 'introductions_performance',
    label: strings.analytics.metrics.introductions_performance.label,
    tooltipText: strings.analytics.metrics.introductions_performance.description,
    fetchEndpoint: '/analytics/talent_network',
    downloadEndpoint: '/analytics/talent_network/download',
    reportType: 'detailed',
    hasChartView: false,
    disableInterval: true,
    showRowLimit: true,
    product: 'talentNetwork',
  },
];

export const INTERVALS = [
  { value: 'daily', label: strings.analytics.intervals.daily },
  { value: 'weekly', label: strings.analytics.intervals.weekly },
  { value: 'monthly', label: strings.analytics.intervals.monthly },
];
