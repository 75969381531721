import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SuggestCompaniesTable from '../SuggestedCompaniesTable';
import SkipSuggestedModal from '../SuggestedCompaniesTable/skipSuggestedModal';

const SuggestedCompanies = ({
  hasInitialized,
  hasInitializedError,
  isLoadingCompanies,
  companies,
  onSkip,
  onAdd,
  loadMore,
  total,
  skippingIds,
  addingIds,
}) => {
  const [skipCompanySelected, setSkipCompany] = useState(false);

  return (
    <>
      {skipCompanySelected && (
        <SkipSuggestedModal
          companyName={skipCompanySelected.name}
          companyId={skipCompanySelected.id}
          onSkip={onSkip}
          close={() => setSkipCompany(null)}
        />
      )}
      <SuggestCompaniesTable
        onAdd={onAdd}
        hasInitialized={hasInitialized}
        hasInitializedError={hasInitializedError}
        loadMore={loadMore}
        loading={isLoadingCompanies}
        companies={companies}
        total={total}
        skippingIds={skippingIds}
        addingIds={addingIds}
        onSkip={setSkipCompany}
      />
    </>
  );
};

SuggestedCompanies.propTypes = {
  hasInitialized: PropTypes.bool.isRequired,
  hasInitializedError: PropTypes.bool.isRequired,
  isLoadingCompanies: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSkip: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  addingIds: PropTypes.array.isRequired,
  skippingIds: PropTypes.array.isRequired,
};

export default SuggestedCompanies;
