import React, { useRef, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { Box, FormSelect, FormTextarea } from '@getro/rombo';

import strings from 'strings';

const formSchema = Yup.object().shape({
  relationshipText: Yup.string()
    // eslint-disable-next-line
    .min(60, 'Please write at least ${min} characters.')
    .required(),
  relationshipLevel: Yup.object().required('Must select one relationship'),
});

const Vouch = ({
  inviteMemberInfo,
  inviteMemberVouch,
  submitting,
  onNavigateBack,
  onInviteMember,
  onVouchMember,
  hasError,
}) => {
  const formRef = useRef(null);
  const { verified: vouchRequired, firstName } = inviteMemberInfo;

  useEffect(() => {
    if (formRef.current && hasError) {
      formRef.current.setStatus({ error: strings.genericError });
    }

    if (formRef.current && !hasError) {
      formRef.current.setStatus({ error: false });
    }
  }, [hasError]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setSubmitting(submitting);
    }
  }, [submitting]);

  const handleSubmit = (values) => {
    onVouchMember(values);
  };

  const handleBackClick = (values) => () => onNavigateBack(values);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={inviteMemberVouch}
      onSubmit={handleSubmit}
      validationSchema={formSchema}
    >
      {({ values, status }) => (
        <Form data-testid="invite-member-vouch-form">
          <Box
            title={`Vouch for ${firstName}`}
            className="InviteMember"
            editing
            onBackClick={handleBackClick(values)}
            onCancelClick={vouchRequired ? null : onInviteMember}
            cancelText={vouchRequired ? null : 'Invite'}
            saveAsSubmit
            saveText="Vouch and invite"
            loading={submitting}
            errorText={status && status.error ? status.error : ''}
          >
            <p>
              Tell us how you know this person and why they are awesome. Your vouch – or mini-referral – will give other
              members of the network additional context on how you’re connected.
            </p>

            <p className="InviteMember__vouch-tip">
              {vouchRequired
                ? 'This is required when you are inviting a member who can request introductions.'
                : 'This is optional when you are inviting a member who can not request introductions.'}
            </p>

            <Field
              component={FormSelect}
              name="relationshipLevel"
              label="How do you know each other?"
              placeholder="Select one option"
              options={Object.entries(strings.vouch.relationshipLevelOptions).map(([k, v]) => ({
                value: k,
                label: v.replace('[Member Name]', firstName || 'this person'),
              }))}
              containerProps={{ mt: 4 }}
            />

            <Field
              component={FormTextarea}
              label="Tell us more"
              helpText="Give 1-2 sentences of additional context that we can share with a employer in the network."
              name="relationshipText"
              placeholder={strings.vouch.relationshipTextPlaceholder.replace('[Member Name]', firstName)}
              containerProps={{ mt: 4 }}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

Vouch.propTypes = {
  inviteMemberInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    linkedinUrl: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    verified: PropTypes.bool,
    company: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  }).isRequired,
  inviteMemberVouch: PropTypes.shape({
    relationshipLevel: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    relationshipText: PropTypes.string,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
  onInviteMember: PropTypes.func.isRequired,
  onVouchMember: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default Vouch;
