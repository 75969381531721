const ORGANIZATION_FUND_TYPES = {
  pre_seed: 'Pre-seed',
  seed: 'Seed',
  series_a: 'Series A',
  series_b: 'Series B',
  series_c_plus: 'Series C+',
  private_equity: 'Private Equity',
  ipo: 'IPO',
  other: 'Other',
};

export const mapCompanyFundType = (filter) => ORGANIZATION_FUND_TYPES[filter] || 'Other';

export const getCompanyFundTypeFormOptions = () =>
  Object.keys(ORGANIZATION_FUND_TYPES).map((key) => ({ label: ORGANIZATION_FUND_TYPES[key], value: key }));
