import React from 'react';
import PropTypes from 'prop-types';
import { Tag, ProfilePicture } from '@getro/rombo';
import { Box } from 'rebass/styled-components';

const EmployerDetails = ({ employer }) => {
  const { logoUrl, name, locations, topics } = employer;

  return (
    <div className="Employer__main" data-testid="employer-details">
      <div className="Employer__header">
        <Box
          sx={{
            flex: 0,
            flexBasis: '64px',
            '@media (min-width: 768px)': {
              width: '80px',
              height: '80px',
              position: 'absolute',
              left: '8px',
              top: '8px',
            },
          }}
        >
          <ProfilePicture imageUrl={logoUrl} name={name} variant="square" />
        </Box>

        <div className="Employer__header-main">
          <div className="Employer__name" data-cy="companies-list-company-name">
            {name}
          </div>
          {locations && !!locations.length && (
            <div className="Employer__locations">{locations.map((l) => l.name).join(' · ')}</div>
          )}
        </div>
      </div>
      {topics && !!topics.length && (
        <div className="Employer__topics">
          {topics.map((t) => (
            <Tag key={t.id} my="1">
              {t.name}
            </Tag>
          ))}
        </div>
      )}
    </div>
  );
};

EmployerDetails.propTypes = {
  employer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default EmployerDetails;
