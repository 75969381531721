import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text, Link } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import strings from '../../../strings';
import BlankMessage from '../../../components/molecules/BlankMessage';

/*
There are three possible render states that can be used here.
Automatic - At least automatic on JS with status: manual, brand new, requires verification or errored
Manual - No JS with statuses above, at least one with: bypassed or unidentifiable
Missing: - There are no job sources provided
*/

const STATES = {
  automatic: ['automatic', 'manual', 'brand_new', 'requires_verification', 'errored'],
  missing: ['bypassed', 'unidentifiable'],
  manual: null,
};

export const JobSourceSummary = ({ organizationCareersPages, ocpSearchStatus, suggestJobSourceLink }) => {
  const status = useMemo(() => {
    let result = 'missing';

    organizationCareersPages.forEach((source) => {
      if (result === 'missing') {
        if (STATES.automatic.includes(source.scraperStatus)) {
          result = 'automatic';
        } else if (STATES.missing.includes(source.scraperStatus)) {
          result = 'manual';
        } else {
          result = 'missing';
        }
      }
    });
    return result;
  }, [organizationCareersPages]);

  if (ocpSearchStatus === strings.ocpSearchStatuses.completed && status === 'automatic') return null;

  return (
    <>
      {ocpSearchStatus === strings.ocpSearchStatuses.in_progress && (
        <BlankMessage title="Our job source experts are actively looking for this company's jobs">
          This usually takes 2 business days to find and configure the jobs for your job board.{' '}
          <a
            href="https://help.getro.com/support/solutions/articles/65000179707-company-job-sources"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
          .
        </BlankMessage>
      )}

      {ocpSearchStatus === strings.ocpSearchStatuses.not_found && (
        <BlankMessage title="Looks like we're having trouble locating this company's job source">
          <>
            <Text>
              Our job source experts will continue to search for these regularly. Remember, you can also post jobs.{' '}
              <a
                href="https://help.getro.com/support/solutions/articles/65000179707-company-job-sources"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
              .
            </Text>
            <Button
              as="a"
              mt={3}
              fontSize={1}
              variant="secondary"
              href={suggestJobSourceLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Suggest job source
            </Button>
          </>
        </BlankMessage>
      )}

      {ocpSearchStatus === strings.ocpSearchStatuses.completed && status === 'manual' && (
        <Text mb={5} color="text.subtle" fontWeight="semibold">
          We have a job source for your company but unfortunately we cannot extract jobs from it.{' '}
          <Link href="https://help.getro.com/support/solutions/articles/65000168381-how-to-ensure-your-job-postings-are-updated-daily">
            Learn more
          </Link>
          .
        </Text>
      )}
    </>
  );
};

JobSourceSummary.propTypes = {
  organizationCareersPages: PropTypes.arrayOf(
    PropTypes.shape({
      scraperStatus: PropTypes.string,
    }),
  ),
  suggestJobSourceLink: PropTypes.string.isRequired,
  ocpSearchStatus: PropTypes.oneOf(['completed', 'in_progress', 'no_ocp_found']).isRequired,
};

JobSourceSummary.defaultProps = {
  organizationCareersPages: [],
};
