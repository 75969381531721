const { useCallback, useState } = require('react');

export const useFocusTrapState = () => {
  const [pauseFocusTrap, setPauseFocusTrap] = useState(false);

  const onDropDownStateChange = useCallback((state) => {
    if (state === 'open') {
      setPauseFocusTrap(true);
    } else {
      setPauseFocusTrap(false);
    }
  }, []);

  return {
    pauseFocusTrap,
    onDropDownStateChange,
  };
};
