import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Input } from '@getro/rombo';
import PropTypes from 'prop-types';
import useOnClickOutside from 'hooks/useClickOutside';
import MultiSelectDropDown from '../MultiSelectDropDown';
import { FilterTitle } from '../filterTitle';

const MultiSelect = ({
  title,
  options,
  selected,
  onSelect,
  onSearch,
  loading,
  onInitialOpenDropDown,
  notFoundText,
  onOpen,
  debounceTime,
  useLocalSearch,
  useSearchAsTag,
  defaultText,
  isList,
  showSearch,
  disableSearch,
  disableSearchText,
  footer,
  dropdownFooter,
  onLoseFocus,
  placeholder,
  clickOutsideDownEvent,
}) => {
  const [search, setSearch] = useState('');
  const [showDropDown, setShowDropDown] = useState(false);
  const initialOpenedRef = useRef();
  const inputRef = useRef();
  const [isOpened, setIsOpened] = useState(false);
  const items = useMemo(
    () =>
      !useLocalSearch ? options : options.filter((item) => item.label.toLowerCase().includes(search.toLowerCase())),

    [options, search, useLocalSearch],
  );
  const [showInput, setShowInput] = useState(true);
  const handleClickOutside = useCallback(() => {
    setIsOpened(false);

    setShowInput(false);
    setShowDropDown(false);
    setSearch('');
  }, []);
  const ref = useOnClickOutside(handleClickOutside, { useEsc: true, downEvent: clickOutsideDownEvent });

  const openDropDown = () => {
    if (onOpen) {
      onOpen();
    }

    setShowDropDown(true);
    initialOpenedRef.current = true;
  };

  const onCloseDropDown = () => {
    if (showSearch) {
      setSearch('');
    }

    if (!showSearch || onInitialOpenDropDown) {
      setIsOpened(false);
    }

    setShowDropDown(false);
  };

  const [typing, setTyping] = useState(false);
  const timerRef = useRef(null);

  const handleSearch = (value) => {
    clearTimeout(timerRef.current);
    setSearch(value);
    setTyping(true);
    timerRef.current = setTimeout(() => {
      setTyping(false);
      if (onSearch) {
        onSearch(value);
      }
    }, debounceTime);
  };

  const onToggle = () => {
    if (!initialOpenedRef.current && onInitialOpenDropDown) {
      onInitialOpenDropDown();
      setShowDropDown(true);
    }

    if (!isOpened) {
      setIsOpened(true);
      setShowInput(true);
    } else {
      setIsOpened(false);
    }

    if (!showSearch) {
      openDropDown();
    }
  };

  const useInput = showSearch && showInput;

  return (
    <Flex ref={ref} sx={{ width: '100%', alignItems: 'flex-start', flexDirection: 'column', gap: '8px' }}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <MultiSelectDropDown
          bottomText={dropdownFooter}
          onClose={() => onCloseDropDown()}
          onOpen={() => openDropDown()}
          onToggle={onToggle}
          onShowSearch={() => {
            setIsOpened(true);
            setShowInput(true);

            if (onInitialOpenDropDown) {
              setShowDropDown(true);
              onInitialOpenDropDown();
            }
          }}
          showSearch={showSearch && showInput}
          selected={selected}
          isMulti
          options={items}
          onSelect={(selectedItems) => {
            if (onInitialOpenDropDown) {
              setShowInput(false);
            }
            onSelect(selectedItems);
          }}
          showDropDown={showDropDown}
          loading={loading}
          defaultText={search ? '' : defaultText}
          isOpened={isOpened}
          openDropDown={openDropDown}
          input={
            useInput ? (
              <>
                {!disableSearch && (
                  <Input
                    ref={inputRef}
                    autoFocus
                    onBlur={() => {
                      if (onLoseFocus) {
                        onLoseFocus(selected.map((item) => item.value));
                      }
                    }}
                    data-testid="multi-select-search"
                    onChange={({ target }) => {
                      setShowDropDown(true);
                      handleSearch(target.value);
                      setSearch(target.value);
                    }}
                    onKeyDown={({ target, key }) => {
                      if (useSearchAsTag && key === 'Enter' && target.value.trim()) {
                        onSelect([...selected, { label: target.value, value: target.value }]);
                        setSearch('');
                        onCloseDropDown();
                      }
                    }}
                    value={search}
                    sx={{
                      height: '32px',
                      fontSize: '14px',
                    }}
                    placeholder={placeholder}
                  />
                )}
                {disableSearch && (
                  <Text fontSize="14px" color="text.placeholder">
                    {disableSearchText}
                  </Text>
                )}
              </>
            ) : null
          }
          trigger={
            <FilterTitle
              data-testid="multi-select-trigger"
              title={title}
              showClear={selected.length > 0}
              showToggle={!isOpened && !selected?.length}
              clearFilter={() => {
                onSelect([]);

                setIsOpened(false);
              }}
              onToggle={onToggle}
            />
          }
        >
          {!items.length && search && !typing && !loading && (
            <Text
              as="p"
              sx={{
                fontSize: '12px',
                lineHeight: '1.4',
                fontStyle: 'italic',
                color: 'text.subtle',
                px: '16px',
                pt: '8px',
              }}
            >
              {notFoundText} “{search}” {isList ? 'in this list' : ''}
            </Text>
          )}
        </MultiSelectDropDown>
      </Box>
      {(isOpened || selected?.length > 0) && footer}
    </Flex>
  );
};

MultiSelect.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  onInitialOpenDropDown: PropTypes.func,
  notFoundText: PropTypes.string,
  onOpen: PropTypes.func,
  debounceTime: PropTypes.number,
  useLocalSearch: PropTypes.bool,
  useSearchAsTag: PropTypes.bool,
  defaultText: PropTypes.string,
  isList: PropTypes.bool,
  showSearch: PropTypes.bool,
  footer: PropTypes.node,
  dropdownFooter: PropTypes.node,
  onLoseFocus: PropTypes.func,
  placeholder: PropTypes.string,
  clickOutsideDownEvent: PropTypes.string,
  disableSearch: PropTypes.bool,
  disableSearchText: PropTypes.string,
};

MultiSelect.defaultProps = {
  onSelect: () => {},
  onSearch: () => {},
  loading: false,
  onInitialOpenDropDown: null,
  onOpen: () => {},
  notFoundText: 'No results found for',
  debounceTime: 0,
  useLocalSearch: false,
  useSearchAsTag: false,
  defaultText: '',
  isList: false,
  showSearch: true,
  footer: null,
  dropdownFooter: null,
  onLoseFocus: null,
  placeholder: 'Search',
  clickOutsideDownEvent: 'click',
  disableSearch: false,
  disableSearchText: '',
};

export default MultiSelect;
