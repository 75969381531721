/* eslint-disable react/no-multi-comp */
import React from 'react';

const CONFIG = {
  VOUCH: {
    title: 'Vouch for [Member Name]',
    desc: () => (
      <p>
        Signal to companies why the people you know are stand out. Vouch for this person by telling us how you are
        connected and why they are awesome.
      </p>
    ),
    permission: 1,
    submitText: 'Vouch',
    success: {
      title: 'Thanks for vouching for [Member Name]!',
      desc: () => (
        <p>
          We’ve sent them an email so they can celebrate. Your vouch will be added to their profile for companies to
          see.
        </p>
      ),
    },
  },
  VERIFY: {
    title: 'Vouch to verify [Member Name]',
    desc: () => (
      <>
        <p>
          Signal to companies why the people you know are stand out. Vouch for this person by telling us how you are
          connected and why they are awesome.
        </p>
        <p>Verify those you trust most, so they can request intros to companies.</p>
      </>
    ),
    permission: 2,
    submitText: 'Vouch and verify',
    success: {
      title: 'Thanks for verifying [Member Name]!',
      desc: () => (
        <>
          <p>We’ve sent them an email so they can celebrate.</p>
          <p>
            They can now request introductions directly to companies in the network. Your vouch will be added to their
            profile for companies to see.
          </p>
        </>
      ),
    },
  },
};

export default CONFIG;
