import { createActions } from 'redux-actions';

export const UPDATE_TALENT_GROUP = 'UPDATE_TALENT_GROUP';

export const updateTalentGroup = (payload) => ({
  type: UPDATE_TALENT_GROUP,
  payload,
});

export const { loadTalentGroups, loadedTalentGroups, errorTalentGroups } = createActions(
  'LOAD_TALENT_GROUPS',
  'LOADED_TALENT_GROUPS',
  'ERROR_TALENT_GROUPS',
);

export const { loadSubscribeGroup, loadedSubscribeGroup, errorSubscribeGroup } = createActions(
  'LOAD_SUBSCRIBE_GROUP',
  'LOADED_SUBSCRIBE_GROUP',
  'ERROR_SUBSCRIBE_GROUP',
);
