import React from 'react';
import { Button, Modal } from '@getro/rombo';
import PropTypes from 'prop-types';
import { Box, Image, Flex } from 'rebass/styled-components';
import GetroConnetIntroModalBackground from '../../../assets/getro-connect-intro.png';

export const GetroConnectIntro = ({ onCancel }) => (
  <Modal onCancel={onCancel} isOpen title="Make better intros at scale with GetroConnect 🚀">
    <Box
      sx={{
        color: 'text.main',
      }}
    >
      <Image src={GetroConnetIntroModalBackground} alt="Never miss a great intro." />
      <Box mb="16px" as="p" mt="24px">
        GetroConnect unlocks your network&apos;s full potential by helping you:{' '}
      </Box>
      <Box as="ul" pl="24px">
        <Box as="li">Manage and search your team&apos;s network of contacts in one place</Box>
        <Box as="li">View updated contact info with robust data enrichment</Box>
        <Box as="li">Match contacts to key open jobs, powered by AI</Box>
        <Box as="li">Share the right contacts with the right people, at the right time</Box>
        <Box as="li">Track hiring outcomes from your intros</Box>
      </Box>
      <Box mt="16px" as="p">
        Learn more at{' '}
        <Box as="a" href="https://connect.getro.com" target="__blank">
          connect.getro.com
        </Box>{' '}
        or schedule a demo with our team.
      </Box>
      <Flex justifyContent="center" mt="32px" as="p">
        <Button
          as="a"
          href="https://talkto.getro.com/request-demo-calendar?utm_medium=jobs_table&utm_source=adminportal&utm_campaign=unlockmatches"
          target="__blank"
          size="medium"
        >
          Let&apos;s talk
        </Button>
      </Flex>
    </Box>
  </Modal>
);

GetroConnectIntro.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default GetroConnectIntro;
