import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Link } from 'rebass/styled-components';
import moment from 'moment';
import { StatusIndicator, Message, PanelRow } from '@getro/rombo';

import Popover from '../../atoms/popoverDeprecated';
import getOcpStatus from '../../../helpers/getOcpStatus';
import UserSchema from '../../../schemas/user';
import OrganizationSchema from '../../../schemas/organization';

const JobSource = ({ source, currentUser, company }) => {
  const { firstName, lastName, email } = currentUser;
  const { id: companyId, name: companyName, websiteUrl: companyUrl } = company;
  const { activeJobsCount, scrapedAt, scraperStatus, url } = source;
  const ocpStatus = getOcpStatus(scraperStatus);

  const jobSourceChangeUrl = useMemo(
    () =>
      `https://getro-forms.typeform.com/to/yMmzpUKy#firstname=${firstName}&companyname=${companyName}&email=${email}&lastname=${lastName}&companyid=${companyId}&companyurl=${companyUrl}`,
    [companyId, companyName, companyUrl, email, firstName, lastName],
  );

  return (
    <Flex width={1} flexDirection="column">
      <Text mb={3} color="text.main" fs={2}>
        Our job source experts make sure that your jobs are always up to date for all of your companies.{' '}
        <Link
          color="primary"
          href="https://help.getro.com/support/solutions/articles/65000179707-company-job-sources"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </Link>
        . To report a change in this job source,{' '}
        <Link color="primary" href={jobSourceChangeUrl} target="_blank" rel="noopener noreferrer">
          click here
        </Link>
        .
      </Text>
      <PanelRow title="URL" mb={3}>
        <Text>{url}</Text>
      </PanelRow>
      <PanelRow title="Status" mb={3}>
        <StatusIndicator status={ocpStatus.status} statusColor={ocpStatus.statusColor} />
      </PanelRow>
      {ocpStatus.explanationText && (
        <PanelRow mt={[3, 3, 0]} mb={3}>
          <Message type="info">
            <span dangerouslySetInnerHTML={{ __html: ocpStatus.explanationText }} />
          </Message>
        </PanelRow>
      )}
      <PanelRow title="Last updated" mb={3}>
        {scrapedAt ? (
          <Popover
            content={scrapedAt ? moment(scrapedAt).format('D MMM, YYYY [at] hh:mm a') : 'Not available'}
            trigger={<span>{moment().from(scrapedAt, true)} ago</span>}
          />
        ) : (
          'Never'
        )}
      </PanelRow>
      <PanelRow title="Jobs">
        <Text>{activeJobsCount || 0} Total</Text>
      </PanelRow>
    </Flex>
  );
};

JobSource.propTypes = {
  source: PropTypes.shape({
    activeJobsCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    scrapedAt: PropTypes.string,
    scraperStatus: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  currentUser: UserSchema.isRequired,
  company: OrganizationSchema.isRequired,
};

export default JobSource;
