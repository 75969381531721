import { useMemo, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from '../useInjectReducer';
import {
  companyDetailsSelector,
  companyTopicsSelector,
  isAdminAdditionErroredSelector,
  isCompanyErroredSelector,
  key,
  updateCompanyErrorSelector,
} from './selectors';
import reducer from './reducer';
import { useInjectSaga } from '../useInjectSaga';
import saga from './sagas';
import { makeSelectIsLoading } from '../../redux/loadingSelector';
import {
  loadCompany,
  loadAddAdmin,
  loadRemoveAdmin,
  loadResendAdminInvite,
  loadUpdateCompany,
  resetCompanyError,
} from './actions';
import { companySlugParser } from '../../helpers/companySlugParser';

const useCompany = () => {
  const match = useRouteMatch();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const error = useSelector(isCompanyErroredSelector);
  const loading = useSelector(makeSelectIsLoading('company'));
  const company = useSelector(companyDetailsSelector);
  const isAdminAdditionErrored = useSelector(isAdminAdditionErroredSelector);
  const isAddingAdmin = useSelector(makeSelectIsLoading('addAdmin'));
  const isRemovingAdmin = useSelector(makeSelectIsLoading('removeAdmin'));
  const isResendingAdminInvite = useSelector(makeSelectIsLoading('resendAdminInvite'));
  const companyTopics = useSelector(companyTopicsSelector);
  const isUpdating = useSelector(makeSelectIsLoading('updateCompany'));
  const updateCompanyError = useSelector(updateCompanyErrorSelector);
  const slugParts = useMemo(() => companySlugParser(match.params.companySlug), [match.params.companySlug]);
  const [companyId, companySlug] = slugParts;

  const initialized = useMemo(() => {
    const { slug, id } = company;
    return slug === companySlug || (typeof companyId !== 'undefined' && id?.toString() === companyId);
  }, [companySlug, companyId, company]);

  useEffect(() => {
    if (!initialized && !loading && !error) {
      dispatch(loadCompany({ companySlug, companyId }));
    }
  }, [companySlug, companyId, dispatch, error, initialized, loading]);

  const addAdmin = useCallback(
    (props) => {
      dispatch(loadAddAdmin({ ...props }));
    },
    [dispatch],
  );

  const removeAdmin = useCallback(
    (admin) => {
      dispatch(loadRemoveAdmin({ admin }));
    },
    [dispatch],
  );

  const resendAdminInvite = useCallback(
    (adminId) => {
      dispatch(loadResendAdminInvite({ adminId }));
    },
    [dispatch],
  );

  const updateCompany = useCallback(
    (params, onComplete) => {
      dispatch(loadUpdateCompany({ ...params, qa: company.qa, onComplete }));
    },
    [company.qa, dispatch],
  );

  const resetError = useCallback(() => {
    dispatch(resetCompanyError());
  }, [dispatch]);

  return {
    companySlug,
    initialized,
    error,
    loading,
    company,
    isAdminAdditionErrored,
    isAddingAdmin,
    addAdmin,
    removeAdmin,
    isRemovingAdmin,
    resendAdminInvite,
    isResendingAdminInvite,
    companyTopics,
    updateCompany,
    updateCompanyError,
    isUpdating,
    resetError,
  };
};

export default useCompany;
