import React, { useEffect, useState } from 'react';
import { EyeOff, Lock, Zap } from 'lucide-react';
import { Button, FormInput } from '@getro/rombo';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Box, Flex, Image, Link, Text } from 'rebass/styled-components';
import * as Yup from 'yup';

import ChromeExtensionLogo from '../../assets/chrome-extension-logo.png';
import { FocusLayout } from '../../components/layout/focus';
import { trackEvent } from '../../helpers/analytics';
import { activeNetworkSelector } from '../../redux/selectors';
import { EXTENSION_URL, checkExtensionAvailability } from '../../services/chromeExtension';
import { useImportContactFromLinkedinMutation } from '../../services/contacts';

export const ContactsAddSingleContact = () => {
  const [importContactFromLinkedin, { isLoading }] = useImportContactFromLinkedinMutation();
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const activeNetwork = useSelector(activeNetworkSelector);
  const history = useHistory();

  const formSchema = Yup.object().shape({
    linkedinUrl: Yup.string().required('This is a required field').url('This must be a valid URL'),
  });

  useEffect(() => {
    if (activeNetwork) {
      trackEvent('contacts:add_single_contact:start', { collection_id: activeNetwork.id });
    }
  }, [activeNetwork]);

  useEffect(() => {
    const checkExtension = async () => {
      try {
        await checkExtensionAvailability();
        setIsExtensionInstalled(true);
      } catch {
        // Do nothing
      }
    };

    checkExtension();
  }, []);

  const onSubmit = async (values) => {
    try {
      const { data } = await importContactFromLinkedin(values);

      history.push(`/networks/${activeNetwork.slug}/contact/${data.contact.id}/edit`);
    } catch {
      history.push(`/networks/${activeNetwork.slug}/contact/add/error?linkedinUrl=${values.linkedinUrl}`);
    }
  };

  return (
    <FocusLayout title="Add a single contact" backUrl={`/networks/${activeNetwork.slug}/contacts`}>
      <Box pb="5">
        <Text fontSize={1}>
          <Text as="span" color="purple.300" fontWeight="medium">
            1. LinkedIn URL
          </Text>
          {' > '}
          <Text as="span" color="text.main" fontWeight="body">
            2. Additional information
          </Text>
        </Text>
        <Flex flexDirection={['column', 'row']} mt="32px" sx={{ columnGap: [0, '40px'] }}>
          <Box flexGrow="1">
            <Formik initialValues={{ linkedinUrl: '' }} validationSchema={formSchema} onSubmit={onSubmit}>
              <Form>
                <Field
                  component={FormInput}
                  name="linkedinUrl"
                  label="LinkedIn URL"
                  helpText="Add the public profile LinkedIn URL for the contact you want to add."
                  placeholder="https://linkedin.com/in/..."
                />
                <Flex
                  mt="16px"
                  pt="16px"
                  justifyContent="flex-end"
                  sx={{ borderTop: 1, borderTopColor: 'neutral.100' }}
                >
                  <Button type="submit" loading={isLoading}>
                    Add contact
                  </Button>
                </Flex>
              </Form>
            </Formik>
          </Box>
          <Box as="ul" flex="0 0 auto" pl="0" mt={[5, 0]} width={['100%', '340px']}>
            <Flex as="li">
              <Flex
                flex="0 0 auto"
                width="32px"
                height="32px"
                alignItems="center"
                justifyContent="center"
                bg="purple.100"
                color="purple.400"
                mr="2"
                sx={{ borderRadius: 'circle' }}
              >
                <Box as={Zap} width="16px" height="16px" aria-hidden="true" />
              </Flex>
              <Text fontSize="1">A complete professional profile will be automatically created for each contact.</Text>
            </Flex>
            <Flex as="li" mt="4">
              <Flex
                flex="0 0 auto"
                width="32px"
                height="32px"
                alignItems="center"
                justifyContent="center"
                bg="purple.100"
                color="purple.400"
                mr="2"
                sx={{ borderRadius: 'circle' }}
              >
                <Box as={EyeOff} width="16px" height="16px" aria-hidden="true" />
              </Flex>
              <Text fontSize="1">Contacts are only visible to network admins until you add them to a shared list.</Text>
            </Flex>
            <Flex as="li" mt="4">
              <Flex
                flex="0 0 auto"
                width="32px"
                height="32px"
                alignItems="center"
                justifyContent="center"
                bg="purple.100"
                color="purple.400"
                mr="2"
                sx={{ borderRadius: 'circle' }}
              >
                <Box as={Lock} width="16px" height="16px" aria-hidden="true" />
              </Flex>
              <Text fontSize="1">We will never contact or share your contacts without your permission.</Text>
            </Flex>
            <Flex as="li" mt="4">
              <Image
                src={ChromeExtensionLogo}
                alt="Install Getro Chrome extension"
                mr="2"
                width="25px"
                height="25px"
                flex="0 0 auto"
              />
              <Box>
                <Text fontSize="1" pb="2">
                  You can add people from LinkedIn directly by using the Getro Chrome extension.
                </Text>
                {!isExtensionInstalled && (
                  <Link
                    href={EXTENSION_URL}
                    fontSize="14px"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      trackEvent('contacts:install_chrome_extension', {
                        collection_id: activeNetwork.id,
                        origin: 'add_single_contact',
                      });
                    }}
                  >
                    Install Getro Chrome extension
                  </Link>
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </FocusLayout>
  );
};
