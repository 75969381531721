import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { Loader, Message, Button } from '@getro/rombo';
import copy from 'copy-to-clipboard';
import Input from '../../atoms/input';

const handleCopy = (url, setCopied) => () => {
  copy(url);
  setCopied(true);
};

const ShareLink = ({ title, url, onGenerateLink, loading, children }) => {
  const [copied, setCopied] = useState(false);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          {title && <h3>{title}</h3>}
          {children}

          <div className="ShareLink__modal-content" data-testid="share-link">
            {url && (
              <>
                <Input className="ShareLink__modal-input" value={url} readOnly />
                <Button variant="secondary" ml={2} onClick={handleCopy(url, setCopied)}>
                  Copy link
                </Button>
              </>
            )}
            {!url && (
              <Button variant="secondary" ml={2} onClick={onGenerateLink}>
                Generate link
              </Button>
            )}
          </div>

          {copied && (
            <Box
              mt={3}
              as={Message}
              type="success"
              className="ShareLink__success-message"
              content="Link copied to clipboard"
            />
          )}
        </>
      )}
    </>
  );
};

ShareLink.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  url: PropTypes.string,
  loading: PropTypes.bool,
  onGenerateLink: PropTypes.func,
};

ShareLink.defaultProps = {
  children: null,
  title: '',
  loading: false,
  url: null,
  onGenerateLink: () => {},
};

export default ShareLink;
