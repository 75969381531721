import React from 'react';
import PropTypes from 'prop-types';
import { MessageBlock, Box } from '@getro/rombo';

const ErrorMessage = ({ onRetryClick }) => (
  <Box data-testid="members-error-message">
    <MessageBlock type="error" title="There was an unexpected error" buttonText="Try again" onClick={onRetryClick}>
      Please try again or contact us at <a href="mailto:help@getro.com">help@getro.com</a>
    </MessageBlock>
  </Box>
);

ErrorMessage.propTypes = {
  onRetryClick: PropTypes.func.isRequired,
};

export default ErrorMessage;
