import { Select } from '@getro/rombo';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTheme } from 'styled-components';

export const options = [
  {
    label: 'Yes',
    value: 'influenced',
    description: 'My team did help with this hire.',
  },
  {
    label: 'No',
    value: 'no_influence',
    description: 'My team did not help with this hire.',
  },
  {
    label: 'Unknown',
    value: 'unknown',
    description: 'I’m not sure if my team helped with this hire.',
  },
];

export const TeamImpactSelector = ({ value, onChange }) => {
  const { shadows } = useTheme();

  const onChangeValue = (newValue) => {
    onChange(newValue);
  };

  return (
    <Flex alignItems="center" minWidth="140px">
      <Box flexGrow="1" maxWidth="110px">
        <Box flex="0 0 auto" sx={{ '&& [data-testid="select-control"]': { border: 0, px: 0 } }}>
          <Select
            anchorToBody
            placeholder="Select"
            options={options}
            value={value}
            position="auto"
            onChange={onChangeValue}
            styles={{
              control: () => ({}),
              dropdownIndicator: () => ({}),
              groupHeading: () => ({}),
              menu: (styles) => ({
                ...styles,
                boxShadow: shadows.small,
                width: '310px',
                right: 0,
              }),
              option: () => ({}),
              optionLabelContent: () => ({}),
              placeholder: () => ({ fontSize: '14px' }),
              singleValue: () => ({ fontSize: '14px' }),
              valueContainer: () => ({ input: { height: '16px' } }),
              input: () => ({}),
              menuPortal: (base) => ({
                ...base,
                zIndex: 999999999,
              }),
            }}
          />
        </Box>
      </Box>
      {!value?.value && (
        <Text
          px="8px"
          flex="0 0 auto"
          bg="purple.100"
          fontSize="10px"
          fontWeight="semibold"
          color="purple.400"
          sx={{ borderRadius: 'circle' }}
        >
          To review
        </Text>
      )}
    </Flex>
  );
};

TeamImpactSelector.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

TeamImpactSelector.defaultProps = {
  value: null,
};
