import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import { X, ChevronLeft } from 'lucide-react';

const handleClick = (open, setOpen) => () => {
  setOpen(open);
  let overflow = null;
  if (open) {
    overflow = 'hidden';
  }
  document.body.style.overflow = overflow;
};

const SidebarFilters = ({ hideDesktopHeader, onClear, title, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <Flex className={`SidebarFilters ${open ? 'SidebarFilters--open' : ''}`}>
      <Button
        display={['block', 'block', 'none']}
        color="text.dark"
        textAlign="left"
        width={[1]}
        variant="secondary"
        onClick={handleClick(true, setOpen)}
        sx={{ borderColor: 'gray.3', ':hover': { bg: 'white' } }}
      >
        {title}
      </Button>
      <div className="SidebarFilters__main">
        <div className={`SidebarFilters__header ${hideDesktopHeader ? 'hide-desktop' : ''}`}>
          <div className="SidebarFilters__header-button SidebarFilters__header-button--left">
            <Button variant="styleless" color="text.dark" onClick={handleClick(false, setOpen)}>
              <Box as={ChevronLeft} height="16px" width="16px" aria-hidden="true" />
            </Button>
          </div>
          <div className={`SidebarFilters__header-title ${hideDesktopHeader ? 'hide-desktop' : ''}`}>{title}</div>
          <div className="SidebarFilters__header-button SidebarFilters__header-button--right">
            {onClear !== null && (
              <Button
                variant="styleless"
                size="small"
                color="text.dark"
                sx={{ opacity: 0.6, pr: 0, pt: 0, pb: 3 }}
                onClick={onClear}
              >
                <Flex alignItems="center">
                  <Box mr="1" as={X} height="16px" width="16px" strokeWidth="1.5" />
                  Clear all
                </Flex>
              </Button>
            )}
          </div>
        </div>

        <div className="SidebarFilters__content">{children}</div>

        <div className="SidebarFilters__footer">
          <Button width={1} fontWeight="semibold" onClick={handleClick(false, setOpen)}>
            View Results
          </Button>
        </div>
      </div>
    </Flex>
  );
};

SidebarFilters.propTypes = {
  hideDesktopHeader: PropTypes.bool,
  onClear: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SidebarFilters.defaultProps = {
  hideDesktopHeader: false,
  title: 'Filters',
  onClear: null,
};

export default SidebarFilters;
