import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { talentGroupsSelector } from 'redux/talentGroups.selector';
import { loadedTalentGroups, loadTalentGroups } from '../actions/talentGroupActions';
import { useInjectReducer } from './useInjectReducer';
import talentGroupsReducer, { key } from '../redux/reducers/talentGroupsReducer';
import { useInjectSaga } from './useInjectSaga';
import talentGroupsSaga from '../redux/sagas/talentGroupsSaga';

const useTalentGroups = ({ id }) => {
  useInjectReducer({ key, reducer: talentGroupsReducer });
  useInjectSaga({ key, saga: talentGroupsSaga });
  const talentGroups = useSelector(talentGroupsSelector);
  const dispatch = useDispatch();

  const addTalentGroup = (talentGroup) => {
    const found = talentGroups.find((element) => element.value === talentGroup.value);
    if (!found) {
      const options = [...talentGroups, talentGroup];
      dispatch(loadedTalentGroups({ id, talentGroups: options }));
    }
  };

  const removeTalentGroup = (talentGroup) => {
    const options = [...talentGroups];
    const index = options.findIndex((element) => element.value === talentGroup.value);
    if (index > -1) {
      options.splice(index, 1);
      dispatch(loadedTalentGroups({ id, talentGroups: options }));
    }
  };

  useEffect(() => {
    if (!talentGroups && id) {
      dispatch(loadTalentGroups({ id }));
    }
  }, [talentGroups, id, dispatch]);

  return [talentGroups, addTalentGroup, removeTalentGroup];
};

export default useTalentGroups;
