import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'addCompanyByName';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const hasErrorSelector = createSelector(
  rootSelector,
  ({ error, companyDetails }) => error || (companyDetails?.id && companyDetails?.status !== 'active'),
);
export const errorMessageSelector = createSelector(rootSelector, ({ errorMessage }) => errorMessage);
export const companyDetailsSelector = createSelector(rootSelector, ({ companyDetails }) => companyDetails);
export const needsVerificationSelector = createSelector(rootSelector, ({ needsVerification }) => needsVerification);
export const bulkCompanyMetaSelector = createSelector(
  rootSelector,
  ({ bulkCompanyDetails }) => bulkCompanyDetails?.meta,
);
