import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  errorRequestExportJobAlertSubscribers,
  loadedRequestExportJobAlertSubscribers,
  loadRequestExportJobAlertSubscribers,
} from './actions';
import { activeNetworkSelector } from '../../../../../redux/selectors';
import { request } from '../../../../../api';
import { trackException } from '../../../../../helpers/errorTracker';

function* loadRequestExportJobAlertSubscribersEvent() {
  const network = yield select(activeNetworkSelector);
  try {
    yield call(request, {
      url: `/collections/${network.id}/job_alerts/export_csv`,
      options: { method: 'post', baseURL: process.env.REACT_APP_API_V2_BASE_URL },
    });

    yield put(loadedRequestExportJobAlertSubscribers());
  } catch (e) {
    yield put(errorRequestExportJobAlertSubscribers());
    yield call(trackException, e);
  }
}

export default function* saga() {
  yield takeLatest(loadRequestExportJobAlertSubscribers().type, loadRequestExportJobAlertSubscribersEvent);
}
