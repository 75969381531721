import React from 'react';
import PropTypes from 'prop-types';
import { StatusIndicator, Button } from '@getro/rombo';
import { Text } from 'rebass/styled-components';

const JobVisibility = ({ visibility, onShowJobRulesClick }) => {
  switch (visibility) {
    case 'visible_forced':
      return (
        <StatusIndicator
          status={
            <>
              Visible
              <br />
              (manually)
            </>
          }
          statusColor="success"
        />
      );
    case 'hidden_forced':
      return (
        <StatusIndicator
          status={
            <>
              Hidden
              <br />
              (manually)
            </>
          }
          statusColor="neutral.300"
        />
      );
    case 'hidden':
      return (
        <StatusIndicator
          status={
            <>
              Hidden
              <br />
              <Button
                onClick={onShowJobRulesClick}
                variant="link"
                sx={{
                  p: 0,
                  color: 'text.main',
                  fontWeight: 'body',
                  textAlign: 'left',
                  textDecoration: 'underline',
                  backgroundColor: 'transparent',
                  height: '24px',
                }}
              >
                <Text fontSize={1} backgroundColor="transparent">
                  (job rules)
                </Text>
              </Button>
            </>
          }
          statusColor="neutral.300"
        />
      );
    case 'not_visible':
      return <StatusIndicator status="Not visible" statusColor="neutral.300" />;
    default:
      return <StatusIndicator status="Visible" statusColor="success" />;
  }
};

JobVisibility.propTypes = {
  visibility: PropTypes.string.isRequired,
  onShowJobRulesClick: PropTypes.func.isRequired,
};

export default JobVisibility;
