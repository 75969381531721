export const optimizeImage = (img) => {
  let logoUrl = img;
  if (logoUrl && logoUrl.indexOf('cdn.filepicker.io/api/file/') > -1) {
    const fileGuid = logoUrl.split('cdn.filepicker.io/api/file/')[1];
    logoUrl = `https://cdn.filestackcontent.com/output=f:webp,t:true,q:80,c:true/cache=expiry:max/resize=w:340/${fileGuid}`;
  } else if (logoUrl && logoUrl.indexOf('cdn.filestackcontent.com/') > -1) {
    const fileGuid = logoUrl.split('cdn.filestackcontent.com/')[1];
    logoUrl = `https://cdn.filestackcontent.com/output=f:webp,t:true,q:80,c:true/cache=expiry:max/resize=w:340/${fileGuid}`;
  }
  return logoUrl || null;
};
