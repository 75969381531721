import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass/styled-components';
import { CheckCircle, EyeOff, FileUp, Linkedin, Lock, RefreshCcw } from 'lucide-react';

import { Message } from '@getro/rombo';
import { FocusLayout } from '../../components/layout/focus';
import { ContactsModalImportFromLinkedin } from '../../components/molecules/contactsModalImportFromLinkedin';
import { trackEvent } from '../../helpers/analytics';
import { activeNetworkSelector } from '../../redux/selectors';
import { checkExtensionAvailability } from '../../services/chromeExtension';

export const ContactsImport = () => {
  const [showImportContactsModal, setShowImportContactsModal] = useState(false);
  const activeNetwork = useSelector(activeNetworkSelector);
  const history = useHistory();
  const [hasExtension, setHasExtension] = useState(false);

  const onClickImportContacts = async () => {
    trackEvent('contacts:select_import_method', {
      selected_method: 'extension',
    });

    try {
      await checkExtensionAvailability();
      setHasExtension(true);

      trackEvent('contacts:import_contacts', {
        collection_id: activeNetwork.id,
        step: 'add contacts page',
        chrome_extension_installed: true,
      });

      setShowImportContactsModal(true);
    } catch {
      trackEvent('contacts:import_contacts', {
        collection_id: activeNetwork.id,
        step: 'add contacts page',
        chrome_extension_installed: false,
      });
      setHasExtension(false);
      setShowImportContactsModal(true);
    }
  };

  const onCloseModals = () => {
    setShowImportContactsModal(false);
    history.push(`/networks/${activeNetwork.slug}/contacts`);
  };

  const onClickImportCsv = () => {
    trackEvent('contacts:select_import_method', {
      selected_method: 'csv',
    });
    history.push(`/networks/${activeNetwork.slug}/import-contacts/csv`);
  };

  return (
    <FocusLayout title="Get started with Matches" backUrl={`/networks/${activeNetwork.slug}/contacts`}>
      <Box maxWidth="600px" mx="auto" mb="80px">
        <Text fontSize="22px" fontWeight="semibold" color="text.dark">
          Add your contacts
        </Text>
        <Text mt="16px" fontSize="14px">
          Uncover great introductions – add all your team’s contacts and get automatic introductions recommendations.
        </Text>
        <Flex mt="56px" sx={{ gap: '16px' }} flexDirection={['column', 'row']}>
          <Flex
            data-testid="button-csv"
            flex="1"
            flexDirection="column"
            sx={{ borderRadius: 'default', border: 1, borderColor: 'border.subtle', cursor: 'pointer' }}
            onClick={onClickImportCsv}
          >
            <Text
              width="100%"
              py="4px"
              bg="purple.100"
              fontSize="12px"
              fontWeight="medium"
              color="purple.400"
              sx={{ textAlign: 'center' }}
            >
              Easiest to get started
            </Text>
            <Flex p="16px" flexDirection="column" alignItems="center" sx={{ '&&:hover': { bg: 'neutral.20' } }}>
              <Flex
                data-selector="circle"
                mt="16px"
                flex="0 0 auto"
                width="56px"
                height="56px"
                bg="purple.100"
                color="purple.400"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: 'circle' }}
              >
                <Box as={FileUp} width="24px" height="24px" aria-hidden="true" />
              </Flex>
              <Text mt="32px" fontSize="14px" fontWeight="medium" color="text.dark">
                Import CSV file
              </Text>
              <Text mt="8px" fontSize="12px" fontWeight="medium" sx={{ textAlign: 'center' }}>
                Upload an export from any tool: Airtable, Notion, Spreadsheets, etc...
              </Text>
            </Flex>
          </Flex>
          <Flex
            data-testid="button-linkedin"
            mt="25px"
            pb="16px"
            flex="1"
            alignItems="center"
            flexDirection="column"
            sx={{
              borderRadius: 'default',
              border: 1,
              borderColor: 'border.subtle',
              cursor: 'pointer',
              '&&:hover': { bg: 'neutral.20' },
            }}
            onClick={onClickImportContacts}
          >
            <Flex
              data-selector="circle"
              mt="32px"
              flex="0 0 auto"
              width="56px"
              height="56px"
              bg="purple.100"
              color="purple.400"
              alignItems="center"
              justifyContent="center"
              sx={{ borderRadius: 'circle' }}
            >
              <Box as={Linkedin} width="24px" height="24px" aria-hidden="true" />
            </Flex>
            <Text mt="32px" fontSize="14px" fontWeight="medium" color="text.dark">
              Import from LinkedIn
            </Text>
            <Text mt="8px" fontSize="12px" fontWeight="medium" sx={{ textAlign: 'center' }}>
              Automatically import all your LinkedIn connections.
            </Text>
          </Flex>
        </Flex>
        <Message mt="80px">
          Adding at least 1,500 contacts is recommended to get good matches. The more contacts you add, the more matches
          we will find.
        </Message>
        <Flex mt="32px" flexDirection="column" sx={{ gap: '16px' }}>
          <Flex color="purple.300" alignItems="center" sx={{ gap: '8px' }}>
            <Box as={RefreshCcw} width="16px" height="16px" aria-hidden="true" />
            <Text fontSize="14px" fontWeight="medium" color="text.main">
              Automatically always up-to-date professional profile for each contact.
            </Text>
          </Flex>
          <Flex color="purple.300" alignItems="center" sx={{ gap: '8px' }}>
            <Box as={EyeOff} width="16px" height="16px" aria-hidden="true" />
            <Text fontSize="14px" fontWeight="medium" color="text.main">
              Contacts are only visible to network admins until you add them to a shared list.
            </Text>
          </Flex>
          <Flex color="purple.300" alignItems="center" sx={{ gap: '8px' }}>
            <Box as={Lock} width="16px" height="16px" aria-hidden="true" />
            <Text fontSize="14px" fontWeight="medium" color="text.main">
              We will never contact or share your contacts without your permission.
            </Text>
          </Flex>
          <Flex color="purple.300" alignItems="center" sx={{ gap: '8px' }}>
            <Box as={CheckCircle} width="16px" height="16px" aria-hidden="true" />
            <Text fontSize="14px" fontWeight="medium" color="text.main">
              Designed with GDPR in mind
            </Text>
          </Flex>
        </Flex>
        {showImportContactsModal && (
          <ContactsModalImportFromLinkedin hasExtension={hasExtension} showFinishInfo={false} onClose={onCloseModals} />
        )}
      </Box>
    </FocusLayout>
  );
};
