import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormSelect, FormToggle } from '@getro/rombo';
import { Box } from 'rebass/styled-components';
import { useGetNetworkAdminsQuery } from '../../../services/networkAdmins';

const PointOfContact = ({
  value,
  onChange: onPointOfContactChange,
  onDropDownStateChange,
  showPointOfContact,
  onShowPointOfContactChange,
}) => {
  const { data: networkAdmins } = useGetNetworkAdminsQuery();

  const options = useMemo(() => {
    if (!networkAdmins) return [];

    return networkAdmins.map(({ id, firstName, lastName }) => ({
      label: `${firstName} ${lastName}`,
      value: id,
    }));
  }, [networkAdmins]);

  return (
    <Box mb="24px">
      <FormToggle
        label="Point of contact"
        onChange={onShowPointOfContactChange}
        helpText="Select a team member for companies to reach out. Their name and email will be visible on the shared list view and in list notifications."
        field={{
          id: 'show-point-of-contact',
          name: 'show-point-of-contact',
          value: showPointOfContact,
        }}
        form={{
          setFieldValue: (_, checked) => onShowPointOfContactChange({ target: { checked } }),
        }}
        sx={{ mb: '8px' }}
      />
      {showPointOfContact && (
        <FormSelect
          required
          options={options}
          anchorToBody
          onMenuOpen={() => onDropDownStateChange('open')}
          onMenuClose={() => onDropDownStateChange('close')}
          field={{
            id: 'pointOfContact',
            name: 'pointOfContact',
            onChange: ({ target: { value: newValue } }) => {
              onPointOfContactChange(newValue);
            },
            value,
          }}
        />
      )}
    </Box>
  );
};

PointOfContact.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDropDownStateChange: PropTypes.func.isRequired,
  showPointOfContact: PropTypes.bool.isRequired,
  onShowPointOfContactChange: PropTypes.func.isRequired,
};

export default PointOfContact;
