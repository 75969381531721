import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from '../useInjectReducer';
import { isJobErroredSelector, isJobInitializedSelector, jobDetailsSelector, key } from './selectors';
import reducer from './reducer';
import { useInjectSaga } from '../useInjectSaga';
import saga from './sagas';
import { makeSelectIsLoading } from '../../redux/loadingSelector';
import { loadJob } from './actions';

const useJob = ({ jobId }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const isJobLoading = useSelector(makeSelectIsLoading('job'));
  const isJobInitialized = useSelector(isJobInitializedSelector);
  const hasJobInitializationFailed = useSelector(isJobErroredSelector);
  const job = useSelector(jobDetailsSelector);
  const { slug } = job;
  const dispatch = useDispatch();

  const shouldRetrieveJob = useMemo(() => {
    if (!isJobLoading && !isJobInitialized && !hasJobInitializationFailed && jobId) {
      return true;
    }

    return !!(jobId && isJobInitialized && slug !== jobId);
  }, [hasJobInitializationFailed, isJobInitialized, isJobLoading, jobId, slug]);

  useEffect(() => {
    if (shouldRetrieveJob) {
      dispatch(loadJob({ jobId }));
    }
  }, [shouldRetrieveJob, jobId, dispatch]);

  return {
    job,
    isJobLoading,
    isJobInitialized,
    hasJobInitializationFailed,
  };
};

export default useJob;
