import axios from 'axios';
import humps from 'humps';

import { getItem } from 'helpers/store';
import APIError from './APIError';
import { trackException } from '../helpers/errorTracker';

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
  },
  transformResponse: [...axios.defaults.transformResponse, (data) => humps.camelizeKeys(data)],
  transformRequest: [(data) => humps.decamelizeKeys(data), ...axios.defaults.transformRequest],
};

const authenticationHeaders = () => {
  const authToken = getItem('authToken');
  if (authToken) {
    return {
      'X-User-Token': authToken,
    };
  }

  return {};
};

/**
 * This is a simple function that returns a promise that will allow a user to communicate with an api. By default it
 * assumes that the request method is 'get' but if the user desires other methods they can be used by sending a
 * method param in the option object as per axios documentation.
 *
 * @param url - The URL of the API you want to call
 * @param options - axios options for the api request as per axios docs: https://github.com/axios/axios
 * @returns {AxiosPromise}
 */
export const request = ({ url, options = {} }) => {
  const customOptions = defaultOptions;
  customOptions.method = 'get';
  return axios(url, {
    ...customOptions,
    headers: {
      ...defaultOptions.headers,
      ...authenticationHeaders(),
    },
    ...options,
    params: humps.decamelizeKeys(options.params),
  }).catch((error) => {
    trackException(error, { url, options });
    const exception = new APIError(`Axios Error: ${url}`);
    exception.response = error.response;
    throw exception;
  });
};

// do not use this function, it is deprecated. use the request function for all api calls.
export const get = async (url, options = {}) =>
  axios.get(url, {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...authenticationHeaders(),
    },
    ...options,
    params: humps.decamelizeKeys(options.params),
  });

// do not use this function, it is deprecated. use the request function for all api calls.
export const post = async (url, data, options = {}) =>
  axios.post(url, data, {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...authenticationHeaders(),
    },
    ...options,
    params: humps.decamelizeKeys(options.params),
  });

// do not use this function, it is deprecated. use the request function for all api calls.
export const put = async (url, data, options = {}) =>
  axios.put(url, data, {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...authenticationHeaders(),
    },
    ...options,
    params: humps.decamelizeKeys(options.params),
  });

// do not use this function, it is deprecated. use the request function for all api calls.
export const del = async (url, options = {}) =>
  axios.delete(url, {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...authenticationHeaders(),
    },
    ...options,
    params: humps.decamelizeKeys(options.params),
  });
