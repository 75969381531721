import React from 'react';
import { ChevronDown } from 'lucide-react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ProfilePicture } from '@getro/rombo';

const ActiveNetwork = ({ current, canSelectNetwork }) => (
  <Flex alignItems="center" maxWidth="auto" sx={{ gap: '8px' }}>
    <Box width="24px" height="24px" flex="0 0 auto">
      <ProfilePicture imageUrl={current.logo} name={current.name} variant="square" />
    </Box>
    <Text
      color="neutral.900"
      fontWeight="medium"
      overflow="hidden"
      textAlign="left"
      fontSize={[1, 2]}
      sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {current.name}
    </Text>

    {canSelectNetwork && (
      <Flex alignItems="center" color="text.main" flex="0 0 auto">
        <Box as={ChevronDown} height="16px" width="16px" aria-hidden="true" />
      </Flex>
    )}
  </Flex>
);

ActiveNetwork.propTypes = {
  current: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  canSelectNetwork: PropTypes.bool,
};

ActiveNetwork.defaultProps = {
  canSelectNetwork: false,
};

export default ActiveNetwork;
