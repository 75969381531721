import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Filter from '../../../components/molecules/Filter';

export const JOBS_SOURCE = [
  { label: 'Any', value: '' },
  { label: 'Daily update', value: 'daily_update' },
  { label: 'Monthly update', value: 'monthly_update' },
  { label: 'Under review', value: 'under_review' },
  { label: 'Excluded', value: 'excluded' },
  { label: 'Missing', value: 'missing' },
];

const JobSourceFilter = ({ value, onChange, embeddedInModal, onDropDownStateChange, showLabel }) => {
  const selectedOption = useMemo(() => JOBS_SOURCE.find((i) => i.value === value), [value]) ?? null;
  return (
    <Filter
      showLabel={showLabel}
      label="Job source"
      name="ocpStatus"
      placeholder="Job Source"
      onChange={(item) => onChange(item)}
      value={selectedOption}
      options={JOBS_SOURCE}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

JobSourceFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
  showLabel: PropTypes.bool,
};

JobSourceFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
  showLabel: true,
};

export default JobSourceFilter;
