import { createActions } from 'redux-actions';

export const { loadSendShareEmail, loadedSendShareEmail, errorSendShareEmail } = createActions(
  'LOAD_SEND_SHARE_EMAIL',
  'LOADED_SEND_SHARE_EMAIL',
  'ERROR_SEND_SHARE_EMAIL',
);

export const { loadShareUserOptions, loadedShareUserOptions, errorShareUserOptions } = createActions(
  'LOAD_SHARE_USER_OPTIONS',
  'LOADED_SHARE_USER_OPTIONS',
  'ERROR_SHARE_USER_OPTIONS',
);

export const { resetSharePage, noUserFoundInvite } = createActions('RESET_SHARE_PAGE', 'NO_USER_FOUND_INVITE');
