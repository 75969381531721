import { takeLatest, put, call, select } from 'redux-saga/effects';
import { request } from 'api';
import { trackEvent } from 'helpers/analytics';
import { updateNetwork } from 'actions/networksActions';
import { activeNetworkSelector } from 'redux/selectors';
import { trackException } from 'helpers/errorTracker';
import toast from 'react-hot-toast';
import {
  loadGeneralSettings,
  loadedGeneralSettings,
  errorGeneralSettings,
  loadSaveGeneralSettings,
  loadedSaveGeneralSettings,
  errorSaveGeneralSettings,
  loadSaveGdprSettings,
  loadedSaveGdprSettings,
  errorSaveGdprSettings,
  loadTalentNetworkSettings,
  loadedTalentNetworkSettings,
  errorTalentNetworkSettings,
  loadUpdateTalentNetworkSettings,
  loadedUpdateTalentNetworkSettings,
  errorUpdateTalentNetworkSettings,
} from './actions';

function* fetchGeneralSettings({ payload }) {
  try {
    const { networkId } = payload;
    const requestUrl = `/collections/${networkId}/settings/general`;
    const response = yield call(request, {
      url: requestUrl,
    });

    yield put(
      loadedGeneralSettings({
        id: networkId,
        settings: response.data,
      }),
    );
  } catch (error) {
    yield put(errorGeneralSettings({ error }));
  }
}

function* saveGeneralSettings({ payload }) {
  try {
    const { networkId, values } = payload;
    const requestUrl = `/collections/${networkId}/settings/general`;
    const response = yield call(request, {
      url: requestUrl,
      options: {
        method: 'put',
        data: {
          ...values,
          administrators: undefined,
          ambassadors: undefined,
        },
      },
    });
    const { data } = response;
    yield put(updateNetwork(data));
    yield put(loadedSaveGeneralSettings({ values }));
    yield call(trackEvent, 'network_settings:edit_general_info');
  } catch (error) {
    yield put(errorSaveGeneralSettings({ error }));
  }
}

function* saveGdprSettings({ payload }) {
  try {
    const { networkId, values } = payload;
    const requestUrl = `/collections/${networkId}/settings/general`;
    const response = yield call(request, {
      url: requestUrl,
      options: {
        method: 'put',
        data: {
          ...values,
          administrators: undefined,
          ambassadors: undefined,
        },
      },
    });
    const { data } = response;
    yield put(updateNetwork(data));
    yield put(loadedSaveGdprSettings({ values }));
    yield call(trackEvent, 'network_settings:edit_gdpr_info');
  } catch (error) {
    yield put(errorSaveGdprSettings({ error }));
  }
}

function* loadTalentNetworkSettingsEvent() {
  try {
    const network = yield select(activeNetworkSelector);

    const requestUrl = `/collections/${network.id}/settings/talent_network`;
    const response = yield call(request, {
      url: requestUrl,
    });

    yield put(loadedTalentNetworkSettings({ id: network.id, ...response.data }));
  } catch (error) {
    yield put(errorTalentNetworkSettings(error));
    yield call(trackException, error);
  }
}

function* updateTalentNetworkSettingsEvent({ payload }) {
  try {
    const network = yield select(activeNetworkSelector);

    const requestUrl = `/collections/${network.id}/settings/talent_network`;
    const response = yield call(request, {
      url: requestUrl,
      options: {
        method: 'put',
        data: payload,
      },
    });

    yield put(loadedUpdateTalentNetworkSettings({ id: network.id, ...response.data }));
    yield call(toast, 'Settings updated', { icon: 'success', id: 'talent_network_settings_updated' });
  } catch (error) {
    yield put(errorUpdateTalentNetworkSettings(error));
    yield call(trackException, error);
  }
}

export default function* userProfileSaga() {
  yield takeLatest(loadGeneralSettings().type, fetchGeneralSettings);
  yield takeLatest(loadSaveGeneralSettings().type, saveGeneralSettings);
  yield takeLatest(loadSaveGdprSettings().type, saveGdprSettings);
  yield takeLatest(loadTalentNetworkSettings().type, loadTalentNetworkSettingsEvent);
  yield takeLatest(loadUpdateTalentNetworkSettings().type, updateTalentNetworkSettingsEvent);
}
