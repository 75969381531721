import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@getro/rombo';
import { Formik, Form } from 'formik';
import { Flex } from 'rebass/styled-components';
import FeatureJob from '../FeatureJob';

export const FeatureJobModal = ({ featureJobValue, isOpen, title, onClose, onSubmit, isSubmitting, error }) => {
  const ref = useRef();

  const handleSubmit = ({ featured }) => {
    onSubmit(featured);
  };

  const initialValues = {
    featured: featureJobValue === 'featured' ? 'not_featured' : 'featured',
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      onCancel={onClose}
      isOpen={isOpen}
      title={title}
      actions={
        <Flex justifyContent="flex-end" alignItems="baseLine">
          <Button onClick={onClose} variant="secondary" mr={1}>
            Cancel
          </Button>
          <Button onClick={() => ref.current?.handleSubmit()} loading={isSubmitting}>
            Update
          </Button>
        </Flex>
      }
    >
      <Formik initialValues={initialValues} innerRef={ref} onSubmit={handleSubmit}>
        <Form>
          <FeatureJob id="featured" name="featured" title={title} error={error} onClose={onClose} />
        </Form>
      </Formik>
    </Modal>
  );
};

FeatureJobModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  featureJobValue: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  error: PropTypes.bool.isRequired,
};

FeatureJobModal.defaultProps = {
  isOpen: false,
  title: '',
  featureJobValue: null,
  isSubmitting: false,
};

export default FeatureJobModal;
