import React from 'react';
import PropTypes from 'prop-types';

import strings from 'strings';

import { Loader, Box } from '@getro/rombo';
import Table from 'components/molecules/Table';

import PercentageChange from './PercentageChange';

function TableView({ data, fetching }) {
  // Get columns from first row and format the content
  const getColumns = () => {
    if (!data || !data.length) {
      return [];
    }

    return Object.keys(data[0]).map((k) => ({ key: k, content: getColumnHeaderContent(k) }));
  };

  const getColumnHeaderContent = (key) => {
    const jfKey = strings.analytics.jobFunctions[key];
    if (jfKey) return <div className="AnalyticsTable__cell--number">{jfKey}</div>;
    const translatedKey = strings.analytics.displayData[key] || key;
    switch (key) {
      case 'change':
      case 'companyProfileViews':
      case 'data':
      case 'daysPosted':
      case 'introductionsAccepted':
      case 'introductionsRequested':
      case 'jobsClicks':
      case 'featuredJobsClicks':
      case 'jobFunctionPosted':
      case 'jobFunctionsSearched':
      case 'locationsPosted':
      case 'locationsSearched':
      case 'newJobs':
      case 'removedJobs':
      case 'totalJobs':
      case 'uniqueVisitors':
        return <div className="AnalyticsTable__cell--number">{translatedKey}</div>;
      case 'jobFunctions':
        return <div className="AnalyticsTable__cell--job-function">{translatedKey}</div>;
      case 'jobTitle':
        return <div className="AnalyticsTable__cell--job-title">{translatedKey}</div>;
      case 'locations':
        return <div className="AnalyticsTable__cell--location">{translatedKey}</div>;
      case 'company':
        return <div className="AnalyticsTable__cell--company">{translatedKey}</div>;
      default:
        return translatedKey;
    }
  };

  // Iterate over the data array and format the content
  const prepareData = () =>
    data.map((row) => {
      const result = { ...row };
      return Object.keys(result).map((k) => ({
        key: k,
        content: getCellContent(k, result[k]),
      }));
    });

  const getCellContent = (key, value) => {
    const jfKey = strings.analytics.jobFunctions[key];
    if (jfKey) return <div className="AnalyticsTable__cell--number">{value}</div>;
    switch (key) {
      case 'companyProfileViews':
      case 'data':
      case 'daysPosted':
      case 'introductionsAccepted':
      case 'introductionsRequested':
      case 'jobsClicks':
      case 'featuredJobsClicks':
      case 'jobFunctionPosted':
      case 'jobFunctionsSearched':
      case 'locationsPosted':
      case 'locationsSearched':
      case 'newJobs':
      case 'removedJobs':
      case 'totalJobs':
      case 'uniqueVisitors':
        return <div className="AnalyticsTable__cell--number">{value}</div>;
      case 'change':
        return <PercentageChange change={value} />;
      case 'url':
        return (
          <a
            href={value}
            target="_blank"
            className="AnalyticsTable__cell--link"
            rel="noopener noreferrer"
            title={value}
          >
            {value}
          </a>
        );
      default:
        return value;
    }
  };

  // Rendering
  if (fetching) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  if (!fetching && (!data || !data.length)) {
    return <Box data-cy="analytics-no-table-data">No data for selected filters.</Box>;
  }

  return (
    <Box>
      <Table columns={getColumns()} data={prepareData(data)} striped borders="none" />
    </Box>
  );
}

TableView.propTypes = {
  data: PropTypes.array,
  fetching: PropTypes.bool,
};

TableView.defaultProps = {
  data: [],
  fetching: false,
};

export default TableView;
