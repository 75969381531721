import React from 'react';
import { Box } from 'rebass/styled-components';
import { Loader } from '@getro/rombo';

export const LoadMore = () => (
  <Box
    data-testid="table-load-more"
    role="cell"
    textAlign="center"
    width={1}
    sx={{ position: 'relative', height: '80px', bottom: 0, width: '100%', left: 0, display: 'flex' }}
  >
    <Loader tx="dataTable" isLoading variant="loader" />
  </Box>
);
