import React from 'react';

import pageHoc from 'hocs/pageHoc';

import MainLayout from 'components/layout/Main';
import AccountLayout from '../../components/layout/AccountLayout';
import Profile from './UserProfile/index';

const pageTitle = 'My account';

const ProfileTab = () => (
  <MainLayout>
    <AccountLayout>
      <Profile />
    </AccountLayout>
  </MainLayout>
);

export default pageHoc({ title: pageTitle })(ProfileTab);
