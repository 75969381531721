import settings from 'settings';

const strings = {
  careerPageStatusExplanations: {
    automatic: null,
    manual:
      'Because this job source does not follow a standard format we can only update it monthly. <a target="_blank" href="https://help.getro.com/support/solutions/articles/65000168381-how-to-ensure-your-job-postings-are-updated-daily">Learn more</a>.',
    brand_new: 'Our team is working on configuring this job source, which should take no more than 2 business days.',
    requires_verification:
      'Our team is reviewing an issue with the job source. It should be resolved within 2 business days. ',
    errored: 'Our team detected an out of the ordinary issue with the job source. We are working hard to resolve this.',
    unidentifiable: 'Our team is setting up the job source.',
    bypassed:
      'Jobs cannot be extracted programmatically and there are too many jobs to extract them manually. <a target="_blank" href="https://help.getro.com/support/solutions/articles/65000168381-how-to-ensure-your-job-postings-are-updated-daily">Learn more</a>.',
    blocked:
      'The results of the last daily update were unexpected. Our team is investigating and will update the status shortly.',
    requires_retry:
      'The results of the last daily update were unexpected. A new attempt to extract the jobs from this source will be made within the next 24 hours.',
  },
  account: {
    emailAlreadyInUse: 'You can’t use this address because there is another account using it.',
  },
  genericError: 'Something went wrong, please try again. If the problem persists, contact us at help@getro.com.',
  genericAuthError: "You don't have permissions to perform this action.",
  slogan: 'Take the manual work out of connecting people and companies in your network with Getro.',
  sloganHeader: 'Make the connection',
  session: {
    login: {
      title: 'Log in',
      subtitle: 'It’s good to see you again!',
    },
    recoverPassword: {
      title: 'Forgot your password?',
      subtitle: 'No worries, we will send instructions over email to get you back on track.',
    },
    noAccess: {
      title: 'Sorry, this beta platform is only for admins and ambassadors at the moment.',
      text: 'Please check back later!',
    },
    setNewPassword: {
      title: 'Set a new password',
      subtitle: '',
    },
  },
  yes: 'Yes',
  no: 'No',
  ocpStatuses: {
    automatic: 'Active',
    manual: 'Active',
    blocked: 'Active',
    unidentifiable: 'Active',
    brand_new: 'Verifying',
    requires_verification: 'Verifying',
    deprecated: 'Verifying',
    errored: 'Verifying',
  },
  jobs: {
    sources: {
      admin_portal: 'Manual',
      career_page: 'Automatic',
      manual: 'Automatic',
    },
    job_sources_for_admin_portal: {
      posted: 'Posted',
      job_source: 'Extracted',
    },
    job_sources_tooltips_for_admin_portal: {
      posted: 'Manually posted by a network or company admin',
      job_source: 'Automatically extracted from the company job source',
    },
    job_status_tooltips_for_admin_portal: {
      hidden: "Not visible in the job board because it doesn't match job rules.",
      active: 'Currently visible in the job board.',
      expired: 'Not visible in the job board, extend the expiration date to make it visible.',
      deactivated: 'Already hired or no longer in need of this position.',
      closed: 'Already hired or no longer in need of this position.',
    },
    statuses: {
      active: 'Active',
      expired: 'Expired',
      deactivated: 'Deactivated',
      closed: 'Closed',
    },
    compensationPeriod: {
      year: 'Year',
      month: 'Month',
      hour: 'Hour',
    },
    showCompensation: 'Show compensation to job seekers',
    viaEmail: 'Via email',
    linkToWebsite: 'Link to website',
  },
  noResults: 'No results for "[string]"',
  onboarding: {
    jobBoard: {
      networkManager: {
        header: 'Welcome to the [Network Name] network',
        body: 'You’ve been invited to become a network administrator to manage companies in the network and monitor the overall performance of the job board.',
      },
      organizationManager: {
        header: 'Welcome to the [Network Name] network',
        body: 'You’ve been invited to become a company administrator for [Org Name] to manage your company presence in the network.',
      },
      ambassador: {
        header: 'Welcome to the [Network Name] network',
        body: '',
      },
      default: {
        header: `Welcome to ${settings.companyName}`,
        body: '',
      },
    },
    talentNetwork: {
      networkManager: {
        header: 'Welcome to the [Network Name] talent network',
        body: `You’ve been invited to become a network administrator to track companies and talent in the network and monitor the overall network performance.`,
      },
      organizationManager: {
        header: 'Welcome to the [Network Name] talent network',
        body: `You’ve been invited to become a company administrator for [Org Name]. You’ll be able to connect with talent directly to accelerate hiring for your company.`,
      },
      ambassador: {
        header: 'Welcome to the [Network Name] talent network',
        body: 'You’ve been invited to become a network ambassador to refer talent to the network.',
      },
      default: {
        header: `Welcome to ${settings.companyName}`,
        body: '',
      },
    },
    connect: {
      networkManager: {
        header: 'Welcome to the [Network Name] network',
        body: `You’ve been invited to be a network administrator. Create an account to get started.`,
      },
      organizationManager: {
        header: 'Welcome to the [Network Name] network',
        body: `You’ve been invited to become a company administrator for [Org Name]. You’ll be able to connect with talent directly to accelerate hiring for your company.`,
      },
      ambassador: {
        header: 'Welcome to the [Network Name] network',
        body: 'You’ve been invited to become a network ambassador to refer talent to the network.',
      },
      default: {
        header: `Welcome to ${settings.companyName}`,
        body: '',
      },
    },
  },
  profile: {
    mentorship: { label: 'Interested in mentoring others', value: 'mentoring' },
    visibility: [
      { label: 'Visible to companies', value: 'visible' },
      { label: 'Hidden from some companies and members', value: 'restricted' },
      { label: 'Hidden from companies', value: 'hidden' },
    ],
    remoteWorkRelocation: {
      remoteWork: { label: 'Open to remote work', value: 'remoteWork' },
      willWorkAnywhere: { label: 'Open to working anywhere', value: 'willWorkAnywhere' },
    },
    employment: {
      employment_type_unspecified: 'Unspecified',
      full_time: 'Full time',
      part_time: 'Part time',
      contractor: 'Contractor',
      intern: 'Intern',
      volunteer: 'Volunteer',
      other_employment_type: 'Other',
    },
    opportunity: {
      employment: 'Open to new opportunities',
      notEmployment: 'Not interested in learning about new opportunities',
    },
    seniorities: {
      job_level_unspecified: { title: 'Unspecified', description: '' },
      entry_level: {
        title: 'Entry level',
        description: '0-2 years experience',
      },
      experienced: { title: 'Experienced', description: '2+ years of experience' },
      manager: {
        title: 'Manager',
        description: 'Entry- to mid-level managers responsible for managing a team',
      },
      director: {
        title: 'Director',
        description: 'Senior-level managers responsible for managing teams of managers',
      },
      executive: {
        title: 'Executive',
        description: 'Executive-level managers and above, including C-level positions',
      },
    },
    jobSearchStatuses: {
      not_looking: 'Not looking',
      casually_browsing: 'Casually browsing',
      actively_looking: 'Actively looking',
    },
    jobSearchStatusOptions: [
      { label: 'Not Looking', value: 'not_looking' },
      { label: 'Casually browsing', value: 'casually_browsing' },
      { label: 'Actively looking', value: 'actively_looking' },
    ],
    usWorkStatus: {
      not_require_sponsorship: 'Authorized, will not require sponsorship',
      require_sponsorship: 'Authorized, will require sponsorship',
      not_authorized: 'Not authorized',
    },
  },
  companyProfile: {
    domainTaken: 'Another company is already using this domain.',
  },
  analytics: {
    metrics: {
      companies_performance: {
        label: 'Companies performance',
        description:
          'This detailed report shows visitors interest to your job board by company and by job function within the selected timeframe.',
      },
      company_profile_views: {
        label: 'Company profile views',
        description: 'The total number of times company profiles were viewed within the selected timeframe.',
      },
      introductions_accepted: {
        label: 'Introductions accepted',
        description:
          'The total number of introductions accepted in your network (from employer or talent) within the selected timeframe.',
      },
      introductions_performance: {
        label: 'Introductions performance',
        description:
          'This detailed report shows all introduction activity happening in your talent network within the selected timeframe. ',
      },
      introductions_requested: {
        label: 'Introductions requested',
        description:
          'The total number of introductions requested in your network (from employer or talent) within the selected timeframe.',
      },
      jobs_clicks: {
        label: 'Jobs clicks',
        description:
          'The total number of times jobs on your job board are viewed within the selected timeframe. When a job is clicked, the user is directed to the job posting.',
      },
      job_functions_posted: {
        label: 'Job functions',
        description:
          'The number of jobs shown on your job board during the selected timeframe that are categorized under a specific job function in ranking order.',
      },
      job_functions_searched: {
        label: 'Job function searches',
        description:
          'The number of times your job board has been filtered by a specific job function in ranking order (this does not include text typed in the search bar).',
      },
      jobs_performance: {
        label: 'Jobs performance',
        description:
          'This detailed report shows information and visitors activity for each job on your job board within the selected timeframe.',
      },
      locations_posted: {
        label: 'Job locations',
        description:
          'The number of jobs shown on your job board during the selected timeframe that are in a specific location in ranking order.',
      },
      locations_searched: {
        label: 'Location searches',
        description:
          'The number of times your job board has been filtered by a specific location in ranking order (this does not include the text typed in search bar).',
      },
      new_jobs: {
        label: 'Added jobs',
        description: 'The number of jobs added to your job board within the selected timeframe.',
      },
      removed_jobs: {
        label: 'Removed jobs',
        description: 'The number of jobs removed from your job board within the selected timeframe.',
      },
      total_jobs: {
        label: 'Total jobs',
        description: 'The number of jobs posted on your job board within the selected timeframe.',
      },
      page_visits: {
        label: 'Page visits',
        description:
          'The total number of individual page visits on your job board within the selected timeframe. This counts every page load, so a single user can contribute to multiple page visits if they view more than one page.',
      },
      unique_visitors: {
        label: 'Unique visitors',
        description:
          'The number of unique visitors to your job board within the selected timeframe. This counts only the first time a person visits your job board.',
      },
    },
    intervals: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
    },
    displayData: {
      change: '% Change',
      company: 'Company',
      companyName: 'Company name',
      companyProfileViews: 'Company profile views',
      date: 'Date',
      dateAccepted: 'Date accepted',
      datePosted: 'Date added',
      dateRemoved: 'Date removed',
      dateSent: 'Date sent',
      daysPosted: 'Age (days)',
      introductionsAccepted: 'Introductions accepted',
      introductionsRequested: 'Introductions requested',
      introductionType: 'Introduction type',
      introId: 'Intro ID',
      introStatus: 'Intro status',
      jobFunction: 'Job function',
      jobFunctions: 'Job functions',
      jobFunctionsSearched: 'Job functions searched',
      jobFunctionPosted: 'Occurrences',
      jobId: 'Job ID',
      jobTitle: 'Job title',
      jobsClicks: 'Jobs clicks',
      featuredJobsClicks: 'Featured jobs clicks',
      locations: 'Locations',
      locationsPosted: 'Occurrences',
      locationsSearched: 'Locations searched',
      newJobs: 'Added jobs',
      organization: 'Organization',
      postedOnJobBoard: 'Posted on job board',
      recipientRole: 'Recipient role',
      removedJobs: 'Removed jobs',
      requestorRole: 'Requestor role',
      totalJobs: 'Total jobs',
      uniqueVisitors: 'Page visits',
      url: 'URL',
    },
    jobFunctions: {
      jfAccountingFinanceClicks: 'Accounting & finance clicks',
      jfAccountingFinanceJobs: 'Accounting & finance jobs',
      jfAdministrationClicks: 'Administration clicks',
      jfAdministrationJobs: 'Administration jobs',
      jfCustomerServiceClicks: 'Customer service clicks',
      jfCustomerServiceJobs: 'Customer service jobs',
      jfDataScienceClicks: 'Data science clicks',
      jfDataScienceJobs: 'Data science jobs',
      jfDesignClicks: 'Design clicks',
      jfDesignJobs: 'Design jobs',
      jfItClicks: 'IT clicks',
      jfItJobs: 'IT jobs',
      jfLegalClicks: 'Legal clicks',
      jfLegalJobs: 'Legal jobs',
      jfMarketingCommunicationsClicks: 'Marketing & communications clicks',
      jfMarketingCommunicationsJobs: 'Marketing & communications jobs',
      jfOperationsClicks: 'Operations clicks',
      jfOperationsJobs: 'Operations jobs',
      jfOtherEngineeringClicks: 'Other engineering clicks',
      jfOtherEngineeringJobs: 'Other engineering jobs',
      jfPeopleHrClicks: 'People & HR clicks',
      jfPeopleHrJobs: 'People & HR jobs',
      jfProductClicks: 'Product clicks',
      jfProductJobs: 'Product jobs',
      jfQualityAssuranceClicks: 'Quality assurance clicks',
      jfQualityAssuranceJobs: 'Quality assurance jobs',
      jfSalesBusinessDevelopmentClicks: 'Sales & business development clicks',
      jfSalesBusinessDevelopmentJobs: 'Sales & business development jobs',
      jfSoftwareEngineeringClicks: 'Software engineering clicks',
      jfSoftwareEngineeringJobs: 'Software engineering jobs',
    },
    products: {
      jobBoard: 'Job board',
      talentNetwork: 'Talent network',
    },
  },
  settings: {
    tabsOrder: {
      companies: 'Companies',
      jobs: 'Jobs',
      talent_network: 'Talent network',
    },
    customCopy: {
      callToActionTooltip:
        'This is the text a visitor to your job board sees in the header encouraging them to join your talent network.',
      talentNetworkTab: `When a visitor to your job board clicks on the talent network tab, this is the text they see on the left.<br/><br/>
         <strong>Pro tip:</strong> Use this space to communicate the values and purpose of your talent network.`,
      signUpProcess: `People who sign up for your talent network will see this message before they start building a profile.<br/><br/>
         <strong>Pro tip:</strong>  Let the voice of your network shine! Make people feel welcome. If you want to attract a specific type of profile, specify that here.`,
    },
    talentNetworkVisibility: `If you want company admins to edit their company information and manually post jobs while you privately manage your talent network – or are just getting started – you can remove their visibility. This can be changed at any time.`,
  },
  validation: {
    domainError: 'Your domain should look like getro.com, not like www.getro.com or https://www.getro.com.',
    urlError: 'Please, enter a valid website URL or domain',
    companyInvalidWebsiteError: 'Enter a valid website, like company.com or www.company.com.',
    companyRequiredWebsiteError: 'Company website is required',
    linkedinUrlError: 'Your profile URL should look like http://linkedin.com/in/your-user-name',
    passwordLength: 'Password should be 8 characters at least.',
    termsAccepted: 'You must accept our terms of service and privacy policy.',
  },
  vouch: {
    scoreOptions: {
      1: 'Low info personally and professionally',
      2: 'High confidence personally; low info professionally',
      3: 'Low info personally; high confidence professionally',
      4: 'High confidence personally and professionally',
      5: 'High confidence personally and professionally, plus, I would bet my career on them',
    },
    relationshipLevelOptions: {
      1: 'I’ve worked with [Member Name]',
      2: 'I’ve interviewed [Member Name]',
      3: 'I’ve been mentored by [Member Name]',
      4: 'I’ve mentored [Member Name]',
      5: 'I don’t know [Member Name] personally',
    },
    relationshipTextPlaceholder:
      '[Member Name] is an amazing engineering talent who is both technically creative and a great team lead. I would trust her on my leadership team.',
  },
  emailNames: {
    analytics_daily: 'daily analytics',
    analytics_weekly: 'weekly analytics',
    analytics_monthly: 'monthly analytics',
    analytics_quarterly: 'quarterly analytics',
  },
  ocpSearchStatuses: {
    not_found: 'no_ocp_found',
    in_progress: 'in_progress',
    completed: 'completed',
  },
  expirationDate: {
    0: 'Your job is now visible and will never expire',
    30: 'Your job is now visible for 30 days',
    60: 'Your job is now visible for 60 days',
    180: 'Your job is now visible for 6 months',
  },
  companyDomainTakenError: 'Domain has already been taken',
  sharedLists: {
    defaultDescription:
      "Introductions made easy ✨: Looking to connect with our firm's contacts? Use this list to request intros and expand your network.",
  },
};
export default strings;
