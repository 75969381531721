import { createEntityAdapter } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import isEqual from 'lodash.isequal';
import queryString from 'query-string';
import { parseFiltersData } from 'services/contacts/filters';
import { networkBaseQueryV2 } from '../queries';
import { mapContactsV2ToV1 } from './formatter';
import deserialize from '../../helpers/getroPropertyMapper';

const itemsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const itemsSelector = itemsAdapter.getSelectors();

export const contactsV2Api = createApi({
  reducerPath: 'contactsV2Api',
  baseQuery: networkBaseQueryV2,
  tagTypes: ['importers', 'contacts'],
  endpoints: (builder) => ({
    upgradeConnect: builder.mutation({
      query: () => ({
        url: 'plans/upgrade_connect',
        method: 'put',
      }),
      transformResponse: (response) => response.data.attributes,
    }),
    requestConnectDemo: builder.mutation({
      query: () => ({
        url: 'plans/schedule_connect_demo',
        method: 'put',
      }),
    }),
    getContactById: builder.query({
      query: (id) => ({
        url: `/contacts/${id}`,
      }),
      transformResponse: mapContactsV2ToV1,
      providesTags: ['contact'],
    }),
    getContacts: builder.query({
      query: ({ filters, listId, ...rest }) => {
        const parsedFilters = parseFiltersData(filters, true);
        if (listId) {
          parsedFilters.sharedListIds.push(listId);
        }

        return {
          url: '/contacts',
          params: {
            ...parsedFilters,
            ...(listId ? { sharedListId: listId } : undefined),
            ...rest,
          },
        };
      },
      transformResponse: (response, _, arg) => ({
        meta: response.meta,
        page: arg.page,
        ...itemsAdapter.addMany(itemsAdapter.getInitialState(), mapContactsV2ToV1(response)),
      }),
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const filters = queryString.stringify(parseFiltersData(queryArgs?.filters || {}, true), {
          arrayFormat: 'bracket',
        });

        return queryArgs?.listId ? { listId: queryArgs?.listId?.toString() } : `${endpointName}(${filters})`;
      },
      merge: (currentState, incomingState) => {
        if (incomingState.meta.queryTotal === 0) {
          itemsAdapter.removeAll(currentState);
        } else if (currentState.page < incomingState.page) {
          itemsAdapter.setMany(currentState, itemsSelector.selectAll(incomingState));
        } else {
          itemsAdapter.removeAll(currentState);
          itemsAdapter.addMany(currentState, itemsSelector.selectAll(incomingState));
        }

        Object.assign(currentState, {
          meta: incomingState.meta,
        });
      },
      providesTags: (data, items, args) =>
        args.listId ? ['shared-lists-contacts', { type: 'contacts', id: args.listId }] : ['contacts'],
    }),
    getImporters: builder.query({
      query: () => ({
        url: '/contacts/importers',
      }),
      providesTags: ['importers'],
      transformResponse: (response) => {
        let importers = deserialize(response);
        importers = importers.sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()));

        return {
          importers,
        };
      },
    }),
    importContactsFromCsv: builder.mutation({
      query: ({ importData, fileName }) => ({
        url: '/contacts/import',
        method: 'post',
        data: {
          ...importData,
          fileName,
        },
      }),
    }),
  }),
});

export const {
  useUpgradeConnectMutation,
  useRequestConnectDemoMutation,
  useGetContactsQuery,
  useGetContactByIdQuery,
  useLazyGetContactsQuery,
  useLazyGetImportersQuery,
  useImportContactsFromCsvMutation,
} = contactsV2Api;
