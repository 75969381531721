import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { ContactDetailSideSkeleton, ContactDetailSkeleton } from './skeleton';

export const ContactDetailLoader = () => (
  <Flex width="100%">
    <Box width={['100%', '100%', 'calc(100% - 40px)']} maxWidth="1100px">
      <ContactDetailSkeleton />
    </Box>

    <Box
      display={['none', 'none', 'block']}
      flex="0 0 auto"
      ml={[0, 0, '24px']}
      mt={['24px', 0, 0]}
      pl={[0, 0, '24px']}
      width={['100%', '100%', '320px']}
      sx={{ borderLeftWidth: [0, 0, 1], borderLeftStyle: 'solid', borderLeftColor: 'neutral.100' }}
    >
      <ContactDetailSideSkeleton />
    </Box>
  </Flex>
);
