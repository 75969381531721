import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'debounce';
import { useLazyGetSharedListsQuery } from '../../../services/contacts';
import { Search } from '../../atoms/search';
import { Dropdown } from '../dropdown';
import { Card } from '../../atoms/Card';

export const SearchSharedListsDropdown = ({ trigger, value, onChange, onCreate, extraOptions, placement }) => {
  const [getSharedLists, { data, isFetching }] = useLazyGetSharedListsQuery();
  const searchTerm = useRef('');
  const [keyword, setKeyword] = useState('');

  const onCurrentChange = (sharedLists, opt, checked, term) => {
    searchTerm.current = term;

    const result = sharedLists.map((list) => data.sharedLists.find((item) => item.id === list.value));

    onChange(result, opt, checked);
  };

  const currentOnChange = debounce((searchKeyword) => {
    setKeyword(searchKeyword);

    getSharedLists({
      keyword: searchKeyword,
    });
  }, 500);

  return (
    <Dropdown
      placement={placement}
      trigger={trigger}
      onOpen={() => getSharedLists()}
      extraOptions={{
        strategy: 'fixed',
        ...extraOptions,
      }}
    >
      <Card width="320px" height="300px" p="16px" bg="neutral.0">
        <Search
          isLoading={isFetching && searchTerm.current !== keyword}
          options={data?.sharedLists?.map((item) => ({ label: item.name, value: item.id })) || []}
          value={value}
          onChange={onCurrentChange}
          onCreate={onCreate}
          onSearch={currentOnChange}
        />
      </Card>
    </Dropdown>
  );
};

SearchSharedListsDropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  placement: PropTypes.string,
  extraOptions: PropTypes.object,
};

SearchSharedListsDropdown.defaultProps = {
  value: [],
  onCreate: null,
  placement: 'top',
  extraOptions: {},
};
