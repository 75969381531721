import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, ReduxSelect, Tag, FormField, Button, Badge, FormSelect } from '@getro/rombo';
import { Field, Form } from 'formik';
import { Box, Flex, Text } from 'rebass/styled-components';
import { MemberTag } from '../../molecules/memberTag';
import strings from '../../../strings';
import { membershipStatusOptions, vouchedBySomeoneOptions } from './options';
import { onCheckboxChange, sectionCount } from './helpers';

const {
  jobSearchStatuses: jobSearchStatusOptions,
  seniorities: seniorityOptions,
  remoteWorkRelocation: remoteWorkRelocationOptions,
  employment: employmentOptions,
  mentorship: mentorshipOptions,
  usWorkStatus: usWorkStatusOptions,
} = strings.profile;

export const MemberFilters = ({
  onlySmartListFilters,
  isCompanyAdmin,
  formRef,
  skills,
  preferredLocations,
  vouchedBy,
  values,
  setFieldValue,
}) => (
  <Flex as={onlySmartListFilters ? null : Form} sx={{ flexDirection: ['column'] }}>
    <Box width={[1]} mb={4}>
      <Field
        component={FormField}
        value={values.keyword}
        name="keyword"
        placeholder="Search job title, companies, industry..."
      />
    </Box>

    <Text sx={{ lineHeight: 1.7 }} as="h4" mb={3} pb={2} variant="header.bottomBorder">
      Professional background
      {sectionCount({ formRef, fields: ['skillIds', 'seniorities'] }) && (
        <Badge variant="neutral" ml="2">
          {sectionCount({ formRef, fields: ['skillIds', 'seniorities'] })}
        </Badge>
      )}
    </Text>
    <Flex mb={4} flexDirection={['column', 'row']}>
      <Box as="fieldset" sx={{ border: 'none' }} width={[1, 1 / 2]} p={0} pr={[0, 3]} mb={[3, 0]}>
        <Box as="legend" variant="legend">
          Skills
        </Box>
        <ReduxSelect
          value={values.skillIds}
          id="skillIds"
          name="skillIds"
          placeholder="Add skill"
          isMulti
          components={{ ClearIndicator: null }}
          onChange={(e) => {
            setFieldValue('skillIds', e);
          }}
          onInputChange={skills.onInputChange}
          isLoading={skills.isLoading}
          options={skills.options}
        />
        {values.skillIds.length > 0 && (
          <Flex flexWrap="wrap">
            {values.skillIds.map((skill) => (
              <Tag
                key={`member_skill_${skill.value}`}
                variant="accent"
                mt={2}
                onRemove={() => {
                  setFieldValue(
                    'skillIds',
                    values.skillIds.filter((s) => s.value !== skill.value),
                  );
                }}
              >
                {skill.label}
              </Tag>
            ))}
          </Flex>
        )}
      </Box>
      <Box as="fieldset" sx={{ border: 'none' }} width={[1, 1 / 2]} p={0} pl={[0, 3]}>
        <Box as="legend" variant="legend">
          Experience
        </Box>
        <Flex flexDirection={['column']}>
          <Box>
            {seniorityOptions &&
              Object.entries(seniorityOptions)
                .filter(([k]) => k !== 'job_level_unspecified')
                .map(([key, { title }]) => (
                  <Checkbox
                    key={key}
                    checked={values.seniorities.indexOf(key) > -1}
                    name={`seniorities_${key}`}
                    id={`seniorities_${key}`}
                    value={key}
                    label={title}
                    containerProps={{ mb: 3 }}
                    onChange={(e) =>
                      onCheckboxChange({
                        values,
                        target: e.target,
                        key: 'seniorities',
                        setFieldValue,
                      })
                    }
                  />
                ))}
          </Box>
        </Flex>
      </Box>
    </Flex>

    <Text sx={{ lineHeight: 1.7 }} as="h4" mb={3} pb={2} variant="header.bottomBorder">
      Preferences
      {sectionCount({
        formRef,
        fields: ['locationIds', 'remoteWork', 'employment', 'willWorkAnywhere', 'mentorship'],
      }) && (
        <Badge variant="neutral" ml="2">
          {sectionCount({
            formRef,
            fields: ['locationIds', 'remoteWork', 'employment', 'willWorkAnywhere', 'mentorship'],
          })}
        </Badge>
      )}
    </Text>
    <Flex mb={onlySmartListFilters ? 0 : 4} flexWrap={['wrap']} flexDirection={['column', 'row']}>
      <Box as="fieldset" sx={{ border: 'none' }} p={0} width={[1, onlySmartListFilters ? 1 : 1 / 2]} pr={[3]} mb={[4]}>
        <Box as="legend" variant="legend">
          Preferred location(s)
        </Box>
        <ReduxSelect
          menuPlacement={onlySmartListFilters ? 'top' : 'bottom'}
          value={values.locationIds}
          id="locationIds"
          name="locationIds"
          placeholder="Add location"
          isMulti
          components={{ ClearIndicator: null }}
          onChange={(e) => {
            setFieldValue('locationIds', e);
          }}
          onInputChange={preferredLocations.onInputChange}
          isLoading={preferredLocations.isLoading}
          options={preferredLocations.options}
        />
        {values.locationIds.length > 0 && (
          <Flex flexDirection="row" flexWrap="wrap">
            {values.locationIds.map((location) => (
              <Tag
                key={`preferred_locations_${location.value}`}
                variant="accent"
                mt={2}
                onRemove={() => {
                  setFieldValue(
                    'locationIds',
                    values.locationIds.filter((s) => s.value !== location.value),
                  );
                }}
              >
                {location.label}
              </Tag>
            ))}
          </Flex>
        )}
      </Box>
      {!onlySmartListFilters && (
        <>
          <Box as="fieldset" sx={{ border: 'none' }} p={0} width={[1, 1 / 2]} pl={[0, 3]} mb={[4]}>
            <Box as="legend" variant="legend">
              Remote work and relocation
            </Box>
            <Flex flexDirection={['column']}>
              <Checkbox
                name="remoteWork"
                id="remoteWork"
                checked={values.remoteWork}
                value={remoteWorkRelocationOptions.remoteWork.value}
                label={remoteWorkRelocationOptions.remoteWork.label}
                containerProps={{ mb: 3 }}
                onChange={(e) => {
                  setFieldValue('remoteWork', e.target.checked);
                }}
              />
              <Checkbox
                name="willWorkAnywhere"
                id="willWorkAnywhere"
                checked={values.willWorkAnywhere}
                value={remoteWorkRelocationOptions.willWorkAnywhere.value}
                label={remoteWorkRelocationOptions.willWorkAnywhere.label}
                onChange={(e) => {
                  setFieldValue('willWorkAnywhere', e.target.checked);
                }}
              />
            </Flex>
          </Box>
          <Box as="fieldset" sx={{ border: 'none' }} p={0} width={[1, 1 / 2]} mb={[3, 0]}>
            <Box as="legend" variant="legend">
              Preferred job types
            </Box>
            <Flex flexDirection={['column']}>
              {employmentOptions &&
                Object.entries(employmentOptions)
                  .filter(([k]) => k !== 'employment_type_unspecified')
                  .map(([key, value]) => (
                    <Checkbox
                      checked={values.employment.indexOf(key) > -1}
                      key={key}
                      name={`employment_${key}`}
                      id={`employment_${key}`}
                      value={key}
                      label={value}
                      containerProps={{ mb: 3 }}
                      onChange={(e) =>
                        onCheckboxChange({
                          values,
                          target: e.target,
                          key: 'employment',
                          setFieldValue,
                        })
                      }
                    />
                  ))}
            </Flex>
          </Box>
          <Box as="fieldset" sx={{ border: 'none' }} p={0} width={[1, 1 / 2]} pl={[0, 3]}>
            <Box as="legend" variant="legend">
              Mentorship
            </Box>
            <Flex flexDirection={['column']}>
              {mentorshipOptions && (
                <Checkbox
                  name="mentorship"
                  id="mentorship"
                  checked={values.mentorship}
                  value={mentorshipOptions.value}
                  label={mentorshipOptions.label}
                  onChange={(e) => {
                    setFieldValue('mentorship', e.target.checked);
                  }}
                />
              )}
            </Flex>
          </Box>
        </>
      )}
    </Flex>

    {!onlySmartListFilters && (
      <>
        <Text sx={{ lineHeight: 1.7 }} as="h4" mb={3} pb={2} variant="header.bottomBorder">
          Job search status
          {sectionCount({ formRef, fields: ['jobSearchStatuses', 'usWorkStatus'] }) && (
            <Badge variant="neutral" ml="2">
              {sectionCount({ formRef, fields: ['jobSearchStatuses', 'usWorkStatus'] })}
            </Badge>
          )}
        </Text>
        <Flex flexDirection={['column', 'row']} mb={[4]}>
          <Box as="fieldset" sx={{ border: 'none' }} p={0} width={[1, 1 / 2]} pr={[0, 3]} mb={[3, 0]}>
            <Box as="legend" variant="legend">
              Job search stage
            </Box>
            <Flex flexDirection={['column']}>
              {jobSearchStatusOptions &&
                Object.entries(jobSearchStatusOptions).map(([key, value]) => (
                  <Checkbox
                    key={key}
                    checked={values.jobSearchStatuses.indexOf(key) > -1}
                    name={`jobSearchStatuses_${key}`}
                    id={`jobSearchStatuses_${key}`}
                    value={key}
                    label={value}
                    containerProps={{ mb: 3 }}
                    onChange={(e) =>
                      onCheckboxChange({
                        values,
                        target: e.target,
                        key: 'jobSearchStatuses',
                        setFieldValue,
                      })
                    }
                  />
                ))}
            </Flex>
          </Box>
          <Box as="fieldset" sx={{ border: 'none' }} p={0} width={[1, 1 / 2]} pl={[0, 3]}>
            <Box as="legend" variant="legend">
              US Work status
            </Box>
            <Flex flexDirection={['column']}>
              {usWorkStatusOptions &&
                Object.entries(usWorkStatusOptions).map(([key, value]) => (
                  <Checkbox
                    key={key}
                    checked={values.usWorkStatus.indexOf(key) > -1}
                    name={`usWorkStatus_${key}`}
                    id={`usWorkStatus_${key}`}
                    value={key}
                    label={value}
                    containerProps={{ mb: 3 }}
                    onChange={(e) =>
                      onCheckboxChange({
                        values,
                        target: e.target,
                        key: 'usWorkStatus',
                        setFieldValue,
                      })
                    }
                  />
                ))}
            </Flex>
          </Box>
        </Flex>
        <Text sx={{ lineHeight: 1.7 }} as="h4" mb={3} pb={2} variant="header.bottomBorder">
          Endorsements
          {sectionCount({ formRef, fields: ['verifiedMembers', 'referralIds', 'vouchedBySomeone'] }) && (
            <Badge variant="neutral" ml="2">
              {sectionCount({ formRef, fields: ['verifiedMembers', 'referralIds', 'vouchedBySomeone'] })}
            </Badge>
          )}
        </Text>
        <Flex flexDirection={['column', 'row']}>
          <Box as="fieldset" sx={{ border: 'none' }} p={0} width={[1, 1 / 2]} pr={[0, 4]} mb={[3, 0]}>
            <Box as="legend" variant="legend">
              Vouch status
            </Box>
            <Field
              component={FormSelect}
              id="vouchedBySomeone"
              name="vouchedBySomeone"
              options={vouchedBySomeoneOptions}
              onChange={(e) => {
                setFieldValue('vouchedBySomeone', e.value);
              }}
              value={values.vouchedBySomeone ? vouchedBySomeoneOptions[0] : vouchedBySomeoneOptions[1]}
            />
          </Box>
          <Box as="fieldset" sx={{ border: 'none' }} p={0} width={[1, 1 / 2]}>
            <Box as="legend" variant="legend">
              Vouched by
            </Box>
            <ReduxSelect
              value={values.referralIds}
              id="referralIds"
              name="referralIds"
              menuPlacement="top"
              onChange={(e) => {
                setFieldValue('referralIds', [e]);
              }}
              formatOptionLabel={(member) => (
                <Flex width={[1]} data-testid="optionContainer">
                  <Box width={[1]}>
                    <MemberTag sx={{ height: '100%', paddingBottom: '4px!important' }} member={member} />
                  </Box>
                </Flex>
              )}
              onInputChange={vouchedBy.onInputChange}
              isLoading={vouchedBy.isLoading}
              options={vouchedBy.options}
            />
            {values.referralIds.length > 0 && (
              <Box data-testid="children" width={[1]} mt={2}>
                {values.referralIds.map((member) => (
                  <MemberTag key={member.label} member={member} onRemove={() => setFieldValue('referralIds', [])} />
                ))}
              </Box>
            )}
          </Box>
        </Flex>

        {isCompanyAdmin && (
          <Flex flexDirection={['column', 'row']} mt={3}>
            <Box as="fieldset" sx={{ border: 'none' }} p={0} width={[1, 1 / 2]} pr={[0, 4]} mb={[3, 0]}>
              <Box as="legend" variant="legend">
                Member status
              </Box>
              <Field
                component={FormSelect}
                menuPlacement="top"
                id="verifiedMembers"
                name="verifiedMembers"
                options={membershipStatusOptions}
                onChange={(e) => {
                  setFieldValue('verifiedMembers', e.value);
                }}
                value={values.verifiedMembers ? membershipStatusOptions[0] : membershipStatusOptions[1]}
              />
            </Box>
          </Flex>
        )}
      </>
    )}
    <Button variant="variants.srOnly" type="submit">
      See Results
    </Button>
  </Flex>
);

MemberFilters.propTypes = {
  onlySmartListFilters: PropTypes.bool,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  formRef: PropTypes.object,
  isCompanyAdmin: PropTypes.bool,
  skills: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
  preferredLocations: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
  vouchedBy: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
};

MemberFilters.defaultProps = {
  onlySmartListFilters: false,
  formRef: null,
  isCompanyAdmin: true,
};
