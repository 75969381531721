import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './queries';

export const sharedListsApi = createApi({
  reducerPath: 'sharedListsApi',
  baseQuery,
  tagTypes: ['shared-list'],
  endpoints: (builder) => ({
    getSharedListBySlug: builder.query({
      query: ({ slug }) => ({
        url: `/collections/contacts/shared_lists/${slug}`,
      }),
      providesTags: ['shared-list'],
    }),

    subscribeEmailToSharedList: builder.mutation({
      query: ({ slug, data }) => ({
        url: `/collections/contacts/shared_lists/${slug}/subscribe`,
        method: 'post',
        data,
      }),
    }),
    unsubscribeFromSharedList: builder.mutation({
      query: ({ data }) => ({
        url: `/collections/contacts/shared_lists/unsubscribe`,
        method: 'put',
        data,
      }),
    }),
  }),
});

export const {
  useGetSharedListBySlugQuery,
  useSubscribeEmailToSharedListMutation,
  useUnsubscribeFromSharedListMutation,
} = sharedListsApi;
