import { Trash2 } from 'lucide-react';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const ContactDeleted = ({ contact }) => (
  <Flex
    sx={{
      svg: { color: 'neutral.400' },
      color: 'text.subtle',
      gap: '16px',
      p: '16px 0',
      justifyItems: 'flex-start',
      alignItems: 'center',
    }}
  >
    <Box as={Trash2} size="32px" />
    <Box>{contact.fullName} has been deleted.</Box>
  </Flex>
);

ContactDeleted.propTypes = {
  contact: PropTypes.object.isRequired,
};
