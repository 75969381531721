import moment from 'moment';

export function getUserDisplayName(user, returnEmail = true) {
  if (user.firstName) {
    return `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`;
  }

  if (returnEmail && user.email) {
    return user.email;
  }

  return '';
}

export function getMemberDisplaySource({ source, joinedAt, invitedBy }) {
  switch (source) {
    case 'not_defined':
      return 'Not defined';
    case 'signup':
      return `Signed up on ${moment(joinedAt).format('MM/DD/YYYY')}`;
    case 'vouch':
    case 'manual':
      return `Invited ${invitedBy ? `by ${getUserDisplayName(invitedBy)}` : ''} on ${moment(joinedAt).format(
        'MM/DD/YYYY',
      )}`;
    case 'import':
      return `Imported on ${moment(joinedAt).format('MM/DD/YYYY')}`;
    case 'integration_gh':
      return `Imported from Greenhouse on ${moment(joinedAt).format('MM/DD/YYYY')}`;
    default:
      return '';
  }
}
