import React, { useEffect, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { Button } from '@getro/rombo';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Card, Flex, Image, Text } from 'rebass/styled-components';
import { trackEvent } from '../../../helpers/analytics';
import { getItem, storeItem } from '../../../helpers/store';
import { makeIsCompanyAdminSelector } from '../../../hooks/usePermissions/selectors';
import RocketShipIcon from '../../../assets/icon-effortless-rocketship-v2.svg';

const FEATURE_ID = 'career_board_banner';

const BannerLandingPage = ({ sx, activeNetwork, slug, ...rest }) => {
  const [showBanner, setShowBanner] = useState(false);
  const isCompanyAdmin = useSelector(makeIsCompanyAdminSelector(slug));

  useEffect(() => {
    const { features, isManager } = activeNetwork;

    if (
      features.includes(FEATURE_ID) &&
      !isManager &&
      isCompanyAdmin &&
      !getItem(`${FEATURE_ID}-${slug}`) &&
      !showBanner
    ) {
      setShowBanner(true);

      if (typeof window.hj !== 'undefined') {
        window.hj('event', 'recruit_ca');
      }

      trackEvent('careerboardV1_banner:view', {
        activeNetwork: activeNetwork.id,
        companySlug: slug,
      });
    }
  }, [activeNetwork, isCompanyAdmin, showBanner, slug]);

  const onClickCta = () => {
    trackEvent('careerboardV1_banner:click_through', {
      activeNetwork: activeNetwork.id,
      companySlug: slug,
    });

    window.open('https://career-board.getro.com/');
  };

  const onClickDismiss = () => {
    setShowBanner(false);

    trackEvent('careerboardV1_banner:dismiss', {
      activeNetwork: activeNetwork.id,
      companySlug: slug,
    });

    storeItem(`${FEATURE_ID}-${slug}`, true);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Card
      p="3"
      sx={{
        position: 'relative',
        backgroundColor: 'rgb(67,39,160)',
        background: 'linear-gradient(90deg, rgba(67,39,160,1) 0%, rgba(64,33,166,1) 65%, rgba(179,64,187,1) 100%)',
        borderRadius: 'default',
        boxShadow: 'medium',
        ...sx,
      }}
      {...rest}
    >
      <Flex flexDirection={['column-reverse', 'row']}>
        <Box pr="3">
          <Text color="neutral.0" fontWeight="medium" fontSize="1">
            Introducing CareerBoard by Getro
          </Text>
          <Text color="neutral.0" fontWeight="semibold" fontSize="3" mt="3">
            A career page to attract web3 talent
          </Text>
          <Text color="neutral.0" mt="2" mb="3" fontSize="1" fontWeight="medium">
            Highlight your unique culture and get more referrals from established web3 networks with a beautiful,
            no-code career page.
          </Text>
          <Flex mt="3" pt="2">
            <Button variant="accent" size="small" onClick={onClickCta}>
              Discover more
              <Box as={ChevronRight} aria-hidden="true" width="16px" height="16px" ml="2" />
            </Button>
            <Button
              variant="styleless"
              size="small"
              ml="2"
              fontWeight="medium"
              color="neutral.0"
              onClick={onClickDismiss}
            >
              Dismiss
            </Button>
          </Flex>
        </Box>
        <Image
          src={RocketShipIcon}
          width={['80px', '120px']}
          height={['80px', '120px']}
          flex="0 0 auto"
          mb={[2, 0]}
          alignSelf={['flex-start', 'center']}
        />
      </Flex>
    </Card>
  );
};

BannerLandingPage.propTypes = {
  activeNetwork: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

BannerLandingPage.defaultProps = {
  sx: {},
};

export default BannerLandingPage;
