/* eslint-disable no-param-reassign */
/* eslint-disable function-paren-newline */
import moment from 'moment';

import {
  companyNameIncludedOptions,
  companySizeOptions,
  fundOptions,
  inPortoOptions,
  jobSearchStatusesOptions,
  jobTitlesIncludedOptions,
  jobTitlesMatchOptions,
  locationRadiusOptions,
  resumeOptions,
  signUpDayOptions,
  signupInfoOptions,
  signupSenioritiesOptions,
  signupWorkEnvironmentsOptions,
} from 'components/organisms/contactsFilters/defaultFilters';
import { monthOptions } from 'components/atoms/periodDropDown/date';

export const parseFiltersData = (filters = {}, enableStringify = false) =>
  Object.keys(filters).reduce((carry, key) => {
    if (['companyStagesYears', 'industryTagsYears', 'locationRadius'].includes(key)) return carry;

    if (key === 'activePositionsOrganizationStages' && filters[key]?.length) {
      carry[key] = filters[key].map((item) => item.from?.value);
      return carry;
    }

    if (key === 'stageTransitions' && filters[key]?.length) {
      carry[key] = filters[key].map((item) => {
        const value = {
          stage_from: item.from.value,
          stage_to: item.to.value,
        };
        return enableStringify ? JSON.stringify(value) : value;
      });

      return carry;
    }

    if (key === 'locationIds' && filters[key]?.length) {
      carry[key] = filters[key].map((item) => {
        const value = {
          place_id: item.value,
          type: item.type,
          title: item.label,
        };

        return enableStringify ? JSON.stringify(value) : value;
      });

      if (filters[key].some((item) => item.type === 'locality')) {
        carry.locationRadius = filters.locationRadius?.value ?? 10;
      }
    } else if (key === 'companyStages' && filters[key]?.length) {
      carry[key] = filters[key].map((item) => {
        const value = {
          years: item.from?.value ?? 2,
          stage: item.to.value,
        };
        return enableStringify ? JSON.stringify(value) : value;
      });
    } else if (key === 'industryTags' && filters.industryTags?.length) {
      carry[key] = filters[key].map((item) => {
        const value = {
          industry_tag: item?.value,
          years: filters.industryTagsYears?.value ?? 2,
        };

        return enableStringify ? JSON.stringify(value) : value;
      });
    } else if (key === 'companyNamePeriod') {
      const { fromMonth, fromYear, toMonth, toYear } = filters[key];

      carry[`${key}From`] = `${fromYear}-${fromMonth.value + 1}-01`;
      carry[`${key}To`] = `${toYear}-${toMonth.value + 1}-${moment(`${toYear}-${toMonth.value + 1}-01`)
        .endOf('month')
        .format('DD')}`;
    } else if (Array.isArray(filters[key]) && typeof filters[key] !== 'string') {
      carry[key] = filters[key].map((item) => (typeof item === 'string' ? item : item.value));
    } else if (filters[key]?.value || typeof filters[key]?.value === 'boolean') {
      carry[key] = filters[key].value;
    } else {
      carry = { ...carry, ...filters[key] };
    }

    return carry;
  }, {});

export const parseFilterValueForTrackingEvents = (key, value) => {
  if ((key === 'yearsOfExperience' || key === 'yearsInCurrentCompany') && !!value) {
    return [value.years_experience_gteq, value.years_experience_lteq];
  }

  if ((typeof value === 'function' || typeof value === 'object') && !!value && !Array.isArray(value)) {
    return value.value;
  }

  if (Array.isArray(value) && value.length) {
    if (Object.prototype.hasOwnProperty.call(value[0], 'place_id')) {
      return value.map((item) => item.place_id);
    }
    if (Object.prototype.hasOwnProperty.call(value[0], 'value')) {
      return value.map((item) => item.value);
    }
  }

  return value;
};

export const filtersCount = (filters) =>
  Object.keys(filters).reduce(
    (carry, idx) => (filters[idx].length || Object.keys(filters[idx]).length ? carry + 1 : carry),
    0,
  );

const mapArrayToObj = (arr) => arr.reduce((carry, item) => ({ ...carry, [item.value]: item }), {});

const mappedOptions = {
  jobTitlesIncluded: mapArrayToObj(jobTitlesIncludedOptions),
  jobTitlesMatch: mapArrayToObj(jobTitlesMatchOptions),
  locationRadius: mapArrayToObj(locationRadiusOptions),
  currentCompanySizes: mapArrayToObj(companySizeOptions),
  fundOptions: mapArrayToObj(fundOptions),
  signupHasResume: mapArrayToObj(resumeOptions),
  signupDaysSinceJoined: mapArrayToObj(signUpDayOptions),
  signupJobSearchStatuses: mapArrayToObj(jobSearchStatusesOptions),
  signupSeniorities: mapArrayToObj(signupSenioritiesOptions),
  signupWorkEnvironment: mapArrayToObj(signupWorkEnvironmentsOptions),
  companyNameIncluded: mapArrayToObj(companyNameIncludedOptions),
  hasSignupInfo: mapArrayToObj(signupInfoOptions),
};

export const reverseParsedFilters = (filters = {}) =>
  Object.keys(filters).reduce((carry, key) => {
    const directFilters = [
      'locationRadius',
      'jobTitlesIncluded',
      'jobTitlesMatch',
      'signupHasResume',
      'signupDaysSinceJoined',
      'companyNameIncluded',
      'hasSignupInfo',
    ];
    if (directFilters.includes(key)) {
      carry[key] = mappedOptions[key][filters[key]];
      return carry;
    }

    if (key === 'inPortco') {
      carry[key] = typeof filters[key] === 'boolean' && !filters[key] ? inPortoOptions[1] : inPortoOptions[0];
      return carry;
    }

    const preferenceFilters = ['signupSeniorities', 'signupWorkEnvironment', 'signupJobSearchStatuses'];
    if (preferenceFilters.includes(key)) {
      carry[key] = filters[key].map((item) => mappedOptions[key][item]);
      return carry;
    }

    if (key === 'currentCompanySizes' && filters.currentCompanySizes) {
      carry[key] = filters.currentCompanySizes.map((item) => mappedOptions[key][item]);
      return carry;
    }

    if (key === 'keywords' && filters.keywords) {
      carry[key] = filters.keywords;
      return carry;
    }

    if (key === 'yearsInCurrentCompanyLteq' && filters.yearsInCurrentCompanyLteq) {
      carry.yearsInCurrentCompany = {
        years_in_current_company_lteq: filters.yearsInCurrentCompanyLteq,
        years_in_current_company_gteq: filters.yearsInCurrentCompanyGteq,
      };
      return carry;
    }

    if (key === 'yearsExperienceLteq' && filters.yearsExperienceLteq) {
      carry.yearsOfExperience = {
        years_experience_lteq: filters.yearsExperienceLteq,
        years_experience_gteq: filters.yearsExperienceGteq,
      };
      return carry;
    }

    if (key === 'stageTransitions' && filters.stageTransitions) {
      carry[key] = filters.stageTransitions.map((item, index) => {
        const fund = {
          id: index,
          from: mappedOptions.fundOptions[item.stageFrom],
          to: mappedOptions.fundOptions[item.stageTo],
          stage: 'growth',
        };

        fund.key = JSON.stringify(fund);
        return fund;
      });
      return carry;
    }

    if (key === 'importerIds' && filters.importerIds) {
      carry[key] = filters.importerIds.map((item) => ({
        value: item.id,
        label: item.fullName,
      }));
      return carry;
    }

    if (key === 'activePositionsOrganizationStages' && filters.activePositionsOrganizationStages) {
      carry[key] = filters.activePositionsOrganizationStages.map((item) => {
        const stage = {
          from: mappedOptions.fundOptions[item],
          stage: 'current',
        };

        stage.key = JSON.stringify(stage);
        return stage;
      });
      return carry;
    }

    if (key === 'companyStages' && filters.companyStages) {
      carry[key] = filters.companyStages.map((item) => {
        const stage = {
          from: {
            label: item.years,
            value: item.years,
          },
          to: mappedOptions.fundOptions[item.stage],
          stage: 'early',
        };

        stage.key = JSON.stringify(stage);
        return stage;
      });
      return carry;
    }

    if (key === 'locationIds' && filters.locationIds) {
      carry[key] = filters.locationIds.map((item) => ({
        value: item.placeId,
        label: item.title,
        type: item.type,
        title: item.title,
      }));
      return carry;
    }

    if (key === 'industryTags' && filters?.industryTags?.length) {
      carry[key] = filters.industryTags.map(({ industryTag, years }) => ({
        label: industryTag,
        value: years,
      }));

      return carry;
    }

    if (key === 'companyNamePeriodFrom' && filters.companyNamePeriodFrom) {
      const [year, month] = filters.companyNamePeriodFrom.split('-');

      carry.companyNamePeriod = {
        fromYear: year,
        fromMonth: monthOptions()[parseInt(month, 10) - 1],
        ...carry.companyNamePeriod,
      };

      return carry;
    }

    if (key === 'companyNamePeriodTo' && filters.companyNamePeriodTo) {
      const [year, month] = filters.companyNamePeriodTo.split('-');

      carry.companyNamePeriod = {
        toYear: year,
        toMonth: monthOptions()[parseInt(month, 10) - 1],
        ...carry.companyNamePeriod,
      };

      return carry;
    }

    const value = filters[key];

    if (Array.isArray(value)) {
      carry[key] = value.map((item) => ({
        value: item,
        label: item,
      }));
    }

    return carry;
  }, {});
