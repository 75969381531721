import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Panel } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Download } from 'lucide-react';

import { useInjectSaga } from '../../../../hooks/useInjectSaga';
import { useInjectReducer } from '../../../../hooks/useInjectReducer';
import reducer from './redux/reducer';
import saga from './redux/sagas';
import {
  key,
  requestExportJobAlertSubscribersFailedSelector,
  requestExportJobAlertSubscribersSucceededSelector,
} from './redux/selectors';
import { loadRequestExportJobAlertSubscribers, resetExportJobAlertSubscribers } from './redux/actions';
import ExportModal from '../../../../components/molecules/ExportModal';

const ExportJobAlertSubscribers = ({ ...rebassProps }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const exportError = useSelector(requestExportJobAlertSubscribersFailedSelector);
  const exportSuccess = useSelector(requestExportJobAlertSubscribersSucceededSelector);
  const [showExportModal, setShowExportModal] = useState(false);

  const handleDownloadClick = () => {
    setShowExportModal(true);
    dispatch(loadRequestExportJobAlertSubscribers());
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    dispatch(resetExportJobAlertSubscribers());
  };

  return (
    <Panel title="Job alerts subscribers export" {...rebassProps}>
      <Text>
        Get a list of users who are (or have been) subscribed to jobs in your job board. Use this list to better
        understand your job board visitors.
      </Text>
      <Button mt={3} variant="secondary" onClick={handleDownloadClick}>
        <Flex alignItems="center" sx={{ columnGap: '10px' }}>
          <Box as={Download} height="16px" width="16px" aria-hidden="true" />
          Export subscribers
        </Flex>
      </Button>
      {showExportModal && (
        <ExportModal
          error={exportError}
          success={exportSuccess}
          successTitle="An export is coming your way!"
          successContent="We've sent you an email. It may take a few minutes."
          onClose={closeExportModal}
        />
      )}
    </Panel>
  );
};

export default ExportJobAlertSubscribers;
