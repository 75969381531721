import { useState } from 'react';

export const useOrganizationSize = () => {
  const defaultOptions = [
    { label: '1 - 10 employees', value: 1 },
    { label: '11 - 50 employees', value: 2 },
    { label: '51 - 200 employees', value: 3 },
    { label: '201 - 1000 employees', value: 4 },
    { label: '1001 - 5000 employees', value: 5 },
    { label: '5001+ employees', value: 6 },
  ];

  const [options, setOptions] = useState(defaultOptions);

  const search = (query) => {
    setOptions(query ? options.filter((opt) => opt.label.toLowerCase().includes(query.toLowerCase())) : defaultOptions);
  };

  return { options, search, isFetching: false };
};
