import React, { useContext } from 'react';
import EOnboarding from 'router/wrappers/EOnboarding';
import PropTypes from 'prop-types';
import { OnboardingLayout } from '@getro/rombo';
import OnboardingContext from './onboardingContext';

const handleSkipAll = (steps, updateStep) => () => updateStep(steps - 1);

const EmployerOnboardingContent = ({ children, headerContent }) => {
  const { network, steps, step, organization, updateStep } = useContext(OnboardingContext);
  const { showSelectYourCompany } = useContext(EOnboarding);

  const talentNetwork = network.features.includes('talent_network');

  const stepsToShow = steps
    .filter(({ title }) => !!title)
    .filter(({ id }) => {
      if (!showSelectYourCompany) {
        return id !== 'selectYourCompany';
      }

      return id;
    });

  const stepToShow = !showSelectYourCompany ? step : step + 1;

  return (
    <OnboardingLayout
      companyLogo={organization.logoUrl}
      companyName={organization.name}
      currentStep={stepToShow}
      headerContent={headerContent}
      networkLogo={network.logoUrl}
      networkName={network.name}
      onSkipAllSteps={handleSkipAll(steps.length, updateStep)}
      steps={stepsToShow}
      title={`${network.name} ${talentNetwork ? 'talent network' : 'job board'}`}
    >
      {children}
    </OnboardingLayout>
  );
};
EmployerOnboardingContent.propTypes = {
  children: PropTypes.any.isRequired,
  headerContent: PropTypes.any.isRequired,
};

export default EmployerOnboardingContent;
