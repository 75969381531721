import { handleActions } from 'redux-actions';
import produce from 'immer';
import { CURRENCY_OPTIONS, EMPTY_VALUES, PERIOD_OPTIONS } from '../constants';
import {
  errorEditedJob,
  errorJobLocations,
  errorJobOrganizations,
  errorSaveEditedJob,
  loadedEditedJob,
  loadEditedJob,
  loadedJobLocations,
  loadedJobOrganizations,
  loadedSaveEditedJob,
  loadSaveEditedJob,
  resetEditedJob,
} from './actions';

export const initialState = {
  job: {
    ...EMPTY_VALUES,
    error: false,
  },
  save: {
    success: false,
    error: false,
  },
  jobOrganizations: [],
  jobLocations: [],
  jobUsers: [],
  companyAdmins: [],
};

/* eslint-disable default-case, no-param-reassign */
const reducer = handleActions(
  {
    [resetEditedJob]: produce(() => initialState),
    [loadEditedJob]: produce((draft) => {
      draft.job = {
        ...initialState.job,
      };
      return draft;
    }),
    [loadedEditedJob]: produce((draft, action) => {
      const { payload: job } = action;

      const {
        applicationMethod,
        applicationPath,
        compensationCurrency,
        compensationPeriod,
        locations,
        organization,
        jobFunctions,
        description,
        compensationMin,
        compensationMax,
        compensationPublic,
        ...otherJobAttributes
      } = job;
      const { id: value, name: label } = organization;

      draft.job = {
        ...EMPTY_VALUES,
        ...otherJobAttributes,
        companyId: { value, label },
        description: description || EMPTY_VALUES.description,
        compensationPublic: compensationPublic === 'visible',
        applicationMethod:
          !applicationMethod || applicationMethod === 'method_not_defined'
            ? EMPTY_VALUES.applicationMethod
            : applicationMethod,
        applicationPath: applicationPath || EMPTY_VALUES.applicationPath,
        compensationCurrency: CURRENCY_OPTIONS.find((e) => e.value === compensationCurrency) || null,
        compensationPeriod: PERIOD_OPTIONS.find((e) => e.value === compensationPeriod) || null,
        locations:
          locations?.filter(({ placeId }) => placeId !== 'remote')?.map((l) => ({ value: l.placeId, label: l.name })) ||
          [],
        remote: locations?.findIndex(({ placeId }) => placeId === 'remote') !== -1,
        jobFunctions: jobFunctions?.map(({ id, name }) => ({ value: id, label: name })) || [],
        employmentTypes:
          otherJobAttributes.employmentTypes?.map((type) => {
            const typeValue = type.charAt(0).toUpperCase() + type.slice(1).replace('_', ' ');
            return { value: type, label: typeValue };
          }) || [],
        compensationMin: compensationMin || '',
        compensationMax: compensationMax || '',
        error: false,
      };

      return draft;
    }),
    [errorEditedJob]: produce((draft) => {
      draft.job.error = true;
      return draft;
    }),
    [loadSaveEditedJob]: produce((draft) => {
      draft.save = { ...initialState.save };
      return draft;
    }),
    [loadedSaveEditedJob]: produce((draft) => {
      draft.save = {
        error: false,
        success: true,
      };
      return draft;
    }),
    [errorSaveEditedJob]: produce((draft) => {
      draft.save = {
        error: true,
        success: false,
      };
      return draft;
    }),
    [loadedJobOrganizations]: produce((draft, action) => {
      const { payload: organizations } = action;
      draft.jobOrganizations = organizations;
      return draft;
    }),
    [errorJobOrganizations]: produce((draft) => {
      draft.jobOrganizations = [];
      return draft;
    }),
    [loadedJobLocations]: produce((draft, action) => {
      const { payload: locations } = action;
      draft.jobLocations = locations;
      return draft;
    }),
    [errorJobLocations]: produce((draft) => {
      draft.jobLocations = [];
      return draft;
    }),
  },
  initialState,
);

export default reducer;
