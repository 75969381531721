import React, { useEffect, useRef, useContext, useState } from 'react';
import { DownloadCloud, HelpCircle } from 'lucide-react';
import { Box } from 'rebass/styled-components';
import axios from 'axios';
import moment from 'moment';

import strings from 'strings';

import { get, post } from 'api';

import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';

import PageLayout from 'components/layout/Page';

import { MessageBlock } from '@getro/rombo';

import ExportModal from 'components/molecules/ExportModal';
import NetworkSchema from 'schemas/network';

import { FiltersContext } from '../context';
import ChartView from './ChartView';
import RowLimit from './RowLimit';
import TableView from './TableView';
import Popover from '../../../components/atoms/popoverDeprecated';
import { METRICS } from '../filtersOptions';

const backendDateFormat = 'MM-DD-YYYY';

function AnalyticsContent({ activeNetwork }) {
  const { id: activeNetworkId } = activeNetwork;
  const { filters } = useContext(FiltersContext);
  const selectedMetric = METRICS.find((m) => m.value === filters.metric.value);

  // Fetch data start
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);
  const [fetchError, setFetchError] = useState(false);
  const getCancelTokenSource = useRef(axios.CancelToken.source());

  const formatDate = (date) => {
    switch (filters.interval.value) {
      case 'daily':
        return moment(date.from, backendDateFormat).format('ddd, MMM D');
      case 'weekly':
        return `${moment(date.from, backendDateFormat).format('MMM D')} - ${moment(date.to, backendDateFormat).format(
          'MMM D',
        )}`;
      case 'monthly':
        return moment(date.from, backendDateFormat).format('MMMM YYYY');
      default:
        return date.from;
    }
  };

  // Make needed transformations like formatting dates
  const handleNewData = (newData) =>
    newData.map((row) => {
      const { date, ...rest } = row;
      if (date) {
        return { date: formatDate(row.date), ...rest };
      }

      return row;
    });

  const fetchJobBoard = async () => {
    // Return if filters are empty
    if (!Object.keys(filters).length) {
      return;
    }

    setFetching(true);
    setFetchError(false);

    getCancelTokenSource.current.cancel();
    getCancelTokenSource.current = axios.CancelToken.source();

    try {
      const params = {
        collectionId: activeNetworkId,
        metric: filters.metric.value,
        interval: filters.interval.value,
        startDate: filters.startDate,
        endDate: filters.endDate,
      };

      const response = await get(selectedMetric.fetchEndpoint, {
        params,
        cancelToken: getCancelTokenSource.current.token,
      });

      setData(handleNewData(response.data));
      setFetching(false);

      // Track event when data is shown to avoid tracking cancelled calls
      trackEvent('analytics:show_report', params);
    } catch (error) {
      // If the request got cancelled due to a user action, don't track nor show the error
      if (!axios.isCancel(error)) {
        trackException(error);
        setFetchError(true);
        setFetching(false);
      }
    }
  };

  useEffect(
    () => () => {
      getCancelTokenSource.current.cancel();
    },
    [],
  );

  useEffect(() => {
    fetchJobBoard();
    // eslint-disable-next-line
  }, [filters]);
  // Fetch data ends

  // Export section start
  const exportCancelToken = useRef(axios.CancelToken.source());
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportSuccess, setExportSuccess] = useState(false);
  const [exportError, setExportError] = useState(false);

  const handleDownloadClick = async () => {
    setShowExportModal(true);

    try {
      const params = {
        collectionId: activeNetworkId,
        metric: filters.metric.value,
        interval: filters.interval.value,
        startDate: filters.startDate,
        endDate: filters.endDate,
      };

      trackEvent('analytics:download_report', params);

      await post(selectedMetric.downloadEndpoint, params, {
        cancelToken: exportCancelToken.current.token,
      });
      setExportSuccess(true);
    } catch (error) {
      trackException(error);
      setExportSuccess(false);
      setExportError(true);
    }
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    setExportSuccess(false);
    setExportError(false);
  };

  useEffect(
    () => () => {
      exportCancelToken.current.cancel();
    },
    [],
  );
  // Export section end

  const getHeaderContent = () => {
    const metricCopy =
      selectedMetric.label === strings.analytics.metrics.page_visits.label
        ? strings.analytics.metrics.page_visits
        : strings.analytics.metrics[selectedMetric.value];

    return (
      <Box sx={{ display: 'flex', height: 'fit-content', alignItems: 'center' }}>
        <Box>{selectedMetric.label}</Box>
        {metricCopy && metricCopy.description && (
          <Popover
            trigger={
              <Box
                as={HelpCircle}
                strokeWidth="1.5"
                aria-hidden="true"
                ml="2"
                height="1em"
                width="1em"
                sx={{
                  cursor: 'pointer',
                  stroke: ({ colors }) => colors.neutral[400],
                }}
              />
            }
            content={metricCopy.description}
          />
        )}
      </Box>
    );
  };

  const secondaryActions = [
    {
      action: handleDownloadClick,
      icon: DownloadCloud,
      text: 'Download',
      popoverContent: 'Download report in csv format',
    },
  ];

  return (
    <PageLayout.Content header={getHeaderContent()} fullWidth secondaryActions={secondaryActions}>
      {showExportModal && (
        <ExportModal
          error={exportError}
          success={exportSuccess}
          onClose={closeExportModal}
          successTitle="A data export is coming your way!"
          successContent="We’re crunching numbers to build your report. We’ll send an email when it is complete. This can take a few minutes."
        />
      )}

      <RowLimit data={data} fetching={fetching} onDownloadLink={handleDownloadClick} />

      {fetchError && (
        <Box>
          <MessageBlock
            type="error"
            title="There was an unexpected error"
            buttonText="Try again"
            onClick={fetchJobBoard}
          >
            Please try again or contact us at <a href="mailto:help@getro.com">help@getro.com</a>.
          </MessageBlock>
        </Box>
      )}

      {!fetchError && (
        <>
          <ChartView data={data} fetching={fetching} />
          <TableView data={data} fetching={fetching} />
        </>
      )}
    </PageLayout.Content>
  );
}

AnalyticsContent.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
};

export default AnalyticsContent;
