import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import {
  errorSetAutoSubscribe,
  errorSetDailyAlerts,
  errorSubscribeAll,
  errorSubscriptions,
  errorUnsubscribeAll,
  loadedSetAutoSubscribe,
  loadedSetDailyAlerts,
  loadedSubscribeAll,
  loadedSubscriptions,
  loadedUnsubscribeAll,
  loadSetAutoSubscribe,
  loadSetDailyAlerts,
  loadSubscribeAll,
  loadSubscriptions,
  loadUnsubscribeAll,
  setShowDeactivateConfirmation,
  toggleBoardSubscription,
  toggleBoardSubscriptionComplete,
} from './actions';
import { request } from '../../api';
import { activeNetworkSelector } from '../../redux/selectors';
import talentGroupsDecorator from '../../helpers/talentGroupsDecorator';
import { makeSelectBoards } from './selectors';

function* loadSetHasDailyAlertsEvent({ payload }) {
  const { checked } = payload;
  const activeNetwork = yield select(activeNetworkSelector);

  const boards = yield select(makeSelectBoards);
  try {
    yield call(request, {
      url: `/collections/${activeNetwork.id}/membership_settings/boards_daily_digest`,
      options: {
        method: checked ? 'post' : 'delete',
      },
    });
    yield put(loadedSetDailyAlerts({ hasDailyAlerts: checked, activeNetworkId: activeNetwork.id }));
    // turn off all alerts
    if (!checked) {
      // call the removeAll api
      yield call(request, {
        url: `/collections/${activeNetwork.id}/talent_groups/subscription/all`,
        options: {
          method: 'delete',
        },
      });
      yield put(loadedUnsubscribeAll({ activeNetwork }));
    }
    yield all(
      boards.options.map((board) => put(toggleBoardSubscriptionComplete({ board, subscribe: checked, activeNetwork }))),
    );
    yield put(setShowDeactivateConfirmation(false));
  } catch (err) {
    yield put(errorSetDailyAlerts({ error: err }));
  }
}

function* loadSetAutoSubscribeEvent({ payload }) {
  const { checked } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  try {
    yield call(request, {
      url: `/collections/${activeNetwork.id}/membership_settings/autosubscribe_to_boards`,
      options: {
        method: checked ? 'post' : 'delete',
      },
    });
    // to fake api call
    yield put(loadedSetAutoSubscribe({ hasAutoSubscribe: checked, activeNetworkId: activeNetwork.id }));
  } catch (err) {
    yield put(errorSetAutoSubscribe({ error: err }));
  }
}

function* toggleBoardSubscriptionEvent({ payload }) {
  const { board, subscribe } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  try {
    yield call(request, {
      url: `/collections/${activeNetwork.id}/talent_groups/${board.id}/subscription`,
      options: {
        method: subscribe ? 'post' : 'delete',
      },
    });
    yield put(toggleBoardSubscriptionComplete({ origin: 'members_alerts_settings', board, subscribe, activeNetwork }));
  } catch (err) {
    yield put(errorSetAutoSubscribe({ error: err }));
  }
}

function* loadSubscribeAllEvent() {
  try {
    const activeNetwork = yield select(activeNetworkSelector);
    yield call(request, {
      url: `/collections/${activeNetwork.id}/talent_groups/subscription/all`,
      options: {
        method: 'post',
      },
    });
    yield put(loadedSubscribeAll({ activeNetwork }));
  } catch (error) {
    yield put(errorSubscribeAll({ error }));
  }
}

function* loadUnsubscribeAllEvent() {
  try {
    const activeNetwork = yield select(activeNetworkSelector);
    yield call(request, {
      url: `/collections/${activeNetwork.id}/talent_groups/subscription/all`,
      options: {
        method: 'delete',
      },
    });
    yield put(loadedUnsubscribeAll({ activeNetwork }));
  } catch (err) {
    yield put(errorUnsubscribeAll({ error: err }));
  }
}

function* loadSubscriptionsEvent() {
  try {
    const activeNetwork = yield select(activeNetworkSelector);
    const response = yield call(request, {
      url: `/collections/${activeNetwork.id}/talent_groups`,
    });
    const { data } = response;
    const { items } = data || {};
    const talentGroups = talentGroupsDecorator(items);
    yield put(
      loadedSubscriptions({ subscribed: talentGroups.filter((group) => group.subscribed).length, talentGroups }),
    );
  } catch (err) {
    yield put(errorSubscriptions({ error: err }));
  }
}

export default function* manageSubscriptionsSaga() {
  yield takeLatest(loadSubscriptions().type, loadSubscriptionsEvent);
  yield takeLatest(loadSetDailyAlerts().type, loadSetHasDailyAlertsEvent);
  yield takeLatest(loadSetAutoSubscribe().type, loadSetAutoSubscribeEvent);
  yield takeLatest(toggleBoardSubscription().type, toggleBoardSubscriptionEvent);
  yield takeLatest(loadSubscribeAll().type, loadSubscribeAllEvent);
  yield takeLatest(loadUnsubscribeAll().type, loadUnsubscribeAllEvent);
}
