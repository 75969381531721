import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import OnboardingContext from 'components/layout/EmployerOnboarding/onboardingContext';
import { useSelector } from 'react-redux';
import { OnboardingLayoutContent } from '@getro/rombo';
import { JobSourceSummary } from './jobSourceSummary';
import JobSource from '../../../components/molecules/jobSource';
import { userSelector } from '../../../redux/selectors';

const CareersPage = ({ onBack, title }) => {
  const { organization, step, updateStep } = useContext(OnboardingContext);
  const user = useSelector(userSelector);
  const { firstName, lastName, email } = user;
  const suggestJobSourceLink = `https://getro-forms.typeform.com/to/auH5tH?firstname=${firstName}&lastname=${lastName}&email=${email}&companyname=${organization.name}&companyid=${organization.id}`;
  const { organizationCareersPages = [], ocpSearchStatus } = organization;

  const handleNextStepClick = () => {
    updateStep(step + 1);
  };

  return (
    <OnboardingLayoutContent title={title} onBack={onBack} onNextStep={handleNextStepClick}>
      <JobSourceSummary
        organizationCareersPages={organizationCareersPages}
        ocpSearchStatus={ocpSearchStatus}
        suggestJobSourceLink={suggestJobSourceLink}
      />
      {organizationCareersPages.map((ocp) => (
        <JobSource source={ocp} key={ocp.id} currentUser={user} company={organization} />
      ))}
    </OnboardingLayoutContent>
  );
};

CareersPage.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.string,
};

CareersPage.defaultProps = {
  onBack: () => {},
  title: '',
};

export default CareersPage;
