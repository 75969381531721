import { takeLatest, put, call } from 'redux-saga/effects';
import { loadedCompany } from 'hooks/useCompany/actions';
import { request } from '../../api';
import {
  loadRemoveCompany,
  loadedRemoveCompany,
  errorRemoveCompany,
  loadedTopicOptions,
  loadTopicOptions,
  errorTopicOptions,
  loadSaveCompanyTopics,
  errorSaveCompanyTopics,
  loadedSaveCompanyTopics,
} from './actions';
import { trackEvent } from '../../helpers/analytics';
import strings from '../../strings';

function* fetchTopicsEvent({ payload }) {
  try {
    const { networkId } = payload;
    const requestUrl = `/collections/${networkId}/company_tags`;
    const response = yield call(request, {
      url: requestUrl,
      options: {
        params: {
          collection_id: networkId,
        },
      },
    });

    yield put(
      loadedTopicOptions({
        topicOptions: response.data.items.map((topic) => ({ value: topic.id, label: topic.name })),
      }),
    );
  } catch (err) {
    yield put(errorTopicOptions({ error: err }));
  }
}

function* loadUserProfileEvent({ payload }) {
  try {
    const { networkId, company } = payload;
    const requestUrl = `/collections/${networkId}/organizations`;
    yield call(request, {
      url: requestUrl,
      options: {
        method: 'delete',
        params: { organizationIds: [company.id] },
      },
    });

    trackEvent('employer_profile:remove_from_collection', {
      organization_id: company.id,
      collection_id: networkId,
    });

    yield put(
      loadedRemoveCompany({
        isRemoved: true,
      }),
    );
  } catch (err) {
    yield put(errorRemoveCompany({ error: strings.genericError }));
  }
}

function* saveCompanyTopicsEvent({ payload }) {
  try {
    const { networkId, company, topics } = payload;
    const requestUrl = `/collections/${networkId}/organizations/${company.id}/topics`;
    yield call(request, {
      url: requestUrl,
      options: {
        method: 'post',
        data: {
          topicIds: topics.map((t) => parseInt(t.value, 10)),
        },
      },
    });
    yield call(trackEvent, 'employer_profile:edit_company_tags', {
      organization_id: company.id,
    });
    yield put(
      loadedSaveCompanyTopics({
        company: { ...company, topics: topics.map(({ label: name, value: id }) => ({ id, name })) },
      }),
    );
    yield put(
      loadedCompany({
        ...company,
        topics: topics.map(({ label: name, value: id }) => ({ id: parseInt(id, 10), name })),
      }),
    );
  } catch (err) {
    let errorMessage;
    switch (err.response.status) {
      case 403:
        errorMessage = strings.genericAuthError;
        break;
      default:
        errorMessage = strings.genericError;
        break;
    }
    yield put(errorSaveCompanyTopics({ error: errorMessage }));
  }
}

export default function* companyProfileSaga() {
  yield takeLatest(loadRemoveCompany().type, loadUserProfileEvent);
  yield takeLatest(loadTopicOptions().type, fetchTopicsEvent);
  yield takeLatest(loadSaveCompanyTopics().type, saveCompanyTopicsEvent);
}
