import { takeLatest, call, put } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { request } from '../../api';
import { trackException } from '../../helpers/errorTracker';
import {
  errorBulkForceAllJobsVisibility,
  errorBulkForceJobVisibility,
  errorCloseJob,
  errorDeleteJob,
  errorExtendJob,
  errorFeatureJob,
  errorForceJobVisibility,
  errorJobApplicationDownload,
  loadBulkForceAllJobsVisibility,
  loadBulkForceJobVisibility,
  loadCloseJob,
  loadDeleteJob,
  loadedBulkForceAllJobsVisibility,
  loadedBulkForceJobVisibility,
  loadedCloseJob,
  loadedDeleteJob,
  loadedExtendJob,
  loadedFeatureJob,
  loadedForceJobVisibility,
  loadedJobApplicationDownload,
  loadExtendJob,
  loadFeatureJob,
  loadForceJobVisibility,
  loadJobApplicationDownload,
  toggleJobActionDialog,
} from './actions';
import APIError from '../../api/APIError';
import { resetJobsTable, resetSelectedJobs } from '../useJobs/actions';

function* loadExtendJobEvent({ payload }) {
  const { job, extension, activeNetwork, source } = payload;

  const mapJobSource = (jobSource) => {
    if (jobSource === 'career_page' || jobSource === 'manual') {
      return 'job_source';
    }
    return 'posted';
  };

  try {
    const response = yield call(request, {
      url: `/jobs/${job.id}/renew`,
      options: {
        method: 'post',
        params: {
          collectionId: activeNetwork.id,
        },
        data: {
          expiresIn: extension,
        },
      },
    });
    const { data } = response;
    data.createdAt = data.postedAt;
    data.expirationDate = data.expiresAt;
    data.source = mapJobSource(data.source);
    yield put(loadedExtendJob({ job: data, source }));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }
    yield put(errorExtendJob({ error: true }));
  }
}
function* loadCloseJobEvent({ payload }) {
  const { jobId, source, networkId, isNetworkManager, companyId } = payload;
  try {
    yield call(request, {
      url: `/jobs/${jobId}/close`,
      options: {
        method: 'delete',
      },
    });
    yield put(loadedCloseJob({ jobId, source, networkId, isNetworkManager, companyId }));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }
    yield put(errorCloseJob({ error: true }));
  }
}
function* loadDeleteJobEvent({ payload }) {
  const { job } = payload;
  try {
    yield call(request, {
      url: `/jobs/${job.id}`,
      options: {
        method: 'delete',
      },
    });

    yield put(loadedDeleteJob({ job }));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }
    yield put(errorDeleteJob({ error: true }));
  }
}

function* loadForceJobVisibilityEvent({ payload }) {
  const {
    job: { id: jobId },
    forcedVisibility,
    activeNetwork: { id: collectionId },
  } = payload;
  try {
    yield call(request, {
      url: `collections/${collectionId}/jobs/${jobId}/force_visibility`,
      options: {
        baseURL: process.env.REACT_APP_API_V2_BASE_URL,
        method: 'put',
        data: {
          forced_visibility: forcedVisibility,
        },
      },
    });
    yield put(loadedForceJobVisibility(payload));
  } catch (e) {
    yield put(errorForceJobVisibility({ error: e }));
  }
}

function* loadFeatureJobEvent({ payload }) {
  const {
    job: { id: jobId, title },
    featured,
    activeNetwork: { id: collectionId },
  } = payload;
  try {
    yield call(request, {
      url: `collections/${collectionId}/jobs/${jobId}/feature`,
      options: {
        baseURL: process.env.REACT_APP_API_V2_BASE_URL,
        method: 'put',
        data: {
          featured,
        },
      },
    });
    yield put(loadedFeatureJob(payload));
    yield put(toggleJobActionDialog({ dialog: 'featureJob', isOpen: false }));
    yield call(toast, `${title} featured status updated`, { icon: 'success', id: 'change_job_visibility_result' });
  } catch (e) {
    yield put(errorFeatureJob({ error: e }));
  }
}
function* loadBulkForceJobVisibilityEvent({ payload }) {
  const {
    ids,
    forcedVisibility,
    activeNetwork: { id: collectionId },
  } = payload;
  try {
    yield call(request, {
      url: `collections/${collectionId}/jobs/bulk_force_visibility`,
      options: {
        baseURL: process.env.REACT_APP_API_V2_BASE_URL,
        method: 'put',
        data: {
          ids,
          forced_visibility: forcedVisibility,
        },
      },
    });
    yield put(loadedBulkForceJobVisibility(payload));
    yield put(resetSelectedJobs());
  } catch (e) {
    yield put(errorBulkForceJobVisibility({ error: e }));
  }
}

function* loadBulkForceAllJobsVisibilityEvent({ payload }) {
  const {
    forcedVisibility,
    activeNetwork: { id: collectionId },
    ...filters
  } = payload;
  try {
    yield call(request, {
      url: `collections/${collectionId}/jobs/bulk_force_visibility_all`,
      options: {
        baseURL: process.env.REACT_APP_API_V2_BASE_URL,
        method: 'put',
        data: {
          forced_visibility: forcedVisibility,
          ...filters,
        },
      },
    });
    yield put(loadedBulkForceAllJobsVisibility(payload));
    yield put(resetJobsTable());
  } catch (error) {
    yield put(errorBulkForceAllJobsVisibility({ error }));
  }
}

function* loadJobApplicationDownloadEvent({ payload }) {
  const {
    activeNetwork: { id: collectionId },
    jobId,
    bulk,
  } = payload;
  try {
    const url = bulk
      ? `collections/${collectionId}/job_applications/export_all_csv`
      : `collections/${collectionId}/jobs/${jobId}/job_applications/export_csv`;

    yield call(request, {
      url,
      options: {
        baseURL: process.env.REACT_APP_API_V2_BASE_URL,
        method: 'post',
      },
    });
    yield put(loadedJobApplicationDownload(payload));
  } catch (error) {
    yield put(errorJobApplicationDownload({ error }));
  }
}

export default function* saga() {
  yield takeLatest(loadExtendJob().type, loadExtendJobEvent);
  yield takeLatest(loadCloseJob().type, loadCloseJobEvent);
  yield takeLatest(loadDeleteJob().type, loadDeleteJobEvent);
  yield takeLatest(loadForceJobVisibility().type, loadForceJobVisibilityEvent);
  yield takeLatest(loadFeatureJob().type, loadFeatureJobEvent);
  yield takeLatest(loadBulkForceJobVisibility().type, loadBulkForceJobVisibilityEvent);
  yield takeLatest(loadBulkForceAllJobsVisibility().type, loadBulkForceAllJobsVisibilityEvent);
  yield takeLatest(loadJobApplicationDownload().type, loadJobApplicationDownloadEvent);
}
