/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import axios from 'axios';
import { Box as FlexBox, Flex, Text } from 'rebass/styled-components';
import { Box, Loader, AsyncButton, MessageBlock, Button } from '@getro/rombo';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Bell, Check, DownloadCloud, Filter, Settings, Share } from 'lucide-react';

import compose from '../../helpers/compose';
import withPage from '../../hocs/withPage';
import withFilters from '../../hocs/withFilters';
import { useInjectReducer } from '../../hooks/useInjectReducer';
import { useInjectSaga } from '../../hooks/useInjectSaga';
import useTalentGroups from '../../hooks/useTalentGroups';
import NetworkSchema from '../../schemas/network';
import { makeSelectIsLoading } from '../../redux/loadingSelector';

import PageLayout from '../../components/layout/Page';
import ExportModal from '../../components/molecules/ExportModal';
import Pagination from '../../components/atoms/Pagination';
import MemberModal from '../../components/organisms/memberModal';
import { FilterModal } from '../../components/organisms/filterModal';

import strings from '../../strings';
import SearchInput from './SearchInput';
import ErrorMessage from './ErrorMessage';

import {
  boardChange,
  createNewBoard,
  deleteBoard,
  loadAddRemoveMemberToList,
  loadBoardSettings,
  loadCreateAndAddMemberToList,
  loadCreateBoard,
  loadDeleteBoard,
  loadExportMembers,
  loadMemberDetails,
  loadMemberLocationOptions,
  loadMembers,
  loadMemberVouchedByOptions,
  loadMemberSkillOptions,
  loadVouchMember,
  resetVouchMember,
  setCurrentBoard,
  setFiltersModal,
  shareBoard,
  showBoardSettings,
  updateMemberById,
} from './actions';
import {
  key,
  makeSelectCreateBoardError,
  makeSelectCurrentBoard,
  makeSelectErrorExportingMembers,
  makeSelectErrorFetchingMembers,
  makeSelectExportedMembers,
  makeSelectIsCreatingNewBoard,
  makeSelectMemberDetails,
  makeSelectMemberLocationOptions,
  makeSelectMembers,
  makeSelectMemberSkillOptions,
  makeSelectMembersTotal,
  makeSelectMembersTotalPages,
  makeSelectMemberVouchedByOptions,
  makeSelectNewTalentGroup,
  makeSelectShowBoardSettings,
  makeSelectShowDeleteBoardModal,
  makeSelectVouchMemberResult,
  makeSelectWasBoardDeleted,
  makeSelectWasNewBoardCreated,
  makeDefaultBoard,
} from './selectors';
import saga from './saga';
import reducer from './reducer';
import { getFilters } from '../../helpers/queryString';
import serializeParams from '../../helpers/serializeParams';
import { MembersSideBar } from '../../components/organisms/membersSidebar';
import NoResultsMessage from './NoResultsMessage';
import { loadSubscribeGroup } from '../../actions/talentGroupActions';
import { makeHasDailyAlerts } from '../../redux/selectors';
import { usePrevious } from '../../hooks/usePrevious';

import { MemberListCard } from '../../components/molecules/memberListCard';
import useVouchModal from '../../hooks/vouch/useVouchModal';
import { VOUCH_CONFIG } from '../../components/molecules/VouchModal';
import VouchModalWrapper from '../../components/molecules/VouchModalWrapper';

const filterSections = {
  skills: ['skillIds'],
  workExperience: ['jobSearchStatuses', 'seniorities', 'usWorkStatus'],
  preferences: ['locationIds', 'remoteWork', 'willWorkAnywhere', 'mentorship'],
  endorsements: ['verifiedMembers', 'referralIds'],
};

const scrollToBoard = ({ id }) => {
  setTimeout(() => {
    const sidebarComponent = document.querySelector('[data-testid="company-boards"]');
    if (sidebarComponent) {
      const list = sidebarComponent.querySelector('[data-testid="searchable-list-list"]');
      if (list) {
        const elm = list.querySelector(`#custom_board_${id}`);
        if (elm) {
          list.scrollTop = elm.offsetTop;
        }
      }
    }
  });
};

const MembersContent = ({ activeNetwork, currentUser, filters, history, match, page, setFilters, setPage }) => {
  const location = useLocation();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const defaultBoard = useSelector(makeDefaultBoard);
  const { id: activeNetworkId, isManager: isNm, isConnector: isNc, name } = activeNetwork;
  const hasDailyAlerts = useSelector(makeHasDailyAlerts);
  const [networkTalentGroups] = useTalentGroups({ id: activeNetworkId });
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [showExportMembersModal, setShowExportMembersModal] = useState(false);
  const exportMembersSuccess = useSelector(makeSelectExportedMembers);
  const exportMembersError = useSelector(makeSelectErrorExportingMembers);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const isBoardSettingsVisible = useSelector(makeSelectShowBoardSettings);
  const isBoardDeleteConfirmationVisible = useSelector(makeSelectShowDeleteBoardModal);
  const exportMembersCancelToken = useRef(axios.CancelToken.source());
  const isCreatingNewBoard = useSelector(makeSelectIsCreatingNewBoard);
  const fetching = useSelector(makeSelectIsLoading('members'));
  const isMemberSkillOptionsLoading = useSelector(makeSelectIsLoading('memberSkillOptions'));
  const isVouchedByOptionsLoading = useSelector(makeSelectIsLoading('memberVouchedByOptions'));
  const isPreferredLocationOptionsLoading = useSelector(makeSelectIsLoading('memberLocationOptions'));
  const isCreateLoading = useSelector(makeSelectIsLoading('createBoard'));
  const creatingList = useSelector(makeSelectIsLoading('createAndAddMemberToList'));
  const isLoadingTalentGroups = useSelector(makeSelectIsLoading('talentGroups'));
  const isDeletingBoard = useSelector(makeSelectIsLoading('deleteBoard'));
  const isSubscribingBoard = useSelector(makeSelectIsLoading('subscribeGroup'));
  const isVouchingMember = useSelector(makeSelectIsLoading('vouchMember'));
  const vouchResult = useSelector(makeSelectVouchMemberResult);
  const boardSettingsModalError = useSelector(makeSelectCreateBoardError);
  const [activeFilters, setActiveFilters] = useState(null);
  const members = useSelector(makeSelectMembers);
  const total = useSelector(makeSelectMembersTotal);
  const totalPages = useSelector(makeSelectMembersTotalPages);
  const errorFetchingMembers = useSelector(makeSelectErrorFetchingMembers);
  const memberSkillOptions = useSelector(makeSelectMemberSkillOptions);
  const preferredLocationsOptions = useSelector(makeSelectMemberLocationOptions);
  const vouchedByOptions = useSelector(makeSelectMemberVouchedByOptions);
  const wasNewBoardCreated = useSelector(makeSelectWasNewBoardCreated);
  const wasBoardDeleted = useSelector(makeSelectWasBoardDeleted);
  const newTalentGroup = useSelector(makeSelectNewTalentGroup);
  const currentBoard = useSelector(makeSelectCurrentBoard);
  const previousBoard = usePrevious(currentBoard);
  const memberDetails = useSelector(makeSelectMemberDetails);
  const loadingMemberDetails = useSelector(makeSelectIsLoading('memberDetails'));
  const {
    openVerify,
    openVouch,
    fetchVouchError,
    setFetchVouchError,
    vouchMember,
    vouchOpen,
    vouchAction,
    setVouchOpen,
    isLoading,
  } = useVouchModal(VOUCH_CONFIG);
  const canVerifyMembers = isNc || isNm;

  // a user is on a custom list when the filters array has a talentGroup
  const isUserCreatedBoard = filters && Object.prototype.hasOwnProperty.call(filters, 'talentGroups');

  const handlePageChange = async (e, { activePage }) => {
    setPage(activePage);
  };

  useEffect(() => {
    if (currentBoard && currentBoard.id) {
      if (!previousBoard || currentBoard.id !== previousBoard.id) {
        dispatch(boardChange({ board: currentBoard }));
      }
    }
  }, [previousBoard, dispatch, currentBoard]);

  useEffect(() => {
    if (wasNewBoardCreated) {
      const newBoardId = newTalentGroup[0].id;
      const search = {
        filters: {
          talentGroups: [{ value: newBoardId }],
        },
      };
      history.push({
        pathname: `/networks/${activeNetwork.slug}/members`,
        search: serializeParams(search),
      });
      scrollToBoard({ id: newBoardId });
    }
  }, [activeNetwork, history, newTalentGroup, wasNewBoardCreated]);

  useEffect(() => {
    if (wasBoardDeleted) {
      history.push({
        pathname: `/networks/${activeNetwork.slug}/members`,
      });
    }
  }, [activeNetwork.slug, history, wasBoardDeleted]);

  useEffect(() => {
    // when we change the list we are on update the name
    if (!isLoadingTalentGroups && networkTalentGroups) {
      if (isUserCreatedBoard) {
        const currentTalentGroupId = filters.talentGroups[0].value;
        const currentGroup = networkTalentGroups.find((group) => group.value === currentTalentGroupId);
        if (currentGroup) {
          dispatch(setCurrentBoard(currentGroup));
          scrollToBoard({ id: currentGroup.id });
        }
      } else {
        dispatch(setCurrentBoard(defaultBoard));
      }
      // not sure what the product intent is if we can't find this group. this would happen if a user messed with the URL or has an old bookmark
    }
  }, [dispatch, isUserCreatedBoard, networkTalentGroups, filters, isLoadingTalentGroups, defaultBoard]);

  useEffect(() => {
    if (JSON.stringify({ activeNetworkId, filters, page }) !== activeFilters) {
      setActiveFilters(
        JSON.stringify({
          activeNetworkId,
          filters,
          page,
        }),
      );
      dispatch(loadMembers({ activeNetworkId, page, filters }));
    }
  }, [activeFilters, activeNetworkId, dispatch, filters, page]);

  useEffect(
    () => () => {
      exportMembersCancelToken.current.cancel();
    },
    [],
  );

  useEffect(() => {
    if (selectedMember) {
      setSelectedMember(members.find((m) => m.id === selectedMember.id));
    }
  }, [members, selectedMember]);

  // Export members

  const employmentTypeOptions = Object.keys(strings.profile.employment)
    .filter((k) => k !== 'employment_type_unspecified')
    .map((k) => ({ id: k, label: strings.profile.employment[k] }));
  const talentNetworkActive = activeNetwork.features.includes('talent_network');
  const isMentorshipFeatureActive = !activeNetwork.features.includes('mentorship_deactivated');

  const handleExportMembersClick = async () => {
    setShowExportMembersModal(true);
    dispatch(loadExportMembers({ filters }));
  };

  // Blank message for Connectors
  if (!fetching && !members.length && !Object.keys(filters).length && isNc) {
    return (
      <PageLayout>
        <PageLayout.Content>
          <Box>
            <MessageBlock
              title="Who should we know?"
              buttonText="Invite member"
              onClick={() => history.push(`${match.url}/invite`)}
            >
              <p>As an ambassador you can only see those people that you invite.</p>
              <p>
                Invite people you know and trust to the {name} talent network so they can discover opportunities and
                connect with companies in our network.
              </p>
              <p>
                You can bring people in to the network by inviting them directly from the platform or by emailing them,
                cc’ing <a href="mailto:?cc=invite@getro.com">invite@getro.com</a>
                (check with your network admin to see if they are using a custom email address).
              </p>
            </MessageBlock>
          </Box>
        </PageLayout.Content>
      </PageLayout>
    );
  }

  const updateIntroduction = (memberId, introduction) => {
    dispatch(updateMemberById({ memberId, updatedProps: { introductionRequested: introduction } }));
  };

  // Add Export button only for NM
  const actionsDisabled = errorFetchingMembers || (!fetching && !errorFetchingMembers && total === 0);

  const secondaryActions = [];
  if (isNm && !actionsDisabled) {
    secondaryActions.push({
      action: handleExportMembersClick,
      icon: DownloadCloud,
      text: 'Download',
      popoverContent: 'Download search results in csv format',
    });
  }

  // invite button
  const handleMainActionClick = () => history.push(`${match.url}/invite`);
  const mainActionText = actionsDisabled ? null : 'Invite';
  const getHeaderText = () => {
    if (errorFetchingMembers) {
      return '';
    }
    if (fetching) {
      return 'Loading...';
    }
    if (total) {
      return `Showing ${members.length.toLocaleString()} of ${total.toLocaleString()} member${
        total.length === 1 ? '' : 's'
      }`;
    }

    return '';
  };
  const handleSearchChange = ({ target }) => {
    setPage(1);
    return setFilters({ [target.name]: target.value });
  };
  const toggleOpenFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
    dispatch(setFiltersModal({ board: currentBoard, activeNetworkId: activeNetwork.id, isFiltersOpen }));
  };
  const getFilterCount = () => {
    const filterKeys = Object.keys(filters);
    let numberOfFilters = 0;
    Object.keys(filterSections).forEach((k) => {
      const sectionCount = filterSections[k].filter((v) => filterKeys.includes(v)).length;
      if (sectionCount > 0) {
        numberOfFilters += sectionCount;
      }
    });
    if (numberOfFilters) {
      return `(${numberOfFilters})`;
    }
    return null;
  };
  const applyFilters = (newFilters) => {
    const query = {};
    // remove all the empty values from the filters
    Object.keys(newFilters).forEach((prop) => {
      if (newFilters[prop] !== null && (newFilters[prop] === true || !!newFilters[prop].length)) {
        query[prop] = newFilters[prop];
      }
    });
    history.push({ search: serializeParams({ filters: query }) });
    setIsFiltersOpen(false);
  };
  const clearFilters = () => {
    history.push({ search: '' });
    setIsFiltersOpen(false);
  };
  const onBoardQuickCreate = (item) => {
    const doesGroupExist = networkTalentGroups.find((group) => group.label === item.name);
    if (!doesGroupExist) {
      dispatch(createNewBoard(true));
      dispatch(loadCreateBoard({ ...item, origin: 'sidebar_quick_create' }));
    }
  };
  const onRetryFetchMembers = () => dispatch(loadMembers({ activeNetworkId, page, filters }));

  const boardsShareLocation = (board) => {
    let returnString = `/networks/${activeNetwork.slug}/members/share/email`;
    if (board.id) {
      const queryString = serializeParams({ talentGroups: [board.id] });
      returnString += queryString;
    }
    return returnString;
  };

  return (
    <PageLayout mainAction={handleMainActionClick} mainActionText={mainActionText}>
      {(!isNc || isNm) && (
        <MembersSideBar
          isBoardDeleteConfirmationVisible={isBoardDeleteConfirmationVisible}
          onDeleteBoardCancel={() => dispatch(deleteBoard(false))}
          isDeletingBoard={isDeletingBoard}
          onSubmitDeleteBoard={() => {
            dispatch(
              loadDeleteBoard({
                talentGroupId: currentBoard.id,
              }),
            );
          }}
          isBoardSettingsVisible={isBoardSettingsVisible}
          boardSettingsModalError={boardSettingsModalError}
          onCancelBoardSettingsModal={() => dispatch(showBoardSettings(false))}
          onSubmitBoardSettingsModal={(value) => dispatch(loadCreateBoard({ ...value, origin: 'sidebar_create' }))}
          isCreatingNewBoard={isCreatingNewBoard}
          skills={{
            isLoading: isMemberSkillOptionsLoading,
            options: memberSkillOptions,
            onInputChange: (e) => {
              dispatch(loadMemberSkillOptions({ networkId: activeNetwork.id, term: e }));
            },
          }}
          preferredLocations={{
            isLoading: isPreferredLocationOptionsLoading,
            options: preferredLocationsOptions,
            onInputChange: (e) => {
              dispatch(loadMemberLocationOptions({ term: e }));
            },
          }}
          vouchedBy={{
            isLoading: isVouchedByOptionsLoading,
            options: vouchedByOptions,
            onInputChange: (e) => {
              dispatch(loadMemberVouchedByOptions({ networkId: activeNetwork.id, term: e }));
            },
          }}
          onDeleteBoard={() => dispatch(deleteBoard(true))}
          currentBoard={currentBoard}
          hasDailyAlerts={hasDailyAlerts}
          isTalentGroupsLoading={isLoadingTalentGroups}
          isCreateLoading={isCreateLoading}
          activeNetwork={activeNetwork}
          networkTalentGroups={networkTalentGroups}
          onCreate={() => {
            dispatch(createNewBoard(true));
            dispatch(showBoardSettings(true));
          }}
          onQuickCreate={onBoardQuickCreate}
          memberInviteUrl={`/networks/${activeNetwork.slug}/members/invite`}
        />
      )}
      <PageLayout.Content
        fullWidth
        header={getHeaderText()}
        preHeader={
          <Flex flexDirection={['column']}>
            <FlexBox width={[1]} sx={{ minHeight: '2.5em' }} mb={4}>
              {!isLoadingTalentGroups && (
                <Flex flexDirection={['column']}>
                  <Flex alignItems="center">
                    <Text variant="default" fontWeight="bold" fontSize={3} as="h2">
                      {currentBoard.label}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" mt={2}>
                    {currentBoard.label !== defaultBoard.label && (
                      <>
                        <AsyncButton
                          variant={currentBoard.subscribed ? 'secondary' : 'primary'}
                          display="flex"
                          sx={{ fontSize: 1, lineHeight: 1.1, alignItems: 'center' }}
                          onClick={() => {
                            dispatch(loadSubscribeGroup({ talentGroup: currentBoard }));
                          }}
                          loading={isSubscribingBoard}
                        >
                          <FlexBox
                            mr={2}
                            height="1.23em"
                            width="1.23em"
                            strokeWidth="1.5"
                            aria-hidden="true"
                            as={currentBoard.subscribed ? Check : Bell}
                          />
                          {currentBoard.subscribed ? 'Subscribed' : 'Subscribe'}
                        </AsyncButton>
                        {!currentBoard.systemBoard && (
                          <Button
                            variant="secondary"
                            bg="transparent"
                            sx={{ border: 'none' }}
                            alignItems="center"
                            display="flex"
                            onClick={() => {
                              dispatch(loadBoardSettings(currentBoard));
                            }}
                          >
                            <FlexBox
                              mr={2}
                              height="1em"
                              width="1em"
                              strokeWidth="1.5"
                              aria-hidden="true"
                              as={Settings}
                            />
                            Settings
                          </Button>
                        )}
                      </>
                    )}
                    <Button
                      as={NavLink}
                      to={boardsShareLocation(currentBoard)}
                      variant="secondary"
                      bg="transparent"
                      sx={{ border: 'none', alignItems: 'center' }}
                      display="flex"
                      onClick={() => {
                        dispatch(shareBoard({ board: currentBoard }));
                      }}
                    >
                      <FlexBox mr={2} height="1em" width="1em" strokeWidth="1.5" aria-hidden="true" as={Share} />
                      Share
                    </Button>
                  </Flex>
                  {currentBoard.description && (
                    <Text variant="default" fontSize={1}>
                      {currentBoard.description}
                    </Text>
                  )}
                </Flex>
              )}
            </FlexBox>
            <FlexBox width={[1]}>
              <Flex width={1} alignItems={['flex-start']} alignContent="stretch">
                <FlexBox flexGrow={[1]} mr={[1]}>
                  <SearchInput onChange={handleSearchChange} value={filters.keyword} />
                </FlexBox>
                <FlexBox ml={[1]} mt="24px" mb={3}>
                  <Button
                    onClick={toggleOpenFilters}
                    display="flex"
                    sx={{ alignItems: 'center' }}
                    justifyContent="center"
                    variant="secondary"
                  >
                    <Flex alignItems="center">
                      <FlexBox as={Filter} mr="2" width="16px" height="16px" aria-hidden="true" />
                      <Text>Filters {getFilterCount()}</Text>
                    </Flex>
                  </Button>
                </FlexBox>
              </Flex>
            </FlexBox>
          </Flex>
        }
        secondaryActions={secondaryActions}
      >
        {fetching && <Loader />}
        {errorFetchingMembers && <ErrorMessage onRetryClick={onRetryFetchMembers} />}
        {members.map((m) => (
          <MemberListCard
            updateIntroduction={updateIntroduction}
            onBoardSelectionChanged={(e) => {
              const { id } = m;
              const { value, checked } = e.target;
              const talentGroup = networkTalentGroups.find((g) => g.value === parseInt(value, 10));
              dispatch(loadAddRemoveMemberToList({ memberId: id, talentGroup, checked, origin: 'member_card' }));
            }}
            key={m.id}
            onMemberClick={() => {
              setSelectedMember(m);
              setIsModalOpen(true);
              dispatch(loadMemberDetails({ member: m }));
            }}
            openVouch={() => {
              setSelectedMember(m);
              openVouch(m);
            }}
            openVerify={() => {
              setSelectedMember(m);
              openVerify(m);
            }}
            onCreateAndAddMemberToList={(payload) =>
              dispatch(loadCreateAndAddMemberToList({ ...payload, origin: 'member_card_quick_create' }))
            }
            networkTalentGroups={networkTalentGroups}
            activeNetwork={activeNetwork}
            member={m}
          />
        ))}
        {!fetching && !errorFetchingMembers && total === 0 && <NoResultsMessage />}
        {!fetching && !errorFetchingMembers && totalPages > 1 && (
          <Flex pb="4">
            <Pagination activePage={page} totalPages={totalPages} onPageChange={handlePageChange} />
          </Flex>
        )}
      </PageLayout.Content>
      <VouchModalWrapper
        isLoading={isLoading}
        setVouchOpen={setVouchOpen}
        vouchAction={vouchAction}
        vouchOpen={vouchOpen}
        member={vouchMember}
        setFetchVouchError={setFetchVouchError}
        fetchVouchError={fetchVouchError}
        currentUser={currentUser}
        canVerifyMembers={canVerifyMembers}
        vouchResult={vouchResult}
        isVouchingMember={isVouchingMember}
        onSubmit={(payload) => dispatch(loadVouchMember(payload))}
        onCloseVouchModal={() => dispatch(resetVouchMember())}
      />
      {showExportMembersModal && (
        <ExportModal
          error={exportMembersError}
          success={exportMembersSuccess}
          onClose={() => setShowExportMembersModal(false)}
        />
      )}
      {isFiltersOpen && (
        <FilterModal
          filterValues={getFilters(location)}
          onCancel={toggleOpenFilters}
          onClearAll={clearFilters}
          onFilter={applyFilters}
          skills={{
            isLoading: isMemberSkillOptionsLoading,
            options: memberSkillOptions,
            onInputChange: (e) => {
              dispatch(loadMemberSkillOptions({ networkId: activeNetwork.id, term: e }));
            },
          }}
          preferredLocations={{
            isLoading: isPreferredLocationOptionsLoading,
            options: preferredLocationsOptions,
            onInputChange: (e) => {
              dispatch(loadMemberLocationOptions({ term: e }));
            },
          }}
          vouchedBy={{
            isLoading: isVouchedByOptionsLoading,
            options: vouchedByOptions,
            onInputChange: (e) => {
              dispatch(loadMemberVouchedByOptions({ networkId: activeNetwork.id, term: e }));
            },
          }}
        />
      )}
      {isModalOpen && (
        <MemberModal
          memberDetails={memberDetails}
          loadingMemberDetails={loadingMemberDetails}
          onModalClose={() => {
            setIsModalOpen(false);
            setSelectedMember(null);
          }}
          employmentTypeOptions={employmentTypeOptions}
          isMentorshipFeatureActive={isMentorshipFeatureActive}
          seniorityOptions={strings.profile.seniorities}
          member={selectedMember}
          isTalentNetworkActive={talentNetworkActive}
          activeNetwork={activeNetwork}
          currentUser={currentUser}
          updateIntroduction={updateIntroduction}
          networkTalentGroups={networkTalentGroups}
          onCreateAndAddMemberToList={(payload) =>
            dispatch(loadCreateAndAddMemberToList({ ...payload, origin: 'member_profile_quick_create' }))
          }
          onAddRemoveMemberToList={(payload) =>
            dispatch(loadAddRemoveMemberToList({ ...payload, origin: 'member_profile' }))
          }
          creating={creatingList}
          addToBoardError={selectedMember.createListErrorMessage}
        />
      )}
    </PageLayout>
  );
};

MembersContent.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  activeNetwork: NetworkSchema.isRequired,
};

export default compose(withRouter, withFilters, withPage)(MembersContent);
