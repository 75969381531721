import React from 'react';
import { Flex, Image, Text } from 'rebass/styled-components';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from '@getro/rombo';
import { Contact, ListRestart, Wand2 } from 'lucide-react';
import IconHandEmptyIcon from '../../assets/icon-hand-empty.svg';
import IconRefreshing from '../../assets/icon-refreshing.svg';
import { activeNetworkSelector } from '../../redux/selectors';

export const ListContactsEmptyState = ({ listId, isJobList, autoUpdate, refreshInProgress }) => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const containerWidth = autoUpdate ? ['300px', '480px'] : '280px';
  const buttonsDirection = autoUpdate ? 'row' : 'column';

  return (
    <Flex my="80px" flexDirection="column" alignItems="flex-start" width={containerWidth} mx="auto">
      {autoUpdate ? (
        <>
          <Image
            src={refreshInProgress ? IconRefreshing : IconHandEmptyIcon}
            width="64px"
            maxWidth="64px"
            alt="Your contacts list is ready"
          />
          <Flex mt="16px" sx={{ gap: '8px', flexDirection: 'column' }}>
            <Text textAlign="left" fontSize="14px" fontWeight="500" color="text.dark">
              {refreshInProgress ? 'Auto-update in progress' : 'No contacts match the Auto-update filters yet'}
            </Text>
            <Text pb="32px" as="span" color="text.subtle" fontSize="14px">
              {refreshInProgress
                ? 'Auto-update is currently in progress, analyzing all contacts to add those matching your filters. It may take a few minutes; come back soon to see the updates.'
                : `Contacts matching your filters will be added automatically. Auto-update runs daily, get updates anytime from the 'Actions' menu.`}
            </Text>
            <Text pb="8px" color="text.subtle" fontSize="14px">
              You can also add contacts manually from:
            </Text>
          </Flex>
        </>
      ) : (
        <>
          <Image src={IconHandEmptyIcon} width="64px" maxWidth="64px" alt="Your contacts list is ready" />
          <Flex sx={{ gap: '8px', flexDirection: 'column' }}>
            <Text textAlign="left" fontWeight="500" color="text.dark">
              Your contacts list is ready{' '}
            </Text>
            <Text mt="32px" pb="8px" as="span" color="text.subtle">
              Start adding contacts from...
            </Text>
          </Flex>
        </>
      )}
      <Flex
        flexDirection={buttonsDirection}
        width="100%"
        sx={{
          gap: '8px',
          color: 'text.main',
        }}
      >
        {isJobList && (
          <Button
            width="100%"
            variant="secondary"
            icon={Wand2}
            iconGap="8px"
            size="small"
            as={NavLink}
            to={`/networks/${activeNetwork.slug}/list/${listId}/ai-matches`}
            sx={{ color: 'text.main', justifyContent: 'center' }}
          >
            AI matches
          </Button>
        )}
        <Button
          width="100%"
          size="small"
          icon={Contact}
          variant="secondary"
          as={NavLink}
          to={`/networks/${activeNetwork.slug}/list/${listId}/add-contacts`}
          sx={{ color: 'text.main', justifyContent: 'center' }}
        >
          All your contacts
        </Button>
      </Flex>
      {!autoUpdate && (
        <Flex mt="32px" flexDirection="column" width="100%" sx={{ gap: '8px' }}>
          <Text as="span" color="text.subtle">
            Or
          </Text>
          <Button
            width="100%"
            size="small"
            icon={ListRestart}
            variant="secondary"
            as={NavLink}
            to={`/networks/${activeNetwork.slug}/list/${listId}/contacts/lists/settings`}
            sx={{ color: 'text.main', justifyContent: 'center' }}
          >
            Enable Auto-update
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

ListContactsEmptyState.defaultProps = {
  listId: '',
  isJobList: false,
  autoUpdate: false,
  refreshInProgress: false,
};

ListContactsEmptyState.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isJobList: PropTypes.bool,
  autoUpdate: PropTypes.bool,
  refreshInProgress: PropTypes.bool,
};
