import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass/styled-components';
import { ProfilePicture, StatusIndicator, Button, AsyncButton } from '@getro/rombo';
import { X } from 'lucide-react';
import { getUserDisplayName } from '../../../helpers/users';

export const AdminListItem = ({
  onRemoveAdmin,
  onResendInvitation,
  isResendingInvitation,
  user,
  showEmailAddress,
  ...rebassProps
}) => {
  const displayName = useMemo(() => getUserDisplayName(user), [user]);
  const userStatus = useMemo(() => (user.onboardingCompleted ? 'Active' : 'Pending'), [user.onboardingCompleted]);
  const statusColor = useMemo(() => (user.onboardingCompleted ? 'success' : 'warning'), [user.onboardingCompleted]);
  return (
    <Flex alignItems="center" py={3} data-testid="admin-list-item" {...rebassProps}>
      <Box mr={[2, 3]} width="48px" minWidth="48px">
        <ProfilePicture imageUrl={user.avatarUrl} name={displayName} />
      </Box>
      <Flex width={1 / 2} flexDirection="column">
        <Text data-testid="display-name" fontWeight="semibold" color="text.dark">
          {displayName}
        </Text>
        {showEmailAddress && (
          <Text data-testid="email" fontColor="text.subtle" fontSize={1}>
            {user.email}
          </Text>
        )}
      </Flex>
      <Flex width={[1 / 2]} flexDirection="column">
        <StatusIndicator status={userStatus} statusColor={statusColor} />
        {!user.onboardingCompleted && (
          <>
            {!user.invitationSent && onResendInvitation && (
              <AsyncButton
                data-testid="resend-invite"
                width="max-content"
                color="text.subtle"
                variant="underlineLink"
                pl={2}
                ml={2}
                loading={isResendingInvitation}
                onClick={() => {
                  onResendInvitation(user.id);
                }}
              >
                Resend invitation
              </AsyncButton>
            )}
            {user.invitationSent && (
              <Text color="text.subtle" data-testid="sent-invite">
                Invitation email sent
              </Text>
            )}
          </>
        )}
      </Flex>
      {onRemoveAdmin && (
        <Button
          data-testid="remove-admin"
          variant="tertiary"
          color="neutral.600"
          size="small"
          p={2}
          title="Remove"
          onClick={() => {
            onRemoveAdmin(user);
          }}
        >
          <Box
            as={X}
            height="16px"
            width="16px"
            sx={{ minWidth: '16px', minHeight: '16px' }}
            strokeWidth="1.5"
            aria-hidden="true"
          />
          <Text variant="srOnly">Remove</Text>
        </Button>
      )}
    </Flex>
  );
};

AdminListItem.propTypes = {
  user: PropTypes.object.isRequired,
  showEmailAddress: PropTypes.bool.isRequired,
  onResendInvitation: PropTypes.func,
  onRemoveAdmin: PropTypes.func,
  isResendingInvitation: PropTypes.bool,
};

AdminListItem.defaultProps = {
  onResendInvitation: null,
  onRemoveAdmin: null,
  isResendingInvitation: false,
};
