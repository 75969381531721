import React from 'react';
import { Panel, Toggle } from '@getro/rombo';
import { Text } from 'rebass/styled-components';
import { Label } from '@rebass/forms/styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { hasContactsSelector } from '../../../redux/selectors';

const EmailNotificationsPanel = ({
  onChange,
  email,
  managerJobsExpiring,
  jobSourcesUpdates,
  jbEngagementWeeklyUpdate,
  jbSuggestedCompanies,
  cnHiresReport,
  cnMatchesReport,
  areSuggestedCompaniesEnabled,
  isNetworkAdmin,
  areJobAlertsEnabled,
}) => {
  const showConnect = useSelector(hasContactsSelector);

  const onToggleUpdate = (toggle) => (e) => {
    const { checked } = e.target;

    onChange(toggle, checked);
  };

  return (
    <Panel title="Email notifications">
      <Text mt="40px" mb={[2]} fontWeight="medium">
        You’re receiving notifications at {email}.
      </Text>

      <Text
        pt={[4]}
        mt={[3]}
        pb="8px"
        fontSize="14px"
        fontWeight="medium"
        color="text.subtle"
        sx={{ borderBottom: 1, borderBottomColor: 'neutral.100', textTransform: 'uppercase', letterSpacing: '1px' }}
      >
        Job board
      </Text>

      {isNetworkAdmin && areJobAlertsEnabled && (
        <>
          <Text mt="3" pt="1" pb="2" fontWeight="medium" color="text.dark">
            Job board engagement weekly update
          </Text>
          <Label justifyContent="space-between">
            <Text fontWeight="body" fontSize="1" color="text.main">
              Get updated on how job seekers are engaging with your job board.
            </Text>
            <Toggle
              data-testid="job-board-engagement-weekly-update-toggle"
              size="large"
              value
              checked={jbEngagementWeeklyUpdate}
              onChange={onToggleUpdate('JbEngagementWeeklyUpdate')}
            />
          </Label>
        </>
      )}

      <Text mt="3" pt="1" pb="2" fontWeight="medium" color="text.dark">
        Jobs about to expire
      </Text>
      <Label justifyContent="space-between">
        <Text fontWeight="body" fontSize="1" color="text.main">
          Get notified of jobs you posted that are about to expire.
        </Text>
        <Toggle
          data-testid="job-to-expire-toggle"
          size="large"
          value
          checked={managerJobsExpiring}
          onChange={onToggleUpdate('ManagerJobsExpiring')}
        />
      </Label>
      {isNetworkAdmin && (
        <>
          <Text mt="3" pt="1" pb="2" fontWeight="medium" color="text.dark">
            Companies updates
          </Text>
          <Label justifyContent="space-between">
            <Text fontWeight="body" fontSize="1" color="text.main">
              Get notified with updates of companies you add.
            </Text>
            <Toggle
              data-testid="job-sources-toggle"
              size="large"
              value
              checked={jobSourcesUpdates}
              onChange={onToggleUpdate('JobSourcesUpdates')}
            />
          </Label>
        </>
      )}

      {isNetworkAdmin && areSuggestedCompaniesEnabled && (
        <>
          <Text mt="3" pt="1" pb="2" fontWeight="medium" color="text.dark">
            Suggested companies
          </Text>
          <Label justifyContent="space-between">
            <Text fontWeight="body" fontSize="1" color="text.main">
              Get notified when we find new companies in your portfolio.
            </Text>
            <Toggle
              data-testid="suggested-companies-toggle"
              size="large"
              value
              checked={jbSuggestedCompanies}
              onChange={onToggleUpdate('JbSuggestedCompanies')}
            />
          </Label>
        </>
      )}

      {showConnect && (
        <>
          <Text
            mt="56px"
            pb="8px"
            fontSize="14px"
            fontWeight="medium"
            color="text.subtle"
            sx={{ borderBottom: 1, borderBottomColor: 'neutral.100', textTransform: 'uppercase', letterSpacing: '1px' }}
          >
            Contacts
          </Text>
          <Text mt="3" pt="1" pb="2" fontWeight="medium" color="text.dark">
            New matches report
          </Text>
          <Label justifyContent="space-between">
            <Text fontWeight="body" fontSize="1" color="text.main">
              Get weekly notifications about new matches between your contacts and your listed companies.
            </Text>
            <Toggle
              data-testid="contact-matches-report"
              size="large"
              value
              checked={cnMatchesReport}
              onChange={onToggleUpdate('CnMatchesReport')}
            />
          </Label>
          <Text mt="3" pt="1" pb="2" fontWeight="medium" color="text.dark">
            New hires report
          </Text>
          <Label justifyContent="space-between">
            <Text fontWeight="body" fontSize="1" color="text.main">
              Get notified when someone in your Contacts is hired by one of your listed companies.
            </Text>
            <Toggle
              data-testid="contact-hires-report"
              size="large"
              value
              checked={cnHiresReport}
              onChange={onToggleUpdate('CnHiresReport')}
            />
          </Label>
        </>
      )}
    </Panel>
  );
};

EmailNotificationsPanel.propTypes = {
  email: PropTypes.string.isRequired,
  managerJobsExpiring: PropTypes.bool.isRequired,
  jobSourcesUpdates: PropTypes.bool.isRequired,
  jbEngagementWeeklyUpdate: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isNetworkAdmin: PropTypes.bool,
  areJobAlertsEnabled: PropTypes.bool,
  areSuggestedCompaniesEnabled: PropTypes.bool,
  jbSuggestedCompanies: PropTypes.bool,
  cnHiresReport: PropTypes.bool,
  cnMatchesReport: PropTypes.bool,
};

EmailNotificationsPanel.defaultProps = {
  isNetworkAdmin: true,
  areJobAlertsEnabled: false,
  areSuggestedCompaniesEnabled: false,
  jbSuggestedCompanies: false,
  cnHiresReport: false,
  cnMatchesReport: false,
};

export default EmailNotificationsPanel;
