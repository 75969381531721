import React from 'react';
import { Image, Text, Flex, Card } from 'rebass/styled-components';
import MagnifyingGlassIcon from '../../../assets/icon-effortless-search.svg';

const NoResultsMessage = () => (
  <Card data-testid="members-no-result-message" width={[2 / 3]} mt={[6]} variant="card.default">
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <Image src={MagnifyingGlassIcon} mb={[3]} width="64px" height="64px" />
      <Text variant="default" color="black" fontWeight="medium" lineHeight="1.2" mb={[2]}>
        We can&apos;t find anyone who matches your search...
      </Text>
      <Text variant="default">Try removing some filters to see more results.</Text>
    </Flex>
  </Card>
);

export default NoResultsMessage;
