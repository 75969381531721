import { createApi } from '@reduxjs/toolkit/query/react';
import isEqual from 'lodash.isequal';
import { networkBaseQuery } from './queries';

export const hiresApi = createApi({
  reducerPath: 'hiresApi',
  baseQuery: networkBaseQuery,
  tagTypes: ['hires'],
  endpoints: (builder) => ({
    getHires: builder.query({
      query: ({ page = 1, perPage = 20 }) => ({
        url: '/contacts/hires',
        params: { page, perPage },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
      merge: (currentCache, incomingState) => {
        currentCache.items.push(...incomingState.items);
      },
    }),
    updateHire: builder.mutation({
      query: ({ hire, data }) => ({ url: `/contacts/hires/${hire.id}`, method: 'patch', data }),
      async onQueryStarted({ hire, data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          hiresApi.util.updateQueryData('getHires', undefined, ({ items }) => {
            const currentHire = items.find((item) => item.id === hire.id);

            if (currentHire) {
              currentHire.teamInvolvement = data.teamInvolvement;
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteHire: builder.mutation({
      query: (hire) => ({ url: `/contacts/hires/${hire.id}`, method: 'delete' }),
      async onQueryStarted(hire, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          hiresApi.util.updateQueryData('getHires', undefined, ({ items }) => {
            const index = items.findIndex((item) => item.id === hire.id);

            if (index !== -1) {
              items.splice(index, 1);
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    downloadHires: builder.mutation({
      query: () => ({ url: '/contacts/hires/export', method: 'post' }),
    }),
  }),
});

export const { useGetHiresQuery, useDeleteHireMutation, useUpdateHireMutation, useDownloadHiresMutation } = hiresApi;
