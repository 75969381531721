import React from 'react';
import { Box, Flex, Image, Link, Text } from 'rebass/styled-components';

import IconMagicWand from '../../assets/icon-magic-wand.svg';
import BackgroundSocialMedia from '../../assets/background-getro-socialmedia.png';

export const BannerTalentNetworkLive = ({ ...rest }) => (
  <Flex
    alignItems="center"
    p="16px"
    sx={{
      borderRadius: 'default',
      boxShadow: 'xs',
      backgroundImage: `url(${BackgroundSocialMedia})`,
      backgroundPosition: '350% 48%',
      backgroundSize: '90% 300%',
      backgroundRepeat: 'no-repeat',
      gap: '16px',
    }}
    {...rest}
  >
    <Image
      src={IconMagicWand}
      alt="hires pending review"
      display={['none', 'block']}
      width="64px"
      height="64px"
      flex="0 0 auto"
    />
    <Box flexGrow="1">
      <Text color="text.dark" fontSize="18px" fontWeight="semibold">
        Want to go beyond contact search?
      </Text>
      <Text mt="8px" fontSize="14px" fontWeight="medium">
        GetroConnect automatically matches your talent signups (and other contacts) to open jobs.
      </Text>
    </Box>
    <Link
      href="https://talkto.getro.com/request-demo-calendar?utm_medium=banner&utm_source=adminportal&utm_campaign=unlockcontacts"
      target="_blank"
      rel="noopener noreferrer"
      flex="0 0 auto"
      sx={{
        textDecoration: 'none',
        bg: 'primary',
        px: '16px',
        py: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 'medium',
        color: 'white',
        borderRadius: 'default',
      }}
    >
      Talk to sales
    </Link>
  </Flex>
);
