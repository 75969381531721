import React, { Component, Children } from 'react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import PageLayoutContent from './PageLayoutContent';
import PageLayoutSidebar from './PageLayoutSidebar';
import { Title } from './title';

export default class PageLayout extends Component {
  static Content = PageLayoutContent;

  static Sidebar = PageLayoutSidebar;

  render() {
    // eslint-disable-next-line react/prop-types
    const { mainAction, mainActionText, children, titleProps, breadcrumbs, ...rest } = this.props;

    return (
      <>
        <Title
          mainAction={mainAction}
          mainActionText={mainActionText}
          titleProps={titleProps}
          breadcrumbs={breadcrumbs}
        />
        <Box sx={{ display: ['undef', 'undef', 'flex'], flex: ['undef', 'undef', 1] }} {...rest}>
          {Children.map(children, (child) => child)}
        </Box>
      </>
    );
  }
}

PageLayout.defaultProps = {
  breadcrumbs: [],
  mainAction: null,
  mainActionText: null,
  titleProps: {},
};

PageLayout.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ),
  children: PropTypes.node.isRequired,
  mainAction: PropTypes.func,
  mainActionText: PropTypes.node,
  titleProps: PropTypes.object,
};
