/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Virtuoso } from 'react-virtuoso';

import { Redirect, useParams, Switch, Route, useHistory, useRouteMatch } from 'react-router';
import { activeNetworkSelector, hasTalentNetworkV2Selector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import usePageHook from 'hooks/usePageHook';
import { ContactFooter } from 'components/atoms/ContactsFooter';
import { ContactsNoResults } from 'components/atoms/ContactsNoResult';
import { useContactsSearchParam } from 'pages/contactsExtended/hooks/useContactsSearchParam';
import { ContactDetail } from 'components/organisms/contactDetail';
import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import MainLayout from '../../components/layout/Main';
import { ContactCard } from '../../components/organisms/contactCard';
import { useGetContactsQuery } from '../../services/contactsV2';
import { MainSkeleton, TableSkeleton } from './skeletons';
import { TableActions } from './tableActions';
import { ContactsFilters } from '../../components/organisms/contactsFilters';
import { defaultFilters } from '../../components/organisms/contactsFilters/defaultFilters';
import { BulkActions } from './bulkActions';
import { ContactsEmptyState } from './emptyState';
import { TalentNetworkSettings } from './talentNetworkSettings';
import { BannerEnableTalentNetwork } from './bannerEnableTalentNetwork';
import { BannerTalentNetworkLive } from './bannerTalentNetworkLive';

export const ContactsBasic = () => {
  const [queryParams, setQueryParams] = useContactsSearchParam({ page: 1, filters: defaultFilters });
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtersCount, setFiltersCount] = useState(0);
  const { data, isLoading, isFetching } = useGetContactsQuery(queryParams);
  const hasConnectFreemium = useSelector(hasTalentNetworkV2Selector);
  usePageHook('Contacts');
  const { listId } = useParams();
  const [showAddNote, setShowAddNote] = useState(false);
  const history = useHistory();
  const route = useRouteMatch();
  const activeNetwork = useSelector(activeNetworkSelector);

  const onLoadMore = () => {
    const currentPage = Math.ceil(data.ids.length / 20);

    if (currentPage + 1 <= Math.ceil(data.meta.queryTotal / 20)) {
      setQueryParams({
        ...queryParams,
        page: currentPage + 1,
      });
    }
  };

  const onChangeSelectedContact = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((position) => position !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const onSetFilters = (newFilters) => {
    setQueryParams({ filters: newFilters, page: 1 });
    setFiltersCount(
      Object.keys(newFilters).reduce(
        (carry, idx) => (newFilters[idx].length || Object.keys(newFilters[idx]).length ? carry + 1 : carry),
        0,
      ),
    );
    setSelectedRows([]);
  };

  if (!hasConnectFreemium) {
    return <Redirect to="/" />;
  }

  return (
    <MainLayout
      containerProps={{
        pb: 0,
      }}
    >
      <MainLayoutHeader title="All contacts" />
      <Box mx="auto" px="16px">
        <TalentNetworkSettings />
        <BannerTalentNetworkLive my="24px" />
      </Box>
      <Flex flex="1" flexDirection="column" width="100%" maxWidth="1600px" mx="auto" pl={['16px', '16px', 0]} pr="16px">
        <Flex flexDirection={['column', 'column', 'row']}>
          <Box width={['100%', '100%', '300px']} mr="40px" flex="0 0 auto">
            <ContactsFilters filters={queryParams.filters} setFilters={onSetFilters} showListFilters={false} />
          </Box>
          <Box flexGrow="1">
            {isLoading && <MainSkeleton />}
            {!isLoading && (filtersCount > 0 || data?.ids?.length > 0) ? (
              <>
                <BannerEnableTalentNetwork />
                <Flex alignItems="center" justifyContent="space-between" py="16px" mt="8px">
                  <Text fontSize="0">
                    {`Showing ${
                      !isLoading && isFetching && queryParams.page === 1 ? '-' : data.meta?.queryTotal
                    } contacts`}
                  </Text>
                </Flex>
                {!isLoading && isFetching && queryParams.page === 1 ? (
                  <Box mt="80px">
                    <TableSkeleton />
                  </Box>
                ) : (
                  <>
                    <TableActions
                      contacts={data.ids}
                      queryTotal={data.meta?.queryTotal}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />
                    <Flex
                      as={Virtuoso}
                      flex="1"
                      data={data.ids}
                      increaseViewportBy={400}
                      useWindowScroll
                      itemContent={(index, id) => (
                        <ContactCard
                          index={index}
                          type="read"
                          contact={data.entities[id]}
                          isSelected={selectedRows.includes(index)}
                          onChangeSelected={() => onChangeSelectedContact(index)}
                          showDetail={() => {
                            history.push(`${route.path}/${id}${history.location.search}`);
                          }}
                        />
                      )}
                      endReached={onLoadMore}
                      components={{
                        Footer: () => (
                          <ContactFooter
                            isLoading={isFetching}
                            allContactsCount={data.ids.length}
                            contactsTotal={data.meta?.queryTotal ?? 0}
                            filters={queryParams.filters}
                            isList={Boolean(listId)}
                          />
                        ),
                      }}
                    />
                    <BulkActions
                      contactIds={data.ids}
                      queryTotal={data.meta?.queryTotal}
                      selectedRows={selectedRows}
                      filters={queryParams.filters}
                    />
                  </>
                )}
              </>
            ) : null}
            {!isLoading && !data?.ids?.length && filtersCount === 0 && <ContactsEmptyState />}
            {!isLoading && !data?.ids?.length && filtersCount > 0 && <ContactsNoResults />}
          </Box>
        </Flex>
      </Flex>
      <Switch>
        <Route path="/networks/:networkSlug/contacts/:contactId">
          <ContactDetail
            showAddNote={showAddNote}
            queryParams={queryParams}
            type="read"
            onClose={() => {
              history.push(`/networks/${activeNetwork.slug}/contacts${history.location.search}`);
              setShowAddNote(false);
            }}
          />
        </Route>
      </Switch>
    </MainLayout>
  );
};
