import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { FormSelect, Message, MessageBlock } from '@getro/rombo';
import { Field } from 'formik';
import { FORCE_VISIBILITY_OPTIONS } from './constants';

const ForceJobVisibility = ({ title, description, error, succeed, onClose, ...props }) => (
  <Flex flexDirection="column">
    {!succeed && (
      <Box data-testid="force-visibility-selector">
        <Text color="dark" fontSize={1} my={3}>
          {description || 'Manually set the visibility of this job.'}
        </Text>
        <Text my={2} fontSize={2} color="text.dark" fontWeight="medium">
          Visibility
        </Text>
        <Field component={FormSelect} options={FORCE_VISIBILITY_OPTIONS} position="top" {...props} />
        {error && (
          <Message type="error" data-testid="force-visibility-error-message">
            Please try again or contact us at <a href="mailto:help@getro.com">help@getro.com</a>.
          </Message>
        )}
      </Box>
    )}
    {succeed && (
      <MessageBlock
        type="success"
        buttonText="Ok"
        onClick={onClose}
        title="Set job visibility"
        data-testid="force-visibility-success-message"
      >
        <Text fontSize="2" color="text.main">
          Visibility set successfully
        </Text>
      </MessageBlock>
    )}
  </Flex>
);

ForceJobVisibility.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.bool,
  succeed: PropTypes.bool,
  description: PropTypes.node,
};

ForceJobVisibility.defaultProps = {
  error: false,
  succeed: false,
  description: null,
};

export default ForceJobVisibility;
