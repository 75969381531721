import React, { useEffect, useState } from 'react';
import pageHoc from 'hocs/pageHoc';
import { Text, Flex, Box } from 'rebass/styled-components';
import { parseCookies, setCookie } from 'nookies';
import { InfoBox } from 'components/atoms/InfoBox';
import CompanyNotification from 'components/atoms/CompanyNotification';
import {
  activeNetworkSelector,
  hasContactsSelector,
  hasImpactAnalyticsEnabledSelector,
  hasJobAlertsEnabledSelector,
  userSelector,
} from 'redux/selectors';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import pluralize from 'pluralize';

import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import MainLayout from '../../components/layout/Main';
import { ContactsImportBanner } from '../../components/molecules/contactsImportBanner';
import { ContactsUpgradeBanner } from '../../components/molecules/contactsUpgradeBanner';
import compose from '../../helpers/compose';
import { CalenderDatePicker } from './components/DatePicker';
import { useAnalytics } from './hooks/useAnalytics';
import { useBannerActions } from './hooks/useBannerActions';
import { useDownloadApplicants } from './hooks/useDownloadApplicants';
import { MatchesIntroModal } from './matchesIntroModal';

const pageTitle = 'Home';

const Impact = () => {
  const { loadingState, ...analytics } = useAnalytics();
  const banner = useBannerActions();
  const download = useDownloadApplicants();
  const [showMatchesIntroModal, setShowMatchesIntroModal] = useState(false);
  const hasJobAlerts = useSelector(hasJobAlertsEnabledSelector);
  const isImpactAnalyticsEnabled = useSelector(hasImpactAnalyticsEnabledSelector);
  const user = useSelector(userSelector);
  const hasContacts = useSelector(hasContactsSelector);
  const activeNetwork = useSelector(activeNetworkSelector);

  useEffect(() => {
    const cookies = parseCookies();

    if (
      user &&
      activeNetwork.connectTier &&
      activeNetwork.contactsCount === 0 &&
      !cookies[`${user.id}-matches-intro-modal`]
    ) {
      setCookie(null, `${user.id}-matches-intro-modal`, 'true', {
        maxAge: 365 * 24 * 60 * 60,
      });
      setShowMatchesIntroModal(true);
    }
  }, [activeNetwork, user]);

  if (!isImpactAnalyticsEnabled) {
    return <Redirect to="/" />;
  }

  const desktopWidth = hasJobAlerts ? '1/3' : '1/2';
  const { contactsImportInProgress = false, matchesBeingGenerated = false, connectTier } = activeNetwork;
  const showContactsOrConnectBanner =
    contactsImportInProgress || matchesBeingGenerated || hasContacts || Boolean(connectTier);
  return (
    <MainLayout>
      <MainLayoutHeader title={pageTitle} />
      <Flex flexDirection="column" mt="56px" mx="auto" px="16px" width="100%" maxWidth="800px" sx={{ gap: '64px' }}>
        {showContactsOrConnectBanner && (
          <Box>
            {hasContacts && (
              <>
                <Text color="text.dark" fontSize="16px" fontWeight="500" lineHeight="20px" mb="16px">
                  How are we helping our companies?
                </Text>
                <Flex alignItems="strech" sx={{ gap: '16px', flexDirection: ['column', 'row'], mb: '16px' }}>
                  <InfoBox
                    width={['100%', desktopWidth]}
                    title="Shared lists"
                    count={analytics.sharedList.count}
                    loading={loadingState.sharedListsLoading || analytics.sharedList.error}
                    infoText={
                      <Text>
                        Number of lists having the shared view enabled, so companies can access them through the link.
                      </Text>
                    }
                    sx={{ height: 'auto' }}
                  />
                  <InfoBox
                    width={['100%', desktopWidth]}
                    title="Contact shares"
                    count={analytics.sharedListContacts.count}
                    loading={loadingState.sharedListContactsLoading || analytics.sharedListContacts.error}
                    infoText={
                      <Text>
                        Total number of contacts on shared lists, so companies can access them through the list links.
                      </Text>
                    }
                    sx={{ height: 'auto' }}
                  />
                  <InfoBox
                    title="Contacts"
                    width={['100%', desktopWidth]}
                    count={analytics.contacts.count}
                    loading={loadingState.contactsLoading || analytics.contacts.error}
                    infoText={
                      <Box>
                        <Text mb="8px">
                          Current number of Contacts, including the ones added and talent network signups.
                        </Text>
                        <Text>
                          Make sure all your team’s contacts are added in order to uncover more great introductions
                        </Text>
                      </Box>
                    }
                    sx={{ height: 'auto' }}
                  />
                </Flex>
              </>
            )}
            <Flex sx={{ gap: '16px' }} flexDirection="column">
              <ContactsUpgradeBanner id="admin_portal_home" network={activeNetwork} />
              <ContactsImportBanner network={activeNetwork} />
            </Flex>
          </Box>
        )}
        <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
          <Flex flexWrap="wrap" sx={{ gap: '24px' }} justifyContent="space-between">
            <Text color="text.dark" fontSize="16px" fontWeight="500" lineHeight="20px">
              How is the job board helping our companies?
            </Text>
            <CalenderDatePicker useRangeController setStartDate={analytics.setStartDate} dates={analytics.dates} />
          </Flex>
          <Flex sx={{ gap: '16px', flexDirection: ['column', 'row'] }}>
            <InfoBox
              infoText={
                <Box>
                  <Text mb="8px">
                    The total number of individual page visits on your job board within the selected timeframe.{' '}
                  </Text>
                  <Text>
                    This counts every page load, so a single user can contribute to multiple page visits if they view
                    more than one page.{' '}
                  </Text>
                </Box>
              }
              width={['100%', desktopWidth]}
              height="100%"
              title="Page visits"
              count={analytics.uniqueVisitors.count}
              loading={loadingState.uniqueVisitorsLoading || analytics.uniqueVisitors.error}
            />
            <InfoBox
              infoText="Total number of times users clicked on a job to view it (either on the job board or the original post)."
              width={['100%', desktopWidth]}
              title="Clicks on jobs"
              count={analytics.jobClicks.count}
              loading={loadingState.jobClicksLoading || analytics.jobClicks.error}
            />
            {hasJobAlerts && (
              <InfoBox
                infoText="Number of users who subscribed to job alerts."
                title="New job alerts subscribers"
                width={['100%', desktopWidth]}
                count={analytics.jobAlert.count}
                loading={loadingState.jobAlertLoading || analytics.jobAlert.error}
              />
            )}
          </Flex>
          <Flex sx={{ flexDirection: ['column', 'row'], gap: '16px' }}>
            <InfoBox
              width={['100%', desktopWidth]}
              title="Likely applied"
              sx={{ height: 'inherit' }}
              count={analytics.jobsLikelyApplied.count}
              loading={loadingState.jobsLikelyAppliedLoading || analytics.jobsLikelyApplied.error}
              actionOnClick={analytics.jobsLikelyApplied.count > 0 ? analytics.onDownloadLikelyApplied : null}
              actionText="Download"
              actionLoading={loadingState.downloadLikelyAppliedLoading}
              infoText={
                <Box>
                  <Text mb="8px">
                    Total number of times users clicked on the ‘apply’ button after viewing a job description on your
                    job board. Because they have read the job description, these actions indicate a strong potential for
                    application submission.
                  </Text>
                  <Text>
                    This applies to jobs with descriptions displayed on your board (all posted jobs and the majority of
                    extracted jobs).
                  </Text>
                </Box>
              }
            />
            <InfoBox
              width={['100%', desktopWidth]}
              title="Applications"
              sx={{ height: 'inherit' }}
              count={analytics.jobsApplicants.count}
              loading={loadingState.jobsApplicantsLoading || analytics.jobsApplicants.error}
              actionOnClick={analytics.jobsApplicants.count > 0 ? download.downloadApplicants : null}
              actionLoading={download.loading}
              actionText="Download"
              infoText={
                <Box>
                  <Text mb="8px">Total number of applications through your job board.</Text>
                  <Text>This only applies to posted jobs using the on job board application method.</Text>
                </Box>
              }
            />
          </Flex>
        </Flex>
        <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
          <Text color="text.dark" fontSize="16px" fontWeight="500" lineHeight="20px">
            Network overview
          </Text>
          <Flex sx={{ gap: '16px', flexDirection: ['column', 'row'] }}>
            <InfoBox
              width={['100%', desktopWidth]}
              title="Visible companies"
              count={analytics.networkCounts.companiesCount}
              loading={loadingState.networkCountsLoading || analytics.networkCounts.error}
            />
            <InfoBox
              width={['100%', desktopWidth]}
              title="Visible jobs"
              count={analytics.networkCounts.jobsCount}
              loading={loadingState.networkCountsLoading || analytics.networkCounts.error}
            />
            {hasJobAlerts && (
              <InfoBox
                width={['100%', desktopWidth]}
                title="Job alerts subscribers"
                count={analytics.allJobAlert.count}
                loading={loadingState.allJobAlertLoading}
              />
            )}
          </Flex>
        </Flex>
        {banner.showBanner && banner.suggestedCompaniesMeta.total > 0 && (
          <CompanyNotification
            onClick={banner.onClickBanner}
            onClose={banner.onCloseBanner}
            show={banner.showBanner}
            title={`${banner.suggestedCompaniesMeta.total} new portfolio ${pluralize(
              'company',
              banner.suggestedCompaniesMeta.total,
            )} ${pluralize('is', banner.suggestedCompaniesMeta.total)} ready to be added`}
            description="Add your last investments to your job board to support them hiring."
            buttonText="See suggested companies"
            sx={{
              mt: '40px',
              button: {
                minWidth: '220px',
              },
            }}
          />
        )}
      </Flex>
      {showMatchesIntroModal && <MatchesIntroModal onCancel={() => setShowMatchesIntroModal(false)} />}
    </MainLayout>
  );
};

export default compose(pageHoc({ title: pageTitle }))(Impact);
