import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Message, Select } from '@getro/rombo';
import { useField } from 'formik';

const OPTIONS = [
  { label: 'Featured', value: 'featured' },
  { label: 'Not featured', value: 'not_featured' },
];

const FeatureJob = ({ title, error, onClose, ...props }) => {
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;

  const selectedOption = useMemo(() => OPTIONS.find((option) => option.value === value), [value]);

  return (
    <Flex flexDirection="column">
      <Box>
        <Flex mb={1} fontSize={2} color="text.main" width={1}>
          <Text as="b" fontWeight="semibold">
            Featured status
          </Text>
        </Flex>
        <Text color="text.subtle" fontSize={1} mb={2}>
          Get more attention by highlighting this job at the top of your job board.
        </Text>
        <Select
          {...props}
          options={OPTIONS}
          value={selectedOption}
          onChange={({ value: selectedValue }) => setValue(selectedValue)}
          anchorToBody
        />
        {error && (
          <Message type="error" data-testid="feature-job-error-message">
            Please try again or contact us at <a href="mailto:help@getro.com">help@getro.com</a>.
          </Message>
        )}
      </Box>
    </Flex>
  );
};

FeatureJob.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

FeatureJob.defaultProps = {
  error: false,
};

export default FeatureJob;
