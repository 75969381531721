import { useSearchParams } from 'hooks/useSearchParams';
import { useLazyGetJobsQuery } from 'services/connectJobs';
import { useLazyGetMatchListQuery } from 'services/matches';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import { useEffect, useRef } from 'react';

export const useJobForList = () => {
  const [{ keywords, page }, setSearchQuery] = useSearchParams();
  const [getJobs, { data: jobData, isFetching: isFetchingJobs, error: jobError }] = useLazyGetJobsQuery();

  const [
    getSuggestedMatches,
    { data: matchSuggestionsList, isLoading: isLoadingMatchSuggestionsList, isFetching: isFetchingSuggestionsList },
  ] = useLazyGetMatchListQuery({
    skip: Boolean(keywords?.length),
  });

  useEffect(() => {
    if (!keywords) {
      getSuggestedMatches({
        params: {
          page: 1,
          suggested: true,
        },
        provideTags: ['suggested-matches'],
      });
    }
  }, [getSuggestedMatches, keywords]);

  useDebouncedEffect(
    () => {
      if (keywords?.length) {
        getJobs({ keywords });
      }
    },
    599,
    keywords,
  );

  const normalizedJobData = jobData
    ? jobData.map((job) => ({
        organization: job.organization,
        job: { id: job.id, title: job.title },
        matches: job.contacts || [],
        matchList: { id: null, status: job.status || 'none' },
      }))
    : [];
  const normalizedSuggestionsData = matchSuggestionsList?.items
    ? matchSuggestionsList.items.map((suggestion) => ({
        organization: suggestion.organization,
        job: suggestion.job,
        matches: suggestion.matches.contacts,
        matchList: { id: suggestion.id, status: suggestion.status },
      }))
    : [];

  const fetchInitialJobsRef = useRef(false);
  useEffect(() => {
    if (fetchInitialJobsRef.current) return;

    if (!isFetchingSuggestionsList && !isLoadingMatchSuggestionsList && normalizedSuggestionsData?.length === 0) {
      getJobs({
        page: 1,
      });
      fetchInitialJobsRef.current = true;
    }
  }, [getJobs, isFetchingSuggestionsList, isLoadingMatchSuggestionsList, normalizedSuggestionsData?.length]);

  const hasMoreSuggestedMatches =
    Object.keys(matchSuggestionsList?.meta?.totalMatchLists || {}).length >= matchSuggestionsList?.items?.length;

  return {
    isLoadingMatchSuggestionsList,
    matchSuggestionsList,
    isLoadingJobs: isFetchingJobs,
    jobs: jobData,
    search: (value) => setSearchQuery((values) => (value ? { ...values, keywords: value } : {})),
    searchResults: keywords || !normalizedSuggestionsData.length ? normalizedJobData : normalizedSuggestionsData,
    showEmptyState: jobData?.length === 0 && normalizedSuggestionsData?.length === 0,
    error: jobError,
    keywords,
    isLoadingMoreMatches: Boolean(matchSuggestionsList?.items?.length) && isFetchingSuggestionsList,
    hasMoreMatches: keywords ? false : hasMoreSuggestedMatches,
    total: Object.keys(matchSuggestionsList?.meta?.totalMatchLists || {}).length,
    loadMore: () => {
      if (hasMoreSuggestedMatches) {
        const currentPage = parseInt(page || 1, 10) + 1;
        setSearchQuery((values) => ({ ...values, page: currentPage }));
        getSuggestedMatches({
          params: {
            page: currentPage,
            suggested: true,
          },
          provideTags: ['suggested-matches'],
        });
      }
    },
  };
};
