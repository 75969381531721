import { createApi } from '@reduxjs/toolkit/query/react';

import { networkBaseQueryV2 } from '../queries';

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: networkBaseQueryV2,
  endpoints: (builder) => ({
    jobsWithMatches: builder.query({
      query: () => ({
        url: '/analytics/jobs_with_matches',
      }),
      transformResponse: (response) => ({
        count: response.total,
      }),
    }),
    matchesShared: builder.query({
      query: () => ({
        url: '/analytics/matches_shared',
      }),
      transformResponse: (response) => ({
        count: response.total,
      }),
    }),
    contacts: builder.query({
      query: () => ({
        url: '/analytics/contacts',
      }),
      transformResponse: (response) => ({
        count: response.total,
      }),
      invalidatesTags: ['analytics-contacts'],
    }),
    sharedList: builder.query({
      query: () => ({
        url: '/analytics/shared_lists',
      }),
      transformResponse: (response) => ({
        count: response.total,
      }),
      invalidatesTags: ['analytics-shared-list'],
    }),
    sharedListContacts: builder.query({
      query: () => ({
        url: '/analytics/shared_lists_contacts',
      }),
      transformResponse: (response) => ({
        count: response.total,
      }),
      invalidatesTags: ['analytics-shared-list-contacts'],
    }),
  }),
});

export const {
  useLazyJobsWithMatchesQuery,
  useLazyContactsQuery,
  useLazyMatchesSharedQuery,
  useLazySharedListQuery,
  useLazySharedListContactsQuery,
} = analyticsApi;
