import { trackException } from 'helpers/errorTracker';
import { get } from './index';
import APIError from './APIError';

class SkillsAPI {
  static async searchByName(q) {
    return SkillsAPI.search({ q });
  }

  static async searchByIds(ids) {
    return SkillsAPI.search({ ids });
  }

  static async search(params) {
    let result = [];

    try {
      const response = await get('/skills', {
        params,
      });

      const { data } = response;
      const { items } = data;
      result = items.map((skill) => {
        const { id: value, name: label } = skill;
        return { value, label };
      });
    } catch (e) {
      trackException(e);
      throw new APIError(e.message);
    }

    return result;
  }
}

export default SkillsAPI;
