import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../hooks/useInjectSaga';
import saga from './saga';
import { loadAnalytics } from '../../helpers/analytics';

export const AnalyticsTracking = ({ children }) => {
  useInjectSaga({ key: 'analyticsTracking', saga });

  useEffect(() => {
    loadAnalytics();
  }, []);

  return <>{children}</>;
};

AnalyticsTracking.propTypes = {
  children: PropTypes.node.isRequired,
};
