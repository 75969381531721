import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  loadedSaveInvitedMemberInfo,
  navigateBackInvitedMemberVouch,
  loadSaveInvitedMemberInvite,
  loadedSaveInvitedMemberInvite,
  errorSaveInvitedMemberInvite,
  loadSaveInvitedMemberVouch,
  loadedSaveInvitedMemberVouch,
  errorSaveInvitedMemberVouch,
  dismissSuccessInviteMember,
} from './actions';

// The initial state of the Reducer
export const initialState = {
  info: null,
  vouch: {
    relationshipLevel: undefined,
    relationshipText: '',
  },
  invitedUser: null,
  showVouch: false,
  showSuccess: false,
  error: false,
};

/* eslint-disable default-case, no-param-reassign */
const memberInviteReducer = handleActions(
  {
    [loadedSaveInvitedMemberInfo]: produce((draft, action) => {
      draft.info = action.payload;
      draft.showVouch = true;
      return draft;
    }),
    [navigateBackInvitedMemberVouch]: produce((draft, action) => {
      draft.vouch = action.payload;
      draft.showVouch = false;
      return draft;
    }),
    [loadSaveInvitedMemberInvite]: produce((draft) => {
      draft.error = false;
      return draft;
    }),
    [loadedSaveInvitedMemberInvite]: produce((draft, action) => {
      draft.invitedUser = action.payload;
      draft.showSuccess = true;
      return draft;
    }),
    [errorSaveInvitedMemberInvite]: produce((draft) => {
      draft.error = true;
      return draft;
    }),
    [loadSaveInvitedMemberVouch]: produce((draft, action) => {
      const { payload } = action;
      const { values } = payload;
      draft.vouch = values;
      draft.error = false;
      return draft;
    }),
    [loadedSaveInvitedMemberVouch]: produce((draft, action) => {
      draft.invitedUser = action.payload;
      draft.vouch = initialState.vouch;
      draft.showSuccess = true;
      return draft;
    }),
    [errorSaveInvitedMemberVouch]: produce((draft) => {
      draft.error = true;
      return draft;
    }),
    [dismissSuccessInviteMember]: produce((draft) => {
      draft = { ...initialState };
      return draft;
    }),
  },
  initialState,
);

export default memberInviteReducer;
