import { Users } from 'lucide-react';
import Members from '../pages/Members';

const memberRoutes = {
  path: '/members',
  component: Members,
  name: 'Members',
  icon: Users,
  exact: true,
};

export default memberRoutes;
