import React from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'styled-components';

export const JobDetailSkeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={280}
      height={52}
      viewBox="0 0 280 52"
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
    >
      <rect x="0" y="0" rx="8" ry="8" width="200" height="12" />
      <rect x="0" y="40" rx="8" ry="8" width="120" height="12" />
      <rect x="0" y="20" rx="8" ry="8" width="200" height="12" />
    </ContentLoader>
  );
};
