import React, { useMemo } from 'react';
import { FormSelect } from '@getro/rombo';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

import { ReduxMultiFormSelect } from './ReduxMultiFormSelect';

export const CompanyDetailsField = ({ name, value, type, hook, ...rest }) => {
  const { options, search, isFetching } = hook();

  const onInputChange = useMemo(() => {
    const onSearchChange = (currentValue) => {
      search(currentValue);
    };

    return debounce(300, onSearchChange);
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  return type === 'search' ? (
    <Field
      component={ReduxMultiFormSelect}
      name={name}
      onInputChange={onInputChange}
      loading={isFetching}
      options={options}
      isMulti
      {...rest}
    />
  ) : (
    <Field component={FormSelect} name={name} options={options} {...rest} />
  );
};

CompanyDetailsField.propTypes = {
  name: PropTypes.string.isRequired,
  hook: PropTypes.func.isRequired,
  value: PropTypes.object,
  type: PropTypes.oneOf(['select', 'search']),
};

CompanyDetailsField.defaultProps = {
  value: undefined,
  type: 'select',
};
