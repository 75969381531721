import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, AsyncButton, Message } from '@getro/rombo';
import { Formik, Form } from 'formik';
import { Flex } from 'rebass/styled-components';
import { Text } from 'rebass';
import pluralize from 'pluralize';
import ForceJobVisibility from '../forceJobVisibility';
import { usePrevious } from '../../../hooks/usePrevious';
import { FORCE_VISIBILITY_OPTIONS } from '../forceJobVisibility/constants';

export const ForceJobVisibilityModal = ({
  updatableJobsIdsCount,
  selectedJobsCount,
  visibilityValue,
  isOpen,
  title,
  onClose,
  onSubmit,
  isSubmitting,
  error,
  selectAllJobs,
}) => {
  const ref = useRef();
  const [succeed, setSucceed] = useState(false);
  const previousSubmitting = usePrevious(isSubmitting);
  const wasSubmitting = useMemo(() => previousSubmitting && isOpen, [previousSubmitting, isOpen]);
  const modalTitle = useMemo(() => (succeed ? null : title), [succeed, title]);
  const modalDesc = useMemo(() => {
    if (selectAllJobs) {
      return (
        <Text>
          Set a new visibility for{' '}
          <Text as="b" fontWeight="semibold">
            multiple jobs
          </Text>
        </Text>
      );
    }

    if (updatableJobsIdsCount === selectedJobsCount) {
      return (
        <Text>
          Set a new visibility for {updatableJobsIdsCount} {pluralize('job', updatableJobsIdsCount)}.
        </Text>
      );
    }
    return (
      <Text>
        Set a new visibility for{' '}
        <Text as="b" fontWeight="semibold">
          {updatableJobsIdsCount} (out of {selectedJobsCount} selected)
          {pluralize('job', updatableJobsIdsCount)}
        </Text>
        .
      </Text>
    );
  }, [selectAllJobs, updatableJobsIdsCount, selectedJobsCount]);

  const handleClose = useCallback(() => {
    setSucceed(false);
    onClose();
  }, [onClose]);

  const handleSubmit = ({ jobVisibility }) => {
    onSubmit(jobVisibility.value);
  };

  const initialJobVisibility = useMemo(() => {
    switch (visibilityValue) {
      case 'visible':
      case 'visible_forced':
        return 'visible_forced';
      case 'hidden':
      case 'hidden_forced':
        return 'hidden_forced';
      default:
        return 'hidden_forced';
    }
  }, [visibilityValue]);

  const initialValues = {
    jobVisibility: Object.values(FORCE_VISIBILITY_OPTIONS).find((opts) => opts.value === initialJobVisibility),
  };

  const modalActions = useMemo(() => {
    if (succeed) {
      return null;
    }

    return (
      <Flex justifyContent="flex-end" alignItems="baseLine" data-testid="interactive-renew-action-buttons">
        <Button onClick={handleClose} variant="secondary" mr={1}>
          Cancel
        </Button>
        <AsyncButton
          disabled={updatableJobsIdsCount === 0}
          onClick={() => ref.current?.handleSubmit()}
          loading={isSubmitting}
        >
          Set visibility
        </AsyncButton>
      </Flex>
    );
  }, [succeed, handleClose, updatableJobsIdsCount, isSubmitting]);

  useEffect(() => {
    if (wasSubmitting && !isSubmitting) {
      setSucceed(!error);
    }
  }, [isSubmitting, wasSubmitting, error]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      data-testid="interactive-renew-modal"
      onCancel={handleClose}
      isOpen={isOpen}
      title={modalTitle}
      actions={modalActions}
    >
      {!succeed && selectAllJobs && (
        <Message type="info" mr={[1]}>
          Visibility of expired and closed jobs can’t be updated, this action may not take place in every selected job.
        </Message>
      )}

      {!succeed && updatableJobsIdsCount !== selectedJobsCount && !selectAllJobs && (
        <Message type="info" mr={[1]}>
          Visibility of expired and closed jobs can’t be updated, this action will take place in {updatableJobsIdsCount}{' '}
          of the {selectedJobsCount} selected jobs.
        </Message>
      )}

      <Formik initialValues={initialValues} innerRef={ref} onSubmit={handleSubmit}>
        <Form>
          <ForceJobVisibility
            id="jobVisibility"
            name="jobVisibility"
            title={title}
            error={error}
            description={modalDesc}
            succeed={succeed}
            onClose={handleClose}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

ForceJobVisibilityModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  visibilityValue: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  updatableJobsIdsCount: PropTypes.number,
  selectedJobsCount: PropTypes.number,
  error: PropTypes.bool.isRequired,
  selectAllJobs: PropTypes.bool,
};

ForceJobVisibilityModal.defaultProps = {
  isOpen: false,
  title: '',
  visibilityValue: null,
  isSubmitting: false,
  updatableJobsIdsCount: null,
  selectedJobsCount: null,
  selectAllJobs: false,
};

export default ForceJobVisibilityModal;
