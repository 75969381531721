import TitleHeader from './titleHeader';

export const getJobsColumns = () => [
  {
    Header: TitleHeader,
    accessor: 'title',
    width: '22%',
    disableSortBy: true,
  },
  {
    Header: 'Location',
    accessor: 'location',
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Source',
    accessor: 'source',
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Visibility',
    accessor: 'visibility',
    width: '14%',
    disableSortBy: true,
  },
  {
    Header: 'Featured',
    accessor: 'featured',
    width: '10%',
    disableSortBy: true,
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    width: '12%',
  },
  {
    Header: 'Applicants',
    accessor: 'applicants',
    disableSortBy: true,
    width: '10%',
  },
  {
    Header: 'Expiring',
    accessor: 'expirationDate',
    width: '12%',
  },
  {
    Header: 'Ai matches',
    accessor: 'matches',
    width: '15%',
  },
];
