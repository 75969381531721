import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormLabel, Select } from '@getro/rombo';
import { Box } from 'rebass/styled-components';

const STAGE_OPTIONS = [
  { label: 'Pre-seed', value: 'pre_seed' },
  { label: 'Seed', value: 'seed' },
  { label: 'Series A', value: 'series_a' },
  { label: 'Series B', value: 'series_b' },
  { label: 'Series C+', value: 'series_c_plus' },
  { label: 'Private Equity', value: 'private_equity' },
  { label: 'IPO', value: 'ipo' },
  { label: 'Other', value: 'other' },
];

const Stage = ({ value, setValue }) => {
  const selectedOption = useMemo(() => STAGE_OPTIONS.find((i) => i.value === value) ?? null, [value]);

  return (
    <Box>
      <FormLabel htmlFor="Stage" data-testid="Stage">
        Stage
      </FormLabel>
      <Select
        name="Stage"
        placeholder="Add Stage"
        value={selectedOption}
        options={STAGE_OPTIONS}
        onChange={({ value: newValue }) => setValue(newValue)}
      />
    </Box>
  );
};

Stage.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default Stage;
