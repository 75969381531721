import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { AsyncButton, Button, Message, Modal } from '@getro/rombo';
import strings from '../../../strings';

export const CloseJobModal = ({ error, onSubmit, onCancel, isOpen, isSubmitting }) => {
  const modalActions = useMemo(
    () => (
      <Flex justifyContent="flex-end" alignItems="baseLine" data-testid="interactive-renew-action-buttons">
        <Button onClick={onCancel} variant="secondary" mr={1}>
          Cancel
        </Button>
        <AsyncButton onClick={onSubmit} loading={isSubmitting}>
          Close Job
        </AsyncButton>
      </Flex>
    ),
    [isSubmitting, onCancel, onSubmit],
  );

  if (!isOpen) {
    return null;
  }
  return (
    <Modal actions={modalActions} data-testid="close-jobs-modal" onCancel={onCancel} isOpen={isOpen} title="Close job">
      <Text>
        Closed jobs are not visible on your job board, but are still available for you. This way you can re-post them
        and see how it performed in the analytics screen.
      </Text>
      {error && <Box as={Message} mt={[3]} type="error" data-testid="error-message" content={strings.genericError} />}
    </Modal>
  );
};
CloseJobModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  error: PropTypes.bool,
};
CloseJobModal.defaultProps = {
  isOpen: false,
  isSubmitting: false,
  error: false,
};
