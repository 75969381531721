import { useLazyGetIndustryQuery } from '../../../services/organizations';

const { useState } = require('react');

export const useOrganizationIndustries = () => {
  const [options, setOptions] = useState([]);
  const [getIndustry, { isFetching }] = useLazyGetIndustryQuery();

  const search = async (query) => {
    if (query) {
      const { data } = await getIndustry({
        q: query,
        crunchbase: true,
      });

      if (data?.data?.length) {
        setOptions(data.data.map((item) => ({ label: item.attributes.description, value: item.id })));
      }
    } else {
      setOptions([]);
    }
  };

  return { options, search, isFetching };
};
