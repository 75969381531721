import produce from 'immer';
import { handleActions } from 'redux-actions';
import { loadedSubscribeToBoards, loadSubscribeToBoards } from './actions';

export const initialState = {
  subscribed: false,
};

/* eslint-disable default-case, no-param-reassign */
const shareReducer = handleActions(
  {
    [loadSubscribeToBoards]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [loadedSubscribeToBoards]: produce((draft) => {
      draft.subscribed = true;
      return draft;
    }),
  },
  initialState,
);

export default shareReducer;
