import pluralize from 'pluralize';
import { Download } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import { loadExportCompanies } from './useActiveCompanies/actions';

export const useSecondaryActions = ({ isLoading, meta, filters }) => {
  const { isManager } = useSelector(activeNetworkSelector);
  const dispatch = useDispatch();
  let secondaryActions = [
    {
      text: isLoading ? 'Loading...' : `Showing ${meta.total.toLocaleString()} ${pluralize('company', meta.total)}`,
    },
  ];

  if (isManager) {
    secondaryActions = [
      ...secondaryActions,
      {
        action: () => dispatch(loadExportCompanies({ filters })),
        icon: Download,
        text: 'Download',
        popoverContent: 'Download search results in csv format',
      },
    ];
  }

  return secondaryActions;
};
