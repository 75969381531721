import { Button, Modal } from '@getro/rombo';
import React from 'react';
import { Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Filters } from './filters';

export const FiltersModal = ({
  sharedList,
  filters,
  setFilters,
  resetFilters,
  extendedMode,
  isTalentNetworkEnabled,
  showListFilters,
  onClose,
  clearFundFilters,
}) => (
  <Modal
    isOpen
    onCancel={onClose}
    variant="large"
    title="All filters"
    sx={{ width: '100vw', 'div[data-testid="overlay"]': { display: 'none' } }}
    pauseFocusTrap
    actions={
      <Flex flexDirection="row" justifyContent="flex-end">
        <Button
          mr={1}
          fontSize={1}
          variant="secondary"
          onClick={() => {
            resetFilters();
            onClose();
          }}
        >
          Clear filters
        </Button>
        <Button ml={1} fontSize={1} fontWeight="semibold" onClick={onClose}>
          View results
        </Button>
      </Flex>
    }
  >
    <Filters
      sharedList={sharedList}
      filters={filters}
      setFilters={setFilters}
      extendedMode={extendedMode}
      isTalentNetworkEnabled={isTalentNetworkEnabled}
      showListFilters={showListFilters}
      clearFundFilters={clearFundFilters}
    />
  </Modal>
);

FiltersModal.propTypes = {
  sharedList: PropTypes.object,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  extendedMode: PropTypes.bool,
  isTalentNetworkEnabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showListFilters: PropTypes.bool.isRequired,
  clearFundFilters: PropTypes.func.isRequired,
};

FiltersModal.defaultProps = {
  sharedList: null,
  extendedMode: false,
  isTalentNetworkEnabled: false,
};
