import React from 'react';
import ContentLoader from 'react-content-loader';
import { Box, Flex } from 'rebass/styled-components';
import { useTheme } from 'styled-components';

export const ContactCardSkeleton = () => {
  const { colors } = useTheme();

  return (
    <Flex flexDirection={['column', 'row']}>
      <Flex
        flexGrow="1"
        pr={[0, '16px']}
        mr={[0, '16px']}
        sx={{ borderRightWidth: [0, 1], borderRightStyle: 'solid', borderRightColor: 'neutral.50' }}
      >
        <ContentLoader
          speed={2}
          width={728}
          height={148}
          viewBox="0 0 728 148"
          backgroundColor={colors.neutral[50]}
          foregroundColor={colors.neutral[20]}
        >
          <rect x="96" y="8" rx="8" ry="8" width="200" height="16" />
          <rect x="96" y="40" rx="8" ry="8" width="460" height="12" />
          <circle cx="40" cy="40" r="40" />
          <rect x="96" y="60" rx="8" ry="8" width="360" height="12" />
          <rect x="96" y="94" rx="8" ry="8" width="240" height="12" />
          <rect x="96" y="114" rx="8" ry="8" width="200" height="12" />
          <rect x="96" y="134" rx="8" ry="8" width="160" height="12" />
        </ContentLoader>
      </Flex>
      <Box width="280px" height="100%" display={['none', 'flex']}>
        <ContentLoader
          speed={2}
          width={120}
          height={74}
          viewBox="0 0 120 74"
          backgroundColor={colors.neutral[50]}
          foregroundColor={colors.neutral[20]}
        >
          <rect x="0" y="8" rx="8" ry="8" width="120" height="12" />
          <rect x="0" y="34" rx="8" ry="8" width="120" height="12" />
          <rect x="0" y="62" rx="8" ry="8" width="120" height="12" />
        </ContentLoader>
      </Box>
    </Flex>
  );
};
