import React from 'react';
import PropTypes from 'prop-types';
import { FormSectionTitle, FormSelect, Checkbox, FormLabel, FormImage } from '@getro/rombo';
import { FastField, Field } from 'formik';
import { Box, Flex, Text } from 'rebass/styled-components';
import { heroBackgroundType } from './options';
import { JobHeroMaskOpacity } from './component/jobHeroMaskOpacity';
import { FormColourPicker } from './component/formColourPicker';

export const HeroBackgroundSettings = ({ values, setFieldValue }) => (
  <>
    <Flex flexDirection="column" sx={{ gap: '16px' }}>
      <FormSectionTitle p="0px" sx={{ borderBottom: '0px', pb: '16px', '& > div': { fontSize: '12px' } }}>
        Hero background
      </FormSectionTitle>
      <FastField
        value={values.heroBackgroundType}
        options={heroBackgroundType}
        component={FormSelect}
        name="heroBackgroundType"
        label="Background Type"
      />
      {values.heroBackgroundType.value === 'image' && (
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '32px',
            ...(!values.heroImageUrl
              ? {
                  '[data-testid="profile-picture"]': {
                    display: 'none',
                    borderRadius: '4px',
                    '& + div': {
                      ml: '0px',
                    },
                  },
                }
              : {
                  '[data-testid="profile-picture"]': {
                    '& > img': {
                      borderRadius: '4px',
                      overflow: 'hidden',
                      objectFit: 'cover!important',
                      backgroundPosition: 'center',
                    },
                  },
                }),
          }}
        >
          <Field
            width="200px"
            height="120px"
            label="Background image"
            filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
            name="heroImageUrl"
            component={FormImage}
            field={{ name: 'heroImageUrl', value: values.heroImageUrl }}
            helpText="Upload a 1600x900 JPG or PNG image."
            form={{
              setFieldValue,
              name: 'heroImageUrl',
            }}
          />
          {values.heroBackgroundType.value === 'image' && (
            <Box
              sx={{
                label: { height: 'fit-content', pointerEvents: 'none' },
                cursor: 'pointer',
                '*': { cursor: 'pointer' },
              }}
            >
              <Flex
                onClick={(e) => {
                  e.stopPropagation();
                  setFieldValue('imageMask', !values.imageMask);
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    e.stopPropagation();
                    setFieldValue('imageMask', !values.imageMask);
                  }}
                  checked={values.imageMask}
                  name="imageMask"
                />
                <FormLabel>Image mask</FormLabel>
              </Flex>
              <Box>
                <Text fontSize="14px" mb="8px" lineHeight="20px" color="text.main">
                  Use a mask over the image to improve text readability.
                </Text>
              </Box>
              {values.heroBackgroundType.value === 'image' && values.imageMask && (
                <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
                  <Field
                    name="heroColorMask"
                    component={FormColourPicker}
                    field={{ name: 'heroColorMask', value: values.heroColorMask }}
                    form={{
                      setFieldValue,
                      name: 'heroColorMask',
                    }}
                  />
                  <JobHeroMaskOpacity
                    value={values.heroMaskOpacity}
                    onChange={(value) => setFieldValue('heroMaskOpacity', value)}
                  />
                </Flex>
              )}
            </Box>
          )}
        </Flex>
      )}
      {values.heroBackgroundType.value === 'solid' && (
        <Field
          label="Background color"
          name="heroColorMask"
          component={FormColourPicker}
          field={{ name: 'heroColorMask', value: values.heroColorMask }}
          form={{
            setFieldValue,
            name: 'heroColorMask',
          }}
        />
      )}
    </Flex>
  </>
);

HeroBackgroundSettings.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
