import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Image, Text } from 'rebass/styled-components';
import IconCheck from '../../assets/icon-check.svg';

export const Feature = ({ children, ...otherProps }) => (
  <Flex alignItems="flex-start" {...otherProps}>
    <Image
      src={IconCheck}
      mr={[2]}
      mt={[2]}
      width="16px"
      height="16px"
      minWidth="16px"
      minHeight="16px"
      maxWidth="16px"
      maxHeight="16px"
    />
    <Text color="text.main" fontSize={[2]}>
      {children}
    </Text>
  </Flex>
);

Feature.propTypes = {
  children: PropTypes.node.isRequired,
};
