import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Input, Loader } from '@getro/rombo';
import { Plus } from 'lucide-react';
import { capitalize } from 'lodash';

const ActionsSearch = ({
  value,
  title,
  onSearch,
  options,
  isLoading,
  onSelect,
  onCreateIfNotExists,
  isCreating,
  newItemText,
  emptyText,
  showInputOnEmptyList,
}) => {
  const ref = useRef(null);
  const [initialOptionLength, setInitialOptionLength] = useState(options.length);

  const loader = (isLoading || isCreating) && (
    <Flex
      sx={{
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        '& > div': { width: '24px', height: '24px' },
      }}
    >
      <Loader />
    </Flex>
  );

  useEffect(() => {
    setInitialOptionLength((previous) => {
      if (!previous) {
        return options.length;
      }
      return previous;
    });
  }, [options]);

  useEffect(
    () => () => {
      onSearch('');
    },
    [onSearch],
  );

  const isEmpty = !value && !initialOptionLength;

  return (
    <Flex ref={ref} sx={{ flexDirection: 'column', gap: '8px', height: '100%' }}>
      {((ref.current && !isCreating) || (options.length && !isLoading && !isCreating)) && (
        <>
          <Box as="h4">{title}</Box>
          {(showInputOnEmptyList || (!showInputOnEmptyList && initialOptionLength > 0)) && (
            <Input
              autoFocus
              size="small"
              placeholder={isEmpty ? `${capitalize(newItemText)} name` : 'Search'}
              p="8px 12px"
              value={value}
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
        </>
      )}

      {!isLoading && !isCreating && (
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '6px',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              color: 'red',
              width: '3px',
            },
            '&::-webkit-scrollbar-track': {},
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10px',
              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            },
            '&::-webkit-scrollbar-bar': {
              width: '12px',
            },
            scrollbarWidth: '3px',
          }}
        >
          {options.map((item) => (
            <Flex
              key={item.label}
              sx={{
                height: '22px',
                alignItems: 'center',
              }}
              onClick={() => onSelect?.(item)}
            >
              <Box
                as="p"
                key={item.label}
                sx={{
                  color: 'text.main',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '14px',
                  ':first-letter': {
                    textTransform: 'capitalize',
                  },
                }}
              >
                {item.label}
              </Box>
            </Flex>
          ))}
          {onCreateIfNotExists && value && options.length > 0 && (
            <Box sx={{ height: '1px', backgroundColor: 'border.subtle' }} />
          )}
          {onCreateIfNotExists && value && (
            <Flex
              onClick={() => onCreateIfNotExists(value)}
              sx={{
                height: '22px',
                alignItems: 'center',
                gap: '8px',
                borderColor: 'border.subtle',
                borderTop: '1px solid',
              }}
            >
              <Box as={Plus} size="17px" sx={{ color: 'neutral.400' }} />
              <Box
                as="p"
                sx={{
                  color: 'text.main',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '14px',
                  lineHeight: '20px',
                  ':first-letter': {
                    textTransform: 'capitalize',
                  },
                }}
              >
                {value} new {newItemText}
              </Box>
            </Flex>
          )}
          {isEmpty && (
            <Flex
              sx={{
                mt: '24px',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                color: 'text.subtle',
                fontSize: '14px',
                lineHeight: '22px',
                fontStyle: 'italic',
              }}
            >
              {emptyText}
            </Flex>
          )}
          {!showInputOnEmptyList && value && !options.length && (
            <Flex
              sx={{
                mt: '24px',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                color: 'text.subtle',
                fontSize: '14px',
                lineHeight: '22px',
                fontStyle: 'italic',
              }}
            >
              No results
            </Flex>
          )}
        </Flex>
      )}
      {((!value && !ref.current) || isCreating || value) && loader}
    </Flex>
  );
};

ActionsSearch.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.objectOf({
      value: PropTypes.string.isRequired,
      label: PropTypes.any.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCreateIfNotExists: PropTypes.func,
  isCreating: PropTypes.bool,
  newItemText: PropTypes.string,
  showInputOnEmptyList: PropTypes.bool,
  emptyText: PropTypes.string,
};

ActionsSearch.defaultProps = {
  onCreateIfNotExists: null,
  isCreating: false,
  newItemText: '',
  emptyText: '',
  showInputOnEmptyList: true,
};

export default ActionsSearch;
