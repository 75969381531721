import { post } from 'api';
import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';
import APIError from './APIError';

class OrganizationsAPI {
  static async addAdmin(params) {
    let response = null;
    const { company, activeNetworkId, firstName, lastName, email, customMessage, isManager } = params;

    try {
      response = await post(`/organizations/${company.id}/poc?collection_id=${activeNetworkId}`, {
        firstName,
        lastName,
        email,
        customMessage,
        sendInvitation: true,
      });

      trackEvent('employer_profile:invite_manager', {
        organization_id: company.id,
        manager_id: response.data.id,
        is_network_admin: isManager,
      });
    } catch (error) {
      trackException(error);
      throw new APIError(error.message);
    }

    return response;
  }
}

export default OrganizationsAPI;
