import React, { useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Plus } from 'lucide-react';
import { Radio, Select, Tag, Button } from '@getro/rombo';
import PropTypes from 'prop-types';
import useOnClickOutside from 'hooks/useClickOutside';
import FundSelector from './FundSelector';
import { FilterTitle } from '../filterTitle';

const FundFilterGrowth = ({ onSelect, selected }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [stage, setStage] = useState('growth');
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const ref = useOnClickOutside(() => setShowDropDown(false));

  const addFilter = () => {
    const newFilter = {
      id: selected.length + 1,
      key: JSON.stringify({ stage, from, to }),
      stage,
      from,
      to,
    };
    const hasFilter = selected.some((item) => item.key === newFilter.key);

    if (hasFilter) {
      setShowDropDown(false);
      return;
    }

    const updatedFilters = [...selected, newFilter];

    onSelect(stage, updatedFilters);
    setShowDropDown(false);
  };

  const onSetStage = (value) => {
    setStage(value);
    setFrom(
      value === 'early'
        ? {
            label: '2',
            value: 2,
          }
        : {
            index: 0,
            label: 'Pre-seed',
            value: 'pre_seed',
          },
    );
    setTo(
      value !== 'current'
        ? {
            index: 1,
            label: 'Seed',
            value: 'seed',
          }
        : null,
    );
  };

  return (
    <Flex ref={ref} sx={{ alignItems: 'center', flexDirection: 'column', gap: '8px' }}>
      <Flex
        sx={{
          gap: '8px',
          alignItems: 'flex-start',
          cursor: 'pointer',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <FilterTitle
          title="Company funding stage"
          onClick={() => {
            setShowDropDown(!showDropDown);
            if (!selected?.length) {
              onSetStage('early');
            }
          }}
          clearFilter={() => onSelect('clear', [])}
          onToggle={() => setShowDropDown(!showDropDown)}
          showClear={!showDropDown && selected?.length > 0}
          showToggle={!showDropDown && !selected?.length}
        />
        {selected && selected.length > 0 && (
          <Flex sx={{ gap: '4px', flexWrap: 'wrap' }}>
            <Flex sx={{ alignItems: 'center', gap: '4px', flexWrap: 'wrap' }}>
              {selected.map((item) => (
                <Tag
                  data-testid={`tag-${item.id}`}
                  key={item.key}
                  onRemove={() => {
                    const updatedFilters = selected.filter((i) => i.key !== item.key);
                    onSelect(item.stage, updatedFilters);
                  }}
                  sx={{
                    mr: '0',
                  }}
                >
                  {item.stage === 'early' && `${item.from.value} or more years in ${item.to.label}`}
                  {item.stage === 'growth' && `${item.from.label} to ${item.to.label}`}
                  {item.stage === 'current' && `Currently in ${item.from.label}`}
                </Tag>
              ))}
              {!showDropDown && (
                <Flex
                  sx={{
                    minWidth: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowDropDown(true)}
                  data-testid="fund-filter-select-add"
                >
                  <Box sx={{ color: 'neutral.300' }} size="16px" as={Plus} />
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
        {showDropDown && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              width: '272px',
              '.Select__menu ': {
                fontSize: '14px',
              },
              '& .Select__control > div': {
                height: '32px',
                fontSize: '14px',
              },
            }}
          >
            <Flex
              sx={{
                gap: '8px',
                flexDirection: 'column',
                '& label > div': {
                  lineHeight: 'unset',
                },
              }}
            >
              <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
                <Radio onChange={() => onSetStage('early')} checked={stage === 'early'} label="Has had at least" />
                {stage === 'early' && (
                  <Flex
                    sx={{
                      pl: '24px',
                      gap: '8px',
                      '& > div': { width: '56px' },
                      alignItems: 'center',
                    }}
                  >
                    <Select
                      onChange={setFrom}
                      options={[
                        {
                          label: '2',
                          value: 2,
                        },
                        {
                          label: '5',
                          value: 5,
                        },
                        {
                          label: '10',
                          value: 10,
                        },
                      ]}
                      value={from}
                    />
                    <Text as="p" fontSize="14px">
                      years in
                    </Text>
                    <FundSelector width="113px" value={to} onChange={setTo} menuWidth="128px" />
                  </Flex>
                )}
              </Flex>
              <Flex sx={{ gap: '8px', flexDirection: 'column' }}>
                <Radio
                  onChange={() => onSetStage('growth')}
                  checked={stage === 'growth'}
                  label="Has experienced growth"
                />
                {stage === 'growth' && (
                  <Flex sx={{ gap: '8px', alignItems: 'center', pl: '24px' }}>
                    <FundSelector
                      width="104px"
                      value={from}
                      maxIndex={4}
                      onChange={(valueFrom, valueTo) => {
                        setFrom(valueFrom);

                        if (to?.index <= valueFrom.index) {
                          setTo(valueTo);
                        }
                      }}
                      menuWidth="128px"
                    />
                    <Text as="p" fontSize="14px">
                      to
                    </Text>
                    <FundSelector
                      onChange={setTo}
                      value={to}
                      width="104px"
                      menuWidth="128px"
                      disableByIndex={from?.index}
                    />
                  </Flex>
                )}
              </Flex>
              <Flex sx={{ gap: '8px', flexDirection: 'column', width: '100%' }}>
                <Radio
                  onChange={() => onSetStage('current')}
                  checked={stage === 'current'}
                  label="Currently at a company in"
                />
                {stage === 'current' && (
                  <Flex sx={{ gap: '8px', alignItems: 'center', pl: '24px' }}>
                    <FundSelector width="240px" value={from} onChange={setFrom} />
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Box
              sx={{
                'button:disabled': {
                  opacity: 0.5,
                },
              }}
            >
              <Button disabled={stage === 'current' ? !from : !from || !to} size="small" onClick={addFilter}>
                Add filter
              </Button>
            </Box>
            <Box color="text.subtle" fontSize="12px" lineHeight="16.8px">
              Not all companies are covered in our funding stage data.
            </Box>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

FundFilterGrowth.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stage: PropTypes.string.isRequired,
      years: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
      fund: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      earlyFund: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
};

export default FundFilterGrowth;
