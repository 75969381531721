import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Loader } from '@getro/rombo';

const ActionsIcon = ({ icon, text, onClick, isLoading, ...props }) => (
  <Flex
    onClick={onClick}
    sx={{
      flex: 'none',
      alignItems: 'center',
      gap: '8px',
      minWidth: '32px',
      height: '32px',
      justifyContent: 'center',
      cursor: 'pointer',
    }}
    {...props}
  >
    {!isLoading && (
      <>
        <Box data-testid="action-button" m={0} size="16px" aria-hidden="true" as={icon} sx={{ color: '#ffffff' }} />
        <Text sx={{ display: ['none', 'none', 'block'] }} as="span" fontSize="14px" fontWeight={500} lineHeight="16px">
          {text}
        </Text>
      </>
    )}
    {isLoading && <Loader size="small" />}
  </Flex>
);

ActionsIcon.propTypes = {
  icon: PropTypes.node,
  onClick: PropTypes.func,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
};

ActionsIcon.defaultProps = {
  icon: null,
  onClick: null,
  text: '',
  isLoading: false,
};
export default ActionsIcon;
