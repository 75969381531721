import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { Loader, MessageBlock } from '@getro/rombo';
import BlankLayout from 'components/layout/Blank';

import { get, put } from 'api';
import strings from 'strings';
import { networkRoutes } from 'router/routes';
import pageHoc from 'hocs/pageHoc';
import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';
import helperGet from 'helpers/get';
import settings from 'settings';

const linkErrorMessage = {
  title: 'Invalid link',
  text: 'Seems that your link is not valid or has expired.',
};

const pageTitle = 'Introductions';

const Unsubscribe = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [networkName, setNetworkName] = useState('');
  const [emailName, setEmailName] = useState('');
  const [queryParams] = useState(queryString.parse(history.location.search));

  const fetchNetwork = async (networkId) => {
    try {
      const result = await get(`/collections/${networkId}`);
      setNetworkName(helperGet(result, 'data.collection.name'));
    } catch (error) {
      trackException(error);
    }
  };

  // eslint-disable-next-line no-shadow
  const makeApiCall = async (emailType, emailName, networkId) => {
    try {
      await put('/mails/unsubscribe', {
        type: emailType,
        name: emailName,
        collectionId: networkId,
      });

      setLoading(false);
      setShowSuccess(true);

      trackEvent('email_unsubscribe', {
        type: emailType,
        name: emailName,
        collectionId: networkId,
        medium: 'email',
      });
    } catch (error) {
      trackException(error);
      setErrorMessage({ text: strings.genericError });
      setLoading(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const { emailType, emailName, networkId } = queryParams;

    // Check if there is any missing parameter
    if (!emailType || !emailName || !networkId) {
      setErrorMessage(linkErrorMessage);
      setLoading(false);
      return;
    }

    setEmailName(helperGet(strings, `emailNames.${emailName}`, emailName));

    fetchNetwork(networkId);
    makeApiCall(emailType, emailName, networkId);
    // eslint-disable-next-line
  }, [queryParams])

  const handleButtonClick = () => {
    // TODO: go to network in the URL
    history.push(networkRoutes.networkHome.path);
  };

  return (
    <BlankLayout>
      {loading && <Loader />}
      {errorMessage && (
        <MessageBlock
          type="error"
          title={errorMessage.title}
          content={errorMessage.text}
          buttonText={`Go to ${settings.companyName}`}
          onClick={handleButtonClick(history)}
        />
      )}
      {showSuccess && (
        <MessageBlock
          type="success"
          title="Succesfully unsubscribed"
          content={`You've successfully been unsubscribed from the ${networkName} ${emailName} email.`}
          buttonText={`Go to ${settings.companyName}`}
          onClick={handleButtonClick(history)}
        />
      )}
    </BlankLayout>
  );
};

export default pageHoc({ title: pageTitle })(Unsubscribe);
