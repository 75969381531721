import { FormSectionTitle, FormToggle, Loader, Panel } from '@getro/rombo';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';
import { openChat } from 'helpers/supportChat';
import { trackEvent } from 'helpers/analytics';
import { useFilterVisibility } from './hooks/useFilterVisibility';

export const JobBoardFilters = () => {
  const { customFilters, jobBoardFilters, loading, initialValues, updateFilterVisibility, networkId } =
    useFilterVisibility();

  if (loading) {
    return (
      <Panel sx={{ mb: '32px' }} title="Filters on job board">
        <Loader />
      </Panel>
    );
  }

  return (
    <Panel sx={{ mb: '32px' }} title="Filters on job board">
      <Box as="p" color="text.main" fontSize="16px" fontHeight="1.4" mb="32px" mt="24px">
        Customize the filters on your job board to help job seekers discover companies that match their interests.
      </Box>
      <Formik initialValues={initialValues}>
        <Form>
          <Flex sx={{ gap: '24px', flexDirection: 'column', mb: '32px' }}>
            {jobBoardFilters.map((filter) => (
              <Field
                id={filter.name}
                key={filter.name}
                component={FormToggle}
                name={filter.name}
                label={filter.label}
                helpText={filter.helpText}
                checked={filter.value}
                form={{
                  setFieldValue: () => {
                    updateFilterVisibility({
                      name: filter.name,
                      value: !filter.value,
                    });
                  },
                }}
              />
            ))}
          </Flex>
          {customFilters.length > 0 && (
            <Box>
              <FormSectionTitle>Custom filters</FormSectionTitle>
              <Flex sx={{ gap: '24px', flexDirection: 'column', mt: '32px' }}>
                {customFilters.map((filter) => (
                  <Field
                    id={filter.name}
                    key={filter.id}
                    component={FormToggle}
                    name={filter.name}
                    label={filter.label}
                    checked={filter.value}
                    helpText={filter.helpText}
                    form={{
                      setFieldValue: () => {
                        updateFilterVisibility({
                          name: filter.name,
                          value: !filter.value,
                          id: filter.id,
                        });
                      },
                    }}
                  />
                ))}
              </Flex>
            </Box>
          )}
        </Form>
      </Formik>
      <Box mt="32px">
        Want more filters?{' '}
        <Box
          onClick={() => {
            openChat();
            trackEvent('network_settings:add_more_filters_click', {
              collection_id: networkId,
            });
          }}
          as="a"
        >
          Let’s talk!
        </Box>
      </Box>
    </Panel>
  );
};

export default JobBoardFilters;
