import Jsona from 'jsona';

const GetroPropertyMapper = {
  createModel: () => ({}),
  setId: (model, id) => {
    Object.assign(model, { id: parseInt(id, 10) });
  },
  setAttributes: (model, attributes) => {
    Object.assign(model, attributes);
  },
  // setMeta(model: TJsonaModel, meta: TAnyKeyValueObject): void;
  setMeta: () => {},
  // setLinks(model: TJsonaModel, links: TAnyKeyValueObject): void;
  setLinks: () => {},
  // setResourceIdObjMeta(model: TJsonaModel, meta: unknown): void;
  setResourceIdObjMeta: () => {},
  setRelationships: (model, relationships) => {
    Object.assign(model, relationships);
  },
  // setRelationshipLinks(parentModel: TJsonaModel, relationName: string, links: TJsonApiLinks): void;
  setRelationshipLinks: () => {},
  // setRelationshipMeta(parentModel: TJsonaModel, relationName: string, meta: TAnyKeyValueObject): void;
  setRelationshipMeta: () => {},
};

const deserialize = (response) => {
  const dataFormatter = new Jsona({ jsonPropertiesMapper: GetroPropertyMapper });
  return dataFormatter.deserialize(response);
};

export default deserialize;
