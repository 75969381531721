import React from 'react';
import { Box } from 'rebass';
import PropTypes from 'prop-types';

export const TagRemove = ({ color, ...props }) => (
  <Box as="svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Box
      as="path"
      d="M6.50113 8.50113H6.51013M11.0023 4H2V13.0023L10.3631 21.3653C11.2093 22.2116 12.5956 22.2116 13.4419 21.3653L19.3653 15.4419C20.2116 14.5956 20.2116 13.2093 19.3653 12.3631L11.0023 4Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Box
      as="path"
      d="M22 2L18 6M18 2L22 6"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Box>
);

TagRemove.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.string,
};

TagRemove.defaultProps = {
  sx: {},
  color: '#ffffff',
};

export default TagRemove;
