import React, { useRef, useState } from 'react';
import { Button, Loader, Tooltip } from '@getro/rombo';
import { ChevronDown, RefreshCcw, Settings2, Trash } from 'lucide-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Box, Card, Flex, Text } from 'rebass/styled-components';

import { DeleteSharedListModal } from '../../components/atoms/deleteSharedListModal';
import { Dropdown } from '../../components/molecules/dropdown';
import { trackEvent } from '../../helpers/analytics';
import { activeNetworkSelector } from '../../redux/selectors';
import { useDeleteSharedListMutation, useRefreshSharedListMutation } from '../../services/contacts';
import { contactsV2Api } from '../../services/contactsV2';

export const ActionsDropdown = ({ sharedList }) => {
  const [deleteSharedList, { isLoading: isLoadingDelete }] = useDeleteSharedListMutation();
  const [refreshSharedList, { isLoading: isLoadingRefresh }] = useRefreshSharedListMutation();
  const activeNetwork = useSelector(activeNetworkSelector);
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const autoUpdate = sharedList?.smartOptions?.includes('auto_update');

  const onClickSettings = () => {
    dropdownRef.current.closeDropdown();

    history.push(`${window.location.pathname.replace('/app', '')}/lists/settings${history.location.search}`);
  };

  const onClickAutoUpdate = async () => {
    const { error } = await refreshSharedList({ sharedListId: sharedList.id });

    if (!error) {
      dropdownRef.current.closeDropdown();
      dispatch(contactsV2Api.util.invalidateTags([{ type: 'contacts', id: sharedList.id }]));

      toast(
        <Box>
          <Text fontSize="14px">Auto-update in progress</Text>
          <Text fontSize="14px" mt="4px" fontWeight="body">
            It may take a few minutes.
          </Text>
        </Box>,
        { icon: 'success', id: 'shared-lists-refresh' },
      );

      trackEvent('contacts:list:force_auto_update', {
        collectionId: activeNetwork.id,
        listId: sharedList.id,
      });
    }
  };

  const onDeleteSharedList = async () => {
    const { error } = await deleteSharedList({
      listId: sharedList.id,
      force: true,
    });

    if (!error) {
      trackEvent('contacts:list:delete_list', {
        collectionId: activeNetwork.id,
        listId: sharedList.id,
      });
      toast('List successfully removed', { icon: 'success', id: 'shared-lists-remove' });

      setShowDeleteWarningModal(false);
      history.push(`/networks/${activeNetwork.slug}/lists`);
    }
  };

  return (
    <>
      <Dropdown
        ref={dropdownRef}
        placement="bottom-end"
        trigger={
          <Button
            data-testid="open-actions-dropdown"
            variant="tertiary"
            size="small"
            icon={ChevronDown}
            iconPosition="right"
          >
            Actions
          </Button>
        }
      >
        <Card px="0" py="8px" width="208px">
          <Flex
            height="32px"
            px="16px"
            sx={{
              gap: '8px',
              alignItems: 'center',
              fontSize: '14px',
              cursor: 'pointer',
              textDecoration: 'none',
              ':hover': { bg: 'neutral.50' },
            }}
            onClick={onClickSettings}
          >
            <Box as={Settings2} width="16px" height="16px" aria-hidden="true" />
            Settings
          </Flex>
          {autoUpdate && (
            <Tooltip
              position="left"
              sx={{
                mb: '8px',
              }}
              content="Auto-update runs daily, get immediate updates now."
              trigger={
                <Flex
                  height="32px"
                  px="16px"
                  sx={{
                    gap: '8px',
                    alignItems: 'center',
                    fontSize: '14px',
                    cursor: 'pointer',
                    ':hover': { bg: 'neutral.50' },
                  }}
                  onClick={onClickAutoUpdate}
                >
                  {isLoadingRefresh ? (
                    <Loader size="small" />
                  ) : (
                    <>
                      <Box as={RefreshCcw} width="16px" height="16px" aria-hidden="true" />
                      Run Auto-update
                    </>
                  )}
                </Flex>
              }
            />
          )}
          <Box mx="16px" my="8px" width="calc(100% - 32px)" height="1px" bg="border.subtle" />
          <Flex
            height="32px"
            px="16px"
            sx={{
              gap: '8px',
              alignItems: 'center',
              fontSize: '14px',
              cursor: 'pointer',
              ':hover': { bg: 'neutral.50' },
            }}
            onClick={() => {
              setShowDeleteWarningModal(true);
              dropdownRef.current.closeDropdown();
            }}
          >
            <Box as={Trash} width="16px" height="16px" aria-hidden="true" />
            Delete
          </Flex>
        </Card>
      </Dropdown>
      {showDeleteWarningModal && (
        <DeleteSharedListModal
          list={sharedList}
          isLoading={isLoadingDelete}
          onCloseModal={() => setShowDeleteWarningModal(false)}
          onDeleteSharedList={onDeleteSharedList}
        />
      )}
    </>
  );
};

ActionsDropdown.propTypes = {
  sharedList: PropTypes.object,
};

ActionsDropdown.defaultProps = {
  sharedList: {},
};
