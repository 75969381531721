import React from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { useContactsExtended } from 'pages/contactsExtended/hooks/useContactsExtended';
import { ExtendedContact } from 'pages/contactsExtended/extendedContact';
import usePageTitle from '../../hooks/usePageTitle';
import { CreateList } from '../list/createList';

export const AddContacts = ({ sharedList }) => {
  const {
    queryParams,
    selectedRows,
    setSelectedRows,
    data,
    isLoading,
    isFetching,
    filtersCount,
    meta,
    showAllContactsEmptyState,
    onLoadMore,
    onChangeSelectedContact,
    onSetFilters,
    activeNetwork,
    listId,
  } = useContactsExtended(true);
  const history = useHistory();

  const { name: listName } = sharedList || {};
  usePageTitle(listName);

  return (
    <>
      <ExtendedContact
        onSetFilters={onSetFilters}
        queryParams={queryParams}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onChangeSelectedContact={onChangeSelectedContact}
        onLoadMore={onLoadMore}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        meta={meta}
        filtersCount={filtersCount}
        activeNetwork={activeNetwork}
        showAllContactsEmptyState={showAllContactsEmptyState}
        showAddToLisButton
        showListFilters
        list={sharedList}
        bulkActions={['add-to-list', 'add-tag', 'export', 'delete', 'clear-selection']}
        page="list:add"
        filtersTitle="Filter all contacts"
      />
      <Route exact path={`/networks/${activeNetwork.slug}/list/:listId/add-contacts/lists/settings`}>
        <CreateList
          goBack={() => {
            history.push(`/networks/${activeNetwork.slug}/list/${listId}/add-contacts${history.location.search}`);
          }}
        />
      </Route>
    </>
  );
};

AddContacts.propTypes = {
  sharedList: PropTypes.object,
};

AddContacts.defaultProps = {
  sharedList: null,
};
