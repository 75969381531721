import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'companies';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const exportCompanyErrorSelector = createSelector(rootSelector, ({ exportCompanyError }) => exportCompanyError);
export const exportCompanySuccessSelector = createSelector(
  rootSelector,
  ({ exportCompanySuccess }) => exportCompanySuccess,
);
