import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ borders, columns, className, data, responsive, striped }) => {
  const getCssClasses = () => {
    let cssClasses = `Table ${className}`;

    if (striped) {
      cssClasses += ' Table--striped';
    }

    if (borders === 'none') {
      cssClasses += ' Table--no-borders';
    }

    if (responsive) {
      cssClasses += ' Table--responsive';
    }

    return cssClasses;
  };

  const renderCell = (row, key) => {
    const cell = row.find((i) => i.key === key);

    return cell ? cell.content : null;
  };

  return (
    <div className={getCssClasses()} data-cy="table">
      <table className="Table__inner">
        <thead>
          <tr>
            {columns.map(({ key, content }) => (
              <th className="Table__cell Table__cell--head" key={key}>
                {content}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr className="Table__row" key={index}>
              {columns.map(({ key }) => (
                <td key={key} className="Table__cell Table__cell--body">
                  {renderCell(row, key)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const rowPropType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    content: PropTypes.node,
  }),
);

Table.propTypes = {
  borders: PropTypes.oneOf(['default', 'none']),
  className: PropTypes.string,
  columns: rowPropType,
  data: PropTypes.arrayOf(rowPropType),
  responsive: PropTypes.bool,
  striped: PropTypes.bool,
};

Table.defaultProps = {
  striped: false,
  responsive: true,
  borders: 'default',
  className: '',
  columns: [],
  data: [],
};

export default Table;
