export const contactDataSelection = (selectedRows, contactIds, queryTotal) => {
  let selectedDataRows = [];
  const excludedDataRows = [];
  let totalCount = 0;

  if (selectedRows.length > contactIds.length) {
    for (let i = 0; i < queryTotal; i += 1) {
      if (!selectedRows.includes(i)) {
        excludedDataRows.push(contactIds[i]);
      }
    }
  } else {
    selectedDataRows = selectedRows.map((position) => contactIds[position]);
  }

  if (!selectedDataRows.length && !excludedDataRows.length) {
    totalCount = queryTotal;
  } else if (selectedDataRows.length) {
    totalCount = selectedDataRows.length;
  } else if (excludedDataRows.length) {
    totalCount = queryTotal - excludedDataRows.length;
  }

  return {
    selectedDataRows,
    excludedDataRows,
    totalCount,
  };
};
