import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SearchableList } from '@getro/rombo';

export const SearchableBoardsList = ({
  networkTalentGroups,
  onSearchSubmit,
  itemTemplate,
  creating,
  onBoardSelectionChanged,
  listHeight,
  keyword,
  onSearchChange,
  error,
}) => {
  const [list, setList] = useState([]);
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    setList(networkTalentGroups);
    setShowCreate(false);
  }, [networkTalentGroups, error]);

  const searchChanged = (e) => {
    setList(networkTalentGroups.filter((i) => i.label.toLowerCase().includes(e.target.value.toLowerCase())));
    setShowCreate(networkTalentGroups.filter((i) => i.label === e.target.value).length === 0 && e.target.value !== '');
    onSearchChange(e);
  };

  return (
    <SearchableList
      keyword={keyword}
      listHeight={listHeight}
      placeholder="Search lists..."
      onSearchChange={searchChanged}
      list={list}
      itemTemplate={itemTemplate}
      showCreate={showCreate}
      loading={creating}
      onSearchSubmit={onSearchSubmit}
      createListColor="danger"
      onItemChange={onBoardSelectionChanged}
      error={error}
    />
  );
};

SearchableBoardsList.propTypes = {
  networkTalentGroups: PropTypes.array.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  itemTemplate: PropTypes.func.isRequired,
  creating: PropTypes.bool,
  onBoardSelectionChanged: PropTypes.func.isRequired,
  listHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  keyword: PropTypes.string,
  onSearchChange: PropTypes.func,
  error: PropTypes.string,
};

SearchableBoardsList.defaultProps = {
  creating: false,
  listHeight: undefined,
  keyword: '',
  onSearchChange: () => {},
  error: null,
};
