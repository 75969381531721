/* eslint-disable no-param-reassign */
import { handleActions } from 'redux-actions';
import produce from 'immer';
import { setLoadStorageValue } from './actions';

export const initialState = {};

const reducer = handleActions(
  {
    [setLoadStorageValue]: produce((draft, action) => {
      const {
        payload: { key, value },
      } = action;
      draft[key] = value;
      return draft;
    }),
  },
  initialState,
);

export default reducer;
