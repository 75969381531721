import React, { useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Button, Message } from '@getro/rombo';
import { useDispatch } from 'react-redux';
import { FocusLayout } from '../../components/layout/focus';
import { Feature } from './Feature';
import { InfoParagraph } from './InfoParagraph';
import { useUpgradeConnectMutation, useRequestConnectDemoMutation } from '../../services/contactsV2';
import NetworkSchema from '../../schemas/network';
import { updateNetwork } from '../../actions/networksActions';
import { triggerTrackEvent } from '../CompanyProfile/actions';

export const Upgrade = ({ network }) => {
  const [upgradeConnect, { isLoading, error }] = useUpgradeConnectMutation();
  const [requestConnectDemo, { isLoading: isRequestingConnectDemo, error: errorRequestingConnectDemo }] =
    useRequestConnectDemoMutation();
  const [isDemoRequested, setIsDemoRequested] = useState(false);
  const dispatch = useDispatch();
  const { id: activeNetworkId, contactsCount, contactsRemainingCount } = network;

  const totalContactsExceedingPlanLimit = contactsRemainingCount > 0 ? 0 : Math.abs(contactsRemainingCount);

  const onConfirmSubscriptionClick = async () => {
    if (!isLoading) {
      dispatch(
        triggerTrackEvent({
          name: 'connect_upsell:upgrade_page:upgrade_click',
          eventProperties: {
            network_id: activeNetworkId,
            current_number_contacts: contactsCount,
            total_contacts_exceeding_plan_limit: totalContactsExceedingPlanLimit,
          },
        }),
      );

      const { data } = await upgradeConnect();
      const { collectionId: id, ...otherProps } = data;

      dispatch(updateNetwork({ id, ...network, ...otherProps }));
    }
  };

  const onDemoRequest = async () => {
    if (!isRequestingConnectDemo) {
      dispatch(
        triggerTrackEvent({
          name: 'connect_upsell:upgrade_page:schedule_demo_click',
          eventProperties: {
            network_id: activeNetworkId,
            current_number_contacts: contactsCount,
            total_contacts_exceeding_plan_limit: totalContactsExceedingPlanLimit,
          },
        }),
      );

      await requestConnectDemo();

      setIsDemoRequested(true);
    }
  };

  return (
    <FocusLayout title="Upgrade GetroConnect">
      <Flex flexDirection="column" alignItems="flex-start">
        <Text
          as="h2"
          mb={[5]}
          width={['100%', '460px']}
          color="text.dark"
          fontWeight="semibold"
          sx={{ alignSelf: 'center', textAlign: 'center' }}
        >
          Get matches between all your team&apos;s contacts and your companies
        </Text>
        <Box
          width={['100%', '460px']}
          p="24px"
          mb={[1]}
          sx={{ alignSelf: 'center', borderWidth: '1px', borderStyle: 'solid', borderColor: 'neutral.100' }}
        >
          <Text fontWeight="bold" fontSize={[5]} color="text.dark">
            25,000 Contacts plan
          </Text>
          <Flex mt={[2]} mb={[4]} alignItems="baseline">
            <Text color="text.dark" fontWeight="medium" fontSize={[4]}>
              $250
            </Text>
            <Text color="text.subtle" fontWeight="body" fontSize={[1]} pl={[1]}>
              /month
            </Text>
            <Text color="purple.300" fontWeight="semibold" fontSize={[1]} pl={[2]}>
              Promotional offer
            </Text>
          </Flex>
          <Feature mb={[3]}>Increase your contacts limit to 25,000</Feature>
          <Feature mb={[5]}>Promotional offer to selected customers in a limited timeframe</Feature>
          <Button
            variant="primary"
            width="100%"
            height="48px"
            fontSize={[2]}
            display="flex"
            justifyContent="center"
            fontWeight="medium"
            loading={isLoading}
            onClick={onConfirmSubscriptionClick}
          >
            Confirm subscription
          </Button>
          {error && (
            <Message type="error" mt={[4]}>
              There was an unexpected error. Please try again or contact us at{' '}
              <a href="mailto:support@getro.com">support@getro.com</a>.
            </Message>
          )}
        </Box>
        <Box sx={{ alignSelf: 'center' }} mb={[6]}>
          {(!isDemoRequested || errorRequestingConnectDemo) && (
            <>
              <Text color="text.subtle" fontSize={[2]}>
                Want to talk?{' '}
                <Button
                  variant="link"
                  color="text.subtle"
                  sx={{ textDecoration: 'underline' }}
                  onClick={onDemoRequest}
                  p={0}
                  m={0}
                  loading={isRequestingConnectDemo}
                >
                  Schedule a demo with us.
                </Button>
              </Text>

              {errorRequestingConnectDemo && (
                <Box width="574px" my={[2]}>
                  <Message type="error">
                    There was an unexpected error. Please try again or contact us at{' '}
                    <a href="mailto:support@getro.com">support@getro.com</a>.
                  </Message>
                </Box>
              )}
            </>
          )}
          {isDemoRequested && !errorRequestingConnectDemo && (
            <Box width="574px" sx={{ alignSelf: 'center' }} my={[2]}>
              <Message type="success">
                Thanks for your interest! Our sales team will reach out within 1 business day to get you the information
                you need.
              </Message>
            </Box>
          )}
        </Box>
        <InfoParagraph title="How do I aggregate my team's Contacts?">
          Invite your team to add connections to Getro. They can import their LinkedIn connections, upload lists of
          contacts from any tool via CSV, and add single contacts directly from LinkedIn.
        </InfoParagraph>
        <InfoParagraph title="What Contact data do we have to manage?">
          Just a LinkedIn URL is needed to add Contacts. We&apos;ll take it from there and complete their professional
          profile. These profiles are automatically updated, so you don&apos;t have to worry about old data.
        </InfoParagraph>
        <InfoParagraph title="What types of jobs can I expect matches for?">
          We are initially focused on matching director-level positions and above. These are the open positions that
          move the needle the most for your portfolio companies. Engineering jobs are currently excluded.
        </InfoParagraph>
        <InfoParagraph title="How frequently do I get matches?">Get new matches on a weekly basis.</InfoParagraph>
      </Flex>
    </FocusLayout>
  );
};

Upgrade.propTypes = {
  network: NetworkSchema.isRequired,
};
