import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { activeNetworkSelector, hasJobAlertsEnabledSelector } from 'redux/selectors';

import pageHoc from 'hocs/pageHoc';
import useFeatureToggle from 'hooks/useFeatureToggle';
import { useInjectReducer } from 'hooks/useInjectReducer';
import { useInjectSaga } from 'hooks/useInjectSaga';

import NoAccess from 'components/atoms/NoAccess';
import compose from 'helpers/compose';
import { makeSelectIsLoading } from 'redux/loadingSelector';
import CustomizeCopy from '../components/CustomizeCopy';
import ExportData from '../components/ExportData';
import GeneralSection from '../components/General';
import JobBoard from '../components/JobBoard';
import TalentNetwork from '../components/TalentNetwork';

import { loadGeneralSettings, loadSaveGeneralSettings } from '../actions';
import reducer from '../reducer';
import saga from '../saga';
import {
  key,
  makeSelectGeneralSettings,
  makeSelectGeneralSettingsLoaded,
  makeSelectGeneralSettingsError,
  makeSelectGeneralSettingsSaveError,
  makeSelectGeneralSettingsSaveSucceed,
  makeSelectGdprSettingsSaveSucceed,
} from '../selectors';
import withMainLayout from '../../../hocs/withMainLayout';
import { SettingsLayout } from '../layout';
import ExportJobAlertSubscribers from '../components/ExportJobAlertSubscribers';

const pageTitle = 'Settings';

const General = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const activeNetwork = useSelector(activeNetworkSelector);
  const generalSettingsLoaded = useSelector(makeSelectGeneralSettingsLoaded);
  const generalSettingsError = useSelector(makeSelectGeneralSettingsError);
  const generalSettings = useSelector(makeSelectGeneralSettings);
  const generalSettingsSaveError = useSelector(makeSelectGeneralSettingsSaveError);
  const generalSettingsSaveSucceed = useSelector(makeSelectGeneralSettingsSaveSucceed);
  const isSavingGeneralSettings = useSelector(makeSelectIsLoading('saveGeneralSettings'));
  const gdprSettingsSaveSucceed = useSelector(makeSelectGdprSettingsSaveSucceed);
  const { id: active } = activeNetwork;
  const jobBoardSettingsEnabled = useFeatureToggle('jobBoardSettingsAdmins');

  const [editingGeneralSettings, setEditingGeneralSettings] = useState(false);
  const [, setEditingGdprSettings] = useState(false);
  const hasJobAlerts = useSelector(hasJobAlertsEnabledSelector);

  useEffect(() => {
    if (!generalSettingsLoaded && !generalSettingsError) {
      dispatch(loadGeneralSettings({ networkId: active }));
    }
  }, [active, dispatch, generalSettingsError, generalSettingsLoaded]);

  useEffect(() => {
    if (generalSettingsSaveSucceed) {
      setEditingGeneralSettings(false);
    }
  }, [generalSettingsSaveSucceed]);

  useEffect(() => {
    if (gdprSettingsSaveSucceed) {
      setEditingGdprSettings(false);
    }
  }, [gdprSettingsSaveSucceed]);

  const updateGeneralSettings = (values) => {
    dispatch(
      loadSaveGeneralSettings({
        networkId: active,
        values,
      }),
    );
  };

  if (!activeNetwork.isManager) {
    return <NoAccess cy="settings-unavailable" />;
  }

  return (
    <SettingsLayout>
      <GeneralSection
        generalSettings={generalSettings}
        fetchError={generalSettingsError}
        editing={editingGeneralSettings}
        onCancel={() => setEditingGeneralSettings(false)}
        onEditClick={() => setEditingGeneralSettings(true)}
        saveError={generalSettingsSaveError}
        submitting={isSavingGeneralSettings}
        onSubmit={updateGeneralSettings}
      />
      {jobBoardSettingsEnabled && <JobBoard />}
      <TalentNetwork />
      <CustomizeCopy />
      {hasJobAlerts && <ExportJobAlertSubscribers mb={[4]} />}
      <ExportData />
    </SettingsLayout>
  );
};

export default compose(withMainLayout, pageHoc({ title: pageTitle }))(General);
