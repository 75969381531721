import React from 'react';
import { List, TrendingUp } from 'lucide-react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

const MetricIcon = ({ reportType }) => {
  if (!reportType) {
    return null;
  }

  let Icon;
  switch (reportType) {
    case 'trend':
      Icon = TrendingUp;
      break;
    case 'detailed':
      Icon = List;
      break;
    default:
  }

  if (!Icon) {
    return null;
  }

  return (
    <div className={`Analytics-sidebar__metric-icon Analytics-sidebar__metric-icon--${reportType}`}>
      <Box as={Icon} aria-hidden="true" height="16px" strokeWidth="1.5" width="16px" />
    </div>
  );
};

MetricIcon.propTypes = {
  reportType: PropTypes.string.isRequired,
};

export default MetricIcon;
