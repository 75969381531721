import { ProfilePicture } from '@getro/rombo';
import { PauseCircle, PlayCircle, Trash } from 'lucide-react';
import React, { useMemo, useRef } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import AdminDataTableActionItem from '../AdminDataTableActionItem';

export const useAdminData = ({
  admins = [],
  onRemoveAdmin,
  onUpdateLinkedinSync,
  onResumeLinkedinSync,
  onResendInvitation,
  currentUserId,
  canRemoveCompanyAdmins,
}) => {
  const menuRef = useRef();
  const columns = [
    {
      Header: 'Admin',
      accessor: 'name',
      width: '40%',
      disableSortBy: true,
      show: true,
    },
    {
      Header: 'LinkedIn sync',
      accessor: 'linkedinSyncStatus',
      width: '40%',
      disableSortBy: true,
      show: true,
    },
    {
      Header: 'Last sync',
      accessor: 'lastSyncedAt',
      width: '10%',
      disableSortBy: true,
      show: true,
      sx: {
        justifyContent: 'flex-end',
      },
    },
    {
      Header: 'Contacts synced',
      accessor: 'contactsSynced',
      width: '20%',
      disableSortBy: true,
      show: true,
      sx: {
        justifyContent: 'flex-end',
      },
    },
    {
      Header: '',
      accessor: 'actions',
      width: '5%',
      disableSortBy: true,
      show: canRemoveCompanyAdmins,
    },
  ];

  const actions = useMemo(
    () => [
      {
        title: 'Pause LinkedIn sync',
        onClick: (admin) => onUpdateLinkedinSync(admin, 'pause'),
        show: (e) => e.linkedinSyncStatus === 'active',
        icon: PauseCircle,
      },
      {
        onClick: (admin) => onResumeLinkedinSync(admin, 'resume'),
        onlyNetworkAdmin: false,
        title: 'Resume LinkedIn sync',
        icon: PlayCircle,
        show: (e) => e.linkedinSyncStatus === 'paused',
      },
      {
        title: 'Remove admin',
        icon: Trash,
        onClick: (e) => onRemoveAdmin(e, 'remove'),
        show: () => canRemoveCompanyAdmins,
      },
    ],
    [canRemoveCompanyAdmins, onRemoveAdmin, onResumeLinkedinSync, onUpdateLinkedinSync],
  );

  const statusMap = useMemo(
    () => ({
      active: {
        color: 'icon.success',
        text: 'Active',
      },
      paused: {
        color: 'neutral.300',
        text: 'Paused',
      },
      reconnection_needed: {
        color: 'orange.600',
        text: 'Reconnection needed',
      },
      not_completed: {
        color: 'neutral.300',
        text: 'Not connected',
      },
    }),
    [],
  );

  const rows = useMemo(
    () =>
      admins.map((admin) => ({
        id: admin.id,
        name: (
          <Flex
            sx={{
              gap: '8px',
              text: {
                fontSize: '28px',
              },
            }}
            alignItems="center"
            justifyContent="flex-start"
            pl="8px"
          >
            <Box width="40px" height="40px" minWidth="40px">
              <ProfilePicture width="40px" height="40px" imageUrl={admin.avatarUrl} name={admin.name} />
            </Box>
            <Box>
              <Flex sx={{ gap: '8px' }}>
                <Text
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  fontSize="14px"
                  fontHeight="1.4"
                  color={!admin.onboardingCompleted ? 'text.subtle' : 'text.dark'}
                  fontWeight="500"
                >
                  {admin.name}
                </Text>

                {!admin.onboardingCompleted && (
                  <Text color="orange.600" fontSize="14px" fontWeight="500">
                    Pending
                  </Text>
                )}
              </Flex>
              <Text
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                fontSize="14px"
                fontHeight="1.4"
                color="text.subtle"
                fontWeight="400"
              >
                {admin.email}
              </Text>
            </Box>
          </Flex>
        ),
        linkedinSyncStatus: (
          <Flex sx={{ gap: '8px' }} alignItems="center" fontSize="14px" height="100%">
            {admin.onboardingCompleted && (
              <Box
                width="8px"
                height="8px"
                sx={{ borderRadius: '100%', gap: '4px' }}
                backgroundColor={statusMap[admin.linkedinSyncStatus]?.color}
              />
            )}
            {admin.linkedinSyncStatus === 'not_completed' && admin.onboardingCompleted && (
              <Text color="text.main">
                {statusMap[admin.linkedinSyncStatus]?.text}{' '}
                {admin.id !== currentUserId ? (
                  <Box as="a" color="text.subtle" onClick={() => onUpdateLinkedinSync(null, 'help-connect')}>
                    Help {admin.name.split(' ')[0]}
                  </Box>
                ) : (
                  <Box as="a" color="text.subtle" onClick={() => onUpdateLinkedinSync(null, 'connect')}>
                    Connect
                  </Box>
                )}
              </Text>
            )}
            {admin.linkedinSyncStatus === 'reconnection_needed' && admin.onboardingCompleted && (
              <Text color="text.main">
                {statusMap[admin.linkedinSyncStatus]?.text}{' '}
                {admin.id !== currentUserId ? (
                  <Box as="a" color="text.subtle" onClick={() => onUpdateLinkedinSync(null, 'help-reconnect')}>
                    Help {admin.name.split(' ')[0]}
                  </Box>
                ) : (
                  <Box as="a" color="text.subtle" onClick={() => onUpdateLinkedinSync(null, 'reconnect')}>
                    Reconnect
                  </Box>
                )}
              </Text>
            )}
            {['active', 'paused'].includes(admin.linkedinSyncStatus) && (
              <Text color="text.main">{statusMap[admin.linkedinSyncStatus]?.text}</Text>
            )}
            {!admin.onboardingCompleted && (
              <Text color="text.subtle">
                Invitation not accepted.{' '}
                <Box as="a" color="text.subtle" onClick={() => onResendInvitation(admin)}>
                  Resend invitation
                </Box>
              </Text>
            )}
          </Flex>
        ),
        lastSyncedAt: (
          <Flex
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              justifyContent: 'flex-end',
              width: '100%',
            }}
            fontSize="14px"
            color="text.main"
          >
            {admin.linkedinSyncStatus === 'not_completed' ? '-' : admin.lastSyncedAt}
          </Flex>
        ),
        contactsSynced: (
          <Flex width="100%" justifyContent="flex-end" fontSize="14px" color="text.main" my="8px" lineHeight="24px">
            {admin.contactsSynced}
          </Flex>
        ),
        actions: (
          <AdminDataTableActionItem
            ref={menuRef}
            actions={actions
              .filter((item) => item.show?.(admin))
              .map((action) => ({
                ...action,
                onClick: () => action.onClick(admin),
              }))}
            tableItem={admin}
          />
        ),
      })),
    [actions, admins, currentUserId, onResendInvitation, onUpdateLinkedinSync, statusMap],
  );

  return {
    columns: columns.filter((column) => column.show),
    rows,
    actions,
  };
};
