import { takeLatest, put, call } from 'redux-saga/effects';

import toast from 'react-hot-toast';
import { request } from '../../api';
import {
  errorUserProfile,
  loadedUserProfile,
  loadUserProfile,
  loadUpdateUserProfile,
  loadedUpdateUserProfile,
  errorUpdateUserProfile,
  userProfileUpdated,
} from '../../actions/profileActions';
import { addProtocolToUrl } from '../../helpers/urlHelpers';
import { trackException } from '../../helpers/errorTracker';
import APIError from '../../api/APIError';

const parseUserObject = (user) => ({
  ...user,
  // all users should have a userTallenCollection but depending on how the user is made some do not
  userTalentCollection: user.userTalentCollection || {},
  locations: user.locations.map((location) => ({ value: location.placeId, label: location.name })),
  websiteUrl: addProtocolToUrl(user.websiteUrl),
  linkedinUrl: addProtocolToUrl(user.linkedinUrl),
  twitterUrl: addProtocolToUrl(user.twitterUrl),
  githubUrl: addProtocolToUrl(user.githubUrl),
  dribbbleUrl: addProtocolToUrl(user.dribbbleUrl),
});

function* loadUserProfileEvent({ payload }) {
  try {
    const { memberId, cancelToken, activeNetworkId } = payload;
    const requestUrl = `/members/${memberId}`;
    const { data } = yield call(request, {
      url: requestUrl,
      options: {
        params: { collectionId: activeNetworkId },
        cancelToken,
      },
    });
    yield put(
      loadedUserProfile({
        user: parseUserObject(data),
      }),
    );
  } catch (err) {
    yield put(errorUserProfile({ error: err }));
    if (!(err instanceof APIError)) {
      yield call(trackException, err);
    }
  }
}

function* loadUpdateUserProfileEvent({ payload }) {
  try {
    const { updatedUser } = payload;
    const { locations, currentLocation, skills, ...rest } = updatedUser;

    const params = {
      ...rest,
      currentLocationId: currentLocation && currentLocation.value,
      locationIds: locations && locations.map((item) => item.value),
      skillIds: skills && skills.map((skill) => (skill.id ? skill.id : skill.value)),
    };

    if (params.resume && params.resume.length) {
      params.resume = params.resume[0];
    }

    const requestUrl = `/users/me`;
    const { data } = yield call(request, {
      url: requestUrl,
      options: {
        method: 'put',
        data: params,
      },
    });

    yield put(
      loadedUpdateUserProfile({
        user: parseUserObject(data),
      }),
    );
    yield put(userProfileUpdated({ payload }));
  } catch (err) {
    yield put(errorUpdateUserProfile(err));
    if (!(err instanceof APIError)) {
      yield call(trackException, err);
    }
  }
}

function* userProfileUpdatedEvent({ payload }) {
  let msg = 'Profile updated';

  if (payload?.payload?.updatedUser?.id === undefined) {
    msg = 'Email notification preference updated';
  }

  toast(msg, {
    icon: 'success',
    id: 'profile-updated',
  });
}

export default function* userProfileSaga() {
  yield takeLatest(loadUserProfile().type, loadUserProfileEvent);
  yield takeLatest(loadUpdateUserProfile().type, loadUpdateUserProfileEvent);
  yield takeLatest(userProfileUpdated().type, userProfileUpdatedEvent);
}
