import produce from 'immer';
import {
  FETCH_INTRODUCTION_REQUESTS,
  FETCH_INTRODUCTION_REQUESTS_FAILURE,
  FETCH_INTRODUCTION_REQUESTS_SUCCESS,
  MARK_AS_DONE_INTRODUCTION_REQUEST,
} from 'actions/introductionRequestsActions';

export const initialState = {
  loading: false,
};

/* eslint-disable default-case, no-param-reassign */
const introductionRequestReducer = (state = initialState, action) =>
  // eslint-disable-next-line no-unused-vars
  produce(state, (_draft) => {
    const { type, payload } = action;
    const { id } = payload || {};

    switch (type) {
      case FETCH_INTRODUCTION_REQUESTS: {
        return {
          ...state,
          loading: true,
        };
      }

      case FETCH_INTRODUCTION_REQUESTS_FAILURE: {
        return {
          ...state,
          loading: false,
        };
      }

      case FETCH_INTRODUCTION_REQUESTS_SUCCESS: {
        const { items } = payload;
        return {
          ...state,
          loading: false,
          [id]: items
            .filter((ir) => ir.introducible !== undefined)
            .sort((a, b) => (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1)),
        };
      }

      case MARK_AS_DONE_INTRODUCTION_REQUEST: {
        const { introductionRequest } = payload;
        const items = state[id].filter((ir) => ir.id !== introductionRequest.id);

        return {
          ...state,
          [id]: items,
        };
      }
      default:
        return state;
    }
  });

export default introductionRequestReducer;
