import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import OnboardingContext from './onboardingContext';

const EmployersOnboardingContext = ({ organization, network, steps, firstStep, isManager, children }) => {
  const [step, setStep] = useState(firstStep);
  const [organizationData, setOrganizationData] = useState(organization);
  const [networkData, setNetworkData] = useState(network);

  useEffect(() => setOrganizationData(organization), [organization]);
  useEffect(() => setNetworkData(network), [network]);

  return (
    <OnboardingContext.Provider
      value={{
        isManager,
        network: networkData,
        organization: organizationData,
        step,
        steps,
        updateNetwork: setNetworkData,
        updateOrganization: setOrganizationData,
        updateStep: setStep,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

EmployersOnboardingContext.propTypes = {
  children: PropTypes.node.isRequired,
  firstStep: PropTypes.number,
  isManager: PropTypes.bool,
  network: PropTypes.object,
  organization: PropTypes.object,
  steps: PropTypes.arrayOf(PropTypes.object),
};

EmployersOnboardingContext.defaultProps = {
  firstStep: 0,
  isManager: false,
  network: '',
  organization: {},
  steps: [],
};

export default EmployersOnboardingContext;
