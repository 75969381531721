import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { activeNetworkSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { useCreateSharedListMutation, useUpdateSharedListMutation } from 'services/contacts';
import { defaultFilters } from 'components/organisms/contactsFilters/defaultFilters';
import { parseFiltersData } from 'services/contacts/filters';
import strings from 'strings';
import { trackEvent } from 'helpers/analytics';
import isEqual from 'lodash.isequal';
import { useListTitle } from './useListTitle';
import { useSharedList } from './useSharedList';

export const useCreateList = () => {
  const [smartOption, setSmartOption] = useState();
  const formSchema = Yup.object().shape({
    name: Yup.string().required(),
  });
  const activeNetwork = useSelector(activeNetworkSelector);
  const [formDefaultValues, setFormValues] = useState({
    name: '',
    description: strings.sharedLists.defaultDescription,
  });
  const formRef = useRef();
  const { listId } = useParams();
  const { state } = useLocation();
  const isEdit = Boolean(listId);
  const [createSharedList, { isLoading: isSubmitting }] = useCreateSharedListMutation();

  const [updateSharedList, { isLoading: isUpdatingList }] = useUpdateSharedListMutation();
  const [match, setMatch] = useState(null);
  const [filters, setFilters] = useState(!isEqual(defaultFilters, state?.filters) ? state?.filters : null);
  const [matchType, setMatchType] = useState('job');
  const history = useHistory();
  const title = useListTitle({ isEdit, smartOption });

  const { isLoadingList, sharedListData } = useSharedList({
    listId,
    setFormValues,
    setMatch,
    setMatchType,
    setFilters,
  });

  const getMatchingProject = (currentMatch) => {
    let matchingProject = {};
    if (matchType === 'job' && currentMatch) {
      matchingProject = {
        jobId: currentMatch.job.id,
      };
    }

    if (matchType === 'private' && currentMatch) {
      matchingProject = {
        ...currentMatch.payload,
      };
    }

    return matchingProject;
  };

  const getEventData = (data) => {
    let aiMatches = 'none';
    let company = null;

    if (data?.sharedList?.matchingProject && data?.sharedList?.matchingProject?.job?.id) {
      aiMatches = 'posted_job';
    }

    if (data?.sharedList?.matchingProject && !data?.sharedList?.matchingProject?.job?.id) {
      aiMatches = 'private_match';
      company = data?.sharedList?.matchingProject?.organization?.id ? 'in_portfolio' : 'outside_portfolio';
    }

    return {
      collectionId: activeNetwork.id,
      list_name: data.sharedList.name,
      aiMatches,
      autoUpdate: Boolean(data.sharedList.smartOptions?.includes?.('auto_update')),
      company,
    };
  };

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      matchingProject: getMatchingProject(match),
    };

    if (filters) {
      payload.smartListOptions = { filters: parseFiltersData(filters), enabled: true };
    }

    const { error, data } = await createSharedList(payload);

    if (!error) {
      trackEvent('contacts:list:create_list', getEventData(data));
      history.push(`/networks/${activeNetwork.slug}/list/${data.sharedList.id}/contacts`);
    }
  };

  const handleSharedListUpdate = async (values, action = 'disable', smartOptionUpdate = true) => {
    const { error, data } = await updateSharedList({
      listId,
      ...values,
    });

    if (!error) {
      trackEvent(`contacts:list:${action}_${smartOptionUpdate ? 'smart_options' : 'list'}`, getEventData(data));
    }
  };

  const updateList = async (selectedMatch) => {
    const payload = {};
    if (smartOption === 'autoUpdate') {
      payload.smartListOptions = {
        filters: parseFiltersData(selectedMatch),
        enabled: true,
      };
    } else {
      payload.matchingProject = getMatchingProject(selectedMatch);
    }

    await handleSharedListUpdate(payload, 'enable');

    setSmartOption(null);
  };

  const onMatchSelected = async (selectedMatch) => {
    setSmartOption(null);

    if (!formDefaultValues.name) {
      setFormValues((current) => {
        const listTitle = `${selectedMatch?.job?.title} at ${selectedMatch?.organization?.name}`;
        return {
          ...current,
          name: listTitle,
        };
      });
    }

    if (isEdit && smartOption === 'matches' && matchType === 'private') {
      setMatch(selectedMatch);

      await updateList(selectedMatch);

      return;
    }

    if (isEdit && (smartOption === 'autoUpdate' || smartOption === 'matches')) {
      await updateList(selectedMatch);
    }

    if (smartOption === 'matches' && selectedMatch) {
      setMatch(selectedMatch);
    }

    if (smartOption === 'autoUpdate') {
      setFilters(selectedMatch);
    }
  };

  const updateSmartOption = async ({ type, matchType: matchTypeToUpdate, action }) => {
    setFormValues(formRef.current.values);

    if (action === 'disable' && type === 'autoUpdate') {
      if (isEdit) {
        handleSharedListUpdate({
          smartListOptions: {
            filters: parseFiltersData(defaultFilters),
            enabled: false,
          },
        });
      }
      setFilters(null);
    }

    if (action === 'disable' && type === 'matches') {
      if (isEdit) {
        handleSharedListUpdate({
          disableAiMatching: true,
          listId,
        });
      }
      setMatch(null);
    }

    if (action === 'edit') {
      if (type === 'matches') {
        setSmartOption('matches');
        setMatchType(matchTypeToUpdate);
      } else {
        setSmartOption(type);
      }
    }
  };

  const updateListTitle = async (values) => {
    await handleSharedListUpdate(
      {
        listId,
        ...values,
      },
      'edit',
      false,
    );
  };

  const goBack = () => setSmartOption(null);

  return {
    formSchema,
    activeNetwork,
    formRef,
    createSharedList,
    isSubmitting,
    isLoadingList: isEdit && (!sharedListData || isLoadingList),
    isUpdatingList,
    onSubmit: isEdit ? updateListTitle : onSubmit,
    title,
    smartOption,
    setSmartOption: (value) => {
      setSmartOption(value);
      setFormValues(formRef.current.values);
    },
    onMatchSelected,
    match,
    setMatch,
    goBack: smartOption ? goBack : null,
    updateSmartOption,
    filters,
    setFilters,
    setMatchType,
    matchType,
    formDefaultValues,
    isEdit,
    contactsCount: sharedListData?.contactsCount ?? 0,
    matchId: sharedListData?.matchingProject?.id,
    hasAutoUpdateFilters: (!isEdit && Boolean(filters)) || sharedListData?.smartOptions?.includes('auto_update'),
  };
};
