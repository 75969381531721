export default function hexToRgba(hex, alpha = 1) {
  if (!/^#[0-9A-F]{6}$/i.test(hex)) {
    return '';
  }

  const match = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const [r, g, b] = [parseInt(match[1], 16), parseInt(match[2], 16), parseInt(match[3], 16)];

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
