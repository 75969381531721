import useOnClickOutside from 'hooks/useClickOutside';
import { useCallback, useEffect, useState } from 'react';

export const useOptionsNavigation = ({ showDropDown, options, selected, onSelect, isMulti, hasInput, onClose }) => {
  const [currentOption, setCurrentOption] = useState(null);
  const ref = useOnClickOutside(onClose);
  const toggle = useCallback(
    (option) => {
      onClose();
      if (isMulti && hasInput) {
        onSelect(selected?.find((item) => item.value === option.value) ? selected : [...selected, option]);
        return;
      }
      if (isMulti && !hasInput) {
        onSelect(
          selected?.find((item) => item.value === option.value)
            ? selected.filter((item) => item.value !== option.value)
            : [...selected, option],
        );
        return;
      }
      onSelect(option);
    },
    [hasInput, isMulti, onClose, onSelect, selected],
  );

  useEffect(() => {
    const refContainer = ref.current;
    const inputElement = refContainer?.querySelector('.multi-select-search') ?? refContainer?.querySelector('input');

    const scroll = (position) => {
      const option = refContainer.querySelector(`#option-${position}`);

      if (!option) return;
      option.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    };

    const handleKeyDown = (e) => {
      if ((e.key !== 'ArrowDown' || e.key !== 'ArrowUp') && !showDropDown) return;

      let current = currentOption;

      if (e.key === 'ArrowUp') {
        if (typeof currentOption !== 'number') {
          setCurrentOption(options.length - 1);
          return;
        }

        setCurrentOption((prev) => {
          current = (prev + options.length - 1) % options.length;
          scroll(current);
          return current;
        });
      }

      if (e.key === 'ArrowDown') {
        if (typeof currentOption !== 'number') {
          setCurrentOption(0);
          return;
        }

        setCurrentOption((prev) => {
          current = (prev + 1) % options.length;
          scroll(current);
          return current;
        });
      }
    };

    const handleInputDown = (e) => {
      if (typeof currentOption === 'number' && e.key === 'Enter') {
        e.stopPropagation();
        onClose();
        const option = options[currentOption];
        inputElement.value = '';
        toggle(option);
        return;
      }

      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') return;
      setCurrentOption(null);
    };

    if (refContainer) {
      refContainer.removeEventListener('keydown', handleKeyDown);
      refContainer.addEventListener('keydown', handleKeyDown);
    }

    if (inputElement) {
      inputElement.removeEventListener('keydown', handleInputDown);
      inputElement.addEventListener('keydown', handleInputDown);
    }

    return () => {
      if (refContainer) refContainer.removeEventListener('keydown', handleKeyDown);
      if (inputElement) inputElement.removeEventListener('keydown', handleInputDown);
    };
  }, [currentOption, onClose, options, ref, showDropDown, toggle]);

  return {
    toggle,
    currentOption,
    ref,
  };
};
