import PropTypes from 'prop-types';

const LayoutSecondaryActionsSchema = PropTypes.arrayOf(
  PropTypes.shape({
    action: PropTypes.func,
    text: PropTypes.string,
    icon: PropTypes.object,
    popoverContent: PropTypes.string,
  }),
);

export default LayoutSecondaryActionsSchema;
