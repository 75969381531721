import { createSelector } from 'reselect';
import { initialState } from './reducers/userProfileReducer';
import { parseUserProfile } from '../helpers/userProfileHelpers';
import strings from '../strings';

export const key = 'userProfile';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const makeSelectUser = createSelector(rootSelector, (state) => {
  const { user } = state;
  if (user) {
    const { currentLocation, ...otherProperties } = user;

    return {
      currentLocation: user.currentLocation
        ? { value: user.currentLocation.placeId, label: user.currentLocation.name }
        : { value: 'unspecified', label: 'Unspecified' },
      ...otherProperties,
    };
  }

  return null;
});

export const makeSelectUserFetchError = createSelector(rootSelector, (state) => state.error);

export const makeSelectJobFunctions = createSelector(rootSelector, (state) => state.jobFunctions);

export const makeSelectSearchLocations = createSelector(rootSelector, (state) => state.searchLocations);

export const makeSelectUserProfile = createSelector(makeSelectUser, (state) => {
  if (state) {
    const { userTalentCollection } = state;
    const { hideFromDomains, hideFromCompanies, ...otherUserTalentCollection } = userTalentCollection;
    let { visibility } = userTalentCollection;
    // in the odd edge case a user does not have a value set we default to visible to all
    if (visibility === null || visibility === undefined) {
      visibility = 'visible';
    }
    return parseUserProfile({
      ...state,
      userTalentCollection: {
        ...otherUserTalentCollection,
        hideFromDomains: hideFromDomains || [],
        hideFromCompanies: hideFromCompanies || [],
        visibility: strings.profile.visibility.find((item) => item.value === visibility),
      },
    });
  }
  return null;
});

export const makeSelectPanelState = (panel) => createSelector(rootSelector, (state) => !!state.editModes[panel]);
