import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  loadJobs,
  loadedJobs,
  errorJobs,
  resetJobsList,
  toggleJobSelection,
  resetSelectedJobs,
  toggleAllJobsSelections,
  resetJobsTable,
} from './actions';
import { removeUser } from '../../actions/userActions';
import { setActiveNetwork } from '../../actions/networksActions';
import {
  loadedBulkForceJobVisibility,
  loadedCloseJob,
  loadedDeleteJob,
  loadedExtendJob,
  loadedFeatureJob,
  loadedForceJobVisibility,
} from '../useJobActions/actions';

export const initialState = {
  initialized: false,
  hasMore: true,
  error: false,
  data: [],
  selectAll: false,
  meta: {
    total: 0,
  },
};

/* eslint-disable default-case, no-param-reassign */
const jobsReducer = handleActions(
  {
    [resetJobsList]: produce((draft) => {
      draft.initialized = false;
      draft.hasMore = true;
      draft.error = false;
      return draft;
    }),
    [loadJobs]: produce((draft) => {
      draft.error = initialState.error;
      return draft;
    }),
    [loadedJobs]: produce((draft, action) => {
      const { payload } = action;
      const { hasMoreJobs, data, meta, page } = payload;

      if (page === 1) {
        const selectedJobIds = new Set(draft.data.filter((job) => job.selected).map((job) => job.id));
        draft.data = data.map((job) => ({ ...job, selected: draft.selectAll || selectedJobIds.has(job.id) }));
      } else {
        draft.data = draft.data.concat(data.map((job) => ({ ...job, selected: draft.selectAll })));
      }

      draft.initialized = true;
      draft.hasMore = hasMoreJobs;
      draft.error = false;
      draft.meta = meta;
    }),
    [errorJobs]: produce((draft) => {
      draft.initialized = true;
      draft.error = true;
    }),
    [loadedExtendJob]: produce((draft, action) => {
      const { job } = action.payload;
      draft.data = draft.data.map((j) => {
        const updatedJob = j;
        if (updatedJob.attributes.id === job.id) {
          updatedJob.attributes = job;
        }
        return updatedJob;
      });
      return draft;
    }),
    [loadedDeleteJob]: produce((draft, action) => {
      const { job } = action.payload;
      draft.data = draft.data.filter((j) => parseInt(j.id, 10) !== parseInt(job.id, 10));
      draft.meta.total -= 1;
      return draft;
    }),
    [loadedCloseJob]: produce((draft, action) => {
      const { payload } = action;
      const { jobId } = payload;
      draft.data = draft.data.map((job) => {
        if (parseInt(job.id, 10) === parseInt(jobId, 10)) {
          const match = job;
          match.attributes.status = 'closed';
          match.attributes.closedAt = new Date().toJSON();
          match.attributes.visibility = 'not_visible';
          return match;
        }
        return job;
      });
      return draft;
    }),
    [loadedForceJobVisibility]: produce((draft, { payload }) => {
      const { visibilityFilter, forcedVisibility, job } = payload;
      draft.data = draft.data.map((j) => {
        if (j.id.toString() === job.id.toString()) j.attributes.visibility = forcedVisibility;
        return j;
      });

      if (visibilityFilter && visibilityFilter !== forcedVisibility) {
        draft.data = draft.data.filter((j) => j.attributes.visibility === visibilityFilter);
        draft.meta.total -= 1;
      }

      return draft;
    }),
    [loadedFeatureJob]: produce((draft, { payload }) => {
      const { featuredFilter, featured, job } = payload;
      draft.data = draft.data.map((j) => {
        if (j.id.toString() === job.id.toString()) j.attributes.featured = featured;
        return j;
      });

      if (featuredFilter && featuredFilter !== featured) {
        draft.data = draft.data.filter((j) => j.attributes.featured === featuredFilter);
        draft.meta.total -= 1;
      }

      return draft;
    }),
    [loadedBulkForceJobVisibility]: produce((draft, { payload }) => {
      const { visibilityFilter, forcedVisibility, ids: jobsIds } = payload;
      draft.data = draft.data.map((j) => {
        if (jobsIds.includes(j.id.toString())) j.attributes.visibility = forcedVisibility;
        return j;
      });

      if (visibilityFilter && visibilityFilter !== forcedVisibility) {
        const lengthBefore = draft.data.length;
        draft.data = draft.data.filter((j) => j.attributes.visibility === visibilityFilter);
        const lengthAfter = draft.data.enght;
        draft.meta.total -= lengthBefore - lengthAfter;
      }

      return draft;
    }),
    [removeUser]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [setActiveNetwork]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [toggleJobSelection]: produce((draft, action) => {
      const { payload } = action;
      const { job, checked } = payload;

      if (!checked) {
        draft.selectAll = false;
      }

      draft.data = draft.data.map((j) => {
        if (j.id.toString() === job.id.toString()) j.selected = checked;
        return j;
      });

      return draft;
    }),
    [toggleAllJobsSelections]: produce((draft, action) => {
      const { payload } = action;
      const { selectAll } = payload;

      draft.selectAll = selectAll;
      draft.data = draft.data.map((j) => {
        j.selected = selectAll;
        return j;
      });

      return draft;
    }),
    [resetSelectedJobs]: produce((draft) => {
      draft.selectAll = false;
      draft.data = draft.data.map((j) => {
        j.selected = false;
        return j;
      });
      return draft;
    }),
    [resetJobsTable]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
  },
  initialState,
);

export default jobsReducer;
