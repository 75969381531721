/* eslint-disable react/no-multi-comp */
import React from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'styled-components';

export const MainSkeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 986 985"
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
    >
      <rect x="0" y="0" rx="0" ry="0" width="585" height="34" />
      <rect x="0" y="50" rx="0" ry="0" width="585" height="11" />
      <rect x="0" y="70" rx="0" ry="0" width="585" height="11" />
      <rect x="0" y="90" rx="0" ry="0" width="585" height="11" />
    </ContentLoader>
  );
};

export const TitleSkeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={216}
      height={30}
      viewBox="0 0 216 30"
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
    >
      <rect x="0" y="0" rx="4" ry="4" width="216" height="30" />
    </ContentLoader>
  );
};
