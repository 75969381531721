import React from 'react';
import { Button, Badge } from '@getro/rombo';
import { Box, Flex, Text, Image } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import Filter from '../../../assets/icon-filter.svg';

export const FilterButton = ({ onClick, filterCount, onAllFiltersClicked }) => (
  <>
    <Box display={['none', 'block']} ml={[0, 'auto', 0]} width={[1, 'unset']}>
      <Button
        color="text.main"
        sx={{ lineHeight: '22px', borderColor: 'neutral.300' }}
        variant="secondary"
        onClick={onAllFiltersClicked}
      >
        All filters
      </Button>
    </Box>
    <Box
      onClick={onClick}
      sx={{
        minHeight: '48px',
        minWidth: '100%',
        width: '100%',
        borderColor: 'border.subtle',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '8px',
        p: '0px 16px',
        cursor: 'pointer',
      }}
      display={['flex', 'none']}
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex sx={{ gap: '8px' }} alignItems="center">
        <Image src={Filter} />
        <Text fontWeight="500" color="text.dark">
          Filters
        </Text>
      </Flex>

      {filterCount > 0 ? (
        <Box>
          <Badge
            sx={{
              color: 'purple.400',
              backgroundColor: 'purple.100',
              fontWeight: '700',
              boxShadow: 'none',
              fontSize: '13px',
              lineHeight: '16px',
              height: '24px',
              width: '24px',
              padding: '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {filterCount}
          </Badge>
        </Box>
      ) : null}
    </Box>
  </>
);

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  filterCount: PropTypes.number.isRequired,
  onAllFiltersClicked: PropTypes.func.isRequired,
};
