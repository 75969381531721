import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { Loader, MessageBlock } from '@getro/rombo';
import BlankLayout from 'components/layout/Blank';

import { put } from 'api';
import strings from 'strings';
import { networkRoutes } from 'router/routes';
import pageHoc from 'hocs/pageHoc';
import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';
import get from 'helpers/get';
import settings from 'settings';

const linkErrorMessage = {
  title: 'Invalid link',
  text: 'Seems that your link is not valid or has expired.',
};

const gateKeeperAccept = async (introductionRequestId, setLoading, setSuccessMessage, setErrorMessage) => {
  try {
    const response = await put(`/introduction_requests/${introductionRequestId}/introduce`);

    const { introducible: organization } = response.data;

    setLoading(false);
    setSuccessMessage({
      title: `Introduction request sent to ${organization.name}!`,
      text: `If the request is accepted by ${organization.name} we will automatically connect them via email. Who knows, you may have just set up the perfect match!`,
    });
    setLoading();
  } catch (error) {
    trackException(error);
    setErrorMessage({ text: strings.genericError });
    setLoading();
  }
};

const handleIntroduction = async (introductionId, action, setLoading, setSuccessMessage, setErrorMessage) => {
  try {
    const response = await put(`/introductions/${introductionId}/${action}`);

    // TODO: add from and email fields
    const eventParams = {
      medium: 'email',
      to: get(response, 'data.introductionRequest.introducible.id', undefined),
    };

    if (action === 'accept') {
      setSuccessMessage({
        title: 'Introduction sent!',
        text: 'Check your inbox for an email from us connecting you both. If you do not receive the request within minutes, reach out to help@getro.com. ',
      });

      trackEvent('accept_introduction', eventParams);
    }

    if (action === 'decline') {
      setSuccessMessage({
        title: 'Introduction not facilitated',
        text: 'Roger, we will not facilitate this introduction to this member.',
      });

      trackEvent('decline_introduction', eventParams);
    }

    setLoading();
  } catch (error) {
    trackException(error);
    if (error.response && (error.response.status === 403 || error.response.status === 404)) {
      setErrorMessage(linkErrorMessage);
    } else {
      setErrorMessage({ text: strings.genericError });
    }
    setLoading();
  }
};

const pageTitle = 'Introductions';

const Introductions = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [queryParams] = useState(queryString.parse(history.location.search));

  useEffect(() => {
    const { action } = queryParams;

    if (action === 'gateKeeperAccept') {
      const { introductionRequestId } = queryParams;
      if (!introductionRequestId) {
        setErrorMessage(linkErrorMessage);
        setLoading(false);
        return;
      }
      gateKeeperAccept(introductionRequestId, setLoading, setSuccessMessage, setErrorMessage);
      return;
    }

    if (action === 'accept' || action === 'decline') {
      const { introductionId } = queryParams;
      if (!introductionId) {
        setErrorMessage(linkErrorMessage);
        setLoading(false);
        return;
      }
      handleIntroduction(introductionId, action, setLoading, setSuccessMessage, setErrorMessage);
      return;
    }

    setErrorMessage(linkErrorMessage);
    setLoading(false);
  }, [queryParams]);

  return (
    <BlankLayout>
      {loading && <Loader />}
      {errorMessage && (
        <MessageBlock
          type="error"
          title={errorMessage.title}
          content={errorMessage.text}
          buttonText={`Go to ${settings.companyName}`}
          buttonHref={networkRoutes.networkHome.path}
        />
      )}
      {successMessage && (
        <MessageBlock
          type="success"
          title={successMessage.title}
          content={successMessage.text}
          buttonText={`Go to ${settings.companyName}`}
          buttonHref={networkRoutes.networkHome.path}
        />
      )}
    </BlankLayout>
  );
};

export default pageHoc({ title: pageTitle })(Introductions);
