import { useCallback, useState } from 'react';

export const useLocalStorage = (storeKey, initialValue, options = { updateState: true }) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(storeKey);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const storeValue = useCallback(
    (value) => {
      localStorage.setItem(storeKey, JSON.stringify(value));

      if (options?.updateState) {
        setStoredValue(value);
      }
    },
    [storeKey, options],
  );

  return [storedValue, storeValue];
};
