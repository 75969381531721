import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { marked } from 'marked';
import dompurify from 'dompurify';
import styled from 'styled-components';

import { Loader, MessageBlock, Box, BoxSection, BoxRow, FormikTextEditor } from '@getro/rombo';
import { activeNetworkSelector } from 'redux/selectors';
import { get, put } from 'api';
import strings from 'strings';
import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';

const HtmlWrapper = styled.div`
  *:first-child {
    margin-top: 0;
    margin-block-start: 0 !important;
  }
`;

const StyledBoxSection = styled(BoxSection)`
  margin-top: var(--space-xxl);
`;

const CustomizeCopy = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { id: activeNetworkId } = activeNetwork;

  const [editing, setEditing] = useState(false);
  const [data, setData] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const handleEditClick = () => {
    setEditing(!editing);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setUpdateError(null);
    try {
      const response = await put(`/collections/${activeNetworkId}/settings/talent_network`, {
        ...values,
        tnHidden: undefined,
      });
      setData(response.data);
      setEditing(false);
      trackEvent('network_settings:edit_talent_network_copy');
    } catch (error) {
      trackException(error);
      setUpdateError(strings.genericError);
      resetForm();
    }
    setSubmitting(false);
  };

  const handleCancel = (resetForm) => () => {
    resetForm();
    setEditing(false);
  };

  // Load settings on first load
  useEffect(() => {
    (async () => {
      try {
        const response = await get(`/collections/${activeNetworkId}/settings/talent_network`);
        setData(response.data);
      } catch (error) {
        trackException(error);
        setFetchError(true);
      }
      setFetching(false);
    })(activeNetworkId);
    // eslint-disable-next-line
  }, [])

  // Don't show this section if the network is not using the TN
  if (!activeNetwork.features.includes('talent_network')) {
    return null;
  }

  if (fetching) {
    return (
      <Box title="Talent network positioning">
        <Loader />
      </Box>
    );
  }

  if (fetchError) {
    return (
      <Box title="Talent network positioning">
        <MessageBlock>{strings.genericError}</MessageBlock>
      </Box>
    );
  }

  return (
    <Formik initialValues={data} onSubmit={handleSubmit}>
      {({ isSubmitting, resetForm }) => (
        <Form>
          <Box
            title="Talent network positioning"
            onEditClick={handleEditClick}
            editLinkText="Edit"
            editing={editing}
            errorText={updateError}
            onCancelClick={handleCancel(resetForm)}
            loading={isSubmitting}
            saveAsSubmit
          >
            <BoxSection title="Talent network tab" tooltipContent={strings.settings.customCopy.talentNetworkTab}>
              <BoxRow title="Introduction">
                {editing && <FormikTextEditor name="tnTabIntroductionText" />}
                {!editing &&
                  (data.tnTabIntroductionText ? (
                    <HtmlWrapper
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(marked(data.tnTabIntroductionText)),
                      }}
                    />
                  ) : (
                    '-'
                  ))}
              </BoxRow>
            </BoxSection>
            <StyledBoxSection title="Talent sign up" tooltipContent={strings.settings.customCopy.signUpProcess}>
              <BoxRow title="Welcome message">
                {editing && <FormikTextEditor name="tnSignUpWelcomeText" />}
                {!editing &&
                  (data.tnSignUpWelcomeText ? (
                    <HtmlWrapper
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(marked(data.tnSignUpWelcomeText)),
                      }}
                    />
                  ) : (
                    '-'
                  ))}
              </BoxRow>
            </StyledBoxSection>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CustomizeCopy;
