import { debounce } from 'debounce';
import { useMemo } from 'react';
import { useLazyGetLocationsFromGoogleQuery } from 'services/locations';

export const useSearchGoogleLocations = () => {
  const [loadLocations, { isFetching, data }] = useLazyGetLocationsFromGoogleQuery();
  const search = debounce((value) => {
    if (!value) return;
    loadLocations(value);
  }, 500);

  const options = useMemo(
    () =>
      data?.items?.map(({ placeId, description }) => ({
        value: placeId,
        label: description,
      })) ?? [],
    [data],
  );

  return {
    loading: isFetching,
    options,
    search: (value) => search(value),
  };
};
