import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import { useDownloadJobApplicationMutation } from 'services/organizations';

export const useDownloadApplicants = () => {
  const currentNetwork = useSelector(activeNetworkSelector);
  const [download, { isLoading }] = useDownloadJobApplicationMutation();

  const downloadApplicants = useCallback(async () => {
    await download(currentNetwork.id);
    toast('We’ll email you the download, it can take a few minutes.', {
      icon: 'success',
      id: 'download_job_application',
    });
  }, [download, currentNetwork.id]);

  return {
    downloadApplicants,
    loading: isLoading,
  };
};
