import React from 'react';
import PropTypes from 'prop-types';

import { Loader, MessageBlock } from '@getro/rombo';

import Modal from 'components/atoms/Modal';

const ExportModal = ({ error, onClose, success, successTitle, successContent }) => (
  <Modal simple open data-cy="analytics-export-modal">
    {!success && !error && <Loader />}
    {success && (
      <MessageBlock type="success" title={successTitle} content={successContent} buttonText="Ok" onClick={onClose} />
    )}
    {error && (
      <MessageBlock type="error" title="There was an unexpected error" buttonText="Ok" onClick={onClose}>
        Please try again or contact us at <a href="mailto:help@getro.com">help@getro.com</a>.
      </MessageBlock>
    )}
  </Modal>
);

ExportModal.propTypes = {
  error: PropTypes.bool,
  onClose: PropTypes.func,
  success: PropTypes.bool,
  successTitle: PropTypes.string,
  successContent: PropTypes.node,
};

ExportModal.defaultProps = {
  error: false,
  onClose: () => {},
  success: false,
  successTitle: 'Email is on its way',
  successContent: 'We are building the csv and will send you via email soon.',
};

export default ExportModal;
