import React from 'react';
import PropTypes from 'prop-types';
import { MessageBlock } from '@getro/rombo';
import VouchModal from '../VouchModal';
import UserSchema from '../../../schemas/user';
import { SimplifiedModal } from '../../atoms/simplifiedModal';
import MemberSchema from '../../../schemas/member';

const VouchModalWrapper = ({
  canVerifyMembers,
  currentUser,
  isVouchingMember,
  vouchResult,
  onSubmit,
  onCloseVouchModal,
  fetchVouchError,
  setFetchVouchError,
  member,
  vouchOpen,
  vouchAction,
  setVouchOpen,
  isLoading,
}) => {
  if (fetchVouchError) {
    return (
      <SimplifiedModal onCancel={() => {}}>
        <MessageBlock
          type="error"
          content={fetchVouchError}
          buttonText="Ok"
          onClick={() => {
            setFetchVouchError(undefined);
            setVouchOpen(false);
          }}
        />
      </SimplifiedModal>
    );
  }

  if (!member || !vouchOpen) {
    return null;
  }

  const { verified, vouch } = member;
  const showVouchModal = !vouch || (canVerifyMembers && !verified);

  return (
    <>
      {showVouchModal && vouchOpen && (
        <VouchModal
          isLoading={isLoading}
          member={member}
          showPermissions={canVerifyMembers && !verified}
          action={vouchAction}
          open={vouchOpen}
          setOpen={setVouchOpen}
          currentUser={currentUser}
          isVouchingMember={isVouchingMember}
          vouchResult={vouchResult}
          onSubmit={onSubmit}
          onCloseVouchModal={onCloseVouchModal}
        />
      )}
    </>
  );
};

VouchModalWrapper.propTypes = {
  setVouchOpen: PropTypes.func.isRequired,
  vouchAction: PropTypes.string.isRequired,
  vouchOpen: PropTypes.bool,
  member: MemberSchema,
  fetchVouchError: PropTypes.string,
  setFetchVouchError: PropTypes.func,
  canVerifyMembers: PropTypes.bool.isRequired,
  currentUser: UserSchema.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isVouchingMember: PropTypes.bool.isRequired,
  vouchResult: PropTypes.shape({
    error: PropTypes.object,
    result: PropTypes.object.isRequired,
  }).isRequired,
  onCloseVouchModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

VouchModalWrapper.defaultProps = {
  vouchOpen: false,
  member: null,
  isLoading: true,
  fetchVouchError: null,
  setFetchVouchError: () => {},
};

export default VouchModalWrapper;
