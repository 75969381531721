import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'api';
import { Search, Message, FormSelect } from '@getro/rombo';
import strings from 'strings';
import { trackException } from 'helpers/errorTracker';
import NetworkSchema from 'schemas/network';
import { Field } from 'formik';

const Employers = ({
  errors,
  values,
  setFieldValue,
  isManagingOrganizations,
  select,
  search,
  canInviteEmployers,
  activeNetwork,
}) => {
  const [error, setError] = useState(false);

  const getSearchCompanies = async (query) => {
    if (!query.length) {
      return [];
    }

    setError(false);

    try {
      const response = await get(`/collections/${activeNetwork.id}/organizations_preview`, {
        params: { keyword: query },
      });

      return response.data.items.map(({ name, id, logoUrl }) => ({
        value: id,
        label: name,
        avatarUrl: logoUrl,
      }));
    } catch (e) {
      trackException(e);
      setError(strings.genericError);
      return [];
    }
  };

  if (isManagingOrganizations && select.length === 1) {
    return null;
  }

  return (
    <div className="InviteMember__employers">
      {isManagingOrganizations && select.length > 1 && (
        <Field component={FormSelect} name="company" label="Company" placeholder="Select Company" options={select} />
      )}

      {search && (
        <Search
          name="company"
          onSearch={getSearchCompanies}
          orientation="horizontal"
          placeholder="Search Company..."
          onChange={(e) => setFieldValue('company', e.target.value)}
          value={values.company}
          showAvatar="company"
        />
      )}

      {error && <Message className="InviteMember__error-message" type="error" content={error} />}
      {errors && errors.company && <div className="FormField__message FormField__message--error">{errors.company}</div>}

      {canInviteEmployers && (
        <div className="InviteMember__tip">
          Can’t find the company? <Link to={`/networks/${activeNetwork.slug}/companies/add`}>Add a new company</Link>
        </div>
      )}
    </div>
  );
};

Employers.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
  isManagingOrganizations: PropTypes.bool.isRequired,
  select: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  search: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  canInviteEmployers: PropTypes.bool.isRequired,
};

export default Employers;
