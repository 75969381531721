import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setPageTitle } from '../actions/pageActions';

const usePageTitle = (title) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (title) {
      dispatch(setPageTitle(title));
    }

    return () => dispatch(setPageTitle(''));
  }, [dispatch, title]);
};

export default usePageTitle;
