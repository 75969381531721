import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'jobForm';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const jobSelector = createSelector(rootSelector, ({ job }) => job);
export const loadingErrorSelector = createSelector(rootSelector, ({ job }) => job.error);
export const saveSuccessSelector = createSelector(rootSelector, ({ save }) => save.success);
export const jobOrganizationsSelector = createSelector(rootSelector, ({ jobOrganizations }) => jobOrganizations);
export const jobLocationsSelector = createSelector(rootSelector, ({ jobLocations }) => jobLocations);
export const companyAdminsSelector = createSelector(rootSelector, ({ companyAdmins }) => companyAdmins);
export const getIsCompanyAdmin = (userId) =>
  createSelector(rootSelector, ({ companyAdmins }) => companyAdmins.some((admin) => admin.id === userId));
