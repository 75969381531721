import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const Tabs = ({ tabs }) => (
  <Box
    width="100%"
    as="nav"
    role="navigation"
    variant="navigation.subnav"
    sx={{
      'a:hover': { backgroundColor: 'transparent' },
      overflow: 'hidden',
      position: 'relative',
      height: '28px',
      li: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        px: 0,
      },
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      WebkitOverflowScrolling: 'touch',
      borderBottom: '1px solid',
      borderColor: 'border.subtle',
      alignItems: 'flex-start',
      mb: '24px',
      gap: '32px',
      '.active': {
        color: 'text.dark',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16px',
        pb: '12px',
        borderBottom: '3px solid',
        borderColor: 'purple.400',

        '&::after': {
          content: ' - Remember this',
          backgroundColor: 'yellow',
          color: 'red',
          fontWeight: 'bold',
        },
      },
    }}
  >
    <Flex
      as="ul"
      sx={{
        position: 'absolute',
        gap: '24px',

        li: {
          height: '28px',
        },
      }}
    >
      {tabs.map((tab) => (
        <Box as="li" key={`tab-${Math.floor(Math.random() * 999 + 1)}`}>
          {tab}
        </Box>
      ))}
    </Flex>
  </Box>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.node).isRequired,
};
