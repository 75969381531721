import { useEffect, useState } from 'react';
import { EyeOff, Lock, RefreshCw } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateNetwork } from '../../actions/networksActions';
import { trackEvent } from '../../helpers/analytics';
import { getItem } from '../../helpers/store';
import { activeNetworkSelector, userSelector } from '../../redux/selectors';
import { EXTENSION_ID, checkExtensionAvailability } from '../../services/chromeExtension';

export const EXTENSION_MESSAGE_TYPES = {
  IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR: 'IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR',
  SCRAPER_VOYAGER_IMPORT_SUCCESS: 'SCRAPER_VOYAGER_IMPORT_SUCCESS',
};

export const useLinkedInSync = () => {
  const user = useSelector(userSelector);
  const activeNetwork = useSelector(activeNetworkSelector);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingVoyager, setIsLoadingVoyager] = useState(false);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const [responseType, setResponseType] = useState();
  const { action = 'sync' } = useParams();
  const dispatch = useDispatch();
  const items = [
    {
      icon: RefreshCw,
      title: 'Your LinkedIn connections are synced with Getro',
      description: 'Existing and new connections will be added.',
    },
    {
      icon: EyeOff,
      title: 'Added contacts are visible only to your team',
      description: 'Share selected contacts with selected companies.',
    },
    {
      icon: Lock,
      title: 'Your LinkedIn password is not stored',
      description: 'Getro doesn’t have access to your password.',
    },
  ];

  useEffect(() => {
    const eventStart = `linkedin_sync:${action === 'sync' ? 'connect_flow' : 'reconnect'}:start`;
    const eventEnd = `linkedin_sync:${action === 'sync' ? 'connect_flow' : 'reconnect'}:success`;

    trackEvent(activeNetwork?.linkedinSyncStatus === 'active' ? eventEnd : eventStart, {
      collectionId: activeNetwork.id,
    });
  }, [activeNetwork?.linkedinSyncStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        await checkExtensionAvailability();

        clearInterval(interval);
        setIsExtensionInstalled(true);
      } catch {
        // Do nothing
      } finally {
        if (isLoading) {
          setIsLoading(false);
        }
      }

      return () => clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onConnect = () => {
    // eslint-disable-next-line no-undef
    if (chrome?.runtime?.sendMessage) {
      setIsLoadingVoyager(true);
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(
        EXTENSION_ID,
        {
          type: 'SCRAPER_VOYAGER_INITIALIZE',
          auth: getItem('authToken'),
          user: {
            ...user,
            fullName: `${user.firstName} ${user.lastName}`,
          },
          networkId: activeNetwork.id,
        },
        (response) => {
          setResponseType(response?.type);
          setIsLoadingVoyager(false);

          if (response?.type === EXTENSION_MESSAGE_TYPES.SCRAPER_VOYAGER_IMPORT_SUCCESS) {
            dispatch(
              updateNetwork({
                ...activeNetwork,
                linkedinSyncStatus: 'active',
                contactsImportInProgress: true,
              }),
            );
          }
        },
      );
    }
  };

  return {
    isLoading,
    isLoadingVoyager,
    isExtensionInstalled,
    activeNetwork,
    action,
    responseType,
    featureList: items,
    onConnect,
  };
};
