import { takeLatest, select, call, put } from 'redux-saga/effects';
import { loadJob, loadedJob, errorJob } from './actions';
import { activeNetworkSelector } from '../../redux/selectors';
import { get } from '../../api';
import { trackException } from '../../helpers/errorTracker';
import APIError from '../../api/APIError';

function* handleLoadJobRequest({ payload }) {
  const { jobId } = payload;

  const { id: collectionId } = yield select(activeNetworkSelector);

  try {
    const response = yield call(get, `/jobs/${jobId}`, {
      params: {
        collectionId,
      },
    });

    const { data } = response;
    yield put(loadedJob(data));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }
    yield put(errorJob(e));
  }
}

export default function* saga() {
  yield takeLatest(loadJob().type, handleLoadJobRequest);
}
