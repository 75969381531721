import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { errorSubscribeToBoards, loadedSubscribeToBoards, loadSubscribeToBoards } from './actions';
import { request } from '../../api';
import { activeNetworkSelector } from '../../redux/selectors';

function* loadSubscribeToBoardsEvent({ payload }) {
  const { boardIds } = payload;
  const activeNetwork = yield select(activeNetworkSelector);

  try {
    yield all(
      boardIds.map(
        (board) =>
          call(request, {
            url: `/collections/${activeNetwork.id}/talent_groups/${board}/subscription`,
            options: {
              method: 'post',
            },
          }),
        // eslint-disable-next-line function-paren-newline
      ),
    );
    yield put(loadedSubscribeToBoards({ hasDailyAlerts: true, activeNetworkId: activeNetwork.id }));
  } catch (err) {
    yield put(errorSubscribeToBoards({ error: err }));
    yield put(loadedSubscribeToBoards({ hasDailyAlerts: false, activeNetworkId: activeNetwork.id }));
  }
}

export default function* shareSaga() {
  yield takeLatest(loadSubscribeToBoards().type, loadSubscribeToBoardsEvent);
}
