import qs from 'query-string';

export const getFilters = ({ search }) => {
  const { filters } = qs.parse(search, { arrayFormat: 'bracket' });
  let filterObject;
  try {
    filterObject = JSON.parse(filters);
  } catch (e) {
    filterObject = {};
  }

  return filterObject;
};

export const qsParse = ({ search }) => qs.parse(search, { decode: true });

export const qsStringify = (params) => qs.stringify(params, { encode: true });
