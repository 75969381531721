import { useEffect, useState } from 'react';

export const useIsKeyboardPressed = (control) => {
  const [keyboardPressed, setKeyboardPressed] = useState(false);
  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === control) {
        setKeyboardPressed(true);
      }
    };

    const handleKeyUp = () => {
      setKeyboardPressed(false);
    };

    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('keydown', handleKeyUp);
    };
  }, [control]);

  return keyboardPressed;
};
