import React, { memo } from 'react';
import { Logo } from '@getro/rombo';
import styled, { keyframes } from 'styled-components';
import { Flex } from 'rebass/styled-components';

const fadeKeyframes = keyframes`
  0% { opacity: 0.4; }
  50% {  opacity: 1; }
  100% { opacity: 0.4; }`;

const FadeAnimation = styled(Logo)`
  animation: ${fadeKeyframes} 3s infinite;
`;

const LoadingFullPage = () => (
  <Flex flex="1" alignItems="center" justifyContent="center" height="100%">
    <FadeAnimation width="120px" />
  </Flex>
);

export default memo(LoadingFullPage);
