import { Button } from '@getro/rombo';
import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import CompanyNotification from 'components/atoms/CompanyNotification';
import { trackEvent } from '../../../helpers/analytics';
import { ContactsProgressCount } from '../contactsProgressCount';

export const ContactsUpgradeBanner = ({ id, network, sx, ...rest }) => {
  const history = useHistory();

  if (network.connectTier !== 'freemium') {
    return false;
  }

  const { contactsCount, contactsLimit } = network || {};
  const completion = (contactsCount / contactsLimit) * 100;
  const title = completion > 100 ? 'You’re over your plan limit' : 'You’re near your plan limit';

  const onClickGetStarted = () => {
    trackEvent('connect_upsell:get_started_click', {
      origin: 'upsell_get_started_banner',
      page_type: id,
    });
    history.push(`/networks/${network.slug}/import-contacts`);
  };

  const onClickUpgradeNow = () => {
    trackEvent('connect_upsell:upsell_banner:cta_click', {
      origin: 'limit_banner',
      page_type: id,
    });
  };

  if (completion === 0) {
    return (
      <CompanyNotification
        onClick={onClickGetStarted}
        title="AI-powered Matches"
        description=" Start using our AI to match your contacts with the most relevant open jobs."
        buttonText="Get started"
      />
    );
  }

  if (completion >= 90) {
    return (
      <Box p="16px" bg="purple.100" sx={{ borderRadius: 'default', ...sx }} {...rest}>
        <Flex justifyContent="space-between" sx={{ gap: '16px' }} flexDirection={['column', 'row']}>
          <Box>
            <Text fontSize="14px" fontWeight="medium" color="text.dark">
              {title}
            </Text>
            <Text mt="4px" fontSize="14px">
              Upgrade your plan now to add more contacts.
            </Text>
          </Box>
          <Box width={['100%', '320px']} flex="0 0 auto">
            <ContactsProgressCount contactsCount={contactsCount} limit={contactsLimit} />
          </Box>
        </Flex>
        <Button
          as={Link}
          to={`/networks/${network.slug}/upgrade`}
          mt={['24px', '8px']}
          width={['100%', 'auto']}
          display={['flex', 'inline-flex']}
          justifyContent="center"
          onClick={onClickUpgradeNow}
          size="small"
        >
          {completion > 100 ? 'Upgrade and unlock all matches' : 'Upgrade now'}
        </Button>
      </Box>
    );
  }

  return false;
};

ContactsUpgradeBanner.propTypes = {
  id: PropTypes.string,
  network: PropTypes.object.isRequired,
  sx: PropTypes.object,
};

ContactsUpgradeBanner.defaultProps = {
  id: '',
  sx: {},
};
