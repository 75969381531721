import React, { useEffect, useMemo } from 'react';
import { Loader, Message } from '@getro/rombo';
import { useHistory, useLocation, useParams } from 'react-router';
import { Box } from 'rebass/styled-components';
import qs from 'query-string';

import { toast } from 'react-hot-toast';
import { FocusLayout } from '../../components/layout/focus';
import { useSubscribeEmailToSharedListMutation } from '../../services/sharedLists';
import { trackEvent } from '../../helpers/analytics';
import useSharedLists from '../sharedLists/useSharedLists';

export const ContactsSharedListsSlackSubscribe = () => {
  const { isInitialized, listInfo } = useSharedLists();
  const [subscribe, { error }] = useSubscribeEmailToSharedListMutation();
  const { slug } = useParams();
  const { search } = useLocation();
  const queryParams = useMemo(() => qs.parse(search), [search]);

  const history = useHistory();

  useEffect(() => {
    const subscribeToSharedList = async () => {
      await subscribe({ slug, data: { ...queryParams, slug } });

      trackEvent('contacts:shared_list:slack_connected_successfully', {
        list_slug: slug,
        collection_id: listInfo.collection.id,
      });

      toast('You are subscribed', {
        icon: 'success',
        id: 'subscribe-to-list-toast',
      });

      history.push(`/shared-lists/${slug}`);
    };

    if (isInitialized) {
      subscribeToSharedList();
    }
  }, [slug, queryParams, history, subscribe, isInitialized, listInfo]);

  return (
    <FocusLayout title="" backUrl={`/shared-lists/${slug}`}>
      <Box pb="5" maxWidth="600px" mx="auto">
        {error ? (
          <Message mt="3" type="error">
            There was an unexpected error, try again or contact us at help@getro.com.
          </Message>
        ) : (
          <Loader />
        )}
      </Box>
    </FocusLayout>
  );
};
