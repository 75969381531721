import React from 'react';
import qs from 'query-string';

import isEmpty from 'helpers/isEmpty';
import serializeParams from 'helpers/serializeParams';
import { useHistory } from 'react-router-dom';

const getParams = ({ search }) => {
  const { filters, ...params } = qs.parse(search, { arrayFormat: 'bracket' });

  let f;
  try {
    f = JSON.parse(filters);
  } catch (e) {
    f = {};
  }

  return { filters: f, ...params };
};

const removeEmpty = (filters) =>
  Object.keys(filters).reduce((acc, i) => {
    if (isEmpty(filters[i])) {
      return acc;
    }

    acc[i] = filters[i];

    return acc;
  }, {});

const clean = (history) => () => {
  const { filters, ...params } = getParams(history.location);

  history.push({
    pathname: history.location.pathname,
    search: serializeParams(params),
  });
};

const setter = (history) => (filter) => {
  const { filters, ...params } = getParams(history.location);
  const result = removeEmpty({ ...filters, ...filter });

  if (!Object.keys(result).length) {
    return clean(history)();
  }

  return history.push({
    pathname: history.location.pathname,
    search: serializeParams({
      ...params,
      filters: result,
    }),
  });
};

const withFilters = (Component) => {
  const Wrapped = (props) => {
    const history = useHistory();
    const { filters } = getParams(history.location);

    return (
      <Component
        {...props}
        history={history}
        filters={filters}
        setFilters={setter(history)}
        cleanFilters={clean(history)}
      />
    );
  };

  Wrapped.displayName = `withFilters(${Component.displayName || Component.name || 'Component'})`;

  return Wrapped;
};

export default withFilters;
