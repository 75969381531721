import deserialize from '../../helpers/getroPropertyMapper';

export const mapContactsV2ToV1 = (v2Data) => {
  const deserializedData = deserialize(v2Data);

  if (Array.isArray(deserializedData)) {
    return deserializedData.map((item) => ({
      contactWorkExperiences: [],
      tags: [],
      notes: [],
      sharedList: [],
      importers: [],
      ...item,
    }));
  }

  return {
    contactWorkExperiences: [],
    tags: [],
    notes: [],
    sharedList: [],
    importers: [],
    ...deserializedData,
  };
};
