import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Plus } from 'lucide-react';
import { Button, Loader, MessageBlock } from '@getro/rombo';
import UserSchema from 'schemas/user';
import strings from 'strings';
import toast from 'react-hot-toast';
import { trackEvent } from 'helpers/analytics';
import NetworkSchema from '../../../schemas/network';
import { AdminDataTable } from './AdminDataTable';
import RemoveAdminModal from '../adminPanel/removeAdminModal';
import { InviteAdminModal } from '../adminPanel/inviteAdminModal';
import PauseLinkedinSync from './PauseLinkedinSync';
import ConnectToLinkedin from './ConnectToLinkedin';
import ReconnectLinkedinSync from './ReconnectLinkedinSync';

export const AdminTable = ({
  onAdminInvite,
  onRemoveAdmin,
  onResendInvitation,
  adminGroupName,
  admins,
  location,
  activeNetwork,
  currentUser,
  canAddCompanyAdmins,
  canRemoveCompanyAdmins,
  isInvitingAdmin,
  isResendingInvitation,
  isDeletingAdmin,
  companyAdmins,
  loadingAdminsError,
  isLoadingAdmins,
  isLoadingLinkedinSync,
  updateLinkedinSync,
}) => {
  const urlParams = new URLSearchParams(location.search);
  const inviteModalOpen = urlParams.has('invite-admin') || urlParams.has('invite-manager');
  const [payload, setActionPayload] = useState(inviteModalOpen ? { action: 'invite' } : null);
  const formRef = useRef(null);

  const { push } = useHistory();
  const handleSetPayload = (data, adminAction) => {
    if (['connect', 'reconnect'].includes(adminAction)) {
      push(`/networks/${activeNetwork.slug}/linkedin/${adminAction === 'connect' ? 'sync' : 'reconnect'}`);
      return;
    }

    if (['help-connect', 'help-reconnect'].includes(adminAction))
      trackEvent(`linkedin_sync:help_other_user_to_${adminAction === 'help-reconnect' ? 'reconnect' : 'connect'}`, {
        collectionId: activeNetwork.id,
      });

    setActionPayload({
      data,
      action: adminAction,
    });
  };

  if (loadingAdminsError) return <MessageBlock data-testid="fetch-error">{strings.genericError}</MessageBlock>;

  if (isLoadingAdmins)
    return (
      <Flex mt="40px" justifyContent="center" width="100%">
        <Loader />
      </Flex>
    );

  return (
    <>
      <Box m="0 auto" px="16px">
        <Flex sx={{ justifyContent: 'flex-end', pb: '16px', pt: '8px' }}>
          <Button
            onClick={() =>
              setActionPayload({
                action: 'invite',
              })
            }
            width="142px"
            variant="secondary"
            icon={Plus}
            size="small"
          >
            Invite Admin
          </Button>
        </Flex>
        <AdminDataTable
          currentUserId={currentUser.id}
          admins={admins ?? []}
          canRemoveCompanyAdmins={canRemoveCompanyAdmins}
          onRemoveAdmin={canRemoveCompanyAdmins && onRemoveAdmin ? handleSetPayload : null}
          onResendInvitation={(admin) => {
            if (!canAddCompanyAdmins) return;
            onResendInvitation(admin).then(() => {
              toast('Invite Resent', {
                icon: 'success',
                id: 'resend-network-invite',
              });
            });
          }}
          isResendingInvitation={isResendingInvitation}
          onUpdateLinkedinSync={handleSetPayload}
          onResumeLinkedinSync={(admin) => {
            updateLinkedinSync(admin, 'resume').then(() => {
              setActionPayload(null);
              toast('LinkedIn sync resumed', {
                icon: 'success',
                id: 'linkedin-sync-resumed',
              });

              trackEvent('linkedin_sync:resume', {
                collectionId: activeNetwork.id,
                account: admin.id === currentUser.id ? 'own_account' : 'other_admin',
              });
            });
          }}
        />
      </Box>
      <InviteAdminModal
        isSubmitting={isInvitingAdmin}
        onAdminInvite={(data) => {
          onAdminInvite(data).then(() => {
            setActionPayload(false);
          });
        }}
        companyName={adminGroupName}
        modalOpen={payload?.action === 'invite'}
        setModalOpen={setActionPayload}
        formRef={formRef}
        companyAdmin={companyAdmins}
      />
      {canRemoveCompanyAdmins && onRemoveAdmin && (
        <RemoveAdminModal
          user={payload?.data}
          currentUser={currentUser}
          network={activeNetwork}
          isSubmitting={isDeletingAdmin}
          modalOpen={payload?.action === 'remove'}
          onCancel={() => setActionPayload(null)}
          onDeleteAdmin={() => onRemoveAdmin(payload.data).then(() => setActionPayload(null))}
        />
      )}

      {payload?.action === 'pause' && (
        <PauseLinkedinSync
          isSubmitting={isLoadingLinkedinSync}
          onCancel={() => setActionPayload(null)}
          onPauseLinkedinSync={() => {
            updateLinkedinSync(payload.data, 'pause').then(() => {
              setActionPayload(null);
              toast('LinkedIn sync paused', {
                icon: 'success',
                id: 'linkedin-sync-paused',
              });

              trackEvent('linkedin_sync:pause', {
                collectionId: activeNetwork.id,
                account: payload.data.id === currentUser.id ? 'own_account' : 'other_admin',
              });
            });
          }}
        />
      )}
      {payload?.action === 'help-connect' && (
        <ConnectToLinkedin
          onCancel={() => setActionPayload(null)}
          onButtonClicked={() => push(`/networks/${activeNetwork.slug}/linkedin/sync`)}
          link={`/app/networks/${activeNetwork.slug}/linkedin/sync`}
        />
      )}
      {payload?.action === 'help-reconnect' && (
        <ReconnectLinkedinSync
          onCancel={() => setActionPayload(null)}
          onButtonClicked={() => push(`/networks/${activeNetwork.slug}/linkedin/reconnect`)}
          link={`/app/networks/${activeNetwork.slug}/linkedin/reconnect`}
          networkName={activeNetwork.name}
        />
      )}
    </>
  );
};

AdminTable.propTypes = {
  isInvitingAdmin: PropTypes.bool,
  isDeletingAdmin: PropTypes.bool,
  isResendingInvitation: PropTypes.bool,
  adminGroupName: PropTypes.string.isRequired,
  activeNetwork: NetworkSchema.isRequired,
  currentUser: UserSchema.isRequired,
  onAdminInvite: PropTypes.func.isRequired,
  admins: PropTypes.array,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  onRemoveAdmin: PropTypes.func,
  onResendInvitation: PropTypes.func,
  canAddCompanyAdmins: PropTypes.bool,
  canRemoveCompanyAdmins: PropTypes.bool,
  loadingAdminsError: PropTypes.bool.isRequired,
  isLoadingAdmins: PropTypes.bool.isRequired,
  updateLinkedinSync: PropTypes.func.isRequired,
  isLoadingLinkedinSync: PropTypes.bool.isRequired,

  companyAdmins: PropTypes.bool,
};

AdminTable.defaultProps = {
  isInvitingAdmin: false,
  isDeletingAdmin: false,
  isResendingInvitation: false,
  admins: [],
  location: {},
  onRemoveAdmin: () => {},
  onResendInvitation: () => {},
  canAddCompanyAdmins: false,
  canRemoveCompanyAdmins: false,
  companyAdmins: true,
};
