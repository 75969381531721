import React, { useMemo, useState } from 'react';
import { EyeOff, Lock, Zap } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass/styled-components';

import { FocusLayout } from '../../components/layout/focus';
import usePageHook from '../../hooks/usePageHook';
import { activeNetworkSelector } from '../../redux/selectors';
import { useImportContactsFromCsvMutation } from '../../services/contactsV2';
import { StepUploadCsv } from './stepUploadCsv';
import { StepPreview } from './stepPreview';

const PAGE_TITLE = 'Add Contacts';

export const ContactsImportCsv = () => {
  const [importContactsFromCsv, { isLoading }] = useImportContactsFromCsvMutation();
  const [step, setStep] = useState(1);
  const [fileName, setFileName] = useState('');
  const [importData, setImportData] = useState({ contacts: [] });
  const activeNetwork = useSelector(activeNetworkSelector);
  const history = useHistory();
  const filteredContacts = useMemo(
    () =>
      importData.contacts.filter((contact) =>
        /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm.test(contact.linkedin_url),
      ), // eslint-disable-line function-paren-newline
    [importData],
  );
  const skippedContacts = importData.contacts.length - filteredContacts.length;

  usePageHook(PAGE_TITLE);

  const navigateBack = () => setStep(step - 1);
  const navigateForward = () => setStep(step + 1);

  const onNext = async (values) => {
    if (step === 1) {
      setImportData(values);
      navigateForward();
    } else if (step === 2) {
      const importedKeys = Object.keys(importData.contacts[0]);
      const filteredData = filteredContacts.map((contact) =>
        values.reduce((acc, position, index) => {
          const { value: key } = position;

          if (key !== 'ignore') {
            acc[key] = contact[importedKeys[index]];
          }

          return acc;
        }, {}),
      ); // eslint-disable-line function-paren-newline
      const fieldsToSkip = values.reduce((acc, column) => {
        if (column.mergeMethod === 'skip') {
          acc.push(column.value);
        }

        return acc;
      }, []);
      const fieldsToOverwrite = values.reduce((acc, column) => {
        if (column.mergeMethod === 'overwrite') {
          acc.push(column.value);
        }

        return acc;
      }, []);

      const { error } = await importContactsFromCsv({
        importData: {
          contacts: filteredData,
          fieldsToImport: values.reduce((acc, column) => {
            if (column.value !== 'ignore' && column.value !== 'linkedin_url') {
              acc.push(column.value);
            }
            return acc;
          }, []),
          ...(fieldsToSkip.length ? { fieldsToSkip } : undefined),
          ...(fieldsToOverwrite.length ? { fieldsToOverwrite } : undefined),
        },
        fileName,
      });

      if (!error) {
        history.push(`/networks/${activeNetwork.slug}/import-contacts/success`);
      }
    }
  };

  return (
    <FocusLayout title={PAGE_TITLE} backUrl={`/networks/${activeNetwork.slug}/contacts`}>
      <Box>
        <Text fontSize="12px">
          <Text as="span" color={step === 1 ? 'purple.300' : 'text.main'} fontWeight={step === 1 ? 'medium' : 'body'}>
            1. Upload
          </Text>
          {' > '}
          <Text as="span" color={step === 2 ? 'purple.300' : 'text.main'} fontWeight={step === 2 ? 'medium' : 'body'}>
            2. Map and preview
          </Text>
          {' > '}
          <Text as="span" color={step === 3 ? 'purple.300' : 'text.main'} fontWeight={step === 3 ? 'medium' : 'body'}>
            3. Import
          </Text>
        </Text>
        <Flex flexDirection={['column', 'row']} mt="8px" sx={{ columnGap: [0, '40px'] }}>
          {step === 1 && (
            <StepUploadCsv onNext={onNext} importData={importData} fileName={fileName} setFileName={setFileName} />
          )}
          {step === 2 && (
            <StepPreview
              isLoading={isLoading}
              onBack={navigateBack}
              onNext={onNext}
              contacts={filteredContacts}
              skippedContacts={skippedContacts}
            />
          )}
          {step === 1 && (
            <Box as="ul" flex="0 0 auto" pl="0" mt={[5, 0]} width={['100%', '340px']}>
              <Flex as="li" alignItems="center">
                <Flex
                  flex="0 0 auto"
                  width="32px"
                  height="32px"
                  alignItems="center"
                  justifyContent="center"
                  color="purple.400"
                  bg="purple.100"
                  mr="8px"
                  sx={{ borderRadius: 'circle' }}
                >
                  <Box as={Zap} width="16px" height="16px" aria-hidden="true" />
                </Flex>
                <Text fontSize="14px">
                  A complete professional profile will be automatically created for each contact.
                </Text>
              </Flex>
              <Flex as="li" mt="16px" alignItems="center">
                <Flex
                  flex="0 0 auto"
                  width="32px"
                  height="32px"
                  alignItems="center"
                  justifyContent="center"
                  color="purple.400"
                  bg="purple.100"
                  mr="8px"
                  sx={{ borderRadius: 'circle' }}
                >
                  <Box as={EyeOff} width="16px" height="16px" aria-hidden="true" />
                </Flex>
                <Text fontSize="14px">
                  Contacts are only visible to network admins until you add them to a shared list.
                </Text>
              </Flex>
              <Flex as="li" mt="16px" alignItems="center">
                <Flex
                  flex="0 0 auto"
                  width="32px"
                  height="32px"
                  alignItems="center"
                  justifyContent="center"
                  color="purple.400"
                  bg="purple.100"
                  mr="8px"
                  sx={{ borderRadius: 'circle' }}
                >
                  <Box as={Lock} width="16px" height="16px" aria-hidden="true" />
                </Flex>
                <Text fontSize="14px">We will never contact or share your contacts without your permission.</Text>
              </Flex>
            </Box>
          )}
        </Flex>
      </Box>
    </FocusLayout>
  );
};
