import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  isNetworkAdminSelector,
  makeIsCompanyAdminSelector,
  canManageCompaniesSelector,
  isOrganizationManagerSelector,
} from './selectors';
import { activeNetworkSelector } from '../../redux/selectors';

const usePermissions = (props) => {
  const { companySlug = '' } = props || {};
  const network = useSelector(activeNetworkSelector);
  const isNetworkAdmin = useSelector(isNetworkAdminSelector);
  const isOrganizationManager = useSelector(isOrganizationManagerSelector);
  const isCompanyAdmin = useSelector(makeIsCompanyAdminSelector(companySlug));
  const canManageCompanies = useSelector(canManageCompaniesSelector);

  const canManageMembers = useMemo(() => {
    if (!network) {
      return false;
    }

    const { features, tnHidden } = network;

    // Don't show it if TN is not active
    if (!features.includes('talent_network')) {
      return false;
    }

    // Don't show it to Organization Managers if it's hidden for them
    return !(!isNetworkAdmin && isOrganizationManager && tnHidden);
  }, [network, isNetworkAdmin, isOrganizationManager]);

  return {
    company: {
      canManageCompanies,
      canManageGeneralInformation: isNetworkAdmin || isCompanyAdmin,
      canManageManuallyPostedJobs: isNetworkAdmin || isCompanyAdmin,
      canAddCompanyAdmins: isNetworkAdmin || isCompanyAdmin,
      canRemoveCompanyAdmins: isCompanyAdmin,
    },
    canManageMembers,
    notifications: { canManageNotifications: isNetworkAdmin || isOrganizationManager },
  };
};

export default usePermissions;
