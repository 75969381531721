/* eslint-disable no-console */
function print(prev, location, action) {
  console.groupCollapsed(
    `%crouter %c${action} %c@ ${new Date().toLocaleTimeString()}`,
    'color: gray',
    'color: inherit',
    'color: gray',
  );
  console.log(`%cprev`, 'color: gray; font-weight: bold', prev);
  console.log(`%caction %c${action}`, 'color: #03A9F4; font-weight: bold', 'font-weight: bold');
  console.log('%ccurrent', 'color: #4CAF50; font-weight: bold', { location, action });
  console.groupEnd();
}

const routerLogger = (history) => {
  if (process.env.NODE_ENV === 'development') {
    let prev;

    history.listen((location, action) => print(prev, location, action));

    prev = { location: history.location, action: history.action };
    print({}, prev.location, prev.action);
  }
};

export default routerLogger;
