import React, { useRef } from 'react';
import { Flex } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { MemberFilters } from '../memberFilters';

import { SimplifiedModal } from '../../atoms/simplifiedModal';

export const FilterModal = ({
  filterValues,
  vouchedBy,
  preferredLocations,
  skills,
  onClearAll,
  onFilter,
  onCancel,
}) => {
  const formRef = useRef(null);

  const defaultValues = {
    keyword: '',
    skillIds: [],
    seniorities: [],
    locationIds: [],
    remoteWork: false,
    willWorkAnywhere: false,
    employment: [],
    mentorship: false,
    jobSearchStatuses: [],
    usWorkStatus: [],
    verifiedMembers: '',
    referralIds: [],
    vouchedBySomeone: null,
  };

  const initValues = { ...defaultValues, ...filterValues };

  return (
    <SimplifiedModal
      data-testid="members-filter-modal"
      onCancel={onCancel}
      variant="large"
      isOpen
      title="Filters"
      content={
        <Formik innerRef={formRef} enableReinitialize initialValues={initValues} onSubmit={onFilter}>
          {({ values, setFieldValue }) => (
            <MemberFilters
              values={values}
              setFieldValue={setFieldValue}
              isCompanyAdmin
              formRef={formRef}
              skills={{
                isLoading: skills.isLoading,
                options: skills.options,
                onInputChange: skills.onInputChange,
              }}
              preferredLocations={{
                isLoading: preferredLocations.isLoading,
                options: preferredLocations.options,
                onInputChange: preferredLocations.onInputChange,
              }}
              vouchedBy={{
                isLoading: vouchedBy.isLoading,
                options: vouchedBy.options,
                onInputChange: vouchedBy.onInputChange,
              }}
            />
          )}
        </Formik>
      }
      actions={
        <Flex justifyContent={['flex-end']}>
          <Button mr={[2]} onClick={onClearAll} variant="text">
            Clear all filters
          </Button>
          <Button
            ml={[2]}
            onClick={() => {
              const { values } = formRef.current;
              onFilter(values);
            }}
          >
            See results
          </Button>
        </Flex>
      }
    />
  );
};

FilterModal.propTypes = {
  filterValues: PropTypes.shape({
    keyword: PropTypes.string,
    skillIds: PropTypes.array,
    seniorities: PropTypes.array,
    locationIds: PropTypes.array,
    remoteWork: PropTypes.bool,
    willWorkAnywhere: PropTypes.bool,
    employment: PropTypes.array,
    mentorship: PropTypes.bool,
    jobSearchStatuses: PropTypes.array,
    usWorkStatus: PropTypes.array,
    verifiedMembers: PropTypes.string,
    referralIds: PropTypes.array,
    vouchedBySomeone: PropTypes.bool,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  skills: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
  preferredLocations: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
  vouchedBy: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
};

FilterModal.defaultProps = {};
