import { createApi } from '@reduxjs/toolkit/query/react';
import { networkBaseQueryV2 } from './queries';
import removeEmptyProps from '../helpers/removeEmptyProps';

const DEFAULT_FILTER = {
  sortBy: 'createdAt',
  sortDirection: 'desc',
  visibility: null,
  source: null,
  status: null,
  expiration: null,
  featured: null,
  expirationWithin: null,
  locationIds: [],
};

export const jobsV2Api = createApi({
  reducerPath: 'jobsV2Api',
  baseQuery: networkBaseQueryV2,
  tagTypes: ['jobs'],
  endpoints: (builder) => ({
    getJobs: builder.query({
      query: ({ page = 1, hitsPerPage = 20, filters }) => ({
        url: '/jobs',
        params: {
          page,
          perPage: hitsPerPage,
          ...removeEmptyProps({ ...DEFAULT_FILTER, ...filters }),
        },
      }),
      providesTags: ['jobs'],
    }),
  }),
});

export const { useGetJobsQuery, useLazyGetJobsQuery } = jobsV2Api;
