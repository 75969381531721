import React from 'react';
import { Image, Box } from 'rebass/styled-components';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconSlack from '../../../assets/icon-slack.svg';
import HeaderButton from '../headerButton';
import ContactsSharedListSchema from '../../../schemas/contactsSharedList';
import { trackEvent } from '../../../helpers/analytics';
import HeaderButtonContent from '../headerButtonContent';

const SubscribeToSlackButton = ({ listInfo, sticky, ...otherProps }) => {
  const { slug } = useParams();
  const { host, protocol } = window.location;
  const params = {
    redirect_uri: `${protocol}//${host}/app/shared-lists/${slug}/slack/subscribe`,
    client_id: '94499456550.3997479506516',
    scope:
      'chat:write,channels:join,incoming-webhook,app_mentions:read,commands,conversations.connect:manage,users:read,im:write,conversations.connect:read,conversations.connect:write,channels:manage',
  };

  const onClick = () => {
    trackEvent('contacts:shared_list:slack_cta_click', {
      list_slug: slug,
      origin: sticky ? 'fixed_header' : 'page_header',
      collection_id: listInfo.collection.id,
    });

    window.open(`https://slack.com/oauth/v2/authorize?${new URLSearchParams(params).toString()}`);
  };

  return (
    <HeaderButton
      tooltip="Get Slack notifications when new people are added to this list."
      {...otherProps}
      onClick={onClick}
    >
      <HeaderButtonContent>
        <Image src={IconSlack} width="16px" height="16px" mr="4px" />
        <Box as="span">Add to Slack</Box>
      </HeaderButtonContent>
    </HeaderButton>
  );
};

SubscribeToSlackButton.propTypes = {
  listInfo: ContactsSharedListSchema,
  sticky: PropTypes.bool,
};

SubscribeToSlackButton.defaultProps = {
  sticky: false,
  listInfo: null,
};

export default SubscribeToSlackButton;
