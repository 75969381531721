import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import strings from 'strings';
import { setPageTitle } from 'actions/pageActions';
import pageHoc from 'hocs/pageHoc';
import compose from 'helpers/compose';
import { Loader, MessageBlock } from '@getro/rombo';
import { Box } from 'rebass/styled-components';
import { useGetIndustryQuery } from 'services/organizations';
import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import MainLayout from '../../components/layout/Main';
import CareersPages from './CareersPages';
import Info from './Info';
import Settings from './Settings';
import { loadRemoveCompany, loadTopicOptions, resetCompanyProfile } from './actions';
import { makeSelectIsLoading } from '../../redux/loadingSelector';
import { key, makeSelectCompanySettings } from './selectors';
import { useInjectReducer } from '../../hooks/useInjectReducer';
import reducer from './reducer';
import { useInjectSaga } from '../../hooks/useInjectSaga';
import saga from './saga';
import usePermissions from '../../hooks/usePermissions';
import { activeNetworkSelector, userSelector } from '../../redux/selectors';
import BannerLandingPage from './bannerLandingPage';
import { CompanyProfileLayout } from '../../components/layout/CompanyProfileLayout';
import useCompany from '../../hooks/useCompany';

const pageTitle = 'Company profile';

const CompanyProfile = () => {
  const history = useHistory();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const {
    loading,
    company,
    initialized,
    error,
    companySlug,
    updateCompany,
    isUpdating,
    updateCompanyError,
    resetError,
  } = useCompany();
  const dispatch = useDispatch();
  const permissions = usePermissions({ companySlug });
  const activeNetwork = useSelector(activeNetworkSelector);
  const currentUser = useSelector(userSelector);
  const companySettings = useSelector(makeSelectCompanySettings);
  const isRemoveCompanyLoading = useSelector(makeSelectIsLoading('removeCompany'));
  useGetIndustryQuery();

  useEffect(
    () => () => {
      dispatch(resetCompanyProfile());
      resetError();
    },
    [dispatch, resetError],
  );

  useEffect(() => {
    dispatch(
      loadTopicOptions({
        networkId: activeNetwork.id,
      }),
    );
  }, [activeNetwork.id, dispatch]);

  useEffect(() => {
    if (loading) {
      dispatch(setPageTitle('Loading...'));
    } else if (company?.name) {
      dispatch(setPageTitle(`${company.name} profile`));
    } else {
      dispatch(setPageTitle(''));
    }
  }, [loading, company, dispatch]);

  return (
    <MainLayout>
      {error ? (
        <>
          <MainLayoutHeader title={pageTitle} />
          <Box mt="24px" maxWidth="400px" mx="auto">
            {error !== 404 && <MessageBlock type="error" content={strings.genericError} />}
            {error === 404 && (
              <MessageBlock
                type="warning"
                title="Company not found"
                content="It looks like the URL is not correct or the company doesn’t belong to the active network."
              />
            )}
          </Box>
        </>
      ) : (
        <>
          {!initialized ? (
            <>
              <MainLayoutHeader title={pageTitle} />
              <Loader />
            </>
          ) : (
            <>
              <CompanyProfileLayout showNotPublishedInfo={!company.qa}>
                <BannerLandingPage mb="4" activeNetwork={activeNetwork} slug={companySlug} />
                <Info
                  company={company}
                  onCompanyUpdate={updateCompany}
                  error={updateCompanyError}
                  editable={permissions.company.canManageGeneralInformation}
                  updating={isUpdating}
                  network={activeNetwork}
                />

                <CareersPages activeNetwork={activeNetwork} currentUser={currentUser} company={company} />
                <Settings
                  history={history}
                  companySettings={companySettings}
                  isRemoveCompanyLoading={isRemoveCompanyLoading}
                  removeCompany={(params) => dispatch(loadRemoveCompany(params))}
                  network={activeNetwork}
                  company={company}
                />
              </CompanyProfileLayout>
            </>
          )}
        </>
      )}
    </MainLayout>
  );
};

export default compose(pageHoc({ title: pageTitle }))(CompanyProfile);
