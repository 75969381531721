import produce from 'immer';
import { handleActions } from 'redux-actions';
import { closeExportCompanies, errorExportCompanies, loadedExportCompanies, loadExportCompanies } from './actions';

export const initialState = {
  exportCompanyError: false,
  exportCompanySuccess: false,
};

/* eslint-disable default-case, no-param-reassign */
const companiesReducer = handleActions(
  {
    [loadExportCompanies]: produce((draft) => {
      draft.exportCompanyError = false;
      draft.exportCompanySuccess = false;
      return draft;
    }),
    [loadedExportCompanies]: produce((draft) => {
      draft.exportCompanySuccess = true;
      draft.exportCompanyError = false;
    }),
    [errorExportCompanies]: produce((draft) => {
      draft.exportCompanySuccess = false;
      draft.exportCompanyError = true;
      return draft;
    }),
    [closeExportCompanies]: produce((draft) => {
      draft.exportCompanyError = false;
      draft.exportCompanySuccess = false;
      return draft;
    }),
  },
  initialState,
);

export default companiesReducer;
