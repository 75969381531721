import { createActions } from 'redux-actions';

export const { loadSuggestedCompanies, loadedSuggestedCompanies, errorSuggestedCompanies } = createActions(
  'LOAD_SUGGESTED_COMPANIES',
  'LOADED_SUGGESTED_COMPANIES',
  'ERROR_SUGGESTED_COMPANIES',
);

export const { loadPublishSuggestedCompany, loadedPublishSuggestedCompany, errorPublishSuggestedCompany } =
  createActions(
    'LOAD_PUBLISH_SUGGESTED_COMPANY',
    'LOADED_PUBLISH_SUGGESTED_COMPANY',
    'ERROR_PUBLISH_SUGGESTED_COMPANY',
  );

export const { loadSkipSuggestedCompany, loadedSkipSuggestedCompany, errorSkipSuggestedCompany } = createActions(
  'LOAD_SKIP_SUGGESTED_COMPANY',
  'LOADED_SKIP_SUGGESTED_COMPANY',
  'ERROR_SKIP_SUGGESTED_COMPANY',
);

export const {
  loadAutoSyncSuggestedCompaniesCompanies,
  loadedAutoSyncSuggestedCompanies,
  errorAutoSyncSuggestedCompanies,
} = createActions(
  'LOAD_AUTO_SYNC_SUGGESTED_COMPANIES',
  'LOADED_AUTO_SYNC_SUGGESTED_COMPANIES',
  'ERROR_AUTO_SYNC_SUGGESTED_COMPANIES',
);

export const { loadSuggestedBannerClicked, loadSuggestedBannerClosed } = createActions(
  'LOAD_SUGGESTED_BANNER_CLICKED',
  'LOAD_SUGGESTED_BANNER_CLOSED',
);
