import React from 'react';
import PropTypes from 'prop-types';
import { MessageBlock, Box } from '@getro/rombo';
import UserSchema from 'schemas/user';

const Success = ({ invitedUser, onDismissClick }) => (
  <Box>
    <MessageBlock
      type="success"
      title={`${invitedUser ? invitedUser.firstName : 'Member'} is now a part of our network`}
      content="Thank you for introducing another exceptional person to our network."
      buttonText="Ok"
      onClick={onDismissClick}
    />
  </Box>
);

Success.propTypes = {
  invitedUser: UserSchema.isRequired,
  onDismissClick: PropTypes.func.isRequired,
};

export default Success;
