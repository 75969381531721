import { createActions } from 'redux-actions';

export const { loadSubscriptions, loadedSubscriptions, errorSubscriptions } = createActions(
  'LOAD_SUBSCRIPTIONS',
  'LOADED_SUBSCRIPTIONS',
  'ERROR_SUBSCRIPTIONS',
);

export const { loadSubscribeAll, loadedSubscribeAll, errorSubscribeAll } = createActions(
  'LOAD_SUBSCRIBE_ALL',
  'LOADED_SUBSCRIBE_ALL',
  'ERROR_SUBSCRIBE_ALL',
);

export const { loadUnsubscribeAll, loadedUnsubscribeAll, errorUnsubscribeAll } = createActions(
  'LOAD_UNSUBSCRIBE_ALL',
  'LOADED_UNSUBSCRIBE_ALL',
  'ERROR_UNSUBSCRIBE_ALL',
);

export const { loadSetAutoSubscribe, loadedSetAutoSubscribe, errorSetAutoSubscribe } = createActions(
  'LOAD_SET_AUTO_SUBSCRIBE',
  'LOADED_SET_AUTO_SUBSCRIBE',
  'ERROR_SET_AUTO_SUBSCRIBE',
);

export const { loadSetDailyAlerts, loadedSetDailyAlerts, errorSetDailyAlerts } = createActions(
  'LOAD_SET_DAILY_ALERTS',
  'LOADED_SET_DAILY_ALERTS',
  'ERROR_SET_DAILY_ALERTS',
);

export const { toggleBoardSubscription, toggleBoardSubscriptionComplete } = createActions(
  'TOGGLE_BOARD_SUBSCRIPTION',
  'TOGGLE_BOARD_SUBSCRIPTION_COMPLETE',
);

export const { setShowDeactivateConfirmation } = createActions('SET_SHOW_DEACTIVATE_CONFIRMATION');
