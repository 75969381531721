import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@getro/rombo';
import { Flex, Text, Link, Card } from 'rebass/styled-components';

const EMPTY_MESSAGE = 'None specified';

export const JobRules = ({ isLoading, titles, keywords, locations }) => {
  if (isLoading) {
    return <Loader />;
  }
  const renderListItem = (text) => (
    <Text as="li" key={text} display="block">
      {text}
    </Text>
  );
  return (
    <Flex flexDirection="column">
      <Text pb={3}>
        These rules help you control which jobs are shown on your network.{' '}
        <Link
          variant="link"
          target="_blank"
          href="https://help.getro.com/support/solutions/articles/65000176573-curating-your-job-board-with-job-rules"
        >
          Learn more
        </Link>
        .
      </Text>
      <Text mt={2} color="text.dark">
        Job titles
      </Text>
      <Text pt={3} pb={2}>
        Show jobs with one of these keywords in their title
      </Text>
      <Card as="ul" tx="card" variant="threeUp" width={[1]}>
        {titles && titles.map((item) => renderListItem(item))}
        {!titles && renderListItem(EMPTY_MESSAGE)}
      </Card>
      <Text pt={3} pb={2}>
        Hide jobs with one of these keywords in their title
      </Text>
      <Card as="ul" tx="card" variant="threeUp" width={[1]}>
        {keywords && keywords.map((item) => renderListItem(item))}
        {!keywords && renderListItem(EMPTY_MESSAGE)}
      </Card>
      <Text mt={2} pt={4} color="text.dark">
        Job locations
      </Text>
      <Text pt={3} pb={2}>
        Only show jobs in one of these locations
      </Text>
      <Card as="ul" tx="card" variant="threeUp" width={[1]}>
        {locations && locations.map((item) => renderListItem(item))}
        {!locations && renderListItem(EMPTY_MESSAGE)}
      </Card>
    </Flex>
  );
};

JobRules.propTypes = {
  isLoading: PropTypes.bool,
  titles: PropTypes.array,
  keywords: PropTypes.array,
  locations: PropTypes.array,
};

JobRules.defaultProps = {
  isLoading: false,
  titles: null,
  keywords: null,
  locations: null,
};
