import React from 'react';
import PropTypes from 'prop-types';

const PercentageChange = ({ change }) => {
  let className = 'AnalyticsTable__cell--number';

  if (!change) {
    return <div className={className}>-</div>;
  }

  if (change > 0) {
    className += ' AnalyticsTable__cell--number-positive';
  }

  if (change < 0) {
    className += ' AnalyticsTable__cell--number-negative';
  }

  return <div className={className}>{change.toFixed(2)}%</div>;
};

PercentageChange.propTypes = {
  change: PropTypes.number,
};

PercentageChange.defaultProps = {
  change: null,
};

export default PercentageChange;
