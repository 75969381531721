import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { FormInput, FormSelect } from '@getro/rombo';
import { Field, Form, Formik } from 'formik';
import { monthOptions } from './date';
import { AutoSubmit } from '../autoSubmit';

export const PeriodSelector = ({ selected, onSelect, bottomText }) => {
  const [errorText, setErrorText] = useState(false);
  const initialValues = {
    fromMonth: monthOptions()[0],
    fromYear: 2010,
    toMonth: monthOptions()[0],
    toYear: 2020,
  };

  const onSubmit = (values) => {
    const { fromYear, toYear } = values;
    const numericFromYear = fromYear ? parseInt(fromYear, 10) : 0;
    const numericToYear = fromYear ? parseInt(toYear, 10) : 0;

    setErrorText(false);
    if (
      !numericFromYear ||
      numericFromYear < 1 ||
      !numericToYear ||
      numericToYear < 1 ||
      numericFromYear > numericToYear ||
      (numericFromYear === numericToYear && values.fromMonth?.value > values.toMonth?.value) ||
      numericFromYear < 1000 ||
      numericToYear < 1000
    ) {
      setErrorText(true);
    } else {
      onSelect(values);
    }
  };

  return (
    <Box
      sx={{
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Flex flexDirection="column">
        <Formik initialValues={selected || initialValues} onSubmit={onSubmit}>
          <Form>
            <AutoSubmit />
            <Flex alignItems="center" sx={{ gap: '8px' }}>
              <Text flex="0 0 auto" width="40px" fontSize="14px" color="text.subtle">
                From
              </Text>
              <Field
                name="fromMonth"
                component={FormSelect}
                size="small"
                options={monthOptions()}
                containerProps={{ flex: 1 }}
              />
              <Field
                name="fromYear"
                component={FormInput}
                size="small"
                placeholder="2010"
                containerProps={{ flex: 1 }}
              />
            </Flex>
            <Flex mt="8px" alignItems="center" sx={{ gap: '8px' }}>
              <Text flex="0 0 auto" width="40px" fontSize="14px" color="text.subtle">
                To
              </Text>
              <Field
                name="toMonth"
                component={FormSelect}
                size="small"
                options={monthOptions()}
                containerProps={{ flex: 1 }}
              />
              <Field name="toYear" component={FormInput} size="small" placeholder="2020" containerProps={{ flex: 1 }} />
            </Flex>
          </Form>
        </Formik>
        {bottomText && (
          <Box
            as="p"
            sx={{
              pt: '8px',
            }}
            color="text.subtle"
            fontSize="12px"
            fontStyle="italic"
          >
            {bottomText}
          </Box>
        )}
        {errorText && (
          <Box
            as="p"
            sx={{
              pt: '8px',
            }}
            color="text.error"
            fontSize="14px"
          >
            {`Enter a valid year for "From" and "To" dates.`}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

PeriodSelector.propTypes = {
  selected: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  bottomText: PropTypes.node,
};

PeriodSelector.defaultProps = {
  bottomText: null,
  selected: null,
};
