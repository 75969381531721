import React from 'react';
import { Box, Text, Flex, Link } from 'rebass/styled-components';
import { Logo, ProfilePicture } from '@getro/rombo';
import ContactsSharedListSchema from '../../../schemas/contactsSharedList';
import HowItWorks from '../howItWorks';
import SubscribeToSlackButton from '../subscribeToSlackButton';
import SubscribeViaEmailButton from '../subscribeViaEmailButton';
import { trackEvent } from '../../../helpers/analytics';
import PointOfContact from '../pointOfContact';

const FixedHeader = ({ listInfo }) => {
  const { showPointOfContact } = listInfo || {};

  const trackPoweredByLinkClick = () => {
    trackEvent('contacts:shared_list:powered_by_click', {
      list_slug: listInfo.slug,
      collection_id: listInfo.collection.id,
      origin: 'header',
    });
  };

  return (
    <Flex flexDirection="column">
      <Flex flexDirection={['column-reverse', 'row', 'row']} alignItems={['flex-start', 'center', 'center']}>
        <Box flexGrow="1" mt={['32px', 0, 0]}>
          <Text fontSize="28px" fontWeight="bold" color="text.dark">
            {listInfo.name}
          </Text>
        </Box>
        <Flex
          sx={{
            columnGap: '16px',
            width: ['100%', 'unset', 'unset'],
            flexDirection: ['row-reverse', 'row', 'row'],
          }}
          alignItems="baseline"
          alignContent="baseline"
        >
          <HowItWorks listInfo={listInfo} />
          <Link
            href="https://www.getro.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ flexGrow: [1, 'unset', 'unset'] }}
            onClick={trackPoweredByLinkClick}
          >
            <Logo color="primary" width="96px" />
          </Link>
        </Flex>
      </Flex>
      {listInfo.description && (
        <Box mt="8px" sx={{ fontSize: '14px' }}>
          {listInfo.description.split('\n').map((item) => (
            <Text key={item}>{item}</Text>
          ))}
        </Box>
      )}
      <Flex
        flexGrow="1"
        alignItems={['flex-start', 'flex-start', 'center']}
        mt="16px"
        sx={{ gap: '8px', display: 'flex' }}
        flexDirection={['column', 'column', 'row']}
      >
        <Flex flexDirection="row" alignItems="center" flexGrow={[1, 0, 0]}>
          <Box flex="0 0 auto" width={['40px', '40px', '48px']} height={['40px', '40px', '48px']} mr="8px">
            <ProfilePicture variant="square" name={listInfo.collection?.name} imageUrl={listInfo.collection?.logoUrl} />
          </Box>
          <Text fontSize="14px">
            {'A list by '}
            <Text as="span" fontWeight="medium">
              {listInfo.collection?.name}
            </Text>
          </Text>
        </Flex>
        <Flex
          flexGrow={[1, 0, 0]}
          flexDirection={['column', 'row', 'row']}
          sx={{ gap: '8px', width: ['100%', 'auto', 'auto'] }}
          mt={['16px', 0, 0]}
          ml={[0, '8px', '8px']}
          alignItems="center"
        >
          <SubscribeToSlackButton sx={{ width: ['100%', 'auto', 'auto'] }} listInfo={listInfo} />
          <SubscribeViaEmailButton
            mr={[0, '8px', '8px']}
            sx={{ width: ['100%', 'auto', 'auto'] }}
            listInfo={listInfo}
          />
          {showPointOfContact && (
            <Box fontSize="14px" height={['32px', 'auto', 'auto']} lineHeight={['32px', 'auto', 'auto']}>
              <PointOfContact listInfo={listInfo} />
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

FixedHeader.propTypes = {
  listInfo: ContactsSharedListSchema,
};

FixedHeader.defaultProps = {
  listInfo: null,
};

export default FixedHeader;
