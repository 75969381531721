import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Button, Modal } from '@getro/rombo';

const CompensationModal = ({ onCancel, submitting }) => (
  <Box>
    <Modal
      title="Post job without compensation?"
      onCancel={onCancel}
      actions={
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button loading={submitting} mr={1} fontSize={1} variant="secondary" type="submit">
            Post job
          </Button>
          <Button ml={1} fontSize={1} onClick={onCancel}>
            Add compensation
          </Button>
        </Flex>
      }
    >
      <Text>We highly recommend adding compensation for jobs in the US or the EU.</Text>
      <Box as="ul" p="24px">
        <Text as="li">Job posts that include salary range can get up to 3X engagement</Text>
        <Text as="li">It is a legal requirement in some US states and European countries</Text>
      </Box>
      <Text
        as="a"
        href="https://help.getro.com/support/solutions/articles/65000183125-best-practice-salary-information-for-posted-roles"
        target="__blank"
      >
        Learn More
      </Text>
    </Modal>
  </Box>
);

CompensationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

CompensationModal.defaultProps = {
  submitting: false,
};

export default CompensationModal;
