import { takeLatest, call, put, select } from 'redux-saga/effects';
import { trackEvent } from 'helpers/analytics';
import { request } from 'api';
import {
  loadSaveInvitedMemberInfo,
  loadedSaveInvitedMemberInfo,
  loadSaveInvitedMemberInvite,
  loadedSaveInvitedMemberInvite,
  errorSaveInvitedMemberInvite,
  loadSaveInvitedMemberVouch,
  loadedSaveInvitedMemberVouch,
  errorSaveInvitedMemberVouch,
} from './actions';
import { inviteMemberInfoSelector } from './selectors';

function* saveInvitedMemberInfo({ payload }) {
  const { activeNetworkId, values } = payload;

  yield call(trackEvent, 'invite_member:finish_step', {
    step: 1,
    collection_id: activeNetworkId,
  });

  yield put(loadedSaveInvitedMemberInfo(values));
}

function* saveInvitedMemberInvite({ payload }) {
  const { activeNetworkId } = payload;
  const inviteMemberInfo = yield select(inviteMemberInfoSelector);

  try {
    const { company, ...otherMemberInfoAttributes } = inviteMemberInfo;
    if (company && !!company.value) {
      otherMemberInfoAttributes.organizationId = company.value;
    }

    const response = yield call(request, {
      url: '/users/invite',
      options: {
        method: 'post',
        data: otherMemberInfoAttributes,
        params: {
          collectionId: activeNetworkId,
        },
      },
    });

    yield call(trackEvent, 'invite_member:completed', {
      with_linkedin_url: inviteMemberInfo.linkedinUrl.length > 0,
      collection_id: activeNetworkId,
      verified: inviteMemberInfo.verified,
      talent: !inviteMemberInfo.roles.length,
      organization_manager: inviteMemberInfo.roles.includes('employer_admin'),
      collection_manager: inviteMemberInfo.roles.includes('network_admin'),
      collection_connector: inviteMemberInfo.roles.includes('ambassador'),
    });

    const { data } = response;
    yield put(loadedSaveInvitedMemberInvite(data));
  } catch (error) {
    yield put(errorSaveInvitedMemberInvite());
  }
}

function* saveInvitedMemberVouch({ payload }) {
  const { activeNetworkId, values } = payload;
  const inviteMemberInfo = yield select(inviteMemberInfoSelector);

  try {
    const { company, ...otherMemberInfoAttributes } = inviteMemberInfo;
    if (company && !!company.value) {
      otherMemberInfoAttributes.organizationId = company.value;
    }

    const response = yield call(request, {
      url: '/users/invite',
      options: {
        method: 'post',
        data: otherMemberInfoAttributes,
        params: {
          collectionId: activeNetworkId,
        },
      },
    });

    yield call(trackEvent, 'invite_member:completed', {
      with_linkedin_url: inviteMemberInfo.linkedinUrl.length > 0,
      collection_id: activeNetworkId,
      verified: inviteMemberInfo.verified,
      talent: !inviteMemberInfo.roles.length,
      organization_manager: inviteMemberInfo.roles.includes('employer_admin'),
      collection_manager: inviteMemberInfo.roles.includes('network_admin'),
      collection_connector: inviteMemberInfo.roles.includes('ambassador'),
    });

    const { data: user } = response;
    yield put(loadedSaveInvitedMemberVouch(user));

    const { relationshipLevel, ...otherValues } = values;
    const params = {
      relationshipLevel: relationshipLevel.value,
      ...otherValues,
    };

    const vouchResponse = yield call(request, {
      url: `/users/${user.id}/vouch?collection_id=${activeNetworkId}`,
      options: {
        method: 'post',
        data: params,
      },
    });

    yield call(trackEvent, 'vouch', {
      medium: 'web',
      origin: 'invite_member',
      collection_id: activeNetworkId,
      vouch_id: vouchResponse.data.id,
      relationship_level: values.relationshipLevel.value,
    });

    if (inviteMemberInfo.verified) {
      yield call(request, {
        url: `/users/${user.id}/verify`,
        options: {
          method: 'post',
          data: { collection_id: activeNetworkId },
        },
      });

      yield call(trackEvent, 'verify', {
        medium: 'web',
        origin: 'invite_member',
        collection_id: activeNetworkId,
      });
    }
  } catch (error) {
    yield put(errorSaveInvitedMemberVouch());
  }
}

export default function* membersInviteSaga() {
  yield takeLatest(loadSaveInvitedMemberInfo().type, saveInvitedMemberInfo);
  yield takeLatest(loadSaveInvitedMemberInvite().type, saveInvitedMemberInvite);
  yield takeLatest(loadSaveInvitedMemberVouch().type, saveInvitedMemberVouch);
}
