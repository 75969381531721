import React from 'react';

import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { ScoreDetails } from '../contactCard/scoreDetails';
import { NoAtMatch } from '../contactCard/noAtMatch';
import InPortco from '../contactCard/inPortco';

export const MatchInfo = ({ match, matchListInfo, scoreDetails }) => (
  <Box pb="16px">
    {match.deleted ? (
      <NoAtMatch
        positionVertical
        match={match}
        matchListId={matchListInfo.id}
        jobId={matchListInfo.job.id}
        organizationId={matchListInfo.organization.id}
      />
    ) : (
      <>
        <Flex pb="12px" alignItems="center">
          <Text ml="8px" fontSize="14px" fontWeight="medium">
            {`Match details for ${matchListInfo.job.title} at ${matchListInfo.organization.name}`}
          </Text>
        </Flex>
        <ScoreDetails scoreDetails={scoreDetails} />
        {match.contact.inPortco && <InPortco />}
      </>
    )}
  </Box>
);

MatchInfo.propTypes = {
  scoreDetails: PropTypes.object,
  match: PropTypes.object,
  matchListInfo: PropTypes.object,
};

MatchInfo.defaultProps = {
  match: {},
  matchListInfo: {},
  scoreDetails: {},
};
