import { Button } from '@getro/rombo';
import { Check, Contact, Mail, Wand2, X } from 'lucide-react';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Image, Text } from 'rebass/styled-components';

import { trackPage } from 'helpers/analytics';
import { setNetworks } from '../../actions/networksActions';
import ContactsImportSuccessPng from '../../assets/contacts-import-success.png';
import { activeNetworkSelector } from '../../redux/selectors';
import { useLazyGetUserQuery } from '../../services/user';

export const ContactsImportSuccess = () => {
  const [getUser] = useLazyGetUserQuery();
  const activeNetwork = useSelector(activeNetworkSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const { data } = await getUser();

        dispatch(setNetworks(data));
      } catch {
        // Do nothing
      }
    };

    trackPage();

    getUserData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        as={NavLink}
        to={`/networks/${activeNetwork.slug}/contacts`}
        p="16px"
        color="neutral.500"
        sx={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
      >
        <Box as={X} width="32px" height="32px" aria-hidden="true" strokeWidth="1.5" />
      </Box>
      <Box mx="auto" my="0" px="20px" pt="80px" maxWidth="640px">
        <Image src={ContactsImportSuccessPng} alt="Get the most out of your team’s network" />
        <Box mx="auto" px="40px" maxWidth="440px">
          <Text mt="56px" fontSize="18px" fontWeight="semibold" color="text.dark">
            CSV received, enrichment in progress
          </Text>
          <Flex mt="32px" alignItems="center" color="green.600" sx={{ gap: '16px' }}>
            <Box as={Check} flex="0 0 auto" width="20px" height="20px" aria-hidden="true" />
            <Text fontSize="14px" fontWeight="medium" color="text.dark">
              CSV uploaded
            </Text>
          </Flex>
          <Box ml="8px" my="8px" height="16px" sx={{ borderLeft: 'solid 2px', borderLeftColor: 'green.600' }} />
          <Flex alignItems="center" color="neutral.400" sx={{ gap: '16px' }}>
            <Box as={Contact} flex="0 0 auto" width="24px" height="24px" aria-hidden="true" />
            <Box>
              <Text fontSize="14px" fontWeight="medium" color="text.dark">
                Enriching professional profiles
              </Text>
              <Text fontSize="14px" color="text.main">
                Contacts will be shown as they are ready. It can take up to a few hours.
              </Text>
            </Box>
          </Flex>
          <Box ml="8px" my="8px" height="16px" sx={{ borderLeft: 'solid 2px', borderLeftColor: 'border.subtle' }} />
          <Flex alignItems="center" color="neutral.400" sx={{ gap: '16px' }}>
            <Box as={Wand2} flex="0 0 auto" width="24px" height="24px" aria-hidden="true" />
            <Box>
              <Text fontSize="14px" fontWeight="medium" color="text.dark">
                AI Matches
              </Text>
              <Text fontSize="14px" color="text.main">
                Our AI will automatically match new contacts with open jobs at your companies.
              </Text>
            </Box>
          </Flex>
          <Box ml="8px" my="8px" height="16px" sx={{ borderLeft: 'solid 2px', borderLeftColor: 'border.subtle' }} />
          <Flex alignItems="center" color="neutral.400" sx={{ gap: '16px' }}>
            <Box as={Mail} flex="0 0 auto" width="24px" height="24px" aria-hidden="true" />
            <Box>
              <Text fontSize="14px" fontWeight="medium" color="text.dark">
                Email summary
              </Text>
              <Text fontSize="14px" color="text.main">
                We’ll email you when everything is ready.
              </Text>
            </Box>
          </Flex>

          <Flex mt="32px" alignItems="center" sx={{ gap: '16px' }}>
            <Button as={NavLink} to={`/networks/${activeNetwork.slug}/contacts`} variant="accent" width="fit-content">
              Go to contacts
            </Button>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
