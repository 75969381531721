import React, { useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { ChevronLeft } from 'lucide-react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Flex, Image, Link, Text } from 'rebass/styled-components';
import { useTheme } from 'styled-components';
import qs from 'query-string';

import ChromeExtensionLogo from '../../assets/chrome-extension-logo.png';
import IconEffortlessSearch from '../../assets/icon-effortless-search-error.svg';
import { FocusLayout } from '../../components/layout/focus';
import { trackEvent } from '../../helpers/analytics';
import { activeNetworkSelector } from '../../redux/selectors';
import { checkExtensionAvailability } from '../../services/chromeExtension';

export const ContactsAddSingleContactError = () => {
  const [isExtensionInitialized, setIsExtensionInitialized] = useState(false);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const activeNetwork = useSelector(activeNetworkSelector);
  const { search } = useLocation();
  const { colors } = useTheme();

  const { linkedinUrl } = useMemo(() => qs.parse(search), [search]);

  useEffect(() => {
    if (activeNetwork) {
      trackEvent('contacts:add_single_contact:error', {
        collection_id: activeNetwork.id,
        error_code: 'enrich_contact_failed',
      });
    }
  }, [activeNetwork]);

  useEffect(() => {
    const checkExtension = async () => {
      try {
        await checkExtensionAvailability();
        setIsExtensionInitialized(true);
        setIsExtensionInstalled(true);
      } catch {
        // Do nothing
      }
    };

    checkExtension();
  }, []);

  return (
    <FocusLayout title="Add a single contact" backUrl={`/networks/${activeNetwork.slug}/contacts`}>
      <Box pb="5" maxWidth="600px" mx="auto">
        {isExtensionInitialized ? (
          <>
            <Flex>
              <Image mt="2" src={IconEffortlessSearch} width="80px" height="80px" alt="Search" flex="0 0 auto" />
              <Box ml="2">
                <Text fontSize="4" fontWeight="semibold" color="text.dark">
                  We couldn’t add this contact
                </Text>
                <Text mt="3">
                  {`We did not add this contact because we couldn’t enrich their information using this LinkedIn URL: `}
                  <Link
                    href={linkedinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    fontWeight="medium"
                    color="text.main"
                    display="inline"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {`${linkedinUrl}`}
                  </Link>
                  .
                </Text>
              </Box>
            </Flex>
            {isExtensionInstalled && (
              <Text mt="56px">If the URL is correct, you can still add them using the Getro Chrome extension:</Text>
            )}
            {!isExtensionInstalled && (
              <Text mt="56px">
                If the URL is correct, you can still add them using the Getro Chrome extension in 2 easy steps:
              </Text>
            )}
            {isExtensionInstalled && (
              <Text mt="3">
                {'Go to '}
                <Link
                  href={linkedinUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  fontWeight="medium"
                  color="text.main"
                  display="inline"
                  sx={{ textDecoration: 'underline' }}
                >
                  {`${linkedinUrl}`}
                </Link>
                {' and click the "Add to Getro" button.'}
              </Text>
            )}
            {!isExtensionInstalled && (
              <Box as="ul" m="0" p="0">
                <Flex as="li" mt="4">
                  <Flex
                    flex="0 0 auto"
                    width="32px"
                    height="32px"
                    alignItems="center"
                    justifyContent="center"
                    bg="purple.100"
                    color="purple.400"
                    mr="3"
                    sx={{ borderRadius: 'circle' }}
                  >
                    1
                  </Flex>
                  <Box>
                    <Text fontWeight="medium" color="text.dark">
                      Install the Getro Chrome extenstion
                    </Text>
                    <Text mt="2" fontSize="1">
                      With it you can add contacts (individually or import all) and manage list and tag information
                      directly from LinkedIn.
                    </Text>
                    <Flex alignItems="center" mt="3">
                      <Image
                        src={ChromeExtensionLogo}
                        alt="Install Getro Chrome extension"
                        mr="2"
                        width="25px"
                        height="25px"
                        flex="0 0 auto"
                      />
                      <Link
                        href="https://chrome.google.com/webstore/detail/getro/ibgcbgjnpaedoomkhclbjgnhnkmeplee"
                        fontSize="1"
                        fontWeight="medium"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          trackEvent('contacts:install_chrome_extension', {
                            collection_id: activeNetwork.id,
                            origin: 'add_single_contact',
                          });
                        }}
                      >
                        Install Getro Chrome extension
                      </Link>
                    </Flex>
                  </Box>
                </Flex>
                <Flex as="li" mt="4">
                  <Flex
                    flex="0 0 auto"
                    width="32px"
                    height="32px"
                    alignItems="center"
                    justifyContent="center"
                    bg="purple.100"
                    color="purple.400"
                    mr="3"
                    sx={{ borderRadius: 'circle' }}
                  >
                    2
                  </Flex>
                  <Box>
                    <Text fontWeight="medium" color="text.dark">
                      Add this contact directly from LinkedIn
                    </Text>
                    <Text mt="2" fontSize="1">
                      {`Go to `}
                      <Link
                        as="span"
                        href={linkedinUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        fontWeight="medium"
                        color="text.main"
                        sx={{ textDecoration: 'underline' }}
                      >
                        {`${linkedinUrl}`}
                      </Link>
                      {` and click the “Add to Getro” button.`}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            )}
            <Box
              as={NavLink}
              to={`/networks/${activeNetwork.slug}/contacts`}
              mt="56px"
              display="block"
              fontSize="1"
              color="text.subtle"
              sx={{ textDecoration: 'none' }}
            >
              <Flex alignItems="center">
                <Box as={ChevronLeft} width="16px" height="16px" aria-hidden="true" />
                <Text>Go back</Text>
              </Flex>
            </Box>
          </>
        ) : (
          <Box mt="4">
            <ContentLoader
              speed={2}
              width="100%"
              height="100%"
              viewBox="0 0 986 985"
              backgroundColor={colors.neutral[50]}
              foregroundColor={colors.neutral[20]}
            >
              <rect x="0" y="0" rx="0" ry="0" width="585" height="34" />
              <rect x="0" y="50" rx="0" ry="0" width="585" height="11" />
              <rect x="0" y="70" rx="0" ry="0" width="585" height="11" />
              <rect x="0" y="90" rx="0" ry="0" width="585" height="11" />
            </ContentLoader>
          </Box>
        )}
      </Box>
    </FocusLayout>
  );
};
