import { SET_USER, REMOVE_USER, SET_USER_READY } from 'actions/userActions';
import * as Sentry from '@sentry/react';
import { addProtocolToUrl } from 'helpers/urlHelpers';
import { loadedSetAutoSubscribe, loadedSetDailyAlerts } from '../../pages/manageSubscriptions/actions';
import { UPDATE_TALENT_GROUP } from '../../actions/talentGroupActions';
import { loadedSubscribeToBoards } from '../../pages/subscribing/actions';
import { loadedUpdateUserProfile } from '../../actions/profileActions';

export const initialState = {
  avatarUrl: null,
  email: null,
  firstName: null,
  lastName: null,
  linkedinUrl: null,
  onboardingCompleted: null,
  ready: false,
};

const rehydrateUserData = (user) => {
  const { websiteUrl, linkedinUrl, twitterUrl, githubUrl, dribbbleUrl, ...otherUserProps } = user;

  return {
    websiteUrl: addProtocolToUrl(websiteUrl),
    linkedinUrl: addProtocolToUrl(linkedinUrl),
    twitterUrl: addProtocolToUrl(twitterUrl),
    githubUrl: addProtocolToUrl(githubUrl),
    dribbbleUrl: addProtocolToUrl(dribbbleUrl),
    ...otherUserProps,
  };
};

function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER: {
      const user = rehydrateUserData(payload);
      Sentry.configureScope((scope) => scope.setUser({ token: user.token, id: user.id }));
      return {
        ...state,
        ...user,
        ready: true,
      };
    }
    case SET_USER_READY:
      return {
        ...state,
        ready: !!payload,
      };
    case REMOVE_USER:
      Sentry.configureScope((scope) => scope.setUser(null));
      return { ...initialState, ready: true };
    case loadedSetAutoSubscribe().type: {
      const { hasAutoSubscribe, activeNetworkId } = payload;
      const { userTalentCollections, ...otherUserProps } = state;
      const mappedUserTalentCollections = userTalentCollections.map((utc) => {
        if (utc.collectionId === activeNetworkId) {
          const { membershipSetting } = utc;
          return {
            ...utc,
            membershipSetting: {
              ...membershipSetting,
              subscribeToAllBoards: hasAutoSubscribe,
            },
          };
        }

        return utc;
      });

      return {
        ...otherUserProps,
        userTalentCollections: mappedUserTalentCollections,
      };
    }
    case loadedSubscribeToBoards().type:
    case loadedSetDailyAlerts().type: {
      const { hasDailyAlerts, activeNetworkId } = payload;
      const { userTalentCollections, ...otherUserProps } = state;
      const mappedUserTalentCollections = userTalentCollections.map((utc) => {
        if (utc.collectionId === activeNetworkId) {
          const { membershipSetting } = utc;
          return {
            ...utc,
            membershipSetting: {
              ...membershipSetting,
              boardsDailyDigest: hasDailyAlerts,
            },
          };
        }
        return utc;
      });
      return {
        ...otherUserProps,
        userTalentCollections: mappedUserTalentCollections,
      };
    }
    case loadedUpdateUserProfile().type:
      return {
        ...state,
        ...payload.user,
        ready: true,
      };
    case UPDATE_TALENT_GROUP: {
      const { id, talentGroup } = payload;
      if (talentGroup.subscribed) {
        const { userTalentCollections, ...otherUserProps } = state;
        const mappedUserTalentCollections = userTalentCollections.map((utc) => {
          if (utc.collectionId === id) {
            const { membershipSetting } = utc;
            return {
              ...utc,
              membershipSetting: {
                ...membershipSetting,
                boardsDailyDigest: true,
              },
            };
          }

          return utc;
        });

        return {
          ...otherUserProps,
          userTalentCollections: mappedUserTalentCollections,
        };
      }

      return state;
    }
    default:
      return state;
  }
}

export default userReducer;
