import React from 'react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Loader } from '@getro/rombo';

export const LoadMore = ({ hasMore, isLoadingMore }) => {
  if (!hasMore || !isLoadingMore) {
    return null;
  }
  return (
    <Box
      data-testid="table-load-more"
      role="cell"
      textAlign="center"
      width={1}
      sx={{ position: 'relative', height: '80px', bottom: 0, width: '100%', left: 0, display: 'flex' }}
    >
      <Loader tx="dataTable" isLoading variant="loader" />
    </Box>
  );
};
LoadMore.propTypes = {
  hasMore: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
};

LoadMore.defaultProps = {
  hasMore: false,
  isLoadingMore: false,
};
