import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Panel } from '@getro/rombo';
import { Field, Form, Formik } from 'formik';
import { Box, Flex, Text } from 'rebass/styled-components';
import * as Yup from 'yup';

import { activeNetworkSelector } from '../../redux/selectors';
import download from '../../helpers/download';
import { FormCsvFile } from '../../components/molecules/formCsvFile';
import { ErrorListener } from './errorListener';

export const StepUploadCsv = ({ importData, fileName, setFileName, onNext }) => {
  const activeNetwork = useSelector(activeNetworkSelector);

  const formSchema = Yup.object().shape({
    contacts: Yup.array()
      .min(1, 'This is a required field.')
      .max(
        25000,
        <Text as="span">
          {'Currently you can only upload 25,000 contacts. Need to add more? '}
          <Text
            as="span"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              window.location.href = `mailto:product@getro.com?subject=Help me add contacts - ${activeNetwork.name}`;
            }}
          >
            Contact us
          </Text>
          .
        </Text>,
      ),
  });

  const onDownloadSampleClick = () => {
    const sample = [
      'LinkedIn URL,Email,Tags,Lists,Resume URL,Website URL',
      'https://www.linkedin.com/in/mavericreal/,maveric@getro.com,"Tag 1, Tag 2, Tag 3","List 1, List 2, List 3",https://example.com/resume.pdf,https://example.com',
      'https://www.linkedin.com/in/evanwalden/,evan@getro.com,"Tag 1, Tag 2","List 1, List 2",https://example.com/resume.pdf,https://example.com',
    ].join('\n');
    download('sample.csv', 'text/csv', sample);
  };

  const transformHeader = (header) => header.toLowerCase().replace(/\W/g, '_');

  const onFileLoaded = (data, _, setCsvError) => {
    const validColumns = ['linkedin'];

    if (
      !validColumns
        .map((column) => transformHeader(column))
        .filter((column) => {
          const result = data.find((item) =>
            Object.keys(item)
              .map((key) => transformHeader(key))
              .join('')
              .includes(column),
          ); // eslint-disable-line function-paren-newline

          return !!result;
        }).length
    ) {
      setCsvError(
        `We can’t find the required information to upload this file. Make sure that you have ${
          validColumns.length > 1 ? '' : 'a'
        } column${validColumns.length > 1 ? 's' : ''} with the following header${
          validColumns.length > 1 ? 's' : ''
        }: ${validColumns.join(', ')}`,
      );
    }
  };

  const onSubmit = ({ contacts }) => {
    const newContacts = contacts.map((row) => {
      const attrs = {};

      Object.keys(row).forEach((key) => {
        if (key.toLowerCase().includes('linkedin') && !key.toLowerCase().includes('tagline')) {
          attrs.linkedin_url = row[key];
        } else if (key.toLowerCase().includes('email')) {
          attrs.email = row[key];
        } else {
          attrs[transformHeader(key)] = row[key];
        }
      });

      return attrs;
    });

    onNext({ contacts: newContacts });
  };

  return (
    <Formik initialValues={importData} validationSchema={formSchema} onSubmit={onSubmit}>
      <Flex flexGrow="1">
        <Box as={Form} width="100%">
          <ErrorListener />
          <Panel
            p="0"
            sx={{ boxShadow: 'none' }}
            footer={
              <Flex justifyContent="flex-end" width="100%" mt="2">
                <Button type="submit">Preview contacts</Button>
              </Flex>
            }
          >
            <Field
              component={FormCsvFile}
              name="contacts"
              label="Select the CSV file to import"
              helpText={
                <Box>
                  <Text mt="2">
                    {'Download our '}
                    <Text
                      as="span"
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={onDownloadSampleClick}
                    >
                      CSV template
                    </Text>
                    {' for guidance.'}
                  </Text>
                </Box>
              }
              fileName={fileName}
              setFileName={setFileName}
              onFileLoaded={onFileLoaded}
            />
          </Panel>
        </Box>
      </Flex>
    </Formik>
  );
};

StepUploadCsv.propTypes = {
  importData: PropTypes.shape({
    contacts: PropTypes.arrayOf(PropTypes.object),
    fileName: PropTypes.string,
  }).isRequired,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
  onNext: PropTypes.func.isRequired,
};

StepUploadCsv.defaultProps = {
  fileName: '',
  setFileName: () => {},
};
