import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormInput, FormTextarea, Modal } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { DeleteSharedListModal } from '../../atoms/deleteSharedListModal';

export const SharedListsModal = ({
  list,
  isLoading,
  onCreateSharedList,
  onEditSharedList,
  onDeleteSharedList,
  onClose,
}) => {
  const formRef = useRef();
  const title = onCreateSharedList ? 'Create a list' : 'Edit list';
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(true);

  const formSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const onSubmit = (values) => {
    if (onCreateSharedList) {
      onCreateSharedList(values);
    } else if (onEditSharedList) {
      onEditSharedList(values);
    }
  };

  const onCloseModal = () => {
    setShowDeleteWarningModal(false);
    onClose();
  };

  return (
    <>
      <Modal
        title={title}
        onCancel={onCloseModal}
        isOpen={showEditModal}
        actions={
          <Box>
            <Flex justifyContent="space-between">
              {onEditSharedList && (
                <>
                  <Button
                    data-testid="button-delete"
                    onClick={() => {
                      setShowEditModal(false);
                      setShowDeleteWarningModal(true);
                    }}
                    variant="danger"
                  >
                    Delete
                  </Button>
                </>
              )}
              <Flex sx={{ gap: '16px' }}>
                <Button onClick={onCloseModal} variant="secondary">
                  Cancel
                </Button>
                {onCreateSharedList && (
                  <Button loading={isLoading} onClick={() => formRef.current.handleSubmit()}>
                    Create list
                  </Button>
                )}
                {onEditSharedList && (
                  <Button data-testid="button-save" loading={isLoading} onClick={() => formRef.current.handleSubmit()}>
                    Save
                  </Button>
                )}
              </Flex>
            </Flex>
          </Box>
        }
      >
        <>
          <Box>
            <Formik
              innerRef={formRef}
              initialValues={{
                name: list.name || '',
                description: list.description || '',
              }}
              validationSchema={formSchema}
              enableReinitialize
              onSubmit={onSubmit}
            >
              <Form>
                <Field component={FormInput} name="name" label="Name*" placeholder="Enter a name for your list" />
                <Field
                  component={FormTextarea}
                  name="description"
                  label="Description"
                  placeholder="Add a short description to explain the purpose of the list"
                  containerProps={{ my: 3 }}
                />
              </Form>
            </Formik>
          </Box>
        </>
      </Modal>
      {showDeleteWarningModal && (
        <DeleteSharedListModal
          list={list}
          isLoading={isLoading}
          onCloseModal={() => onCloseModal()}
          onDeleteSharedList={onDeleteSharedList}
        />
      )}
    </>
  );
};

SharedListsModal.propTypes = {
  list: PropTypes.object,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateSharedList: PropTypes.func,
  onEditSharedList: PropTypes.func,
  onDeleteSharedList: PropTypes.func,
};

SharedListsModal.defaultProps = {
  list: {},
  isLoading: false,
  onClose: () => {},
  onCreateSharedList: null,
  onEditSharedList: null,
  onDeleteSharedList: null,
};
