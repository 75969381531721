import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, WorkPreferences, ProfileDescription, Tag, Checkbox } from '@getro/rombo';
import { PickerOverlay } from 'filestack-react';

import { Flex, Box as FlexBox, Text } from 'rebass/styled-components';
import { Modal as SemanticModal } from 'semantic-ui-react';
import strings from '../../../strings';
import NetworkSchema from '../../../schemas/network';
import { getUserDisplayName, getMemberDisplaySource } from '../../../helpers/users';
import MakeIntroModal from '../../molecules/MakeIntroModal';
import RequestIntroModal from '../../molecules/RequestIntroModal/RequestIntroModal';
import { parsePreferredJobTypes, parseUserProfile } from '../../../helpers/userProfileHelpers';
import hexToRgba from '../../../helpers/hexToRgba';
import AddToBoards from '../../molecules/AddToBoards';
import { isLoadingApi } from '../../../pages/Members/helpers';
import GroupSchema from '../../../schemas/group';
import VouchesReceived from '../../molecules/VouchesReceived';

const MemberModal = ({
  isTalentNetworkActive,
  employmentTypeOptions,
  member,
  activeNetwork,
  updateIntroduction,
  seniorityOptions,
  isMentorshipFeatureActive,
  onModalClose,
  networkTalentGroups,
  onCreateAndAddMemberToList,
  onAddRemoveMemberToList,
  creating,
  addToBoardError,
  memberDetails,
  loadingMemberDetails,
}) => {
  const [currentMember, setCurrentMember] = useState(member);
  const { introductionRequested, introduction } = currentMember;
  const { isManager: isNetworkManager } = activeNetwork;
  const isOm = !!Object.keys(activeNetwork.isOrganizationManager).length;
  const canRequestIntroduction = isOm || isNetworkManager;

  useEffect(() => {
    const { id } = memberDetails;

    if (id === member.id) {
      setCurrentMember(memberDetails);
    } else {
      setCurrentMember(member);
    }
  }, [member, memberDetails]);

  const onBoardSelectionChanged = (e) => {
    const { id } = member;
    const { value, checked } = e.target;
    const talentGroup = networkTalentGroups.find((g) => g.value === parseInt(value, 10));
    onAddRemoveMemberToList({
      memberId: id,
      talentGroup,
      checked,
    });
  };

  return (
    <SemanticModal className="MemberModal" open size="small" onClose={onModalClose} closeOnDimmerClick>
      <SemanticModal.Content>
        <ProfileDescription
          Picker={PickerOverlay}
          filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
          getUserDisplayName={getUserDisplayName}
          get={() => {}}
          handleException={() => {}}
          user={parseUserProfile(currentMember)}
          userProfile={parseUserProfile(currentMember)}
          onUserUpdate={() => {}}
          talentNetworkActive={isTalentNetworkActive}
          canEdit={false}
        />
        <p className="MemberModal__source">{getMemberDisplaySource(currentMember)}</p>
        {isTalentNetworkActive && (
          <>
            <WorkPreferences
              isLoading={loadingMemberDetails}
              onEditClick={null}
              get={() => {}}
              handleException={() => {}}
              onUserUpdate={() => {}}
              seniorityOptions={seniorityOptions}
              isMentorshipFeatureActive={isMentorshipFeatureActive}
              usWorkAuthorizationStatus={currentMember.usWorkAuthorizationStatus}
              workLocations={currentMember.workLocations}
              preferredJobTypes={parsePreferredJobTypes(currentMember)}
              employmentTypeOptions={employmentTypeOptions}
              user={currentMember}
              userProfile={currentMember}
              talentNetworkActive={isTalentNetworkActive}
              canEdit={false}
            />
          </>
        )}
        <div className="MemberModal__main">
          <Box title="Lists">
            {member.talentGroups.map((g) => (
              <Tag sx={{ background: hexToRgba(g.color, '0.15'), color: g.color }} key={g.id}>
                {g.name}
              </Tag>
            ))}
            {member.talentGroups.length === 0 && <Text>This member has not been added to any list yet.</Text>}
          </Box>

          <VouchesReceived
            fetching={loadingMemberDetails}
            relationshipLevelOptions={strings.vouch.relationshipLevelOptions}
            scoreOptions={strings.vouch.scoreOptions}
            getUserDisplayName={getUserDisplayName}
            user={currentMember}
          />
        </div>
      </SemanticModal.Content>
      <SemanticModal.Actions>
        <Flex width={[1]} justifyContent={['flex-end']}>
          {isNetworkManager && (
            <FlexBox mr={[2]}>
              <MakeIntroModal member={currentMember} activeNetwork={activeNetwork} />
            </FlexBox>
          )}

          {introductionRequested && introduction && (
            <FlexBox ml={[isNetworkManager ? 2 : 0]}>
              <div className="Member__footer-introduction">
                {`Introduction ${introduction.status === 'accepted' ? 'made' : 'requested'}`}
                <br />
                {`on ${moment(new Date(introduction.createdAt)).format('MM/DD/YYYY')}`}
              </div>
            </FlexBox>
          )}
          {canRequestIntroduction && !introductionRequested && (
            <FlexBox ml={[isNetworkManager ? 2 : 0]} mr={2}>
              <RequestIntroModal
                member={currentMember}
                updateIntroduction={updateIntroduction}
                activeNetwork={activeNetwork}
              />
            </FlexBox>
          )}

          {isNetworkManager && (
            <AddToBoards
              itemTemplate={({ onChange, label, value }) => (
                <Checkbox
                  isLoading={!!isLoadingApi(member, value)}
                  value={value}
                  checked={member.talentGroups.find((g) => g.id === value)}
                  onChange={onChange}
                  label={label}
                  containerProps={{ mt: 2 }}
                />
              )}
              member={member}
              networkTalentGroups={networkTalentGroups}
              onCreateAndAddMemberToList={({ name }) => {
                onCreateAndAddMemberToList({ member, name });
              }}
              onBoardSelectionChanged={onBoardSelectionChanged}
              creating={creating}
              error={addToBoardError}
            />
          )}
        </Flex>
      </SemanticModal.Actions>
    </SemanticModal>
  );
};

MemberModal.propTypes = {
  member: PropTypes.object.isRequired,
  memberDetails: PropTypes.object.isRequired,
  loadingMemberDetails: PropTypes.bool.isRequired,
  activeNetwork: NetworkSchema.isRequired,
  updateIntroduction: PropTypes.func.isRequired,
  isTalentNetworkActive: PropTypes.bool,
  seniorityOptions: PropTypes.object,
  isMentorshipFeatureActive: PropTypes.bool,
  employmentTypeOptions: PropTypes.array,
  onModalClose: PropTypes.func,
  networkTalentGroups: PropTypes.arrayOf(GroupSchema).isRequired,
  onCreateAndAddMemberToList: PropTypes.func.isRequired,
  onAddRemoveMemberToList: PropTypes.func.isRequired,
  creating: PropTypes.bool,
  addToBoardError: PropTypes.string,
};

MemberModal.defaultProps = {
  seniorityOptions: {},
  isTalentNetworkActive: false,
  isMentorshipFeatureActive: false,
  employmentTypeOptions: [],
  creating: false,
  addToBoardError: null,
  onModalClose: () => {},
};

export default MemberModal;
