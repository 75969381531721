import { createApi } from '@reduxjs/toolkit/query/react';
import isEqual from 'lodash.isequal';
import queryString from 'query-string';
import uniqBy from 'lodash.uniqby';

import { axiosBaseQuery } from './queries';
import { parseFiltersData } from './contacts/filters';
import { mapContactsV2ToV1 } from './contactsV2/formatter';

export const sharedListsApiV2 = createApi({
  reducerPath: 'sharedListsApiV2',
  baseQuery: (args) => axiosBaseQuery({ ...args, version: 'v2' }),
  tagTypes: ['shared-list'],
  endpoints: (builder) => ({
    getContactsFromSharedList: builder.query({
      query: ({ slug, params, password }) => ({
        url: `/shared_lists/${slug}/contacts`,
        params: {
          ...parseFiltersData(params.filters, true),
          page: params.page,
        },
        ...(password
          ? { options: { headers: { 'X-Shared-List-Password': password, Accept: 'application/json' } } }
          : undefined),
      }),
      transformResponse: (response, _, arg) => ({
        meta: response.meta,
        page: arg.params.page,
        contacts: mapContactsV2ToV1(response),
      }),
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { params } = queryArgs;
        const { filters: queryFilters = {} } = params || {};
        const effectiveFilters = { ...parseFiltersData(queryFilters, true) };
        const filters = queryString.stringify(effectiveFilters, {
          arrayFormat: 'bracket',
        });

        return `${endpointName}?${filters}`;
      },
      merge: (currentState, incomingState) => {
        Object.assign(currentState, {
          ...incomingState,
          contacts: uniqBy([...currentState.contacts, ...incomingState.contacts], 'id'),
        });
      },
    }),
    getSharedListContactById: builder.query({
      query: ({ slug, password, contactId }) => ({
        url: `/shared_lists/${slug}/contacts/${contactId}`,
        ...(password
          ? { options: { headers: { 'X-Shared-List-Password': password, Accept: 'application/json' } } }
          : undefined),
      }),
      providesTags: ['shared-list-contact'],
      transformResponse: mapContactsV2ToV1,
    }),
  }),
});

export const {
  useGetContactsFromSharedListQuery,
  useLazyGetContactsFromSharedListQuery,
  useGetSharedListContactByIdQuery,
} = sharedListsApiV2;
