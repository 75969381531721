import { Loader } from '@getro/rombo';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Flex } from 'rebass/styled-components';

import { setNetworksReady } from '../../actions/networksActions';
import { logOut, removeUser, setUserReady } from '../../actions/userActions';

export const LogOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(removeUser());
    logOut();
    dispatch(setUserReady(true));
    dispatch(setNetworksReady(true));

    history.push('/login');
  }, [dispatch, history]);

  return (
    <Flex height="100vh" alignItems="center">
      <Loader />
    </Flex>
  );
};
