import React, { useEffect, useMemo, useRef } from 'react';
import MainLayout from 'components/layout/Main';
import { Box, Flex } from 'rebass/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import { useParams, Switch, Route, NavLink } from 'react-router-dom';
import { Plus, UserCheck, Wand2 } from 'lucide-react';
import { useGetSharedListQuery } from 'services/contacts';
import PageHeader from 'components/atoms/pageHeader';
import { Tabs } from 'components/atoms/tabs';
import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import { contactsV2Api } from '../../services/contactsV2';
import { ContactList } from './contactList';
import { AddContacts } from './addContacts';
import { AiMatches } from './aiContacts';
import { ShareList } from './shareList';
import usePageTitle from '../../hooks/usePageTitle';
import { ActionsDropdown } from './actionsDropdown';

const ListDetail = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { listId } = useParams();
  const { data: { sharedList } = {}, refetch: refetchSharedList } = useGetSharedListQuery({ listId });
  const pollingRef = useRef(null);
  const dispatch = useDispatch();
  const autoUpdate = sharedList?.smartOptions?.includes('auto_update');

  useEffect(() => {
    const cleanup = () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };

    if (sharedList?.refreshInProgress) {
      if (!pollingRef.current) {
        pollingRef.current = setInterval(() => {
          refetchSharedList();
        }, 3000);
      }
    } else if (sharedList && !sharedList.refreshInProgress && pollingRef.current) {
      cleanup();
      dispatch(contactsV2Api.util.invalidateTags([{ type: 'contacts', id: parseInt(listId, 10) }]));
    }

    return () => cleanup();
  }, [sharedList?.refreshInProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  const listName = useMemo(() => {
    const { name } = sharedList || {};
    return name;
  }, [sharedList]);

  const isJobList = Boolean(Object.keys(sharedList?.matchingProject ?? {}).length);
  const tabs = [
    {
      id: 'tab-contacts-in-list',
      title: `Contacts in list · ${sharedList?.contactsCount ?? '0'} `,
      to: `/networks/${activeNetwork.slug}/list/${listId}/contacts`,
      icon: UserCheck,
      show: true,
    },
    {
      id: 'tab-ai-matches',
      title: 'AI matches',
      to: `/networks/${activeNetwork.slug}/list/${listId}/ai-matches`,
      icon: Wand2,
      show: isJobList,
    },
    {
      id: 'tab-add-contacts',
      title: 'Add contacts',
      to: `/networks/${activeNetwork.slug}/list/${listId}/add-contacts`,
      icon: Plus,
      show: true,
    },
  ];

  usePageTitle(listName);

  return (
    <MainLayout>
      <MainLayoutHeader
        title={sharedList?.name || ''}
        actions={[
          <ActionsDropdown key="actions-dropdown" sharedList={sharedList} />,
          <ShareList key="shared-list-menu" sharedList={sharedList} />,
        ]}
        breadcrumbs={[
          {
            label: 'Lists',
            to: `/networks/${activeNetwork.slug}/lists`,
          },
        ]}
      />
      <Box px="16px" pt="24px">
        {(sharedList?.description || autoUpdate) && (
          <PageHeader description={sharedList?.description} autoUpdate={autoUpdate} />
        )}
        <Tabs
          tabs={tabs
            .filter(({ show }) => show)
            .map(({ id, title, to, icon }) => (
              <Flex
                key={id}
                data-testid={id}
                as={NavLink}
                to={to}
                sx={{
                  gap: '8px',
                  textDecoration: 'none',
                  alignItems: 'flex-start',
                  fontSize: '14px',
                  lineHeight: '16px',
                  pb: '12px',
                  height: '100%',
                }}
              >
                <Box as={icon} size="16px" />
                <Box>{title}</Box>
              </Flex>
            ))}
        />
      </Box>
      <Box px="16px" width="100%" maxWidth="1600px" mx="auto">
        <Switch>
          <Route path={`/networks/${activeNetwork.slug}/list/:listId/contacts`}>
            <ContactList isJobList={isJobList} sharedList={sharedList} />
          </Route>
          <Route path={`/networks/${activeNetwork.slug}/list/:listId/ai-matches`}>
            <AiMatches matchListId={sharedList?.matchingProject?.id} listName={listName} />
          </Route>
          <Route path={`/networks/${activeNetwork.slug}/list/:listId/add-contacts`}>
            <AddContacts sharedList={sharedList} />
          </Route>
        </Switch>
      </Box>
    </MainLayout>
  );
};

export default ListDetail;
