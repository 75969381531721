import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Loader, MessageBlock } from '@getro/rombo';
import withMainLayout from '../../hocs/withMainLayout';
import { CompanyProfileLayout } from '../../components/layout/CompanyProfileLayout';
import { AdminPanel } from '../../components/organisms/adminPanel';
import useCompany from '../../hooks/useCompany';
import { activeNetworkSelector, userSelector } from '../../redux/selectors';
import usePermissions from '../../hooks/usePermissions';
import { setPageTitle } from '../../actions/pageActions';
import PageLayout from '../../components/layout/Page';
import strings from '../../strings';

const CompanyAdmins = () => {
  const location = useLocation();
  const {
    company,
    loading,
    error,
    initialized,
    companySlug,
    addAdmin,
    isAddingAdmin,
    removeAdmin,
    isRemovingAdmin,
    resendAdminInvite,
    isResendingAdminInvite,
  } = useCompany();
  const dispatch = useDispatch();
  const activeNetwork = useSelector(activeNetworkSelector);
  const currentUser = useSelector(userSelector);
  const permissions = usePermissions({ companySlug });

  useEffect(() => {
    if (loading) {
      dispatch(setPageTitle('Loading...'));
    } else if (initialized) {
      dispatch(setPageTitle(`${company.name} profile`));
    } else {
      dispatch(setPageTitle(''));
    }
  }, [loading, initialized, company, dispatch]);

  if (!initialized && !error) {
    return (
      <PageLayout>
        <PageLayout.Content>
          <Loader />
        </PageLayout.Content>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <PageLayout.Content>
          <Box>
            {error !== 404 && <MessageBlock type="error" content={strings.genericError} />}
            {error === 404 && (
              <MessageBlock
                type="warning"
                title="Company not found"
                content="It looks like the URL is not correct or the company doesn’t belong to the active network."
              />
            )}
          </Box>
        </PageLayout.Content>
      </PageLayout>
    );
  }

  return (
    <CompanyProfileLayout>
      <AdminPanel
        adminGroupName={company.name}
        isInvitingAdmin={isAddingAdmin}
        isDeletingAdmin={isRemovingAdmin}
        isResendingInvitation={isResendingAdminInvite}
        activeNetwork={activeNetwork}
        currentUser={currentUser}
        admins={company.managers}
        onRemoveAdmin={removeAdmin}
        onResendInvitation={resendAdminInvite}
        onAdminInvite={addAdmin}
        location={location}
        canAddCompanyAdmins={permissions.company.canAddCompanyAdmins}
        canRemoveCompanyAdmins={permissions.company.canRemoveCompanyAdmins}
      />
    </CompanyProfileLayout>
  );
};

export default withMainLayout(CompanyAdmins);
