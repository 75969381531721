import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { trackEvent } from '../../helpers/analytics';
import { activeNetworkSelector } from '../../redux/selectors';

export const ErrorListener = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();
  const { id } = useSelector(activeNetworkSelector);

  useEffect(() => {
    if (errors.contacts && isSubmitting) {
      if (typeof errors.contacts === 'string') {
        trackEvent('contacts:import_csv:error', {
          collectionId: id,
          errorCode: errors.contacts,
        });
      } else if (isValidating) {
        trackEvent('contacts:import_csv:error', {
          collectionId: id,
          errorCode: errors.contacts.props.children.join(' '),
        });
      }
    }
  }, [errors.contacts, id, isSubmitting, isValidating]);

  return null;
};
