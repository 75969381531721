import { takeLatest, put, call, select } from 'redux-saga/effects';
import { trackEvent } from 'helpers/analytics';
import { errorExportCompanies, loadedExportCompanies, loadExportCompanies } from './actions';
import { activeNetworkSelector } from '../../../../redux/selectors';
import { get } from '../../../../api';
import { trackException } from '../../../../helpers/errorTracker';

export const prepare = (filters) => {
  const { places, topicIds, missingManager, qa, ...rest } = filters;

  let placeIds;
  if (places) {
    placeIds = JSON.parse(places).map((p) => p.value);
  }

  return {
    ...rest,
    placeIds,
    topicIds: topicIds || [],
    missingManager: missingManager ?? undefined,
    qa: qa ?? null,
  };
};

function* loadExportCompaniesEvent({ payload: { filters } }) {
  const { id: networkId } = yield select(activeNetworkSelector);

  try {
    yield get(`/collections/${networkId}/organizations/export_csv`, {
      params: {
        ...prepare(filters),
      },
    });
    yield put(loadedExportCompanies());
    trackEvent('employers:export_search_results');
  } catch (error) {
    yield put(errorExportCompanies());
    yield call(trackException, error);
  }
}

export default function* saga() {
  yield takeLatest(loadExportCompanies().type, loadExportCompaniesEvent);
}
