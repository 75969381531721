export const mapRoleToPermissions = (role) => {
  switch (role) {
    case 'authenticated':
      return {
        routes: ['all', 'user'],
        home: '/',
      };
    case 'unauthenticated':
      return {
        routes: ['all', 'public'],
        home: '/login',
      };
    case 'onboarding':
      return {
        routes: ['all', 'onboarding'],
        home: '/welcome',
      };
    default:
      return [['all'], '/'];
  }
};

export const setRole = ({ authenticated, onboardingCompleted }) => {
  if (!authenticated) return 'unauthenticated';
  if (!onboardingCompleted) return 'onboarding';
  return 'authenticated';
};
