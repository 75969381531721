import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input, Radio, Tooltip } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import useOnClickOutside from '../../../../hooks/useClickOutside';
import { useLazyGetFilterOptionsQuery } from '../../../../services/contacts';
import { FilterTitle } from '../../../atoms/filterTitle';
import MultiSelectDropDown from '../../../atoms/MultiSelectDropDown';
import { monthOptions } from '../../../atoms/periodDropDown/date';
import { PeriodSelector } from './periodSelector';

export const CompanyFilter = ({
  filters,
  setFilters,
  notFoundText,
  orderBy,
  sharedList,
  useShareListFilter,
  onOpen,
  defaultText,
  isList,
  disableSearch,
  disableSearchText,
  footer,
  dropdownFooter,
  onLoseFocus,
  placeholder,
}) => {
  const [search, setSearch] = useState('');
  const [showDropDown, setShowDropDown] = useState(false);
  const initialOpenedRef = useRef();
  const inputRef = useRef();
  const [isOpened, setIsOpened] = useState(false);
  const [getFilterOptions, { data, isLoading, isFetching }] = useLazyGetFilterOptionsQuery();
  const { companyName, companyNameIncluded, companyNamePeriod } = filters;
  const radioOptions = [
    {
      label: 'Current and past',
      value: 'all',
    },
    {
      label: 'Current',
      value: 'current',
    },
    {
      label: 'Specific period',
      value: 'specific_period',
    },
  ];
  const initialPeriodValues = {
    fromMonth: monthOptions()[0],
    fromYear: 2010,
    toMonth: monthOptions()[0],
    toYear: 2020,
  };
  const [currentIncludedValue, setCurrentIncludedValue] = useState(companyNameIncluded || radioOptions[0]);
  const [currentPeriodValue, setCurrentPeriodValue] = useState(companyNamePeriod || initialPeriodValues);
  const items = data?.map((item) => ({ label: item.charAt(0).toUpperCase() + item.slice(1), value: item })) || [];

  useEffect(() => {
    setCurrentIncludedValue(companyNameIncluded || radioOptions[0]);
  }, [companyNameIncluded]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearchChange = (currentValue) => {
    getFilterOptions({
      facet: 'companies',
      query: currentValue,
      sharedList: useShareListFilter ? sharedList : null,
      orderBy,
    });
  };

  const handleClickOutside = useCallback(() => {
    setIsOpened(false);
    setShowDropDown(false);
    setSearch('');

    if (companyName?.length === 0) {
      setCurrentIncludedValue(radioOptions[0]);
      setCurrentPeriodValue(initialPeriodValues);
    }
  }, [companyName?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const ref = useOnClickOutside(handleClickOutside);

  const openDropDown = () => {
    if (onOpen) {
      onOpen();
    }

    setShowDropDown(true);
    initialOpenedRef.current = true;
  };

  const onCloseDropDown = () => {
    setSearch('');

    setShowDropDown(false);
  };

  const [typing, setTyping] = useState(false);
  const timerRef = useRef(null);

  const handleSearch = (value) => {
    clearTimeout(timerRef.current);
    setSearch(value);
    setTyping(true);
    timerRef.current = setTimeout(() => {
      setTyping(false);
      onSearchChange(value);
    }, 300);
  };

  const onToggle = () => {
    if (currentIncludedValue.value === 'specific_period' && companyName?.length < 2) {
      return;
    }

    if (!isOpened) {
      setIsOpened(true);
    } else {
      setIsOpened(false);

      if (companyName?.length === 0) {
        setCurrentIncludedValue(radioOptions[0]);
        setCurrentPeriodValue(initialPeriodValues);
      }
    }
  };

  const onSelectRadios = (option) => {
    setCurrentIncludedValue(option);

    if (companyName?.length > 0) {
      if (option?.value === 'specific_period') {
        setFilters({ companyNameIncluded: option, companyNamePeriod: currentPeriodValue });
        setIsOpened(false);
        setShowDropDown(false);
        setSearch('');
      } else {
        setFilters({ companyNameIncluded: option }, { removeFilters: ['companyNamePeriod'] });
      }
    }
  };

  const onSelect = (value) => {
    const removeFilters = !value.length ? ['companyNamePeriod', 'companyNameIncluded'] : [];

    setFilters(
      {
        companyName: value,
        ...(value.length ? { companyNameIncluded: currentIncludedValue } : undefined),
        ...(currentIncludedValue?.value === 'specific_period' ? { companyNamePeriod: currentPeriodValue } : undefined),
      },
      { removeFilters },
    );

    if (currentIncludedValue?.value === 'specific_period' && value?.length) {
      setIsOpened(false);
    }
  };

  const onSelectPeriod = (values) => {
    setCurrentPeriodValue(values);

    if (companyName?.length) {
      setFilters({
        companyNamePeriod: values,
      });
    }
  };

  return (
    <Flex ref={ref} sx={{ width: '100%', alignItems: 'flex-start', flexDirection: 'column', gap: '8px' }}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <MultiSelectDropDown
          bottomText={dropdownFooter}
          onClose={() => onCloseDropDown()}
          onOpen={() => openDropDown()}
          onToggle={onToggle}
          onShowSearch={() => {
            setIsOpened(true);
          }}
          selected={companyName}
          isMulti
          options={items}
          onSelect={onSelect}
          showDropDown={showDropDown}
          loading={isLoading || isFetching}
          defaultText={search ? '' : defaultText}
          isOpened={isOpened}
          openDropDown={openDropDown}
          disableAddMore={currentIncludedValue?.value === 'specific_period' && companyName?.length > 0}
          disableAddMoreMessage={`"Specific period" option only works with one company.`}
          input={
            <>
              {!disableSearch && (
                <Input
                  ref={inputRef}
                  autoFocus
                  onBlur={() => {
                    if (onLoseFocus) {
                      onLoseFocus(companyName.map((item) => item.value));
                    }
                  }}
                  data-testid="multi-select-search"
                  className="multi-select-search"
                  onChange={({ target }) => {
                    setShowDropDown(true);
                    handleSearch(target.value);
                    setSearch(target.value);
                  }}
                  value={search}
                  sx={{
                    height: '32px',
                    fontSize: '14px',
                  }}
                  placeholder={placeholder}
                />
              )}
              {disableSearch && (
                <Text fontSize="14px" color="text.placeholder">
                  {disableSearchText}
                </Text>
              )}
            </>
          }
          trigger={
            <>
              <FilterTitle
                data-testid="multi-select-trigger"
                title="Company"
                showClear={companyName.length > 0}
                showToggle={!isOpened && !companyName?.length}
                clearFilter={() => {
                  onSelect([]);
                  setIsOpened(false);
                  setCurrentIncludedValue(radioOptions[0]);
                  setCurrentPeriodValue(initialPeriodValues);
                }}
                onToggle={onToggle}
              />
              {(isOpened || companyName?.length > 0) && (
                <Flex mt="8px" sx={{ gap: '8px', flexDirection: 'column' }}>
                  {radioOptions.map(
                    (option, index) =>
                      companyName?.length > 1 && radioOptions.length === index + 1 ? (
                        <Tooltip
                          key={`company-included-${option.label}`}
                          position="top"
                          content="Not available when multiple companies selected."
                          trigger={
                            <Box width="fit-content" sx={{ cursor: 'default' }}>
                              <Radio
                                onChange={() => onSelectRadios(option)}
                                checked={currentIncludedValue.value === option.value}
                                id={option.value}
                                label={option.label}
                                disabled
                              />
                            </Box>
                          }
                        />
                      ) : (
                        <Radio
                          key={`company-included-${option.label}`}
                          onChange={() => onSelectRadios(option)}
                          checked={currentIncludedValue.value === option.value}
                          id={option.value}
                          label={option.label}
                        />
                      ),
                    // eslint-disable-next-line function-paren-newline
                  )}
                  {currentIncludedValue.value === 'specific_period' && (
                    <PeriodSelector selected={currentPeriodValue} onSelect={onSelectPeriod} />
                  )}
                </Flex>
              )}
            </>
          }
        >
          {!items.length && search && !typing && !isLoading && !isFetching && (
            <Text
              as="p"
              sx={{
                fontSize: '12px',
                lineHeight: '1.4',
                fontStyle: 'italic',
                color: 'text.subtle',
                px: '16px',
                pt: '8px',
              }}
            >
              {notFoundText} “{search}” {isList ? 'in this list' : ''}
            </Text>
          )}
        </MultiSelectDropDown>
      </Box>
      {(isOpened || companyName?.length > 0) && footer}
    </Flex>
  );
};

CompanyFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  notFoundText: PropTypes.string,
  onOpen: PropTypes.func,
  defaultText: PropTypes.string,
  sharedList: PropTypes.object,
  useShareListFilter: PropTypes.bool,
  isList: PropTypes.bool,
  footer: PropTypes.node,
  dropdownFooter: PropTypes.node,
  onLoseFocus: PropTypes.func,
  placeholder: PropTypes.string,
  disableSearch: PropTypes.bool,
  disableSearchText: PropTypes.string,
  orderBy: PropTypes.oneOf(['count', 'alpha']),
};

CompanyFilter.defaultProps = {
  onOpen: () => {},
  notFoundText: 'No results found for',
  defaultText: '',
  isList: false,
  footer: null,
  dropdownFooter: null,
  onLoseFocus: null,
  placeholder: 'Search',
  disableSearch: false,
  disableSearchText: '',
  orderBy: 'count',
  sharedList: null,
  useShareListFilter: false,
};
