import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ProfilePicture } from '@getro/rombo';
import SubscribeViaEmailButton from '../subscribeViaEmailButton';
import SubscribeToSlackButton from '../subscribeToSlackButton';
import ContactsSharedListSchema from '../../../schemas/contactsSharedList';
import PointOfContact from '../pointOfContact';

const StickyHeader = ({ listInfo }) => {
  const [showHeader, setShowHeader] = useState(false);
  const { showPointOfContact } = listInfo || {};

  const onScroll = () => {
    if (window.pageYOffset > 180) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
  };

  const on = (obj, ...args) => {
    obj.addEventListener(...args);
  };

  const off = (obj, ...args) => {
    obj.removeEventListener(...args);
  };

  useEffect(() => {
    on(window, 'scroll', onScroll, { passive: true });

    return () => {
      off(window, 'scroll', onScroll, { passive: true });
    };
  }, []);

  return (
    <Box
      as="header"
      bg="neutral.0"
      height="84px"
      width="100%"
      py="16px"
      px="32px"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        boxShadow: 'small',
        zIndex: 1,
        transform: showHeader ? 'translate3d(0, 0, 0)' : 'translate3d(0, -96px, 0)',
        transition: 'transform 0.15s ease-in-out',
        display: ['none', 'none', 'block'],
      }}
    >
      <Flex maxWidth="1016px" mx="auto" justifyContent="space-between">
        <Flex flexGrow="1" justifyContent="center" flexDirection="column">
          <Text fontSize="20px" fontWeight="700" color="text.dark" lineHeight="120%" mb="4px">
            {listInfo.name}
          </Text>
          <Flex flexDirection="row" alignItems="center">
            <Box flex="0 0 auto" width="24px" height="24px" mr="8px">
              <ProfilePicture
                variant="square"
                name={listInfo.collection?.name}
                imageUrl={listInfo.collection?.logoUrl}
              />
            </Box>
            <Text fontSize="14px">
              {'A list by '}
              <Text as="span" fontWeight="medium">
                {listInfo.collection?.name}
              </Text>
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="row" sx={{ gap: '8px' }} ml="8px" alignItems="center">
          {showPointOfContact && (
            <Box fontSize="14px">
              <PointOfContact listInfo={listInfo} />
            </Box>
          )}
          <SubscribeToSlackButton listInfo={listInfo} sticky />
          <SubscribeViaEmailButton mr="8px" listInfo={listInfo} sticky />
        </Flex>
      </Flex>
    </Box>
  );
};

StickyHeader.propTypes = {
  listInfo: ContactsSharedListSchema.isRequired,
};

export default StickyHeader;
