import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  errorBulkForceJobVisibility,
  errorBulkForceAllJobsVisibility,
  errorCloseJob,
  errorDeleteJob,
  errorExtendJob,
  errorFeatureJob,
  errorForceJobVisibility,
  loadBulkForceJobVisibility,
  loadBulkForceAllJobsVisibility,
  loadCloseJob,
  loadDeleteJob,
  loadedDeleteJob,
  loadExtendJob,
  loadFeatureJob,
  loadForceJobVisibility,
  toggleJobActionDialog,
  loadedCloseJob,
  loadJobApplicationDownload,
  loadedJobApplicationDownload,
  errorJobApplicationDownload,
  closeJobApplicationDownloadModal,
} from './actions';

export const initialState = {
  error: {
    extendJob: false,
    closeJob: false,
    deleteJob: false,
    forceJobVisibility: false,
    featureJob: false,
    bulkForceJobVisibility: false,
    bulkForceAllJobsVisibility: false,
    jobApplicationDownload: false,
  },
  dialogs: {
    extendJob: false,
    shareJob: false,
    closeJob: false,
    deleteJob: false,
    forceJobVisibility: false,
    featureJob: false,
    bulkForceJobVisibility: false,
    jobApplicationDownload: false,
  },
  message: {
    jobApplicationDownload: {
      description: '',
    },
  },
};

/* eslint-disable default-case, no-param-reassign */
const jobsReducer = handleActions(
  {
    [toggleJobActionDialog]: produce((draft, action) => {
      const { dialog, isOpen } = action.payload;
      draft.dialogs[dialog] = isOpen;

      if (!isOpen) {
        draft.error[dialog] = false;
      }
      return draft;
    }),
    [loadExtendJob]: produce((draft) => {
      draft.error.extendJob = initialState.error.extendJob;
      return draft;
    }),
    [errorExtendJob]: produce((draft, action) => {
      const { error } = action.payload;
      draft.error.extendJob = error;
      return draft;
    }),
    [loadFeatureJob]: produce((draft) => {
      draft.error.featureJob = initialState.error.featureJob;
      return draft;
    }),
    [errorFeatureJob]: produce((draft, action) => {
      const { error } = action.payload;
      draft.error.featureJob = error;
      return draft;
    }),
    [loadForceJobVisibility]: produce((draft) => {
      draft.error.forceJobVisibility = initialState.error.forceJobVisibility;
      return draft;
    }),
    [errorForceJobVisibility]: produce((draft, action) => {
      const { error } = action.payload;
      draft.error.forceJobVisibility = error;
      return draft;
    }),
    [loadBulkForceJobVisibility]: produce((draft) => {
      draft.error.bulkForceJobVisibility = initialState.error.bulkForceJobVisibility;
      return draft;
    }),
    [loadBulkForceAllJobsVisibility]: produce((draft) => {
      draft.error.bulkForceAllJobsVisibility = initialState.error.bulkForceAllJobsVisibility;
      return draft;
    }),
    [errorBulkForceJobVisibility]: produce((draft, action) => {
      const { error } = action.payload;
      draft.error.bulkForceJobVisibility = error;
      return draft;
    }),
    [errorBulkForceAllJobsVisibility]: produce((draft, action) => {
      const { error } = action.payload;
      draft.error.bulkForceAllJobsVisibility = error;
      return draft;
    }),
    [loadCloseJob]: produce((draft) => {
      draft.error.closeJob = initialState.error.closeJob;
      return draft;
    }),
    [loadedCloseJob]: produce((draft) => {
      draft.dialogs.closeJob = false;
      draft.dialogs.deleteJob = false;
      return draft;
    }),
    [errorCloseJob]: produce((draft, action) => {
      const { error } = action.payload;
      draft.error.closeJob = error;
      return draft;
    }),
    [loadDeleteJob]: produce((draft) => {
      draft.error.deleteJob = initialState.error.deleteJob;
      return draft;
    }),
    [loadedDeleteJob]: produce((draft) => {
      draft.dialogs.deleteJob = false;
      return draft;
    }),
    [errorDeleteJob]: produce((draft, action) => {
      const { error } = action.payload;
      draft.error.deleteJob = error;
      return draft;
    }),
    [loadJobApplicationDownload]: produce((draft) => {
      draft.error.jobApplicationDownload = initialState.error.jobApplicationDownload;
      return draft;
    }),
    [loadedJobApplicationDownload]: produce((draft, { payload }) => {
      draft.message.jobApplicationDownload.description = payload.bulk
        ? 'We’ll send you the list of all applicants to posted jobs in a few minutes.'
        : 'We’ll send you the list of applicants to this job in a few minutes.';
      draft.dialogs.jobApplicationDownload = true;
      return draft;
    }),
    [errorJobApplicationDownload]: produce((draft, action) => {
      const { error } = action.payload;
      draft.error.jobApplicationDownload = error;
      return draft;
    }),
    [closeJobApplicationDownloadModal]: produce((draft) => {
      draft.dialogs.jobApplicationDownload = false;
      return draft;
    }),
  },
  initialState,
);

export default jobsReducer;
