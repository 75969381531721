import { Button } from '@getro/rombo';
import { ExternalLink } from 'lucide-react';
import React from 'react';
import { Box, Text, Image, Flex } from 'rebass/styled-components';
import { EXTENSION_URL } from 'services/chromeExtension';
import Chrome from '../../../assets/chrome-extension-logo.png';

export const InstallChromeExtension = () => (
  <Flex
    data-testid="install-chrome-extension"
    bg="purple.100"
    p="16px"
    mt="40px"
    sx={{ gap: '16px', borderRadius: '8px' }}
  >
    <Image height="32px" src={Chrome} alt="Install extension" />
    <Box>
      <Text fontSize="16px" color="text.dark" fontWeight="500" lineHeight="1.4">
        To get started, install our Chrome extension
      </Text>

      <Text fontSize="14px" color="text.main" fontWeight="400" lineHeight="1.4" mt="8px">
        It will help you importing your contacts and managing them directly from LinkedIn.
      </Text>
      <Button
        iconGap="8px"
        onClick={() => window.open(EXTENSION_URL)}
        iconPosition="right"
        mt="24px"
        icon={ExternalLink}
      >
        Install extension
      </Button>
    </Box>
  </Flex>
);
