import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';

import { MessageBlock, EditPanel, Popover } from '@getro/rombo';
import { Flex, Box, Text } from 'rebass/styled-components';
import { activeNetworkSelector } from 'redux/selectors';
import { get, put } from 'api';
import strings from 'strings';
import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';

import { CheckCircle, EyeOff, HelpCircle } from 'lucide-react';
import { Button } from 'rebass';
import { GenericError } from '../../../../components/molecules/genericError';
import { Toggle } from '../../../../components/atoms/toggleDeprecated';

const TalentNetwork = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { id: activeNetworkId } = activeNetwork;
  const formRef = useRef(null);

  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const handleSubmit = async (values, { setSubmitting }) => {
    setUpdateError(null);
    try {
      const response = await put(`/collections/${activeNetworkId}/settings/talent_network`, {
        ...values,
        tnTabIntroductionText: undefined,
        tnSignUpWelcomeText: undefined,
      });
      setData(response.data);
      trackEvent('network_settings:edit_talent_network_settings');
    } catch (error) {
      trackException(error);
      setUpdateError(strings.genericError);
    } finally {
      setIsSaving(false);
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    if (formRef && formRef.current) {
      formRef.current.resetForm();
    }
  };

  // Load settings on first load
  useEffect(() => {
    (async () => {
      setFetching(true);
      try {
        const response = await get(`/collections/${activeNetworkId}/settings/talent_network`);
        setData(response.data);
      } catch (error) {
        trackException(error);
        setFetchError(true);
      } finally {
        setFetching(false);
      }
    })(activeNetworkId);
  }, [activeNetworkId]);

  // Don't show this section if the network is not using the TN
  if (!activeNetwork.features.includes('talent_network')) {
    return null;
  }

  return (
    <Flex mb={4}>
      <EditPanel
        canEdit
        isLoading={fetching}
        cancelButton={{
          onClick: () => handleCancel,
          children: 'Cancel',
        }}
        saveButton={{
          loading: isSaving,
          onClick: () => {
            setIsSaving(true);
            if (formRef.current) {
              formRef.current.handleSubmit();
            }
            // handleSubmit({}, { setSubmitting: false });
          },
          children: 'Save',
        }}
        title="Talent network visibility"
      >
        {({ isEditing }) => (
          <Flex flexDirection="column">
            {fetchError && <MessageBlock>{strings.genericError}</MessageBlock>}
            {!fetchError && (
              <>
                {!isEditing && (
                  <Flex width={1} mb={3} flexDirection={['column', 'column', 'row']}>
                    <Box width={['27%']} mb={[2, 2, 0]}>
                      <Flex alignItems="center">
                        <Text>Visible to companies</Text>
                        <Box ml={1}>
                          <Popover
                            content={strings.settings.talentNetworkVisibility}
                            trigger={
                              <Flex color="neutral.400">
                                <Box height="16px" width="auto" as={HelpCircle} strokeWidth="1" />
                              </Flex>
                            }
                          />
                        </Box>
                      </Flex>
                    </Box>
                    <Box flex={1}>
                      <Flex>
                        <Box color={data.tnHidden ? 'text.main' : 'green.600'}>
                          <Box
                            mr={2}
                            height="24px"
                            width="24px"
                            strokeWidth="1"
                            aria-hidden="true"
                            as={data.tnHidden ? EyeOff : CheckCircle}
                          />
                        </Box>
                        <Text as="b">Yes:&nbsp;</Text>
                        <Text>Companies can view members in your network.</Text>
                      </Flex>
                    </Box>
                  </Flex>
                )}
                {isEditing && (
                  <Flex width={1}>
                    <Formik innerRef={formRef} initialValues={data} onSubmit={handleSubmit}>
                      {({ values, setFieldValue }) => (
                        <Flex as={Form} width={1} flexDirection="column">
                          <Box>
                            <Field
                              className="Visibility__checkbox"
                              component={Toggle}
                              name="tnHidden"
                              value
                              checked={!values.tnHidden}
                              onChange={(e) => setFieldValue('tnHidden', !e.target.checked)}
                              label={
                                <Flex>
                                  <Text as="b">Yes:&nbsp;</Text>
                                  <Text>Companies can view members in your network.</Text>
                                </Flex>
                              }
                            />
                          </Box>
                          {updateError && (
                            <Box mt={3}>
                              <GenericError />
                            </Box>
                          )}
                          <Button
                            type="submit"
                            sx={{
                              position: 'absolute',
                              width: '1px',
                              height: '1px',
                              padding: '0',
                              margin: '-1px',
                              overflow: 'hidden',
                              clip: 'rect(0, 0, 0, 0)',
                              whiteSpace: 'nowrap',
                              border: '0',
                            }}
                          >
                            Save
                          </Button>
                        </Flex>
                      )}
                    </Formik>
                  </Flex>
                )}
              </>
            )}
          </Flex>
        )}
      </EditPanel>
    </Flex>
  );
};

export default TalentNetwork;
