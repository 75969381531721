import { takeLatest, put, call, select } from 'redux-saga/effects';
import moment from 'moment';
import {
  loadEditedJob,
  loadedEditedJob,
  errorEditedJob,
  loadSaveEditedJob,
  loadedSaveEditedJob,
  errorSaveEditedJob,
  loadJobOrganizations,
  loadedJobOrganizations,
  errorJobOrganizations,
  loadJobLocations,
  loadedJobLocations,
  errorJobLocations,
} from './actions';
import * as api from '../../../../api';
import { activeNetworkSelector } from '../../../../redux/selectors';
import { trackException } from '../../../../helpers/errorTracker';
import { addProtocolToUrl } from '../../../../helpers/urlHelpers';
import { trackEvent } from '../../../../helpers/analytics';

export function* loadEditedJobEvent({ payload }) {
  const { jobId } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: collectionId } = activeNetwork;

  try {
    const response = yield call(api.get, `/collections/${collectionId}/jobs/${jobId}`, {
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });
    const {
      data: { data },
    } = response;
    yield put(loadedEditedJob(data.attributes));
  } catch (e) {
    yield call(trackException, e, { jobId });
    yield put(errorEditedJob({ error: e }));
  }
}

export function* loadSaveEditedJobEvent({ payload }) {
  try {
    const network = yield select(activeNetworkSelector);
    const { id: collectionId, isManager } = network;

    const { values, editing, action, eventName } = payload;
    const {
      locations,
      expirationDate,
      applicationPath,
      applicationMethod,
      closedAt,
      status,
      id,
      companyId,
      jobFunctions,
      employmentTypes,
      notifiedUsers,
      ...otherValues
    } = values;

    const organizationId = companyId?.value || companyId;
    const applyOnJobBoard = applicationMethod === 'on_job_board';
    let applicationPathPayload =
      applicationMethod === 'url' ? addProtocolToUrl(applicationPath.trim()) : applicationPath;

    if (applyOnJobBoard) {
      applicationPathPayload = null;
    }

    const data = {
      ...otherValues,
      id: editing ? id : null,
      applicationPath: applicationPathPayload,
      applicationMethod,
      collectionId,
      compensationCurrency: values.compensationCurrency?.value,
      compensationPeriod: values.compensationPeriod?.value,
      organizationId,
      placeIds: values.locations.map(({ value }) => value),
      status: action === 'duplicate-job' ? 'active' : status,
      jobFunctionIds: jobFunctions.map(({ value }) => value),
      employmentTypes: employmentTypes.map(({ value }) => value),
    };

    if (applyOnJobBoard) {
      data.notifiedUsers = notifiedUsers
        .split(/[,;]/)
        .map((email) => email.trim())
        .filter((email) => !!email);
    }

    let response;
    if (editing) {
      response = yield call(api.put, `/collections/${collectionId}/jobs/${id}`, data, {
        baseURL: process.env.REACT_APP_API_V2_BASE_URL,
      });
    } else {
      data.expirationDate =
        expirationDate.value > 0 ? moment().add(expirationDate.value, 'days').format('YYYY-MM-DD') : null;
      response = yield call(api.post, `/collections/${collectionId}/jobs`, data, {
        baseURL: process.env.REACT_APP_API_V2_BASE_URL,
      });
    }
    const eventProperties = {
      organization_id: organizationId,
      job_id: response.data.id,
      is_network_admin: isManager,
      application_method: applicationMethod,
    };

    if (!editing) {
      eventProperties.expiration_date = expirationDate.label;
    }

    yield call(trackEvent, eventName, eventProperties);
    yield put(loadedSaveEditedJob());
  } catch (e) {
    yield call(trackException, e, { payload });
    yield put(errorSaveEditedJob({ error: e }));
  }
}

export function* loadJobOrganizationsEvent({ payload }) {
  const { query } = payload;

  if (!query.length) {
    yield put(loadedJobOrganizations([]));
    return;
  }

  const activeNetwork = yield select(activeNetworkSelector);
  const { id: activeNetworkId } = activeNetwork;

  try {
    const response = yield call(api.get, `collections/${activeNetworkId}/organizations/search`, {
      params: { q: query },
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });

    const organizationsFound = response.data.data.map(({ id, attributes: { name } }) => ({
      value: id,
      label: name,
    }));

    yield put(loadedJobOrganizations(organizationsFound));
  } catch (e) {
    yield call(trackException, e, { activeNetworkId, query });
    yield put(errorJobOrganizations());
  }
}

export function* loadJobLocationsEvent({ payload }) {
  const { query } = payload;

  if (!query.length) {
    yield put(loadedJobLocations([]));
    return;
  }

  try {
    const response = yield call(api.get, '/locations/search/google', {
      params: { q: query },
    });

    const locationsFound = response.data.items.map(({ description, placeId }) => ({
      value: placeId,
      label: description,
    }));

    yield put(loadedJobLocations(locationsFound));
  } catch (e) {
    yield call(trackException, e, { query });
    yield put(errorJobLocations());
  }
}

export default function* saga() {
  yield takeLatest(loadEditedJob().type, loadEditedJobEvent);
  yield takeLatest(loadSaveEditedJob().type, loadSaveEditedJobEvent);
  yield takeLatest(loadJobOrganizations().type, loadJobOrganizationsEvent);
  yield takeLatest(loadJobLocations().type, loadJobLocationsEvent);
}
