import React from 'react';

import strings from 'strings';

const NoAccess = () => (
  <p>
    {strings.session.noAccess.title}
    <br />
    {strings.session.noAccess.text}
  </p>
);

export default NoAccess;
