import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass/styled-components';

export const InfoParagraph = ({ title, children }) => (
  <Flex flexDirection="column" mb={[4]} sx={{ alignSelf: 'center' }} width={['100%', '574px']}>
    <Text mb={[2]} color="text.dark" fontWeight="medium">
      {title}
    </Text>
    <Text>{children}</Text>
  </Flex>
);

InfoParagraph.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
