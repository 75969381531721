import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import { NavLink } from 'react-router-dom';
import PageLayout from '../Page';
import usePermissions from '../../../hooks/usePermissions';

const Index = ({ children }) => {
  const {
    notifications: { canManageNotifications },
  } = usePermissions();
  return (
    <PageLayout>
      <PageLayout.Content
        contentHeader={
          <Box width={1} as="nav" role="navigation" variant="navigation.subnav" mt="16px" mb={4}>
            <Flex as="ul">
              <Box as="li" pr={1}>
                <Button as={NavLink} variant="activeUnderline" mr={2} to="/account/my-profile" exact>
                  Profile
                </Button>
              </Box>
              {canManageNotifications && (
                <Box as="li" px={1}>
                  <Button as={NavLink} variant="activeUnderline" mx={2} to="/account/notifications" exact>
                    Email notifications
                  </Button>
                </Box>
              )}
              <Box as="li" px={1}>
                <Button as={NavLink} variant="activeUnderline" mx={2} to="/account/my-account" exact>
                  Account
                </Button>
              </Box>
            </Flex>
          </Box>
        }
      >
        {children}
      </PageLayout.Content>
    </PageLayout>
  );
};

Index.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Index;
