import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import pluralize from 'pluralize';

export const BulkActionsBar = ({ count, itemLabel, actions, sx }) => (
  <Flex
    sx={{
      mx: 'auto',
      width: '400px',
      bottom: count > 0 ? ['16px', '16px', '32px'] : '-64px',
      position: 'fixed',
      height: '48px',
      borderRadius: '64px',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#FFFFFF',
      left: '50%',
      transform: 'translate(-50%, 0)',
      backgroundColor: 'purple.500',
      maxWidth: 'calc(100vw - 32px)',
      transition: 'all ease .3s',
      zIndex: 2,
      px: '24px',
      ...sx,
    }}
  >
    <Text sx={{ flex: 'none' }} fontSize="14px" fontWeight={500}>{`${count} ${pluralize(
      itemLabel,
      count,
    )} selected:`}</Text>
    <Box>{actions}</Box>
  </Flex>
);

BulkActionsBar.propTypes = {
  count: PropTypes.number.isRequired,
  itemLabel: PropTypes.string,
  actions: PropTypes.node,
  sx: PropTypes.object,
};

BulkActionsBar.defaultProps = {
  itemLabel: 'item',
  actions: null,
  sx: {},
};
