import { useContactsFilterCount } from 'hooks/useContactsFilterCount';
import pluralize from 'pluralize';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Loader } from '@getro/rombo';

export const ContactFooter = ({ filters, contactsTotal, isLoading, allContactsCount, isList }) => {
  const filterCount = useContactsFilterCount(filters);
  const showFooter = contactsTotal > 0 && contactsTotal === allContactsCount;

  if (isLoading) return <Loader data-testid="contact-footer-loader" mt="24px" />;

  if (!showFooter) return null;

  return (
    <Flex
      flexDirection="column"
      lineHeigh="1.4"
      justifyContent="center"
      py="80px"
      width="100%"
      fontSize="14px"
      alignItems="center"
    >
      <Box fontWeight="500" color="text.main">
        {!filterCount
          ? `All ${pluralize('contact', contactsTotal)} ${isList ? 'in this list' : ''} shown`
          : `All ${pluralize('results', contactsTotal)} for this search ${isList ? 'in this list' : ''} shown`}
      </Box>
    </Flex>
  );
};

ContactFooter.propTypes = {
  filters: PropTypes.object.isRequired,
  contactsTotal: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  allContactsCount: PropTypes.number.isRequired,
  isList: PropTypes.bool,
};

ContactFooter.defaultProps = {
  isLoading: false,
  isList: false,
};
