import React from 'react';
import PropTypes from 'prop-types';

import { useLazyGetImportersQuery } from '../../../services/contactsV2';
import MultiSelect from '../../atoms/MultiSelect';

export const ContactOfFilter = ({ value, onChange }) => {
  const [getImporters, { data, isLoading }] = useLazyGetImportersQuery();

  return (
    <MultiSelect
      title="Contact of"
      options={data?.importers?.map((item) => ({ label: item.name, value: item.id })) || []}
      selected={value}
      loading={isLoading}
      onSelect={(values) => onChange(values)}
      onInitialOpenDropDown={() => getImporters()}
      notFoundText="No admins found with name"
      useLocalSearch
    />
  );
};

ContactOfFilter.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

ContactOfFilter.defaultProps = {
  value: [],
};
