import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Message, Panel } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { ChevronLeft } from 'lucide-react';

import { ImportContactColumnSelector } from '../../components/molecules/importContactColumnSelector';
import { trackEvent } from '../../helpers/analytics';
import { activeNetworkSelector } from '../../redux/selectors';

export const StepPreview = ({ contacts, skippedContacts, isLoading, onBack, onNext }) => {
  const { id } = useSelector(activeNetworkSelector);
  const [errors, setErrors] = useState([]);
  const firstContact = contacts[0];
  const tableColumns = Object.keys(firstContact);
  const options = [
    { label: 'LinkedIn URL', value: 'linkedin_url' },
    { label: 'Email', value: 'email', mergeMethod: null },
    { label: 'Lists', value: 'lists', mergeMethod: null },
    { label: 'Tags', value: 'tags', mergeMethod: null },
    { label: 'Resume URL', value: 'resume_url', mergeMethod: null },
    { label: 'Website', value: 'website_url', mergeMethod: null },
    { label: 'Do not import', value: 'ignore' },
  ];

  const initColumnNames = () => {
    const values = [];

    tableColumns.forEach((column) => {
      if (!values.find((item) => item.value === 'linkedin_url') && column.toLowerCase().indexOf('linkedin') >= 0) {
        values.push({ label: 'LinkedIn URL', value: 'linkedin_url' });
      } else if (!values.find((item) => item.value === 'email') && column.toLowerCase().indexOf('email') >= 0) {
        values.push({ label: 'Email', value: 'email', mergeMethod: null });
      } else if (!values.find((item) => item.value === 'lists') && column.toLowerCase().indexOf('lists') >= 0) {
        values.push({ label: 'Lists', value: 'lists', mergeMethod: null });
      } else if (!values.find((item) => item.value === 'tags') && column.toLowerCase().indexOf('tags') >= 0) {
        values.push({ label: 'Tags', value: 'tags', mergeMethod: null });
      } else if (!values.find((item) => item.value === 'resume_url') && column.toLowerCase().indexOf('resume') >= 0) {
        values.push({ label: 'Resume URL', value: 'resume_url', mergeMethod: null });
      } else if (!values.find((item) => item.value === 'website_url') && column.toLowerCase().indexOf('website') >= 0) {
        values.push({ label: 'Website', value: 'website_url', mergeMethod: null });
      } else {
        values.push({ label: 'Do not import', value: 'ignore' });
      }
    });

    return values;
  };

  const [columnValues, setColumnValues] = useState(initColumnNames());

  const isValid = () => {
    let valid = true;
    const newErrors = [];

    if (!columnValues.find((column) => column.value === 'linkedin_url')) {
      valid = false;
      newErrors.push('Map one column to LinkedIn URL: this is required to identify unique contacts.');
    }

    if (!columnValues.find((column) => column.value !== 'ignore')) {
      valid = false;
      newErrors.push('Nothing to import: map at least one column to a field.');
    }

    if (columnValues.find((item) => item.mergeMethod === null)) {
      valid = false;
      newErrors.push(
        'Select merge method for every column to import: this way you can specify how to update existing contacts',
      );
    }

    setErrors(newErrors);

    return valid;
  };

  const onCurrentNext = async () => {
    if (isValid()) {
      try {
        await onNext(columnValues);
        trackEvent('contacts:import_csv:start', {
          collectionId: id,
          totalCount: contacts.length,
          rowsMissingLinkedinUrl: skippedContacts,
        });
      } catch (e) {
        const newErrors = [...errors, e.message];
        setErrors(newErrors);
        trackEvent('contacts:import_csv:validation_error', {
          collectionId: id,
          errorMissingMergeMethod: errors.some((item) => item.includes('merge method')),
          errorLinkedinUrlNotMapped: errors.some((item) => item.includes('LinkedIn URL')),
        });
      }
    } else {
      trackEvent('contacts:import_csv:validation_error', {
        collectionId: id,
        errorMissingMergeMethod: errors.some((item) => item.includes('merge method')),
        errorLinkedinUrlNotMapped: errors.some((item) => item.includes('LinkedIn URL')),
      });
    }
  };

  const onChangeColumnValue = (index, value) => {
    const nextColumnValues = columnValues.map((item, i) => (i === index ? value : item));

    setColumnValues(nextColumnValues);
  };

  return (
    <Panel
      p="0"
      sx={{ boxShadow: 'none' }}
      footer={
        <Box width="100%">
          {!!errors.length && (
            <Message mb="24px" type="warning">
              <Text>Review the following errors:</Text>
              <Box as="ul" pl="24px" pt="8px">
                {errors.map((error) => (
                  <Box as="li">{error}</Box>
                ))}
              </Box>
            </Message>
          )}
          <Flex justifyContent="space-between" width="100%">
            <Button variant="tertiary" onClick={onBack}>
              <Flex alignItems="center">
                <Box as={ChevronLeft} mr="8px" width="16px" height="16px" aria-hidden="true" />
                Back
              </Flex>
            </Button>
            <Button loading={isLoading} onClick={onCurrentNext}>{`Add ${contacts.length} contacts`}</Button>
          </Flex>
        </Box>
      }
    >
      <Text fontSize="14px" fontWeight="medium" color="text.dark">
        Map your file
      </Text>
      <Text mt="4px" fontSize="14px">
        Assign each CSV column to a field and choose merge method.
      </Text>
      <Text mt="40px" fontSize="14px">{`${contacts.length} contacts found.${
        contacts.length > 0 ? ` Previewing first ${contacts.slice(0, 10).length} valid rows.` : ''
      }`}</Text>
      <Box
        mt="8px"
        minHeight="400px"
        ml="-8px"
        pl="8px"
        sx={{
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            height: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            background: '#DEE4ED',
          },
          '&::-webkit-scrollbar-bar': {
            width: '1px',
          },
          scrollbarWidth: '1px',
        }}
      >
        <Flex
          mb="8px"
          py="8px"
          width="fit-content"
          sx={{ borderBottom: 1, borderBottomColor: 'neutral.100', gap: '8px' }}
        >
          {tableColumns.map((key, index) => (
            <Box
              key={key}
              minWidth="360px"
              sx={{
                pr: index + 1 === tableColumns.length ? '8px' : 0,
                mr: index + 1 === tableColumns.length ? '-8px' : 0,
              }}
            >
              <Text
                pb="4px"
                fontSize="12px"
                color="text.subtle"
                sx={{ textTransform: 'uppercase', letterSpacing: '1px' }}
              >
                {key.replaceAll('_', ' ')}
              </Text>
              <ImportContactColumnSelector
                options={options}
                columnValues={columnValues}
                value={columnValues[index]}
                onChange={(value) => onChangeColumnValue(index, value)}
                hideMerge={columnValues[index].value === 'linkedin_url'}
              />
            </Box>
          ))}
        </Flex>
        {contacts.slice(0, 9).map((contact) => (
          <Flex key={contact.linkedin_url} my="8px" sx={{ gap: '8px' }}>
            {tableColumns.map((key) => (
              <Text
                key={`${key}-${contact.linkedin_url || contact.email || contact.contact_name}`}
                minWidth="360px"
                width="100%"
                fontSize="14px"
                lineHeight="24px"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  '-webkit-box-orient': 'vertical',
                  '-webkit-line-clamp': '3',
                }}
              >
                {contact[key]}
              </Text>
            ))}
          </Flex>
        ))}
      </Box>
    </Panel>
  );
};

StepPreview.propTypes = {
  contacts: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  skippedContacts: PropTypes.number.isRequired,
};

StepPreview.defaultProps = {
  isLoading: false,
};
