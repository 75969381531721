import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'createHistory';

import { companiesApi } from 'services/companies';
import { analyticsApi } from 'services/analytics';
import { networkApi } from 'services/network';
import { networkV2Api } from 'services/networkV2';
import { contactsApi } from '../../services/contacts';
import { hiresApi } from '../../services/hires';
import { networkAdminsApi } from '../../services/networkAdmins';
import { tagsApi } from '../../services/tags';
import { skillsApi } from '../../services/skills';
import { locationsApi } from '../../services/locations';
import { organizationApi } from '../../services/organizations';
import networks from './networksReducer';
import user from './userReducer';
import page from './pageReducer';
import jobFunctions from './jobFunctionsReducer';
import loadingReducer from './loadingReducer';
import mainMenuReducer from '../../features/mainMenu';
import { contactsV2Api } from '../../services/contactsV2';
import { companiesV2Api } from '../../services/companiesV2';
import { userApi } from '../../services/user';
import { jobsV2Api } from '../../services/jobsV2';
import { matchesApi } from '../../services/matches';
import { connectJobsAPI } from '../../services/connectJobs';
import { searchApi } from '../../services/search';
import { sharedListsApi } from '../../services/sharedLists';
import { sharedListsApiV2 } from '../../services/sharedListV2';

export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    loading: loadingReducer,
    jobFunctions,
    networks,
    user,
    page,
    router: connectRouter(history),
    mainMenu: mainMenuReducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [networkAdminsApi.reducerPath]: networkAdminsApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [hiresApi.reducerPath]: hiresApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [contactsV2Api.reducerPath]: contactsV2Api.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [jobsV2Api.reducerPath]: jobsV2Api.reducer,
    [companiesV2Api.reducerPath]: companiesV2Api.reducer,
    [matchesApi.reducerPath]: matchesApi.reducer,
    [skillsApi.reducerPath]: skillsApi.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
    [connectJobsAPI.reducerPath]: connectJobsAPI.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [sharedListsApi.reducerPath]: sharedListsApi.reducer,
    [sharedListsApiV2.reducerPath]: sharedListsApiV2.reducer,
    [networkApi.reducerPath]: networkApi.reducer,
    [networkV2Api.reducerPath]: networkV2Api.reducer,
    ...injectedReducers,
  });
}
