import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@getro/rombo';

import { post } from 'api';
import { trackException } from 'helpers/errorTracker';
import { openChat } from 'helpers/supportChat';

const resendConfirmationEmail = (email, networkId, organizationId, setResended) => async () => {
  try {
    await post('/users/confirmation', {
      email,
      collectionId: networkId,
      claimOrgProfile: organizationId,
    });
    setResended(true);
  } catch (error) {
    trackException(error);
    setResended(false);
  }
};

const handleContactClick = () => {
  openChat();
};

const renderResended = (resended) => {
  if (resended) {
    return (
      <p className="VerifyYourAccount__resend--confirmation">
        We sent you the email again, please check your spam folder. If you still can’t find it you can{' '}
        <Button variant="underlineLink" color="text.dark" onClick={handleContactClick}>
          contact us
        </Button>
        .
      </p>
    );
  }

  if (resended === false) {
    return (
      <p className="VerifyYourAccount__resend--error">
        There was an unexpected error, please try again or contact us.{' '}
        <Button textOnly onClick={handleContactClick}>
          contact us
        </Button>
        .
      </p>
    );
  }

  return null;
};

const VerifyYourAccount = ({ email, networkId, organization }) => {
  const [resended, setResended] = useState(undefined);

  return (
    <>
      <h2 className="VerifyYourAccount__headline">Verify your email address</h2>
      <p className="VerifyYourAccount__subheader">
        We need to verify your email address is associated with {organization.name}. Check your email and click on the
        link we sent you.
      </p>
      <p className="VerifyYourAccount__resend">
        Can’t find the email? We can{' '}
        <Button
          variant="underlineLink"
          p={0}
          m={0}
          onClick={resendConfirmationEmail(email, networkId, organization.id, setResended)}
        >
          send it again
        </Button>
        .
      </p>
      {renderResended(resended)}
    </>
  );
};

VerifyYourAccount.propTypes = {
  email: PropTypes.string.isRequired,
  networkId: PropTypes.number.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default VerifyYourAccount;
