import { FETCH_JOB_FUNCTIONS_SUCCESS } from 'actions/jobFunctionsActions';

export const initialState = [];

const jobFunctionsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_JOB_FUNCTIONS_SUCCESS:
      return payload;

    default:
      return state;
  }
};

export default jobFunctionsReducer;
