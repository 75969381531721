import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Image } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AddCompanyProcess } from 'components/atoms/AddCompanyProcess';
import PageLayout from '../Page';
import { activeNetworkSelector } from '../../../redux/selectors';
import { Title } from './title';
import Refreshing from '../../../assets/icon-refreshing.svg';

export const CompanyProfileLayout = ({ children, fullWidth, showNotPublishedInfo }) => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const match = useRouteMatch();

  return (
    <>
      <Title activeNetwork={activeNetwork} />
      {showNotPublishedInfo && (
        <Flex
          p="16px"
          alignItems="center"
          sx={{ border: '1px solid #DEE4ED', borderRadius: '4px', gap: '16px' }}
          mb="40px"
        >
          <Image minWidth="80px" src={Refreshing} />
          <Box>
            <Box fontSize="14px" fontWeight="500" as="h3">
              This company is not published yet
            </Box>
            <Box fontSize="14px" mt="8px" as="p">
              Getro team will add information and publish it, usually it takes 2 business days. Optionally, you can edit
              and publish it. <AddCompanyProcess />
            </Box>
          </Box>
        </Flex>
      )}
      <Box sx={{ display: ['undef', 'undef', 'flex'], flex: ['undef', 'undef', 1] }}>
        <PageLayout.Content
          fullWidth={fullWidth}
          contentHeader={
            <Box width={1} as="nav" role="navigation" variant="navigation.subnav" mb={4} mt="16px">
              <Flex as="ul">
                <Box as="li" pr={1}>
                  <Button
                    as={NavLink}
                    variant="activeUnderline"
                    mr={2}
                    to={`/networks/${activeNetwork.slug}/companies/${match.params.companySlug}`}
                    exact
                  >
                    General
                  </Button>
                </Box>
                <Box as="li" px={1}>
                  <Button
                    as={NavLink}
                    variant="activeUnderline"
                    mx={2}
                    to={`/networks/${activeNetwork.slug}/companies/${match.params.companySlug}/jobs`}
                    exact
                  >
                    Jobs
                  </Button>
                </Box>
                <Box as="li" px={1}>
                  <Button
                    as={NavLink}
                    variant="activeUnderline"
                    mx={2}
                    to={`/networks/${activeNetwork.slug}/companies/${match.params.companySlug}/admins`}
                    exact
                  >
                    Admins
                  </Button>
                </Box>
              </Flex>
            </Box>
          }
        >
          {children}
        </PageLayout.Content>
      </Box>
    </>
  );
};

CompanyProfileLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  fullWidth: PropTypes.bool,
  showNotPublishedInfo: PropTypes.bool,
};

CompanyProfileLayout.defaultProps = {
  fullWidth: false,
  showNotPublishedInfo: false,
};
