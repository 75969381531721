import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { PrivateLock } from 'components/atoms/PrivateLock';
import { useJobDetail } from './hooks/useJobDetail';
import { JobDetailSkeleton } from './skeletons/jobDetail';

const JobDetail = ({ locations, skills, isLoading, isPrivate, organization, job }) => {
  const { jobName, ...rest } = useJobDetail({
    locations,
    skills,
    industryTags: organization?.industry_tags ?? [],
    stage: organization?.stage,
    approxEmployees: organization?.approx_employees,
    organizationName: organization?.name,
    job,
  });

  return (
    <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
      <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
        <Box
          sx={{
            fontSize: '12px',
            color: 'text.subtle',
            textTransform: 'uppercase',
            letterSpacing: '1px',
          }}
        >
          Job Details
        </Box>
        {isLoading && <JobDetailSkeleton />}
        {!isLoading && isPrivate && <PrivateLock text="Private job" />}
      </Flex>
      {!isLoading && (
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '4px',
            '& > p': {
              fontSize: '14px',
              color: 'text.main',
            },
          }}
        >
          {jobName && (
            <Text fontSize="14px" fontWeight="medium">
              {jobName}
            </Text>
          )}
          {Object.entries(rest).map(([key, value]) => (
            <Box as="p" key={key}>
              {value}
            </Box>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

JobDetail.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skills: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool,
  isPrivate: PropTypes.bool.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    stage: PropTypes.string,
    industry_tags: PropTypes.arrayOf(PropTypes.shape({})),
    approx_employees: PropTypes.number,
    head_count: PropTypes.number,
    logo_url: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
  job: PropTypes.object,
};

JobDetail.defaultProps = {
  isLoading: false,
  job: {},
};

export default JobDetail;
