import React from 'react';
import { ChevronDown, Mail, Power, User, UserCog } from 'lucide-react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Dropdown, ProfilePicture } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';

import { logOut, removeUser, setUserReady } from '../../../actions/userActions';
import { toggleMenu } from '../../../features/mainMenu';
import { getUserDisplayName } from '../../../helpers/users';
import { userSelector } from '../../../redux/selectors';
import { setNetworksReady } from '../../../actions/networksActions';
import { Card } from '../../atoms/Card';

const UserMenu = ({ isMenuOpen, onlyLogout, onLogout }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(userSelector);
  const { avatarUrl } = user;

  const handleLogout = () => {
    const width = window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

    if (width < 990) {
      dispatch(toggleMenu());
    }

    dispatch(removeUser());
    logOut();
    dispatch(setUserReady(true));
    dispatch(setNetworksReady(true));
    history.push('/login');

    if (onLogout) {
      onLogout();
    }
  };

  const dropdownTrigger = (
    <Flex alignItems="center" width="100%" sx={{ gap: '8px' }}>
      <Box width="24px" heigth="24px" flex="0 0 auto">
        <ProfilePicture imageUrl={avatarUrl} name={getUserDisplayName(user)} />
      </Box>
      <Text
        className="main-menu-item"
        textAlign="left"
        fontSize="14px"
        fontWeight="medium"
        flexGrow="1"
        sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', opacity: isMenuOpen ? 1 : 0 }}
      >
        {getUserDisplayName(user)}
      </Text>
      <Flex alignItems="center" color="text.subtle">
        <Box as={ChevronDown} width="16px" height="16px" aria-hidden="true" />
      </Flex>
    </Flex>
  );

  return user.email ? (
    <Dropdown
      placement="bottom-end"
      triggerProps={{ variant: 'styleless', p: '8px', children: dropdownTrigger, flex: '0 0 auto', width: '100%' }}
    >
      <Card width="200px">
        {!onlyLogout && (
          <>
            <Flex
              as={NavLink}
              to="/account/my-profile"
              sx={{
                color: 'text.main',
                textDecoration: 'none',
                cursor: 'pointer',
                fontSize: '14px',
                lineHeight: '24px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                py: '4px',
                ':hover': {
                  color: 'text.dark',
                },
              }}
              onClick={() => {
                const width =
                  window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

                if (width < 990) {
                  dispatch(toggleMenu());
                }
              }}
            >
              <Box as={User} size="16px" aria-hidden="true" />
              <Box as="span">Profile</Box>
            </Flex>
            <Flex
              as={NavLink}
              to="/account/notifications"
              sx={{
                color: 'text.main',
                textDecoration: 'none',
                cursor: 'pointer',
                fontSize: '14px',
                lineHeight: '24px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                py: '4px',
                ':hover': {
                  color: 'text.dark',
                },
              }}
              onClick={() => {
                const width =
                  window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

                if (width < 990) {
                  dispatch(toggleMenu());
                }
              }}
            >
              <Box as={Mail} size="16px" aria-hidden="true" />
              <Box as="span">Notifications</Box>
            </Flex>
            <Flex
              as={NavLink}
              to="/account/my-account"
              sx={{
                color: 'text.main',
                textDecoration: 'none',
                cursor: 'pointer',
                fontSize: '14px',
                lineHeight: '24px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                py: '4px',
                ':hover': {
                  color: 'text.dark',
                },
              }}
              onClick={() => {
                const width =
                  window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

                if (width < 990) {
                  dispatch(toggleMenu());
                }
              }}
            >
              <Box as={UserCog} size="16px" aria-hidden="true" />
              <Box as="span">Account</Box>
            </Flex>
            <Box height="1px" width="100%" my="8px" bg="border.subtle" />
          </>
        )}
        <Flex
          onClick={handleLogout}
          alignItems="center"
          sx={{
            color: 'text.main',
            textDecoration: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            py: '4px',
            ':hover': {
              color: 'text.dark',
            },
          }}
        >
          <Box as={Power} size="16px" aria-hidden="true" />
          Log out
        </Flex>
      </Card>
    </Dropdown>
  ) : null;
};

UserMenu.propTypes = {
  isMenuOpen: PropTypes.bool,
  onlyLogout: PropTypes.bool,
  onLogout: PropTypes.func,
};

UserMenu.defaultProps = {
  isMenuOpen: true,
  onlyLogout: false,
  onLogout: () => {},
};

export default UserMenu;
