import React, { useEffect } from 'react';
import { Flex, Box as FlexBox } from 'rebass/styled-components';
import { compose } from 'redux';
import { Box } from '@getro/rombo';
import { useGetGeneralSettingsQuery, useUpdateGeneralSettingsMutation } from 'services/network';
import { useSelector } from 'react-redux';
import { useGetJobBoardSettingsQuery, useUpdateJobBoardSettingsMutation } from 'services/networkV2';
import { trackEvent } from 'helpers/analytics';
import { SettingsLayout } from '../layout';
import pageHoc from '../../../hocs/pageHoc';
import withMainLayout from '../../../hocs/withMainLayout';
import { JobRules } from '../../../components/molecules/jobRules';
import useJobRules from '../../../hooks/jobRules/useJobRules';
import { activeNetworkSelector } from '../../../redux/selectors';
import JobBoardFilters from '../components/JobBoardFilters';
import { JobBoardSettings } from '../components/JobBoardSettings';

import PrivacyPolicy from '../components/PrivacyPolicy';
import { JobBoardAppearancePanel } from '../components/JobBoardAppearancePanel';

const pageTitle = 'Settings';

const JobBoard = () => {
  const { jobRules, isJobRulesLoading, loadJobRulesRequest } = useJobRules();
  const activeNetwork = useSelector(activeNetworkSelector);
  const [updateGeneralSettings, { isLoading: isSavingGdprSettings, isError: gdprSettingsSaveError }] =
    useUpdateGeneralSettingsMutation();
  const { data, isLoading: loadingSettings } = useGetJobBoardSettingsQuery();
  const { data: generalSettings, error: generalSettingsError } = useGetGeneralSettingsQuery();
  const [update, { isLoading }] = useUpdateJobBoardSettingsMutation();

  useEffect(() => {
    loadJobRulesRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SettingsLayout>
      <Flex>
        <FlexBox width={[1]}>
          <JobBoardAppearancePanel activeNetwork={activeNetwork} />
          <JobBoardSettings
            domain={activeNetwork.url}
            faviconUrl={data?.faviconUrl ?? ''}
            update={async (updates) =>
              update(updates).then(() => {
                trackEvent('network_settings:edit_job_board_general_settings', {
                  collectionId: activeNetwork.id,
                });
              })
            }
            isUpdating={isLoading}
            isLoading={loadingSettings}
          />
          <JobBoardFilters />
          <PrivacyPolicy
            activeNetwork={activeNetwork}
            generalSettings={generalSettings}
            fetchError={generalSettingsError}
            saveError={gdprSettingsSaveError}
            submitting={isSavingGdprSettings}
            onSubmit={updateGeneralSettings}
          />
          <Box title="Job rules">
            <JobRules
              isLoading={isJobRulesLoading}
              titles={jobRules.titles}
              keywords={jobRules.keywords}
              locations={jobRules.locations}
            />
          </Box>
        </FlexBox>
      </Flex>
    </SettingsLayout>
  );
};

export default compose(withMainLayout, pageHoc({ title: pageTitle }))(JobBoard);
