/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { call, takeLatest, select, takeEvery } from 'redux-saga/effects';

import { trackHomeLeftBeforeLoad } from 'pages/Impact/hooks/useAnalytics/actions';
import { trackPlansAndBilling } from 'pages/Settings/actions';
import {
  loadedPublishSuggestedCompany,
  loadedSkipSuggestedCompany,
  loadSuggestedBannerClicked,
  loadSuggestedBannerClosed,
} from '../Employers/hooks/useSuggestedCompanies/actions';
import {
  loadedAddRemoveMemberToList,
  loadedCreateAndAddMemberToList,
  createdNewBoard,
  loadedDeleteBoard,
  loadMembers,
  setFiltersModal,
  updatedBoard,
  boardChange,
  shareBoard,
} from '../Members/actions';
import { trackEvent } from '../../helpers/analytics';
import { prepareMembersFilterSearchEvent } from '../../helpers/membersFilter';
import {
  loadedSetAutoSubscribe,
  loadedSetDailyAlerts,
  loadedSubscribeAll,
  loadedUnsubscribeAll,
  toggleBoardSubscription,
} from '../manageSubscriptions/actions';
import { loadedSubscribeGroup } from '../../actions/talentGroupActions';
import { activeNetworkSelector } from '../../redux/selectors';
import { loadedSendShareEmail, noUserFoundInvite } from '../share/actions';
import { triggerTrackEvent } from '../CompanyProfile/actions';
import { EXPIRATION_OPTIONS } from '../../components/molecules/RenewJob/constants';
import {
  loadedBulkForceJobVisibility,
  loadedDeleteJob,
  loadedExtendJob,
  loadedForceJobVisibility,
  loadedFeatureJob,
  loadedCloseJob,
  loadedBulkForceAllJobsVisibility,
} from '../../hooks/useJobActions/actions';
import { loadedAddCompanyByName, loadedBulkAddCompanyByDomain } from '../AddCompany/redux/actions';
import { loadedRequestExportJobAlertSubscribers } from '../Settings/components/ExportJobAlertSubscribers/redux/actions';

function* analyticsTrackLoadMembers({ payload }) {
  const { activeNetworkId, filters } = payload;
  yield call(trackEvent, 'members:search', {
    ...prepareMembersFilterSearchEvent({ ...filters, collectionId: activeNetworkId }),
  });
}

function* toggleBoardSubscriptionEvent({ payload }) {
  const { origin, board, subscribe, activeNetwork } = payload;
  const eventName = subscribe ? 'members:subscribe_to_board' : 'members:unsubscribe_to_board';
  yield call(trackEvent, eventName, {
    origin,
    boardName: board.name,
    boardId: board.id,
    networkId: activeNetwork.id,
  });
}

function* loadedSetDailyAlertsEvent({ payload }) {
  const { hasDailyAlerts, activeNetworkId } = payload;
  const status = hasDailyAlerts ? 'on' : 'off';
  yield call(trackEvent, 'members:toggle_board_alerts', {
    origin: 'members_alerts_settings',
    networkId: activeNetworkId,
    status,
  });
}

function* loadedSubscribeAllEvent({ payload }) {
  const { activeNetwork } = payload;
  yield call(trackEvent, 'members:subscribe_to_all_boards', {
    origin: 'members_alerts_settings',
    networkId: activeNetwork.id,
  });
}

function* loadedUnsubscribeAllEvent({ payload }) {
  const { activeNetwork } = payload;
  yield call(trackEvent, 'members:unsubscribe_from_all_boards', {
    origin: 'members_alerts_settings',
    networkId: activeNetwork.id,
  });
}

function* loadedAddRemoveMemberToListEvent({ payload }) {
  const { origin, memberId, checked, talentGroup, activeNetworkId } = payload;
  const eventName = checked ? 'members:add_member_to_board' : 'members:remove_member_from_board';
  yield call(trackEvent, eventName, {
    origin,
    memberId,
    boardId: talentGroup.id,
    networkId: activeNetworkId,
  });
}

function* setFiltersModalEvent({ payload }) {
  const { board, activeNetworkId, isFiltersOpen } = payload;
  if (!isFiltersOpen) {
    yield call(trackEvent, 'members:open_filters', {
      collectionId: board.id || board.name,
      networkId: activeNetworkId,
    });
  }
}

function* loadedDeleteBoardEvent({ payload }) {
  const { collectionId } = payload;
  yield call(trackEvent, 'members:delete_board', {
    origin: 'board_settings',
    collectionId,
  });
}

function* createdNewBoardEvent({ payload }) {
  const { origin, activeNetworkId, board, hasSmartList } = payload;
  yield call(trackEvent, 'members:create_board', {
    origin,
    collectionId: activeNetworkId,
    boardId: board.id,
    smartFilters: hasSmartList,
  });
}

function* loadedCreateAndAddMemberToListEvent({ payload }) {
  const { memberId, origin, activeNetworkId, talentGroup } = payload;
  yield call(trackEvent, 'members:create_board', {
    origin,
    collectionId: activeNetworkId,
    boardId: talentGroup.id,
    smartFilters: false,
    memberId,
  });
}

function* updatedBoardEvent({ payload }) {
  const { activeNetworkId, board, hasSmartList } = payload;
  yield call(trackEvent, 'members:update_board', {
    collectionId: activeNetworkId,
    boardId: board.id,
    smartFilters: hasSmartList,
  });
}

function* loadedSetAutoSubscribeEvent({ payload }) {
  const { collectionId, hasAutoSubscribe } = payload;
  const status = hasAutoSubscribe ? 'on' : 'off';
  yield call(trackEvent, 'members:auto_subscribe', {
    collectionId,
    status,
  });
}

function* boardChangeEvent({ payload }) {
  const { board } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  yield call(trackEvent, 'members:view_board', {
    collectionId: activeNetwork.id,
    boardId: board.id,
    createdByNetwork: false,
    boardName: board.name,
  });
}

function* shareBoardEvent({ payload }) {
  const { board } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  yield call(trackEvent, 'members:share_network_click', {
    origin: 'board_header',
    collectionId: activeNetwork.id,
    boardId: board.id,
  });
}

function* loadedSendShareEmailEvent({ payload }) {
  const { boardIds, sharedWith, note } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  yield call(trackEvent, 'members:share_success', {
    sharedBoardIds: boardIds,
    numberBoardsShared: boardIds.length,
    numberPeopleShared: sharedWith.length,
    personalNotePresent: !note.length,
    collectionId: activeNetwork.id,
  });
}

function* noUserFoundInviteEvent({ payload }) {
  const { query } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  yield call(trackEvent, 'members:share_no_results_invite_click', {
    searchedString: query,
    collectionId: activeNetwork.id,
  });
}

function* loadedExtendJobEvent({ payload }) {
  const { job, companyId, expiresIn, source } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  const { isManager } = activeNetwork;
  const { id: jobId } = job;

  const eventProperties = {
    organization_id: companyId,
    job_id: jobId,
    is_network_admin: isManager,
  };

  if (expiresIn) {
    eventProperties.expiration_date = EXPIRATION_OPTIONS.find(({ value }) => value === parseInt(expiresIn, 10)).label;
  }

  yield call(trackEvent, `${source}:renew_job`, eventProperties);
}

function* triggerTrackEventEvent({ payload }) {
  const { name, eventProperties } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  const { isManager } = activeNetwork;
  yield call(trackEvent, name, {
    ...eventProperties,
    is_network_admin: isManager,
  });
}

function* loadedCloseJobEvent({ payload }) {
  const {
    jobId: job_id,
    companyId: organization_id,
    networkId: network_id,
    isNetworkManager: is_network_admin,
    source,
  } = payload;

  yield call(trackEvent, `${source}:close_job`, {
    job_id,
    organization_id,
    network_id,
    is_network_admin,
  });
}

function* loadedDeleteJobEvent({ payload }) {
  const { job } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id, isManager: is_network_admin } = activeNetwork;

  yield call(trackEvent, 'jobs:delete_job', {
    job_id: job.id,
    organization_id: job.organization.id,
    network_id,
    is_network_admin,
  });
}

function* loadedAddCompanyByNameEvent({ payload }) {
  const { id, name } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id } = activeNetwork;

  yield call(trackEvent, 'add_employer:submit_without_domain', {
    organization_id: id,
    network_id,
    name,
  });
}

function* loadedRequestExportJobAlertSubscribersEvent() {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id, isManager: is_network_admin } = activeNetwork;

  yield call(trackEvent, 'job_seeker_notifications:subscribers_dump_request', {
    network_id,
    is_network_admin,
  });
}

function* loadedFeatureJobEvent({ payload }) {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id, isManager: is_network_admin } = activeNetwork;
  const { job, featured, source } = payload;

  yield call(trackEvent, `${source}:update_featured_status`, {
    network_id,
    is_network_admin,
    job_id: job.id,
    organization_id: job.organization.id,
    featured: featured === 'featured',
  });
}

function* loadedBulkForceJobVisibilityEvent({ payload }) {
  const { source, ids: selected_jobs, forcedVisibility: visibility } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id, isManager: is_network_admin } = activeNetwork;

  yield call(trackEvent, `${source}:update_visibility`, {
    network_id,
    is_network_admin,
    multiple_selection: true,
    selected_jobs,
    visibility,
  });
}

function* loadedBulkForceAllJobVisibilityEvent({ payload }) {
  const { source, forcedVisibility: visibility, companyId: company_id } = payload;
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id, isManager: is_network_admin } = activeNetwork;

  yield call(trackEvent, `${source}:update_visibility`, {
    network_id,
    is_network_admin,
    multiple_selection: true,
    visibility,
    company_id,
  });
}

function* loadedForceJobVisibilityEvent({ payload: { job, forcedVisibility, source } }) {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id, isManager: is_network_admin } = activeNetwork;

  yield call(trackEvent, `${source}:update_visibility`, {
    network_id,
    is_network_admin,
    multiple_selection: false,
    organization_id: job.organization.id,
    job_id: job.id,
    visibility: forcedVisibility,
  });
}

function* loadedBulkAddCompanyByDomainVerifyEvent({
  payload: {
    meta: { created, failed, published, notActive },
  },
}) {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id } = activeNetwork;
  const notAddedDomains = [...failed, ...notActive];

  yield call(trackEvent, `add_employer:employer_added`, {
    network_id,
    total_domains: failed.length + created + published + notActive.length,
    added_domains_qa_true: published,
    added_domains_qa_false: created,
    not_added_domains: notAddedDomains.length,
  });

  for (let i = 0, l = failed.length; i < l; i += 1) {
    yield call(trackEvent, `add_employer:failed`, {
      network_id,
      ...notAddedDomains[i],
    });
  }

  for (let i = 0, l = notActive.length; i < l; i += 1) {
    yield call(trackEvent, `add_employer:failed`, {
      network_id,
      domain: notAddedDomains[i],
      error: 'The organization has been added but it is not active',
    });
  }
}

function* loadSuggestedCompaniesPublishedEvent({ payload: { companyId } }) {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id } = activeNetwork;

  yield call(trackEvent, `employers:suggestion_added`, {
    network_id,
    source: 'new_suggestions',
    organization_id: companyId,
  });
}

function* loadSuggestedCompaniesSkippedEvent({ payload: { companyId } }) {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id } = activeNetwork;

  yield call(trackEvent, `employers:suggestion_skipped`, {
    network_id,
    organization_id: companyId,
  });
}

function* loadSuggestedCompaniesSkippedReasonEvent({ payload: { companyId, reason, otherValue } }) {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id } = activeNetwork;

  yield call(trackEvent, `employers:suggestion_skipped_reason`, {
    network_id,
    organization_id: companyId,
    reason: reason === 'Other' && otherValue ? otherValue : reason,
  });
}

function* loadSuggestedCompaniesBannerClickedEvent() {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id } = activeNetwork;

  yield call(trackEvent, `new_suggested_companies_banner:cta_click`, {
    network_id,
    source: 'employer',
  });
}

function* loadSuggestedCompaniesBannerClosedEvent() {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id } = activeNetwork;

  yield call(trackEvent, `new_suggested_companies_banner:close`, {
    network_id,
    source: 'employer',
  });
}

function* trackHomeLeftBeforeLoadEvent() {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id } = activeNetwork;

  yield call(trackEvent, 'home:left_before_loaded', {
    network_id,
  });
}

function* trackPlansAndBillingEvent({ payload: { eventName } }) {
  const activeNetwork = yield select(activeNetworkSelector);
  const { id: network_id } = activeNetwork;

  yield call(trackEvent, `plans_billing:${eventName}`, {
    network_id,
  });
}

/**
 * When we want to track something instead of filling up the saga with events we should but them here.
 * We can listen for the action and track here as we want. Because the component wraps the whole site the saga
 * is able to listen for all actions from all the children.
 */
export default function* analyticsTrackingSaga() {
  yield takeLatest(loadMembers().type, analyticsTrackLoadMembers);
  yield takeLatest(toggleBoardSubscription().type, toggleBoardSubscriptionEvent);
  yield takeLatest(loadedSubscribeGroup().type, toggleBoardSubscriptionEvent);
  yield takeLatest(loadedSetDailyAlerts().type, loadedSetDailyAlertsEvent);
  yield takeLatest(loadedSubscribeAll().type, loadedSubscribeAllEvent);
  yield takeLatest(loadedUnsubscribeAll().type, loadedUnsubscribeAllEvent);
  yield takeLatest(loadedAddRemoveMemberToList().type, loadedAddRemoveMemberToListEvent);
  yield takeLatest(setFiltersModal().type, setFiltersModalEvent);
  yield takeLatest(loadedDeleteBoard().type, loadedDeleteBoardEvent);
  yield takeLatest(createdNewBoard().type, createdNewBoardEvent);
  yield takeLatest(loadedCreateAndAddMemberToList().type, loadedCreateAndAddMemberToListEvent);
  yield takeLatest(updatedBoard().type, updatedBoardEvent);
  yield takeLatest(loadedSetAutoSubscribe().type, loadedSetAutoSubscribeEvent);
  yield takeLatest(boardChange().type, boardChangeEvent);
  yield takeLatest(shareBoard().type, shareBoardEvent);
  yield takeLatest(loadedSendShareEmail().type, loadedSendShareEmailEvent);
  yield takeLatest(noUserFoundInvite().type, noUserFoundInviteEvent);
  yield takeEvery(triggerTrackEvent().type, triggerTrackEventEvent);
  yield takeLatest(loadedExtendJob().type, loadedExtendJobEvent);
  yield takeLatest(loadedCloseJob().type, loadedCloseJobEvent);
  yield takeLatest(loadedDeleteJob().type, loadedDeleteJobEvent);
  yield takeLatest(loadedAddCompanyByName().type, loadedAddCompanyByNameEvent);
  yield takeLatest(loadedRequestExportJobAlertSubscribers().type, loadedRequestExportJobAlertSubscribersEvent);
  yield takeLatest(loadedFeatureJob().type, loadedFeatureJobEvent);
  yield takeLatest(loadedBulkForceJobVisibility().type, loadedBulkForceJobVisibilityEvent);
  yield takeLatest(loadedBulkForceAllJobsVisibility().type, loadedBulkForceAllJobVisibilityEvent);
  yield takeLatest(loadedForceJobVisibility().type, loadedForceJobVisibilityEvent);
  yield takeLatest(loadedBulkAddCompanyByDomain().type, loadedBulkAddCompanyByDomainVerifyEvent);
  yield takeLatest(loadedSkipSuggestedCompany().type, loadSuggestedCompaniesSkippedEvent);
  yield takeLatest(loadedSkipSuggestedCompany().type, loadSuggestedCompaniesSkippedReasonEvent);
  yield takeLatest(loadSuggestedBannerClicked().type, loadSuggestedCompaniesBannerClickedEvent);
  yield takeLatest(loadSuggestedBannerClosed().type, loadSuggestedCompaniesBannerClosedEvent);
  yield takeLatest(loadedPublishSuggestedCompany().type, loadSuggestedCompaniesPublishedEvent);
  yield takeLatest(trackHomeLeftBeforeLoad().type, trackHomeLeftBeforeLoadEvent);
  yield takeLatest(trackPlansAndBilling().type, trackPlansAndBillingEvent);
}
