import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import GroupRadioSelector from 'components/atoms/groupRadioSelector';

import MultiSelect from '../../atoms/MultiSelect';
import { ApiFacet } from './apiFacet';
import {
  resumeOptions,
  signUpDayOptions,
  jobSearchStatusesOptions,
  signupSenioritiesOptions,
  signupWorkEnvironmentsOptions,
} from './defaultFilters';

export const FiltersPreferences = ({ sharedList, filters, setFilters, isEdit }) => {
  const isDisabled = filters?.hasSignupInfo?.value === 'not_completed';
  const [isOpen, setIsOpen] = useState(isEdit && !isDisabled);

  return (
    <Box width="100%">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        sx={{ cursor: isDisabled ? 'auto' : 'pointer', color: 'text.subtle' }}
        onClick={isDisabled ? null : () => setIsOpen(!isOpen)}
        height="20px"
      >
        <Text
          fontSize="12px"
          fontWeight="medium"
          color="text.subtle"
          as="p"
          sx={{ textTransform: 'uppercase', letterSpacing: '1px', lineHeight: '1,4' }}
        >
          Preferences
        </Text>
        {!isDisabled && <Box as={isOpen ? ChevronUp : ChevronDown} size="16px" aria-hidden="true" />}
      </Flex>
      {isDisabled && (
        <Text mt="4px" fontSize="12px" color="text.subtle">
          Only available for talent network signups, include them to enable these filters.
        </Text>
      )}
      {isOpen && !isDisabled && (
        <>
          <Text as="p" mt="4px" fontSize="12px" color="text.subtle">
            Filters below only apply to talent network signups.
          </Text>
          <Flex
            mt="8px"
            flexDirection="column"
            sx={{
              gap: '4px',
              '& > div': {
                py: '8px',
              },
            }}
          >
            <Flex width="100%" flexDirection="column" sx={{ gap: '8px' }}>
              <ApiFacet
                name="signup_job_functions"
                label="Job function"
                value={filters.signupJobFunctions}
                onChange={(value) => setFilters({ signupJobFunctions: value })}
                notFoundText="No contacts with job function"
                sharedList={sharedList}
                orderBy="alpha"
                useSearchAsTag
                useInitialOpenDropDown
              />
            </Flex>
            <Box width="100%">
              <MultiSelect
                title="Level of experience"
                options={signupSenioritiesOptions}
                selected={filters.signupSeniorities ?? []}
                onSelect={(values) => setFilters({ signupSeniorities: values })}
                showSearch={false}
              />
            </Box>
            <Box width="100%">
              <MultiSelect
                title="Work environment"
                options={signupWorkEnvironmentsOptions}
                selected={filters.signupWorkEnvironments ?? []}
                onSelect={(values) => setFilters({ signupWorkEnvironments: values })}
                showSearch={false}
              />
            </Box>
            <Box width="100%">
              <ApiFacet
                name="signup_locations"
                label="Preferred locations"
                value={filters.signupLocations}
                onChange={(value) => setFilters({ signupLocations: value })}
                notFoundText="No contacts with preferred locations"
                sharedList={sharedList}
              />
            </Box>
            <Box width="100%">
              <MultiSelect
                title="Job search status"
                options={jobSearchStatusesOptions}
                selected={filters.signupJobSearchStatuses ?? []}
                onSelect={(values) => setFilters({ signupJobSearchStatuses: values })}
                showSearch={false}
              />
            </Box>
            <Box width="100%">
              <GroupRadioSelector
                title="Resume"
                placeholder="Any"
                options={resumeOptions}
                selected={filters.signupHasResume || resumeOptions[0]}
                onSelect={(value) => setFilters({ signupHasResume: value })}
              />
            </Box>
            <Box width="100%">
              <GroupRadioSelector
                title="Signup date"
                placeholder="Any"
                options={signUpDayOptions}
                selected={filters.signupDaysSinceJoined || signUpDayOptions[0]}
                onSelect={(value) => setFilters({ signupDaysSinceJoined: value })}
              />
            </Box>
          </Flex>
        </>
      )}
    </Box>
  );
};

FiltersPreferences.propTypes = {
  sharedList: PropTypes.object,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

FiltersPreferences.defaultProps = {
  sharedList: null,
};
