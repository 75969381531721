import React from 'react';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';

const getParams = ({ search }) => qs.parse(search, { arrayFormat: 'bracket' });

const setter = (history) => (newPage) => {
  const { page = 1, ...params } = getParams(history.location);

  if (newPage === page) {
    return;
  }

  let search = { ...params };
  if (newPage > 1) {
    search = { ...search, page: newPage };
  }

  history.push({
    pathname: history.location.pathname,
    search: `?${qs.stringify(search)}`,
  });
};

const withPage = (Component) => {
  const Wrapped = (props) => {
    const history = useHistory();
    const { page = 1 } = getParams(history.location);

    return <Component {...props} history={history} page={parseInt(page, 10)} setPage={setter(history)} />;
  };

  Wrapped.displayName = `withPage(${Component.displayName || Component.name || 'Component'})`;

  return Wrapped;
};

export default withPage;
