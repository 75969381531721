import { createActions } from 'redux-actions';

export const { loadCompany, loadedCompany, errorCompany } = createActions(
  'LOAD_COMPANY',
  'LOADED_COMPANY',
  'ERROR_COMPANY',
);

export const { loadUpdateCompany, loadedUpdateCompany, errorUpdateCompany } = createActions(
  'LOAD_UPDATE_COMPANY',
  'LOADED_UPDATE_COMPANY',
  'ERROR_UPDATE_COMPANY',
);

export const { loadAddAdmin, loadedAddAdmin, errorAddAdmin } = createActions(
  'LOAD_ADD_ADMIN',
  'LOADED_ADD_ADMIN',
  'ERROR_ADD_ADMIN',
);

export const { loadRemoveAdmin, loadedRemoveAdmin, errorRemoveAdmin } = createActions(
  'LOAD_REMOVE_ADMIN',
  'LOADED_REMOVE_ADMIN',
  'ERROR_REMOVE_ADMIN',
);

export const { loadResendAdminInvite, loadedResendAdminInvite, errorResendAdminInvite } = createActions(
  'LOAD_RESEND_ADMIN_INVITE',
  'LOADED_RESEND_ADMIN_INVITE',
  'ERROR_RESEND_ADMIN_INVITE',
);

export const { resetCompanyError } = createActions('RESET_COMPANY_ERROR');
