import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from 'rebass';
import toast from 'react-hot-toast';
import { Globe, KeyRound, Lock } from 'lucide-react';
import { activeNetworkSelector } from 'redux/selectors';
import { Flex } from 'rebass/styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import { SharedListsModal } from 'components/molecules/sharedListsModal';
import { useDeleteSharedListMutation, useUpdateSharedListMutation } from 'services/contacts';
import { ShareSharedListModal } from 'components/molecules/shareSharedListModal';
import { trackEvent } from 'helpers/analytics';

export const ShareList = ({ sharedList }) => {
  const { listId } = useParams();
  const activeNetwork = useSelector(activeNetworkSelector);
  const [updateSharedList, { isLoading: isLoadingUpdate }] = useUpdateSharedListMutation();
  const [deleteSharedList, { isLoading: isLoadingDelete }] = useDeleteSharedListMutation();
  const [showShareSharedListModal, setShowShareSharedListModal] = useState(false);
  const [showEditListModal, setShowEditListModal] = useState(false);
  const history = useHistory();

  const onEditSharedList = async (values, { closeModal = true, updateMessage } = {}) => {
    const { error } = await updateSharedList({ ...values, listId });
    if (!error) {
      setShowEditListModal(false);
      setShowShareSharedListModal(!closeModal);

      toast(updateMessage || 'List updated', { icon: 'success', id: 'shared-lists-success' });
      trackEvent('contacts:list:edit_list', {
        collectionId: activeNetwork.id,
        listId,
      });
    }
    return true;
  };

  const onDeleteSharedList = async () => {
    const { error } = await deleteSharedList({ listId });
    if (!error) {
      setShowEditListModal(false);
      history.push(`/networks/${activeNetwork.slug}/lists`);

      toast('List successfully removed', { icon: 'success', id: 'shared-lists-remove' });
      trackEvent('contacts:list:delete_list', {
        collectionId: activeNetwork.id,
        listId,
      });
    }
  };

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Button variant="accent" size="small" flex="0 0 auto" onClick={() => setShowShareSharedListModal(true)}>
          <Flex data-testid="button-share-list" alignItems="center">
            {!sharedList?.shareable && (
              <>
                <Box as={Lock} mr="2" width="16px" height="16px" aria-hidden="true" />
                Share
              </>
            )}
            {sharedList?.shareable && sharedList.passwordProtected && (
              <>
                <Box as={KeyRound} mr="2" width="16px" height="16px" aria-hidden="true" />
                Shared
              </>
            )}
            {sharedList?.shareable && !sharedList.passwordProtected && (
              <>
                <Box as={Globe} mr="2" width="16px" height="16px" aria-hidden="true" />
                Shared
              </>
            )}
          </Flex>
        </Button>
      </Flex>
      {showEditListModal && (
        <SharedListsModal
          list={sharedList}
          isLoading={isLoadingUpdate || isLoadingDelete}
          onEditSharedList={onEditSharedList}
          onDeleteSharedList={onDeleteSharedList}
          onClose={() => setShowEditListModal(false)}
        />
      )}
      {showShareSharedListModal && (
        <ShareSharedListModal
          list={sharedList}
          isLoading={isLoadingUpdate}
          onEditSharedList={onEditSharedList}
          onClose={() => setShowShareSharedListModal(false)}
        />
      )}
    </Box>
  );
};

ShareList.propTypes = {
  sharedList: PropTypes.object,
};

ShareList.defaultProps = {
  sharedList: null,
};
