import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';

import UserMenu from '../../molecules/UserMenu';

const BlankLayout = ({ children }) => (
  <Box p={['24px', '40px']} minHeight="100vh" bg="neutral.0">
    <Flex alignItems="flex-start" justifyContent="space-between">
      <Logo width="180px" />
      <UserMenu onlyLogout />
    </Flex>

    <Box m="0 auto" mt="40px" maxWidth={['auto', '720px']}>
      {children}
    </Box>
  </Box>
);

BlankLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlankLayout;
