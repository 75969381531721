import React from 'react';
import { Button, Modal } from '@getro/rombo';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass/styled-components';

export const DeleteSharedListModal = ({ list, isLoading, onCloseModal, onDeleteSharedList }) => (
  <Modal
    title="Delete list?"
    isOpen
    onCancel={onCloseModal}
    actions={
      <Flex sx={{ gap: '8px', justifyContent: 'flex-end' }}>
        <Button variant="secondary" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button data-testid="delete-btn" variant="danger" onClick={() => onDeleteSharedList(list)} loading={isLoading}>
          Delete list
        </Button>
      </Flex>
    }
  >
    <Text>
      By deleting the list
      <Text as="spawn" fontWeight="500">
        {` ${list.name}`}
      </Text>
      , you’ll also turn off its shared access if it’s currently active. No contacts will be removed from your network.
    </Text>
  </Modal>
);

DeleteSharedListModal.propTypes = {
  list: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onDeleteSharedList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

DeleteSharedListModal.defaultProps = {
  isLoading: false,
};
