import MainLayout from 'components/layout/Main';
import React, { useEffect, useRef } from 'react';
import pageHoc from 'hocs/pageHoc';
import { Loader, MessageBlock } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import { activeNetworkSelector, isGetroUserSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useGetChargeBessSessionMutation, useGetSubscriptionsQuery } from 'services/organizations';
import { trackEvent } from 'helpers/analytics';
import { SettingsLayout } from '../layout';
import { trackPlansAndBilling } from '../actions';
import { PlansBox } from './plansBox';
import { UpgradeConnectLimit } from './upgradeConnectLimit';

const pageTitle = 'Settings';

export const PlansAndBilling = () => {
  const network = useSelector(activeNetworkSelector);
  const [getChargeBessSession] = useGetChargeBessSessionMutation();
  const { data: subscriptions, error, isLoading } = useGetSubscriptionsQuery(network.id);
  const isGetroUser = useSelector(isGetroUserSelector);
  const dispatch = useDispatch();
  const cbInstance = useRef(null);

  useEffect(() => {
    if (cbInstance.current || !isGetroUser) {
      return () => {};
    }

    cbInstance.current = window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE,
    });

    cbInstance.current.setPortalSession(async () => {
      const { data } = await getChargeBessSession(network.id);
      return data;
    });

    return () => {};
  }, [getChargeBessSession, isGetroUser, network.id]);

  useEffect(
    () => () => {
      if (cbInstance.current) {
        cbInstance.current.logout();
        cbInstance.current = null;
      }
    },
    [],
  );

  if (!isGetroUser) return null;

  const handlePortal = (type) => {
    const sources = window.Chargebee.getPortalSections();

    cbInstance.current.createChargebeePortal().openSection({
      sectionType: type,
    });

    let eventName;
    switch (type) {
      case sources.PAYMENT_SOURCES:
        eventName = 'open_update_payment_method_widget';
        break;
      case sources.ADDRESS:
        eventName = 'open_update_billing_info_widget';
        break;
      case sources.BILLING_HISTORY:
        eventName = 'open_billing_history_widget';
        break;
      default:
        break;
    }

    dispatch(trackPlansAndBilling({ eventName }));
  };
  const { connectTier } = network;

  const onUpgradeClicked = () => {
    trackEvent('connect_upsell:upsell_banner:cta_click', {
      origin: 'plan_details',
      page_type: 'plans_and_billing',
    });
  };

  const connectSubscription = subscriptions?.activeSubscriptions?.find(
    (subscription) => subscription.subscriptionItems[0].itemPriceFamilyId === 'GetroConnect',
  );

  const hasErrorOrWarning = !isLoading && (error || subscriptions.activeSubscriptions <= 0);

  return (
    <MainLayout>
      <SettingsLayout>
        {isLoading && (
          <Flex mt="40px" justifyContent="center" width="100%">
            <Loader />
          </Flex>
        )}
        {hasErrorOrWarning && (
          <MessageBlock
            sx={{
              mt: '40px',
            }}
            type="warning"
            title="This information is currently unavailable"
          >
            <Box>
              Please reach out to{' '}
              <Box as="a" href="/">
                help@getro.com
              </Box>{' '}
              to see your billing details.
            </Box>
          </MessageBlock>
        )}
        {!hasErrorOrWarning && !isLoading && subscriptions?.activeSubscriptions?.length > 0 && (
          <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
            {subscriptions.activeSubscriptions.map((subscription) => (
              <PlansBox
                key={subscription.id}
                subscription={subscription.subscriptionItems[0]}
                connectTier={connectTier}
                onUpgradeClicked={onUpgradeClicked}
                networkSlug={network.slug}
                billingPeriod={subscription.billingPeriod}
                billingPeriodUnit={subscription.billingPeriodUnit}
                currencyCode={subscription.currencyCode}
                upcomingInvoiceTotal={subscription.upcomingInvoiceTotal}
                nextBillingAt={subscription.nextBillingAt}
                coupon={subscription.coupon}
                status={subscription.status}
                currentTermEnd={subscription.currentTermEnd}
              />
            ))}
            {connectSubscription && connectSubscription.subscriptionItems[0]?.stairstep && (
              <UpgradeConnectLimit
                connectTier={connectTier}
                subscription={connectSubscription}
                onUpgradeClicked={onUpgradeClicked}
                completion={
                  (connectSubscription.subscriptionItems[0].stairstep.used /
                    connectSubscription.subscriptionItems[0].stairstep.total) *
                  100
                }
                networkSlug={network.slug}
              />
            )}
          </Flex>
        )}
        {!hasErrorOrWarning && !isLoading && (
          <>
            <Flex
              mt="32px"
              flexDirection={['column', 'row']}
              sx={{ gap: '16px', a: { color: 'text.main', fontSize: '14px', lineHeight: '16px' } }}
            >
              <Box
                as="a"
                onClick={() => handlePortal(window.Chargebee.getPortalSections().PAYMENT_SOURCES)}
                data-cb-type="portal"
              >
                Update payment method
              </Box>
              <Box as="a" onClick={() => handlePortal(window.Chargebee.getPortalSections().ADDRESS)} to="#">
                Update billing information
              </Box>
              <Box as="a" onClick={() => handlePortal(window.Chargebee.getPortalSections().BILLING_HISTORY)}>
                Billing history
              </Box>
            </Flex>
          </>
        )}
      </SettingsLayout>
    </MainLayout>
  );
};

export default pageHoc({ title: pageTitle })(PlansAndBilling);
