import { Button, Modal } from '@getro/rombo';
import { Code, Users, HelpCircle, Bell } from 'lucide-react';
import React, { useState } from 'react';
import { Box, Flex, Image, Text, Link } from 'rebass/styled-components';

import ImportContactsIcon from '../../../assets/icon-getro-network.svg';
import ContactsSharedListSchema from '../../../schemas/contactsSharedList';

const HowItWorks = ({ listInfo, ...otherProps }) => {
  const [showHowItWorks, setShowHowItWorks] = useState(false);

  return (
    <Box {...otherProps}>
      <Box
        as={HelpCircle}
        sx={{ cursor: 'pointer' }}
        width="16px"
        height="16px"
        onClick={() => setShowHowItWorks(true)}
        data-testid="how-it-works-trigger"
      />
      {showHowItWorks && (
        <Modal
          isOpen={showHowItWorks}
          actions={
            <Flex justifyContent="flex-end">
              <Button onClick={() => setShowHowItWorks(false)}>Got it</Button>
            </Flex>
          }
          onCancel={() => setShowHowItWorks(false)}
        >
          <Flex mt="8px" sx={{ columnGap: [0, '16px'], lineHeight: '140%' }}>
            <Image
              src={ImportContactsIcon}
              alt="Getro Network"
              display={['none', 'block']}
              width="96px"
              height="96px"
              flex="0 0 auto"
            />
            <Box flexGrow="1">
              <Text fontWeight="semibold" color="text.dark" fontSize="3">
                Get introduced
              </Text>
              <Text mt="8px" sx={{ lineHeight: '140% !important' }}>
                {listInfo.collection?.name} wants to help you hit key milestones by connecting with the right people in
                their network.
              </Text>
            </Box>
          </Flex>
          <Text mt="56px" fontWeight="semibold" color="text.dark">
            How it works
          </Text>
          <Flex mt="32px">
            <Flex
              bg="purple.100"
              color="purple.500"
              height="32px"
              width="32px"
              flex="0 0 auto"
              alignItems="center"
              justifyContent="center"
              sx={{ borderRadius: 'circle' }}
            >
              <Box as={Users} width="16px" height="16px" aria-hidden="true" />
            </Flex>
            <Box flexGrow="1" ml="16px">
              <Text fontWeight="medium" color="text.dark" fontSize="14px">
                Easily find people to connect with
              </Text>
              <Text fontSize="14px" sx={{ lineHeight: '140% !important' }}>
                Identify people in the {listInfo.collection?.name} network who you’d like to meet.
              </Text>
            </Box>
          </Flex>
          <Flex mt="32px">
            <Flex
              bg="purple.100"
              color="purple.500"
              height="32px"
              width="32px"
              flex="0 0 auto"
              alignItems="center"
              justifyContent="center"
              sx={{ borderRadius: 'circle' }}
            >
              <Box as={Bell} width="16px" height="16px" aria-hidden="true" />
            </Flex>
            <Box flexGrow="1" ml="16px">
              <Text fontWeight="medium" color="text.dark" fontSize="14px">
                Stay in the loop
              </Text>
              <Text fontSize="14px" sx={{ lineHeight: '140% !important' }}>
                Subscribe to lists to receive updates when new people are added. Opt for updates via email or Slack.{' '}
                <Link href="https://www.getro.com" rel="noopener noreferrer" target="_blank" color="text.main">
                  Learn more
                </Link>
                .
              </Text>
            </Box>
          </Flex>
          {listInfo.showPointOfContact && (
            <Flex mt="32px">
              <Flex
                bg="purple.100"
                color="purple.500"
                height="32px"
                width="32px"
                flex="0 0 auto"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: 'circle' }}
              >
                <Box as={Code} width="16px" height="16px" aria-hidden="true" />
              </Flex>
              <Box flexGrow="1" ml="16px">
                <Text fontWeight="medium" color="text.dark" fontSize="14px">
                  Get a warm intro
                </Text>
                <Text fontSize="14px" sx={{ lineHeight: '140% !important' }}>
                  Found someone interesting? Simply copy their LinkedIn URL and email{' '}
                  <Link
                    href={`mailto:${listInfo.pointOfContact.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="text.main"
                  >
                    {listInfo.pointOfContact.fullName}
                  </Link>{' '}
                  for a warm introduction. Pro tip: A{' '}
                  <Link href="https://www.getro.com" rel="noopener noreferrer" target="_blank" color="text.main">
                    forwardable email
                  </Link>{' '}
                  saves everyone time!
                </Text>
              </Box>
            </Flex>
          )}
        </Modal>
      )}
    </Box>
  );
};

HowItWorks.propTypes = {
  listInfo: ContactsSharedListSchema.isRequired,
};

export default HowItWorks;
