import React, { useState } from 'react';
import { FormField, MessageBlock } from '@getro/rombo';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Box, Flex } from 'rebass/styled-components';

import capitalize from 'helpers/capitalize';
import Modal from 'components/atoms/Modal';
import { trackException } from 'helpers/errorTracker';
import formSchema from './formSchema';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const InviteAdmin = ({
  addAdmin,
  inviter,
  adminType,
  onCloseModal,
  open,
  setModalOpen,
  showTalentNetworkCopy,
  networkName,
}) => {
  const [success, setSuccess] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
    setSuccess(false);

    onCloseModal();
  };

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    const { firstName, lastName, email } = values;
    setStatus({ error: false });
    setSuccess(false);

    try {
      await addAdmin(firstName, lastName, email);
      setSubmitting(false);
      setSuccess(true);
    } catch (error) {
      trackException(error);
      setSubmitting(false);
      setStatus({ error: true });
    }
  };

  const getRole = (capitalized = false) => {
    let result;

    if (adminType === 'company') {
      result = 'company admin';
    } else if (showTalentNetworkCopy) {
      result = 'network admin';
    } else {
      result = 'job board admin';
    }

    if (capitalized) {
      return capitalize(result);
    }

    return result;
  };

  return (
    <Modal
      formik={!success}
      formikProps={{
        initialValues,
        validationSchema: formSchema,
      }}
      title={success ? '' : `Invite ${getRole()} for ${inviter}`}
      saveText={`Invite ${getRole()}`}
      onSubmit={handleSubmit}
      open={open}
      cancelText="Cancel"
      onCancelClick={closeModal}
      simple={success}
    >
      {success && (
        <MessageBlock
          type="success"
          title={`${getRole(true)} invited`}
          content={
            showTalentNetworkCopy
              ? 'They can now manage their company information and post jobs, as well as request and receive introductions.'
              : 'They can now manage their company information and post jobs.'
          }
          buttonText="Ok"
          onClick={closeModal}
        />
      )}

      {!success && (
        <>
          <p>
            {showTalentNetworkCopy ? (
              <>
                Company admins can help you manage their company profile and jobs sources and access the{' '}
                <b>{networkName}</b> talent network.
              </>
            ) : (
              <>Company admins can help you manage their company profile and jobs sources.</>
            )}
          </p>
          <Flex justifyContent="space-between" pb="3" sx={{ columnGap: 4 }}>
            <Box flex="1" className="InviteAdmin__input">
              <Field type="text" name="firstName" component={FormField} label="First name *" />
            </Box>
            <Box flex="1" className="InviteAdmin__input">
              <Field type="text" name="lastName" component={FormField} label="Last name" />
            </Box>
          </Flex>
          <Field type="email" name="email" component={FormField} label="Email address *" />
        </>
      )}
    </Modal>
  );
};

InviteAdmin.propTypes = {
  addAdmin: PropTypes.func.isRequired,
  inviter: PropTypes.string.isRequired,
  adminType: PropTypes.oneOf(['company', 'network']),
  onCloseModal: PropTypes.func,
  open: PropTypes.bool,
  setModalOpen: PropTypes.func,
  showTalentNetworkCopy: PropTypes.bool,
  networkName: PropTypes.string.isRequired,
};

InviteAdmin.defaultProps = {
  adminType: 'company',
  onCloseModal: () => {},
  open: false,
  setModalOpen: () => {},
  showTalentNetworkCopy: false,
};

export default InviteAdmin;
