import React from 'react';
import PropTypes from 'prop-types';

import GroupRadioSelector from 'components/atoms/groupRadioSelector';
import { inPortoOptions } from './defaultFilters';

export const InPortcoFilter = ({ value, onChange }) => (
  <GroupRadioSelector
    title="Working at network companies"
    placeholder="Include"
    options={inPortoOptions}
    selected={value}
    onSelect={(values) => onChange(values)}
  />
);

InPortcoFilter.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

InPortcoFilter.defaultProps = {
  value: { label: 'Include', value: 'any' },
};
