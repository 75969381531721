export const FETCH_INTRODUCTION_REQUESTS = 'FETCH_INTRODUCTION_REQUESTS';
export const FETCH_INTRODUCTION_REQUESTS_SUCCESS = 'FETCH_INTRODUCTION_REQUESTS_SUCCESS';
export const FETCH_INTRODUCTION_REQUESTS_FAILURE = 'FETCH_INTRODUCTION_REQUESTS_FAILURE';
export const DECLINE_INTRODUCTION_REQUEST = 'DECLINE_INTRODUCTION_REQUEST';
export const MARK_AS_DONE_INTRODUCTION_REQUEST = 'MARK_AS_DONE_INTRODUCTION_REQUEST';

export const fetchIntroductionRequests = (collectionId) => ({
  type: FETCH_INTRODUCTION_REQUESTS,
  collectionId,
});

export const fetchIntroductionRequestsFailure = () => ({
  type: FETCH_INTRODUCTION_REQUESTS_FAILURE,
});

export const fetchIntroductionRequestsSuccess = (payload) => ({
  type: FETCH_INTRODUCTION_REQUESTS_SUCCESS,
  payload,
});

export const declineIntroductionRequest = (payload) => ({
  type: DECLINE_INTRODUCTION_REQUEST,
  payload,
});

export const markAsDoneIntroductionRequest = (payload) => ({
  type: MARK_AS_DONE_INTRODUCTION_REQUEST,
  payload,
});
