import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'components/atoms/Card';
import { getUserDisplayName } from '../../../helpers/users';
import { useLazyGetNetworkAdminsQuery } from '../../../services/networkAdmins';
import { Search } from '../../atoms/search';
import { Dropdown } from '../dropdown';

export const SearchImportersDropdown = ({ trigger, value, onChange, ...rest }) => {
  const [getNetworkAdmins, { data, isLoading }] = useLazyGetNetworkAdminsQuery();

  const onCurrentChange = (importers, opt, checked) => {
    const result = importers.reduce((carry, user) => {
      const admin = data.find((item) => parseInt(item.id, 10) === user.value);

      if (admin) {
        carry.push({
          id: parseInt(admin.id, 10),
          name: getUserDisplayName(admin),
          ...admin.attributes,
        });
      }

      return carry;
    }, []);

    onChange(result, opt, checked);
  };

  return (
    <Dropdown placement="bottom-end" trigger={trigger} onOpen={() => getNetworkAdmins()} {...rest}>
      <Card width="320px" maxHeight="300px" p="16px" bg="neutral.0">
        <Search
          isLoading={isLoading}
          options={
            data?.map((item) => ({
              label: getUserDisplayName(item),
              value: parseInt(item.id, 10),
            })) || []
          }
          value={value}
          onChange={onCurrentChange}
        />
      </Card>
    </Dropdown>
  );
};

SearchImportersDropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

SearchImportersDropdown.defaultProps = {
  value: [],
};
