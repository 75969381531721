export const initChat = (user) => {
  if (!process.env.REACT_APP_FRESHCHAT_TOKEN) return;

  if (!window.fcWidget) {
    window.fcSettings = {
      onInit: () => initChat(user),
    };
    return;
  }

  const params = {
    token: process.env.REACT_APP_FRESHCHAT_TOKEN,
    host: 'https://wchat.freshchat.com',
  };

  if (user) {
    params.email = user.email;
    params.externalId = user.id;
    params.firstName = user.firstName;
    params.lastName = user.lastName;
  }

  window.fcWidget.init({
    ...params,
    config: {
      headerProperty: {
        hideChatButton: true,
      },
    },
  });
};

export const identifyUser = (user) => {
  if (!window.fcWidget || !user) return;

  window.fcWidget.setExternalId(user.id);
  window.fcWidget.user.setEmail(user.email);
  window.fcWidget.user.setFirstName(user.firstName);
  window.fcWidget.user.setLastName(user.lastName);
};

const addFreshChatScript = (init) => {
  const scriptId = 'freshchat-js-sdk';

  if (document.getElementById(scriptId)) {
    if (init) initChat();
    return;
  }

  // Load script
  const e = document.createElement('script');
  e.id = 'freshchat-js-sdk';
  e.async = !0;
  e.src = 'https://wchat.freshchat.com/js/widget.js';
  if (init) e.onload = () => initChat();
  document.head.appendChild(e);
};

export const loadChat = (init = false) => {
  if (window.addEventListener) {
    window.addEventListener('load', () => addFreshChatScript(init), !1);
  } else {
    window.attachEvent('load', () => addFreshChatScript(init), !1);
  }
};

export const openChat = (onError, options) => {
  if (typeof window.fcWidget !== 'object') {
    if (onError) {
      onError();
    }
    return;
  }

  if (options) {
    window.fcWidget.open(options);
    return;
  }

  window.fcWidget.open();
};

export const restartChat = () => {
  if (typeof window.fcWidget !== 'object') return;

  window.fcWidget.user.clear();
};
