import React from 'react';
import PropTypes from 'prop-types';
import { Box as RomboBox, FormInput, FormSectionTitle, FormTextEditor, AsyncButton } from '@getro/rombo';
import { Flex, Text } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';

export const TalentNetworkSettings = ({ talentNetwork, error, update, isUpdating }) => {
  const initialValues = {
    tnV2TabIntroductionTitle: talentNetwork.tnV2TabIntroductionTitle || 'Find your next opportunity',
    tnV2TabIntroductionText:
      talentNetwork.tnV2TabIntroductionText || `Get connected to the right company at the right time.`,
    tnV2SuccessMessageTitle: !talentNetwork.tnV2SuccessMessageTitle
      ? 'Welcome to our talent network'
      : talentNetwork.tnV2SuccessMessageTitle,
    tnV2SuccessMessageText: !talentNetwork.tnV2SuccessMessageText
      ? `Thanks for signaling your interest, your profile is now visible to our team and companies in our network.
    We’ll contact you when we find a great opportunity for you.`
      : talentNetwork.tnV2SuccessMessageText,
  };

  const validate = (values) =>
    Object.keys(values).reduce((acc, key) => {
      if (!values[key] || !values[key].trim()) {
        acc[key] = 'This field is required';
        return acc;
      }

      return acc;
    }, {});

  return (
    <Formik validate={validate} enableReinitialize={false} initialValues={initialValues} onSubmit={update}>
      {({ handleSubmit, isValid, setFieldValue, setFieldTouched }) => (
        <RomboBox errorText={error} editing title="Sign up customization">
          <Text color="text.main" as="p">
            Customize the sign up experience to communicate its values and purpose.
          </Text>
          <Flex sx={{ flexDirection: 'column', rowGap: '24px' }}>
            <FormSectionTitle>Talent network tab</FormSectionTitle>
            <Text as="p" color="text.main" lineHeight="20px" fontSize="16px">
              Content on the left side of the talent network tab on your job board.
            </Text>
            <Form>
              <Flex sx={{ flexDirection: 'column', rowGap: '24px' }}>
                <Field name="tnV2TabIntroductionTitle" component={FormInput} label="Title" labelExtra="Required" />
                <Field
                  component={FormTextEditor}
                  name="tnV2TabIntroductionText"
                  label="Content"
                  options={['bold', 'italic', 'h1', 'h2', 'list', 'link']}
                  labelExtra="Required"
                  onChange={(value) => {
                    setFieldTouched('tnV2TabIntroductionText');
                    setFieldValue('tnV2TabIntroductionText', value);
                  }}
                />
                <FormSectionTitle>Success message</FormSectionTitle>
                <Text as="p" color="text.main" lineHeight="20px" fontSize="16px">
                  Message shown right after the sign up is completed.
                </Text>
                <Field name="tnV2SuccessMessageTitle" component={FormInput} label="Title" labelExtra="Required" />
                <Field
                  component={FormTextEditor}
                  name="tnV2SuccessMessageText"
                  label="Content"
                  options={['bold', 'italic', 'h1', 'h2', 'list', 'link']}
                  labelExtra="Required"
                  onChange={(value) => {
                    setFieldTouched('tnV2SuccessMessageText');
                    setFieldValue('tnV2SuccessMessageText', value);
                  }}
                />
                <Flex
                  height="56px"
                  mt="8px"
                  sx={{
                    borderTop: '1px solid',
                    borderColor: 'border.subtle',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}
                >
                  <AsyncButton disabled={!isValid} type="submit" loading={isUpdating} onClick={handleSubmit}>
                    Save
                  </AsyncButton>
                </Flex>
              </Flex>
            </Form>
          </Flex>
        </RomboBox>
      )}
    </Formik>
  );
};

TalentNetworkSettings.propTypes = {
  talentNetwork: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  error: PropTypes.string,
  isUpdating: PropTypes.bool.isRequired,
};

TalentNetworkSettings.defaultProps = {
  error: null,
};

export default TalentNetworkSettings;
