import React from 'react';
import { useSelector } from 'react-redux';
import { Router, Switch, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import history from 'createHistory';

import Home from 'pages/Home';
import Introductions from 'pages/Introductions';
import Loading from 'components/molecules/LoadingFullPage';
import PageNotFound from 'pages/PageNotFound';
import SetNewPassword from 'pages/SetNewPassword';
import StayTuned from 'pages/StayTuned';
import Unsubscribe from 'pages/Unsubscribe';
import Vouches from 'pages/Vouches';
import EmployerOnboardingWrapper from './wrappers/EmployerOnboarding';
import OnboardingWrapper from './wrappers/Onboarding';
import NetworkWrapper from './wrappers/Network';

import SharedLists from '../pages/sharedLists';
import Route from './Route';
import { sessionRoutes, onboardingEmployersRoutes, userRoutes, networkRoutes } from './routes';
import { networksSelector, userSelector } from '../redux/selectors';

const Routes = () => {
  const networks = useSelector(networksSelector);
  const user = useSelector(userSelector);
  const { ready: networksReady } = networks;
  const { ready: userReady } = user;

  if (!userReady || !networksReady) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <LastLocationProvider>
        <Switch>
          <Redirect from="/networks/:networkSlug/members/share" to="/networks/:networkSlug/members/share/email" exact />
          <Redirect
            from="/claim-your-profile/:networkSlug/employer/:employerSlug"
            to={onboardingEmployersRoutes.claimYourProfile.path}
          />
          <Redirect
            exact
            from="/networks/:networkSlug/settings"
            to={`/networks/:networkSlug${networkRoutes.settingsGeneral.path}`}
          />
          <Redirect
            from="/onboarding/:networkSlug/employer/:employerSlug"
            to={onboardingEmployersRoutes.onboardingEmployer.path}
          />
          {Object.values(userRoutes).map(({ component, path, visibility }) => (
            <Route key={path} path={path} component={component} visibility={visibility} exact />
          ))}

          {Object.values(sessionRoutes).map(({ component, path, visibility }) => (
            <Route key={path} path={path} component={component} visibility={visibility} exact />
          ))}

          {Object.values(onboardingEmployersRoutes).map(({ path, visibility }) => (
            <Route key={path} path={path} component={EmployerOnboardingWrapper} visibility={visibility} exact />
          ))}

          <Route path="/shared-lists/:slug" component={SharedLists} visibility="all" />
          <Route path="/networks/:networkSlug" component={NetworkWrapper} visibility="user" />

          <Route path="/" component={Home} visibility="user" exact />
          <Route path="/welcome" component={OnboardingWrapper} visibility="onboarding" exact />

          {/* TODO: move these routes */}
          <Route path="/new-password" component={SetNewPassword} visibility={['public', 'onboarding', 'user']} exact />
          <Route path="/vouches" component={Vouches} visibility="user" exact />
          <Route path="/introductions" component={Introductions} visibility="user" />
          <Route path="/unsubscribe" component={Unsubscribe} visibility="user" />
          {/* TODO end */}

          <Route path="/stay-tuned" component={StayTuned} visibility={['user', 'onboarding']} />

          <Route path="*" component={PageNotFound} visibility="all" />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

export default Routes;
