import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  errorUserProfile,
  loadedUserProfile,
  loadedUpdateUserProfile,
  errorUpdateUserProfile,
} from '../../actions/profileActions';
import { removeUser } from '../../actions/userActions';

// The initial state of the Main
export const initialState = {
  user: null,
  error: null,
  jobFunctions: [],
  editModes: {
    profile: false,
    work: false,
    visibility: false,
  },
};

/* eslint-disable no-param-reassign */
const profileUpdateAction = (draft, action) => {
  const newUser = action.payload.user;
  draft.user = newUser;
  return draft;
};

const onErrorAction = (draft, action) => {
  draft.error = action.payload.error;
  return draft;
};

/* eslint-disable default-case, no-param-reassign */
const userProfileReducer = handleActions(
  {
    [loadedUpdateUserProfile]: produce(profileUpdateAction),

    [loadedUserProfile]: produce((draft, action) => {
      draft.user = action.payload.user;
      return draft;
    }),
    [errorUserProfile]: produce(onErrorAction),
    [errorUpdateUserProfile]: produce(onErrorAction),
    [removeUser]: () => initialState,
  },
  initialState,
);

export default userProfileReducer;
