import React from 'react';
import { Loader } from '@getro/rombo';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { X } from 'lucide-react';
import toast, { Toaster, resolveValue } from 'react-hot-toast';

import { MessageIcon } from '../messageIcon';

export const Toast = ({ position }) => (
  <Toaster position={position} toastOptions={{ duration: 10000 }} data-testid="toaster">
    {(t) => (
      <Flex
        bg="neutral.0"
        textAlign="left"
        alignItems="center"
        pl={[2, 3]}
        width={[1, 'auto']}
        maxWidth={['100%', '480px']}
        sx={{
          borderRadius: '4px',
          boxShadow: 'small',
          borderLeft: '0.1875rem solid',
          borderLeftColor: t.icon,
          lineHeight: 1.4,
          opacity: t.visible ? 1 : 0,
        }}
      >
        <Flex py={[3]} flexGrow="2" alignItems="center">
          {t.type !== 'bulk-notification' ? (
            <Box
              as={MessageIcon}
              type={t.icon}
              mr={[2, 3]}
              sx={{
                alignSelf: 'flex-start',
                flexShrink: 0,
                flexGrow: 0,
                flexBasis: '24px',
                color: t.icon,
              }}
              data-testid={`icon-${t.icon}`}
            />
          ) : (
            <Box mr="16px" flex="0 0 auto">
              <Loader size="medium" />
            </Box>
          )}
          <Flex flexDirection="column">
            <Box
              color="text.dark"
              pr={[2, 3]}
              sx={{
                flexGrow: 2,
                fontWeight: 'medium',
                fontSize: [2, 1],
              }}
            >
              {resolveValue(t.message, t)}
            </Box>
            {t.notificationContent && (
              <Box mt={2} color="text.main" sx={{ fontSize: [2, 1] }}>
                {t.notificationContent}
              </Box>
            )}
          </Flex>
        </Flex>
        {t.type !== 'loading' && (
          <Box
            color="text.subtle"
            sx={{
              ':hover': { color: 'text.main', bg: 'transparent' },
              alignSelf: 'flex-start',
              flex: '0 0 auto',
              cursor: 'pointer',
            }}
            p={[3]}
            onClick={() => toast.dismiss(t.id)}
          >
            <Box data-item="close-icon" height="24px" width="24px" strokeWidth="1.5" aria-hidden="true" as={X} />
          </Box>
        )}
      </Flex>
    )}
  </Toaster>
);

Toast.propTypes = {
  position: PropTypes.oneOf(['top-left', 'top-center', 'top-right', 'bottom-left', 'bottom-center', 'bottom-right']),
};

Toast.defaultProps = {
  position: 'top-right',
};
