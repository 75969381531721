const permissions = [
  { value: 'verified', label: 'Verified: Enable intro requests for all members' },
  { value: 'non-verified', label: 'Non-Verified: Disable intro requests for all members' },
];

export const findByValue = (value) => {
  if (!value) return null;

  return permissions.find((p) => p.value === value);
};

export default permissions;
