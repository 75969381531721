import React from 'react';
import { Button, Modal } from '@getro/rombo';
import { Flex, Text, Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const AutoUpdateModal = ({ onCancel, onEnable, action }) => (
  <Modal
    title={action === 'enable' ? 'Enable Auto-update' : 'Disable Auto-update'}
    isOpen
    onCancel={onCancel}
    actions={
      <Flex justifyContent={['flex-end']}>
        <Button onClick={onCancel} variant="tertiary">
          Cancel
        </Button>
        <Button onClick={onEnable}>{action === 'enable' ? 'Enable' : 'Disable'}</Button>
      </Flex>
    }
    data-testid={action === 'enable' ? 'enable-auto-update' : 'disable-auto-update'}
  >
    {action === 'enable' && (
      <>
        <Text pb="16px" color="text.main">
          Enabling Auto-update will:
        </Text>
        <Box as="ul" pl="32px">
          <Box as="li">Automatically add and remove contacts based on your filters</Box>
          <Box as="li">Keep existing and manually added contacts</Box>
          <Box as="li">{`Update every 24 hours, you can initiate an update anytime via the 'Actions' menu.`}</Box>
        </Box>
      </>
    )}
    {action === 'disable' && (
      <>
        <Text pb="16px" color="text.main">
          Disabling Auto-update will:
        </Text>
        <Box as="ul" pl="32px">
          <Box as="li">Keep existing contacts in the list</Box>
          <Box as="li">Stop any automatic add or removal, you’ll be fully in charge of the list management</Box>
        </Box>
      </>
    )}
  </Modal>
);

AutoUpdateModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
};
