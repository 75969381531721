import qs from 'query-string';

const serializeParams = ({ filters, ...otherParams }) => {
  if (filters) {
    return `?${qs.stringify({
      ...otherParams,
      filters: JSON.stringify(filters),
    })}`;
  }

  return `?${qs.stringify({ ...otherParams })}`;
};

export default serializeParams;
