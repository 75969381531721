import React from 'react';
import { Button, Panel } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import { ChevronRight } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import NetworkSchema from 'schemas/network';

export const JobBoardAppearancePanel = ({ activeNetwork }) => (
  <Panel title="Job board appearance" sx={{ mb: '32px' }}>
    <Flex sx={{ gap: '16px', flexDirection: 'column', mt: '16px' }}>
      <Box as="P" sx={{ color: 'text.main', fontSize: '14px', lineHeight: '20px' }}>
        Customize how your job board looks in the advanced design settings.
      </Box>
      <Box>
        <Button
          as={NavLink}
          to={`/networks/${activeNetwork?.slug}/settings/job-board/appearance`}
          icon={ChevronRight}
          variant="secondary"
          iconPosition="right"
        >
          Open appearance settings
        </Button>
      </Box>
    </Flex>
  </Panel>
);

JobBoardAppearancePanel.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
};
