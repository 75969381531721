/* eslint-disable react/jsx-no-bind */
import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { Modal as SemanticModal } from 'semantic-ui-react';
import { Formik, Form } from 'formik';
import uniqueId from 'helpers/uniqueId';
import { Button, Message, AsyncButton } from '@getro/rombo';
import { Flex } from 'rebass/styled-components';
import 'semantic-ui-css/components/dimmer.min.css';
import 'semantic-ui-css/components/modal.min.css';
import 'semantic-ui-css/components/icon.min.css';
import { GenericError } from '../../molecules/genericError';

export const ModalContext = createContext();

const handleCancel = (onCancelClick, setOpenInt, formik, resetForm) => () => {
  handleChange(setOpenInt)();
  if (formik) {
    resetForm();
  }
  if (onCancelClick) {
    onCancelClick();
  }
};

const handleSubmit = async (onSubmit, setOpenInt, ...args) => {
  await onSubmit(...args);
  handleChange(setOpenInt)();
};

const clickInsideTrigger = (e, id) => {
  if (!e) return false;

  let element = e.target;
  let found = false;
  while (element && !found) {
    found = element.id === id;
    element = element.parentElement;
  }

  return found;
};

const handleChange =
  (setOpenInt, needToOpen = false, id) =>
  (e) => {
    if (needToOpen) {
      if (clickInsideTrigger(e, id)) {
        setOpenInt(needToOpen);
      }
    } else {
      setOpenInt(needToOpen);
    }
  };

const getSize = (size) => {
  if (size === 'sm') {
    return 'mini';
  }
  if (size === 'fullwidth') {
    return 'fullscreen';
  }

  if (size === 'lg') return 'large';

  return 'small';
};

const Modal = ({
  title,
  saveText,
  cancelText,
  trigger,
  children,
  errorText,
  onCancelClick,
  onClose,
  onOpen,
  onSubmit,
  onSaveClick,
  open,
  loading,
  submitDisabled,
  formik,
  formikProps,
  simple,
  size,
  ...rest
}) => {
  const [openInt, setOpenInt] = useState(false);

  const triggerId = uniqueId();
  return (
    <>
      {trigger &&
        React.cloneElement(trigger, {
          onClick: handleChange(setOpenInt, true, triggerId),
          id: triggerId,
        })}
      <SemanticModal
        open={open || openInt}
        size={getSize(size)}
        closeIcon={open === undefined}
        onClose={handleChange(setOpenInt)}
        onMount={onOpen}
        onUnmount={onClose}
        closeOnDimmerClick
        {...rest}
      >
        {!!title.length && <SemanticModal.Header>{title}</SemanticModal.Header>}
        {formik ? (
          <Formik onSubmit={handleSubmit.bind(null, onSubmit, setOpenInt)} {...formikProps}>
            {({ values, setFieldValue, status, isSubmitting, resetForm }) => (
              <ModalContext.Provider value={{ values, setFieldValue, status, isSubmitting, resetForm }}>
                <Form>
                  <SemanticModal.Content>{children}</SemanticModal.Content>
                  <SemanticModal.Actions>
                    {status && status.error && <GenericError />}
                    <Flex justifyContent="flex-end">
                      {(!!cancelText || !!onCancelClick) && (
                        <Button variant="tertiary" onClick={handleCancel(onCancelClick, setOpenInt, formik, resetForm)}>
                          {cancelText}
                        </Button>
                      )}
                      <AsyncButton variant="primary" type="submit" loading={isSubmitting}>
                        {saveText}
                      </AsyncButton>
                    </Flex>
                  </SemanticModal.Actions>
                </Form>
              </ModalContext.Provider>
            )}
          </Formik>
        ) : (
          <>
            <SemanticModal.Content>{children}</SemanticModal.Content>
            {simple || (
              <SemanticModal.Actions>
                {errorText && <Message className="Modal__error-message" type="error" content={errorText} />}
                <Flex justifyContent="flex-end">
                  {(!!cancelText || !!onCancelClick) && (
                    <Button variant="tertiary" onClick={handleCancel(onCancelClick, setOpenInt)}>
                      {cancelText}
                    </Button>
                  )}
                  {(onSaveClick || onSubmit) && (
                    <AsyncButton
                      variant="primary"
                      loading={loading}
                      onClick={handleSubmit.bind(null, onSubmit, setOpenInt)}
                    >
                      {saveText}
                    </AsyncButton>
                  )}
                </Flex>
              </SemanticModal.Actions>
            )}
          </>
        )}
      </SemanticModal>
    </>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  trigger: PropTypes.node,
  children: PropTypes.node.isRequired,
  saveText: PropTypes.string,
  cancelText: PropTypes.string,
  errorText: PropTypes.string,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  formik: PropTypes.bool,
  formikProps: PropTypes.object,
  simple: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'fullwidth']),
};

Modal.defaultProps = {
  trigger: null,
  cancelText: null,
  errorText: null,
  onCancelClick: () => {},
  onSaveClick: () => {},
  open: false,
  formikProps: null,
  saveText: 'Ok',
  title: '',
  onClose: () => {},
  onOpen: () => {},
  onSubmit: () => {},
  loading: false,
  submitDisabled: false,
  formik: false,
  simple: false,
  size: 'md',
};

export default Modal;
