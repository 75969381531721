import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { activeNetworkSelector } from 'redux/selectors';

import { MessageBlock, Box } from '@getro/rombo';

function NoAccess({ history }) {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { slug } = activeNetwork;

  const handleClick = () => {
    history.push({ pathname: `/networks/${slug}/members` });
  };

  return (
    <Box data-testid="analytics-unavailable">
      <MessageBlock
        type="warning"
        title="Analytics unavailable"
        content="Only network administrators can see analytics."
        buttonText="Go to members"
        onClick={handleClick}
      />
    </Box>
  );
}

NoAccess.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default NoAccess;
