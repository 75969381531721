import React, { useEffect } from 'react';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useInjectReducer } from '../../hooks/useInjectReducer';
import { useInjectSaga } from '../../hooks/useInjectSaga';
import reducer from './reducer';
import saga from './saga';
import { key, makeSelectFinishedSubscribing } from './selectors';
import pageHoc from '../../hocs/pageHoc';
import { loadSubscribeToBoards } from './actions';
import { activeNetworkSelector } from '../../redux/selectors';
import Loading from '../../components/molecules/LoadingFullPage';

const pageTitle = 'Subscribing to your talent network';

const Subscribing = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const history = useHistory();
  const activeNetwork = useSelector(activeNetworkSelector);
  const isFinishedSubscribing = useSelector(makeSelectFinishedSubscribing);
  useEffect(() => {
    // get the ids from the query string;
    let boardIds = qs.parse(history.location.search).talentGroups || [];
    if (!boardIds) {
      boardIds = [];
    }
    if (!Array.isArray(boardIds)) {
      boardIds = [boardIds];
    }
    dispatch(loadSubscribeToBoards({ boardIds }));
  }, [history, dispatch]);

  useEffect(() => {
    if (isFinishedSubscribing) {
      history.replace(`/networks/${activeNetwork.slug}/members/alerts`);
    }
  }, [history, isFinishedSubscribing, activeNetwork]);

  return <Loading />;
};

export default pageHoc({ useMainLayout: false, title: pageTitle })(Subscribing);
