import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Filters } from 'components/organisms/contactsFilters/filters';
import { Box } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import { defaultFilters } from 'components/organisms/contactsFilters/defaultFilters';

export const AutoUpdate = memo(
  ({ filters, setFilters, isTalentNetworkEnabled, onMatchSelected, isUpdatingList, isEdit }) => {
    const setMultipleFilters = (group, options = {}) => {
      const { removeFilters = [] } = options;
      const cleanFilters = Object.keys(group).reduce((arr, key) => {
        if (group[key]?.value === 'any') {
          return arr;
        }

        arr[key] = group[key]; // eslint-disable-line no-param-reassign
        return arr;
      }, {});
      const newFilters = { ...filters, ...cleanFilters };

      removeFilters.forEach((item) => {
        delete newFilters[item];
      });

      setFilters(newFilters);
    };

    const clearFundFilters = () => {
      setFilters({
        ...(filters || defaultFilters),
        stageTransitions: [],
        activePositionsOrganizationStages: [],
        companyStages: [],
      });
    };

    return (
      <Box>
        <Box as="p" fontSize="14px" lineHeight="20px" mb="32px" color="text.main">
          Auto-update adds and removes contacts according to your filters daily, while preserving manual edits.{' '}
        </Box>
        <Box as="p" fontSize="14px" color="text.subtle" mb="8px">
          Select the filters to keep your list updated.
        </Box>
        <Filters
          filters={filters ?? defaultFilters}
          setFilters={setMultipleFilters}
          extendedMode
          isTalentNetworkEnabled={isTalentNetworkEnabled}
          showListFilters={false}
          clearFundFilters={clearFundFilters}
          isEdit={isEdit}
        />
        <Box>
          <Button
            loading={isUpdatingList}
            onClick={() => onMatchSelected(filters || defaultFilters, 'autoUpdate')}
            size="small"
          >
            Save filters
          </Button>
        </Box>
      </Box>
    );
  },
);

AutoUpdate.propTypes = {
  isTalentNetworkEnabled: PropTypes.bool.isRequired,
  onMatchSelected: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
  isUpdatingList: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

AutoUpdate.defaultProps = {
  filters: defaultFilters,
};
