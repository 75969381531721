import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { FormField, Message, MessageBlock, AsyncButton } from '@getro/rombo';

import { put } from 'api';
import strings from 'strings';
import { trackException } from 'helpers/errorTracker';
import pageHoc from 'hocs/pageHoc';
import settings from 'settings';

import SessionLayout from 'components/layout/Session';
import { useSelector } from 'react-redux';
import { userSelector } from '../../redux/selectors';

const initialValues = { password: '' };

const passwordFormSchema = Yup.object().shape({
  password: Yup.string().min(8, strings.validation.passwordLength).required(),
});

const handleSubmit =
  (cancelToken, setSuccess) =>
  async (values, { setStatus, setSubmitting }) => {
    setStatus({ error: null });
    const params = {
      password: values.password,
      password_confirmation: values.password,
    };

    try {
      await put('/users/me', params, { cancelToken });
      setSuccess(true);
    } catch (error) {
      trackException(error);
      setStatus({ error: strings.genericError });
    }
    setSubmitting(false);
  };

const pageTitle = 'Set a new password';

const SetNewPassword = () => {
  const history = useHistory();
  const user = useSelector(userSelector);
  const [success, setSuccess] = useState(false);
  const cancelTokenSource = useRef(axios.CancelToken.source());

  useEffect(
    () => () => {
      cancelTokenSource.current.cancel();
    },
    [],
  );

  const handleButtonClick = () => {
    history.push('/');
  };

  if (!user.forcePasswordUpdate) {
    return (
      <SessionLayout title={strings.session.setNewPassword.title}>
        <MessageBlock
          type="warning"
          title="Expired link"
          content="Looks like this link has expired. To reset your password please start the process again."
          buttonText={`Continue to ${settings.companyName}`}
          onClick={handleButtonClick}
        />
      </SessionLayout>
    );
  }

  if (success) {
    return (
      <SessionLayout>
        <MessageBlock
          title="Password updated"
          type="success"
          content="You can now log in using your new password."
          buttonText={`Continue to ${settings.companyName}`}
          onClick={handleButtonClick}
        />
      </SessionLayout>
    );
  }

  return (
    <SessionLayout
      className="SetNewPassword"
      title={strings.session.setNewPassword.title}
      subtitle={strings.session.recoverPassword.subtitle}
    >
      <Formik
        validationSchema={passwordFormSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit(cancelTokenSource.current.token, setSuccess)}
      >
        {({ status, isSubmitting }) => (
          <Form>
            <Field type="password" name="password" label="New password" size="lg" component={FormField} />
            <AsyncButton
              fontWeight="semibold"
              width={1}
              justifyContent="center"
              type="submit"
              variant="primary"
              fontSize={3}
              loading={isSubmitting}
            >
              Set New Password
            </AsyncButton>
            {status && status.error && (
              <Message className="SetNewPassword__error-message" content={status.error} type="error" />
            )}
          </Form>
        )}
      </Formik>
    </SessionLayout>
  );
};

export default pageHoc({ title: pageTitle })(SetNewPassword);
