import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useInjectReducer } from '../useInjectReducer';
import {
  hasMoreJobsSelector,
  isJobsErroredSelector,
  isJobsInitializedSelector,
  jobsSelector,
  key,
  totalJobsSelector,
} from './selectors';
import reducer from './reducer';
import { useInjectSaga } from '../useInjectSaga';
import saga from './sagas';
import { makeSelectIsLoading } from '../../redux/loadingSelector';
import { loadJobs, resetJobsList } from './actions';
import { qsParse, qsStringify } from '../../helpers/queryString';

const defaultHitsPerPage = 20;
const DEFAULT_FILTER = {
  sortBy: 'createdAt',
  sortDirection: 'desc',
  visibility: null,
  source: null,
  status: null,
  expiration: null,
  featured: null,
  expirationWithin: null,
  locationIds: [],
};

const useJobs = ({ forCompany }) => {
  const history = useHistory();
  const location = useLocation();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const isJobsLoading = useSelector(makeSelectIsLoading('jobs'));
  const isJobsInitialized = useSelector(isJobsInitializedSelector);
  const hasJobsInitializationFailed = useSelector(isJobsErroredSelector);
  const totalJobs = useSelector(totalJobsSelector);
  const jobs = useSelector(jobsSelector);
  const hasMoreJobs = useSelector(hasMoreJobsSelector);

  const defaultFilter = useMemo(() => {
    if (forCompany?.id) {
      return {
        ...DEFAULT_FILTER,
        companyId: forCompany.id.toString(),
      };
    }

    return DEFAULT_FILTER;
  }, [forCompany]);

  const statusOptions = [
    { label: 'Any', value: '' },
    { label: 'Open', value: 'active' },
    { label: 'Expired', value: 'expired' },
    { label: 'Closed', value: 'closed' },
  ];
  const visibilityOptions = [
    { label: 'Any', value: '' },
    { label: 'Visible', value: 'visible' },
    { label: 'Visible (manually)', value: 'visible_forced' },
    { label: 'Hidden (manually)', value: 'hidden_forced' },
    { label: 'Hidden (Job rules)', value: 'hidden' },
    { label: 'Not visible', value: 'not_visible' },
  ];
  const featuredOptions = [
    { label: 'Any', value: '' },
    { label: 'Featured', value: 'featured' },
    { label: 'Not featured', value: 'not_featured' },
  ];
  const expirationOptions = [
    { label: 'Any', value: '' },
    { label: 'Next 7 days', value: '7' },
    { label: 'Next 30 days', value: '30' },
  ];
  const sourceOptions = [
    { label: 'Any', value: '' },
    { label: 'Extracted', value: 'job_source' },
    { label: 'Posted', value: 'posted' },
  ];

  const dispatch = useDispatch();
  const shouldRetrieveJobs = useMemo(
    () => isJobsInitialized && !isJobsLoading && !hasJobsInitializationFailed,
    [hasJobsInitializationFailed, isJobsInitialized, isJobsLoading],
  );

  const jobsFilters = useMemo(() => {
    const filter = qsParse(location);
    delete filter.page;
    const { locations, ...otherFilters } = filter;
    const currentFilters = { ...defaultFilter, ...otherFilters };

    if (locations) {
      const parsedLocations = JSON.parse(atob(locations));
      currentFilters.locations = parsedLocations.filter((item) => item.value !== 'remote');
      currentFilters.remote = currentFilters.locations.length === parsedLocations.length ? '' : 'remote';
      currentFilters.locationIds = parsedLocations.map(({ value }) => value);
    }

    return currentFilters;
  }, [defaultFilter, location]);

  const hasJobsFilters = useMemo(() => {
    const filter = qsParse(location);
    return filter && Object.keys(filter).length !== 0;
  }, [location]);

  useEffect(() => {
    if (!isJobsInitialized && !isJobsLoading) {
      const params = qsParse(location);
      const page = parseInt(params.page || 1, 10);
      const hitsPerPage = defaultHitsPerPage * page;
      dispatch(loadJobs({ page: 1, hitsPerPage, filters: jobsFilters }));
    }
  }, [shouldRetrieveJobs, dispatch, isJobsInitialized, isJobsLoading, location, jobsFilters]);

  useEffect(
    () => () => {
      dispatch(resetJobsList());
    },
    [dispatch],
  );

  const loadNextPage = useCallback(() => {
    if (shouldRetrieveJobs) {
      const params = qsParse(location);
      const page = parseInt(params.page || 1, 10) + 1;
      params.page = page.toString();
      history.replace({ pathname: location.pathname, search: qsStringify(params) });
      dispatch(loadJobs({ page, hitsPerPage: defaultHitsPerPage, filters: jobsFilters }));
    }
  }, [dispatch, history, jobsFilters, location, shouldRetrieveJobs]);

  const updateQueryString = useCallback(
    ({ filter, value }) => {
      const params = qsParse(location);
      if (value.length) {
        params[filter] = value;
      } else {
        delete params[filter];
      }
      return params;
    },
    [location],
  );

  const filterOrSortJobs = useCallback(
    ({ search }) => {
      const { locations, page, ...otherFilters } = search;
      const params = { ...otherFilters };

      if (locations) {
        params.locationIds = JSON.parse(atob(locations)).map(({ value }) => value);
      }

      const queryParams = { locations, ...otherFilters };
      history.replace({ pathname: location.pathname, search: qsStringify(queryParams) });
      dispatch(resetJobsList());
      dispatch(loadJobs({ filters: { ...defaultFilter, ...params } }));
    },
    [defaultFilter, dispatch, history, location.pathname],
  );

  const initialSortBy = useMemo(() => {
    const params = qsParse(location);
    let id = DEFAULT_FILTER.sortBy;
    let desc = DEFAULT_FILTER.sortDirection === 'desc';
    if (params.sortBy) {
      id = params.sortBy;
    }
    if (params.sortDirection) {
      desc = params.sortDirection === 'desc';
    }
    return { id, desc };
  }, [location]);

  return {
    updateQueryString,
    initialSortBy,
    statusOptions,
    visibilityOptions,
    featuredOptions,
    expirationOptions,
    sourceOptions,
    jobs,
    jobsFilters,
    hasJobsFilters,
    totalJobs,
    isJobsLoading,
    isJobsInitialized,
    hasJobsInitializationFailed,
    hasMoreJobs,
    filterOrSortJobs,
    loadNextPage,
  };
};

export default useJobs;
