import React from 'react';
import { Box } from 'rebass';
import PropTypes from 'prop-types';

export const Tag = ({ color, ...props }) => (
  <Box as="svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Box
      as="path"
      d="M7 7H7.01M12 2H2V12L11.29 21.29C12.23 22.23 13.77 22.23 14.71 21.29L21.29 14.71C22.23 13.77 22.23 12.23 21.29 11.29L12 2Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Box>
);

Tag.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.string,
};

Tag.defaultProps = {
  sx: {},
  color: '#ffffff',
};

export default Tag;
