import React from 'react';
import { Search } from 'lucide-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

import { Input } from '@getro/rombo';

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--space-sm);
  line-height: 1;
  font-weight: var(--text-medium);
  color: var(--color-text-dark);
`;

const StyledInput = styled(Input)`
  margin-bottom: var(--space-md);
`;

const SearchInput = ({ onChange, value }) => (
  <>
    <Title>
      <Box as={Search} mr="1" width="16px" height="16px" aria-hidden="true" />
      Search
    </Title>
    <StyledInput
      placeholder="Search name, bio, location..."
      name="keyword"
      onChange={onChange}
      value={value}
      delayed
      fluid
    />
  </>
);

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

SearchInput.defaultProps = {
  value: '',
};

export default SearchInput;
