import React from 'react';
import PropTypes from 'prop-types';

import GroupRadioSelector from 'components/atoms/groupRadioSelector';
import { signupInfoOptions } from './defaultFilters';

export const TalentNetworkSignupFilter = ({ value, onChange }) => (
  <GroupRadioSelector
    title="Talent network signups"
    placeholder="Include"
    options={signupInfoOptions}
    selected={value}
    onSelect={(values) => onChange(values)}
  />
);

TalentNetworkSignupFilter.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

TalentNetworkSignupFilter.defaultProps = {
  value: { label: 'Include', value: 'any' },
};
