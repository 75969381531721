import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { get } from 'api';
import strings from 'strings';
import { MessageBlock } from '@getro/rombo';
import { useSelector } from 'react-redux';

import helperGet from '../../helpers/get';
import { trackException } from '../../helpers/errorTracker';
import { trackEvent } from '../../helpers/analytics';
import Route from '../Route';
import { onboardingEmployersRoutes } from '../routes';
import EmployerOnboardingLayout from '../../components/layout/EmployerOnboarding';
import BlankLayout from '../../components/layout/Blank';
import { networksSelector, userSelector } from '../../redux/selectors';
import EOnboarding from './EOnboarding';

export const EOnboardingContext = ({ children }) => {
  const [showSelectYourCompany, setShowSelectYourCompany] = useState(undefined);

  return (
    <EOnboarding.Provider
      value={{
        showSelectYourCompany,
        setShowSelectYourCompany,
      }}
    >
      {children}
    </EOnboarding.Provider>
  );
};

EOnboardingContext.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

EOnboardingContext.defaultProps = {
  children: null,
};

const fetchOrg = async (
  organizationSlug,
  networkId,
  withDetails = false,
  cancelToken,
  setter,
  setLoading,
  setOrganizationError,
) => {
  let params = {
    collectionId: networkId,
  };

  if (withDetails) {
    params = {
      ...params,
      detailed: true,
    };
  }

  setLoading(true);

  try {
    const response = await get(`/organizations/${organizationSlug}`, {
      cancelToken,
      params,
    });

    setter(response.data);
    setLoading(false);
  } catch (error) {
    const notFound = helperGet(error, 'response.status') === 404;

    trackException(error);

    let message = strings.genericError;
    if (notFound) {
      message = 'Seems that your link is not valid or has expired.';
    }

    setOrganizationError(message);
    setLoading(false);
  }
};

const getNetworkInformation = async (
  networkSlug,
  cancelToken,
  setNetWorkInformation,
  setLoadingNetwork,
  setNetWorkError,
) => {
  setLoadingNetwork(true);

  try {
    const response = await get(`/collections/${networkSlug}`, {
      cancelToken,
    });

    setNetWorkInformation(response.data.collection);
    setLoadingNetwork(false);
  } catch (error) {
    trackException(error);
    let message = strings.genericError;
    if (error && error.response && error.response.status === 404) {
      message = 'Seems that your link is not valid or has expired.';
    }

    setNetWorkError(message);
    setLoadingNetwork(false);
  }
};

const renderLayout = (netWorkInformation, organizationInformation, isManager, isLoading = false) => (
  <EmployerOnboardingLayout
    isLoading={isLoading}
    isManager={isManager}
    network={netWorkInformation}
    organization={organizationInformation}
  >
    {Object.values(onboardingEmployersRoutes).map((route) => (
      <Route {...route} key={route.path} exact />
    ))}
  </EmployerOnboardingLayout>
);

const renderError = (message) => (
  <BlankLayout>
    <MessageBlock type="warning" title="Invalid link" content={message} />
  </BlankLayout>
);

const renderDomainError = (netWorkInformation, organizationInformation, isManager, history) => {
  trackEvent('employer_onboarding:different_domain', {
    organization_id: organizationInformation.id,
    collection_id: netWorkInformation.id,
  });

  return (
    <EmployerOnboardingLayout isManager={isManager} network={netWorkInformation} organization={organizationInformation}>
      <MessageBlock
        title="We need to verify your permissions"
        type="warning"
        content={'To claim the [company] profile automatically you must use your company email address.'.replace(
          '[company]',
          organizationInformation.name,
        )}
        footerContent={
          <>
            If you do not have a {organizationInformation.name} email, but think you should be able to manage this
            company profile, please contact{' '}
            <a href="mailto:help@getro.com" target="_blank" rel="noopener noreferrer">
              help@getro.com
            </a>
            .
          </>
        }
        buttonText="Ok"
        onClick={() => {
          history.push(
            onboardingEmployersRoutes.selectYourCompany.path.replace(':networkSlug', netWorkInformation.slug),
          );
        }}
      />
    </EmployerOnboardingLayout>
  );
};

const EmployerOnboarding = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [isManager, setIsManager] = useState(undefined);
  const [netWorkInformation, setNetWorkInformation] = useState(undefined);
  const [networkError, setNetWorkError] = useState(false);
  const [loadingNetwork, setLoadingNetwork] = useState(true);
  const [organizationInformation, setOrganizationInformation] = useState({});
  const [loadingOrganization, setLoadingOrganization] = useState(true);
  const [organizationError, setOrganizationError] = useState(false);

  const { showSelectYourCompany, setShowSelectYourCompany } = useContext(EOnboarding);

  const isShowYourCompanyRoute = match.path === onboardingEmployersRoutes.selectYourCompany.path;

  const {
    params: { networkSlug, employerSlug: organizationSlug },
  } = match;

  const user = useSelector(userSelector);
  const { networks } = useSelector(networksSelector);

  useEffect(() => {
    const source = axios.CancelToken.source();

    getNetworkInformation(networkSlug, source.token, setNetWorkInformation, setLoadingNetwork, setNetWorkError);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      source.cancel('getNetworkInformation: Canceled by cleanup on effect');
    };
  }, [networkSlug]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (netWorkInformation && netWorkInformation.id && organizationSlug) {
      fetchOrg(
        organizationSlug,
        netWorkInformation.id,
        false,
        source.token,
        setOrganizationInformation,
        setLoadingOrganization,
        setOrganizationError,
      );
    }

    return () => {
      source.cancel('fetchOrg: Canceled by cleanup on effect');
    };
    // eslint-disable-next-line
  }, [organizationSlug, netWorkInformation])

  useEffect(() => {
    let manager = false;

    if (!netWorkInformation || !organizationInformation.id) {
      return setIsManager(undefined);
    }
    const managingOrganizations = helperGet(networks, `${netWorkInformation.id}.isOrganizationManager`, false);
    if (
      managingOrganizations &&
      Object.values(managingOrganizations).some((n) => n.id === organizationInformation.id)
    ) {
      manager = true;
    }

    return setIsManager(manager);
    // eslint-disable-next-line
  }, [netWorkInformation, organizationInformation])

  const sameDomain = user.id && new RegExp(`@${organizationInformation.domain}$`).test(user.email);
  if (networkError || organizationError) {
    return renderError(networkError || organizationError);
  }

  if ((organizationSlug && loadingOrganization) || loadingNetwork) {
    return renderLayout({}, {}, isManager, true, match);
  }

  if (!isManager && !isShowYourCompanyRoute && user.id && !sameDomain) {
    return renderDomainError(netWorkInformation, organizationInformation, isManager, history);
  }

  if (showSelectYourCompany === undefined) {
    let show = false;
    if (isShowYourCompanyRoute) {
      show = true;
    }

    setShowSelectYourCompany(show);
  }

  return renderLayout(netWorkInformation, organizationInformation, isManager, false, match);
};

export default EmployerOnboarding;
