import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UserMenu from 'components/molecules/UserMenu';
import Loading from 'components/molecules/LoadingFullPage';

import { userSelector } from '../../../redux/selectors';
import EmployersOnboardingContext from './context';
import EmployerOnboardingContent from './EmployerOnboardingContent';

const STEPS = [
  {
    id: 'selectYourCompany',
    title: 'Your company',
  },
  {
    id: 'yourAccount',
    title: 'Your account',
  },
  {
    id: 'generalInformation',
    withSkipAll: true,
    title: 'Company information',
  },
  {
    id: 'socialProfiles',
    withSkipAll: true,
    title: 'Social profiles',
  },
  {
    id: 'careersPage',
    withSkipAll: true,
    title: 'Job source',
  },
  {
    id: 'managers',
    withSkipAll: true,
    title: 'Invite company admins',
  },
  {
    id: 'subscribe',
    withSkipAll: true,
    title: 'Subscribe to lists',
  },
  {
    id: 'success',
  },
];

const EmployerOnboarding = ({ children, network, organization, isManager, isLoading }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { email } = useSelector(userSelector);
  const steps = useMemo(() => {
    const { features = [] } = network;
    const isTalentNetworkEnabled = features.includes('talent_network');

    if (isTalentNetworkEnabled) {
      return STEPS;
    }

    return STEPS.filter(({ id }) => id !== 'subscribe');
  }, [network]);

  const handleLogout = useCallback(() => history.push('/login'), [history]);

  if (isLoading) {
    return <Loading />;
  }

  let firstStep = 0;
  if (match.path === '/claim-your-profile/:networkSlug/company/:employerSlug') {
    firstStep = 1;
  }
  if (match.path === '/onboarding/:networkSlug/company/:employerSlug') {
    firstStep = 2;
  }

  let headerContent;
  if (!email) {
    headerContent = (
      <NavLink to="/login" className="EmployerOnboarding__userMenu">
        Already have an account?
      </NavLink>
    );
  } else {
    headerContent = <UserMenu className="EmployerOnboarding__userMenu" onlyLogout onLogout={handleLogout} />;
  }

  return (
    <EmployersOnboardingContext
      firstStep={firstStep}
      isManager={isManager}
      network={network}
      organization={organization}
      steps={steps}
    >
      <EmployerOnboardingContent headerContent={headerContent}>{children}</EmployerOnboardingContent>
    </EmployersOnboardingContext>
  );
};

EmployerOnboarding.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  isManager: PropTypes.bool,
  network: PropTypes.object,
  organization: PropTypes.object,
};

EmployerOnboarding.defaultProps = {
  children: null,
  isManager: false,
  network: {},
  organization: {},
};

export default EmployerOnboarding;
