import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  errorRemoveCompany,
  errorSaveCompanyTopics,
  loadedRemoveCompany,
  loadedSaveCompanyTopics,
  loadedTopicOptions,
  loadRemoveCompany,
  loadSaveCompanyTopics,
  resetCompanyProfile,
} from './actions';
import { removeUser } from '../../actions/userActions';

export const initialState = {
  settings: {
    isRemoved: null,
    error: null,
  },
  topics: {
    options: [],
    success: null,
    error: null,
  },
};

/* eslint-disable default-case, no-param-reassign */
const companyProfileReducer = handleActions(
  {
    [resetCompanyProfile]: () => initialState,
    [loadRemoveCompany]: produce((draft) => {
      draft.settings.isRemoved = initialState.settings.isRemoved;
      draft.settings.error = initialState.settings.error;
      return draft;
    }),
    [loadedRemoveCompany]: produce((draft, action) => {
      draft.settings.isRemoved = action.payload.isRemoved;
      return draft;
    }),
    [errorRemoveCompany]: produce((draft, action) => {
      draft.settings.isRemoved = false;
      draft.settings.error = action.payload.error;
      return draft;
    }),
    [loadedTopicOptions]: produce((draft, action) => {
      draft.topics.options = action.payload.topicOptions;
      return draft;
    }),
    [loadSaveCompanyTopics]: produce((draft) => {
      draft.topics.success = initialState.topics.success;
      draft.topics.error = initialState.topics.error;
      return draft;
    }),
    [loadedSaveCompanyTopics]: produce((draft, action) => {
      draft.topics.success = true;
      draft.topics.error = null;
      draft.company = action.payload.company;
      return draft;
    }),
    [errorSaveCompanyTopics]: produce((draft, action) => {
      draft.topics.success = false;
      draft.topics.error = action.payload.error;
      return draft;
    }),
    [removeUser]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
  },
  initialState,
);

export default companyProfileReducer;
