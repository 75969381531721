import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

const NoList = ({ networkName }) => (
  <Flex sx={{ gap: '16px', maxWidth: '356px', width: '100%', flexDirection: 'column' }}>
    <Box as="h1" fontSize="22px" fontWeight="600">
      Oops! We can’t find that list
    </Box>
    <Box fontSize="15px" lineHeight="1.5">
      Either there is an error on the URL or the list is no longer accessible. Double-check the URL or{' '}
      {networkName
        ? ` reach out to your
      contact at ${networkName}`
        : 'person who shared the list with you'}
      .
    </Box>
  </Flex>
);

NoList.propTypes = {
  networkName: PropTypes.string.isRequired,
};

export default NoList;
