import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CSVReader from 'react-csv-reader';
import { Box } from '@getro/rombo';
import download from 'helpers/download';

const UploadCSV = ({ navigateBack, navigateForward, setRecords, records }) => {
  const [errorText, setErrorText] = useState(null);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (errorText !== null) {
      setIsValid(false);
    } else if (!records || records.length === 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [records, errorText]);

  const onDownloadSampleClick = () => {
    const sample = [
      'firstName;lastName;email;permissions',
      'Annibal;Smith;annibal@ateam.org;verified',
      'H.M.;Murdock;murdock@ateam.org;non-verified',
    ].join('\n');

    download('sample.csv', 'text/csv', sample);
  };

  const onFileLoaded = (data) => {
    const validRecords = data.filter((record) => record.firstName || record.lastName || record.email);
    if (validRecords.length === 0) {
      setErrorText(
        'No valid records found. Please check that you have at least defined one of the following columns: firstName, lastName and email',
      );
    } else {
      setErrorText(null);
      setRecords(validRecords);
    }
  };

  const onSaveClick = () => {
    if (isValid) navigateForward();
  };

  const csvParserOptions = {
    header: true,
    skipEmptyLines: true,
    dynamicTyping: true,
  };

  return (
    <Box
      size="md"
      title="Upload a CSV of members you'd like to upload"
      onCancelClick={navigateBack}
      cancelText="Cancel"
      onSaveClick={onSaveClick}
      saveText="Next"
      onBackClick={onDownloadSampleClick}
      errorText={errorText}
      backText="Download sample CSV"
      data-testid="upload-csv"
    >
      <CSVReader
        cssClass="csv-reader-input"
        label="Drag your file here or click to upload"
        onFileLoaded={onFileLoaded}
        parserOptions={csvParserOptions}
      />
      {isValid && (
        <>
          <br />
          <p>
            <strong>{records.length} records found</strong>
          </p>
        </>
      )}
    </Box>
  );
};

UploadCSV.propTypes = {
  navigateBack: PropTypes.func.isRequired,
  navigateForward: PropTypes.func.isRequired,
  setRecords: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
};

export default UploadCSV;
