import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Filter from '../../../components/molecules/Filter';

export const PUBLISHED = [
  { label: 'Any', value: '' },
  { label: 'Published', value: 'true' },
  { label: 'Not published', value: 'false' },
];

const PublishedFilter = ({ value, onChange, embeddedInModal, onDropDownStateChange, showLabel }) => {
  const selectedOption = useMemo(() => PUBLISHED.find((i) => i.value === value), [value]) ?? null;
  return (
    <Filter
      showLabel={showLabel}
      label="Published"
      placeholder="Published"
      name="qa"
      onChange={(item) => onChange(item)}
      value={selectedOption}
      options={PUBLISHED}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

PublishedFilter.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
  showLabel: PropTypes.bool,
};

PublishedFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
  showLabel: true,
};

export default PublishedFilter;
