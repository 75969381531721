import hexToRgba from 'helpers/hexToRgba';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';
import { useGetJobBoardSettingsQuery, useUpdateJobBoardSettingsMutation } from 'services/networkV2';
import { useEffect, useMemo } from 'react';
import getRgbaAlpha from 'helpers/rgbaGetAlpha';
import rgbaToHex from 'helpers/rgbaToHex';
import { trackEvent, trackPage } from 'helpers/analytics';
import {
  companiesSort,
  heroBackgroundType,
  heroOptions,
  heroTextColor,
  tabOrderOptions,
  tabOrderOptionsDefault,
} from '../options';

export const useJobBoardAppearance = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { isLoading, data } = useGetJobBoardSettingsQuery();
  const [update, { isLoading: isUpdating }] = useUpdateJobBoardSettingsMutation();

  const customHeroAndAD = !isLoading && data?.advancedDesign && data?.customHero;
  const noCustomHeroAndAD = !isLoading && data?.advancedDesign && !data?.customHero;
  const isGCOrTN = !isLoading && (data?.talentNetwork || (data?.tnOnboardingV2 && !data.tnHidden));
  const standardDesign = noCustomHeroAndAD || !data?.advancedDesign || !data?.customHero;
  const tabOrderOptionsToUse = isGCOrTN ? tabOrderOptions : tabOrderOptionsDefault;

  useEffect(() => {
    trackPage();
  }, []);

  const handleSubmit = (values) => {
    let updates = {
      tabsOrder: values.tabsOrder.value,
    };

    if (values.placement.value === 'hero') {
      updates.hideNumbersInHero = false;
      updates.numbersInNav = false;
    } else if (values.placement.value === 'navigation_bar') {
      updates.hideNumbersInHero = true;
      updates.numbersInNav = true;
    } else {
      updates.hideNumbersInHero = true;
      updates.numbersInNav = false;
    }

    if (!standardDesign) {
      return update(updates);
    }

    updates = {
      ...updates,
      heroSubtitle: values.heroSubtitle,
      heroTitle: values.heroTitle,
      darkHeroText: values.heroTextColor.value,
      hideLogoInHero: !values.hideLogoInHero,
      hideNameInHero: !values.hideNameInHero,
      claimOrganizationProfileInHero: values.claimOrganizationProfileInHero,
      hideTnCtaInHero: !values.hideTnCtaInHero,
    };

    if (values.heroBackgroundType.value === 'solid') {
      updates.heroColorMask = hexToRgba(values.heroColorMask, 1);
      updates.heroImageUrl = null;
    }
    if (values.heroBackgroundType.value === 'image') {
      updates.heroImageUrl = values.heroImageUrl;

      if (values.imageMask) {
        updates.heroColorMask = hexToRgba(values.heroColorMask, Number(values.heroMaskOpacity) / 100);
      } else {
        updates.heroColorMask = null;
      }
    }

    return update(updates).then(() => {
      trackEvent('network_settings:edit_job_board_appearance_settings', {
        collectionId: activeNetwork.id,
      });
    });
  };

  const placement = useMemo(() => {
    if (!standardDesign && data?.hideNumbersInHero && !data?.numbersInNav) {
      return heroOptions[0];
    }
    if (data?.hideNumbersInHero && data?.numbersInNav) {
      return heroOptions[1];
    }
    return heroOptions[2];
  }, [data?.hideNumbersInHero, data?.numbersInNav, standardDesign]);

  const initialValues = data
    ? {
        tabsOrder: tabOrderOptionsToUse.find((option) => option.value === data?.tabsOrder) || tabOrderOptionsToUse[0],
        placement,
        companiesSort: data?.sortOrganizationsActiveJobs ? companiesSort[1] : companiesSort[0],
        heroTitle: data?.heroTitle,
        heroSubtitle: data?.heroSubtitle,
        heroTextColor: data?.darkHeroText ? heroTextColor[1] : heroTextColor[0],
        hideLogoInHero: !data?.hideLogoInHero,
        hideNameInHero: !data?.hideLogoInHero,
        claimOrganizationProfileInHero: data?.claimOrganizationProfileInHero,
        hideTnCtaInHero: !data?.hideTnCtaInHero,
        heroBackgroundType: data?.heroImageUrl ? heroBackgroundType[1] : heroBackgroundType[0],
        imageMask: Boolean(data?.heroColorMask),
        heroMaskOpacity: data?.heroColorMask ? (getRgbaAlpha(data?.heroColorMask) ?? 1) * 100 : 80,
        heroImageUrl: data?.heroImageUrl,
        heroColorMask: data?.heroColorMask ? rgbaToHex(data.heroColorMask) : '#333333',
      }
    : null;

  return {
    handleSubmit,
    customHeroAndAD,
    noCustomHeroAndAD,
    standardDesign,
    tabOrderOptionsToUse,
    slug: activeNetwork.slug,
    url: activeNetwork.url,
    initialValues,
    placement,
    data,
    isLoading,
    update,
    isUpdating,
    heroOptions: standardDesign ? heroOptions : heroOptions.slice(1),
    ...(data || {}),
    isGCOrTN,
  };
};
