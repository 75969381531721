import moment from 'moment';

export const sortPositions = (positionList = []) => {
  const positions = [];
  const currentPositions = [];

  positionList.slice(0).forEach((element) => {
    if (element.dateTo) {
      positions.push(element);
    } else {
      currentPositions.push(element);
    }
  });

  return [
    ...currentPositions.sort((a, b) => (a.dateFrom < b.dateFrom ? 1 : -1)),
    ...positions.sort((a, b) => (a.dateFrom < b.dateFrom ? 1 : -1)),
  ];
};

export const sortPositionsByCompany = (workExperiences) =>
  sortPositions(workExperiences).reduce((carry, item) => {
    const companyIndex = carry.findIndex((pos) => pos[0].companyName === item.companyName);

    if (companyIndex >= 0) {
      carry[companyIndex].push(item);
    } else {
      carry.push([item]);
    }

    return carry;
  }, []);

export const toStringLastChange = (primaryActivePosition) =>
  primaryActivePosition?.dateFrom ? moment(new Date(primaryActivePosition?.dateFrom)).diff(Date.now(), 'years') : null;
