import { takeLatest, call, put } from 'redux-saga/effects';
import { loadAllOrganizations, loadedAllOrganizations, errorAllOrganizations } from './actions';
import { request } from '../../api';
import { trackException } from '../../helpers/errorTracker';
import APIError from '../../api/APIError';

function* loadAllOrganizationsEvent({ payload }) {
  const { activeNetwork } = payload;
  try {
    const response = yield call(request, { url: `/collections/${activeNetwork.id}/organizations/all` });
    const { data } = response;
    yield put(loadedAllOrganizations(data));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }

    yield put(errorAllOrganizations(e));
  }
}

export default function* saga() {
  yield takeLatest(loadAllOrganizations().type, loadAllOrganizationsEvent);
}
