import React from 'react';
import { Flex } from 'rebass/styled-components';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AdminTable } from 'components/organisms/adminTable';
import { useNetworkAdmins } from 'hooks/useNetworkAdmins';
import { SettingsLayout } from '../layout';
import pageHoc from '../../../hocs/pageHoc';
import MainLayout from '../../../components/layout/Main';
import { activeNetworkSelector, userSelector } from '../../../redux/selectors';

const pageTitle = 'Settings';

const Admins = () => {
  const {
    isLoadingNetworkAdmins,
    isLoadingResendInvite,
    isLoadingRemoveAdmin,
    isLoadingAddAdmin,
    isLoadingLinkedinSync,
    admins,
    resendInvite,
    removeAdmin,
    hasLoadAdminsListError,
    addAdmin,
    updateLinkedinSync,
  } = useNetworkAdmins();
  const activeNetwork = useSelector(activeNetworkSelector);
  const currentUser = useSelector(userSelector);
  if (!activeNetwork.isManager) {
    return (
      <Redirect
        to={{
          pathname: `/networks/${activeNetwork.slug}/settings`,
        }}
      />
    );
  }
  return (
    <MainLayout>
      <SettingsLayout fullWidth>
        <Flex>
          <AdminTable
            title="Network admins"
            loadingAdminsError={hasLoadAdminsListError}
            isLoadingAdmins={isLoadingNetworkAdmins}
            adminGroupName={activeNetwork.name}
            isInvitingAdmin={isLoadingAddAdmin}
            isResendingInvitation={isLoadingResendInvite}
            isDeletingAdmin={isLoadingRemoveAdmin}
            activeNetwork={activeNetwork}
            currentUser={currentUser}
            admins={admins}
            onRemoveAdmin={removeAdmin}
            onResendInvitation={resendInvite}
            onAdminInvite={addAdmin}
            canAddCompanyAdmins
            canRemoveCompanyAdmins
            showEmailAddress
            companyAdmins={false}
            updateLinkedinSync={updateLinkedinSync}
            isLoadingLinkedinSync={isLoadingLinkedinSync}
          />
        </Flex>
      </SettingsLayout>
    </MainLayout>
  );
};
export default pageHoc({ title: pageTitle })(Admins);
