const sourceColorMap = {
  'Daily update': 'icon.success',
  'Monthly update': 'icon.success',
  Multiple: 'icon.success',
  'Under review': 'icon.info',
  'Need action': 'icon.info',
  Excluded: 'neutral.400',
  'No longer exists': 'neutral.400',
  'No job source': 'neutral.400',
};

export default sourceColorMap;
