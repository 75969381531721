import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass/styled-components';
import { AdminListItem } from '../adminListItem';
import BlankMessage from '../BlankMessage';

export const AdminList = ({
  onEmptyListClick,
  companyName,
  users,
  onResendInvitation,
  isResendingInvitation,
  isTalentNetworkActive,
  showEmailAddress,
  onRemoveAdmin,
}) => {
  if (!users || !users.length) {
    const children = isTalentNetworkActive ? (
      <Text data-testid="talent-network-active">
        Invite a company contact (like a hiring manager) to help you manage the {companyName} profile and to access your
        talent network.
      </Text>
    ) : (
      <Text data-testid="no-talent-network-active">
        Invite a company contact (like a hiring manager) to help you manage the {companyName} profile.
      </Text>
    );
    return (
      <BlankMessage title="No admins" buttonText="Invite admin" onClick={onEmptyListClick}>
        {children}
      </BlankMessage>
    );
  }
  return (
    <Flex data-testid="admin-list">
      <Box width={1}>
        {users.map((user, index) => {
          const isLast = index === users.length - 1;
          return (
            <AdminListItem
              key={user.id}
              sx={{ borderBottom: 1, borderBottomColor: 'neutral.100', borderBottomStyle: !isLast ? 'solid' : 'unset' }}
              user={user}
              onResendInvitation={onResendInvitation}
              isResendingInvitation={isResendingInvitation}
              showEmailAddress={showEmailAddress}
              onRemoveAdmin={onRemoveAdmin}
            />
          );
        })}
      </Box>
    </Flex>
  );
};

AdminList.propTypes = {
  users: PropTypes.array,
  companyName: PropTypes.string,
  onResendInvitation: PropTypes.func,
  isResendingInvitation: PropTypes.bool,
  onRemoveAdmin: PropTypes.func,
  isTalentNetworkActive: PropTypes.bool,
  onEmptyListClick: PropTypes.func,
  showEmailAddress: PropTypes.bool.isRequired,
};

AdminList.defaultProps = {
  users: null,
  onResendInvitation: null,
  isTalentNetworkActive: false,
  companyName: '',
  onEmptyListClick: () => {},
  onRemoveAdmin: null,
  isResendingInvitation: false,
};
