import { handleActions } from 'redux-actions';
import produce from 'immer';
import { loadedJobRules, loadJobRules } from './actions';

export const initialState = {
  titles: null,
  keywords: null,
  locations: null,
};
/* eslint-disable default-case, no-param-reassign */

const jobRulesReducer = handleActions(
  {
    [loadJobRules]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [loadedJobRules]: produce((draft, action) => {
      const { titles, keywords, locations } = action.payload;
      draft.titles = titles && titles.length ? [...titles].sort() : null;
      draft.keywords = keywords && keywords.length ? [...keywords].sort() : null;
      draft.locations = locations && locations.length ? [...locations].sort() : null;
      return draft;
    }),
  },
  initialState,
);

export default jobRulesReducer;
