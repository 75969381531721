import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Download } from 'lucide-react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { useDownloadHiresMutation } from '../../services/hires';
import { trackEvent } from '../../helpers/analytics';
import { activeNetworkSelector } from '../../redux/selectors';

export const Actions = () => {
  const [downloadHires] = useDownloadHiresMutation();
  const activeNetwork = useSelector(activeNetworkSelector);

  const onClickDownload = async () => {
    await downloadHires();

    toast('Email sent.', { icon: 'success', id: 'download_hires' });
    trackEvent('hires:export_hires', { collection_id: activeNetwork.id });
  };

  return (
    <Flex alignItems="center" justifyContent="flex-end">
      <Flex data-testid="button-download" alignItems="center" onClick={onClickDownload} sx={{ cursor: 'pointer' }}>
        <Box as={Download} mr="8px" width="16px" height="16px" aria-hidden="true" />
        <Text fontSize="12px" fontWeight="medium">
          Download
        </Text>
      </Flex>
    </Flex>
  );
};
