import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Input } from '@rebass/forms/styled-components';

export const JobHeroMaskOpacity = ({ onChange, value }) => (
  <Flex sx={{ alignItems: 'center', gap: '14px' }}>
    <Text color="text.main" fontSize="14px" lineHeight="24px">
      Opacity:
    </Text>
    <Flex sx={{ alignItems: 'center', gap: '16px' }}>
      <Box sx={{ width: '120px' }}>
        <Box
          sx={{
            position: 'relative',
            input: {
              border: 0,
              position: 'absolute',
              outline: 'none',
              px: 0,
              pointerEvents: 'none',
              backgroundColor: 'transparent',
              zIndex: 3,
              '&::-webkit-slider-thumb': {
                appearance: 'none',
                background: '#8A75F0',
                border: 'none',
                borderRadius: '50%',
                cursor: 'pointer',
                height: '16px',
                width: '16px',
                pointerEvents: 'all',
                position: 'relative',
              },
              '::-moz-range-thumb': {
                appearance: 'none',
                background: '#8A75F0',
                border: 'none',
                borderRadius: '50%',
                cursor: 'pointer',
                height: '20px',
                width: '20px',
                pointerEvents: 'all',
                position: 'relative',
              },
            },
          }}
        >
          <Input
            data-testid="min-range"
            type="range"
            min={0}
            max={100}
            height="0"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </Box>
        <Box pt="6px" sx={{ position: 'relative' }}>
          <Box height="4px" width="100%" sx={{ zIndex: 1, top: '6px', bg: 'neutral.100' }} />
          <Box
            height="4px"
            sx={{
              width: value * 1.2,
              position: 'absolute',
              zIndex: 2,
              top: '6px',
              bg: '#8A75F0',
            }}
          />
        </Box>
      </Box>
      <Flex sx={{ alignItems: 'center', gap: '4px' }}>
        <Input
          sx={{
            border: '1px solid',
            borderColor: 'border.default',
            borderRadius: '4px',
            width: '48px',
            height: '32px',
            p: '8px',
            color: 'text.main',
          }}
          value={value}
          onChange={(e) => {
            if (!e.target.value) {
              onChange(0);
            }

            const percent = parseInt(e.target.value, 10);
            if (percent > 0 && percent < 100) {
              onChange(percent);
            }
          }}
        />
        <Text color="text.main" fontSize="14px" lineHeight="24px">
          %
        </Text>
      </Flex>
    </Flex>
  </Flex>
);

JobHeroMaskOpacity.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};
