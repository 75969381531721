import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

const PageLayoutSidebar = ({ children, className }) => (
  <Box
    maxWidth={['100%', '100%', '240px']}
    mr={[0, 0, '40px']}
    mb={[4, 4, 0]}
    pl="16px"
    pr={['16px', '16px', 0]}
    className={`PageLayoutSidebar ${className}`}
  >
    {children}
  </Box>
);

PageLayoutSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PageLayoutSidebar.defaultProps = {
  className: null,
};

export default PageLayoutSidebar;
