import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Flex, Image, Text } from 'rebass/styled-components';
import { X } from 'lucide-react';
import MagicWand from '../../../assets/icon-magic-wand.svg';

const CompanyNotification = ({ onClick, onClose, title, description, buttonText, sx }) => (
  <Flex
    sx={{
      gap: '16px',
      position: 'relative',
      boxShadow: ' 0px 1px 0px rgba(0, 0, 0, 0.06)',
      borderRadius: '8px',
      backgroundImage: 'none',
      background: ` 
      radial-gradient(
            28.66% 216.15% at 81.69% 246.88%,
            #d97cac 0%,
            rgba(217, 124, 172, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        radial-gradient(
            24.37% 392.71% at 93.68% 368.23%,
            #ffae94 0%,
            rgba(255, 174, 148, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        radial-gradient(
            34.84% 393.75% at 59.81% 410.94%,
            #864ff8 0%,
            rgba(134, 79, 248, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        #ffffff;`,
      ...sx,
    }}
    p="16px"
    justifyContent="space-between"
    minHeight="96px"
    width="100%"
    mb="40px"
    flexDirection={['column', 'row']}
    alignItems={['flex-start', 'center']}
  >
    {onClose && (
      <Box
        onClick={onClose}
        sx={{ position: 'absolute', right: '8px', top: '8px', cursor: 'pointer' }}
        as={X}
        width="16px"
      />
    )}
    <Flex
      sx={{ gap: '16px' }}
      flexDirection={['column', 'row']}
      alignItems={['flex-start', 'center']}
      justifyContent="space-between"
    >
      <Image src={MagicWand} />
      <Box>
        <Text as="h3" color="text.dark" fontSize="18px" lineHeight="22px" fontWeight="600">
          {title}
        </Text>
        <Text mt="8px" color="text.main" fontWeight="500" as="p" fontSize="14px" lineHeight="1.4">
          {description}
        </Text>
      </Box>
    </Flex>
    <Button
      sx={{ width: ['100%', 'fit-content'], fontSize: '14px', lineHeight: '16px' }}
      height={['40px', '32px']}
      display="flex"
      justifyContent="center"
      variant="primary"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  </Flex>
);

CompanyNotification.defaultProps = {
  onClose: null,
  sx: {},
};

CompanyNotification.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  sx: PropTypes.objectOf(PropTypes.any),
};

export default CompanyNotification;
