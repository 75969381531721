import { createSelector } from 'reselect';
import { activeNetworkSelector } from 'redux/selectors';
import { initialState } from './reducer';

export const key = 'inviteMember';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const isNetworkManagerSelector = createSelector(activeNetworkSelector, (activeNetwork) => {
  const { isManager } = activeNetwork;

  return isManager;
});

export const canSetPermissionsSelector = createSelector(activeNetworkSelector, (activeNetwork) => {
  const { isManager, isConnector } = activeNetwork;

  return isManager || isConnector;
});

export const managingOrganizationsSelector = createSelector(activeNetworkSelector, (activeNetwork) => {
  const { isOrganizationManager } = activeNetwork;

  return ((n) => !!n && typeof n === 'object' && !!Object.keys(n).length && Object.values(n))(isOrganizationManager);
});

export const isManagingOrganizationsSelector = createSelector(managingOrganizationsSelector, (o) => !!o);

export const showVouchSelector = createSelector(rootSelector, (state) => state.showVouch);
export const showSuccessSelector = createSelector(rootSelector, (state) => state.showSuccess);
export const inviteMemberVouchSelector = createSelector(rootSelector, (state) => state.vouch);
export const errorSelector = createSelector(rootSelector, (state) => state.error);
export const invitedUserSelector = createSelector(rootSelector, (state) => state.invitedUser);

export const inviteMemberInfoSelector = createSelector(
  rootSelector,
  canSetPermissionsSelector,
  managingOrganizationsSelector,
  (state, canSetPermissions, managingOrganizations) => {
    const { info } = state;
    const companies = managingOrganizations ? managingOrganizations.map((o) => ({ value: o.id, label: o.name })) : [];

    if (!info) {
      return {
        firstName: '',
        lastName: '',
        email: '',
        linkedinUrl: '',
        roles: [],
        verified: canSetPermissions,
        company: companies.length ? companies[0] : {},
      };
    }

    return info;
  },
);
