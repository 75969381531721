import { createActions } from 'redux-actions';

export const { loadUserProfile, loadedUserProfile, errorUserProfile } = createActions(
  'LOAD_USER_PROFILE',
  'LOADED_USER_PROFILE',
  'ERROR_USER_PROFILE',
);

export const { loadUpdateUserProfile, userProfileUpdated, loadedUpdateUserProfile, errorUpdateUserProfile } =
  createActions(
    'LOAD_UPDATE_USER_PROFILE',
    'USER_PROFILE_UPDATED',
    'LOADED_UPDATE_USER_PROFILE',
    'ERROR_UPDATE_USER_PROFILE',
  );
