import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'companyProfile';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const makeSelectCompanySettings = createSelector(rootSelector, (state) => state.settings);

export const makeSelectTopicOptions = createSelector(rootSelector, (state) => state.topics);
