import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { AsyncButton, Button, FormField, Modal } from '@getro/rombo';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { emailRegEx } from '../../../helpers/regExs';

export const InviteAdminModal = ({
  isSubmitting,
  onAdminInvite,
  companyName,
  modalOpen,
  setModalOpen,
  formRef,
  companyAdmin,
}) => {
  const title = useMemo(() => {
    if (companyAdmin) {
      return `Invite admin for ${companyName}`;
    }

    return 'Invite network admin';
  }, [companyAdmin, companyName]);

  const message = useMemo(() => {
    if (companyAdmin) {
      return 'Company Admins can help you manage their company profile and job sources.';
    }

    return 'Network admins can manage everything (companies, jobs, settings and users), and see analytics.';
  }, [companyAdmin]);

  const inviteAdminFormikSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string(),
    email: Yup.string().trim().matches(emailRegEx, 'Not a valid email').required('Required'),
  });
  const inviteAdminInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  return (
    <Modal
      title={title}
      isOpen={modalOpen}
      onCancel={() => {
        setModalOpen(false);
      }}
      actions={
        <Flex justifyContent={['flex-end']}>
          <Button
            mr={[2]}
            onClick={() => {
              setModalOpen(false);
            }}
            variant="tertiary"
          >
            Cancel
          </Button>
          <AsyncButton
            ml={[2]}
            loading={isSubmitting}
            onClick={() => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            }}
          >
            Invite admin
          </AsyncButton>
        </Flex>
      }
      data-testid="invite-admin"
    >
      <Flex flexDirection="column">
        <Text width={1} mb={2}>
          {message}
        </Text>
        <Box width={1}>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={inviteAdminInitialValues}
            validationSchema={inviteAdminFormikSchema}
            onSubmit={onAdminInvite}
          >
            {({ values, errors }) => (
              <Form>
                <Flex flexDirection="column">
                  <Flex width={1} mb={3}>
                    <Box width={1 / 2} mr={1}>
                      <Field
                        required
                        name="firstName"
                        error={values.firstName && errors.firstName}
                        value={values.firstName}
                        type="text"
                        label="First name *"
                        component={FormField}
                      />
                    </Box>
                    <Box width={1 / 2} ml={2}>
                      <Field
                        name="lastName"
                        error={errors.lastName}
                        value={values.lastName}
                        type="text"
                        label="Last name"
                        component={FormField}
                      />
                    </Box>
                  </Flex>
                  <Box width={1}>
                    <Field
                      required
                      name="email"
                      error={values.email && errors.email}
                      value={values.email}
                      type="text"
                      label="Email address *"
                      component={FormField}
                    />
                  </Box>
                  <Button variant="variants.srOnly" type="submit">
                    Invite admin
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>
    </Modal>
  );
};

InviteAdminModal.propTypes = {
  onAdminInvite: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func.isRequired,
  formRef: PropTypes.object,
  isSubmitting: PropTypes.bool,
  companyAdmin: PropTypes.bool,
};

InviteAdminModal.defaultProps = {
  modalOpen: false,
  isSubmitting: false,
  formRef: null,
  companyAdmin: true,
};
