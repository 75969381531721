import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  closeDownloadLikelyApplied,
  errorAllJobAlertSubscribers,
  errorJobAlertSubscribers,
  errorJobsApplicants,
  errorJobsClicks,
  errorJobsLikelyApplied,
  errorSuggestedCompaniesCount,
  errorUniqueVisitors,
  loadAllJobAlertSubscribers,
  loadedAllJobAlertSubscribers,
  loadedJobAlertSubscribers,
  loadedJobsApplicants,
  loadedJobsClicks,
  loadedJobsLikelyApplied,
  loadedSuggestedCompaniesCount,
  loadedUniqueVisitors,
  loadJobAlertSubscribers,
  loadJobsApplicants,
  loadJobsClicks,
  loadJobsLikelyApplied,
  loadSuggestedCompaniesCount,
  loadUniqueVisitors,
} from './actions';

export const initialState = {
  uniqueVisitors: {
    count: 0,
    error: false,
  },
  jobClicks: {
    count: 0,
    error: false,
  },
  jobAlert: {
    count: 0,
    error: false,
  },
  allJobAlert: {
    count: 0,
    error: false,
  },
  companies: {
    suggestedCount: 0,
    suggestedError: false,
  },
  jobsApplicants: {
    count: 0,
    error: false,
  },
  jobsLikelyApplied: {
    count: 0,
    error: false,
  },
  downloadLikelyApplied: {
    error: false,
    isOpen: false,
  },
};

/* eslint-disable default-case, no-param-reassign */
const reducer = handleActions(
  {
    [loadUniqueVisitors]: produce((draft) => {
      draft.uniqueVisitors.count = 0;
      draft.uniqueVisitors.error = false;
      return draft;
    }),
    [loadedUniqueVisitors]: produce((draft, { payload: { total } }) => {
      draft.uniqueVisitors.count = total;
      draft.uniqueVisitors.error = false;
      return draft;
    }),
    [errorUniqueVisitors]: produce((draft) => {
      draft.uniqueVisitors.error = true;
      return draft;
    }),
    [loadJobsClicks]: produce((draft) => {
      draft.jobClicks.count = 0;
      draft.jobClicks.error = false;
      return draft;
    }),
    [loadedJobsClicks]: produce((draft, { payload: { total } }) => {
      draft.jobClicks.count = total;
      return draft;
    }),
    [errorJobsClicks]: produce((draft) => {
      draft.jobClicks.error = true;
      return draft;
    }),
    [loadJobAlertSubscribers]: produce((draft) => {
      draft.jobAlert.count = 0;
      draft.jobAlert.error = false;
      return draft;
    }),
    [loadedJobAlertSubscribers]: produce((draft, { payload: { total } }) => {
      draft.jobAlert.count = total;
      draft.jobAlert.error = false;
      return draft;
    }),
    [errorJobAlertSubscribers]: produce((draft) => {
      draft.jobAlert.error = true;
      return draft;
    }),
    [loadAllJobAlertSubscribers]: produce((draft) => {
      draft.allJobAlert.count = 0;
      draft.allJobAlert.error = false;
      return draft;
    }),
    [loadedAllJobAlertSubscribers]: produce((draft, { payload: { total } }) => {
      draft.allJobAlert.count = total;
      draft.allJobAlert.error = false;
      return draft;
    }),
    [errorAllJobAlertSubscribers]: produce((draft) => {
      draft.allJobAlert.error = true;
      return draft;
    }),
    [loadSuggestedCompaniesCount]: produce((draft) => {
      draft.companies.suggestedError = false;
      draft.companies.suggestedCount = 0;
      return draft;
    }),
    [loadedSuggestedCompaniesCount]: produce((draft, { payload: { total } }) => {
      draft.companies.suggestedError = false;
      draft.companies.suggestedCount = total;
      return draft;
    }),
    [errorSuggestedCompaniesCount]: produce((draft) => {
      draft.companies.suggestedError = true;
      return draft;
    }),
    [loadJobsLikelyApplied]: produce((draft) => {
      draft.jobsLikelyApplied.count = 0;
      draft.jobsLikelyApplied.error = false;
      return draft;
    }),
    [loadedJobsLikelyApplied]: produce((draft, { payload: { total } }) => {
      draft.jobsLikelyApplied.count = total;
      draft.jobsLikelyApplied.error = false;
      return draft;
    }),
    [errorJobsLikelyApplied]: produce((draft) => {
      draft.jobsLikelyApplied.error = true;
      return draft;
    }),
    [loadJobsApplicants]: produce((draft) => {
      draft.jobsApplicants.count = 0;
      draft.jobsApplicants.error = false;
      return draft;
    }),
    [loadedJobsApplicants]: produce((draft, { payload: { total } }) => {
      draft.jobsApplicants.count = total;
      draft.jobsApplicants.error = false;
      return draft;
    }),
    [errorJobsApplicants]: produce((draft) => {
      draft.jobsApplicants.error = true;
      return draft;
    }),
    [closeDownloadLikelyApplied]: produce((draft) => {
      draft.downloadLikelyApplied = initialState.downloadLikelyApplied;
      return draft;
    }),
  },
  initialState,
);

export default reducer;
