import { getItem } from 'helpers/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { activeNetworkSelector, userSelector } from 'redux/selectors';

import { EXTENSION_ID, checkExtensionAvailability } from '../services/chromeExtension';

export const EXTENSION_MESSAGE_TYPES = {
  SEND_COOKIES_ERROR: 'SEND_COOKIES_ERROR',
  SEND_COOKIES_SUCCESS: 'SEND_COOKIES_SUCCESS',
  SCRAPER_INITIALIZED: 'SCRAPER_INITIALIZED',
};

export const useLaunchScraper = (hasExtension) => {
  const network = useSelector(activeNetworkSelector);
  const user = useSelector(userSelector);
  const [importing, setImporting] = useState(false);
  const [responseType, setResponseType] = useState();
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(hasExtension);
  const intervalRef = useRef();

  const launchScraper = useCallback(() => {
    setImporting(true);

    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      if (chrome?.runtime?.sendMessage) {
        // eslint-disable-next-line no-undef
        chrome.runtime.sendMessage(
          EXTENSION_ID,
          {
            type: 'SCRAPER_INITIALIZE',
            auth: getItem('authToken'),
            user: {
              ...user,
              fullName: `${user.firstName} ${user.lastName}`,
            },
            networkId: network.id,
          },
          (response) => {
            setResponseType(response?.type);
            setImporting(false);

            resolve(response?.type);
          },
        );

        resolve(EXTENSION_MESSAGE_TYPES.SCRAPER_INITIALIZED);
        setImporting(false);
      }
    });
  }, [network, user]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkExtensionAvailability()
        .then((availability) => {
          setIsExtensionInstalled(availability);
        })
        .catch(() => {
          setIsExtensionInstalled(false);
        });
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return {
    launchScraper,
    responseType,
    importing,
    isExtensionInstalled,
  };
};
