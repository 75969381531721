import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useInjectReducer } from '../../hooks/useInjectReducer';
import { useInjectSaga } from '../../hooks/useInjectSaga';
import { key } from '../Settings/selectors';
import { loadTalentNetworkSettings } from '../Settings/actions';
import reducer from '../Settings/reducer';
import saga from '../Settings/saga';

export const TalentNetworkSettings = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTalentNetworkSettings());
  }, [dispatch]);

  return false;
};
