import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'jobRules';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const makeSelectJobRules = createSelector(rootSelector, (state) => state);
