import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { useSelector } from 'react-redux';
import { MainLayoutMenu } from '../../molecules/mainLayoutMenu';

const MainLayout = ({ children, containerProps }) => {
  const isMenuOpen = useSelector((state) => state.mainMenu.isOpen);

  return (
    <Flex>
      <MainLayoutMenu />
      <Box
        ml={isMenuOpen ? [0, 0, '240px'] : [0, 0, '40px']}
        flex="1"
        sx={{ transition: ['none', 'none', 'margin-left 0.15s ease-in-out'] }}
      >
        <Flex minHeight="calc(100vh - 64px)" pb="40px" flexDirection="column" {...containerProps}>
          {children}
        </Flex>
      </Box>
    </Flex>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  containerProps: PropTypes.object,
};

MainLayout.defaultProps = {
  containerProps: {},
};

export default MainLayout;
