import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setPageTitle } from '../actions/pageActions';
import { trackPage } from '../helpers/analytics';

const usePageHook = (title) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (title) {
      dispatch(setPageTitle(title));
    }

    return () => dispatch(setPageTitle(''));
  }, [dispatch, title]);

  useEffect(() => {
    trackPage();
  }, []);
};

export default usePageHook;
