import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Link } from 'react-router-dom';
import { SidebarOpen } from 'lucide-react';
import { useDispatch } from 'react-redux';

import { toggleMenu } from '../../../features/mainMenu';

export const MainLayoutHeader = ({ title, actions, breadcrumbs }) => {
  const dispatch = useDispatch();

  return (
    <Flex
      as="header"
      height={['auto', 'auto', '48px']}
      minHeight={['48px', '48px', 'auto']}
      px="16px"
      py="8px"
      alignItems="center"
      sx={{ borderBottom: 1, borderBottomColor: 'border.subtle', gap: '16px' }}
    >
      <Box
        display={['flex', 'flex', 'none']}
        alignSelf="flex-start"
        pt={['10px', '10px', 0]}
        alignItems="center"
        justifyContent="center"
        width="22px"
        height="22px"
        onClick={() => dispatch(toggleMenu())}
      >
        <Box as={SidebarOpen} width="16px" height="16px" aria-hidden="true" flex="0 0 auto" />
      </Box>
      <Flex
        alignItems={['flex-start', 'flex-start', 'center']}
        justifyContent="space-between"
        flexWrap="wrap"
        width="100%"
        flexDirection={['column', 'column', 'row']}
        sx={{ gap: '8px' }}
      >
        <Box>
          {breadcrumbs
            .filter((element) => element !== null)
            .map(({ label, to, ...others }) => (
              <Link
                key={label}
                to={to}
                style={{ textDecoration: 'none', display: 'inline-block', marginRight: '4px' }}
                {...others}
              >
                <Text color="text.subtle" fontWeight="medium">{`${label} /`}</Text>
              </Link>
            ))}
          <Box as="h1" fontSize="16px" fontWeight="semibold" color="text.dark" display="inline">
            {title}
          </Box>
        </Box>
        {!!actions.length && (
          <Flex alignItems="center" sx={{ gap: '8px' }}>
            {actions.map((action) => action)}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

MainLayoutHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.array,
  breadcrumbs: PropTypes.array,
};

MainLayoutHeader.defaultProps = {
  actions: [],
  breadcrumbs: [],
};
