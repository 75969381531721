import { createAction, createActions } from 'redux-actions';

export const { loadGeneralSettings, loadedGeneralSettings, errorGeneralSettings } = createActions(
  'LOAD_GENERAL_SETTINGS',
  'LOADED_GENERAL_SETTINGS',
  'ERROR_GENERAL_SETTINGS',
);

export const { loadSaveGeneralSettings, loadedSaveGeneralSettings, errorSaveGeneralSettings } = createActions(
  'LOAD_SAVE_GENERAL_SETTINGS',
  'LOADED_SAVE_GENERAL_SETTINGS',
  'ERROR_SAVE_GENERAL_SETTINGS',
);

export const { loadSaveGdprSettings, loadedSaveGdprSettings, errorSaveGdprSettings } = createActions(
  'LOAD_SAVE_GDPR_SETTINGS',
  'LOADED_SAVE_GDPR_SETTINGS',
  'ERROR_SAVE_GDPR_SETTINGS',
);

export const { loadTalentNetworkSettings, loadedTalentNetworkSettings, errorTalentNetworkSettings } = createActions(
  'LOAD_TALENT_NETWORK_SETTINGS',
  'LOADED_TALENT_NETWORK_SETTINGS',
  'ERROR_TALENT_NETWORK_SETTINGS',
);

export const { loadUpdateTalentNetworkSettings, loadedUpdateTalentNetworkSettings, errorUpdateTalentNetworkSettings } =
  createActions(
    'LOAD_UPDATE_TALENT_NETWORK_SETTINGS',
    'LOADED_UPDATE_TALENT_NETWORK_SETTINGS',
    'ERROR_UPDATE_TALENT_NETWORK_SETTINGS',
  );

export const trackPlansAndBilling = createAction('TRACK_PLANS_AND_BILLING');
