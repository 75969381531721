import React, { memo, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import AdminFilter from './adminFilter';
import AllFiltersModal from './allFilters';

import JobSourceFilter from './jobSourceFilter';
import KeywordFilter from './keywordFilter';

const CompaniesFilter = ({ setFilter, filters, setFilters, filterCount, topics }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Flex mb="32px" alignItems="flex-end" flexDirection="row" sx={{ gap: '8px', flexWrap: 'wrap' }}>
      <Box
        flexGrow={1}
        alignItems="flex-end"
        flexDirection="row"
        sx={{ gap: '8px', flexWrap: 'wrap' }}
        display={['none', 'flex']}
      >
        <Box flexGrow={1} width="1">
          <KeywordFilter showLabel={false} onChange={(value) => setFilter('keyword', value)} value={filters.keyword} />
        </Box>
        <Box width="144px">
          <JobSourceFilter
            onChange={({ value }) => setFilter('ocpStatus', value.toString())}
            value={filters.ocpStatus}
            embeddedInModal={false}
            showLabel={false}
          />
        </Box>
        <Box width="120px">
          <AdminFilter
            showLabel={false}
            embeddedInModal={false}
            onChange={({ value }) => setFilter('missingManager', value)}
            value={filters.missingManager}
          />
        </Box>
      </Box>
      <AllFiltersModal
        filterCount={filterCount}
        tags={topics}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        applyFilters={setFilters}
        companyFilters={filters}
      />
    </Flex>
  );
};

CompaniesFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  filterCount: PropTypes.number.isRequired,
  topics: PropTypes.array.isRequired,
};

export default memo(CompaniesFilter);
