export const getCompaniesColumns = ({ showTags }) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: !showTags ? '32%' : '22%',
      disableSortBy: true,
      show: true,
    },
    {
      Header: 'Location',
      accessor: 'location',
      width: !showTags ? '30%' : '20%',
      disableSortBy: true,
      show: true,
    },
    {
      Header: 'Visible Jobs',
      accessor: 'visibility',
      width: '8%',
      disableSortBy: true,
      sx: { justifyContent: 'flex-end' },
      show: true,
    },
    {
      Header: 'Job Source',
      accessor: 'source',
      width: '12%',
      disableSortBy: true,
      show: true,
    },
    {
      Header: 'tags',
      accessor: 'tags',
      width: '20%',
      disableSortBy: true,
      show: showTags,
    },
    {
      Header: 'Admins',
      accessor: 'admins',
      width: '6%',
      disableSortBy: true,
      sx: { justifyContent: 'flex-end' },
      show: true,
    },
    {
      Header: 'Published',
      accessor: 'published',
      width: '12%',
      show: true,
    },
  ];

  return columns.filter(({ show }) => show);
};
