import settings from 'settings';

const { talentGroupDefaultColor } = settings;

export const talentGroupDecorator = (t) => ({
  isSubmitting: false,
  id: t.id,
  value: t.id,
  label: t.name,
  description: t.description,
  color: t.color || talentGroupDefaultColor,
  visibility: t.visibility,
  count: t.userTalentGroupsCount,
  subscribed: t.subscribed,
  filters: t.filters || {},
  token: t.token || null,
  collectionId: t.collectionId || null,
  permission: t.permission || null,
  systemBoard: t.systemBoard || false,
  shared: t.shared || false,
  subscribtionsCount: t.userTalentGroupSubscriptionsCount || 0,
  creator: t.creator,
});

export const talentGroupsDecorator = (items) => items.map(talentGroupDecorator);

export default talentGroupsDecorator;
