import { createActions, createAction } from 'redux-actions';

export const { loadAddCompanyByName, loadedAddCompanyByName, errorAddCompanyByName } = createActions(
  'LOAD_ADD_COMPANY_BY_NAME',
  'LOADED_ADD_COMPANY_BY_NAME',
  'ERROR_ADD_COMPANY_BY_NAME',
);

export const resetAddCompany = createAction('RESET_ADD_COMPANY');

export const { loadBulkAddCompanyByDomain, loadedBulkAddCompanyByDomain, errorBulkAddCompanyByDomain } = createActions(
  'LOAD_BULK_ADD_COMPANY_BY_DOMAIN',
  'LOADED_BULK_ADD_COMPANY_BY_DOMAIN',
  'ERROR_BULK_ADD_COMPANY_BY_DOMAIN',
);
