import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button } from '@getro/rombo';
import { Box, Flex, Text, Card, Image } from 'rebass/styled-components';
import { Plus, Bell } from 'lucide-react';

import { Sidebar as FlexSidebar } from '../../molecules/flexSidebar';

import ListIcon from '../../../assets/icon-effortless-list.svg';
import { usePrevious } from '../../../hooks/usePrevious';
import './override.scss';
import { BoardSettingsModal } from '../boardSettinsModal';
import BoardDeleteConfirmationModal from '../boardDeleteConfirmationModal';
import { SearchableBoardsList } from '../../molecules/searchableBoardsList';
import serializeParams from '../../../helpers/serializeParams';

const listItems = [{ name: 'this is a crazy super long list name to break things' }];

for (let i = 0; i < 50; i += 1) {
  listItems.push({ name: `test${i + 1}` });
}

export const MembersSideBar = ({
  onCreate,
  onQuickCreate,
  activeNetwork,
  networkTalentGroups,
  isCreateLoading,
  memberInviteUrl,
  hasDailyAlerts,
  isTalentGroupsLoading,
  isBoardSettingsVisible,
  boardSettingsModalError,
  onCancelBoardSettingsModal,
  isCreatingNewBoard,
  onSubmitBoardSettingsModal,
  skills,
  preferredLocations,
  vouchedBy,
  onDeleteBoard,
  currentBoard,
  isBoardDeleteConfirmationVisible,
  onDeleteBoardCancel,
  isDeletingBoard,
  onSubmitDeleteBoard,
}) => {
  const [keyword, setKeyword] = useState('');
  const prevIsCreateLoading = usePrevious(isCreateLoading);
  const onSearchSubmit = (name) => {
    onQuickCreate({
      name,
    });
  };
  useEffect(() => {
    if (prevIsCreateLoading && !isCreateLoading) {
      setKeyword('');
    }
  }, [prevIsCreateLoading, isCreateLoading]);
  const { isManager: isNm } = activeNetwork;

  return (
    <FlexSidebar mobileTriggerText="Select a list" mobileHeaderText="Select a list">
      <Box py={2} width={1} sx={{ borderBottom: 1, borderBottomColor: 'gray.3' }}>
        <Button
          display="flex"
          sx={{ alignItems: 'center' }}
          variant={hasDailyAlerts ? 'sideBarNav' : 'primary'}
          as={NavLink}
          size="small"
          to={
            hasDailyAlerts
              ? `/networks/${activeNetwork.slug}/members/alerts`
              : `/networks/${activeNetwork.slug}/members/alerts?talentAlerts=true`
          }
          exact
        >
          <Box mr={1} height="16px" strokeWidth="1.5" aria-hidden="true" as={Bell} />
          {hasDailyAlerts ? 'Alerts' : 'Get alerts for new talent'}
        </Button>
      </Box>
      <Box py={2} width={1} sx={{ borderBottom: 1, borderBottomColor: 'gray.3' }}>
        <Flex width={1} data-testid="system-boards" alignItems={['center']}>
          <Button
            mr={2}
            pl={0}
            sx={{
              textAlign: 'left',
              flexGrow: [1],
            }}
            variant="sideBarNav"
            fontSize={2}
            as={NavLink}
            to={`/networks/${activeNetwork.slug}/members`}
            isActive={(match, location) => {
              if (!match) {
                return false;
              }
              return !location.search;
            }}
            exact
          >
            All members
          </Button>
          <Button
            as={NavLink}
            data-testid="invite-member"
            variant="accent"
            p={0}
            to={memberInviteUrl}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              height: '24px',
              width: '24px',
              border: 'none',
              borderRadius: 'circle',
            }}
          >
            <Flex as={Plus} height="1em" width="1em" aria-hidden="true" />
          </Button>
        </Flex>
      </Box>
      <Box pb={2} display="flex" width={1} data-testid="company-boards" pt={3} flexDirection={['row']}>
        <Text flexGrow={1} as="h4" variant="lightHeading" fontSize={1}>
          Lists
        </Text>
        {onCreate && isNm && (
          <Button
            onClick={onCreate}
            data-testid="create-board"
            fontSize="16px"
            variant="accent"
            p={0}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              height: '24px',
              width: '24px',
              border: 'none',
              borderRadius: 'circle',
            }}
          >
            <Flex as={Plus} height="1em" width="1em" aria-hidden="true" />
          </Button>
        )}
      </Box>
      {networkTalentGroups.length > 0 && (
        <Flex
          as={SearchableBoardsList}
          keyword={keyword}
          onSearchChange={(e) => setKeyword(e.target.value)}
          networkTalentGroups={networkTalentGroups}
          onSearchSubmit={onSearchSubmit}
          itemTemplate={(item) => {
            const search = {
              filters: {
                talentGroups: [{ value: item.value }],
              },
            };
            return (
              <Button
                id={`custom_board_${item.id}`}
                as={NavLink}
                width={[1]}
                variant="sideBarNav"
                fontSize={2}
                data-value={item.value}
                sx={{ textAlign: 'left' }}
                to={`/networks/${activeNetwork.slug}/members${serializeParams(search)}`}
                isActive={(match, location) => {
                  if (!match) {
                    return false;
                  }
                  const path = `/networks/${activeNetwork.slug}/members${serializeParams(search)}`;
                  const currentPath = `${location.pathname}${location.search}`;
                  return currentPath === path;
                }}
              >
                {item.label}
              </Button>
            );
          }}
          creating={isCreateLoading}
          onBoardSelectionChanged={() => {}}
        />
      )}
      {!isTalentGroupsLoading && networkTalentGroups.length === 0 && (
        <Card p={3}>
          <Image src={ListIcon} pb={[3]} />
          <Text as="h4" variant="default" color="text.dark" mb={2} fontSize={1}>
            Organize and share members with lists
          </Text>
          <Text variant="default" mb={2} fontSize={1}>
            Keep talent in your network organized and collaborate with companies in your network.
          </Text>
          <Button
            fontSize={1}
            onClick={onCreate}
            mt={2}
            width={[1]}
            variant="secondary"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box height="1.23em" width="1.23em" strokeWidth="3" aria-hidden="true" as={Plus} mr={2} />
            Create a list
          </Button>
        </Card>
      )}
      {isBoardSettingsVisible && (
        <BoardSettingsModal
          error={boardSettingsModalError}
          onCancel={onCancelBoardSettingsModal}
          isSubmitting={isCreateLoading}
          isNewBoard={isCreatingNewBoard}
          onSubmit={onSubmitBoardSettingsModal}
          skills={skills}
          preferredLocations={preferredLocations}
          vouchedBy={vouchedBy}
          onDeleteBoard={onDeleteBoard}
          initValues={currentBoard}
        />
      )}
      {isBoardDeleteConfirmationVisible && (
        <BoardDeleteConfirmationModal
          board={currentBoard}
          onCancel={onDeleteBoardCancel}
          isSubmitting={isDeletingBoard}
          onSubmit={onSubmitDeleteBoard}
        />
      )}
    </FlexSidebar>
  );
};

MembersSideBar.propTypes = {
  isTalentGroupsLoading: PropTypes.bool,
  isCreateLoading: PropTypes.bool,
  activeNetwork: PropTypes.object.isRequired,
  networkTalentGroups: PropTypes.array,
  onCreate: PropTypes.func,
  onQuickCreate: PropTypes.func.isRequired,
  memberInviteUrl: PropTypes.string.isRequired,
  hasDailyAlerts: PropTypes.bool,
  isBoardSettingsVisible: PropTypes.bool,
  boardSettingsModalError: PropTypes.string,
  onCancelBoardSettingsModal: PropTypes.func.isRequired,
  onSubmitBoardSettingsModal: PropTypes.func.isRequired,
  isCreatingNewBoard: PropTypes.bool,
  skills: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
  preferredLocations: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
  vouchedBy: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func.isRequired,
  }).isRequired,
  onDeleteBoard: PropTypes.func.isRequired,
  currentBoard: PropTypes.object.isRequired,
  isBoardDeleteConfirmationVisible: PropTypes.bool,
  onDeleteBoardCancel: PropTypes.func.isRequired,
  isDeletingBoard: PropTypes.bool,
  onSubmitDeleteBoard: PropTypes.func.isRequired,
};

MembersSideBar.defaultProps = {
  boardSettingsModalError: null,
  hasDailyAlerts: false,
  isTalentGroupsLoading: false,
  onCreate: undefined,
  isCreateLoading: false,
  networkTalentGroups: [],
  isBoardSettingsVisible: false,
  isCreatingNewBoard: false,
  isBoardDeleteConfirmationVisible: false,
  isDeletingBoard: false,
};
