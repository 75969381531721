import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  loadedSetDailyAlerts,
  loadedSubscriptions,
  loadSubscriptions,
  setShowDeactivateConfirmation,
  toggleBoardSubscription,
  toggleBoardSubscriptionComplete,
  loadedUnsubscribeAll,
  loadedSubscribeAll,
} from './actions';

export const initialState = {
  showDeactivateConfirmation: false,
  boards: {},
};

/* eslint-disable default-case, no-param-reassign */
const manageSubscriptionsReducer = handleActions(
  {
    [loadSubscriptions]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [loadedSubscriptions]: produce((draft, action) => {
      const { talentGroups, subscribed } = action.payload;
      draft.boards = { total: talentGroups.length, subscribed, options: talentGroups };
      return draft;
    }),
    [loadedSetDailyAlerts]: produce((draft, action) => {
      const { hasDailyAlerts } = action.payload;

      draft.boards.subscribed = hasDailyAlerts ? draft.boards.length : 0;
      draft.boards.options = draft.boards.options.map((b) => {
        b.subscribed = hasDailyAlerts;
        return b;
      });

      return draft;
    }),
    [toggleBoardSubscription]: produce((draft, action) => {
      const { board } = action.payload;
      draft.boards.options = draft.boards.options.map((item) => {
        if (item.id === board.id) {
          item.isSubmitting = true;
        }
        return item;
      });
      return draft;
    }),
    [toggleBoardSubscriptionComplete]: produce((draft, action) => {
      const { board, subscribe } = action.payload;
      draft.boards.options = draft.boards.options.map((item) => {
        if (item.id === board.id) {
          item.subscribed = subscribe;
          item.isSubmitting = false;
        }
        return item;
      });
      return draft;
    }),
    [setShowDeactivateConfirmation]: produce((draft, action) => {
      draft.showDeactivateConfirmation = action.payload;
    }),
    [loadedSubscribeAll]: produce((draft) => {
      draft.boards.subscribed = draft.boards.total;
      draft.boards.options = draft.boards.options.map((item) => {
        item.subscribed = true;
        return item;
      });
      return draft;
    }),
    [loadedUnsubscribeAll]: produce((draft) => {
      draft.boards.subscribed = 0;
      draft.boards.options = draft.boards.options.map((item) => {
        item.subscribed = false;

        return item;
      });

      return draft;
    }),
  },
  initialState,
);

export default manageSubscriptionsReducer;
