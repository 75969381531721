import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass/styled-components';

import OptionsFilter from 'components/atoms/OptionsFilter';
import GooglePlacesAPI from '../../../api/GooglePlacesAPI';
import MultiSelect from '../../atoms/MultiSelect';

export const LocationFilter = ({ name, nameRadius, value, title, setFilters, locationRadius, showRadius }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const onSearchChange = (currentValue) => {
    setIsLoading(true);

    GooglePlacesAPI.searchGoogle(currentValue)
      .then(
        (results) =>
          results.map(({ label, value: apiValue, type }) => ({
            label,
            value: apiValue,
            type,
          })),
        // eslint-disable-next-line function-paren-newline
      )
      .then((opts) => {
        setOptions(opts);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Flex width="100%" flexDirection="column" sx={{ gap: '8px' }}>
      <MultiSelect
        title={title}
        options={options}
        selected={value}
        loading={isLoading}
        onSearch={onSearchChange}
        onSelect={(item) => setFilters({ [name]: item })}
        debounceTime={300}
        footer={
          showRadius && value.length > 0 && value.some((i) => i.type === 'locality') ? (
            <OptionsFilter
              placeholder="Current and past"
              selected={locationRadius}
              onSelect={(item) => setFilters({ [nameRadius]: item })}
              bottomText="Only applies to cities"
              options={[
                {
                  label: 'Within 10 miles (16km)',
                  value: 10,
                },
                {
                  label: 'Within 25 miles (40km)',
                  value: 20,
                },
                {
                  label: 'Within 50 miles (80km)',
                  value: 50,
                },
                {
                  label: 'Within 100 miles (160km)',
                  value: 100,
                },
                {
                  label: 'Within 200 miles (320km)',
                  value: 200,
                },
              ]}
            />
          ) : null
        }
      />
    </Flex>
  );
};

LocationFilter.propTypes = {
  name: PropTypes.string,
  nameRadius: PropTypes.string,
  value: PropTypes.array,
  title: PropTypes.string,
  setFilters: PropTypes.func.isRequired,
  locationRadius: PropTypes.object,
  showRadius: PropTypes.bool,
};

LocationFilter.defaultProps = {
  name: 'locationIds',
  nameRadius: 'locationRadius',
  value: [],
  title: 'Location',
  locationRadius: {},
  showRadius: false,
};
