import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../queries';

const networkBaseQueryV2 = ({ url, method = 'get', data, params, options = {} }) =>
  baseQuery({ url, method, data, params, options, version: 'v2' });

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: networkBaseQueryV2,
  endpoints: (builder) => ({
    getIndustry: builder.query({
      query: (params) => ({
        url: '/industry_tags',
        params,
      }),
    }),
    getChargeBessSession: builder.mutation({
      query: (networkId) => ({
        url: `/collections/${networkId}/chargebee_sessions`,
        method: 'post',
      }),
      transformResponse: ({ portalSession }) => portalSession,
    }),
    getSubscriptions: builder.query({
      query: (networkId) => ({
        url: `/collections/${networkId}/subscriptions`,
      }),
    }),
    getCustomFilters: builder.query({
      query: (id) => ({
        url: `/collections/${id}/custom_filters`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getNetwork: builder.query({
      query: (id) => ({
        url: `/collections/${id}`,
      }),
      transformResponse: ({ data: { attributes } }) => attributes,
    }),
    updateFilterVisibility: builder.mutation({
      query: ({ data, networkId }) => ({
        url: `/collections/${networkId}/filters_visibility`,
        method: 'put',
        data: {
          ...data.jobBoardFilters,
          customFilters: data.customFilters,
        },
      }),
      onQueryStarted: async ({ data, networkId }, { dispatch, queryFulfilled }) => {
        const networkPatchResult = dispatch(
          organizationApi.util.updateQueryData('getNetwork', networkId, (draft) => {
            Object.assign(draft, {
              ...draft,
              jobBoardFilters: data.jobBoardFilters,
            });
          }),
        );
        const filtersPatchResult = dispatch(
          organizationApi.util.updateQueryData('getCustomFilters', networkId, (draft) => {
            const mapData = new Map(data.customFilters.map((item) => [item.id.toString(), item]));

            Object.assign(
              draft,
              draft.map((item) => {
                const visibility = mapData.get(item.id)?.visibility || item.attributes.visibility;
                return {
                  ...item,
                  attributes: {
                    ...item.attributes,
                    visibility,
                  },
                };
              }),
            );
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          filtersPatchResult.undo();
          networkPatchResult.undo();
        }
      },
    }),
    downloadJobApplication: builder.mutation({
      query: (networkId) => ({
        url: `/collections/${networkId}/job_applications/export_all_csv`,
        method: 'post',
      }),
    }),
    downloadLikelyApplied: builder.query({
      query: ({ networkId, startDate, endDate }) => ({
        url: `/collections/${networkId}/analytics/jobs_likely_applied_csv`,
        params: {
          startDate,
          endDate,
        },
      }),
    }),
  }),
});

export const {
  useGetIndustryQuery,
  useLazyGetIndustryQuery,
  useGetChargeBessSessionMutation,
  useGetSubscriptionsQuery,
  useDownloadJobApplicationMutation,
  useLazyDownloadLikelyAppliedQuery,
  useGetCustomFiltersQuery,
  useGetNetworkQuery,
  useUpdateFilterVisibilityMutation,
} = organizationApi;
