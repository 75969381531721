import { createSlice } from '@reduxjs/toolkit';

import { getItem, storeItem } from '../helpers/store';

const STORAGE_ID = 'main-menu-state';

const initialize = () => {
  const width = window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

  if (width < 990) {
    return false;
  }

  const stored = getItem(STORAGE_ID);

  if (stored === null) {
    return true;
  }

  return stored === 'true';
};

const initialState = {
  isOpen: initialize(),
};

export const mainMenuSlice = createSlice({
  name: 'mainMenu',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      const newState = !state.isOpen;
      // eslint-disable-next-line no-param-reassign
      state.isOpen = newState;
      storeItem(STORAGE_ID, newState);
    },
  },
});

export const { toggleMenu } = mainMenuSlice.actions;

export default mainMenuSlice.reducer;
