import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { FormLabel, Radio } from '@getro/rombo';
import { Box, Text } from 'rebass/styled-components';

const PERMISSIONS = [
  {
    key: 1,
    title: 'Non-verified',
    desc: 'Can not request introductions within the network.',
  },
  {
    key: 2,
    title: 'Verified',
    desc: 'Can request introductions within the network',
  },
];

function PermissionsSelector({ selected, showLabel, onChange, formik }) {
  const error = formik.touched.permission && formik.errors.permission;

  return (
    <>
      {showLabel && (
        <div className="field">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <FormLabel>Permissions</FormLabel>
        </div>
      )}

      {PERMISSIONS.map(({ key, title, desc }) => (
        <Box mb="2">
          <Radio
            key={key}
            className="PermissionsSelector__control-row"
            name="permission"
            value={key}
            checked={selected === key}
            onChange={onChange}
            label={
              <Text>
                <Text as="span" fontWeight="semibold" mr="1">
                  {title}:
                </Text>
                {desc}
              </Text>
            }
          />
        </Box>
      ))}

      {error && <div className="FormField__message FormField__message--error">{formik.errors.permission}</div>}
    </>
  );
}

PermissionsSelector.propTypes = {
  showLabel: PropTypes.bool,
  onChange: PropTypes.func,
  formik: PropTypes.object.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PermissionsSelector.defaultProps = {
  showLabel: false,
  onChange: () => {},
  selected: null,
};

export default connect(PermissionsSelector);
