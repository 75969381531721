import { toast } from 'react-hot-toast';

import { trackEvent } from '../../../helpers/analytics';
import { contactDataSelection } from '../../../services/contacts/selectedRows';

export const addContactsToSharedList = async ({
  list,
  queryParams,
  activeNetwork,
  contactIds,
  meta,
  selectedRows,
  addContactsToSharedList: addContacts,
  notificationToast,
  listId,
}) => {
  try {
    const { selectedDataRows, excludedDataRows } = contactDataSelection(selectedRows, contactIds, meta.queryTotal);

    const { data, error } = await addContacts({
      queryParams,
      list,
      contactIds: selectedDataRows,
      excludedContactIds: excludedDataRows,
      listId,
    });

    if (!error) {
      trackEvent('contacts:list:add_contact_bulk', {
        collection_id: activeNetwork.id,
        page: listId ? 'list:add' : 'all_contacts',
        listId,
      });

      notificationToast({ transactionId: data?.bulkOperationId, type: 'adding-contacts-to-list', list });
    }

    if (error) {
      toast('Failed to add contacts to the list. Please try again.', { icon: 'danger', id: 'bulk-actions' });
    }
  } catch {
    toast('Failed to add contacts to the list. Please try again.', { icon: 'danger', id: 'bulk-actions' });
  }
};

export const removeContactsFromSharedLists = async ({
  list,
  queryParams,
  activeNetwork,
  contactIds,
  meta,
  selectedRows,
  removeContactsFromSharedList: removeContacts,
  notificationToast,
  listId,
}) => {
  try {
    const { selectedDataRows, excludedDataRows } = contactDataSelection(selectedRows, contactIds, meta.queryTotal);

    const { data, error } = await removeContacts({
      queryParams,
      list,
      contactIds: selectedDataRows,
      excludedContactIds: excludedDataRows,
      listId: list.id,
    });

    if (!error) {
      trackEvent('contacts:list:remove_contact_bulk', {
        collection_id: activeNetwork.id,
        list_id: parseInt(list.id, 10),
        page: listId ? 'list:add' : 'all_contacts',
      });

      notificationToast({ transactionId: data?.bulkOperationId, type: 'removing-contacts-from-list', list });
    }

    if (error) {
      toast('Failed to remove contacts from the list. Please try again.', { icon: 'danger', id: 'bulk-actions' });
    }
  } catch {
    toast('Failed to remove contacts from the list. Please try again.', { icon: 'danger', id: 'bulk-actions' });
  }
};
