import React, { useEffect, useRef, useState } from 'react';
import { ExternalLink } from 'lucide-react';
import PropTypes from 'prop-types';
import { Box as RomboBox, BoxRow, BoxSection, FormField, Loader, Message, MessageBlock } from '@getro/rombo';
import styled from 'styled-components';
import strings from 'strings';
import { Field, Form, Formik } from 'formik';
import { Box, Link } from 'rebass/styled-components';
import * as Yup from 'yup';
import NetworkSchema from '../../../../schemas/network';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledMessage = styled(Message)`
  margin: var(--space-md) 0;
`;

const OpeningParagraph = styled.p`
  margin-bottom: var(--space-xxl);
`;

const ClosingParagraph = styled.p`
  margin-top: var(--space-xxl);

  svg {
    margin-left: var(--space-sm);
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
`;

const formSchema = Yup.object().shape({
  legalName: Yup.string().label('Legal business name').nullable().required(),
  legalEmail: Yup.string().email().label('Data controller email').nullable().required(),
  legalAddress: Yup.string().label('Business address').nullable().required(),
});

const PrivacyPolicy = ({ activeNetwork, generalSettings, fetchError, onSubmit, saveError, submitting }) => {
  const [editing, setEditingGdprSettings] = useState(false);
  const formRef = useRef(null);
  const errorMessage = saveError ? strings.genericError : null;
  const { url } = activeNetwork;

  useEffect(() => {
    const { current: form } = formRef;
    if (form !== null) {
      form.setSubmitting(editing && submitting);
    }
  }, [editing, submitting]);

  const handleCancel = () => {
    const { current: form } = formRef;
    if (form) form.resetForm();
    setEditingGdprSettings(false);
  };

  if (fetchError) {
    return (
      <RomboBox title="Privacy policy" data-testid="gdpr-settings-loading-error">
        <MessageBlock>{strings.genericError}</MessageBlock>
      </RomboBox>
    );
  }

  if (generalSettings === null) {
    return (
      <RomboBox title="Privacy policy" data-testid="gdpr-settings-loading">
        <Loader />
      </RomboBox>
    );
  }

  const { legalName, legalEmail, legalAddress } = generalSettings;
  const initialValues = {
    legalName,
    legalEmail,
    legalAddress,
  };
  const emptyState = !legalName && !legalEmail && !legalAddress;

  const LABELS = {
    legalName: 'Legal business name',
    legalAddress: 'Business address',
    legalEmail: 'Data controller email',
  };

  const labelFor = (id) => {
    if (editing) {
      return `${LABELS[id]} *`;
    }

    return LABELS[id];
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values) => {
        onSubmit(values).then(() => {
          setEditingGdprSettings(false);
        });
      }}
      enableReinitialize
    >
      {({ values, isSubmitting }) => (
        <Form data-testid="network-settings-privacy-policy">
          <RomboBox
            title="Privacy policy"
            onEditClick={() => setEditingGdprSettings(true)}
            editLinkText="Edit"
            editing={editing}
            errorText={errorMessage}
            onCancelClick={handleCancel}
            loading={isSubmitting}
            saveAsSubmit
          >
            <OpeningParagraph>
              GDPR requires you to have a data controller. Your GDPR data controller information will be included in the
              privacy policy of your current network.{' '}
              <StyledLink
                href="https://help.getro.com/support/solutions/articles/65000174032-gdpr-and-getro"
                target="_blank"
                rel="noopener noreferrer"
                color="purple.300"
              >
                Learn more
              </StyledLink>
              .
            </OpeningParagraph>
            <BoxSection title="DATA CONTROLLER INFORMATION">
              {emptyState && (
                <StyledMessage type="warning" data-testid="empty-state-message">
                  Fill in your data controller information below.
                </StyledMessage>
              )}
              <BoxRow title={labelFor('legalName')}>
                {editing && <Field type="text" name="legalName" component={FormField} />}
                {!editing && (values.legalName || '-')}
              </BoxRow>

              <BoxRow title={labelFor('legalAddress')}>
                {editing && <Field type="text" name="legalAddress" component={FormField} />}
                {!editing && (values.legalAddress || '-')}
              </BoxRow>

              <BoxRow title={labelFor('legalEmail')}>
                {editing && <Field type="text" name="legalEmail" component={FormField} />}
                {!editing && (values.legalEmail || '-')}
              </BoxRow>
            </BoxSection>
            {!editing && (
              <ClosingParagraph>
                <StyledLink
                  href={`https://${url}/trust/privacy`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="purple.300"
                >
                  View your network&apos;s privacy policy
                  <Box as={ExternalLink} strokeWidth="1.5" aria-hidden="true" />
                </StyledLink>
              </ClosingParagraph>
            )}
          </RomboBox>
        </Form>
      )}
    </Formik>
  );
};

PrivacyPolicy.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
  fetchError: PropTypes.string,
  generalSettings: PropTypes.shape({
    legalName: PropTypes.string,
    legalEmail: PropTypes.string,
    legalAddress: PropTypes.string,
  }),
  saveError: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

PrivacyPolicy.defaultProps = {
  generalSettings: null,
  fetchError: null,
};

export default PrivacyPolicy;
