import AddCompany from 'pages/AddCompany';

const addCompanyRoutes = {
  path: '/companies/add',
  component: AddCompany,
  name: 'Add company',
  exact: true,
};

export default addCompanyRoutes;
