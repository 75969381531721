import { Button } from '@getro/rombo';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Flex, Image, Text } from 'rebass/styled-components';

import ContactsEmptyStateSvg from '../../assets/empty-state-contacts.svg';
import { activeNetworkSelector } from '../../redux/selectors';
import { talentNetworkSelector } from '../Settings/selectors';

export const ContactsEmptyState = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const talentNetwork = useSelector(talentNetworkSelector);

  return (
    <Flex mt="16px" flexDirection="column" alignItems="center" maxWidth="536px" mx="auto" textAlign="center">
      <Image src={ContactsEmptyStateSvg} width="100%" maxWidth="400px" />
      {talentNetwork?.data?.tnHidden ? (
        <>
          <Text fontWeight="semibold" fontSize="5" color="text.dark">
            Leverage your your job board traffic to grow your network
          </Text>
          <Text mt="16px" fontSize="3">
            Activate the talent network tab on your job board and let job seekers express their interest in working at
            one of your companies.
          </Text>
          <Button
            as={NavLink}
            variant="accent"
            mt="40px"
            to={`/networks/${activeNetwork.slug}/settings/contacts`}
            exact
          >
            Activate talent network signup
          </Button>
          <Text mt="16px" fontSize="1" color="text.subtle">
            You can always update this in the Contacts settings.
          </Text>
        </>
      ) : (
        <>
          <Text fontWeight="semibold" fontSize="5" color="text.dark">
            {`There's no one here yet...`}
          </Text>
          <Text mt="16px" fontSize="3">
            People who sign up to your talent network on your job board will appear here.
          </Text>
        </>
      )}
    </Flex>
  );
};
