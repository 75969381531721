import React, { memo } from 'react';
import { Flex, Link, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const CompanyInfo = memo(({ position }) => {
  const { companySize } = position;

  const generateCompanySizeText = () => {
    switch (companySize) {
      case 1:
        return '1-10 employees';
      case 2:
        return '11-50 employees';
      case 3:
        return '51-200 employees';
      case 4:
        return '201-1,000 employees';
      case 5:
        return '1,001-5,000 employees';
      case 6:
        return '5,001+ employees';
      default:
        return null;
    }
  };

  const companySizeText = generateCompanySizeText();

  const generateSubtitle = () => {
    const result = [];

    if (companySizeText && position.active) {
      result.push(companySizeText);
    }

    if (position.industryTags?.length > 0) {
      position.industryTags.forEach((item) => {
        result.push(item.description);
      });
    }

    return [].concat(...result.map((item) => ['·', item])).slice(1);
  };

  const subtitle = generateSubtitle();

  return (
    <Flex alignItems="center" flexWrap="wrap" sx={{ gap: '4px', lineHeight: '16px' }}>
      {position.companyName && (
        <>
          {position.companyLinkedinUrl ? (
            <Link
              pr="4px"
              fontSize="14px"
              fontWeight="medium"
              color="text.main"
              flex="0 0 auto"
              href={position.companyLinkedinUrl}
              sx={{ textDecoration: 'none', ':hover': { textDecoration: 'underline' } }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {position.companyName}
            </Link>
          ) : (
            <Text pr="4px" fontSize="14px" fontWeight="medium" color="text.main" flex="0 0 auto">
              {position.companyName}
            </Text>
          )}
        </>
      )}
      {subtitle.map((item) => (
        <Text fontSize="14px" color="text.subtle">
          {item}
        </Text>
      ))}
    </Flex>
  );
});

CompanyInfo.propTypes = {
  position: PropTypes.object,
};

CompanyInfo.defaultProps = {
  position: {},
};
