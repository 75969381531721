import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, BoxRow } from '@getro/rombo';
import Table from 'components/molecules/Table';
import PermissionsSelector from './PermissionsSelector';
import { findByValue } from './permissions';

const ConfirmImport = ({ activeNetworkName, navigateBack, navigateForward, records, setRecords }) => {
  const [data, setData] = useState([]);
  const hasError = records.filter((r) => !r.permissions).length > 0;
  const errorText = hasError ? 'Select the permissions for all the rows that are being imported' : null;

  useEffect(() => {
    const onPermissionsChange = (index, value) => {
      const newRecords = [...records];
      newRecords[index].permissions = value;

      setRecords(newRecords);
    };

    const result = records.map((record, i) => [
      { key: 'firstName', content: record.firstName || '' },
      { key: 'lastName', content: record.lastName || '' },
      { key: 'email', content: record.email || '' },
      {
        key: 'permissions',
        content: (
          <PermissionsSelector
            name={`permission_row_${i}`}
            value={findByValue(record.permissions)}
            onChange={(value) => onPermissionsChange(i, value)}
          />
        ),
      },
    ]);

    setData(result);
  }, [records, setRecords]);

  const columns = [
    { key: 'firstName', content: 'First name' },
    { key: 'lastName', content: 'Last name' },
    { key: 'email', content: 'Work email' },
    { key: 'permissions', content: 'Permissions' },
  ];

  const onSaveClick = () => {
    if (hasError) return;

    navigateForward();
  };

  const onApplyPermissionToAll = (permissions) => {
    const newRecords = [...records];
    newRecords.forEach((r) => {
      // eslint-disable-next-line no-param-reassign
      r.permissions = permissions;
    });
    setRecords(newRecords);
  };

  return (
    <Box
      size="md"
      title="Fill missing user information"
      onCancelClick={navigateBack}
      cancelText="Back"
      onSaveClick={onSaveClick}
      saveText="Continue"
      errorText={errorText}
      editing
      data-testid="bulk-members-invite-confirm-import"
    >
      <BoxRow>
        {`Users with more information on their profiles are more likely to be browsed by companies in the ${activeNetworkName} network.`}
      </BoxRow>

      <BoxRow>
        <PermissionsSelector
          name="all-permissions"
          onChange={onApplyPermissionToAll}
          placeholder="Apply permissions to all"
        />
      </BoxRow>

      <BoxRow>
        <Table columns={columns} data={data} />
      </BoxRow>
    </Box>
  );
};

ConfirmImport.propTypes = {
  activeNetworkName: PropTypes.string,
  navigateBack: PropTypes.func.isRequired,
  navigateForward: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  setRecords: PropTypes.func.isRequired,
};

ConfirmImport.defaultProps = {
  activeNetworkName: '',
};

export default ConfirmImport;
