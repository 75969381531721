import React, { useEffect } from 'react';
import { Button, FormCheckboxes, FormInput, FormSelect, Message, ProfilePicture } from '@getro/rombo';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Box, Flex, Text } from 'rebass/styled-components';
import * as Yup from 'yup';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'styled-components';
import toast from 'react-hot-toast';

import { useGetContactByIdQuery } from 'services/contactsV2';
import { useEditContactMutation, useGetSharedListsQuery } from 'services/contacts';
import { FocusLayout } from '../../components/layout/focus';
import { trackEvent } from '../../helpers/analytics';
import { getUserDisplayName } from '../../helpers/users';
import { activeNetworkSelector } from '../../redux/selectors';
import { useGetNetworkAdminsQuery } from '../../services/networkAdmins';
import { useGetTagsQuery } from '../../services/tags';

export const ContactsEditSingleContact = () => {
  const { contactId } = useParams();

  const { data: contact = {}, isLoading: isLoadingContact } = useGetContactByIdQuery(contactId);
  const { data: { sharedLists } = {}, isLoading: isLoadingSharedLists } = useGetSharedListsQuery();
  const { data: { tags } = {}, isLoading: isLoadingTags } = useGetTagsQuery();
  const { data: networkAdmins = [], isLoading: isLoadingNetworkAdmins } = useGetNetworkAdminsQuery();
  const [editContact, { isLoading: isLoadingEditContact }] = useEditContactMutation();
  const activeNetwork = useSelector(activeNetworkSelector);
  const { colors } = useTheme();
  const history = useHistory();

  const formSchema = Yup.object().shape({
    email: Yup.string().email(),
  });

  useEffect(() => {
    if (activeNetwork) {
      trackEvent('contacts:add_single_contact:success', {
        collection_id: activeNetwork.id,
      });
    }
  }, [activeNetwork]);

  const onSubmit = async (values) => {
    try {
      trackEvent('contacts:update_contact_info', {
        collection_id: activeNetwork.id,
        origin: 'add_single_contact',
      });

      const contactOf = values.contactOf?.reduce((carry, opt) => {
        const admin = networkAdmins.find((user) => parseInt(user.id, 10) === opt.value);

        if (admin) {
          carry.push({
            id: parseInt(admin.id, 10),
            name: getUserDisplayName(admin),
            ...admin,
          });
        }

        return carry;
      }, []);

      await editContact({ contactId, values: { ...values, contactOf } });

      toast('Additional information updated.', { icon: 'success', id: 'edit_contact_success' });
      window.scrollTo(0, 0);
      history.push(`/networks/${activeNetwork.slug}/contacts`);
    } catch {
      // Do nothing
    }
  };

  if (!contact) return null;
  return (
    <FocusLayout title="Add a single contact" backUrl={`/networks/${activeNetwork.slug}/contacts`}>
      <Box pb="5">
        <Text fontSize={1}>
          <Text as="span" color="text.main" fontWeight="body">
            1. LinkedIn URL
          </Text>
          {' > '}
          <Text as="span" color="purple.300" fontWeight="medium">
            2. Additional information
          </Text>
        </Text>
        {!isLoadingContact && !isLoadingSharedLists && !isLoadingTags && !isLoadingNetworkAdmins ? (
          <Box mt="4">
            <Message type="success">
              <Box>
                <Text color="textDark">{`${contact.fullName} has been added`}</Text>
                <Text mt="1" fontWeight="body">
                  Include additional information to make it easier to intro this contact.
                </Text>
              </Box>
            </Message>

            <Flex mt="48px" alignItems="center">
              <Box width="80px" height="80px" mr="3" flex="0 0 auto">
                <ProfilePicture name={contact.fullName || 'Getro'} imageUrl={contact.profilePicUrl} />
              </Box>
              <Box>
                <Text fontSize="5" fontWeight="semibold" color="textDard">
                  {contact.fullName}
                </Text>
                {contact.primaryActivePosition && (
                  <Text>
                    <Text as="span" fontWeight="semibold">
                      {contact.primaryActivePosition.jobTitle}
                    </Text>
                    {` at ${contact.primaryActivePosition.companyName}`}
                  </Text>
                )}
              </Box>
            </Flex>

            <Box mt="5">
              <Formik
                initialValues={{
                  email: contact.email || '',
                  lists: contact?.sharedList?.map((list) => ({ label: list.name, value: list.id })) || [],
                  tags: contact?.tags?.map((tag) => ({ label: tag.tag, value: tag.id })) || [],
                  contactOf:
                    contact?.importers?.map((user) => ({
                      label: `${user.firstName} ${user.lastName}`,
                      value: user.id,
                    })) || [],
                }}
                validationSchema={formSchema}
                onSubmit={onSubmit}
              >
                <Form>
                  <Field
                    component={FormInput}
                    name="email"
                    label="Email"
                    helpText="To comply with GDPR and CCPA this is for internal reference only and will only be visible to network admins."
                  />
                  <Field
                    component={FormSelect}
                    name="contactOf"
                    label="Contact of"
                    helpText="Select who knows this person and can confidently facilitate an intro."
                    placeholder="Type to search..."
                    options={networkAdmins.map(({ id, firstName, lastName }) => ({
                      label: `${firstName} ${lastName}`,
                      value: parseInt(id, 10),
                    }))}
                    containerProps={{ mt: 4 }}
                    isSearchable
                    multiple
                  />
                  <Field
                    component={FormCheckboxes}
                    name="lists"
                    label="Lists"
                    helpText="Add this contact to relevant lists so companies know they can connect with them."
                    options={sharedLists.map(({ id, name }) => ({ value: id, label: name }))}
                    containerProps={{ mt: 4 }}
                  />
                  <Field
                    component={FormCheckboxes}
                    name="tags"
                    label="Tags"
                    helpText="Add tags so you can stay organized and remember how this contact is related to your network."
                    options={tags.map(({ id, tag }) => ({ value: id, label: tag }))}
                    containerProps={{ mt: 4 }}
                  />
                  <Flex
                    mt="3"
                    pt="3"
                    justifyContent="flex-end"
                    sx={{ borderTop: 1, borderTopColor: 'neutral.100', columnGap: 2 }}
                  >
                    <Button variant="tertiary" onClick={() => history.push(`/networks/${activeNetwork.slug}/contacts`)}>
                      Skip
                    </Button>
                    <Button type="submit" loading={isLoadingEditContact}>
                      Update info
                    </Button>
                  </Flex>
                </Form>
              </Formik>
            </Box>
          </Box>
        ) : (
          <Box mt="4">
            <ContentLoader
              speed={2}
              width="100%"
              height="100%"
              viewBox="0 0 986 985"
              backgroundColor={colors.neutral[50]}
              foregroundColor={colors.neutral[20]}
            >
              <rect x="0" y="0" rx="0" ry="0" width="585" height="34" />
              <rect x="0" y="50" rx="0" ry="0" width="585" height="11" />
              <rect x="0" y="70" rx="0" ry="0" width="585" height="11" />
              <rect x="0" y="90" rx="0" ry="0" width="585" height="11" />
            </ContentLoader>
          </Box>
        )}
      </Box>
    </FocusLayout>
  );
};
