import { createSelector } from 'reselect';
import { activeNetworkSelector } from '../../redux/selectors';

export const isNetworkAdminSelector = createSelector(activeNetworkSelector, (activeNetwork) => {
  const { isManager } = activeNetwork;

  return isManager;
});

export const isOrganizationManagerSelector = createSelector(activeNetworkSelector, (activeNetwork) => {
  const { isOrganizationManager } = activeNetwork;

  return isOrganizationManager;
});

export const makeIsCompanyAdminSelector = (companySlug) =>
  createSelector(activeNetworkSelector, (activeNetwork) => {
    const { isOrganizationManager } = activeNetwork;

    return !!Object.values(isOrganizationManager).find(({ slug }) => slug === companySlug);
  });

export const canManageCompaniesSelector = createSelector(activeNetworkSelector, (activeNetwork) => {
  const { isManager, isOrganizationManager } = activeNetwork;

  return isManager || Object.keys(isOrganizationManager).length >= 2;
});
