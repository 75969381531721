import { useIsMobile } from './useIsMobile';

export const useDisableRootOnMobileScroll = (disable) => {
  useIsMobile((isMobile) => {
    if (isMobile && disable) {
      if (document.getElementById('root')) {
        document.getElementById('root').style.overflow = 'hidden';
      }

      if (document.querySelector('[role=table]')) {
        document.querySelector('[role=table]').style.width = '300px';
      }
    } else {
      if (document.querySelector('[role=table]')) {
        document.querySelector('[role=table]').style.width = null;
      }

      if (document.getElementById('root')) {
        document.getElementById('root').style.overflow = null;
      }
    }
  });
};
