import { DataTable, ProfilePicture } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIsKeyboardPressed } from 'hooks/useKeyboardPressed';
import { Link } from 'react-router-dom';
import { COMPANIES_BACK_URL, COMPANIES_SCROLL_INDEX } from 'helpers/constants';
import { getCompaniesColumns } from './companyColumn';
import { EmptyView } from './emptyView';
import { LoadMore } from './loadMore';
import sourceColorMap from './sourceColorMap';

export const CompaniesDataTable = ({
  companies,
  total,
  loading,
  loadMore,
  hasInitialized,
  hasInitializedError,
  filterCount,
  pathname,
  search,
  showTags,
}) => {
  const [companiesRow, setCompaniesRow] = useState([]);
  const companiesColumns = useMemo(() => getCompaniesColumns({ showTags }), [showTags]);
  const hasMore = total > companies.length;
  const cmdPressed = useIsKeyboardPressed('Meta');
  const ref = useRef();

  const scrollToIndex = ref.current?.scrollToIndex;

  useEffect(() => {
    const currentScrollView = localStorage.getItem(COMPANIES_SCROLL_INDEX);
    if (currentScrollView && companies.length && scrollToIndex) {
      const [x, y] = JSON.parse(currentScrollView);
      window.scrollTo(x, y);
      localStorage.removeItem(COMPANIES_SCROLL_INDEX);
      localStorage.removeItem(COMPANIES_BACK_URL);
    }
  }, [companies, companies.length, scrollToIndex]);

  useEffect(() => {
    setCompaniesRow(
      companies.map((company) => ({
        id: company.id,
        slug: company.slug,
        name: (
          <Flex sx={{ gap: '8px' }} alignItems="flex-start" my={[2]} justifyContent="flex-start">
            <Box width="40px" height="40px" minWidth="40px">
              <ProfilePicture
                width="40px"
                height="40px"
                imageUrl={company.logoUrl}
                name={company.name}
                variant="square"
              />
            </Box>
            <Box>
              <Text
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                fontSize="14px"
                fontHeight="18px"
                color="text.dark"
                fontWeight="500"
              >
                {company.name}
              </Text>
              <Text color="text.subtle" fontWeight="400" fontSize="12px" fontHeight="18px">
                {company.domain}
              </Text>
            </Box>
          </Flex>
        ),
        location: (
          <Text
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            fontSize={[1]}
            color="text.main"
            my={2}
          >
            {company.locations && company.locations.length
              ? company.locations.map(({ name }) => name)?.join(' · ')
              : '-'}
          </Text>
        ),
        visibility: (
          <Flex width="100%" justifyContent="flex-end" fontSize={[1]} my={[2]}>
            {company.jobsCount}
          </Flex>
        ),
        source: (
          <Flex sx={{ gap: '4px' }} alignItems="center" fontSize={[1]} my={[2]}>
            <Box
              width="8px"
              height="8px"
              sx={{ borderRadius: '100%', gap: '4px' }}
              backgroundColor={sourceColorMap[company.jobSourceCustomerFacingStatus]}
            />
            {company.jobSourceCustomerFacingStatus}
          </Flex>
        ),
        tags: (
          <Text sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} fontSize={[1]} my={[2]}>
            {company.topics?.length ? `${company.topics.map(({ name }) => name).join(' · ')}` : ''}
          </Text>
        ),
        admins: (
          <Flex width="100%" justifyContent="flex-end" fontSize={[1]} my={[2]}>
            {company.managers.length}
          </Flex>
        ),
        published: (
          <Flex
            fontSize="14px"
            width={[1]}
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start!important"
          >
            {company.qa ? (
              'Yes'
            ) : (
              <Box>
                <Text fontSize="14px" lineHeight="16px">
                  Not published Getro <br />
                  <Text as="span" color="text.subtle" fontSize="12px" lineHeight="14px">
                    Getro team will add info and publish it.
                  </Text>
                </Text>
              </Box>
            )}
          </Flex>
        ),
      })),
    );
  }, [companies]);

  return (
    <Flex flexDirection="column" flexGrow="1">
      <DataTable
        sx={{
          pb: 3,
          minWidth: '980px',
          height: ['600px', '500px', '100%'],
          width: ['1000px!important', '1000px!important', '100%!important'],
          flexGrow: 1,
        }}
        virtuosoProps={{
          ref,
          useWindowScroll: true,
          components: {
            // eslint-disable-next-line react/no-multi-comp
            Footer: () => <LoadMore hasMore={hasMore} isLoadingMore={hasInitialized && loading} />,
          },
        }}
        loadMore={loadMore}
        items={companiesRow}
        columnDefinition={companiesColumns}
        hasMore={hasMore}
        isInitialized={hasInitialized}
        emptyView={<EmptyView error={hasInitializedError} filterCount={filterCount} />}
        rowProps={({ original }) => ({
          as: Link,
          to: `${pathname}/${original.id}-${original.slug}`,
          onClick: () => {
            localStorage.setItem(COMPANIES_BACK_URL, `${pathname}${search}`);
            localStorage.setItem(COMPANIES_SCROLL_INDEX, JSON.stringify([window.scrollX, window.scrollY]));
          },
          target: cmdPressed ? '_blank' : '_self',
          sx: {
            textDecoration: 'none!important',
            minHeight: '40px',
            transition: 'background-color ease 0.3s',
            py: '8px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'neutral.20',
            },

            '& > div': {
              '& > div': {
                minHeight: '40px',
                py: '0px',

                '& > div': {
                  minHeight: '40px',
                  margin: 0,
                },
              },

              '& > div > div': {
                display: 'flex',
                alignItems: 'center',
              },
            },
          },
        })}
      />
    </Flex>
  );
};

CompaniesDataTable.propTypes = {
  companies: PropTypes.array,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasInitialized: PropTypes.bool.isRequired,
  hasInitializedError: PropTypes.bool.isRequired,
  filterCount: PropTypes.number.isRequired,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  showTags: PropTypes.bool.isRequired,
};

CompaniesDataTable.defaultProps = {
  companies: [],
};

export default CompaniesDataTable;
