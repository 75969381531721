import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormSelect } from '@getro/rombo';
import { Box } from 'rebass/styled-components';
import { useGetIndustryQuery } from 'services/organizations';
import capitalize from 'helpers/capitalize';

const IndustryTags = ({ value, setValue }) => {
  const { data, isLoading, isFetching } = useGetIndustryQuery();
  const initialDataset = useRef(false);

  const options = useMemo(() => {
    if (data?.data) {
      return data.data.map((tag) => ({
        label: capitalize(tag.attributes.description),
        value: parseInt(tag.id, 10),
      }));
    }

    return [];
  }, [data]);

  useEffect(() => {
    if (options.length && !initialDataset.current) {
      setValue(value?.map((item) => options.find((option) => option.label === item.label)));

      initialDataset.current = true;
    }
  }, [options, setValue, value]);

  return (
    <Box>
      <FormLabel htmlFor="Tags" data-testid="Tags">
        Industries
      </FormLabel>
      <FormSelect
        name="IndustryTags"
        placeholder="Add industry..."
        options={options}
        disabled={isLoading || isFetching}
        field={{
          onChange: ({ target: { value: newValue } }) => {
            setValue(newValue);
          },
          value,
        }}
        multiple
        isSearchable
      />
    </Box>
  );
};

IndustryTags.propTypes = {
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default IndustryTags;
