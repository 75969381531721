import React from 'react';
import { Box, Image, Text, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { CheckCircle, Contact, Shuffle } from 'lucide-react';
import ContactsImportSuccessPng from '../../../assets/contacts-import-success.png';

export const ContactsFromLinkedinImportSuccessful = ({ isMatchesEnabled }) => (
  <Box data-testid="linkedin-import-success">
    <Flex width="100%" justifyContent="center">
      <Box maxWidth="600px">
        <Image src={ContactsImportSuccessPng} alt="Get the most out of your team’s network" />
      </Box>
    </Flex>

    <Text as="h1" fontSize="18px" fontWeight="600" color="text.dark" mb="32px" mt="40px">
      We’re working on your import
    </Text>

    <Flex sx={{ '& > div': { gap: '16px' }, flexDirection: 'column', gap: '16px' }}>
      <Flex>
        <Box as={CheckCircle} sx={{ color: 'icon.success' }} size="24px" />
        <Text fontSize="14px" fontWeight="500" color="text.dark" as="p">
          Your LinkedIn connections have been fetched
        </Text>
      </Flex>
      <Flex fontSize="14px" lineHeight="1.4" fontWeight="500" color="text.dark">
        <Box as={Contact} sx={{ color: 'neutral.400' }} size="24px" />
        <Box>
          <Text as="p">We’re completing their professional profiles</Text>
          <Text fontWeight="400" color="text.main">
            Enriched profiles are added as they are available. It can take up to a few hours, we’ll email you when they
            are ready.
          </Text>
        </Box>
      </Flex>
      {isMatchesEnabled && (
        <Flex fontSize="14px" lineHeight="1.4" fontWeight="500" color="text.dark">
          <Box as={Shuffle} sx={{ color: 'neutral.400' }} size="24px" />
          <Box>
            <Text as="p">Matches will be ready soon</Text>
            <Text fontWeight="400" color="text.main">
              It can take up to 1-3 hours, we will email you when they are ready.
            </Text>
          </Box>
        </Flex>
      )}
    </Flex>
  </Box>
);

ContactsFromLinkedinImportSuccessful.propTypes = {
  isMatchesEnabled: PropTypes.bool,
};

ContactsFromLinkedinImportSuccessful.defaultProps = {
  isMatchesEnabled: false,
};
