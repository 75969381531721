/* eslint-disable react/no-multi-comp */
import React from 'react';
import ContentLoader from 'react-content-loader';
import { Box, Text } from 'rebass/styled-components';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { ContactCardSkeleton } from '../../components/organisms/contactCard/skeleton';

export const SidebarSkeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={200}
      height={240}
      viewBox="0 0 200 240"
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
    >
      <rect x="0" y="1" rx="4" ry="4" width="153" height="24" />
      <rect x="0" y="37" rx="3" ry="3" width="200" height="1" />
      <rect x="0" y="50" rx="4" ry="4" width="78" height="16" />
      <rect x="0" y="77" rx="4" ry="4" width="200" height="6" />
      <rect x="0" y="90" rx="4" ry="4" width="178" height="6" />
      <circle cx="189" cy="58" r="9" />
      <rect x="0" y="115" rx="4" ry="4" width="97" height="16" />
      <rect x="0" y="143" rx="4" ry="4" width="116" height="16" />
      <rect x="0" y="177" rx="0" ry="0" width="200" height="1" />
      <rect x="0" y="190" rx="4" ry="4" width="61" height="16" />
      <circle cx="189" cy="197" r="8" />
      <rect x="0" y="224" rx="4" ry="4" width="132" height="16" />
    </ContentLoader>
  );
};

export const MainSkeleton = ({ rows }) => (
  <Box>
    <Text color="text.subtle" fontSize="12px" pb="24px">
      Loading...
    </Text>
    {Array.from(Array(rows).keys()).map((key) => (
      <Box
        key={`skeleton-main-${key}`}
        pb={['16px', '32px']}
        mb={[0, '16px']}
        sx={{ borderBottom: 1, borderBottomColor: 'neutral.50' }}
      >
        <ContactCardSkeleton />
      </Box>
    ))}
  </Box>
);

MainSkeleton.propTypes = {
  rows: PropTypes.number,
};

MainSkeleton.defaultProps = {
  rows: 4,
};
