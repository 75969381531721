import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { createPortal } from 'react-dom';
import { Box, Flex } from 'rebass/styled-components';
import { Button, FormFieldError, Input, Modal } from '@getro/rombo';
import strings from '../../../strings';

const SubscribeModal = ({
  title,
  showModal,
  setShowModal,
  modalActions,
  storedValue,
  updateError,
  isUpdating,
  onSubmit,
}) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  return createPortal(
    <Modal title={title} isOpen={showModal} onCancel={() => setShowModal(false)} actions={modalActions}>
      {!storedValue && (
        <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ isSubmitting, handleSubmit, values, touched, errors, setFieldValue }) => (
            <Form
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            >
              <Box mb="24px" color="text.main" fontSize="16px" fontWeight="400">
                Get daily emails when new people are added to this list.
              </Box>

              <Flex flexDirection={['column', 'row']} width={[1]}>
                <Flex sx={{ flexGrow: 2 }} flexDirection="column">
                  <Field
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder="Enter your email..."
                    sx={{ flexGrow: 2, maxHeight: '40px' }}
                    component={Input}
                    value={values.email}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                  />
                  {touched.email && errors.email && <FormFieldError error={errors.email} />}
                  {updateError && !errors.email && <FormFieldError error={strings.genericError} />}
                </Flex>

                <Button
                  type="submit"
                  variant="primary"
                  ml={[0, 2]}
                  mt={[2, 0]}
                  height="40px"
                  sx={{ display: 'flex', justifyContent: 'center' }}
                  loading={isSubmitting || isUpdating}
                >
                  Subscribe
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
      {storedValue && (
        <Box color="text.main" fontSize="16px" fontWeight="400">
          You will no longer receive email notifications when new contacts are added to this list.
        </Box>
      )}
    </Modal>,
    document.getElementById('portals'),
  );
};

SubscribeModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  modalActions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  storedValue: PropTypes.shape({
    anonymousId: PropTypes.string,
    token: PropTypes.string,
  }),
  updateError: PropTypes.bool,
  isUpdating: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

SubscribeModal.defaultProps = {
  updateError: false,
  modalActions: '',
};

export default SubscribeModal;
