export const prepareSearchEventAttributes = (filters) => {
  const { collectionId, skillIds, keyword, locationIds, referralIds, talentGroups, verifiedMembers, ...rest } = filters;

  let result = {
    collection_id: collectionId,
    ...rest,
  };

  if (keyword !== undefined) {
    result = { ...result, keyword };
  }

  if (skillIds && skillIds.length) {
    result = { ...result, skills: skillIds.map(({ label }) => label) };
  }

  if (locationIds && locationIds.length) {
    result = { ...result, locations: locationIds.map((e) => e.label) };
  }

  if (referralIds && referralIds.length) {
    result = { ...result, referrals_count: referralIds.length };
  }

  if (talentGroups && talentGroups.length) {
    result = { ...result, talent_groups: talentGroups.map((e) => e.label) };
  }

  if (verifiedMembers) {
    result = { ...result, verified_members: verifiedMembers };
  }

  return result;
};

export const prepareFilters = (filters) => {
  let { skillIds, locationIds, referralIds } = filters;
  const { talentGroups, ...f } = filters;

  if (skillIds && skillIds.length) {
    skillIds = skillIds.map((p) => p.value);
  }

  if (locationIds && locationIds.length) {
    locationIds = locationIds.map((p) => p.value);
  }

  if (referralIds && referralIds.length) {
    referralIds = referralIds.map((p) => p.value);
  }

  let talentGroupIds = [];
  if (talentGroups && talentGroups.length) {
    talentGroupIds = talentGroups.map((g) => g.value);
  }

  return {
    ...f,
    skillIds,
    roles: ['none'],
    referralIds,
    locationIds,
    talentGroupIds,
  };
};

export const PAGE_SIZE = 20;

export const isLoadingApi = (member, groupId) => {
  const group = member.talentGroups.find((g) => g.id === groupId);
  if (!group) {
    return false;
  }
  return group.isLoading;
};
