import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const PublishChanges = ({ domain }) => (
  <Flex
    sx={{
      fontSize: '14px',
      lineHeight: '17px',
      color: 'text.main',
      flexDirection: 'column',
      gap: '8px',
    }}
  >
    <Box sx={{ fontWeight: '500', color: 'text.dark' }}>Settings updated</Box>
    <Flex sx={{ flexDirection: 'column', gap: '4px' }}>
      <Box>Changes should be visible at </Box>
      <Box>
        <Box as="a" sx={{ cursor: 'pointer', textDecoration: 'underline' }} href={`https://${domain}`} target="_blank">
          {domain}
        </Box>{' '}
        within 10 minutes.
      </Box>
    </Flex>
  </Flex>
);

PublishChanges.propTypes = {
  domain: PropTypes.string.isRequired,
};
