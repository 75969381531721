import React, { Fragment, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BookOpen,
  ChevronDown,
  ExternalLink,
  HelpCircle,
  Inbox,
  LifeBuoy,
  MessageCircle,
  SidebarClose,
  SidebarOpen,
} from 'lucide-react';
import { Dropdown } from '@getro/rombo';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Card } from '../../atoms/Card';
import { toggleMenu } from '../../../features/mainMenu';
import { activeNetworkSelector } from '../../../redux/selectors';
import useIntroductionRequests from '../../../hooks/useIntroductionRequests';
import { useMainMenu } from '../../../hooks/useMainMenu';
import { openChat } from '../../../helpers/supportChat';
import { NetworkSelector } from '../NetworkSelector';
import UserMenu from '../UserMenu';

export const MainLayoutMenu = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const isMenuOpen = useSelector((state) => state.mainMenu.isOpen);
  const { isManager, features } = activeNetwork;
  const showInbox = useMemo(() => isManager && features && features.includes('talent_network'), [isManager, features]);
  const [introductionRequests] = useIntroductionRequests(activeNetwork);
  const { menuItems } = useMainMenu(activeNetwork);
  const introductionRequestsCount = useMemo(
    () => (introductionRequests ? introductionRequests.length : 0),
    [introductionRequests],
  );
  const dispatch = useDispatch();
  const match = useRouteMatch(`/networks/${activeNetwork.slug}/list`);
  const dropdownRef = useRef(null);

  const support = [
    {
      label: 'Learning center',
      href: 'https://help.getro.com',
      icon: BookOpen,
    },
    {
      label: 'Contact support',
      onClick: () => openChat(null, { name: 'Support' }),
      icon: LifeBuoy,
    },
    {
      label: 'Share feedback',
      onClick: () => openChat(null, { name: 'Feedback' }),
      icon: MessageCircle,
    },
    {
      label: 'Terms of use',
      href: 'https://www.getro.com/terms',
      icon: ExternalLink,
    },
    {
      label: 'Privacy policy',
      href: 'https://www.getro.com/privacy',
      icon: ExternalLink,
    },
  ];

  return (
    <>
      <Box
        display={[isMenuOpen ? 'block' : 'none', isMenuOpen ? 'block' : 'none', 'none']}
        bg="neutral.20"
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 98,
          opacity: 0.8,
        }}
        onClick={() => dispatch(toggleMenu())}
      />
      <Flex
        className="main-menu"
        height="100vh"
        width={isMenuOpen ? '240px' : ['240px', '240px', '40px']}
        flexDirection="column"
        bg="neutral.0"
        pb="8px"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: [99, 99, 1],
          borderRight: 1,
          borderRightColor: 'neutral.100',
          boxShadow: [isMenuOpen ? 'medium' : 'none', 'none', 'none'],
          overflow: ['visible', 'visible', 'hidden'],
          transition: ['transform 0.15s ease-in-out', 'transform 0.15s ease-in-out', 'width 0.15s ease-in-out'],
          transform: [
            isMenuOpen ? 'translate3d(0,0,0)' : 'translate3d(-240px,0,0)',
            isMenuOpen ? 'translate3d(0,0,0)' : 'translate3d(-240px,0,0)',
            'none',
          ],
          ...(!isMenuOpen
            ? {
                '&&:hover': {
                  '&& .main-menu-open-icon': {
                    opacity: 1,
                  },
                },
              }
            : undefined),
        }}
      >
        <Flex
          px="8px"
          py="12px"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            gap: '4px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <NetworkSelector />
          <Flex
            className="main-menu-open-icon"
            alignItems="center"
            justifyContent="center"
            width="40px"
            height="48px"
            bg="neutral.0"
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: 0,
              transition: 'opacity ease 0.15s',
              pointerEvents: isMenuOpen ? 'none' : 'auto',
            }}
            onClick={() => dispatch(toggleMenu())}
          >
            <Box as={SidebarOpen} width="16px" height="16px" aria-hidden="true" flex="0 0 auto" />
          </Flex>
          <Flex
            className="main-menu-close-icon"
            alignItems="center"
            justifyContent="center"
            width="24px"
            height="24px"
            sx={{ cursor: 'pointer' }}
            onClick={() => dispatch(toggleMenu())}
          >
            <Box
              as={SidebarClose}
              className="main-menu-item"
              width="16px"
              height="16px"
              aria-hidden="true"
              flex="0 0 auto"
              sx={{
                opacity: isMenuOpen ? 1 : 0,
              }}
            />
          </Flex>
        </Flex>

        <Flex mt="16px" flexDirection="column" justifyContent="space-between" flexGrow="1">
          <Box>
            {!!menuItems.length && (
              <Box as="nav" mx="4px">
                <Box as="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
                  {menuItems.map(({ icon, label, path, href }) => (
                    <Box
                      key={`${path}-${href}`}
                      as="li"
                      sx={{
                        '> a': {
                          '&.active': {
                            '> div': {
                              bg: 'purple.100',
                              color: 'text.dark',
                            },
                          },
                        },
                      }}
                    >
                      <NavLink
                        to={path}
                        className={({ isActive }) => (isActive || (label === 'Lists' && !!match) ? 'active' : '')}
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                          const width =
                            window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

                          if (width < 990) {
                            dispatch(toggleMenu());
                          }
                        }}
                      >
                        <Flex
                          tx="mainMenu"
                          variant="item"
                          alignItems="center"
                          sx={{ gap: '8px', borderRadius: 'default' }}
                        >
                          <Box as={icon} width="16px" height="16px" aria-hidden="true" flex="0 0 auto" />
                          <Text
                            data-testid={`main-menu-${label.toLowerCase()}`}
                            className="main-menu-item"
                            sx={{
                              opacity: isMenuOpen ? 1 : 0,
                            }}
                          >
                            {label}
                          </Text>
                        </Flex>
                      </NavLink>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          <Box>
            {showInbox && (
              <Flex
                as={NavLink}
                to={`/networks/${activeNetwork.slug}/inbox`}
                exact
                sx={{
                  alignItems: 'center',
                  position: 'relative',
                  width: '100%',
                  height: '36px',
                  flex: '0 0 auto',
                  py: '8px',
                  pl: '12px',
                  textDecoration: 'none',
                  gap: '12px',
                }}
              >
                <Box as={Inbox} width="16px" height="16px" aria-hidden="true" />
                <Text
                  textAlign="left"
                  fontSize="14px"
                  fontWeight="medium"
                  flexGrow="1"
                  className="main-menu-item"
                  sx={{
                    whiteSpace: 'nowrap',
                    opacity: isMenuOpen ? 1 : 0,
                  }}
                >
                  Inbox
                </Text>
                {introductionRequestsCount > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '10px',
                      left: '22px',
                      width: '8px',
                      height: '8px',
                      borderRadius: 'circle',
                      bg: 'primary',
                    }}
                  />
                )}
              </Flex>
            )}

            <Dropdown
              ref={dropdownRef}
              className="help-and-support"
              placement="bottom-end"
              triggerProps={{
                variant: 'styleless',
                width: '100%',
                height: '36px',
                flex: '0 0 auto',
                p: '8px',
                children: (
                  <Flex alignItems="center" pl="4px" width="100%" sx={{ gap: '12px' }}>
                    <Box as={HelpCircle} height="16px" width="16px" aria-hidden="true" flex="0 0 auto" />
                    <Text
                      textAlign="left"
                      fontSize="14px"
                      fontWeight="medium"
                      flexGrow="1"
                      className="main-menu-item"
                      sx={{
                        whiteSpace: 'nowrap',
                        opacity: isMenuOpen ? 1 : 0,
                      }}
                    >
                      Help & support
                    </Text>
                    <Flex alignItems="center" color="text.subtle">
                      <Box as={ChevronDown} width="16px" height="16px" aria-hidden="true" />
                    </Flex>
                  </Flex>
                ),
              }}
            >
              <Card width="200px">
                {support.map(({ label, icon, onClick, ...props }, index) => (
                  <Fragment key={label}>
                    {index === 3 && <Box my="8px" bg="border.subtle" width="100%" height="1px" />}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      color="text.main"
                      sx={{
                        color: 'text.main',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        fontSize: '14px',
                        lineHeight: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        py: '4px',
                        ':hover': {
                          color: 'text.dark',
                        },
                      }}
                      onClick={() => {
                        if (onClick) onClick();
                        dropdownRef.current.closeDropdown();

                        const width =
                          window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

                        if (width < 990) {
                          dispatch(toggleMenu());
                        }
                      }}
                      {...props}
                    >
                      <Box as={icon} size="16px" />
                      <Box as="span"> {label}</Box>
                    </Link>
                  </Fragment>
                ))}
              </Card>
            </Dropdown>

            <UserMenu isMenuOpen={isMenuOpen} />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
