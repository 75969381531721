import React from 'react';
import { Box, Button } from '@getro/rombo';
import { Flex, Image, Text } from 'rebass/styled-components';
import BlankMessage from 'components/molecules/BlankMessage';
import PrivacyIcon from '../../../assets/icon-effortless-privacy.svg';
import SearchIcon from '../../../assets/icon-effortless-search.svg';
import ForbiddenIcon from '../../../assets/icon-forbidden.svg';

import JobSource from '../../../components/molecules/jobSource';
import NetworkSchema from '../../../schemas/network';
import { openChat } from '../../../helpers/supportChat';
import UserSchema from '../../../schemas/user';
import strings from '../../../strings';
import OrganizationSchema from '../../../schemas/organization';

const CareersPages = ({ company, activeNetwork, currentUser }) => {
  const { isManager } = activeNetwork;
  const { domain } = company;
  const { firstName, lastName, email } = currentUser;
  const {
    id: companyId,
    name: companyName,
    scrapingEnabled,
    ocpSearchStatus,
    organizationCareersPages: ocps,
  } = company;

  if (!domain) {
    return (
      <Box title="Job source">
        <Flex flexDirection={['column']} alignItems="center" textAlign="center" pb={3}>
          <Image src={ForbiddenIcon} mb={[3]} width="64px" height="64px" />
          <Text mb={2} fontWeight="semibold">
            Extracted jobs are not available
          </Text>
          <Text>
            Because this company has no website, we are not able to add a job source. You can always post jobs below.
          </Text>
        </Flex>
      </Box>
    );
  }

  if (!ocps || !ocps.length) {
    return (
      <Box title="Job source">
        {!scrapingEnabled && (
          <Flex flexDirection={['column']} alignItems="center" textAlign="center" pb={3}>
            <Image src={PrivacyIcon} mb={[3]} width="64px" height="64px" />
            <Text mb={2} fontWeight="semibold">
              Job source jobs are excluded
            </Text>
            <Text>The company job source has been excluded at the network&apos;s request.</Text>
            {isManager && (
              <Text>
                To include it again,
                <Button onClick={openChat} m={0} color="purple.2" variant="underlineLink">
                  contact us
                </Button>
                .
              </Text>
            )}
          </Flex>
        )}
        {scrapingEnabled && ocpSearchStatus === strings.ocpSearchStatuses.in_progress && (
          <Flex flexDirection={['column']} alignItems="center" textAlign="center">
            {' '}
            <Image src={SearchIcon} width="64px" pb={1} />
            <BlankMessage title="We are connecting the company jobs to your network" pt={1}>
              It usually takes 2 business days, we will email you when it is done.{' '}
              <a
                href="https://help.getro.com/support/solutions/articles/65000179707-company-job-sources"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
              .
            </BlankMessage>
          </Flex>
        )}

        {scrapingEnabled && ocpSearchStatus === strings.ocpSearchStatuses.not_found && (
          <BlankMessage title="Looks like we're having trouble locating this company's job source">
            <>
              <Text>
                Our job source experts will continue to search for these regularly. Remember, you can also post jobs.{' '}
                <a
                  href="https://help.getro.com/support/solutions/articles/65000179707-company-job-sources"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
                .
              </Text>
              <Button
                as="a"
                mt={3}
                fontSize={1}
                variant="secondary"
                href={`https://getro-forms.typeform.com/to/auH5tH?firstname=${firstName}&lastname=${lastName}&email=${email}&companyname=${companyName}&companyid=${companyId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Suggest job source
              </Button>
            </>
          </BlankMessage>
        )}
      </Box>
    );
  }

  return (
    <Box title="Job source">
      {!scrapingEnabled && (
        <Flex flexDirection={['column']} alignItems="center" textAlign="center" pb={3}>
          <Image src={PrivacyIcon} mb={[3]} width="64px" height="64px" />
          <Text mb={2} fontWeight="semibold">
            Job source jobs are excluded
          </Text>
          <Text>The company job source has been excluded at the network&apos;s request.</Text>
          {isManager && (
            <Text>
              To include it again,
              <Button onClick={openChat} m={0} color="purple.2" variant="underlineLink">
                contact us
              </Button>
              .
            </Text>
          )}
        </Flex>
      )}
      {scrapingEnabled && (
        <>
          {ocps.map((ocp) => (
            <JobSource source={ocp} key={ocp.id} currentUser={currentUser} company={company} />
          ))}
        </>
      )}
    </Box>
  );
};

CareersPages.propTypes = {
  company: OrganizationSchema.isRequired,
  activeNetwork: NetworkSchema.isRequired,
  currentUser: UserSchema.isRequired,
};

export default CareersPages;
