/* eslint-disable no-param-reassign */
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { updateNetwork, setNetworksReady, setActiveNetwork, setNetworks } from 'actions/networksActions';
import { removeUser } from '../../actions/userActions';
import { loadedUpdateTalentNetworkSettings } from '../../pages/Settings/actions';

export const initialState = {
  active: null,
  networks: null,
  ready: false,
};

const addToObjectById = (object, element) => {
  object[element.id] = { ...element };
  return object;
};

const networksReducer = handleActions(
  {
    [setNetworks]: produce((draft, action) => {
      const { payload } = action;
      const { managingCollections, connectingCollections, managingOrganizations } = payload;

      let networks = {
        ...managingCollections.reduce(addToObjectById, {}),
        ...connectingCollections.reduce(addToObjectById, {}),
      };

      // Add networks that organizations managed by the user belong to
      if (managingOrganizations.length) {
        networks = {
          ...networks,
          ...managingOrganizations.reduce((newNetworks, newOrganization) => {
            const orgNetworks = newOrganization.collections.reduce(addToObjectById, {});
            return { ...newNetworks, ...orgNetworks };
          }, {}),
        };
      }

      // Add networks if user is a Network Admin or a Company Admin of a company that is part of Getro.org
      // TODO: This information should come from the backend.
      managingOrganizations.forEach((organization) => {
        const { collections } = organization;

        const getroOrg = collections.find((collection) => collection.domain === 'getro.org');
        if (getroOrg !== undefined) {
          networks[getroOrg.id] = getroOrg;
        }
      });

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in networks) {
        networks[key] = {
          ...networks[key],
          isManager: false,
          isConnector: false,
          isOrganizationManager: false,
        };
      }

      managingCollections.forEach((network) => {
        networks[network.id].isManager = true;
      });

      connectingCollections.forEach((network) => {
        networks[network.id].isConnector = true;
      });

      managingOrganizations.forEach((organization) => {
        const { id, logoUrl, name, collectionIds, slug } = organization;

        collectionIds.forEach((networkID) => {
          // Skip if network hasn't been added
          if (!networks[networkID]) {
            return;
          }

          if (networks[networkID].isOrganizationManager === false) {
            networks[networkID].isOrganizationManager = { [id]: { id, name, logoUrl, slug } };
          } else {
            networks[networkID].isOrganizationManager = addToObjectById(networks[networkID].isOrganizationManager, {
              id,
              name,
              logoUrl,
              slug,
            });
          }
        });
      });

      draft.networks = networks;
      return draft;
    }),
    [setNetworksReady]: produce((draft, action) => {
      const { payload } = action;
      draft.ready = !!payload;
      return draft;
    }),
    [setActiveNetwork]: produce((draft, action) => {
      const { payload } = action;
      draft.active = payload;
      draft.ready = true;
      return draft;
    }),
    [removeUser]: produce((draft) => {
      draft = { ...initialState };
      return draft;
    }),
    [updateNetwork]: produce((draft, action) => {
      const { payload } = action;
      const { id } = payload;

      draft.networks[id] = {
        ...draft.networks[id],
        ...payload,
      };

      return draft;
    }),
    [loadedUpdateTalentNetworkSettings]: produce((draft, action) => {
      const { payload } = action;
      const { id, tnHidden } = payload;

      draft.networks[id] = {
        ...draft.networks[id],
        tnHidden,
      };

      return draft;
    }),
  },
  initialState,
);

export default networksReducer;
