import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { FormError, Tag } from '@getro/rombo';
import ReduxFormSelect from './ReduxFormSelect';

export const ReduxMultiFormSelect = ({ form, field, options, onInputChange, ...rest }) => {
  const { name, value, onChange } = field;
  const error = form?.touched?.[name] && form?.errors?.[name];

  return (
    <Box width={[1]}>
      <ReduxFormSelect
        onChange={(newValue) => onChange({ target: { name, value: newValue } })}
        value={value}
        onInputChange={onInputChange}
        options={options}
        isMulti
        {...rest}
      />
      {error && <FormError mt="2">{error}</FormError>}
      {value.length > 0 && (
        <Flex flexDirection="row" flexWrap="wrap">
          {value.map((location) => (
            <Tag
              key={`${name}_${location.value}`}
              mt={2}
              onRemove={() => {
                onChange({ target: { name, value: value.filter((s) => s.value !== location.value) } });
              }}
            >
              {location.label}
            </Tag>
          ))}
        </Flex>
      )}
    </Box>
  );
};

ReduxMultiFormSelect.propTypes = {
  ...ReduxFormSelect.propTypes,
};
