import { Button, Dropdown } from '@getro/rombo';
import { FileSpreadsheet, Plus, User, UserPlus } from 'lucide-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Box, Card, Flex, Text } from 'rebass/styled-components';

import { activeNetworkSelector } from '../../../redux/selectors';

export const ImportButton = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const isLimitReached = activeNetwork.connectTier && activeNetwork.contactsCount > activeNetwork.contactsLimit;

  return isLimitReached ? (
    <Button as={NavLink} to={`/networks/${activeNetwork.slug}/upgrade`} variant="accent" icon={Plus} height="32px">
      <Text fontSize="14px">Add contacts</Text>
    </Button>
  ) : (
    <>
      <Dropdown
        placement="bottom-end"
        triggerProps={{
          variant: 'accent',
          children: (
            <Flex alignItems="center" fontSize="14px">
              <Box as={UserPlus} mr="2" width="16px" height="16px" aria-hidden="true" />
              Add contacts
            </Flex>
          ),
          sx: {
            height: '32px',
          },
        }}
      >
        <Card px="0" py="8px" width="208px">
          <Flex
            as={NavLink}
            to={`/networks/${activeNetwork.slug}/contact/add`}
            height="32px"
            px="16px"
            sx={{ gap: '8px', alignItems: 'center', textDecoration: 'none', ':hover': { bg: 'neutral.50' } }}
          >
            <Box as={User} width="16px" height="16px" aria-hidden="true" />
            Add single contact
          </Flex>
          <Flex
            as={NavLink}
            to={`/networks/${activeNetwork.slug}/import-contacts/csv`}
            height="32px"
            px="16px"
            sx={{ gap: '8px', alignItems: 'center', textDecoration: 'none', ':hover': { bg: 'neutral.50' } }}
          >
            <Box as={FileSpreadsheet} width="16px" height="16px" aria-hidden="true" />
            Import CSV
          </Flex>
        </Card>
      </Dropdown>
    </>
  );
};
