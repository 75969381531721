import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import pageHoc from 'hocs/pageHoc';
import { activeNetworkSelector } from 'redux/selectors';
import NoAccess from 'components/atoms/NoAccess';
import compose from '../../helpers/compose';

import MainLayout from '../../components/layout/Main';
import Companies from './Companies';

const pageTitle = 'Companies';

const Employers = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { isOrganizationManager, isManager } = activeNetwork;
  const hasNoAccess = useMemo(
    () => !isManager && Object.keys(isOrganizationManager).length < 2,
    [isManager, isOrganizationManager],
  );

  return (
    <MainLayout>
      {hasNoAccess ? <NoAccess cy="companies-unavailable" /> : <Companies network={activeNetwork} />}
    </MainLayout>
  );
};

export default compose(pageHoc({ title: pageTitle }))(Employers);
