import { useLocalStorage } from 'hooks/useLocalStorage';
import {
  loadSuggestedBannerClicked,
  loadSuggestedBannerClosed,
  loadSuggestedCompanies,
} from 'pages/Employers/hooks/useSuggestedCompanies/actions';
import { suggestedCompaniesMetaSelector } from 'pages/Employers/hooks/useSuggestedCompanies/selector';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { activeNetworkSelector, hasSuggestedCompaniesSelector } from 'redux/selectors';

export const useBannerActions = () => {
  const { id: networkId, slug } = useSelector(activeNetworkSelector);
  const [banner, setBanner] = useLocalStorage(`${networkId}-show-suggestion-banner`, {
    show: true,
    total: 0,
  });
  const { push } = useHistory();
  const dispatch = useDispatch();
  const suggestedCompaniesMeta = useSelector(suggestedCompaniesMetaSelector);
  const isSuggestedTurnedOn = useSelector(hasSuggestedCompaniesSelector);

  const onCloseBanner = useCallback(() => {
    setBanner({ ...banner, show: false });
    dispatch(loadSuggestedBannerClosed());
  }, [banner, dispatch, setBanner]);

  const onClickBanner = useCallback(() => {
    push(`/networks/${slug}/companies?status=suggested`);

    dispatch(loadSuggestedBannerClicked());
  }, [dispatch, push, slug]);

  useEffect(() => {
    dispatch(loadSuggestedCompanies({ page: 1 }));
  }, [dispatch]);

  return {
    showBanner: banner.show && isSuggestedTurnedOn,
    onCloseBanner,
    onClickBanner,
    suggestedCompaniesMeta,
  };
};
