import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { FormField, Message, MessageBlock, AsyncButton } from '@getro/rombo';

import SessionLayout from 'components/layout/Session';

import { post } from 'api';
import pageHoc from 'hocs/pageHoc';
import strings from 'strings';
import { trackException } from 'helpers/errorTracker';

const emailFormSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const pageTitle = 'Recover password';

const RecoverPassword = () => {
  const cancelTokenSource = axios.CancelToken.source();
  const [success, setSuccess] = useState(false);

  useEffect(
    () => () => {
      cancelTokenSource.cancel();
    },
    [cancelTokenSource],
  );

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    setStatus({ error: null });
    const params = {
      email: values.email,
    };
    try {
      await post('/users/passwords', params, { cancelToken: cancelTokenSource.token });
      setSubmitting(false);
      setSuccess(true);
    } catch (error) {
      const errorMessage = error.response && error.response.data.error;
      trackException(error);
      if (errorMessage) {
        setStatus({ error: errorMessage });
      } else {
        setStatus({ error: strings.genericError });
      }
      setSubmitting(false);
    }
  };

  return (
    <SessionLayout
      className="RecoverPassword"
      title={strings.session.recoverPassword.title}
      subtitle={success ? '' : strings.session.recoverPassword.subtitle}
    >
      <Formik validationSchema={emailFormSchema} initialValues={{ email: '' }} onSubmit={handleSubmit}>
        {({ status, isSubmitting }) => (
          <Form>
            {!success && (
              <>
                <Field type="email" name="email" label="Email address" size="lg" component={FormField} />
                <AsyncButton
                  fontWeight="semibold"
                  width={1}
                  justifyContent="center"
                  type="submit"
                  variant="primary"
                  fontSize={3}
                  loading={isSubmitting}
                >
                  Send reset email
                </AsyncButton>
              </>
            )}
            {status && status.error && (
              <Message className="RecoverPassword__error-message" content={status.error} type="error" />
            )}
            {success && (
              <MessageBlock
                title="Reset email sent"
                type="success"
                content="Instructions email sent, please check your inbox."
              />
            )}
          </Form>
        )}
      </Formik>
      <div className="RecoverPassword__back-wrapper">
        <Link to="/login">Back to log in</Link>
      </div>
    </SessionLayout>
  );
};

export default pageHoc({ title: pageTitle })(RecoverPassword);
