import React from 'react';
import PropTypes from 'prop-types';

import { Logo } from '@getro/rombo';
import Slogan from './Slogan';

const SessionLayout = ({ title, subtitle, children }) => (
  <div className="SessionLayout">
    <div className="SessionLayout__column SessionLayout__column--left">
      <div className="SessionLayout__header">
        <a href="/">
          <Logo className="SessionLayout__logo" />
        </a>
      </div>
      <div className="SessionLayout__main">
        {title && (
          <h1 className="SessionLayout__title" data-cy="session-title">
            {title}
          </h1>
        )}
        {subtitle && <p className="SessionLayout__subtitle">{subtitle}</p>}
        <div className="SessionLayout__content">{children}</div>
      </div>
      <div className="SessionLayout__footer">
        <a href="https://www.getro.com/terms" target="_blank" rel="noopener noreferrer">
          Terms of service
        </a>
        {' · '}
        <a href="https://www.getro.com/privacy" target="_blank" rel="noopener noreferrer">
          Privacy policy
        </a>
      </div>
    </div>
    <div className="SessionLayout__column SessionLayout__column--right">
      <Slogan />
    </div>
  </div>
);

SessionLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SessionLayout.defaultProps = {
  children: null,
  title: null,
  subtitle: null,
};

export default SessionLayout;
