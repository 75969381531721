import React from 'react';
import { Text } from 'rebass/styled-components';
import { Message, Button } from '@getro/rombo';
import { openChat } from '../../../helpers/supportChat';

export const GenericError = () => (
  <Message className="Modal__error-message" type="error">
    <Text>
      Something went wrong, please try again. If the problem persists,
      <Button
        fontSize="inherit"
        mr={0}
        fontWeight="body"
        p={0}
        onClick={openChat}
        type="button"
        variant="underlineLink"
      >
        contact us
      </Button>
      .
    </Text>
  </Message>
);
