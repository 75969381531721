/* eslint-disable react/no-multi-comp */
import React from 'react';
import ContentLoader from 'react-content-loader';
import { Box } from 'rebass/styled-components';
import { useTheme } from 'styled-components';

export const ContactDetailSkeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={600}
      height={500}
      viewBox="0 0 600 500"
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
    >
      <circle cx="52" cy="52" r="52" />
      <Box as="rect" x="126" y="12" rx="8" ry="8" width={[100, 150, 200]} height="16" />
      <Box as="rect" x="126" y="46" rx="8" ry="8" width={[200, 400, 460]} height="12" />
      <Box as="rect" x="126" y="70" rx="8" ry="8" width={[140, 300, 360]} height="12" />
      <rect x="0" y="172" rx="3" ry="3" width="32" height="32" />
      <Box as="rect" x="42" y="173" rx="3" ry="3" width={[260, 260, 300]} height="8" />
      <rect x="42" y="189" rx="3" ry="3" width="160" height="8" />
      <rect x="0" y="147" rx="3" ry="3" width="80" height="12" />
      <rect x="0" y="228" rx="3" ry="3" width="32" height="32" />
      <rect x="46" y="229" rx="3" ry="3" width="200" height="8" />
      <rect x="46" y="245" rx="3" ry="3" width="160" height="8" />
      <rect x="0" y="290" rx="3" ry="3" width="32" height="32" />
      <rect x="44" y="290" rx="3" ry="3" width="200" height="8" />
      <rect x="44" y="309" rx="3" ry="3" width="160" height="8" />
      <rect x="1" y="375" rx="3" ry="3" width="200" height="8" />
      <rect x="1" y="351" rx="3" ry="3" width="80" height="12" />
      <rect x="1" y="396" rx="3" ry="3" width="200" height="8" />
      <rect x="0" y="423" rx="8" ry="8" width="120" height="12" />
      <rect x="0" y="447" rx="8" ry="8" width="120" height="12" />
      <rect x="0" y="469" rx="8" ry="8" width="120" height="12" />
    </ContentLoader>
  );
};

export const ContactDetailSideSkeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={476}
      height={500}
      viewBox="0 0 476 500"
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
    >
      <rect x="0" y="0" rx="8" ry="8" width="120" height="12" />
      <rect x="0" y="28" rx="8" ry="8" width="120" height="12" />
      <rect x="0" y="56" rx="8" ry="8" width="120" height="12" />
    </ContentLoader>
  );
};
