import React, { useState } from 'react';
import { Radio } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import useOnClickOutside from 'hooks/useClickOutside';
import { FilterTitle } from '../filterTitle';

const GroupRadioSelector = ({ selected, onSelect, title, options }) => {
  const [isOpened, setIsOpened] = useState(selected?.value !== options[0].value);
  const ref = useOnClickOutside(() => {
    if (!selected) {
      setIsOpened(false);
    }
  });

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '8px',
        cursor: 'pointer',
      }}
    >
      <FilterTitle
        title={title}
        showClear={isOpened && selected}
        showToggle={!isOpened}
        clearFilter={() => {
          onSelect();
          setIsOpened(false);
        }}
        onToggle={() => {
          if (!selected && !isOpened) {
            onSelect(options[0]);
          }
          setIsOpened(true);
        }}
      />
      {isOpened && (
        <Flex sx={{ gap: '8px', flexDirection: 'column', pt: '8px' }}>
          {options.map((option) => (
            <Radio
              onChange={() => onSelect(option)}
              checked={selected?.value === option.value}
              id={option.value}
              label={option.label}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

GroupRadioSelector.defaultProps = {
  selected: null,
  onSelect: () => {},
};

GroupRadioSelector.propTypes = {
  selected: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onSelect: PropTypes.func,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default GroupRadioSelector;
