import React, { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import { Check, ListRestart, Stars } from 'lucide-react';
import PropTypes from 'prop-types';
import { PreviewAiMatches } from './previewAiMatches';
import { PreviewAutoUpdate } from './previewAutoUpdate';
import { AutoUpdateModal } from './autoUpdateModal';

export const SmartOptions = ({
  onEnableMatch,
  match,
  updateSmartOption,
  contactsCount,
  isEdit,
  hasAutoUpdateFilters,
}) => {
  const [autoUpdateAction, setAutoUpdateAction] = useState();
  const smartOptions = [
    {
      key: 'matches',
      title: 'AI matches for open roles',
      description:
        'Let our AI do the lifting: select a job and get ongoing suggestions with the best matches in your contacts.',
      icon: Stars,
      buttonText: 'Enable AI matches',
      onClick: () => onEnableMatch('matches'),
      footer: match && <PreviewAiMatches match={match} updateSmartOption={updateSmartOption} />,
    },
    {
      key: 'autoUpdate',
      title: 'Auto-update',
      description: (
        <Box>
          Auto-update adds and removes contacts according to your filters daily, while preserving manual edits.{' '}
        </Box>
      ),
      icon: ListRestart,
      buttonText: 'Enable Auto-update',
      onClick: () => {
        if (contactsCount > 0) {
          setAutoUpdateAction('enable');
          return;
        }

        onEnableMatch('autoUpdate');
      },
      footer: hasAutoUpdateFilters && (
        <PreviewAutoUpdate
          updateSmartOption={(args) => {
            if (args.action === 'disable' && isEdit) {
              setAutoUpdateAction('disable');
              return;
            }

            updateSmartOption(args);
          }}
        />
      ),
    },
  ];

  return (
    <Flex sx={{ flexDirection: 'column', gap: '32px' }}>
      <Box as="h3" fontSize="16px" fontWeight="500" color="text.dark">
        Smart options
      </Box>
      {smartOptions.map((option) => (
        <Flex
          key={option.title}
          sx={{
            gap: '8px',
            color: 'text.main',
            fontSize: '14px',
          }}
        >
          <Box
            as={option.icon}
            size="24px"
            sx={{
              color: 'purple.400',
            }}
          />
          <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
            <Flex sx={{ gap: '8px', flexDirection: 'column' }}>
              <Flex sx={{ alignItems: 'center', gap: '8px' }}>
                <Box color="text.dark" fontWeight="500">
                  {option.title}
                </Box>
                {option.footer && (
                  <Flex
                    sx={{
                      backgroundColor: 'green.20',
                      borderRadius: '100px',
                      p: '4px 12px',
                      color: 'green.600',
                      fontSize: '12px',
                      fontWeight: 500,
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <Box as={Check} size="16px" />
                    <Box as="span">Enabled</Box>
                  </Flex>
                )}
              </Flex>
              <Box>{option.description}</Box>
              {!option.footer && (
                <Box>
                  <Button variant="secondary" size="small" onClick={option.onClick}>
                    {option.buttonText}
                  </Button>
                </Box>
              )}
            </Flex>
            {option.footer}
          </Flex>
        </Flex>
      ))}
      {autoUpdateAction && (
        <AutoUpdateModal
          action={autoUpdateAction}
          onCancel={() => setAutoUpdateAction()}
          onEnable={() => {
            if (autoUpdateAction === 'enable') {
              onEnableMatch('autoUpdate');
            } else {
              updateSmartOption({ type: 'autoUpdate', action: 'disable' });
            }
            setAutoUpdateAction();
          }}
        />
      )}
    </Flex>
  );
};

SmartOptions.propTypes = {
  onEnableMatch: PropTypes.func.isRequired,
  match: PropTypes.object,
  updateSmartOption: PropTypes.func.isRequired,
  contactsCount: PropTypes.number.isRequired,
  isEdit: PropTypes.bool.isRequired,
  hasAutoUpdateFilters: PropTypes.bool.isRequired,
};

SmartOptions.defaultProps = {
  match: null,
};
