import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Card } from 'rebass/styled-components';
import { ThemeProvider } from 'styled-components';
import { SearchableList, Dropdown, Checkbox } from '@getro/rombo';
import theme from 'theme';

const AddToBoards = ({
  member,
  onCreateAndAddMemberToList,
  creating,
  onBoardSelectionChanged,
  networkTalentGroups,
  error,
}) => {
  const dropdown = useRef();
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState([]);
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    if (networkTalentGroups && networkTalentGroups.length) {
      const sortList = (unsortedList) => unsortedList.sort((a, b) => a.label.localeCompare(b.label));
      setList(sortList([...networkTalentGroups]));
      setShowCreate(false);
    }
  }, [networkTalentGroups]);

  const onSearchChange = (e) => {
    const { target } = e;
    const { value } = target;

    setKeyword(value);
    setList(networkTalentGroups.filter((i) => i.label.toLowerCase().includes(value.toLowerCase())));
    setShowCreate(networkTalentGroups.filter((i) => i.label === value).length === 0 && value !== '');
  };

  const onSearchSubmit = (name) => {
    setKeyword('');

    onCreateAndAddMemberToList({
      member,
      name,
    });
  };

  const isLoadingApi = (value) => {
    const group = member.talentGroups.find((g) => g.id === value);
    if (!group) {
      return false;
    }
    return group.isLoading;
  };

  return (
    <ThemeProvider theme={theme}>
      <Dropdown
        placement="left-start"
        variant="shadow"
        detectDrop
        ref={dropdown}
        triggerProps={{
          variant: 'accent',
          children: 'Add to lists',
          sx: { fontSize: '1', ml: 1 },
        }}
        dropDownProps={{
          width: [320],
          sx: { right: 0, textAlign: 'left' },
        }}
        onOpen={() => {
          const sortList = (unsortedList) =>
            unsortedList
              .sort((a, b) => a.label.localeCompare(b.label))
              .sort((a, b) => {
                const aChecked = !!member.talentGroups.find((g) => g.id === a.id);
                const bChecked = !!member.talentGroups.find((g) => g.id === b.id);
                return Number(bChecked) - Number(aChecked);
              });

          setList(sortList([...networkTalentGroups]));
        }}
      >
        <Card display="flex" sx={{ boxShadow: 'none' }}>
          <SearchableList
            listHeight="145px"
            keyword={keyword}
            placeholder="Search lists..."
            onSearchChange={onSearchChange}
            list={list}
            itemTemplate={({ onChange, label, value }) => (
              <Checkbox
                isLoading={!!isLoadingApi(value)}
                value={value}
                checked={member.talentGroups.find((g) => g.id === value)}
                onChange={onChange}
                label={label}
                containerProps={{ mt: 2 }}
              />
            )}
            error={error}
            showCreate={showCreate}
            loading={creating}
            onSearchSubmit={onSearchSubmit}
            createListColor="danger"
            onItemChange={onBoardSelectionChanged}
          />
        </Card>
      </Dropdown>
    </ThemeProvider>
  );
};
AddToBoards.propTypes = {
  member: PropTypes.object.isRequired,
  networkTalentGroups: PropTypes.array,
  onCreateAndAddMemberToList: PropTypes.func.isRequired,
  onBoardSelectionChanged: PropTypes.func.isRequired,
  creating: PropTypes.bool,
  error: PropTypes.string,
};

AddToBoards.defaultProps = {
  networkTalentGroups: null,
  creating: false,
  error: null,
};

export default AddToBoards;
