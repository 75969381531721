import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Chart as ChartJS } from 'chart.js';

const CHART_CONFIG = {
  type: 'line',
  data: {},
  options: {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      line: {
        fill: false,
        borderWidth: 3,
        borderColor: '#4900ff',
      },
      point: {
        radius: 4,
        hoverRadius: 6,
        hoverBorderWidth: 3,
        borderWidth: 2,
        borderColor: '#4900ff',
        backgroundColor: '#fff',
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            maxRotation: 0,
            fontColor: '#777d88',
            fontFamily: 'Silka, Helvetica, Arial, sans-serif',
            autoSkipPadding: 24,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#e4e4e7',
            zeroLineColor: '#e4e4e7',
          },
          ticks: {
            beginAtZero: true,
            precision: 0,
            fontColor: '#777d88',
            fontFamily: 'Silka, Helvetica, Arial, sans-serif',
          },
        },
      ],
    },
  },
};

const Chart = ({ labels, datasets }) => {
  const inputEl = React.useRef();
  const chartsjsObject = React.useRef();

  const updateChart = () => {
    CHART_CONFIG.data.labels = labels;
    CHART_CONFIG.data.datasets = datasets;

    if (chartsjsObject.current) {
      chartsjsObject.current.update(CHART_CONFIG);
    } else {
      const ctx = inputEl.current.getContext('2d');
      chartsjsObject.current = new ChartJS(ctx, CHART_CONFIG);
    }
  };

  // Update chart on props update
  useEffect(() => {
    updateChart();
    // eslint-disable-next-line
  }, [labels, datasets])

  // Destroy chart on component unmount
  useEffect(
    () => () => {
      chartsjsObject.current.destroy();
    },
    [],
  );

  return (
    <div style={{ position: 'relative', height: 280 }}>
      <canvas ref={inputEl}></canvas>
    </div>
  );
};

Chart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.array,
    }),
  ).isRequired,
};

export default Chart;
