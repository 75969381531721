import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@getro/rombo';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import { Check, ExternalLink, ExternalLinkIcon, MousePointer2, RefreshCcw, Zap } from 'lucide-react';
import { EXTENSION_URL, checkExtensionAvailability } from 'services/chromeExtension';
import Chrome from '../../../assets/chrome-extension-refresh.png';

export const RefreshContactModal = ({ onCloseModal, linkedinUrl, trackRefreshEvent }) => {
  const [isExtensionAvailable, setExtensionAvailability] = useState(false);
  useEffect(() => {
    checkExtensionAvailability().then((value) => {
      setExtensionAvailability(value);
    });
  }, []);
  const [checked, setChecked] = useState([]);

  const installOptions = [
    {
      icon: Zap,
      text: 'Automatic on-going sync of your LinkedIn connections',
    },
    {
      icon: MousePointer2,
      text: 'Add and manage Contacts directly from LinkedIn',
    },
    {
      icon: RefreshCcw,
      text: 'Refresh any profile information at any time',
    },
  ];

  const refreshOptions = [
    {
      id: 1,
      text: (
        <Flex
          onClick={() => {
            if (!checked.includes(1)) {
              setChecked([...checked, 1]);
              trackRefreshEvent();
            }
          }}
          sx={{ alignItems: 'center', gap: '8px' }}
        >
          <Box target="__blank" as="a" href={linkedinUrl}>
            Visit contact’s profile
          </Box>
          <Box as={ExternalLinkIcon} size={16} />
        </Flex>
      ),
    },
    {
      id: 2,
      text: (
        <Flex
          onClick={() => {
            if (!checked.includes(2)) {
              setChecked([...checked, 2]);
              trackRefreshEvent();
            }
          }}
          sx={{ alignItems: 'center', gap: '8px' }}
        >
          <Box target="__blank" as="a" href={`${linkedinUrl}/details/experience`}>
            Visit contact’s work experience{' '}
          </Box>
          <Box as={ExternalLinkIcon} size={16} />
        </Flex>
      ),
    },
    {
      id: 3,
      text: (
        <Box>
          <Box>
            {checked.length > 1
              ? 'Processing new information (including AI Matches, and Hires)...'
              : 'New information (including AI Matches, and Hires) is available'}
          </Box>
          <Box>It can take a few minutes for the new information to be ready in Getro.</Box>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ cursor: 'default' }} data-testid="refresh-contact-modal" onClick={(e) => e.stopPropagation()}>
      <Modal
        title={
          !isExtensionAvailable ? (
            <Flex
              sx={{
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Image height="32px" src={Chrome} alt="Install extension" />

              <Text variant="h4" sx={{ fontSize: '20px', color: 'text.dark', fontWeight: 600 }}>
                Boost your Getro experience with our extension
              </Text>
            </Flex>
          ) : (
            'Refresh profile information'
          )
        }
        onCancel={onCloseModal}
      >
        <Flex sx={{ flexDirection: 'column', gap: !isExtensionAvailable ? '32px' : '24px', fontSize: '14px' }}>
          <Flex sx={{ flexDirection: 'column', gap: '24px' }}>
            {isExtensionAvailable ? (
              <Box mb="16px">
                Thanks to our Chrome extension, any time you visit your contacts&apos; LinkedIn profiles, they&apos;ll
                be automatically updated in Getro.
              </Box>
            ) : (
              <Box>Manually enrich contacts and unlock additional features to streamline your workflow:</Box>
            )}
            <Box sx={{ flexDirection: 'column', gap: '16px', display: 'flex' }}>
              {!isExtensionAvailable &&
                installOptions.map(({ icon, text }) => (
                  <Flex sx={{ gap: '8px', alignItems: 'center' }}>
                    <Box as={icon} sx={{ color: 'purple.300', size: 20 }} />
                    <Box>{text}</Box>
                  </Flex>
                ))}

              {isExtensionAvailable && (
                <>
                  <Box>
                    Visit their LinkedIn profile to get fresh information (you can close the tab after it loads):
                  </Box>
                  <Box sx={{ gap: '8px', alignItems: 'center', flexDirection: 'column' }}>
                    <Box>
                      {refreshOptions.map((option, index) => (
                        <Flex sx={{ gap: '8px', alignItems: 'baseline' }}>
                          <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                            {checked.includes(option.id) ? (
                              <Flex
                                data-testId={`${option.id}-checked`}
                                sx={{
                                  backgroundColor: 'purple.400',
                                  borderRadius: '40px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '24px',
                                  height: '24px',
                                }}
                              >
                                <Box as={Check} size="16px" sx={{ color: 'neutral.0' }} />
                              </Flex>
                            ) : (
                              <Flex
                                sx={{
                                  color: 'text.main',
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  width: '24px',
                                  height: '24px',
                                  backgroundColor: 'neutral.100',
                                  borderRadius: '100%',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {option.id}
                              </Flex>
                            )}
                            {index !== refreshOptions.length - 1 && (
                              <Box
                                width="2px"
                                height="24px"
                                sx={{
                                  width: '2px',
                                  height: '32px',
                                  backgroundColor: 'neutral.100',
                                }}
                              />
                            )}
                          </Flex>
                          {option.text}
                        </Flex>
                      ))}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Flex>
          {!isExtensionAvailable && (
            <Button
              sx={{
                width: '100%',
                justifyContent: 'center',
              }}
              as="a"
              type="link"
              data-testid="submit-btn"
              icon={ExternalLink}
              iconPosition="right"
              target="__blank"
              iconGap="8px"
              href={EXTENSION_URL}
            >
              Install extension
            </Button>
          )}
        </Flex>
      </Modal>
    </Box>
  );
};

RefreshContactModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  trackRefreshEvent: PropTypes.func.isRequired,
  linkedinUrl: PropTypes.string.isRequired,
};
