import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'hooks/useInjectReducer';
import { useInjectSaga } from 'hooks/useInjectSaga';
import { makeSelectIsLoading } from 'redux/loadingSelector';
import { useHistory } from 'react-router';

import { activeNetworkSelector, hasSuggestedCompaniesSelector } from 'redux/selectors';
import { useLocalStorage } from 'hooks/useLocalStorage';

import {
  suggestedCompaniesMetaSelector,
  suggestedCompaniesSelector,
  suggestedCompaniesError,
  suggestedCompaniesInitialized,
  key as suggestedKey,
  skippingSuggestedCompaniesIds,
  addingSuggestedCompaniesIds,
} from './selector';
import reducer from './reducer';
import saga from './saga';
import {
  loadPublishSuggestedCompany,
  loadSkipSuggestedCompany,
  loadSuggestedBannerClicked,
  loadSuggestedBannerClosed,
  loadSuggestedCompanies,
} from './actions';

export const DEFAULT_FILTERS = {
  keyword: '',
  places: null,
  topicIds: [],
  ocpStatus: null,
  missingManager: null,
  qa: undefined,
  page: 1,
};

// i want to keep track of this outside the scope of the hook
let initialized = null;

export const useSuggestedCompanies = () => {
  useInjectSaga({ key: suggestedKey, saga });
  useInjectReducer({ key: suggestedKey, reducer });
  const {
    location: { search, pathname },
    replace,
  } = useHistory();
  const dispatch = useDispatch();
  const { id: networkId, isManager } = useSelector(activeNetworkSelector);
  const meta = useSelector(suggestedCompaniesMetaSelector);
  const companies = useSelector(suggestedCompaniesSelector);
  const hasInitialized = useSelector(suggestedCompaniesInitialized);
  const hasInitializedError = useSelector(suggestedCompaniesError);
  const isSuggested = useMemo(() => new URLSearchParams(search).has('status'), [search]);
  const isLoadingCompanies = useSelector(makeSelectIsLoading('suggestedCompanies'));
  const skippingIds = useSelector(skippingSuggestedCompaniesIds);
  const addingIds = useSelector(addingSuggestedCompaniesIds);
  const [filter, applyFilter] = useState({ page: 1 });
  const isInitial = useRef(false);
  const [banner, setBanner] = useLocalStorage(`${networkId}-show-suggestion-banner`, {
    show: true,
    total: 0,
  });
  const isSuggestedTurnedOn = useSelector(hasSuggestedCompaniesSelector);

  useLayoutEffect(() => {
    if (!initialized) {
      initialized = true;
      setBanner({ ...banner, show: true });
    }
  }, [banner, setBanner]);

  useEffect(() => {
    if (!isSuggestedTurnedOn) return;
    const searchParams = new URLSearchParams(search);
    if (!isInitial.current && searchParams.get('status') && searchParams.has('page')) {
      searchParams.delete('page');
      replace({ pathname, search: searchParams.toString() });
      isInitial.current = true;
      return;
    }

    dispatch(loadSuggestedCompanies({ page: searchParams.get('page') }));
    isInitial.current = true;
  }, [dispatch, isSuggestedTurnedOn, pathname, replace, search]);

  const navigate = useCallback(() => {
    applyFilter({ page: 1 });
    replace({ pathname, search: '?status=suggested' });
  }, [pathname, replace]);

  const loadMore = useCallback(() => {
    const updatedFilters = { page: parseInt(filter.page, 10) + 1 };
    applyFilter(updatedFilters);
    const params = new URLSearchParams({
      ...updatedFilters,
      status: 'suggested',
    });
    replace({ pathname, search: params.toString() });
  }, [filter.page, pathname, replace]);

  const onSkip = useCallback(
    ({ companyId, otherValue, reason }) => {
      dispatch(loadSkipSuggestedCompany({ companyId, otherValue, reason }));
    },
    [dispatch],
  );

  const onAdd = useCallback(
    (companyId) => {
      dispatch(loadPublishSuggestedCompany({ companyId }));
    },
    [dispatch],
  );

  const onCloseBanner = useCallback(() => {
    setBanner({ ...banner, show: false });
    dispatch(loadSuggestedBannerClosed());
  }, [banner, dispatch, setBanner]);

  const onClickBanner = useCallback(() => {
    const searchParams = new URLSearchParams(search);
    if (!searchParams.has('status')) {
      navigate('suggested');
    }

    dispatch(loadSuggestedBannerClicked());
  }, [dispatch, navigate, search]);

  return {
    isSuggested: isSuggestedTurnedOn && isSuggested,
    companies,
    total: meta.total,
    isLoadingCompanies,
    hasInitialized,
    hasInitializedError,
    navigate,
    loadMore,
    onSkip,
    onAdd,
    onCloseBanner,
    onClickBanner,
    showBanner: banner.show && !isSuggested && isManager,
    skippingIds,
    addingIds,
    isSuggestedTurnedOn,
  };
};
