import { createApi } from '@reduxjs/toolkit/query/react';
import { transformCompanyData } from 'helpers/organizations';
import { networkBaseQueryV2 } from '../queries';

export const companiesV2Api = createApi({
  reducerPath: 'companiesV2Api',
  baseQuery: networkBaseQueryV2,
  endpoints: (builder) => ({
    searchJobCompanies: builder.query({
      query: ({ query: q }) => ({
        url: '/organizations/search',
        params: {
          q,
        },
      }),
    }),
    company: builder.query({
      query: ({ companyId, companySlug }) => ({
        url: `organizations/${companyId || companySlug}`,
      }),
      transformResponse: transformCompanyData,
    }),
  }),
});

export const { useSearchJobCompaniesQuery, useLazySearchJobCompaniesQuery, useCompanyQuery } = companiesV2Api;
