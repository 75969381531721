import React, { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { FormField, Radio, Box, BoxRow, FormSelect } from '@getro/rombo';
import NetworkSchema from 'schemas/network';
import { Flex, Text } from 'rebass/styled-components';

import Employers from './Employers';
import GroupSchema from '../../../schemas/group';
import UserSchema from '../../../schemas/user';

const Info = ({
  activeNetwork,
  currentUser,
  canInviteEmployers,
  canSearchCompanies,
  canSetConnector,
  canSetAdmin,
  canSetPermissions,
  canSetRoles,
  canSetEmployer,
  companies,
  isManagingOrganizations,
  onSubmit,
  inviteMemberInfo,
  onCancel,
  handleUploadCSV,
  talentGroups,
}) => {
  const { features, isManager } = activeNetwork;
  const actionText = useMemo(() => {
    if (features.includes('bulk_members_invite')) {
      return 'Upload a CSV';
    }

    return null;
  }, [features]);

  const action = useMemo(() => {
    if (features.includes('bulk_members_invite')) {
      return handleUploadCSV;
    }

    return null;
  }, [features, handleUploadCSV]);

  const manageRoleChange = (role, setFieldValue) => () => {
    let roleToSet = [];

    if (role) {
      roleToSet = [role];
      setFieldValue('verified', true);
    }

    setFieldValue('roles', roleToSet);
  };

  const handleSubmit = (values) => {
    const { company, ...rest } = values;

    const params = {
      ...rest,
      verified: values.roles.length ? true : values.verified,
      company: values.roles.includes('employer_admin') ? values.company : null,
    };

    onSubmit(params);
  };

  const formSchema = Yup.object().shape({
    firstName: Yup.string().label('First name').required(),
    lastName: Yup.string().label('Last name').required(),
    email: Yup.string()
      .required()
      .email()
      .test('email', "You can't invite yourself", (value) => value !== currentUser.email),
    linkedinUrl: Yup.string().label('LinkedIn URL').url(),
    company: Yup.object()
      // eslint-disable-next-line func-names
      .test('company', 'Please select a company', function (value) {
        // eslint-disable-next-line react/no-this-in-sfc
        if (!this.parent.roles.includes('employer_admin')) {
          return true;
        }

        if (companies.length === 1) {
          return true;
        }

        return value && !!value.value;
      })
      .nullable(),
  });

  return (
    <Formik enableReinitialize initialValues={inviteMemberInfo} onSubmit={handleSubmit} validationSchema={formSchema}>
      {({ values, errors, setFieldValue }) => (
        <Form data-testid="invited-member-data-form">
          <Box
            size="md"
            title="New member information"
            className="InviteMember"
            editing
            onCancelClick={onCancel}
            saveAsSubmit
            saveText="Next"
            actionText={actionText}
            action={action}
          >
            <BoxRow title="First name*">
              <Field type="text" name="firstName" component={FormField} placeholder="" />
            </BoxRow>
            <BoxRow title="Last name*">
              <Field type="text" name="lastName" component={FormField} placeholder="" />
            </BoxRow>
            <BoxRow title="Work email address*">
              <Field type="text" name="email" component={FormField} placeholder="email@company.com" />
            </BoxRow>
            <BoxRow title="LinkedIn profile URL">
              <Field
                type="text"
                name="linkedinUrl"
                component={FormField}
                placeholder="https://www.linkedin.com/in/..."
              />
            </BoxRow>
            {isManager && (
              <BoxRow title="Lists">
                <Field
                  component={FormSelect}
                  name="talentGroupIds"
                  placeholder="Search lists"
                  options={talentGroups}
                  isSearchable
                  multiple
                />
              </BoxRow>
            )}

            {canSetRoles && (
              <BoxRow title="Relationship to network">
                {(canSetEmployer || canSetConnector || canSetAdmin) && (
                  <Radio
                    className="InviteMember__role InviteMember__control-row"
                    name="none"
                    value="none"
                    onChange={manageRoleChange(undefined, setFieldValue)}
                    checked={!values.roles.length}
                    label={
                      <Text>
                        <Text as="span" fontWeight="semibold" mr="2">
                          Talent:
                        </Text>
                        member interested in connecting to opportunities in the network.
                      </Text>
                    }
                  />
                )}

                {canSetConnector && (
                  <Flex mt="3">
                    <Radio
                      className="InviteMember__role InviteMember__control-row"
                      name="ambassador"
                      value="ambassador"
                      onChange={manageRoleChange('ambassador', setFieldValue)}
                      checked={values.roles.includes('ambassador')}
                      label={
                        <Text>
                          <Text as="span" fontWeight="semibold" mr="2">
                            Ambassador:
                          </Text>
                          member you trust to invite and verify new members.
                        </Text>
                      }
                    />
                  </Flex>
                )}

                {canSetEmployer && (
                  <Flex mt="3" flexDirection="column">
                    <Radio
                      className="InviteMember__role InviteMember__control-row"
                      name="employer_admin"
                      value="employer_admin"
                      onChange={manageRoleChange('employer_admin', setFieldValue)}
                      checked={values.roles.includes('employer_admin')}
                      label={
                        <Text>
                          <Text as="span" fontWeight="semibold" mr="2">
                            Company admin:
                          </Text>
                          member who can manage company profile and posted jobs.
                        </Text>
                      }
                    />

                    {values.roles.includes('employer_admin') && (
                      <Employers
                        {...{ values, errors, setFieldValue }}
                        isManagingOrganizations={isManagingOrganizations}
                        select={companies}
                        search={canSearchCompanies}
                        canInviteEmployers={canInviteEmployers}
                        activeNetwork={activeNetwork}
                      />
                    )}
                  </Flex>
                )}

                {canSetAdmin && (
                  <Flex mt="3">
                    <Radio
                      className="InviteMember__role InviteMember__control-row"
                      name="network_admin"
                      value="network_admin"
                      checked={values.roles.includes('network_admin')}
                      onChange={manageRoleChange('network_admin', setFieldValue)}
                      label={
                        <Text>
                          <Text as="span" fontWeight="semibold" mr="2">
                            Network admin:
                          </Text>
                          member who can manage network settings and all network data.
                        </Text>
                      }
                    />
                  </Flex>
                )}
              </BoxRow>
            )}

            {canSetPermissions && (
              <BoxRow title="Status">
                <Radio
                  name="verified"
                  checked={values.verified}
                  disabled={values.roles.length !== 0}
                  className="InviteMember__control-row"
                  onChange={() => {
                    setFieldValue('verified', true);
                  }}
                  label={
                    <Text>
                      <Text as="span" fontWeight="semibold" mr="2">
                        Verified:
                      </Text>
                      can request introductions within the network.
                    </Text>
                  }
                />
                <Flex mt="3">
                  <Radio
                    name="verified"
                    checked={!values.verified}
                    disabled={values.roles.length !== 0}
                    className="InviteMember__control-row"
                    onChange={() => {
                      setFieldValue('verified', false);
                    }}
                    label={
                      <Text>
                        <Text as="span" fontWeight="semibold" mr="2">
                          Non-verified:
                        </Text>
                        can
                        <Text as="span" fontWeight="semibold" mx="1">
                          not
                        </Text>
                        request introductions within the network.
                      </Text>
                    }
                  />
                </Flex>
                {values.roles.length > 0 && (
                  <p className="InviteMember__permissions-tip">
                    * Ambassadors, company admins and network admins always can request introductions.
                  </p>
                )}
              </BoxRow>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

Info.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
  currentUser: UserSchema.isRequired,
  canInviteEmployers: PropTypes.bool.isRequired,
  canSearchCompanies: PropTypes.bool.isRequired,
  canSetConnector: PropTypes.bool.isRequired,
  canSetAdmin: PropTypes.bool.isRequired,
  canSetPermissions: PropTypes.bool.isRequired,
  canSetRoles: PropTypes.bool.isRequired,
  canSetEmployer: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isManagingOrganizations: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inviteMemberInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    linkedinUrl: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    verified: PropTypes.bool,
    company: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  handleUploadCSV: PropTypes.func.isRequired,
  talentGroups: PropTypes.arrayOf(GroupSchema).isRequired,
};

export default Info;
