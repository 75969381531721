import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from '../useInjectSaga';
import saga from './saga';
import reducer from './reducer';
import { jobActionsDialogStateSelector, jobActionsErrorSelector, key } from './selectors';
import { makeSelectIsLoading } from '../../redux/loadingSelector';
import {
  loadCloseJob,
  loadDeleteJob,
  loadExtendJob,
  loadForceJobVisibility,
  loadBulkForceJobVisibility,
  toggleJobActionDialog,
  viewJob,
  loadFeatureJob,
  loadBulkForceAllJobsVisibility,
  loadJobApplicationDownload,
} from './actions';
import { useInjectReducer } from '../useInjectReducer';
import { activeNetworkSelector } from '../../redux/selectors';
import { triggerTrackEvent } from '../../pages/CompanyProfile/actions';

const useJobActions = ({ source }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const activeNetwork = useSelector(activeNetworkSelector);
  const dispatch = useDispatch();
  const bulkForceJobVisibility = useSelector(makeSelectIsLoading('bulkForceJobVisibility'));
  const bulkForceAllJobsVisibility = useSelector(makeSelectIsLoading('bulkForceAllJobsVisibility'));
  const isBulkForcingVisibility = useMemo(
    () => bulkForceJobVisibility || bulkForceAllJobsVisibility,
    [bulkForceAllJobsVisibility, bulkForceJobVisibility],
  );
  const bulkForceJobVisibilityError = useSelector(jobActionsErrorSelector('bulkForceJobVisibility'));
  const bulkForceAllJobsVisibilityError = useSelector(jobActionsErrorSelector('bulkForceAllJobsVisibility'));
  const isBulkForcingVisibilityError = useMemo(
    () => bulkForceJobVisibilityError || bulkForceAllJobsVisibilityError,
    [bulkForceAllJobsVisibilityError, bulkForceJobVisibilityError],
  );

  return {
    jobActions: {
      onViewJob: ({ job }) => {
        window.open(job.url);
        // we dispatch this only so we can tie into the event for analytics
        dispatch(viewJob({ job }));
      },
      onExtendJobRequest: (params) => {
        dispatch(toggleJobActionDialog({ dialog: 'extendJob', isOpen: true }));
        const { organization } = params;
        dispatch(
          triggerTrackEvent({
            name: `${source}:open_extend_job_modal`,
            eventProperties: {
              network_id: activeNetwork.id,
              organization_id: organization?.id,
              job_id: params.id,
            },
          }),
        );
      },
      onExtendJob: (params) => {
        dispatch(loadExtendJob({ ...params, activeNetwork, source }));
      },
      onCloseJobRequest: (job) => {
        const { organization } = job;
        dispatch(
          triggerTrackEvent({
            name: `${source}:open_close_job_modal`,
            eventProperties: {
              network_id: activeNetwork.id,
              organization_id: organization?.id,
              job_id: job.id,
            },
          }),
        );
        dispatch(toggleJobActionDialog({ dialog: 'closeJob', isOpen: true }));
      },
      onCloseJob: (params) => {
        dispatch(loadCloseJob({ ...params, activeNetwork, source }));
      },
      onForceJobVisibilityRequest: (job) => {
        const { organization } = job;
        dispatch(
          triggerTrackEvent({
            name: `${source}:open_visibility_modal`,
            eventProperties: {
              network_id: activeNetwork.id,
              organization_id: organization?.id,
              job_id: job.id,
              multiple_selection: false,
            },
          }),
        );
        dispatch(toggleJobActionDialog({ dialog: 'forceJobVisibility', isOpen: true }));
      },
      onForceJobVisibility: (params) => {
        dispatch(loadForceJobVisibility({ ...params, activeNetwork, source }));
      },
      onFeatureJobRequest: (job) => {
        dispatch(
          triggerTrackEvent({
            name: `${source}:open_feature_job_modal`,
            eventProperties: {
              network_id: activeNetwork.id,
              job_id: job.id,
            },
          }),
        );
        dispatch(toggleJobActionDialog({ dialog: 'featureJob', isOpen: true }));
      },
      onFeatureJob: (params) => {
        dispatch(loadFeatureJob({ ...params, activeNetwork, source }));
      },
      onBulkForceJobVisibilityRequest: () => {
        dispatch(
          triggerTrackEvent({
            name: `${source}:open_visibility_modal`,
            eventProperties: {
              network_id: activeNetwork.id,
              multiple_selection: true,
            },
          }),
        );
        dispatch(toggleJobActionDialog({ dialog: 'bulkForceJobVisibility', isOpen: true }));
      },
      onBulkForceJobVisibility: (params) => {
        dispatch(loadBulkForceJobVisibility({ ...params, activeNetwork, source }));
      },
      onBulkForceAllJobsVisibility: (params) => {
        dispatch(loadBulkForceAllJobsVisibility({ ...params, activeNetwork, source }));
      },
      onDeleteJob: (params) => {
        dispatch(loadDeleteJob({ ...params, activeNetwork }));
      },
      onShareJob: (params) => {
        dispatch(toggleJobActionDialog({ dialog: 'shareJob', isOpen: true }));
        const { organization } = params;
        dispatch(
          triggerTrackEvent({
            name: `${source}:open_share_job_modal`,
            eventProperties: {
              network_id: activeNetwork.id,
              organization_id: organization?.id,
              job_id: params.id,
            },
          }),
        );
      },
      onDownloadJobApplications: (job) => {
        dispatch(
          triggerTrackEvent({
            name: `${source}:download_applicants`,
            eventProperties: {
              network_id: activeNetwork.id,
              job_id: job.id,
              number_applicants: job.jobApplicationsCount,
            },
          }),
        );
        dispatch(loadJobApplicationDownload({ jobId: job.id, activeNetwork }));
      },
    },
    jobActionsLoading: {
      isViewing: useSelector(makeSelectIsLoading('viewJob')),
      isExtending: useSelector(makeSelectIsLoading('extendJob')),
      isForcingVisibility: useSelector(makeSelectIsLoading('forceJobVisibility')),
      isFeaturingJob: useSelector(makeSelectIsLoading('featureJob')),
      isBulkForcingVisibility,
      isClosing: useSelector(makeSelectIsLoading('closeJob')),
      isDeleting: useSelector(makeSelectIsLoading('deleteKpb')),
      isRequestingApplicationsDownload: useSelector(makeSelectIsLoading('jobApplicationDownload')),
    },
    jobActionsErrors: {
      viewJob: useSelector(jobActionsErrorSelector('viewJob')),
      extendJob: useSelector(jobActionsErrorSelector('extendJob')),
      forceJobVisibility: useSelector(jobActionsErrorSelector('forceJobVisibility')),
      featureJob: useSelector(jobActionsErrorSelector('featureJob')),
      bulkForceJobVisibility: isBulkForcingVisibilityError,
      closeJob: useSelector(jobActionsErrorSelector('closeJob')),
      deleteJob: useSelector(jobActionsErrorSelector('deleteJob')),
    },
    dialogStates: {
      closeJob: useSelector(jobActionsDialogStateSelector('closeJob')),
      extendJob: useSelector(jobActionsDialogStateSelector('extendJob')),
      forceJobVisibility: useSelector(jobActionsDialogStateSelector('forceJobVisibility')),
      featureJob: useSelector(jobActionsDialogStateSelector('featureJob')),
      bulkForceJobVisibility: useSelector(jobActionsDialogStateSelector('bulkForceJobVisibility')),
      shareJob: useSelector(jobActionsDialogStateSelector('shareJob')),
      deleteJob: useSelector(jobActionsDialogStateSelector('deleteJob')),
      jobApplicationDownload: useSelector(jobActionsDialogStateSelector('jobApplicationDownload')),
    },
  };
};

export default useJobActions;
