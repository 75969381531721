import React, { useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Box, Card, Flex, Text } from 'rebass/styled-components';
import { Button, Dropdown, FormCheckboxes, FormField, Modal, ProfilePicture } from '@getro/rombo';
import { MoreHorizontal, Trash } from 'lucide-react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ContactDetail } from '../../../../components/organisms/contactDetail';
import { useDeleteHireMutation, useUpdateHireMutation } from '../../../../services/hires';
import { activeNetworkSelector } from '../../../../redux/selectors';
import { trackEvent } from '../../../../helpers/analytics';
import { TeamImpactSelector, options as optionsTeamImpact } from './teamImpactSelector';

export const TableRow = ({ hire }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetailView, setShowDetailView] = useState(false);
  const activeNetwork = useSelector(activeNetworkSelector);
  const [deleteHire, { isLoading: isLoadingDeleteHire }] = useDeleteHireMutation();
  const [updateHire] = useUpdateHireMutation();
  const formRef = useRef();

  const formSchema = Yup.object().shape({
    reason: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }),
      )
      .required('A reason is required so we can improve hire reports'),
  });

  const deleteModalOptions = [
    { label: 'This person is not an employee – board member, investor, advisor, or similar', value: '0' },
    { label: 'This person does not work at this company', value: '1' },
    { label: 'We connected after they started working at this company', value: '2' },
    { label: 'Other', value: 'other' },
  ];

  const emitReason = (other, reason) => {
    const parsedReason = reason.reduce(
      (carry, item, index) =>
        `${carry}${index > 0 ? '; ' : ''}${item.value === 'other' ? `${item.label}: ${other}` : item.label}`,
      '',
    );

    trackEvent('hires:delete_hire_reason', {
      collection_id: activeNetwork.id,
      hire_id: hire.id,
      reason: parsedReason,
    });
  };

  const onSubmitDeleteForm = async ({ other, reason }) => {
    try {
      await deleteHire(hire);

      toast('Hire Report updated', { icon: 'success', id: 'hires' });

      trackEvent('hires:delete_hire', {
        collection_id: activeNetwork.id,
        hire_id: hire.id,
      });

      emitReason(other, reason);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const onClickOpenDetail = () => {
    setShowDetailView(true);
  };

  const onChangeTeamImpact = async (currentHire, { value }) => {
    try {
      await updateHire({ hire: currentHire, data: { teamInvolvement: value } });

      toast('Hire Report updated', { icon: 'success', id: 'hires' });

      trackEvent('hires:impact_change', {
        collection_id: activeNetwork.id,
        hire_id: currentHire.id,
        value,
      });
    } catch (e) {
      // Do nothing
    }
  };

  return (
    <>
      <Box as="td" pt="24px" verticalAlign="middle" onClick={onClickOpenDetail} sx={{ cursor: 'pointer' }}>
        <Flex alignItems="center">
          <Box width="32px" height="32px" mr="8px" flex="0 0 auto">
            <ProfilePicture variant="square" name={hire.organization?.name} imageUrl={hire.organization?.logoUrl} />
          </Box>
          <Text
            fontSize="14px"
            fontWeight="medium"
            color="text.dark"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {hire.organization?.name}
          </Text>
        </Flex>
      </Box>
      <Box as="td" pt="24px" verticalAlign="middle" onClick={onClickOpenDetail} sx={{ cursor: 'pointer' }}>
        <Flex alignItems="center">
          <Box width="32px" height="32px" mr="8px" flex="0 0 auto">
            <ProfilePicture name={hire.contact?.fullName} imageUrl={hire.contact?.profilePicUrl} />
          </Box>
          <Text
            fontSize="14px"
            fontWeight="medium"
            color="text.dark"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {hire.contact?.fullName}
          </Text>
        </Flex>
      </Box>
      <Box as="td" pt="24px" verticalAlign="middle" onClick={onClickOpenDetail} sx={{ cursor: 'pointer' }}>
        <Text fontSize="14px" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {hire.jobTitle || '-'}
        </Text>
      </Box>
      <Box as="td" pt="24px" verticalAlign="middle" onClick={onClickOpenDetail} sx={{ cursor: 'pointer' }}>
        <Text fontSize="14px" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {new Date(hire.dateFrom).toLocaleString('en-US', {
            month: 'short',
            year: 'numeric',
          })}
        </Text>
      </Box>
      <Box as="td" pt="24px" verticalAlign="middle" onClick={onClickOpenDetail} sx={{ cursor: 'pointer' }}>
        <Text fontSize="14px" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {hire.contact.importers?.map((importer) => importer.name).join(', ') || '-'}
        </Text>
      </Box>
      <Box as="td" pt="24px" verticalAlign="middle" onClick={onClickOpenDetail} sx={{ cursor: 'pointer' }}>
        <Text fontSize="14px" minWidth="120px" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {hire.contact.signupInfo ? 'Yes' : 'No'}
        </Text>
      </Box>
      <Box as="td" pt="24px" verticalAlign="middle">
        <TeamImpactSelector
          value={optionsTeamImpact.find((item) => item.value === hire.teamInvolvement)}
          onChange={(e) => onChangeTeamImpact(hire, e)}
        />
      </Box>
      <Box as="td" pt="24px" verticalAlign="middle" color="text.subtle">
        <Flex justifyContent="flex-end">
          <Dropdown
            placement="bottom-end"
            triggerProps={{
              variant: 'styleless',
              children: <Box as={MoreHorizontal} width="24px" height="24px" aria-hidden="true" />,
              flex: '0 0 auto',
            }}
          >
            <Card px="0" py="8px">
              <Flex
                onClick={() => setShowDeleteModal(true)}
                alignItems="center"
                height="32px"
                px="8px"
                sx={{ cursor: 'pointer', ':hover': { bg: 'neutral.50' } }}
              >
                <Box as={Trash} mr="8px" width="16px" height="16px" aria-hidden="true" />
                Remove
              </Flex>
            </Card>
          </Dropdown>
        </Flex>
        {showDetailView && (
          <ContactDetail page="hires" contact={hire.contact} onClose={() => setShowDetailView(false)} />
        )}
        {showDeleteModal && (
          <Modal
            title="Remove hire"
            actions={
              <Flex justifyContent={['flex-end']} sx={{ columnGap: '16px' }}>
                <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                  Cancel
                </Button>
                <Button variant="danger" loading={isLoadingDeleteHire} onClick={() => formRef.current.handleSubmit()}>
                  Remove
                </Button>
              </Flex>
            }
            onCancel={() => setShowDeleteModal(false)}
          >
            <Box>
              <Text pb="24px">Remove this hire if it does not belong to make sure your metrics are accurate.</Text>
              <Formik
                innerRef={formRef}
                initialValues={{ other: '', reason: [] }}
                validationSchema={formSchema}
                onSubmit={onSubmitDeleteForm}
              >
                {({ values }) => (
                  <Form>
                    <Field
                      component={FormCheckboxes}
                      name="reason"
                      label="Why do you want to remove this hire?"
                      options={deleteModalOptions}
                    />
                    <Box
                      mt="16px"
                      ml="24px"
                      sx={{
                        visibility: values.reason.some((item) => item.value === 'other') ? 'visible' : 'hidden',
                      }}
                    >
                      <Field component={FormField} name="other" placeholder="Specify the reason..." />
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Modal>
        )}
      </Box>
    </>
  );
};

TableRow.propTypes = {
  hire: PropTypes.object,
};

TableRow.defaultProps = {
  hire: {},
};
