import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { introductionRequestsSelector } from 'redux/selectors';
import { useInjectReducer } from 'hooks/useInjectReducer';
import { useInjectSaga } from 'hooks/useInjectSaga';
import { fetchIntroductionRequests } from 'actions/introductionRequestsActions';
import introductionRequestsReducer from 'redux/reducers/introductionRequestsReducer';
import fetchIntroductionRequestsWatcher from 'redux/sagas/fetchIntroductionRequests';
import declineIntroductionRequestSaga from 'redux/sagas/declineIntroductionRequest';

const useIntroductionRequests = (collection) => {
  useInjectReducer({ key: 'introductionRequests', reducer: introductionRequestsReducer });
  useInjectSaga({ key: 'fetchIntroductionRequests', saga: fetchIntroductionRequestsWatcher });
  useInjectSaga({ key: 'declineIntroductionRequestSaga', saga: declineIntroductionRequestSaga });
  const introductionRequests = useSelector(introductionRequestsSelector);
  const { loading } = introductionRequests;
  const dispatch = useDispatch();
  const { id, isManager, features } = collection;
  const introductionRequestsEnabled = isManager && features && features.includes('talent_network');

  useEffect(() => {
    if (introductionRequests[id] === undefined && !loading && introductionRequestsEnabled) {
      dispatch(fetchIntroductionRequests(id));
    }
  }, [introductionRequestsEnabled, introductionRequests, id, dispatch, loading]);

  return [introductionRequests[id], loading];
};

export default useIntroductionRequests;
