import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from 'rebass/styled-components';
import { activeNetworkSelector } from 'redux/selectors';
import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import MainLayout from '../../components/layout/Main';
import { JobEditor } from '../../components/organisms/jobEditor';
import usePageHook from '../../hooks/usePageHook';

const PostJob = () => {
  const params = useParams();
  const activeNetwork = useSelector(activeNetworkSelector);
  const { action } = params;
  const title = useMemo(() => {
    if (action === 'edit-job') {
      return 'Edit job';
    }
    if (action === 'duplicate-job') {
      return 'Duplicate job';
    }

    return 'Post a job';
  }, [action]);

  usePageHook(title);

  const history = useHistory();

  const goBackToJobs = () => {
    history.goBack();
  };

  return (
    <MainLayout>
      <MainLayoutHeader
        title={title}
        breadcrumbs={[
          {
            label: 'Jobs',
            to: `/networks/${activeNetwork.slug}/jobs`,
          },
        ]}
      />
      <Box pt="24px" px="16px">
        <JobEditor onCancelClick={goBackToJobs} action={action} selectCompany={!action} analyticsPage="jobs" />
      </Box>
    </MainLayout>
  );
};
export default PostJob;
