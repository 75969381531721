import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { UploadCloud } from 'lucide-react';
import { FormError, FormLabel } from '@getro/rombo';
import { read, utils } from 'xlsx';
import { Input } from '@rebass/forms/styled-components';

export const FormCsvFile = ({
  label,
  labelExtra,
  helpText,
  field,
  form,
  containerProps,
  parserOptions,
  rowLimit,
  fileName,
  setFileName,
  onFileLoaded,
  ...rest
}) => {
  const [csvError, setCsvError] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const { id, name, value } = field;
  const { setFieldValue } = form;
  const error = (form.touched?.[name] && form.errors?.[name]) || csvError;

  const onCurrentFileLoaded = (data, fileInfo) => {
    setCsvError('');
    setFileName('');

    if (!data.length) {
      setCsvError('No valid records found');
    } else {
      setFieldValue(name, data);
      setFileName(fileInfo.name);

      if (onFileLoaded) {
        onFileLoaded(data, fileInfo, setCsvError);
      }
    }
  };

  const onRemove = () => {
    setCsvError('');
    setFileName('');
    setFieldValue(name, []);
  };

  const onDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const parseFile = (file) => {
    const errorMessage = 'We couldn’t read the file, make sure it is a CSV.';

    if (file.type.toLowerCase().includes('csv')) {
      const reader = new FileReader();

      reader.onload = ({ target }) => {
        const arrayBuffer = target.result;
        const wb = read(arrayBuffer);
        const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { defval: null });

        onCurrentFileLoaded(data, { name: file.name, lastModified: file.lastModified, type: file.type });
      };

      reader.onError = () => setCsvError(errorMessage);

      reader.readAsArrayBuffer(file);
    } else {
      setCsvError(errorMessage);
    }
  };

  const onFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDragActive(false);

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      parseFile(event.dataTransfer.files[0]);
    }
  };

  const onChange = (event) => {
    event.preventDefault();

    if (event.target.files && event.target.files[0]) {
      parseFile(event.target.files[0]);
    }
  };

  return (
    <Box width="100%" {...containerProps}>
      {label && (
        <FormLabel htmlFor={name || id} extra={labelExtra}>
          {label}
        </FormLabel>
      )}
      {helpText && (
        <Text fontSize="14px" mb="8px" lineHeight="20px">
          {helpText}
        </Text>
      )}

      {!value.length ? (
        <Flex
          display="block"
          height="100px"
          width="100%"
          bg="neutral.20"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{
            borderRadius: 'default',
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={() => inputRef.current.click()}
          onDragEnter={onDrag}
          onDragOver={onDrag}
          {...rest}
        >
          <Input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            onChange={onChange}
            sx={{ visibility: 'hidden', opacity: 0, position: 'absolute', top: 0 }}
          />
          <Flex alignItems="center" justifyContent="center" color="text.subtle">
            <Box as={UploadCloud} width="24px" height="24px" strokeWidth="1.5" aria-hidden="true" mr="2" />
            <Text color="text.subtle" fontWeight="body">
              Drag and drop or click to upload.
            </Text>
          </Flex>
          {dragActive && (
            <Box
              id="drag-file-element"
              onDragEnter={onDrag}
              onDragLeave={onDrag}
              onDragOver={onDrag}
              onDrop={onFileDrop}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            />
          )}
        </Flex>
      ) : (
        <Flex alignItems="center" py="4px">
          <Text mr="3">{fileName}</Text>
          <Text fontSize="14px" fontWeight="medium" sx={{ cursor: 'pointer' }} onClick={onRemove}>
            Remove
          </Text>
        </Flex>
      )}

      {error && <FormError>{error}</FormError>}
    </Box>
  );
};

FormCsvFile.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelExtra: PropTypes.string,
  helpText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  containerProps: PropTypes.object,
  parserOptions: PropTypes.object,
  rowLimit: PropTypes.number,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
  field: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        content: PropTypes.string,
      }),
    ),
    onChange: PropTypes.func,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    touched: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }),
  onFileLoaded: PropTypes.func,
};

FormCsvFile.defaultProps = {
  label: null,
  labelExtra: '',
  helpText: null,
  containerProps: {},
  parserOptions: {},
  rowLimit: 1000,
  fileName: '',
  setFileName: () => {},
  field: {},
  form: { errors: [] },
  onFileLoaded: null,
};
