import React, { useEffect, useState, useRef } from 'react';
import { HelpCircle } from 'lucide-react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormikTextArea, Loader, MessageBlock, Button } from '@getro/rombo';

import Modal from 'components/atoms/Modal';
import MemberCard from 'components/molecules/MemberCard';

import { get, post } from 'api';

import { getUserDisplayName } from 'helpers/users';
import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';
import { Box, Flex } from 'rebass/styled-components';
import Popover from '../../atoms/popoverDeprecated';
import { SimplifiedModal } from '../../atoms/simplifiedModal';
import MemberSchema from '../../../schemas/member';
import NetworkSchema from '../../../schemas/network';

const formSchema = Yup.object().shape({
  customMessage: Yup.string()
    // eslint-disable-next-line
    .min(60, 'Please write at least ${min} characters.')
    .required('Please let us know why you want to be introduced.'),
});

function RequestIntroModal({ member, updateIntroduction, activeNetwork, children }) {
  const { id: activeNetworkId, isAdmin } = activeNetwork;
  const limitFeatureActive = activeNetwork.features.includes('introductions_limit');
  const skillsDeactivated = activeNetwork.features.includes('skills_deactivated');
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const formRef = useRef(null);

  // Check number of introductions remaining
  const [loadingLimit, setLoadingLimit] = useState(true);
  const [limit, setLimit] = useState(null);
  const [remaining, setRemaining] = useState(null);

  const updateIntroductionsRemaining = async () => {
    try {
      const response = await get('introduction_requests/remainder', {
        params: { collectionId: activeNetworkId },
      });
      setLoadingLimit(false);
      setLimit(response.data.dailyLimit);
      setRemaining(response.data.dailyBalance);

      // Track events when user reaches the limit
      if (response.data.dailyBalance === 0) {
        trackEvent('introductions:no_introductions_available', {
          limit: response.data.dailyLimit,
          origin: 'search_members',
        });
      }
    } catch (error) {
      trackException(error);
      setLoadingLimit(false);
    }
  };

  const handleModalOpen = (isOpen) => {
    setOpen(isOpen);

    // Feature toggle for introductions limit
    if (!limitFeatureActive) {
      setLoadingLimit(false);
      return;
    }
    // End feature toggle for introductions limit

    if (isOpen) {
      // TODO: Review if we want to check this here or have the number in the user store
      updateIntroductionsRemaining();
    }
  };

  const handleCloseSuccessModal = () => {
    updateIntroduction(member.id, success);
    setOpen(false);
  };

  const handleSubmitRequest = async ({ customMessage }, { setSubmitting, setStatus }) => {
    setStatus({ error: false });
    setSuccess(false);

    try {
      await post('introduction_requests', {
        userId: member.id,
        collectionId: activeNetworkId,
        customMessage,
      });

      trackEvent('request_introduction', {
        id: member.id,
        type: 'user',
        name: getUserDisplayName(member),
        collection_id: activeNetworkId,
        origin: 'search_members',
      });

      // Update number of introductions remaining
      if (remaining === 1) {
        trackEvent('introductions:reach_daily_limit', {
          limit,
          origin: 'search_members',
        });
      }
      setRemaining(remaining - 1);

      setSubmitting(false);
      setSuccess(true);
    } catch (error) {
      trackException(error);
      setSubmitting(false);
      // TODO: show different message if error is no more intros
      // can't do it right now because BE returns always same error
      setStatus({ error: true });
    }
  };

  useEffect(() => {
    const parsedSearch = qs.parse(window.location.search, { arrayFormat: 'bracket' });
    const { requestIntroduction } = parsedSearch;
    if (parsedSearch.filters === undefined) {
      return;
    }
    const parsedFilters = JSON.parse(parsedSearch.filters);
    if (requestIntroduction !== undefined && parsedFilters.id === member.id) {
      handleModalOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  const defaultValues = {
    member: {},
    activeNetwork: {},
    children: {},
  };
  let button;
  if (children) {
    button = React.cloneElement(children, {
      onClick: () => handleModalOpen(true),
    });
  } else {
    button = (
      <Button type="button" fontSize={1} fontWeight="semibold" onClick={() => handleModalOpen(true)}>
        Request introduction
      </Button>
    );
  }

  if (loadingLimit) {
    return (
      <>
        {button}
        <Modal open={open} simple>
          <Loader />
        </Modal>
      </>
    );
  }

  if (success) {
    return (
      <>
        {button}

        <Modal open={open} simple>
          <MessageBlock
            type="success"
            title="We’ve sent your request!"
            buttonText="Ok"
            onClick={handleCloseSuccessModal}
          >
            <p>If accepted we will cc you both on an intro email.</p>
            {/* Feature toggle for introductions limit */}
            {limitFeatureActive && (
              <p className="RequestIntroModal__limit-tip">
                You can request {remaining} more introduction{remaining !== 1 && 's'} today.
              </p>
            )}
          </MessageBlock>
        </Modal>
      </>
    );
  }

  if (remaining !== null && remaining < 1) {
    return (
      <>
        {button}

        <Modal
          title="You have 0 intro requests left today."
          open={open}
          onSubmit={() => handleModalOpen(false)}
          saveText="Ok"
        >
          <>
            <p>
              We know there are many amazing people in our network and we are glad that you are eager to meet them! You
              have requested the maximum number of introductions ({limit}) today.
            </p>
            <p>
              <b>Come back tomorrow and request your introduction to {getUserDisplayName(member)}.</b>
            </p>
            <p>
              There is a limit because people’s time is valuable. We encourage you to request only the most relevant
              intros and prioritize accordingly.
            </p>
          </>
        </Modal>
      </>
    );
  }
  return (
    <SimplifiedModal
      title={`Request an introduction to ${getUserDisplayName(member)}`}
      saveText="Request introduction"
      cancelText="Cancel"
      variant="large"
      isOpen={open}
      actions={
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button
            mr={1}
            type="submit"
            fontSize={1}
            fontWeight="semibold"
            onClick={() => formRef.current?.handleSubmit()}
          >
            Request introduction
          </Button>
          <Button ml={1} fontSize={1} variant="secondary" onClick={() => handleModalOpen(false)}>
            Cancel
          </Button>
        </Flex>
      }
      onCancel={() => handleModalOpen(false)}
      content={
        <Formik
          validationSchema={formSchema}
          enableReinitialize
          initialValues={defaultValues}
          onSubmit={handleSubmitRequest}
          innerRef={formRef}
        >
          <>
            <p>We will send an email to this person sharing why you want to connect</p>
            <MemberCard member={member} isNetworkManager={isAdmin} skillsDeactivated={skillsDeactivated} />
            <FormikTextArea
              label="Why you want to be introduced*"
              name="customMessage"
              placeholder="Share why this person is stand out and should be excited about the opportunity to join your team. Be specific!"
            />
            {remaining && (
              <div className="RequestIntroModal__limit">
                <span className="RequestIntroModal__limit-number">
                  {remaining}/{limit}
                </span>
                You have {remaining} more introduction{remaining !== 1 && 's'} today.
                <Popover
                  trigger={
                    <Box
                      as={HelpCircle}
                      strokeWidth="1.5"
                      aria-hidden="true"
                      height="1em"
                      width="1em"
                      ml="2"
                      verticalAlign="middle"
                      sx={{
                        stroke: ({ colors }) => colors.neutral[400],
                      }}
                    />
                  }
                  header="Introduction daily limit"
                  content={`Each day you have the option to request ${limit} introductions in this network. Make ‘em count!`}
                />
              </div>
            )}
          </>
        </Formik>
      }
    />
  );
}

RequestIntroModal.propTypes = {
  member: MemberSchema.isRequired,
  updateIntroduction: PropTypes.func.isRequired,
  activeNetwork: NetworkSchema.isRequired,
  children: PropTypes.node,
};

RequestIntroModal.defaultProps = {
  children: null,
};

export default RequestIntroModal;
