import { useState } from 'react';
import { get } from 'api';
import { VERIFY_CONFIG, VOUCH_CONFIG } from 'components/molecules/VouchModal';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from '../../redux/selectors';
import strings from '../../strings';

const useVouchModal = (action) => {
  const [vouchOpen, setVouchOpen] = useState(false);
  const [vouchMember, setVouchMember] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchVouchError, setFetchVouchError] = useState(undefined);
  const [vouchAction, setVouchAction] = useState(action);
  const activeNetwork = useSelector(activeNetworkSelector);

  const getExtraMemberDetails = (m) => {
    const member = { ...m };
    return get(`/collections/${activeNetwork.id}/members/${m.id}/vouches`)
      .then(({ data }) => {
        if (data) {
          member.referralsReceived = data.items || [];
          setVouchMember(member);
        }
      })
      .catch(() => {
        setFetchVouchError(strings.genericError);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openVerify = (m) => {
    setVouchAction(VERIFY_CONFIG);
    setIsLoading(true);
    setVouchOpen(true);
    const member = { ...m };

    setVouchMember(member);
    getExtraMemberDetails(member);
    return member;
  };

  const openVouch = (m) => {
    setIsLoading(true);
    setVouchOpen(true);
    const member = { ...m };
    setVouchAction(VOUCH_CONFIG);
    // call the api to get the details
    getExtraMemberDetails(member);
    // open the vouch modal
    setVouchMember(member);
    return member;
  };

  return {
    fetchVouchError,
    setFetchVouchError,
    vouchMember,
    setVouchMember,
    isLoading,
    vouchOpen,
    setVouchOpen,
    vouchAction,
    openVerify,
    openVouch,
  };
};

export default useVouchModal;
