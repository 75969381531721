import strings from '../strings';
import { addProtocolToUrl } from './urlHelpers';

export const getFileNameFromUrl = (url) => {
  if (typeof url !== 'string' || !url.length) {
    return url;
  }
  const parts = url.split('path=');
  const path = decodeURIComponent(parts[parts.length - 1]);
  const pathParts = path.split('/');
  const fileName = pathParts[pathParts.length - 1];
  const firstDash = fileName.indexOf('-');
  const fileNameWithoutId = fileName.substring(firstDash + 1, fileName.length);

  return fileNameWithoutId.replace(/[^\w-.]/g, ' ');
};

export const parsePreferredJobTypes = ({ employmentTypes }) => {
  const result = [];
  if (employmentTypes) {
    employmentTypes.forEach((item) => {
      result.push(strings.profile.employment[item]);
    });
  }
  if (!result.length) {
    result.push('Unspecified');
  }
  return result.join(', ');
};

export const parseUserProfile = (user) => {
  if (user) {
    return {
      ...user,
      resumeName: getFileNameFromUrl(user.resumeUrl),
      resumeUrl: user.resumeUrl,
      mentorship: user.mentorship ? 'yes' : 'no',
      jobSearchStatus: user.jobSearchStatus || 'not_looking',
      jobSearchStage: strings.profile.jobSearchStatuses[user.jobSearchStatus || 'not_looking'],
      locations: user.locations
        ? user.locations.map((location) => ({ value: location.placeId, label: location.name }))
        : [],
      avatarUrl: user.avatarUrl,
      bio: user.bio || '',
      employmentTypes: user.employmentTypes || [],
      firstName: user.firstName || '',
      jobFunctionIds: user.jobFunctions ? user.jobFunctions.map((j) => j.id) : [],
      lastName: user.lastName || '',
      willWorkAnywhere: user.willWorkAnywhere ? 'yes' : 'no',
      remoteWork: user.remoteWork ? 'yes' : 'no',
      opportunities: user.opportunityTypes && user.opportunityTypes.includes('employment') ? 'yes' : 'no',
      seniority: strings.profile.seniorities[user.seniority || 'job_level_unspecified'],
      linkedinUrl: addProtocolToUrl(user.linkedinUrl),
    };
  }
  return {};
};

export const parsePreferredWorkLocations = ({ remoteWork, locations }) => {
  const result = [];
  if (remoteWork) {
    result.push('Remote');
  }
  if (locations) {
    locations.forEach((loc) => {
      result.push(loc.label);
    });
  }
  if (!result.length) {
    result.push('Unspecified');
  }
  return result.join(', ');
};

export const parseUsWorkStatus = ({ usWorkAuthorization, requireSponsorshipVisa }) => {
  const result = [];
  if (usWorkAuthorization) {
    result.push('Authorized');
  } else {
    result.push('Unauthorized');
  }

  if (requireSponsorshipVisa) {
    result.push('will require H1B');
  } else {
    result.push('sponsorship not required');
  }
  return result.join(', ');
};
