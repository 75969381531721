import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Box as RomboBox, FormField, Message } from '@getro/rombo';
import PropTypes from 'prop-types';
import { Text, Flex, Box } from 'rebass/styled-components';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import helperGet from '../../helpers/get';
import { loadAddCompanyByName } from './redux/actions';
import { makeSelectIsLoading } from '../../redux/loadingSelector';
import { companyDetailsSelector } from './redux/selectors';
import NetworkSchema from '../../schemas/network';

const initialValues = {
  name: '',
};

const formSchema = Yup.object().shape({
  name: Yup.string().required(),
});

const AddCompanyWithoutDomain = ({ activeNetwork, goToCompaniesList, showAddCompanyWithDomain }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const addedCompany = useSelector(companyDetailsSelector);
  const isLoading = useSelector(makeSelectIsLoading('addCompanyByName'));

  useEffect(() => {
    if (!isLoading && addedCompany) {
      history.push(`/networks/${activeNetwork.slug}/companies/${addedCompany.slug}`);
    }
  }, [activeNetwork.slug, addedCompany, history, isLoading]);

  const handleSubmit = async ({ name }) => {
    dispatch(loadAddCompanyByName({ name }));
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={formSchema}>
        {({ status, isSubmitting }) => (
          <Form>
            <Box
              sx={{
                '.Box__title': {
                  fontWeight: 600,
                },
                '.Box__footer-back-button': {
                  fontWeight: 400,
                },
              }}
            >
              <RomboBox
                className="AddCompany"
                errorText={helperGet(status, 'error', null)}
                onCancelClick={goToCompaniesList}
                loading={isSubmitting || isLoading}
                editing
                saveAsSubmit
                title="Add Company"
                saveText="Add company"
                onBackClick={showAddCompanyWithDomain}
                backText="< Back"
              >
                <Text>
                  Add a company without a website for <strong>early-stage</strong> and <strong>stealth</strong>{' '}
                  companies.
                </Text>
                <Message type="warning" my={[4]}>
                  <Flex flexDirection="column">
                    <Text fontWeight="medium" mb={[2]} fontSize={[1]}>
                      Some things you need to know about companies without a website:
                    </Text>
                    <Box as="ul" p={[0]} sx={{ listStylePosition: 'inside' }}>
                      <Text as="li">No company information will be added automatically</Text>
                      <Text as="li">All jobs must be posted</Text>
                      <Text as="li">Getro cannot pull extracted jobs if this company has them posted elsewhere</Text>
                    </Box>
                  </Flex>
                </Message>
                <Field type="text" name="name" component={FormField} label="Company name" />
              </RomboBox>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

AddCompanyWithoutDomain.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
  goToCompaniesList: PropTypes.func.isRequired,
  showAddCompanyWithDomain: PropTypes.func.isRequired,
};

export default AddCompanyWithoutDomain;
