import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import strings from 'strings';
import { put, post } from 'api';
import { trackException } from 'helpers/errorTracker';
import get from 'helpers/get';

import { setUser } from 'actions/userActions';
import { linkedinUrlRegEx } from 'helpers/regExs';

import RegisterForm from 'components/molecules/RegisterForm';

import OnboardingContext from 'components/layout/EmployerOnboarding/onboardingContext';
import VerifyYourAccount from '../VerifyYourAccount';

const formSchema = (withPassword, withDomainValidation, organizationDomain) => {
  let validations = {
    email: Yup.string().email().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    linkedinUrl: Yup.string().matches(linkedinUrlRegEx, {
      message: strings.validation.linkedinUrlError,
    }),
    termsAccepted: Yup.boolean().oneOf([true], strings.validation.termsAccepted),
  };

  if (withDomainValidation) {
    validations = {
      ...validations,
      email: Yup.string()
        .matches(new RegExp(`@${organizationDomain}$`), {
          message:
            'You must use an email with a domain associated with this company account. If you want to use a different one, contact us.',
        })
        .email()
        .required(),
    };
  }

  if (withPassword) {
    validations = {
      ...validations,
      password: Yup.string().min(8, strings.validation.passwordLength).required(),
    };
  }

  return Yup.object().shape(validations);
};

const YourAccount = ({ isManager, user }) => {
  const history = useHistory();
  const { avatarUrl, confirmed, email: mail, firstName, forcePasswordUpdate = true, id, lastName, linkedinUrl } = user;
  const [showVerificationLayout, setShowVerificationLayout] = useState(id && !confirmed);
  const [email, setEmail] = useState(mail);
  const { organization, network } = useContext(OnboardingContext);
  const isTalentNetworkActive = network.features.includes('talent_network');

  const dispatch = useDispatch();

  const initialValues = {
    avatarUrl: avatarUrl || '',
    firstName: firstName || '',
    lastName: lastName || '',
    email: mail || '',
    linkedinUrl: linkedinUrl || '',
    termsAccepted: false,
  };

  if (forcePasswordUpdate) {
    initialValues.password = '';
  }

  if (showVerificationLayout) {
    return <VerifyYourAccount email={email} organization={organization} networkId={network.id} />;
  }

  let title = strings.onboarding.jobBoard.organizationManager.header;
  let subtitle = strings.onboarding.jobBoard.organizationManager.body;
  if (isTalentNetworkActive) {
    title = strings.onboarding.talentNetwork.organizationManager.header;
    subtitle = strings.onboarding.talentNetwork.organizationManager.body;
  }

  title = title.replace('[Network Name]', network.name).replace('[Org Name]', organization.name);
  subtitle = subtitle.replace('[Network Name]', network.name).replace('[Org Name]', organization.name);

  let nextStep = () => setShowVerificationLayout(true);
  if (id) {
    nextStep = () => history.push(`/onboarding/${network.slug}/company/${organization.slug}`);
  }

  const handleSubmit = async (values, { setErrors, setSubmitting }) => {
    const { termsAccepted, ...rest } = values;

    const params = {
      ...rest,
      onboardingCompleted: true,
      termsPolicyVersion: '1.1',
      privacyPolicyVersion: '1.1',
    };

    try {
      let response;
      if (user.id) {
        response = await put('/users/me', params);
        dispatch(setUser(response.data));
      } else {
        response = await post(
          '/users',
          {
            ...params,
            claimOrgProfile: organization.id,
          },
          {
            params: { collectionId: network.id },
          },
        );
      }

      setEmail(response.data.email);

      nextStep();
    } catch (error) {
      // Generic error by default
      let errorMessage = strings.genericError;

      // Catch known errors
      if (
        get(error, 'response.status') === 422 &&
        get(error, 'response.data.error[0]') === 'Email has already been taken'
      ) {
        errorMessage = strings.account.emailAlreadyInUse;
      }

      trackException(error);
      setErrors({ email: errorMessage });
      setSubmitting(false);
    }
  };

  return (
    <div className="YourAccount">
      <RegisterForm
        callToAction="Next step >"
        formSchema={formSchema(!id || forcePasswordUpdate, !isManager, organization.domain)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        title={title}
        subtitle={subtitle}
        withPassword={!id || forcePasswordUpdate}
      />
    </div>
  );
};

YourAccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isManager: PropTypes.bool,
  user: PropTypes.shape({
    confirmed: PropTypes.bool,
    avatarUrl: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    forcePasswordUpdate: PropTypes.bool,
    id: PropTypes.number,
    lastName: PropTypes.string,
    linkedinUrl: PropTypes.string,
    privacyPolicyVersion: PropTypes.string,
    termsPolicyVersion: PropTypes.string,
  }),
};

YourAccount.defaultProps = {
  history: null,
  isManager: false,
  user: PropTypes.shape({
    confirmed: false,
    avatarUrl: '',
    email: '',
    firstName: '',
    forcePasswordUpdate: true,
    id: 0,
    lastName: '',
    linkedinUrl: '',
    privacyPolicyVersion: '',
    termsPolicyVersion: '',
  }),
};

export default YourAccount;
