import { createActions, createAction } from 'redux-actions';

export const { loadEditedJob, loadedEditedJob, errorEditedJob } = createActions(
  'LOAD_EDITED_JOB',
  'LOADED_EDITED_JOB',
  'ERROR_EDITED_JOB',
);

export const { loadSaveEditedJob, loadedSaveEditedJob, errorSaveEditedJob } = createActions(
  'LOAD_SAVE_EDITED_JOB',
  'LOADED_SAVE_EDITED_JOB',
  'ERROR_SAVE_EDITED_JOB',
);

export const { loadJobOrganizations, loadedJobOrganizations, errorJobOrganizations } = createActions(
  'LOAD_JOB_ORGANIZATIONS',
  'LOADED_JOB_ORGANIZATIONS',
  'ERROR_JOB_ORGANIZATIONS',
);

export const { loadJobLocations, loadedJobLocations, errorJobLocations } = createActions(
  'LOAD_JOB_LOCATIONS',
  'LOADED_JOB_LOCATIONS',
  'ERROR_JOB_LOCATIONS',
);

export const resetEditedJob = createAction('RESET_EDITED_JOB');
