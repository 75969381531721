import React from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/molecules/Filter';

const RemoteFilter = ({ value, onChange, embeddedInModal, onDropDownStateChange }) => {
  const options = [
    { label: 'Any', value: '' },
    { label: 'Yes', value: 'remote' },
  ];

  return (
    <Filter
      label="Remote position"
      name="remote"
      placeholder="Remote position"
      value={options.find((item) => item.value === value)}
      onChange={onChange}
      options={options}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

RemoteFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
};

RemoteFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
};

export default RemoteFilter;
