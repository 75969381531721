import { debounce } from 'debounce';
import { useMemo } from 'react';
import { useLazyGetSkillsQuery } from 'services/skills';

export const useProjectSkillsOptions = () => {
  const [loadSkills, { isFetching, data }] = useLazyGetSkillsQuery();

  const options = useMemo(
    () =>
      data?.items.map(({ id, name }) => ({
        label: name,
        value: id,
      })) ?? [],
    [data?.items],
  );

  const search = debounce((value) => {
    if (!value) return;
    loadSkills({ query: value });
  }, 500);

  return {
    loading: isFetching,
    options,
    search: (value) => search(value),
  };
};
