import React, { useEffect } from 'react';
import { Loader, Logo, ProfilePicture } from '@getro/rombo';
import isEqual from 'lodash.isequal';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import { Virtuoso } from 'react-virtuoso';
import { Switch, Route, useHistory } from 'react-router-dom';

import { ContactDetail } from 'components/organisms/contactDetail';
import { ContactCard } from '../../components/organisms/contactCard';
import { ContactsFilters } from '../../components/organisms/contactsFilters';
import { defaultFilters } from '../../components/organisms/contactsFilters/defaultFilters';
import { trackEvent } from '../../helpers/analytics';
import useContactsFilterCount from '../../hooks/useContactsFilterCount';
import { MainSkeleton, TitleSkeleton } from './skeletons';
import useSharedLists from './useSharedLists';
import HowItWorks from './howItWorks';
import AuthModal from './authModal';
import { EmptyState } from './emptyState';
import { NoResults } from './noResutls';
import { SharedListsFooter } from './sharedListsFooter';
import NoList from './noList';
import FixedHeader from './fixedHeader/fixedHeader';
import StickyHeader from './stickyHeader';

const SharedLists = () => {
  const {
    slug,
    isInitialized,
    isFetching,
    contacts,
    listInfo,
    meta,
    queryParams,
    setQueryParams,
    showAuthentication,
    onSubmitPassword,
    nextPage,
    status,
    activeNetwork,
  } = useSharedLists();
  const history = useHistory();

  const filtersCount = useContactsFilterCount(queryParams?.filters);

  useEffect(() => {
    if (isInitialized) {
      trackEvent('contacts:shared_list:view_shared_list', {
        list_slug: slug,
        collection_id: listInfo.collection.id,
        list_type: listInfo.matchingProject.preset ? 'smart' : 'static',
      });
    }
  }, [isInitialized]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLinkedinClick = () => {
    trackEvent('contacts:shared_list:view_linkedin_profile', {
      list_slug: slug,
      origin: 'shared_list_page',
      collection_id: listInfo.collection.id,
    });
  };

  const showNetworkLogo =
    ((!isInitialized && !isFetching && status === 'rejected') || showAuthentication) && activeNetwork;
  const isLoading = !isInitialized && status !== 'rejected' && !showAuthentication;

  return (
    <Box pt={['32px', '40px']} px={['16px', '32px']}>
      {isInitialized && <StickyHeader listInfo={listInfo} />}
      {isInitialized && <FixedHeader listInfo={listInfo} />}
      {!isInitialized && (
        <Flex
          justifyContent={showNetworkLogo || isLoading ? 'space-between' : 'flex-end'}
          alignItems={status === 'rejected' ? 'center' : 'flex-start'}
          flexDirection={['column-reverse', 'row']}
          sx={{ gap: '32px' }}
        >
          {!isInitialized && status !== 'rejected' && !showAuthentication && <TitleSkeleton />}
          {showNetworkLogo && (
            <Flex height="48px" alignItems="center">
              <ProfilePicture variant="square" name={activeNetwork.name} imageUrl={activeNetwork.logoUrl} />
              <Text fontSize="14px" fontWeight="500" color="text.main">
                {activeNetwork.name}
              </Text>
            </Flex>
          )}
          <Flex
            sx={{
              columnGap: '16px',
              width: ['100%', 'unset', 'unset'],
              flexDirection: ['row-reverse', 'row', 'row'],
            }}
            alignItems="baseline"
            alignContent="baseline"
          >
            <HowItWorks listInfo={listInfo} />
            <Link
              href="https://www.getro.com"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ flexGrow: [1, 'unset', 'unset'] }}
            >
              <Logo color="primary" width="96px" />
            </Link>
          </Flex>
        </Flex>
      )}
      {!showAuthentication && (
        <Flex mt="56px" flexDirection={['column', 'column', 'row']}>
          {isInitialized && (isFetching || contacts?.length > 0 || filtersCount > 0) && (
            <Box width={['100%', '100%', '264px']} mr="16px" flex="0 0 auto">
              <ContactsFilters
                sharedList={listInfo}
                trackingLocation="shared_list"
                filters={queryParams.filters}
                setFilters={(newFilters) => setQueryParams({ ...queryParams, filters: newFilters, page: 1 })}
                showListFilters={false}
              />
            </Box>
          )}
          {!isInitialized && status !== 'rejected' && (
            <Flex flex="1" height="70vh" alignItems="center" justifyContent="center">
              <Loader />
            </Flex>
          )}
          {!isInitialized && status === 'rejected' && (
            <Flex flex="1" height="70vh" alignItems="center" justifyContent="center">
              <NoList networkName={activeNetwork?.name} />
            </Flex>
          )}
          {isInitialized && (
            <>
              {isFetching && queryParams.page === 1 ? (
                <Flex flexGrow="1" maxWidth="900px">
                  <MainSkeleton />
                </Flex>
              ) : (
                <Box
                  flexGrow="1"
                  sx={{
                    borderLeft: ['none', 'none', '1px solid'],
                    borderColor: ['none', 'none', 'border.subtle'],
                    minHeight: 'calc(100dvh - 203px)',
                    pl: ['0', '0', '32px'],
                  }}
                >
                  {!!contacts?.length && (
                    <Flex alignItems="stretch" justifyContent="space-between">
                      <Text sx={{ fontSize: '12px' }}>{`Showing ${meta.queryTotal} contacts`}</Text>
                    </Flex>
                  )}
                  <Flex
                    as={Virtuoso}
                    flex="1"
                    data={contacts}
                    increaseViewportBy={400}
                    useWindowScroll
                    itemContent={(index, contact) => (
                      <ContactCard
                        index={index}
                        type="public"
                        queryParams={queryParams}
                        contact={contact}
                        notSelectable
                        page="shared_list"
                        onLinkedinClick={onLinkedinClick}
                        showDetail={() => {
                          history.push(`/shared-lists/${slug}/contacts/${contact.id}${history.location.search}`);
                        }}
                      />
                    )}
                    components={{
                      // eslint-disable-next-line react/no-multi-comp
                      Footer: () => (
                        <SharedListsFooter
                          isLoading={isFetching}
                          allContactsCount={contacts.length}
                          contactsTotal={meta?.queryTotal ?? 0}
                          filters={queryParams.filters}
                        />
                      ),
                    }}
                    endReached={() => nextPage()}
                  />
                  {!isFetching && !contacts?.length && !isEqual(defaultFilters, queryParams.filters) && <NoResults />}
                  {!isFetching && !contacts?.length && isEqual(defaultFilters, queryParams.filters) && <EmptyState />}
                </Box>
              )}
            </>
          )}
        </Flex>
      )}
      {!isInitialized && showAuthentication && (
        <AuthModal networkName={activeNetwork?.name} onEnterPassword={onSubmitPassword} />
      )}

      <Switch>
        <Route exact path="/shared-lists/:slug/contacts/:contactId">
          <ContactDetail
            onClose={() => {
              history.push(`/shared-lists/${slug}/contacts${history.location.search}`);
            }}
            type="public"
            queryParams={queryParams}
            page="shared_list"
            onLinkedinClick={onLinkedinClick}
          />
        </Route>
      </Switch>
    </Box>
  );
};

export default SharedLists;
