import React, { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Plus } from 'lucide-react';
import PropTypes from 'prop-types';
import { Input, Tag } from '@getro/rombo';
import useOnClickOutside from 'hooks/useClickOutside';
import { FilterTitle } from '../filterTitle';

const KeywordFilter = ({ selected, onEnter, title, onLoseFocus }) => {
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState('');

  const ref = useOnClickOutside(
    () => {
      setShowInput(false);

      if (showInput && onLoseFocus) {
        onLoseFocus(selected.map((item) => item));
      }
    },
    { useEsc: true },
  );

  return (
    <Flex
      ref={ref}
      sx={{
        input: {
          p: '8px',
          fontSize: '14px',
        },
        flexDirection: 'column',
      }}
    >
      <FilterTitle
        title={title}
        showClear={selected.length > 0}
        showToggle={!showInput && !selected.length > 0}
        clearFilter={() => onEnter([])}
        onToggle={() => setShowInput(!showInput)}
        data-testid="keyword-filter-title-trigger"
      />

      {((selected && selected.length > 0) || showInput) && (
        <Box pt="8px">
          {selected && selected.length > 0 && (
            <Flex sx={{ gap: '8px', flexWrap: 'wrap', mb: showInput ? '8px' : '0px' }}>
              <Flex sx={{ alignItems: 'center', gap: '4px', flexWrap: 'wrap', '& > div': { margin: '0' } }}>
                {selected.map((item) => (
                  <Tag
                    data-testid={`keyword-tag-${item}`}
                    key={item}
                    onRemove={() => {
                      onEnter(selected.filter((selectedItem) => selectedItem !== item));
                    }}
                  >
                    {item}
                  </Tag>
                ))}
                {!showInput && (
                  <Flex
                    id="keyword-filter-trigger"
                    sx={{
                      minWidth: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowInput(!showInput)}
                  >
                    <Box sx={{ color: 'neutral.300' }} size="16px" as={Plus} />
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
          {showInput && (
            <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
              <Input
                value={value}
                autoFocus
                data-testid="keyword-filter-input"
                onChange={({ target }) => setValue(target.value)}
                onKeyDown={({ target, key }) => {
                  if (key === 'Enter' && target.value.trim()) {
                    onEnter(Array.from(new Set([...selected, target.value.trim()])));
                    setValue('');
                  }
                }}
                sx={{ height: '32px' }}
                placeholder="Type and hit enter"
              />
              <Box as="p" fontSize="12px" color="text.subtle">
                Profiles containing all keywords (AND).
              </Box>
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
};

KeywordFilter.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
  onEnter: PropTypes.func,
  title: PropTypes.string,
  onLoseFocus: PropTypes.func,
};

KeywordFilter.defaultProps = {
  selected: [],
  onEnter: () => {},
  title: 'Keyword',
  onLoseFocus: null,
};

export default KeywordFilter;
