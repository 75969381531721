import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import { Plus } from 'lucide-react';

export const EditableAction = ({ action }) => (
  <Box pl="24px" sx={{ position: 'relative', cursor: 'pointer', '&&:hover [data-trigger]': { display: 'flex' } }}>
    <Box
      color="neutral.400"
      sx={{ position: 'absolute', top: '2px', left: 0 }}
      onClick={() => action.extendMethods?.onShowDetailView?.()}
    >
      <Box as={action.icon} width="16px" height="16px" aria-hidden="true" />
    </Box>
    <Box>
      <Flex alignItems="center" sx={{ '&&:hover [data-trigger="true"]': { display: 'block' } }}>
        <Text color="text.subtle" fontSize="14px" mr="4px">
          {action.label}
        </Text>
        {!!action.extendMethods?.onClickAddMore && (
          <Button
            data-trigger="true"
            display="none"
            variant="styleless"
            size="small"
            icon={Plus}
            color="primary"
            fontSize="14px"
            height="auto"
            p="0"
            onClick={action.extendMethods.onClickAddMore}
          >
            Add
          </Button>
        )}
        {!!action.DropdownComponent && (
          <action.DropdownComponent
            trigger={
              <Button
                data-trigger="true"
                display="none"
                variant="styleless"
                size="small"
                icon={Plus}
                color="primary"
                fontSize="14px"
                height="auto"
                p="0"
              >
                Add
              </Button>
            }
            value={action.items}
            onChange={action.onChange}
            onCreate={action.onCreate}
            placement="bottom-end"
            extraOptions={{
              strategy: 'absolute',
            }}
          />
        )}
      </Flex>
      {action.items?.length ? (
        action.items
          .slice()
          .map((item) => (
            <action.EditableActionItemComponent
              key={`editable-action-${item.id}-${item.label}`}
              isPublic={action.isPublic}
              item={item}
              extendMethods={action.extendMethods}
              onRemove={action.onRemove ? () => action.onRemove(item) : null}
            />
          ))
      ) : (
        <Text mt="1" fontSize="1" fontStyle="italic" color="text.subtle">
          None
        </Text>
      )}
    </Box>
  </Box>
);

EditableAction.propTypes = {
  action: PropTypes.object.isRequired,
};
