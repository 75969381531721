import humps from 'humps';

const removeEmptyProps = (obj = {}) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      result[key] = obj[key];
    }
    if (result[key] !== undefined && key === 'sortBy') {
      result[key] = humps.decamelize(result[key]);
    }
  });
  return result;
};

export default removeEmptyProps;
