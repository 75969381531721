import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'company';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const isCompanyErroredSelector = createSelector(rootSelector, ({ error }) => error);
export const isAdminAdditionErroredSelector = createSelector(rootSelector, ({ errorAddAdmin }) => errorAddAdmin);
export const companyDetailsSelector = createSelector(rootSelector, ({ details }) => details);
export const companyTopicsSelector = createSelector(companyDetailsSelector, (company) => {
  const { topics } = company;
  if (topics) {
    return topics.map((t) => ({ value: t.id, label: t.name }));
  }
  return [];
});

export const updateCompanyErrorSelector = createSelector(rootSelector, ({ errorUpdateCompany }) => errorUpdateCompany);
