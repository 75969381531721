import axios from 'axios';
import humps from 'humps';

import { trackException } from '../helpers/errorTracker';
import { getItem } from '../helpers/store';

const defaultOptions = {
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/`,
  headers: {
    Accept: 'application/json',
  },
  transformResponse: [...axios.defaults.transformResponse, (data) => humps.camelizeKeys(data)],
  transformRequest: [(data) => humps.decamelizeKeys(data), ...axios.defaults.transformRequest],
};

const authenticationHeaders = () => {
  const authToken = getItem('authToken');

  return authToken ? { 'X-User-Token': authToken } : {};
};

export const axiosBaseQuery = async ({ url, method = 'get', data, params, options = {}, network, version }) => {
  try {
    const customOptions = { ...defaultOptions, ...options };

    if (version === 'v2') {
      customOptions.baseURL = `${process.env.REACT_APP_API_V2_BASE_URL}/`;
    }

    if (network) {
      customOptions.baseURL += `collections/${network}`;
    }

    const result = await axios(url, {
      ...customOptions,
      headers: {
        ...customOptions.headers,
        ...authenticationHeaders(),
      },
      method,
      data,
      params: humps.decamelizeKeys(params),
    });

    return { data: result.data };
  } catch (error) {
    trackException(error, { url, method, data, params });

    return {
      error: {
        status: error.response?.status,
        data: error.response?.data || error.message,
      },
    };
  }
};

/**
 * Use this query as default query for new apis
 */
export const baseQuery = axiosBaseQuery;

/**
 * This query is intended for apis related to a network that usually works under
 * the path /collections/{id}/. Removes the necessity of adding the newtork id in
 * every endpoint.
 */
export const networkBaseQuery = ({ url, method = 'get', data, params, options = {}, networkId }, { getState }) => {
  const { networks } = getState();

  return axiosBaseQuery({ url, method, data, params, options, network: networkId || networks?.active });
};

export const networkBaseQueryV2 = ({ url, method = 'get', data, params, options = {}, networkId }, { getState }) => {
  const { networks } = getState();

  return axiosBaseQuery({ url, method, data, params, options, network: networkId || networks?.active, version: 'v2' });
};
