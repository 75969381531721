/* eslint-disable react/no-multi-comp */
import React from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'styled-components';

export const SidebarSkeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={200}
      height={240}
      viewBox="0 0 200 240"
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
    >
      <rect x="0" y="1" rx="4" ry="4" width="153" height="24" />
      <rect x="0" y="37" rx="3" ry="3" width="200" height="1" />
      <rect x="0" y="50" rx="4" ry="4" width="78" height="16" />
      <rect x="0" y="77" rx="4" ry="4" width="200" height="6" />
      <rect x="0" y="90" rx="4" ry="4" width="178" height="6" />
      <circle cx="189" cy="58" r="9" />
      <rect x="0" y="115" rx="4" ry="4" width="97" height="16" />
      <rect x="0" y="143" rx="4" ry="4" width="116" height="16" />
      <rect x="0" y="177" rx="0" ry="0" width="200" height="1" />
      <rect x="0" y="190" rx="4" ry="4" width="61" height="16" />
      <circle cx="189" cy="197" r="8" />
      <rect x="0" y="224" rx="4" ry="4" width="132" height="16" />
    </ContentLoader>
  );
};

export const MainSkeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={800}
      height={600}
      viewBox="0 0 800 600"
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
    >
      <rect x="0" y="0" rx="4" ry="4" width="450" height="32" />
      <rect x="0" y="44" rx="0" ry="0" width="200" height="6" />
      <rect x="103" y="95" rx="4" ry="4" width="152" height="16" />
      <rect x="461" y="0" rx="4" ry="4" width="129" height="32" />
      <circle cx="44" cy="129" r="43" />
      <rect x="104" y="121" rx="4" ry="4" width="110" height="11" />
      <rect x="106" y="145" rx="4" ry="4" width="201" height="6" />
      <rect x="108" y="159" rx="4" ry="4" width="180" height="6" />
      <rect x="103" y="214" rx="4" ry="4" width="128" height="16" />
      <circle cx="44" cy="248" r="43" />
      <rect x="104" y="240" rx="4" ry="4" width="157" height="11" />
      <rect x="106" y="264" rx="4" ry="4" width="201" height="6" />
      <rect x="108" y="278" rx="4" ry="4" width="100" height="6" />
    </ContentLoader>
  );
};

export const TableSkeleton = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={800}
      height={600}
      viewBox="0 0 800 600"
      backgroundColor={colors.neutral[50]}
      foregroundColor={colors.neutral[20]}
    >
      <circle cx="44" cy="49" r="43" />
      <rect x="104" y="20" rx="4" ry="4" width="110" height="11" />
      <rect x="106" y="44" rx="4" ry="4" width="201" height="6" />
      <rect x="108" y="58" rx="4" ry="4" width="180" height="6" />
      <circle cx="44" cy="168" r="43" />
      <rect x="103" y="132" rx="4" ry="4" width="128" height="16" />
      <rect x="104" y="160" rx="4" ry="4" width="157" height="11" />
      <rect x="106" y="184" rx="4" ry="4" width="201" height="6" />
      <rect x="108" y="198" rx="4" ry="4" width="100" height="6" />
    </ContentLoader>
  );
};
