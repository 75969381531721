import { ProfilePicture } from '@getro/rombo';
import PropTypes from 'prop-types';
import React from 'react';
import { FileText, Link as LinkIcon, Linkedin, Mail, Maximize2 } from 'lucide-react';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import pluralize from 'pluralize';

import InPortco from 'components/organisms/contactCard/inPortco';
import { addProtocolToUrl } from '../../../helpers/urlHelpers';
import { toStringLastChange } from '../../../services/contacts/postitions';

export const ContactAvatar = ({ variant, contact, type, showMaximize, trackLinkedinClick, onLinkedinClick }) => {
  const lastChange = toStringLastChange(contact.primaryActivePosition);

  const onLinkedinVisit = (event) => {
    event.stopPropagation();
    trackLinkedinClick();

    if (onLinkedinClick) {
      onLinkedinClick();
    }
  };

  return (
    <Flex
      tx="contactAvatar"
      variant={`${variant}.container`}
      justifyContent="flex-end"
      sx={{
        columnGap: '16px',
        rowGap: '8px',
      }}
    >
      <Box tx="contactAvatar" variant={`${variant}.avatarContainer`}>
        <Box
          sx={{
            position: 'relative',
          }}
          tx="contactAvatar"
          variant={`${variant}.picture`}
          flex="0 0 auto"
        >
          <ProfilePicture mb="8px" name={contact.fullName || 'Getro'} imageUrl={contact.profilePicUrl} />
        </Box>
      </Box>
      <Box pb="8px" flexGrow="1">
        <Flex alignItems="center">
          <Text
            as="h1"
            fontWeight="600"
            fontSize={['18px', '20px']}
            color="text.dark"
            lineHeight="1.6"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: '-webkit-box',
              '-webkit-line-clamp': ['2', '1'],
              '-webkit-box-orient': 'vertical',
            }}
          >
            {contact.fullName}
          </Text>
          <Flex
            className="contact-avatar-socials"
            flex="0 0 auto"
            sx={{
              visibility: !showMaximize ? 'visible' : ['visible', 'hidden'],
              alignItems: 'center',
              opacity: !showMaximize ? 1 : [1, 0],
              transition: 'visibility 0.3s linear,opacity 0.3s linear',
            }}
          >
            {contact.linkedinUrl && (
              <Flex
                as={Link}
                ml="2"
                width="32px"
                height="32px"
                bg="neutral.20"
                color="text.main"
                flex="0 0 auto"
                sx={{ borderRadius: 'default' }}
                alignItems="center"
                justifyContent="center"
                href={contact.linkedinUrl}
                rel="noopener noreferrer"
                target="_blank"
                onClick={onLinkedinVisit}
              >
                <Box as={Linkedin} height="16px" width="16px" aria-hidden="true" />
              </Flex>
            )}

            {contact.email && type !== 'public' && (
              <Flex
                as={Link}
                ml="2"
                width="32px"
                height="32px"
                bg="neutral.20"
                color="text.main"
                flex="0 0 auto"
                sx={{ borderRadius: 'default' }}
                alignItems="center"
                justifyContent="center"
                href={`mailto:${contact.email}`}
                onClick={(event) => event.stopPropagation()}
              >
                <Box as={Mail} height="16px" width="16px" aria-hidden="true" />
              </Flex>
            )}
            {contact?.resumeUrl ? (
              <Flex
                as={Link}
                ml="2"
                width="32px"
                height="32px"
                bg="neutral.20"
                color="text.main"
                flex="0 0 auto"
                sx={{ borderRadius: 'default' }}
                alignItems="center"
                justifyContent="center"
                rel="noopener noreferrer"
                target="_blank"
                href={contact.resumeUrl}
                onClick={(event) => event.stopPropagation()}
              >
                <Box as={FileText} height="16px" width="16px" aria-hidden="true" />
              </Flex>
            ) : null}
            {contact?.websiteUrl ? (
              <Flex
                as={Link}
                ml="2"
                width="32px"
                height="32px"
                bg="neutral.20"
                color="text.main"
                flex="0 0 auto"
                sx={{ borderRadius: 'default' }}
                alignItems="center"
                justifyContent="center"
                rel="noopener noreferrer"
                target="_blank"
                href={addProtocolToUrl(contact.websiteUrl)}
                onClick={(event) => event.stopPropagation()}
              >
                <Box as={LinkIcon} height="16px" width="16px" aria-hidden="true" />
              </Flex>
            ) : null}
            {showMaximize && (
              <Flex
                data-item="maximize"
                bg="neutral.20"
                alignItems="center"
                justifyContent="center"
                ml="2"
                width="32px"
                height="32px"
                sx={{
                  borderRadius: 'default',
                  cursor: 'pointer',
                }}
              >
                <Box as={Maximize2} width="16px" height="16px" aria-hidden="true" />
              </Flex>
            )}
          </Flex>
        </Flex>

        {contact.linkedinTagline && (
          <Text as="span" mr="1">
            {contact.linkedinTagline}
          </Text>
        )}
        {(contact.location || contact.yearsOfExperience) && (
          <Flex alignItems="center" flexWrap="wrap">
            {contact.location?.description && (
              <Text fontSize="1" color="text.subtle">
                {contact.location?.description}
              </Text>
            )}
            {contact.location && contact.yearsOfExperience && (
              <Box bg="text.subtle" width="2px" height="2px" mx="1" sx={{ borderRadius: 'circle' }}></Box>
            )}
            {contact.yearsOfExperience && Math.floor(contact.yearsOfExperience) > 0 && (
              <Text fontSize="1" color="text.subtle">
                {`${Math.floor(contact.yearsOfExperience)} ${pluralize(
                  'year',
                  Math.floor(contact.yearsOfExperience),
                )} of experience`}
              </Text>
            )}
            {lastChange && Math.abs(lastChange) >= 2 ? (
              <>
                <Box bg="text.subtle" width="2px" height="2px" mx="1" sx={{ borderRadius: 'circle' }}></Box>
                <Text fontSize="1" fontWeight="medium" color="success">
                  {`Last change ${Math.abs(lastChange)} ${pluralize('year', Math.abs(lastChange))} ago`}
                </Text>
              </>
            ) : null}
          </Flex>
        )}
        {contact.inPortco && <InPortco showTooltip={type === 'matches'} />}
      </Box>
    </Flex>
  );
};

ContactAvatar.propTypes = {
  contact: PropTypes.object.isRequired,
  variant: PropTypes.string,
  type: PropTypes.oneOf(['default', 'read', 'matches', 'public']),
  showMaximize: PropTypes.bool,
  trackLinkedinClick: PropTypes.func,
  onLinkedinClick: PropTypes.func,
};

ContactAvatar.defaultProps = {
  variant: 'medium',
  showMaximize: false,
  type: 'default',
  trackLinkedinClick: () => {},
  onLinkedinClick: null,
};
