import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import { Flex, Box, Text } from 'rebass/styled-components';
import { Plus } from 'lucide-react';

const BlankMessage = ({ className, title, content, children, buttonText, onClick, ...rest }) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    className={`BlankMessage ${className}`}
    data-testid="BlankMessage"
    {...rest}
  >
    {!!title.length && (
      <Text color="text.main" fontWeight="medium" lineHeight="1.2" mb={2}>
        {title}
      </Text>
    )}
    {!!content.length && <div className="BlankMessage__content" dangerouslySetInnerHTML={{ __html: content }} />}
    {children && <div className="BlankMessage__content">{children}</div>}
    {!!buttonText.length && onClick && (
      <Button mt={3} onClick={onClick} variant="secondary" size="small" data-testid="BlankMessage-button">
        <Box mr={1} height="1em" width="1em" strokeWidth="1.5" aria-hidden="true" as={Plus} />
        {buttonText}
      </Button>
    )}
  </Flex>
);

BlankMessage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

BlankMessage.defaultProps = {
  className: '',
  title: '',
  content: '',
  buttonText: '',
  onClick: () => {},
  children: null,
};

export default BlankMessage;
