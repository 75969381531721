import React, { Fragment, useRef } from 'react';
import { MoreVertical } from 'lucide-react';
import { Flex, Box, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Card } from 'components/atoms/Card';
import { Dropdown } from 'components/molecules/dropdown';
import { activeNetworkSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { RefreshContactModal } from 'components/molecules/refreshContactModal';
import { DeleteContactModal } from './deleteContactModal';
import { useContactsMoreActions } from './hooks/useContactsMoreActions';

export const ContactMoreActions = ({
  page,
  showMoreOptions,
  contact,
  queryParams,
  deleteContactFromList,
  list,
  fullContactView,
}) => {
  const ref = useRef(null);
  const activeNetwork = useSelector(activeNetworkSelector);
  const {
    openDeleteModal,
    onDeleteContact,
    options,
    closeDeleteModal,
    deleting,
    openRefreshModal,
    closeRefreshModal,
    trackRefreshEvent,
  } = useContactsMoreActions({
    contact,
    queryParams,
    networkId: activeNetwork.id,
    deleteContactFromList,
    listId: list?.id,
    page,
    fullContactView,
  });

  return (
    <Box>
      <Dropdown
        ref={ref}
        placement="right-end"
        autoPlacement
        trigger={
          <Flex
            sx={{
              background: 'none',
              height: '32px',
              width: '32px',
              cursor: 'pointer',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              svg: {
                color: 'neutral.400',
              },
              transition: 'visibility .3s ease, opacity .3s ease',
              visibility: showMoreOptions ? 'visible' : 'hidden',
              opacity: showMoreOptions ? 1 : 0,
            }}
            id="contact-more-dropdown-action"
            data-testid="contact-more-dropdown-action"
          >
            <Box as={MoreVertical} size="16px" />
          </Flex>
        }
      >
        <Card sx={{ cursor: 'pointer', width: '228px', gap: '4px' }} onClick={(event) => event.stopPropagation()}>
          {options.map((option) => {
            if (option.text === 'divider') {
              return <Box key={`divider-${option.text}`} as="hr" variant="hr" />;
            }

            return (
              <Fragment key={option.text}>
                {!option.useTooltip && (
                  <Flex
                    key={option.text}
                    onClick={(e) => {
                      e.stopPropagation();
                      option.onClick();
                      ref.current.closeDropdown();
                    }}
                    data-testid={option.text}
                    alignItems="center"
                    sx={{
                      gap: '8px',
                      color: 'text.main',
                      py: '4px',
                      '&:hover': {
                        color: 'text.dark',
                      },
                    }}
                  >
                    <Box as={option.icon} size="16px" />
                    <Text color="text.main" fontSize="14px" fontWeight="500" lineHeight="24px">
                      {option.text}
                    </Text>
                  </Flex>
                )}
              </Fragment>
            );
          })}
        </Card>
      </Dropdown>
      {openDeleteModal && (
        <DeleteContactModal onCloseModal={closeDeleteModal} onDeleteContact={onDeleteContact} deleting={deleting} />
      )}
      {openRefreshModal && (
        <RefreshContactModal
          trackRefreshEvent={trackRefreshEvent}
          linkedinUrl={contact?.linkedinUrl}
          onCloseModal={closeRefreshModal}
        />
      )}
    </Box>
  );
};

ContactMoreActions.propTypes = {
  page: PropTypes.string,
  showMoreOptions: PropTypes.bool,
  contact: PropTypes.object.isRequired,
  queryParams: PropTypes.object.isRequired,
  deleteContactFromList: PropTypes.bool.isRequired,
  list: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  fullContactView: PropTypes.bool,
};

ContactMoreActions.defaultProps = {
  page: '',
  showMoreOptions: false,
  list: null,
  fullContactView: false,
};
