import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import strings from 'strings';

import { Loader, Box } from '@getro/rombo';

import Chart from 'components/atoms/Chart';

import { FiltersContext } from 'pages/Analytics/context';
import { METRICS } from 'pages/Analytics/filtersOptions';

function ChartView({ data, fetching }) {
  const { filters } = useContext(FiltersContext);
  const metric = METRICS.find((m) => m.value === filters.metric.value);

  if (metric.hasChartView === false) {
    return null;
  }

  const getLabels = (elements) => {
    const keys = Object.keys(elements[0]);
    return elements.map((row) => row[keys[0]]);
  };

  const getDatasets = (elements) => {
    const keys = Object.keys(elements[0]).filter((k) => k !== 'date' && k !== 'change');
    return keys.map((key) => ({
      label: strings.analytics.displayData[key] || key,
      data: elements.map((row) => row[key] || 0),
    }));
  };

  // Rendering
  if (fetching) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  if (!fetching && (!data || !data.length)) {
    return <Box data-cy="analytics-no-chart-data">No data for selected filters.</Box>;
  }

  return (
    <Box>
      <Chart labels={getLabels(data)} datasets={getDatasets(data)} />
    </Box>
  );
}

ChartView.propTypes = {
  data: PropTypes.array,
  fetching: PropTypes.bool,
};

ChartView.defaultProps = {
  data: [],
  fetching: false,
};

export default ChartView;
