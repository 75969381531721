import { createApi } from '@reduxjs/toolkit/query/react';

import { networkBaseQuery } from './queries';
import { networkV2Api } from './networkV2';

export const networkApi = createApi({
  reducerPath: 'networkApi',
  baseQuery: networkBaseQuery,
  tagTypes: ['network'],
  endpoints: (builder) => ({
    updateJobBoardSettings: builder.mutation({
      query: (values) => ({
        url: '/settings/job_board',
        method: 'put',
        data: values,
      }),
    }),
    getJobBoardSettings: builder.query({
      query: () => ({
        url: '/settings/job_board',
      }),
    }),
    getGeneralSettings: builder.query({
      query: () => ({
        url: '/settings/general',
      }),
      providesTags: ['network-settings-general'],
    }),
    updateGeneralSettings: builder.mutation({
      query: (values) => ({
        url: '/settings/general',
        method: 'put',
        data: { ...values, administrators: undefined, ambassadors: undefined },
      }),
      invalidatesTags: ['network-settings-general'],
    }),
    getTalentNetworkSettings: builder.query({
      query: () => ({
        url: '/settings/talent_network',
      }),
      providesTags: ['talent-network-settings', 'network-job-board-v2'],
    }),
    updateTalentNetwork: builder.mutation({
      query: (data) => ({
        url: `/settings/talent_network`,
        method: 'put',
        data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(
          networkApi.util.updateQueryData('getTalentNetworkSettings', undefined, (draft) => {
            Object.assign(draft, arg);
          }),
        );
        await queryFulfilled;
        dispatch(
          networkV2Api.util.updateQueryData('getJobBoardSettings', undefined, (draft) => {
            if (typeof arg.tnHidden === 'boolean') {
              Object.assign(draft, {
                tnHidden: arg.tnHidden,
              });
            }
          }),
        );
      },
    }),
  }),
});

export const {
  useUpdateJobBoardSettingsMutation,
  useGetJobBoardSettingsQuery,
  useGetGeneralSettingsQuery,
  useUpdateGeneralSettingsMutation,
  useUpdateTalentNetworkMutation,
  useGetTalentNetworkSettingsQuery,
} = networkApi;
