import { Button, FormInput, FormTextarea } from '@getro/rombo';

import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import SideModal from 'components/atoms/sideModal';
import { AiMatches } from './aiMatches';
import { AutoUpdate } from './autoUpdate';
import { SmartOptions } from './components/smartOptions';
import { useCreateList } from './hooks/useCreateList';
import { UpdateListSkeleton } from './components/updateListSkeleton';

export const CreateList = ({ goBack: close }) => {
  const {
    formSchema,
    formRef,
    isSubmitting,
    match,
    goBack,
    setSmartOption,
    smartOption,
    title,
    onMatchSelected,
    onSubmit,
    activeNetwork,
    filters,
    setFilters,
    setMatchType,
    matchType,
    isEdit,
    updateSmartOption,
    formDefaultValues,
    contactsCount,
    isUpdatingList,
    matchId,
    hasAutoUpdateFilters,
    isLoadingList,
  } = useCreateList();

  const history = useHistory();
  return (
    <SideModal
      isLoading={isLoadingList}
      loadingComponent={UpdateListSkeleton}
      headerText={title}
      goBack={goBack}
      width="680px"
      sx={{
        ...(smartOption === 'matches' ? { px: 0, py: 0 } : {}),
        '& > div:first-child': {
          ...(smartOption === 'matches' ? { top: '24px' } : {}),
        },
      }}
      headerRebassProps={{
        ...(smartOption === 'matches' ? { px: ['16px', '40px'], pt: '24px' } : {}),
      }}
      onClose={() => {
        if (close) {
          close();
          return;
        }

        if (isEdit) {
          history.goBack();
          return;
        }
        history.push('.');
      }}
    >
      <Box>
        {smartOption === 'matches' && (
          <AiMatches
            onMatchSelected={onMatchSelected}
            setMatchType={setMatchType}
            matchType={matchType}
            isUpdatingList={isUpdatingList}
            isEdit={isEdit}
            matchId={matchId}
            setSmartOption={setSmartOption}
          />
        )}
        {smartOption === 'autoUpdate' && (
          <AutoUpdate
            contactsCount={contactsCount}
            onMatchSelected={onMatchSelected}
            isTalentNetworkEnabled={!activeNetwork?.tnHidden}
            setFilters={setFilters}
            filters={filters}
            isUpdatingList={isUpdatingList}
            isEdit={isEdit}
          />
        )}
        {!smartOption && (
          <Formik
            innerRef={formRef}
            initialValues={formDefaultValues}
            validationSchema={formSchema}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            <Box
              as={Form}
              width="100%"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Field
                autoFocus
                component={FormInput}
                name="name"
                label="Name"
                labelExtra="Required"
                sx={{
                  fontSize: '14px',
                }}
              />
              <Field
                component={FormTextarea}
                name="description"
                label="Description"
                helpText="Add your list's purpose for your team and those it’s shared with."
                sx={{
                  fontSize: '14px',
                }}
              />
              {isEdit && (
                <Flex justifyContent="flex-start">
                  <Button type="submit" loading={isUpdatingList} size="small">
                    Update
                  </Button>
                </Flex>
              )}
              <Box
                sx={{
                  width: '100%',
                  my: '24px',
                  height: '1px',
                  borderBottom: '1px solid',
                  borderColor: 'border.subtle',
                }}
              />
              <SmartOptions
                match={match}
                updateSmartOption={updateSmartOption}
                onEnableMatch={(type) => {
                  setSmartOption(type === 'matches' ? 'matches' : 'autoUpdate');
                }}
                filters={filters}
                contactsCount={contactsCount}
                isEdit={isEdit}
                hasAutoUpdateFilters={hasAutoUpdateFilters}
              />
              {!isEdit && (
                <Box
                  sx={{
                    width: '100%',
                    mt: '24px',
                    height: '0px',
                    borderBottom: '1px solid',
                    borderColor: 'border.subtle',
                  }}
                />
              )}
              {!isEdit && (
                <Flex justifyContent="flex-start">
                  <Button type="submit" loading={isSubmitting} size="small">
                    Create list
                  </Button>
                </Flex>
              )}
            </Box>
          </Formik>
        )}
      </Box>
    </SideModal>
  );
};

CreateList.propTypes = {
  goBack: PropTypes.func,
};
CreateList.defaultProps = {
  goBack: null,
};
