import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';

export const TableActions = ({ queryTotal, selectedRows, setSelectedRows }) => {
  const totalSelectedRowsCount = selectedRows.length;

  const onChangeSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(Array.from(Array(queryTotal).keys()));
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <Flex mb="3" sx={{ borderBottom: 1, borderBottomColor: 'neutral.100' }}>
      <Box sx={{ position: 'relative' }}>
        <Flex mr="3" alignItems="center" height="52px">
          <Flex alignItems="center">
            <Checkbox
              name="bulkSelect"
              checked={selectedRows.length === queryTotal && selectedRows.length > 0}
              onChange={onChangeSelectAll}
            />
            <Text fontSize="1">
              {totalSelectedRowsCount === 0 ? 'Select all' : `${totalSelectedRowsCount} selected`}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

TableActions.propTypes = {
  queryTotal: PropTypes.number,
  selectedRows: PropTypes.array.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
};

TableActions.defaultProps = {
  queryTotal: 0,
};
