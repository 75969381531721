/* eslint-disable react/no-multi-comp */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass/styled-components';
import { DataTable, ProfilePicture, Button } from '@getro/rombo';
import { Check, EyeOff, Plus } from 'lucide-react';
import { getCompaniesColumns } from './companyColumns';
import EmptyView from './emptyView';
import { LoadMore } from '../CompaniesTable/loadMore';

const SuggestCompaniesTable = ({
  companies,
  total,
  loading,
  loadMore,
  hasInitialized,
  onSkip,
  onAdd,
  hasInitializedError,
  addingIds,
  skippingIds,
}) => {
  const [companiesRow, setCompaniesRow] = useState([]);
  const companiesColumns = useMemo(() => getCompaniesColumns(), []);

  useEffect(() => {
    setCompaniesRow(
      companies.map((company) => ({
        id: company.id,
        slug: company.slug,
        name: (
          <Flex sx={{ gap: '8px' }} alignItems="center" my={[2]} justifyContent="flex-start">
            <ProfilePicture
              width="40px"
              height="40px"
              imageUrl={company.logoUrl}
              name={company.name}
              variant="square"
            />
            <Text color="text.dark" fontWeight="400" fontSize="14px" fontHeight="16px">
              {company.name}
            </Text>
          </Flex>
        ),
        website: (
          <Flex fontSize={[1]} color="text.main" my={2}>
            {company.domain}
          </Flex>
        ),
        options: (
          <Flex sx={{ gap: '8px' }} width="100%" pr="16px" justifyContent="flex-end" fontSize={[1]} my={[2]}>
            {company.status === 'published' && (
              <Button icon={Check} height="32px" sx={{ cursor: 'pointer', color: 'text.subtle' }} variant="styleless">
                Company added
              </Button>
            )}
            {company.status === 'skipped' && (
              <Button icon={EyeOff} height="32px" sx={{ cursor: 'pointer', color: 'text.subtle' }} variant="styleless">
                Company skipped
              </Button>
            )}
            {company.status === 'active' && (
              <>
                <Button
                  loading={skippingIds.includes(company.id)}
                  height="32px"
                  onClick={() => onSkip(company)}
                  sx={{ cursor: 'pointer' }}
                  variant="styleless"
                >
                  Skip
                </Button>
                <Button
                  height="32px"
                  loading={addingIds.includes(company.id)}
                  variant="accent"
                  onClick={() => onAdd(company.id)}
                  icon={Plus}
                >
                  Add
                </Button>
              </>
            )}
          </Flex>
        ),
      })),
    );
  }, [addingIds, companies, onAdd, onSkip, skippingIds]);

  if ((hasInitialized && !loading && !companies.length) || hasInitializedError) {
    return <EmptyView />;
  }

  return (
    <Flex maxWidth="800px" width="100%" flexDirection="column" flexGrow="1" alignItems="center">
      <DataTable
        sx={{
          pb: 3,
          height: ['600px', '600px', '100%'],
          width: ['800px!important', '800px!important', '800px!important'],
          flexGrow: 1,
        }}
        virtuosoProps={{
          ...(process.env.NODE_ENV === 'test' ? { initialItemCount: companies.length } : {}),
          useWindowScroll: true,
          components: {
            // eslint-disable-next-line react/no-multi-comp
            Footer: () => <LoadMore hasMore={loadMore} isLoadingMore={hasInitialized && loading} />,
          },
        }}
        loadMore={loadMore}
        items={companiesRow}
        columnDefinition={companiesColumns}
        hasMore={total > companies.length}
        isInitialized={hasInitialized}
        rowProps={() => ({
          sx: {
            textDecoration: 'none!important',
            minHeight: '40px',
            py: '8px',
            transition: 'background-color ease 0.3s',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'neutral.20',
            },
            '& > div': {
              '& > div': {
                minHeight: '40px',
                py: '0px',

                '& > div': {
                  minHeight: '40px',
                  margin: 0,
                },
              },

              '& > div > div': {
                display: 'flex',
                alignItems: 'center',
              },
            },
          },
        })}
      />
    </Flex>
  );
};

SuggestCompaniesTable.propTypes = {
  companies: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  hasInitialized: PropTypes.bool.isRequired,
  hasInitializedError: PropTypes.bool.isRequired,
  addingIds: PropTypes.array.isRequired,
  skippingIds: PropTypes.array.isRequired,
};

export default SuggestCompaniesTable;
