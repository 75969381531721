import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import PageLayout from 'components/layout/Page';
import memberRoutes from 'router/membersRoutes';
import { activeNetworkSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import UploadCSV from './UploadCSV';
import ConfirmImport from './ConfirmImport';
import Request from './Request';

const BulkMembersInvite = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const [step, setStep] = useState(1);
  const [records, setRecords] = useState([]);

  const navigateBack = () => setStep(step - 1);
  const navigateForward = () => setStep(step + 1);

  if (step === 0 || step === 4) {
    return <Redirect to={`/networks/${activeNetwork.slug}${memberRoutes.path}`} />;
  }

  return (
    <PageLayout>
      <PageLayout.Content>
        {step === 1 && (
          <UploadCSV
            navigateBack={navigateBack}
            navigateForward={navigateForward}
            records={records}
            setRecords={setRecords}
          />
        )}
        {step === 2 && (
          <ConfirmImport
            activeNetworkName={activeNetwork.name}
            navigateBack={navigateBack}
            navigateForward={navigateForward}
            records={records}
            setRecords={setRecords}
          />
        )}
        {step === 3 && <Request activeNetwork={activeNetwork} navigateForward={navigateForward} records={records} />}
      </PageLayout.Content>
    </PageLayout>
  );
};

export default BulkMembersInvite;
