import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  loadCompany,
  loadedCompany,
  errorCompany,
  loadAddAdmin,
  loadedAddAdmin,
  errorAddAdmin,
  loadRemoveAdmin,
  loadedRemoveAdmin,
  errorRemoveAdmin,
  loadedResendAdminInvite,
  loadResendAdminInvite,
  loadedUpdateCompany,
  loadUpdateCompany,
  errorUpdateCompany,
  resetCompanyError,
} from './actions';
import { removeUser } from '../../actions/userActions';
import strings from '../../strings';
import get from '../../helpers/get';

export const initialState = {
  errorAddAdmin: false,
  errorRemoveAdmin: false,
  errorResendAdminInvite: false,
  errorUpdateCompany: false,
  error: false,
  details: {},
};

/* eslint-disable default-case, no-param-reassign */
const companyReducer = handleActions(
  {
    [loadCompany]: produce((draft) => {
      draft.error = false;
      draft.details = {};
      return draft;
    }),
    [loadedCompany]: produce((draft, action) => {
      const { payload } = action;
      draft.error = false;
      draft.details = payload;
      return draft;
    }),
    [errorCompany]: produce((draft, payload) => {
      draft.details = {};
      if (payload === parseInt(payload, 10)) {
        draft.error = payload;
      } else {
        draft.error = true;
      }
      return draft;
    }),
    [loadUpdateCompany]: produce((draft) => {
      draft.errorUpdateCompany = false;
      return draft;
    }),
    [loadedUpdateCompany]: produce((draft, action) => {
      const { payload } = action;
      draft.details = payload;
      draft.errorUpdateCompany = false;
      return draft;
    }),
    [errorUpdateCompany]: produce((draft, action) => {
      const { payload } = action;
      const { error } = payload;

      const domainTaken = get(error, 'response.data.errors [0]') === strings.companyDomainTakenError;
      const status = get(error, 'response.status');

      if (status === 403) {
        draft.errorUpdateCompany = strings.genericAuthError;
      } else if (domainTaken) {
        draft.errorUpdateCompany = strings.companyProfile.domainTaken;
      } else {
        draft.errorUpdateCompany = strings.genericError;
      }

      return draft;
    }),
    [loadAddAdmin]: produce((draft) => {
      draft.errorAddAdmin = false;
      return draft;
    }),
    [loadedAddAdmin]: produce((draft, action) => {
      const { payload } = action;
      const { admin } = payload;
      draft.details.managers.push(admin);
      return draft;
    }),
    [errorAddAdmin]: produce((draft) => {
      draft.errorAddAdmin = true;
      return draft;
    }),
    [loadRemoveAdmin]: produce((draft) => {
      draft.errorRemoveAdmin = false;
      return draft;
    }),
    [loadedRemoveAdmin]: produce((draft, action) => {
      const { payload } = action;
      const { admin } = payload;
      draft.details = {
        ...draft.details,
        managers: draft.details.managers.filter((m) => m.id !== admin.id),
      };
      return draft;
    }),
    [errorRemoveAdmin]: produce((draft) => {
      draft.errorRemoveAdmin = true;
      return draft;
    }),
    [loadResendAdminInvite]: produce((draft) => {
      draft.errorResendAdminInvite = false;
      return draft;
    }),
    [loadedResendAdminInvite]: produce((draft, action) => {
      const { payload } = action;
      const { adminId } = payload;
      draft.details = {
        ...draft.details,
        managers: draft.details.managers.map((m) => (m.id !== adminId ? m : { ...m, invitationSent: true })),
      };
      return draft;
    }),
    [removeUser]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [resetCompanyError]: produce((draft) => {
      draft = {
        ...initialState,
        details: draft.details,
      };
      return draft;
    }),
  },
  initialState,
);

export default companyReducer;
