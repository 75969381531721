import { trackException } from 'helpers/errorTracker';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import APIError from 'api/APIError';
import { activeNetworkSelector } from 'redux/selectors';
import { get } from 'api';
import {
  errorAllJobAlertSubscribers,
  errorJobAlertSubscribers,
  errorJobsApplicants,
  errorJobsClicks,
  errorJobsLikelyApplied,
  errorSuggestedCompaniesCount,
  errorUniqueVisitors,
  loadAllJobAlertSubscribers,
  loadedAllJobAlertSubscribers,
  loadedJobAlertSubscribers,
  loadedJobsApplicants,
  loadedJobsClicks,
  loadedJobsLikelyApplied,
  loadedSuggestedCompaniesCount,
  loadedUniqueVisitors,
  loadJobAlertSubscribers,
  loadJobsApplicants,
  loadJobsClicks,
  loadJobsLikelyApplied,
  loadSuggestedCompaniesCount,
  loadUniqueVisitors,
} from './actions';

function* handleUniqueVisitorsEvent({ payload: { startDate, endDate } }) {
  const { id: collectionId } = yield select(activeNetworkSelector);

  try {
    const response = yield call(get, `/collections/${collectionId}/analytics/unique_visitors`, {
      params: {
        startDate,
        endDate,
      },
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });
    yield put(loadedUniqueVisitors(response.data));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }

    yield put(errorUniqueVisitors());
  }
}

function* handleJobClickEvent({ payload: { startDate, endDate } }) {
  const { id: collectionId } = yield select(activeNetworkSelector);

  try {
    const response = yield call(get, `/collections/${collectionId}/analytics/jobs_clicks`, {
      params: {
        startDate,
        endDate,
      },
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });
    yield put(loadedJobsClicks(response.data));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }

    yield put(errorJobsClicks());
  }
}

function* handleJobAlertSubscribersEvent({ payload: { startDate, endDate } }) {
  const { id: collectionId } = yield select(activeNetworkSelector);

  try {
    const response = yield call(get, `/collections/${collectionId}/analytics/job_alert_subscribers`, {
      params: {
        startDate,
        endDate,
      },
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });

    yield put(loadedJobAlertSubscribers(response.data));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }

    yield put(errorJobAlertSubscribers());
  }
}

function* handleAllJobAlertSubscribersEvent() {
  const { id: collectionId } = yield select(activeNetworkSelector);

  try {
    const response = yield call(get, `/collections/${collectionId}/analytics/job_alert_subscribers`, {
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });

    yield put(loadedAllJobAlertSubscribers(response.data));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }

    yield put(errorAllJobAlertSubscribers());
  }
}

function* loadSuggestedCompaniesCountEvent() {
  const activeNetwork = yield select(activeNetworkSelector);

  try {
    const response = yield call(get, `/collections/${activeNetwork.id}/organizations`, {
      params: {
        status: 'suggested',
        perPage: 1,
        page: 1,
      },
    });
    const { data } = response;

    yield put(loadedSuggestedCompaniesCount(data.meta));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }
    yield put(errorSuggestedCompaniesCount());
  }
}

function* handleJobsLikelyAppliedEvent({ payload: { startDate, endDate } }) {
  const { id: collectionId } = yield select(activeNetworkSelector);

  try {
    const response = yield call(get, `/collections/${collectionId}/analytics/jobs_likely_applied`, {
      params: {
        startDate,
        endDate,
      },
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });
    yield put(loadedJobsLikelyApplied(response.data));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }

    yield put(errorJobsLikelyApplied());
  }
}

function* handleJobsApplicantsEvent({ payload: { startDate, endDate } }) {
  const { id: collectionId } = yield select(activeNetworkSelector);

  try {
    const response = yield call(get, `/collections/${collectionId}/analytics/applicants`, {
      params: {
        startDate,
        endDate,
      },
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });
    yield put(loadedJobsApplicants(response.data));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }

    yield put(errorJobsApplicants());
  }
}

export default function* saga() {
  yield takeLatest(loadUniqueVisitors().type, handleUniqueVisitorsEvent);
  yield takeLatest(loadJobsClicks().type, handleJobClickEvent);
  yield takeLatest(loadJobAlertSubscribers().type, handleJobAlertSubscribersEvent);
  yield takeLatest(loadSuggestedCompaniesCount().type, loadSuggestedCompaniesCountEvent);
  yield takeLatest(loadAllJobAlertSubscribers().type, handleAllJobAlertSubscribersEvent);
  yield takeLatest(loadJobsLikelyApplied().type, handleJobsLikelyAppliedEvent);
  yield takeLatest(loadJobsApplicants().type, handleJobsApplicantsEvent);
}
