import React from 'react';
import { MessageBlock, Modal } from '@getro/rombo';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { closeJobApplicationDownloadModal } from 'hooks/useJobActions/actions';
import { jobDownloadMessageSelector } from 'hooks/useJobActions/selectors';

const DownloadApplicants = ({ isOpen }) => {
  const dispatch = useDispatch();
  const handleCancel = () => dispatch(closeJobApplicationDownloadModal());
  const description = useSelector(jobDownloadMessageSelector);

  return (
    <Modal sx={{ button: { display: 'none' } }} isOpen={isOpen} onCancel={handleCancel}>
      <MessageBlock type="success" title="A data export is coming your way" buttonText="Ok" onClick={handleCancel}>
        {description}
      </MessageBlock>
    </Modal>
  );
};

DownloadApplicants.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default DownloadApplicants;
