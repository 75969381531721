import React from 'react';
import { Flex, Image, Link, Text } from 'rebass/styled-components';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import IconHandEmptyIcon from '../../assets/icon-hand-empty.svg';
import { activeNetworkSelector } from '../../redux/selectors';

export const SharedListEmptyState = () => {
  const activeNetwork = useSelector(activeNetworkSelector);

  return (
    <Flex mt="40px" flexDirection="column" alignItems="center" maxWidth="520px" mx="auto" textAlign="center">
      <Image src={IconHandEmptyIcon} width="64px" maxWidth="64px" />
      <Text mt="16px" fontWeight="semibold">
        Your new list is ready
      </Text>
      <Text as="span" mt="8px" color="text.subtle">
        Start adding contacts from{' '}
        <Link as={NavLink} to={`/networks/${activeNetwork.slug}/contacts`} sx={{ color: 'text.subtle' }}>
          All contacts
        </Link>{' '}
        or other lists.
      </Text>
    </Flex>
  );
};
