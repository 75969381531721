import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ExternalLink, Settings2 } from 'lucide-react';
import { Virtuoso } from 'react-virtuoso';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { useParams, Switch, Route, useHistory } from 'react-router-dom';

import { useGetSharedListMatchesQuery } from 'services/contacts';
import { Button, Modal } from '@getro/rombo';
import { PrivateList } from 'pages/listCreate/privateList';
import { useLazyGetMatchStatusQuery } from 'services/matches';
import { useFetchContacts } from 'pages/contactsExtended/hooks/useFetchContacts';
import { ContactCard } from '../../../components/organisms/contactCard';
import { addProtocolToUrl } from '../../../helpers/urlHelpers';
import usePageHook from '../../../hooks/usePageHook';
import { activeNetworkSelector } from '../../../redux/selectors';
import { CreateList } from '../../list/createList';
import { MatchesNoResults } from './noResults';
import JobDetail from './jobDetail';
import { ContentSkeleton } from './skeletons/content';
import { AiContactDetail } from './aiContactDetail';

export const AiMatches = ({ listName }) => {
  const { listId } = useParams();
  const { data, isLoading, refetch } = useGetSharedListMatchesQuery({ listId });
  const [getMatchStatus] = useLazyGetMatchStatusQuery();
  const activeNetwork = useSelector(activeNetworkSelector);
  const history = useHistory();
  const pollingRef = useRef(null);
  const { data: allContactsData, queryParams } = useFetchContacts(true);

  useEffect(() => {
    if (!isLoading) {
      window?.CommandBar?.trackEvent('matches_search_loaded', {});
    }
  }, [isLoading]);

  usePageHook(listName);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const cleanup = () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };

    if (!data?.matchingProject || data?.matchingProject?.status === 'active') {
      cleanup();

      return () => {};
    }

    pollingRef.current = setInterval(() => {
      getMatchStatus({
        matchListId: data?.matchingProject.id,
      }).then((res) => {
        if (res?.data?.status === 'active') {
          refetch();
          cleanup();
        }
      });
    }, 3000);

    return cleanup;
  }, [data?.matchingProject, getMatchStatus, listId, refetch]);

  const matches = data?.matchingProject?.matches ?? [];

  const matchesCount = matches.length;
  const locations = data?.matchingProject?.job?.locations ?? [];
  const skills = data?.matchingProject?.job?.skills ?? [];

  return (
    <Box>
      <Box>
        <Flex flexDirection={['column', 'column', 'row']} sx={{ gap: '40px' }}>
          <Box width="280px">
            <JobDetail
              locations={locations}
              skills={skills}
              isLoading={isLoading}
              isPrivate={data?.matchingProject?.isPrivate}
              organization={data?.matchingProject?.organization}
              job={data?.matchingProject?.job}
            />
            {!isLoading && (
              <Box mt="24px">
                {data?.matchingProject?.job?.url && (
                  <Link
                    mt="16px"
                    display="flex"
                    alignItems="center"
                    color="text.main"
                    href={
                      data.matchingProject.job.description
                        ? addProtocolToUrl(
                            `${activeNetwork.url}/companies/${data.matchingProject.organization?.slug}/jobs/${data.matchingProject.job.slug}#content`,
                          )
                        : addProtocolToUrl(data.matchingProject.job.url)
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ textDecoration: 'none' }}
                  >
                    <Text fontSize="14px" fontWeight="medium">
                      See job post
                    </Text>
                    <Box ml="8px" as={ExternalLink} width="16px" height="16px" aria-hidden="true" />
                  </Link>
                )}
              </Box>
            )}

            {!isLoading && matches.length > 0 && (
              <Flex
                sx={{
                  mt: '32px',
                  gap: '4px',
                  flexDirection: 'column',
                  fontSize: '12px',
                  borderTop: 1,
                  borderTopColor: 'border.subtle',
                }}
              >
                <Text>
                  {Number(data?.matchingProject?.contactsCount || 0).toLocaleString()}{' '}
                  {pluralize('contacts', data?.matchingProject?.contactsCount)} analyzed
                </Text>
                <Text>
                  {matchesCount} {pluralize('matches', matchesCount)} found
                </Text>
              </Flex>
            )}
            <Button
              mt="16px"
              icon={Settings2}
              variant="secondary"
              size="small"
              onClick={() => history.push(`/networks/${activeNetwork.slug}/list/${listId}/ai-matches/lists/settings`)}
            >
              AI matches settings
            </Button>
          </Box>
          <Box flex="1">
            {isLoading ? (
              <ContentSkeleton />
            ) : (
              <>
                {matches.length > 0 ? (
                  <Box flex="1">
                    <Text data-testid="matches-count" fontSize="12px">{`Showing ${matchesCount} ${pluralize(
                      'match',
                      matchesCount,
                    )}`}</Text>
                    <Flex
                      as={Virtuoso}
                      flex="1"
                      data={matches}
                      increaseViewportBy={400}
                      useWindowScroll
                      itemContent={(index, match) => (
                        <ContactCard
                          index={index}
                          contact={match.contact}
                          type="matches"
                          page="list:matches"
                          notSelectable
                          matchListInfo={{
                            id: data.matchingProject?.id,
                            job: data.matchingProject?.job || {},
                            organization: data.matchingProject?.organization,
                            name: data?.name,
                            listId,
                          }}
                          mb="0px"
                          showAddToLisButton
                          addedToSharedList={match.addedToSharedList}
                          list={{
                            id: listId,
                            name: data?.name,
                          }}
                          showDetail={() => {
                            history.push(
                              `/networks/${activeNetwork.slug}/list/${listId}/ai-matches/${match.contact.id}`,
                            );
                          }}
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <Box flex="1">
                    <MatchesNoResults
                      status={data?.matchingProject?.status}
                      contactCount={allContactsData?.meta?.contactsTotal}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Flex>
      </Box>
      <Switch>
        <Route exact path={`/networks/${activeNetwork.slug}/list/:listId/ai-matches/lists/settings`}>
          <CreateList
            goBack={() => {
              history.push(`/networks/${activeNetwork.slug}/list/${listId}/ai-matches${history.location.search}`);
            }}
          />
        </Route>
        <Route path={`/networks/${activeNetwork.slug}/list/:listId/ai-matches/:matchListId/edit`}>
          <Modal
            title="Edit private job details"
            isOpen
            variant="large"
            onCancel={() => history.push(`/networks/${activeNetwork.slug}/list/${listId}/ai-matches`)}
          >
            <PrivateList edit />
          </Modal>
        </Route>
        <Route path={[`/networks/${activeNetwork.slug}/list/:listId/ai-matches/:contactId`]}>
          <AiContactDetail queryParams={queryParams} data={data} isLoading={isLoading} />
        </Route>
      </Switch>
    </Box>
  );
};

AiMatches.propTypes = {
  listName: PropTypes.string,
};

AiMatches.defaultProps = {
  listName: null,
};
