import PropTypes from 'prop-types';

const OptionsSchema = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
);

export default OptionsSchema;
