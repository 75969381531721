import { createSelector } from 'reselect';
import { initialState } from './reducer';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const key = 'topics';

export const topicsSelector = createSelector(rootSelector, (state) => state.topics);
