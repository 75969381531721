import PropTypes from 'prop-types';
import React from 'react';
import { Check } from 'lucide-react';
import { Box, Flex, Text } from 'rebass/styled-components';

export const ScoreDetails = ({ scoreDetails }) => {
  const sortedKeys = ['currentJobTitles', 'pastJobTitles', 'skills', 'tenureInCurrentJob', 'location'];

  const scoreNames = {
    currentJobTitles: 'Current job title(s)',
    pastJobTitles: 'Past job title(s)',
    skills: 'Skills',
    tenureInCurrentJob: '3+ years in current company',
    location: 'Location',
  };

  return (
    <Box data-testid="score-details" as="ul" p="0" m="0" sx={{ listStyle: 'none' }}>
      {sortedKeys.map(
        (key) =>
          scoreDetails[key]?.score > 0 && (
            <Box as="li" key={`score-${key}`} pb="4px">
              <Flex alignItems="center" color="green.600">
                <Box mr="8px" as={Check} width="16px" height="16px" aria-hidden="true" />
                <Text fontSize="14px" color="text.main">
                  {scoreNames[key]}
                </Text>
              </Flex>
            </Box>
          ),
      )}
    </Box>
  );
};

ScoreDetails.propTypes = {
  scoreDetails: PropTypes.object,
};

ScoreDetails.defaultProps = {
  scoreDetails: {},
};
