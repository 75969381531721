import React, { useEffect, useMemo, useState } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import { Box, Flex } from 'rebass/styled-components';
import { createPortal } from 'react-dom';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import PropTypes from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';
import { FormLabel, Input } from '@getro/rombo';
import { FORMAT, renderMonthElement } from 'components/atoms/DatePicker';
import moment from 'moment';

export const PostedJobsFilter = ({ onChange, date, label }) => {
  const [controlledVisible, setControlledVisible] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible, triggerRef } = usePopperTooltip({
    trigger: 'click',
    interactive: false,
    placement: 'bottom',
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
  });

  const formattedDate = useMemo(() => (date ? moment(date) : null), [date]);
  const calenderWidth = `${triggerRef?.offsetWidth}px` || 'auto';

  const handleResize = () => {
    setSmallScreen(window.innerWidth <= 991);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const calender = (
    <DayPickerSingleDateController
      onDateChange={(selectedDate) => {
        onChange(selectedDate.format(FORMAT));
        setControlledVisible(false);
      }}
      date={formattedDate}
      numberOfMonths={1}
      renderMonthElement={renderMonthElement}
      customInputIcon={ChevronRight}
      noBorder
      navPrev={<ChevronLeft />}
      navNext={<ChevronRight />}
      showKeyboardShortcuts={false}
      hideKeyboardShortcutsPanel
      withPortal={smallScreen}
      isOutsideRange={(day) => moment().diff(day) < 0}
    />
  );

  return (
    <Box>
      <FormLabel>{label}</FormLabel>
      <Flex
        sx={{
          input: { m: 0, borderColor: 'border.default' },
          position: 'relative',
          alignItems: 'center',
          svg: { color: 'neutral.400' },
          cursor: 'pointer',
        }}
      >
        <Input
          autoComplete="off"
          ref={setTriggerRef}
          name="default"
          placeholder="MM/DD/YYYY"
          onChange={(e) => e.preventDefault()}
          size="medium"
          mt="3"
          sx={{
            maxHeight: '40px',
            height: '40px',
            outline: 'none',
            cursor: 'pointer',
          }}
          value={date ? formattedDate.format('MM/DD/YYYY') : ''}
        />
        <Box
          strokeWidth="1.5"
          onClick={() => onChange(null)}
          sx={{ position: 'absolute', right: '8px', cursor: 'pointer' }}
          as={X}
        />
      </Flex>
      {visible && smallScreen && (
        <Box
          onClick={() => setControlledVisible(false)}
          sx={{ zIndex: 999999 }}
          className="DateRangePicker_picker__portal DateRangePicker_picker__portal_3"
        >
          {calender}
        </Box>
      )}
      {visible &&
        !smallScreen &&
        createPortal(
          <Box
            maxWidth={calenderWidth}
            tx="dropdown"
            ref={setTooltipRef}
            {...getTooltipProps({ className: 'dropdown-container' })}
            sx={{
              width: '100%',
              zIndex: 3333333333,
              boxShadow: '0px 1px 8px rgba(27, 36, 50, 0.1), 0px 4px 16px rgba(27, 36, 50, 0.1)',
              position: 'relative',
              cursor: 'pointer',
              '.DayPicker': {
                width: '100%!important',
                zIndex: 2,
                left: '0!important',
                ml: ['0!important'],
                mt: '0!important',
                position: ['relative!important'],
                margin: '0px auto',
                border: 'none',
                boxShadow: 'none',

                '& > div > div': {
                  margin: '0px auto',
                },
              },
              '& > svg': {
                color: 'neutral.500',
              },
            }}
          >
            {calender}
          </Box>,
          document.body,
        )}
    </Box>
  );
};

PostedJobsFilter.defaultProps = {
  date: null,
};

PostedJobsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default PostedJobsFilter;
