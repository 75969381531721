import { debounce } from 'debounce';
import { useMemo } from 'react';
import { useLazySearchJobCompaniesQuery } from 'services/companiesV2';

export const useProjectCompaniesOptions = () => {
  const [loadCompanies, { isFetching, data }] = useLazySearchJobCompaniesQuery();

  const options = useMemo(
    () =>
      data?.data.map(({ id, attributes: { name, logoUrl } }) => ({
        value: id,
        label: name,
        icon: logoUrl,
        showAvatar: true,
        avatarUrl: logoUrl,
      })) ?? [],
    [data],
  );

  const search = debounce((value) => {
    if (!value) return;
    loadCompanies({ query: value });
  }, 500);

  return {
    loading: isFetching,
    options,
    search: (value) => search(value),
  };
};
