import React, { Fragment, useState, useEffect } from 'react';
import { Box } from 'rebass/styled-components';
import { DateRangePicker, DayPickerRangeController } from 'react-dates';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import PropTypes from 'prop-types';
import moment from 'moment';

import 'react-dates/lib/css/_datepicker.css';

export const FORMAT = 'YYYY-MM-DD';
const TODAY = moment();
export const PRESETS = [
  {
    text: 'Last 7 days',
    start: moment().subtract(7, 'day'),
    end: moment().subtract(1, 'day'),
  },
  {
    text: 'Last 30 days',
    start: moment().subtract(30, 'day'),
    end: moment().subtract(1, 'day'),
  },
  {
    text: 'Last 60 days',
    start: moment().subtract(60, 'day'),
    end: moment().subtract(1, 'day'),
  },
  {
    text: 'Last 365 days',
    start: moment().subtract(365, 'day'),
    end: moment().subtract(1, 'day'),
  },
  {
    text: 'This week',
    start: moment().startOf('week'),
    end: TODAY,
  },
  {
    text: 'This month',
    start: moment().startOf('month'),
    end: TODAY,
  },
  {
    text: 'This year',
    start: moment().startOf('year'),
    end: TODAY,
  },
];

const YEARS = Array.from(Array(10).keys())
  .reverse()
  .map((y) => moment().subtract(y, 'years').year());

export const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
  <div className="DatePicker__month-year-selectors">
    <div>
      <select
        className="DatePicker__month-selector"
        value={month.month()}
        onChange={(e) => {
          onMonthSelect(month, e.target.value);
        }}
      >
        {moment.months().map((label, value) => (
          <option value={value} key={`date_${JSON.stringify(value)}`}>
            {label}
          </option>
        ))}
      </select>
    </div>
    <div>
      <select
        className="DatePicker__year-selector"
        value={month.year()}
        onChange={(e) => {
          onYearSelect(month, e.target.value);
        }}
      >
        {YEARS.map((y) => (
          <option value={y} key={y}>
            {y}
          </option>
        ))}
      </select>
    </div>
  </div>
);

const DatePicker = ({ startDate, endDate, onSelectDates, useRangeController, ...props }) => {
  // Handling sceen size
  const [smallScreen, setSmallScreen] = useState(false);

  const handleResize = () => {
    setSmallScreen(window.innerWidth <= 991);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  // Using react-dates
  const [dates, setDates] = useState({
    startDate: startDate ? moment(startDate) : null,
    endDate: endDate ? moment(endDate) : null,
  });
  const [focusInput, setFocusInput] = useState(useRangeController ? 'startDate' : null);

  const renderCalendarInfo = () => (
    <>
      <button className="DatePicker__close" type="button" onClick={() => handleFocusChange(null)}>
        <Box as={X} strokeWidth="1.5" aria-hidden="true" />
      </button>
      <div className="DatePicker__shortcuts">
        {PRESETS.map(({ text, start, end }) => (
          <Fragment key={text}>
            {text === 'This week' && <br />}
            <button
              className="DatePicker__shortcut"
              key={text}
              type="button"
              onClick={() =>
                handleChangeDates(
                  {
                    startDate: !useRangeController ? start : moment(start),
                    endDate: !useRangeController ? end : moment(end),
                  },
                  { isPreset: true },
                )
              }
            >
              {text}
            </button>
          </Fragment>
        ))}
      </div>
    </>
  );

  const handleFocusChange = (focusedInput) => setFocusInput(focusedInput);

  // eslint-disable-next-line no-shadow
  const handleChangeDates = ({ startDate, endDate }, options = {}) => {
    setDates({ startDate, endDate });

    if (!startDate || !endDate) {
      return;
    }

    onSelectDates(
      {
        startDate: !useRangeController ? moment(startDate).format(FORMAT) : moment(startDate),
        endDate: !useRangeController ? moment(endDate).format(FORMAT) : moment(endDate),
      },
      options,
    );
    handleFocusChange(null);
  };

  const Picker = useRangeController ? DayPickerRangeController : DateRangePicker;

  // Rendering
  return (
    <Picker
      customArrowIcon="-"
      startDate={dates.startDate}
      startDateId="start-date-id"
      focusedInput={focusInput}
      onFocusChange={handleFocusChange}
      endDate={dates.endDate}
      endDateId="end-date-id"
      onDatesChange={handleChangeDates}
      calendarInfoPosition="top"
      renderCalendarInfo={renderCalendarInfo}
      isOutsideRange={(day) => moment().diff(day) < 0}
      initialVisibleMonth={() => (smallScreen ? moment() : moment().subtract(1, 'month'))}
      hideKeyboardShortcutsPanel
      renderMonthElement={renderMonthElement}
      appendToBody
      withPortal={smallScreen}
      numberOfMonths={smallScreen ? 1 : 2}
      small
      navPrev={<Box as={ChevronLeft} strokeWidth="1.5" aria-hidden="true" />}
      navNext={<Box as={ChevronRight} strokeWidth="1.5" aria-hidden="true" />}
      {...props}
    />
  );
};

DatePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onSelectDates: PropTypes.func,
  useRangeController: PropTypes.bool,
};

DatePicker.defaultProps = {
  startDate: '',
  endDate: '',
  onSelectDates: () => {},
  useRangeController: false,
};

export default DatePicker;
