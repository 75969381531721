import React from 'react';
import { Flex } from 'rebass/styled-components';
import { X } from 'lucide-react';
import PropTypes from 'prop-types';

import { BulkActionsBar } from 'components/atoms/BulkActionsBar';
import { ActionExport } from './actionExport';
import { ActionTags } from './actionTags';
import { ActionLists } from './actionLists';
import { ActionDeleteContacts } from './actionDeleteContacts';
import ActionsIcon from './actionsIcon';

export const BulkActions = ({
  actions,
  queryParams,
  contactIds,
  selectedRows,
  meta,
  sharedList,
  setSelectedRows,
  page,
}) => {
  const totalSelectedRowsCount = selectedRows.length;
  const currentActions = {
    'add-to-list': {
      text: 'Add to lists',
      component: (
        <ActionLists
          queryParams={queryParams}
          contactIds={contactIds}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          meta={meta}
          sharedList={sharedList}
          page={page}
        />
      ),
    },
    'add-tag': {
      text: 'Add tag',
      component: (
        <ActionTags
          queryParams={queryParams}
          contactIds={contactIds}
          selectedRows={selectedRows}
          meta={meta}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
    export: {
      text: 'Export selected',
      component: (
        <ActionExport queryParams={queryParams} contactIds={contactIds} selectedRows={selectedRows} meta={meta} />
      ),
    },
    delete: {
      text: 'Delete selected',
      component: (
        <ActionDeleteContacts
          setSelectedRows={setSelectedRows}
          queryParams={queryParams}
          contactIds={contactIds}
          meta={meta}
          selectedRows={selectedRows}
        />
      ),
    },
    'clear-selection': {
      text: 'Clear selection',
      component: <ActionsIcon onClick={() => setSelectedRows([])} icon={X} text="Clear selection" />,
    },
  };

  return (
    <div>
      <BulkActionsBar
        count={totalSelectedRowsCount}
        itemLabel="contacts"
        sx={{
          maxWidth: 'max-content',
          width: ['100%', '100%', '100%'],
          gap: '24px',
        }}
        actions={
          <Flex sx={{ gap: ['0px', '0px', '24px'], cursor: 'pointer', height: '32px' }}>
            {actions.map((key) => (currentActions[key] ? currentActions[key].component : null))}
          </Flex>
        }
      />
    </div>
  );
};

BulkActions.propTypes = {
  actions: PropTypes.array,
  sharedList: PropTypes.object,
  queryParams: PropTypes.object,
  contactIds: PropTypes.array,
  meta: PropTypes.object,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func.isRequired,
  page: PropTypes.string,
};

BulkActions.defaultProps = {
  actions: ['add-to-list', 'add-tag', 'export', 'delete', 'clear-selection'],
  sharedList: null,
  queryParams: {},
  contactIds: [],
  meta: {},
  selectedRows: [],
  page: 'contacts',
};
