import isEqual from 'lodash.isequal';
import { blacklistedFiltersForCount, defaultFilters } from 'components/organisms/contactsFilters/defaultFilters';
import { useMemo } from 'react';

export const useContactsFilterCount = (filters) =>
  useMemo(
    () =>
      Object.keys(filters).reduce(
        (carry, idx) =>
          !blacklistedFiltersForCount.includes(idx) &&
          filters[idx] &&
          !isEqual(filters[idx], defaultFilters[idx]) &&
          (filters[idx]?.length || Object.keys(filters[idx])?.length)
            ? carry + 1
            : carry,
        0,
      ),
    [filters],
  );

export default useContactsFilterCount;
