import React from 'react';
import PropTypes from 'prop-types';
import { StatusIndicator } from '@getro/rombo';

const JobFeatured = ({ isFeatured }) => {
  if (!isFeatured) return <>-</>;

  return <StatusIndicator status="Featured" statusColor="purple.300" />;
};

JobFeatured.propTypes = {
  isFeatured: PropTypes.bool,
};

JobFeatured.defaultProps = {
  isFeatured: false,
};

export default JobFeatured;
