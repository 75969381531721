import React from 'react';
import { Button } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { openChat } from 'helpers/supportChat';

export const UpgradeConnectLimit = ({ connectTier, completion, onUpgradeClicked, networkSlug }) => (
  <>
    {completion >= 90 && (
      <Box p="16px" bg="purple.100" sx={{ borderRadius: 'default' }}>
        <Flex justifyContent="space-between" sx={{ gap: '16px' }}>
          <Box>
            <Text fontSize="14px" fontWeight="medium" color="text.dark">
              {completion > 100 ? 'You’re over your plan limit' : 'You’re near your plan limit'}
            </Text>
            <Text mt="4px" fontSize="14px">
              Upgrade your plan now to add more contacts.{' '}
            </Text>
          </Box>
        </Flex>

        {connectTier === 'premium' ? (
          <Button onClick={openChat} mt="8px" size="small">
            Let’s talk
          </Button>
        ) : (
          <Button onClick={onUpgradeClicked} as={Link} to={`/networks/${networkSlug}/upgrade`} mt="8px" size="small">
            {completion > 100 ? 'Upgrade and unlock all matches' : 'Upgrade now'}
          </Button>
        )}
      </Box>
    )}
  </>
);

UpgradeConnectLimit.propTypes = {
  connectTier: PropTypes.oneOf(['premium', 'freemium']).isRequired,
  completion: PropTypes.number.isRequired,
  onUpgradeClicked: PropTypes.func.isRequired,
  networkSlug: PropTypes.string.isRequired,
};
