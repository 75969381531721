import React from 'react';

import Route from 'router/Route';
import BlankLayout from 'components/layout/Blank';
import Onboarding from 'pages/Onboarding';

const OnboardingWrapper = () => (
  <BlankLayout>
    <Route path="/welcome" component={Onboarding} visibility="onboarding" exact />
  </BlankLayout>
);

export default OnboardingWrapper;
