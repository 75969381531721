import React from 'react';
import { Link } from 'rebass/styled-components';
import ContactsSharedListSchema from '../../../schemas/contactsSharedList';
import { trackEvent } from '../../../helpers/analytics';

const PointOfContact = ({ listInfo }) => {
  const { pointOfContact } = listInfo || {};

  const onPOCCtaClick = () => {
    trackEvent('contacts:shared_list:poc_cta_click', {
      list_slug: listInfo.slug,
      collection_id: listInfo.collection.id,
    });
  };

  return (
    <>
      <Link
        href={`mailto:${pointOfContact.email}`}
        sx={{ textDecoration: 'underline', color: 'text.main' }}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onPOCCtaClick}
      >
        Email {pointOfContact.fullName}
      </Link>{' '}
      for intros
    </>
  );
};

PointOfContact.propTypes = {
  listInfo: ContactsSharedListSchema,
};

PointOfContact.defaultProps = {
  listInfo: null,
};

export default PointOfContact;
