import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';

import { getUserDisplayName } from 'helpers/users';
import InitialStep from './InitialStep';
import CustomStep from './CustomStep';
import ConfirmStep from './ConfirmStep';
import SuccessStep from './SuccessStep';
import Popover from '../../atoms/popoverDeprecated';

const MakeIntroModal = ({ member, activeNetwork, children }) => {
  const { id: activeNetworkId, isManager: isNm, features } = activeNetwork;
  const skillsDeactivated = features && features.includes('skills_deactivated');
  const [members, setMembers] = useState([]);
  const [customMessage, setCustomMessage] = useState('');

  // Modal steps
  const [initialStep, setInitialStep] = useState(false);
  const [customStep, setCustomStep] = useState(false);
  const [confirmStep, setConfirmStep] = useState(false);
  const [successStep, setSuccessStep] = useState(false);

  const resetFields = () => {
    setMembers([]);
    setCustomMessage('');
  };

  const popoverContent = useMemo(() => {
    if (member.sharePermission) {
      return `Click here to make an email introduction between ${getUserDisplayName(member)} and any company in the ${
        activeNetwork.name
      } network.`;
    }
    return `This member only wants to be seen by administrators at ${activeNetwork.name}. Please, don’t share their profile without asking for their permission first.`;
  }, [activeNetwork, member]);

  const onPopoverClick = () => setInitialStep(true);

  const onSubmitInitialStep = (_members) => {
    setMembers(_members);
    setCustomStep(true);
    setInitialStep(false);
  };

  const onCancelInitialStep = () => {
    setInitialStep(false);
    resetFields();
  };

  const onSubmitCustomStep = ({ customMessage: cm }) => {
    setCustomMessage(cm);
    setConfirmStep(true);
    setCustomStep(false);
  };

  const onCancelCustomStep = () => {
    setInitialStep(true);
    setCustomStep(false);
  };

  const onSubmitConfirmStep = () => {
    setConfirmStep(false);
    setSuccessStep(true);
    resetFields();
  };

  const onCancelConfirmStep = () => {
    setCustomStep(true);
    setConfirmStep(false);
  };

  const onCloseSuccessStep = () => {
    setSuccessStep(false);
  };

  return (
    <>
      <Popover
        style={{ zIndex: 1000 }}
        data-testid="make-intro-modal"
        content={popoverContent}
        trigger={
          (children &&
            React.cloneElement(children, {
              onClick: onPopoverClick,
            })) || (
            <div>
              {member.sharePermission && (
                <Button data-testid="share-button" type="button" variant="tertiary" onClick={onPopoverClick}>
                  Share
                </Button>
              )}
            </div>
          )
        }
      />
      {initialStep && (
        <InitialStep
          member={member}
          members={members}
          activeNetworkId={activeNetworkId}
          open={initialStep}
          onSubmit={onSubmitInitialStep}
          onCancel={onCancelInitialStep}
        />
      )}
      {customStep && (
        <CustomStep
          open={customStep}
          member={member}
          onSubmit={onSubmitCustomStep}
          onCancel={onCancelCustomStep}
          customMessage={customMessage}
          isNetworkManager={isNm}
          skillsDeactivated={skillsDeactivated}
        />
      )}
      {confirmStep && (
        <ConfirmStep
          open={confirmStep}
          onCancel={onCancelConfirmStep}
          onSubmit={onSubmitConfirmStep}
          activeNetworkId={activeNetworkId}
          member={member}
          members={members}
          customMessage={customMessage}
        />
      )}
      {successStep && <SuccessStep open={successStep} member={member} onClose={onCloseSuccessStep} />}
    </>
  );
};

MakeIntroModal.propTypes = {
  member: PropTypes.object.isRequired,
  activeNetwork: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

MakeIntroModal.defaultProps = {
  children: null,
};

export default MakeIntroModal;
