import { createAction, createActions } from 'redux-actions';

export const { loadUniqueVisitors, loadedUniqueVisitors, errorUniqueVisitors } = createActions(
  'LOAD_UNIQUE_VISITORS',
  'LOADED_UNIQUE_VISITORS',
  'ERROR_UNIQUE_VISITORS',
);

export const { loadJobsClicks, loadedJobsClicks, errorJobsClicks } = createActions(
  'LOAD_JOBS_CLICKS',
  'LOADED_JOBS_CLICKS',
  'ERROR_JOBS_CLICKS',
);

export const { loadJobAlertSubscribers, loadedJobAlertSubscribers, errorJobAlertSubscribers } = createActions(
  'LOAD_JOB_AlERT_SUBSCRIBERS',
  'LOADED_JOB_AlERT_SUBSCRIBERS',
  'ERROR_JOB_AlERT_SUBSCRIBERS',
);

export const { loadAllJobAlertSubscribers, loadedAllJobAlertSubscribers, errorAllJobAlertSubscribers } = createActions(
  'LOAD_ALL_JOB_AlERT_SUBSCRIBERS',
  'LOADED_ALL_JOB_AlERT_SUBSCRIBERS',
  'ERROR_ALL_JOB_AlERT_SUBSCRIBERS',
);

export const { loadSuggestedCompaniesCount, loadedSuggestedCompaniesCount, errorSuggestedCompaniesCount } =
  createActions(
    'LOAD_SUGGESTED_COMPANIES_COUNT',
    'LOADED_SUGGESTED_COMPANIES_COUNT',
    'ERROR_SUGGESTED_COMPANIES_COUNT',
  );

export const { loadJobsLikelyApplied, loadedJobsLikelyApplied, errorJobsLikelyApplied } = createActions(
  'LOAD_JOBS_LIKELY_APPLIED',
  'LOADED_JOBS_LIKELY_APPLIED',
  'ERROR_JOBS_LIKELY_APPLIED',
);

export const { loadJobsApplicants, loadedJobsApplicants, errorJobsApplicants } = createActions(
  'LOAD_JOBS_APPLICANTS',
  'LOADED_JOBS_APPLICANTS',
  'ERROR_JOBS_APPLICANTS',
);

export const closeDownloadLikelyApplied = createAction('CLOSE_DOWNLOAD_LIKELY_APPLIED');

export const trackHomeLeftBeforeLoad = createAction('HOME_LEFT_BEFORE_LOAD');
