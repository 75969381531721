import { takeLatest, select, call, put, all } from 'redux-saga/effects';
import {
  loadCompany,
  loadedCompany,
  errorCompany,
  loadAddAdmin,
  loadedAddAdmin,
  errorAddAdmin,
  loadRemoveAdmin,
  loadedRemoveAdmin,
  errorRemoveAdmin,
  loadResendAdminInvite,
  loadedResendAdminInvite,
  loadUpdateCompany,
  errorResendAdminInvite,
  errorUpdateCompany,
  loadedUpdateCompany,
} from './actions';
import { activeNetworkSelector } from '../../redux/selectors';
import { del, get, request } from '../../api';
import { trackException } from '../../helpers/errorTracker';
import helperGet from '../../helpers/get';
import OrganizationsAPI from '../../api/OrganizationsAPI';
import { triggerTrackEvent } from '../../pages/CompanyProfile/actions';
import APIError from '../../api/APIError';
import { companyDetailsSelector } from './selectors';
import { transformCompanyData } from '../../helpers/organizations';

function* handleLoadCompanyRequest({ payload }) {
  const { companySlug, companyId } = payload;

  const { id: collectionId } = yield select(activeNetworkSelector);

  try {
    const response = yield call(get, `/collections/${collectionId}/organizations/${companyId || companySlug}`, {
      baseURL: process.env.REACT_APP_API_V2_BASE_URL,
    });
    const { data } = response;
    yield put(loadedCompany(transformCompanyData(data)));
  } catch (e) {
    if (!(e instanceof APIError)) {
      yield call(trackException, e);
    }

    const errorCode = helperGet(e, 'response.status');
    yield put(errorCompany(errorCode));
  }
}

function* loadAddAdminEvent({ payload }) {
  const { firstName, lastName, email, customMessage } = payload;
  try {
    const company = yield select(companyDetailsSelector);
    const activeNetwork = yield select(activeNetworkSelector);
    const { id: activeNetworkId, isManager } = activeNetwork;

    const response = yield call(OrganizationsAPI.addAdmin, {
      company,
      activeNetworkId,
      firstName,
      lastName,
      email,
      customMessage,
      isManager,
    });
    yield put(loadedAddAdmin({ admin: response.data }));
  } catch (err) {
    if (!(err instanceof APIError)) {
      yield call(trackException, err);
    }
    yield put(errorAddAdmin());
  }
}

function* loadRemoveAdminEvent({ payload }) {
  const { admin } = payload;
  const { id: adminId } = admin;
  try {
    const company = yield select(companyDetailsSelector);
    yield call(del, `/organizations/${company.id}/poc/${adminId}`);
    yield put(loadedRemoveAdmin({ admin }));
    yield put(
      triggerTrackEvent({
        name: 'employer_profile:remove_manager',
        eventProperties: {
          organization_id: company.id,
          manager_id: adminId,
        },
      }),
    );
  } catch (err) {
    if (!(err instanceof APIError)) {
      yield call(trackException, err);
    }

    yield put(errorRemoveAdmin());
  }
}

function* loadResendAdminInviteEvent({ payload }) {
  const { adminId } = payload;

  try {
    const company = yield select(companyDetailsSelector);
    const activeNetwork = yield select(activeNetworkSelector);
    const { id: activeNetworkId } = activeNetwork;

    yield call(request, {
      url: `/users/${adminId}/invitation_email/organization_manager`,
      options: {
        method: 'post',
        data: {
          collectionId: activeNetworkId,
          organizationId: company.id,
        },
      },
    });

    yield put(loadedResendAdminInvite({ adminId }));
    yield put(
      triggerTrackEvent({
        name: 'employer_profile:resend_manager_invitation',
        eventProperties: {
          organization_id: company.id,
          manager_id: adminId,
        },
      }),
    );
  } catch (err) {
    if (!(err instanceof APIError)) {
      yield call(trackException, err);
    }

    yield put(errorResendAdminInvite());
  }
}

function* loadUpdateCompanyEvent({ payload: { qa, onComplete, topics, ...payload } }) {
  try {
    const company = yield select(companyDetailsSelector);
    const { id: activeNetworkId } = yield select(activeNetworkSelector);
    const updateTopicsUrl = `/collections/${activeNetworkId}/organizations/${company.id}/topics`;

    const requests = [
      {
        url: `/organizations/${company.id}?collection_id=${activeNetworkId}`,
        options: {
          method: 'put',
          data: {
            ...payload,
          },
        },
      },
    ];

    if (topics) {
      requests.push({
        url: updateTopicsUrl,
        options: {
          method: 'post',
          data: {
            topicIds: topics.map((topic) => parseInt(topic.value, 10)),
          },
        },
      });
    }

    const [companyResponse] = yield all(requests.map((item) => call(request, item)));
    const { data } = companyResponse;

    if (topics) {
      data.topics = topics.map((topic) => ({
        id: parseInt(topic.value, 10),
        name: topic.label,
      }));
    }

    yield put(loadedUpdateCompany(data));

    yield put(
      triggerTrackEvent({
        name: 'employer_profile:edit_general_info',
        eventProperties: {
          organization_id: company.id,
          previous_qa_status: qa,
          new_qa_status: data.qa,
        },
      }),
    );
    if (onComplete) {
      yield call(onComplete, data);
    }
  } catch (error) {
    if (!(error instanceof APIError)) {
      yield call(trackException, error);
    }

    yield put(errorUpdateCompany({ error }));
  }
}

export default function* saga() {
  yield takeLatest(loadCompany().type, handleLoadCompanyRequest);
  yield takeLatest(loadAddAdmin().type, loadAddAdminEvent);
  yield takeLatest(loadRemoveAdmin().type, loadRemoveAdminEvent);
  yield takeLatest(loadResendAdminInvite().type, loadResendAdminInviteEvent);
  yield takeLatest(loadUpdateCompany().type, loadUpdateCompanyEvent);
}
