import React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import strings from '../../../strings';

export const EmptyView = ({ error, filterCount }) => {
  if (error) {
    return (
      <Flex width={1} justifyContent="center" mt={5}>
        <Text>{strings.genericError}</Text>
      </Flex>
    );
  }

  if (filterCount > 0) {
    return (
      <Flex width={1} justifyContent="center" mt={5} flexDirection="column">
        <Text mx="auto" mb={[3]} fontWeight="semibold">
          No search results
        </Text>
        <Text mx="auto">There are no companies with this criteria, try changing your filters.</Text>
      </Flex>
    );
  }

  return (
    <Flex width={1} justifyContent="center" mt={5}>
      <Text>There are no companies for the network at this time</Text>
    </Flex>
  );
};

EmptyView.propTypes = {
  error: PropTypes.bool.isRequired,
  filterCount: PropTypes.number.isRequired,
};
