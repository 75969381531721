import { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const useSearchParams = () => {
  const { search } = useLocation();
  const { replace } = useHistory();

  const params = useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);

  const setSearchParams = useCallback(
    (value) => {
      let newValues = value;
      if (typeof value === 'function') {
        newValues = value(params);
      }
      replace({
        to: '/',
        search: new URLSearchParams(Object.entries(newValues)).toString(),
      });

      return newValues;
    },
    [params, replace],
  );

  return [params, setSearchParams];
};
