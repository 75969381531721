import React from 'react';
import PropTypes from 'prop-types';
import { Social, ProfilePicture, Tag } from '@getro/rombo';
import { Text, Flex, Box } from 'rebass/styled-components';
import { getUserDisplayName } from 'helpers/users';
import { trackEvent } from 'helpers/analytics';
import MemberSchema from 'schemas/member';
import MemberRow from './MemberRow';

const MemberCard = ({ className, isNetworkManager, member, skillsDeactivated }) => {
  const {
    id,
    active,
    avatarUrl,
    bio,
    email,
    githubUrl,
    skills,
    linkedinUrl,
    resumeUrl,
    twitterUrl,
    dribbbleUrl,
    verified,
    websiteUrl,
    talentGroups,
  } = member || {};

  const handleSocialClick = (platform) => {
    trackEvent('members:click_profile_link', {
      member_id: id,
      origin: 'search_members',
      platform,
      collection_id: active,
    });
  };

  let memberSkills = [];
  if (!skillsDeactivated && skills && !!skills.length) {
    memberSkills = skills.map((skill) => skill.name);
  }

  let memberBoards = [];
  if (talentGroups && talentGroups.length) {
    memberBoards = talentGroups.map((board) => ({ label: board.name, id: board.id }));
  }

  if (!id) return null;

  return (
    <div className={`MemberCard ${className}`} data-testid="membercard">
      <Box
        sx={{
          flexBasis: '64px',
          flexGrow: 0,
          flexShrink: 0,
          '@media (min-width: 480px)': {
            flexBasis: '88px',
          },
        }}
      >
        <ProfilePicture imageUrl={avatarUrl} name={getUserDisplayName(member)} verified={verified} />
      </Box>

      <div className="MemberCard__main">
        <div className="MemberCard__main-top">
          <div className="MemberCard__name">{getUserDisplayName(member)}</div>
        </div>

        {memberSkills.length > 0 && (
          <Text color="gray.1" mb={2}>
            {memberSkills.join(', ')}
          </Text>
        )}

        {memberBoards.length > 0 && (
          <Flex flexWrap="wrap" mt={[-2]} pb={[2]} pt={[memberSkills.length ? 0 : 2]} width={[1]}>
            {memberBoards.map((board) => (
              <Tag fontSize={1} mt={2} key={board.id}>
                {board.label}
              </Tag>
            ))}
          </Flex>
        )}

        <Box py={2} className="text-hyphenate">
          {bio}
        </Box>

        <Social
          className="MemberCard__social"
          email={isNetworkManager ? email : null}
          github={githubUrl}
          linkedin={linkedinUrl}
          resume={resumeUrl}
          twitter={twitterUrl}
          website={websiteUrl}
          dribbble={dribbbleUrl}
          onClick={handleSocialClick}
        />
      </div>
    </div>
  );
};

MemberCard.propTypes = {
  className: PropTypes.string,
  isNetworkManager: PropTypes.bool,
  member: MemberSchema,
  skillsDeactivated: PropTypes.bool,
};

MemberCard.defaultProps = {
  className: '',
  isNetworkManager: false,
  skillsDeactivated: false,
  member: null,
};

export { MemberRow };
export default MemberCard;
