import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { Loader, Message } from '@getro/rombo';

import useIntroductionRequests from 'hooks/useIntroductionRequests';
import { activeNetworkSelector } from 'redux/selectors';
import { declineIntroductionRequest, markAsDoneIntroductionRequest } from 'actions/introductionRequestsActions';

import NoAccess from 'components/atoms/NoAccess';
import PageLayout from 'components/layout/Page';
import IntroductionRequest from './IntroductionRequest';
import NetworkSchema from '../../schemas/network';

const Inbox = ({ activeNetwork, onDecline, onMarkAsDone }) => {
  const { isManager } = activeNetwork;
  const [introductionRequests, loading] = useIntroductionRequests(activeNetwork);
  const [inNetwork, setInNetwork] = useState([]);
  const [outOfNetwork, setOutOfNetwork] = useState([]);

  useEffect(() => {
    if (introductionRequests) {
      const pokAsked = introductionRequests.filter((r) => r.status === 'poc_asked');
      setOutOfNetwork(pokAsked);

      const gkAsked = introductionRequests.filter((r) => r.status === 'gk_asked');
      setInNetwork(gkAsked);
    }
  }, [introductionRequests]);

  if (!isManager) return <NoAccess cy="introduction-requests-unavailable" />;

  if (loading) return <Loader />;

  return (
    <PageLayout>
      <PageLayout.Content>
        <Box pt="24px">
          {inNetwork.length > 0 && (
            <>
              <h2>In network ({inNetwork.length})</h2>
              {inNetwork.map((ir) => (
                <IntroductionRequest
                  key={ir.id}
                  introductionRequest={ir}
                  onDecline={onDecline}
                  onMarkAsDone={onMarkAsDone}
                  activeNetwork={activeNetwork}
                />
              ))}
            </>
          )}
          {outOfNetwork.length > 0 && (
            <>
              <h2>Out of network ({outOfNetwork.length})</h2>
              {outOfNetwork.map((ir) => (
                <IntroductionRequest
                  key={ir.id}
                  introductionRequest={ir}
                  onDecline={onDecline}
                  onMarkAsDone={onMarkAsDone}
                  activeNetwork={activeNetwork}
                />
              ))}
            </>
          )}
          {inNetwork.length === 0 && outOfNetwork.length === 0 && (
            <Message type="success">Congratulations! You&apos;re all done!</Message>
          )}
        </Box>
      </PageLayout.Content>
    </PageLayout>
  );
};

Inbox.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
  onDecline: PropTypes.func.isRequired,
  onMarkAsDone: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => ({
  activeNetwork: activeNetworkSelector(state),
});

export const mapDispatchToProps = (dispatch) => ({
  onDecline: (id, introductionRequest) => dispatch(declineIntroductionRequest({ id, introductionRequest })),
  onMarkAsDone: (id, introductionRequest) => dispatch(markAsDoneIntroductionRequest({ id, introductionRequest })),
});

export default Inbox;
