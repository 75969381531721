import React from 'react';
import PropTypes from 'prop-types';

import { MessageBlock, Panel } from '@getro/rombo';
import PageLayout from 'components/layout/Page';

const NoAccess = ({ cy }) => (
  <PageLayout data-cy={cy}>
    <PageLayout.Content>
      <Panel>
        <MessageBlock
          data-testid="no-access-message"
          type="warning"
          title="No access"
          content="Sorry, you don't have the necessary permissions to access this page."
        />
      </Panel>
    </PageLayout.Content>
  </PageLayout>
);

NoAccess.propTypes = {
  cy: PropTypes.string.isRequired,
};

export default NoAccess;
