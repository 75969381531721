import React from 'react';
import { Modal } from '@getro/rombo';
import PropTypes from 'prop-types';
import { JobRules } from '../../molecules/jobRules';
import useJobRules from '../../../hooks/jobRules/useJobRules';

const JobRulesModal = ({ onCancel }) => {
  const { jobRules, isJobRulesLoading } = useJobRules();
  return (
    <Modal variant="large" onCancel={onCancel} data-testid="company-job-rules-modal" title="Job rules">
      <JobRules
        isLoading={isJobRulesLoading}
        keywords={jobRules.keywords}
        titles={jobRules.titles}
        locations={jobRules.locations}
      />
    </Modal>
  );
};

JobRulesModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default JobRulesModal;
