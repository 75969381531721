/* eslint-disable react/no-multi-comp */
import React, { useContext, useEffect } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { trackPage } from 'helpers/analytics';
import OnboardingContext from 'components/layout/EmployerOnboarding/onboardingContext';

import YourAccount from './YourAccount';
import SelectYourCompany from './SelectYourCompany';
import { userSelector } from '../../redux/selectors';

const STEPS = {
  selectYourCompany: {
    component: (...params) => <SelectYourCompany network={params[params.length - 1]} />,
  },
  yourAccount: {
    component: (user, isManager) => <YourAccount user={user} isManager={isManager} />,
  },
};

const ClaimYourProfile = () => {
  const match = useRouteMatch();
  const user = useSelector(userSelector);
  const { organization, network, steps, step, isManager } = useContext(OnboardingContext);

  const goToOnboarding = user.id && user.onboardingCompleted && !user.forcePasswordUpdate;

  const claimYourProfileWithNetwork = match.path === '/claim-your-profile/:networkSlug/company/:employerSlug';

  useEffect(() => {
    trackPage();
  }, [step]);

  if (goToOnboarding && claimYourProfileWithNetwork) {
    return <Redirect to={`/onboarding/${network.slug}/company/${organization.slug}`} />;
  }

  if (step >= 2) {
    return null;
  }

  return STEPS[steps[step].id].component(user, isManager, network);
};

export default ClaimYourProfile;
