import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isEqual from 'lodash.isequal';
import { Loader, MessageBlock, Panel } from '@getro/rombo';
import NetworkSchema from '../../../schemas/network';
import { AdminList } from '../../molecules/adminList';
import strings from '../../../strings';
import { InviteAdminModal } from './inviteAdminModal';
import { usePrevious } from '../../../hooks/usePrevious';
import RemoveAdminModal from './removeAdminModal';
import UserSchema from '../../../schemas/user';

export const AdminPanel = ({
  onAdminInvite,
  onRemoveAdmin,
  onResendInvitation,
  adminGroupName,
  admins,
  location,
  activeNetwork,
  currentUser,
  canAddCompanyAdmins,
  canRemoveCompanyAdmins,
  fetchError,
  isLoading,
  isInvitingAdmin,
  isResendingInvitation,
  isDeletingAdmin,
  title,
  showEmailAddress,
  companyAdmins,
}) => {
  const isTalentNetworkActive = activeNetwork.features.includes('talent_network');
  const formRef = useRef(null);
  const { action } = queryString.parse(location.search);
  // Check both actions to support old 'invite-manager'
  const inviteModalOpen = action === 'invite-admin' || action === 'invite-manager';
  const [isInviteAdminModalOpen, setIsInviteAdminModalOpen] = useState(inviteModalOpen);
  const previousCompanyManagers = usePrevious(admins);
  const [removingAdmin, setRemovingAdmin] = useState(null);

  useEffect(() => {
    if (previousCompanyManagers && !isEqual(admins, previousCompanyManagers)) {
      setIsInviteAdminModalOpen(false);
    }
  }, [admins, previousCompanyManagers]);

  useEffect(() => {
    if (removingAdmin !== null) {
      const removingAdminFound = admins.find(({ id }) => id === removingAdmin.id);
      if (!removingAdminFound) {
        setRemovingAdmin(null);
      }
    }
  }, [removingAdmin, admins]);

  const onRemoveAdminRequest = (user) => {
    setRemovingAdmin(user);
  };

  return (
    <Panel
      data-testid="admin-panel"
      mb="40px"
      title={title}
      headerButton={{
        children: 'Invite',
        display: canAddCompanyAdmins && !fetchError && admins && admins.length ? 'flex' : 'none',
        onClick: () => {
          setIsInviteAdminModalOpen(true);
        },
        variant: 'secondary',
        alignItems: 'center',
        width: 'max-content',
        fontSize: 1,
        lineHeight: '14px',
        px: 4,
        py: 2,
      }}
    >
      {isLoading && <Loader data-testid="loader" />}
      {!isLoading && (
        <>
          {fetchError && <MessageBlock data-testid="fetch-error">{strings.genericError}</MessageBlock>}
          {!fetchError && (
            <>
              <AdminList
                users={admins}
                onRemoveAdmin={canRemoveCompanyAdmins && onRemoveAdmin ? onRemoveAdminRequest : null}
                onResendInvitation={canAddCompanyAdmins ? onResendInvitation : null}
                isResendingInvitation={isResendingInvitation}
                companyName={adminGroupName}
                onEmptyListClick={() => {
                  setIsInviteAdminModalOpen(true);
                }}
                isTalentNetworkActive={isTalentNetworkActive}
                showEmailAddress={showEmailAddress}
              />
              <InviteAdminModal
                isSubmitting={isInvitingAdmin}
                onAdminInvite={onAdminInvite}
                companyName={adminGroupName}
                modalOpen={isInviteAdminModalOpen}
                setModalOpen={setIsInviteAdminModalOpen}
                formRef={formRef}
                companyAdmin={companyAdmins}
              />
              {canRemoveCompanyAdmins && onRemoveAdmin && (
                <RemoveAdminModal
                  user={removingAdmin}
                  currentUser={currentUser}
                  network={activeNetwork}
                  isSubmitting={isDeletingAdmin}
                  modalOpen={removingAdmin !== null}
                  onCancel={() => setRemovingAdmin(null)}
                  onDeleteAdmin={() => onRemoveAdmin(removingAdmin)}
                />
              )}
            </>
          )}
        </>
      )}
    </Panel>
  );
};

AdminPanel.propTypes = {
  isInvitingAdmin: PropTypes.bool,
  isDeletingAdmin: PropTypes.bool,
  isResendingInvitation: PropTypes.bool,
  adminGroupName: PropTypes.string.isRequired,
  activeNetwork: NetworkSchema.isRequired,
  currentUser: UserSchema.isRequired,
  onAdminInvite: PropTypes.func.isRequired,
  admins: PropTypes.array,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  onRemoveAdmin: PropTypes.func,
  onResendInvitation: PropTypes.func,
  canAddCompanyAdmins: PropTypes.bool,
  canRemoveCompanyAdmins: PropTypes.bool,
  fetchError: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  showEmailAddress: PropTypes.bool,
  companyAdmins: PropTypes.bool,
};

AdminPanel.defaultProps = {
  isInvitingAdmin: false,
  isDeletingAdmin: false,
  isResendingInvitation: false,
  admins: [],
  location: {},
  onRemoveAdmin: () => {},
  onResendInvitation: () => {},
  canAddCompanyAdmins: false,
  canRemoveCompanyAdmins: false,
  fetchError: false,
  isLoading: false,
  title: 'Company admins',
  showEmailAddress: false,
  companyAdmins: true,
};
