import { createApi } from '@reduxjs/toolkit/query/react';
import { networkBaseQueryV2 } from './queries';

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: networkBaseQueryV2,
  tagTypes: ['network-counts'],
  endpoints: (builder) => ({
    getNetworkCounts: builder.query({
      query: () => ({
        url: '/search/network_counts',
      }),
      transformResponse: (response) => ({ companiesCount: 0, jobsCount: 0, ...response?.results }),
      transformErrorResponse: (response) => ({ ...response, companiesCount: 0, jobsCount: 0 }),
    }),
  }),
});

export const { useGetNetworkCountsQuery, useLazyGetNetworkCountsQuery } = searchApi;
