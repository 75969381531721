import React from 'react';
import { Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import { Pen, X } from 'lucide-react';

export const PreviewAutoUpdate = ({ updateSmartOption }) => (
  <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
    <Flex sx={{ gap: '8px' }}>
      <Button
        icon={Pen}
        iconGap="8px"
        variant="secondary"
        size="small"
        onClick={() => {
          updateSmartOption({ type: 'autoUpdate', action: 'edit' });
        }}
      >
        Edit filters
      </Button>
      <Button
        icon={X}
        iconGap="8px"
        variant="secondary"
        size="small"
        onClick={() => {
          updateSmartOption({ type: 'autoUpdate', action: 'disable' });
        }}
      >
        Disable
      </Button>
    </Flex>
  </Flex>
);

PreviewAutoUpdate.propTypes = {
  updateSmartOption: PropTypes.func.isRequired,
};
