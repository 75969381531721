import { Button } from '@getro/rombo';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Check, MinusCircle, Plus } from 'lucide-react';
import { useAddContactToSharedListMutation, useRemoveContactFromSharedListMutation } from 'services/contacts';
import { trackEvent } from 'helpers/analytics';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';

const AddContactsToList = ({ page, queryParams, contact, addedToSharedList, matchId, list, fullContactView }) => {
  const [addContactToSharedList, { isLoading: isLoadingAddContact }] = useAddContactToSharedListMutation();
  const [removeContactFromSharedList, { isLoading: isLoadingRemoveContact }] = useRemoveContactFromSharedListMutation();
  const { id } = useSelector(activeNetworkSelector);
  const contactInList = matchId
    ? addedToSharedList
    : list?.id && contact?.sharedList?.some((item) => item.id === parseInt(list.id, 10));

  const onAddContactToList = async () => {
    trackEvent('contacts:list:add_contact_single', {
      listId: list?.id,
      collectionId: id,
      origin: fullContactView ? 'full_profile_cta' : 'card_cta',
      page,
    });

    addContactToSharedList({
      queryParams,
      list: { id: parseInt(list.id, 10), name: list?.name },
      contactId: contact.id,
      matchId,
    });
  };

  const onRemoveContactToList = () => {
    trackEvent('contacts:list:remove_contact_single', {
      listId: parseInt(list.id, 10),
      collectionId: id,
      origin: fullContactView ? 'full_profile_cta' : 'card_cta',
      page,
    });

    removeContactFromSharedList({
      queryParams,
      list: { id: parseInt(list.id, 10), name: list?.name },
      contactId: contact.id,
      matchId,
    });
  };

  return (
    <Button
      size="small"
      iconProps={{
        size: '16px',
      }}
      sx={{
        '& > div': {
          borderTopColor: 'purple.500',
        },
        ':hover': {
          '[data-testid="added-to-list"]': { display: 'none' },
          '[data-testid="remove-from-list"]': { display: 'flex' },
        },
      }}
      iconGap="8px"
      color="purple.500"
      display="flex"
      justifyContent="center"
      width="100%"
      variant={contactInList ? 'tertiary' : 'accent'}
      loading={isLoadingAddContact || isLoadingRemoveContact}
      onClick={() => {
        if (contactInList) {
          onRemoveContactToList();
        } else {
          onAddContactToList();
        }
      }}
    >
      {contactInList && (
        <>
          <Flex data-testid="added-to-list" alignItems="center" sx={{ gap: '8px' }}>
            <Box as={Check} width="16px" height="16px" aria-hidden="true" />
            <Box as="p">Added</Box>
          </Flex>
          <Box data-testid="remove-from-list" display="none" alignItems="center" sx={{ gap: '8px' }}>
            <Box as={MinusCircle} width="16px" height="16px" aria-hidden="true" />
            <Box as="p">Remove from list</Box>
          </Box>
        </>
      )}
      {!contactInList && (
        <Flex alignItems="center" sx={{ gap: '8px' }}>
          <Box as={Plus} width="16px" height="16px" aria-hidden="true" />
          <Box as="p">Add to list</Box>
        </Flex>
      )}
    </Button>
  );
};

AddContactsToList.propTypes = {
  page: PropTypes.string,
  queryParams: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  addedToSharedList: PropTypes.bool,
  matchId: PropTypes.number,
  list: PropTypes.object.isRequired,
  fullContactView: PropTypes.bool,
};
AddContactsToList.defaultProps = {
  page: '',
  addedToSharedList: false,
  matchId: null,
  fullContactView: false,
};

export default AddContactsToList;
