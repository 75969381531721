import React from 'react';
import PropTypes from 'prop-types';

import { useLazyGetFilterOptionsQuery } from '../../../services/contacts';
import MultiSelect from '../../atoms/MultiSelect';

export const ApiFacet = ({
  label,
  name,
  value,
  onChange,
  disableSearchText,
  notFoundText,
  sharedList,
  orderBy,
  useSearchAsTag,
  useShareListFilter,
  children,
  placeholder,
  useInitialOpenDropDown,
  clickOutsideDownEvent,
  disableSearch,
}) => {
  const [getFilterOptions, { data, isLoading, isFetching }] = useLazyGetFilterOptionsQuery();
  const onSearchChange = (currentValue) => {
    getFilterOptions({
      facet: name,
      query: currentValue,
      sharedList: useShareListFilter ? sharedList : null,
      orderBy,
    });
  };

  return (
    <MultiSelect
      title={label}
      options={data?.map((item) => ({ label: item.charAt(0).toUpperCase() + item.slice(1), value: item })) || []}
      selected={value}
      loading={isLoading || isFetching}
      onSearch={onSearchChange}
      onSelect={(values) => onChange(values)}
      notFoundText={notFoundText}
      debounceTime={300}
      useSearchAsTag={useSearchAsTag}
      isList={!!sharedList?.id}
      footer={children}
      placeholder={placeholder}
      disableSearch={disableSearch}
      disableSearchText={disableSearchText}
      clickOutsideDownEvent={clickOutsideDownEvent}
      onInitialOpenDropDown={
        useInitialOpenDropDown
          ? () => {
              if (useInitialOpenDropDown) {
                getFilterOptions({
                  facet: name,
                  query: '',
                  sharedList: useShareListFilter ? sharedList : null,
                  orderBy,
                });
              }
            }
          : null
      }
    />
  );
};

ApiFacet.propTypes = {
  label: PropTypes.string,
  name: PropTypes.oneOf([
    'job_titles',
    'companies',
    'skills',
    'industry_tags',
    'tags',
    'signup_job_functions',
    'signup_locations',
  ]).isRequired,
  value: PropTypes.array,
  sharedList: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  notFoundText: PropTypes.string.isRequired,
  orderBy: PropTypes.oneOf(['count', 'alpha']),
  useSearchAsTag: PropTypes.bool,
  useShareListFilter: PropTypes.bool,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  useInitialOpenDropDown: PropTypes.bool,
  clickOutsideDownEvent: PropTypes.string,
  disableSearch: PropTypes.bool,
  disableSearchText: PropTypes.string,
};

ApiFacet.defaultProps = {
  label: '',
  value: [],
  sharedList: null,
  orderBy: 'count',
  useSearchAsTag: false,
  useShareListFilter: false,
  children: null,
  placeholder: 'Search',
  useInitialOpenDropDown: false,
  clickOutsideDownEvent: 'click',
  disableSearch: false,
  disableSearchText: '',
};
