import React from 'react';
import { Button } from '@getro/rombo';
import { ExternalLink } from 'lucide-react';
import { Box, Text, Image, Flex } from 'rebass/styled-components';

import Chrome from '../../assets/chrome-extension-logo.png';
import { openChat } from '../../helpers/supportChat';
import { EXTENSION_URL } from '../../services/chromeExtension';

export const InstallChromeExtension = () => (
  <Flex
    data-testid="install-chrome-extension"
    flexDirection="column"
    mb="24px"
    bg="purple.100"
    p="16px"
    sx={{ gap: '16px', borderRadius: '8px' }}
  >
    <Flex alignItems="center" sx={{ gap: '8px' }}>
      <Image flex="0 0 auto" height="32px" src={Chrome} alt="Install extension" />
      <Text color="text.dark" fontWeight="500" fontSize="15px">
        To get started, install our Chrome extension
      </Text>
    </Flex>
    <Box>
      <Text fontSize="14px" fontWeight="400" lineHeight="1.4">
        It will help you importing your contacts and managing them directly from LinkedIn.
      </Text>
      <Text fontSize="14px" fontWeight="400" lineHeight="1.4" mt="8px">
        Come back to this tab after installing it.{' '}
        <Text
          display="inline-block"
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => openChat(null, { name: 'Support' })}
        >
          Need help?
        </Text>
      </Text>
      <Button
        iconGap="8px"
        onClick={() => window.open(EXTENSION_URL)}
        iconPosition="right"
        mt="24px"
        width="100%"
        justifyContent="center"
        icon={ExternalLink}
      >
        Install extension
      </Button>
    </Box>
  </Flex>
);
