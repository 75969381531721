import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Box, Button } from '@getro/rombo';

import EmployerCard from 'components/molecules/EmployerCard';
import MemberCard, { MemberRow } from 'components/molecules/MemberCard';

import InviteAdmin from 'components/molecules/InviteAdmin';
import OrganizationsAPI from 'api/OrganizationsAPI';
import DeclineModal from '../DeclineModal';
import NetworkSchema from '../../../schemas/network';

const StyledMemberCard = styled(MemberCard)`
  margin-bottom: var(--space-md);
`;

const Footer = styled.div`
  margin-top: var(--space-md);
  border-top: 1px solid var(--color-gray-4);
  padding-top: var(--space-md);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button:not(:last-child) {
    margin-right: var(--space-sm);
  }
`;

const IntroductionRequest = ({ introductionRequest, onDecline, onMarkAsDone, activeNetwork, history }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { id, user, customMessage, introducible, status, createdAt } = introductionRequest;
  const { id: activeNetworkId, features, isManager } = activeNetwork;
  const skillsDeactivated = features && features.includes('skills_deactivated');
  const isTalentNetworkActive = features && features.includes('talent_network');

  const buttonText = useMemo(() => {
    if (status === 'gk_asked') return 'Make intro';

    return 'Add point of contact and suggest intro';
  }, [status]);

  const onPrimaryClick = () => {
    if (status === 'gk_asked') {
      history.push({
        pathname: '/introductions',
        search: `?action=gateKeeperAccept&introductionRequestId=${id}`,
      });
    } else {
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => setModalOpen(false);
  const addAdmin = async (firstName, lastName, email, message) => {
    const response = await OrganizationsAPI.addAdmin({
      company: introducible,
      activeNetworkId,
      firstName,
      lastName,
      email,
      customMessage: message,
      isManager,
    });

    if (response !== null) {
      onMarkAsDone(activeNetworkId, introductionRequest);
    }
  };

  return (
    <Box size="sm" className="IntroductionRequest" data-testid="introduction-request">
      <StyledMemberCard member={user} isNetworkManager skillsDeactivated={skillsDeactivated} />

      <MemberRow title="Company">
        <EmployerCard employer={introducible} network={activeNetwork} />
      </MemberRow>
      <MemberRow title="Reason">{customMessage}</MemberRow>
      <MemberRow title="Date">{moment(new Date(createdAt)).format('MMM DD, yyyy')}</MemberRow>

      <Footer>
        <DeclineModal activeNetwork={activeNetwork} introductionRequest={introductionRequest} onDecline={onDecline} />
        <Button variant="primary" onClick={onPrimaryClick}>
          {buttonText}
        </Button>
      </Footer>

      <InviteAdmin
        addAdmin={addAdmin}
        inviter={introducible.name}
        onCloseModal={handleCloseModal}
        open={modalOpen}
        setModalOpen={setModalOpen}
        showTalentNetworkCopy={isTalentNetworkActive}
        adminType="company"
        networkName={activeNetwork.name}
      />
    </Box>
  );
};

IntroductionRequest.propTypes = {
  introductionRequest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatarUrl: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
    introducible: PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
      name: PropTypes.string.isRequired,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ),
      topics: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
    status: PropTypes.string.isRequired,
    customMessage: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  onDecline: PropTypes.func.isRequired,
  onMarkAsDone: PropTypes.func.isRequired,
  activeNetwork: NetworkSchema.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default IntroductionRequest;
