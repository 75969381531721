import React from 'react';
import { Box } from 'rebass/styled-components';
import { Search } from 'lucide-react';
import { Input } from '@rebass/forms/styled-components';
import { Label } from '@rebass/forms';
import PropTypes from 'prop-types';
import { FormLabel } from '@getro/rombo';

const KeywordFilter = ({ value, onChange, showLabel }) => (
  <Label mb="0" flexDirection="column" display="flex">
    {showLabel && <FormLabel>Keyword</FormLabel>}
    <Box color="neutral.300" display="flex" alignItems="center" sx={{ position: 'relative' }} width={1}>
      <Box
        variant="icon"
        sx={{ stroke: 'currentColor', position: 'absolute', left: 2 }}
        height="16px"
        width="16px"
        strokeWidth="1.5"
        aria-hidden="true"
        as={Search}
        mr={1}
      />
      <Input
        data-testid="search-input"
        variant="search"
        value={value}
        type="text"
        placeholder="Search by title"
        onChange={onChange}
      />
    </Box>
  </Label>
);

KeywordFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
};

KeywordFilter.defaultProps = {
  value: '',
  showLabel: true,
};

export default KeywordFilter;
