import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { get } from 'api';
import { getUserDisplayName } from 'helpers/users';
import { trackException } from 'helpers/errorTracker';

import { Search } from '@getro/rombo';
import Modal from 'components/atoms/Modal';
import ShareLink from 'components/molecules/ShareLink';
import MemberSchema from '../../../schemas/member';

const InitialStep = ({ member, members: initialMembers, activeNetworkId, open, onSubmit, onCancel }) => {
  const [members, setMembers] = useState(initialMembers);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const fetchMembersCancelToken = useRef(axios.CancelToken.source());

  const handleChange = ({ target }) => setMembers(target.value);

  const handleSearch = async (query) => {
    fetchMembersCancelToken.current.cancel();
    fetchMembersCancelToken.current = axios.CancelToken.source();

    try {
      const response = await get(`/collections/${activeNetworkId}/admins`, {
        params: {
          roles: ['employer_admin'],
          keyword: query,
        },
        cancelToken: fetchMembersCancelToken.current.token,
      });

      return response.data.items.map(({ id, firstName, lastName, avatarUrl, role, companiesName }) => ({
        value: id,
        label: `${getUserDisplayName({ firstName, lastName })} - ${role} at ${companiesName}`,
        avatarUrl,
      }));
    } catch (error) {
      trackException(error);
      return [];
    }
  };

  useEffect(() => {
    setSubmitDisabled(members.length === 0);
  }, [members]);

  return (
    <Modal
      title={`Share ${getUserDisplayName(member)} with companies`}
      saveText="Draft email"
      cancelText="Cancel"
      onSubmit={() => onSubmit(members)}
      open={open}
      onCancelClick={onCancel}
      simple={false}
      submitDisabled={submitDisabled}
      data-testid="make-intro-initial-step"
    >
      <>
        <Search
          multiple
          name="memberIds"
          placeholder="Select companies"
          onSearch={handleSearch}
          onChange={handleChange}
          value={members}
          showAvatar
        />

        <ShareLink
          title="Share a link instead"
          url={`${window.location.href.split('?')[0]}?filters={"id":${member.id}}`}
        >
          <p>
            Use this link to share this member’s profile. This link can only be viewed by people with access to your
            network.
          </p>
        </ShareLink>
      </>
    </Modal>
  );
};

InitialStep.propTypes = {
  member: MemberSchema.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
      role: PropTypes.string.isRequired,
      companiesName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeNetworkId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default InitialStep;
