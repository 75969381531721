import { ContactDetail } from 'components/organisms/contactDetail';
import React, { useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';

export const AiContactDetail = ({ data, isLoading, queryParams }) => {
  const { contactId, listId } = useParams();
  const [showAddNote, setShowAddNote] = useState(false);
  const activeNetwork = useSelector(activeNetworkSelector);
  const history = useHistory();

  const match = useMemo(() => {
    if (!data?.matchingProject) return {};

    const foundMatch = data?.matchingProject?.matches.find((item) => item.contact.id.toString() === contactId);

    if (!foundMatch) return {};

    return foundMatch;
  }, [contactId, data]);

  return (
    <ContactDetail
      queryParams={queryParams}
      loading={isLoading}
      showAddNote={showAddNote}
      isTalentNetworkEnabled={!activeNetwork?.tnHidden}
      contact={match.contact}
      type="matches"
      page="list:matches"
      list={{
        id: listId,
      }}
      addedToSharedList={match.addedToSharedList}
      onClose={() => {
        history.push(`/networks/${activeNetwork.slug}/list/${listId}/ai-matches${history.location.search}`);
        setShowAddNote(false);
      }}
      match={match}
      mb="0px"
      matchListInfo={
        data?.matchingProject
          ? {
              id: data.matchingProject?.id,
              job: data.matchingProject?.job || {},
              organization: data.matchingProject?.organization,
              name: data?.name,
              listId,
            }
          : {}
      }
      showAddToLisButton
    />
  );
};

AiContactDetail.propTypes = {
  queryParams: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

AiContactDetail.defaultProps = {
  isLoading: false,
};
