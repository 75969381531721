import { takeLatest, put, call, debounce, select } from 'redux-saga/effects';
import { request } from '../../api';
import strings from '../../strings';
import {
  errorSendShareEmail,
  errorShareUserOptions,
  loadedSendShareEmail,
  loadedShareUserOptions,
  loadSendShareEmail,
  loadShareUserOptions,
} from './actions';
import { activeNetworkSelector } from '../../redux/selectors';
import { getUserDisplayName } from '../../helpers/users';

function* loadSendShareEmailEvent({ payload }) {
  const activeNetwork = yield select(activeNetworkSelector);
  const { note, boardsToShare, shareWith } = payload;
  try {
    const boardIds = boardsToShare.map(({ value }) => value);
    yield call(request, {
      url: `/collections/${activeNetwork.id}/share`,
      options: {
        method: 'post',
        data: {
          note,
          to: shareWith.map(({ value }) => value),
          boardIds,
          allBoards: boardsToShare.length === 0,
        },
      },
    });
    yield put(loadedSendShareEmail({ note, boardIds, sharedWith: shareWith }));
  } catch (err) {
    yield put(errorSendShareEmail({ error: strings.genericError }));
  }
}

function* loadShareUserOptionsEvent({ payload }) {
  const activeNetwork = yield select(activeNetworkSelector);
  const { query } = payload;
  try {
    const response = yield call(request, {
      url: `/collections/${activeNetwork.id}/admins`,
      options: {
        params: {
          keyword: query,
        },
      },
    });
    yield put(
      loadedShareUserOptions({
        userOptions: response.data.items.map(({ id, firstName, lastName, avatarUrl, role, companiesName }) => ({
          value: id,
          displayName: getUserDisplayName({ firstName, lastName }),
          label: `${getUserDisplayName({ firstName, lastName })} - ${role} at ${companiesName}`,
          role,
          firstName,
          lastName,
          companiesName,
          avatarUrl,
        })),
      }),
    );
  } catch (err) {
    yield put(errorShareUserOptions({ error: err }));
  }
}

export default function* shareSaga() {
  yield debounce(500, loadShareUserOptions().type, loadShareUserOptionsEvent);
  yield takeLatest(loadSendShareEmail().type, loadSendShareEmailEvent);
}
