import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/atoms/Modal';

import { getUserDisplayName } from 'helpers/users';

import { MessageBlock } from '@getro/rombo';
import MemberSchema from '../../../schemas/member';

const SuccessStep = ({ open, member, onClose }) => (
  <Modal open={open} simple data-testid="make-intro-success-step">
    <MessageBlock type="success" title="We’ve sent your request!" buttonText="Ok" onClick={onClose}>
      <p>We’ve sent the email intros for {getUserDisplayName(member)}</p>
    </MessageBlock>
  </Modal>
);

SuccessStep.propTypes = {
  open: PropTypes.bool,
  member: MemberSchema.isRequired,
  onClose: PropTypes.func.isRequired,
};

SuccessStep.defaultProps = {
  open: false,
};

export default SuccessStep;
