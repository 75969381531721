import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  loadAddCompanyByName,
  loadedAddCompanyByName,
  errorAddCompanyByName,
  resetAddCompany,
  loadBulkAddCompanyByDomain,
  loadedBulkAddCompanyByDomain,
  errorBulkAddCompanyByDomain,
} from './actions';

export const initialState = {
  error: false,
  errorMessage: null,
  needsVerification: false,
  companyDetails: null,
  bulkError: false,
  bulkCompanyDetails: null,
};

/* eslint-disable default-case, no-param-reassign */
const companyProfileReducer = handleActions(
  {
    [loadAddCompanyByName]: produce(() => initialState),
    [resetAddCompany]: produce(() => initialState),
    [loadedAddCompanyByName]: produce((draft, action) => {
      const { payload } = action;
      draft.companyDetails = payload;
      return draft;
    }),
    [errorAddCompanyByName]: produce((draft) => {
      draft.error = true;
      return draft;
    }),
    [loadBulkAddCompanyByDomain]: produce(() => initialState),
    [loadedBulkAddCompanyByDomain]: produce((draft, action) => {
      const { payload } = action;
      draft.bulkCompanyDetails = payload;
      draft.error = false;
      return draft;
    }),
    [errorBulkAddCompanyByDomain]: produce((draft) => {
      draft.error = true;
      return draft;
    }),
  },
  initialState,
);

export default companyProfileReducer;
