import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Filter from '../../../components/molecules/Filter';

export const ADMIN_FILTER = [
  { label: 'Any', value: '' },
  { label: 'At least one', value: 'false' },
  { label: 'No company admins', value: 'true' },
];

const AdminFilter = ({ value, onChange, embeddedInModal, onDropDownStateChange, showLabel }) => {
  const selectedOption = useMemo(() => ADMIN_FILTER.find((i) => i.value === value), [value]) ?? null;

  return (
    <Filter
      showLabel={showLabel}
      label="Admins"
      name="admin"
      placeholder="Admins"
      onChange={(item) => onChange(item)}
      value={selectedOption}
      options={ADMIN_FILTER}
      embeddedInModal={embeddedInModal}
      onDropDownStateChange={onDropDownStateChange}
    />
  );
};

AdminFilter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  embeddedInModal: PropTypes.bool,
  onDropDownStateChange: PropTypes.func,
  showLabel: PropTypes.bool,
};

AdminFilter.defaultProps = {
  value: '',
  embeddedInModal: false,
  onDropDownStateChange: () => {},
  showLabel: true,
};

export default AdminFilter;
