import React, { useMemo, useRef, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import DatePicker, { FORMAT, PRESETS } from 'components/atoms/DatePicker';
import { ChevronDown } from 'lucide-react';
import { useIsMobile } from 'hooks/useIsMobile';

export const CalenderDatePicker = ({ dates, setStartDate }) => {
  const [showPicker, setShowPicker] = useState(false);
  const closeRef = useRef();
  const [isPreset, setIsPreset] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const title = useMemo(() => {
    const selectedPreset = isPreset
      ? PRESETS.find(
          (preset) => dates.startDate.isSameOrAfter(preset.start) && dates.endDate.isSameOrBefore(preset.end),
        )
      : null;

    if (selectedPreset) {
      return selectedPreset.text;
    }

    return `${dates.startDate.format('MMM D, YYYY')} - ${dates.endDate.format('MMM D, YYYY')}`;
  }, [dates.endDate, dates.startDate, isPreset]);

  useIsMobile((windowIsMobile) => {
    setIsMobile(windowIsMobile);
  });

  return (
    <Box>
      <Flex ref={closeRef} sx={{ gap: '8px', alignItems: 'center' }} onClick={() => setShowPicker(!showPicker)}>
        <Text sx={{ cursor: 'pointer' }} color="text-main" fontWeight="500" lineHeight="14px" fontSize="12px">
          {title}
        </Text>
        <Box
          height="16px"
          sx={{
            position: 'relative',
            cursor: 'pointer',
            '.DateRangePicker_picker__portal': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '& > div': {
                m: '0!important',
                mt: '0pximportant',
                position: 'relative!important',
                left: '0!important',
                top: '0!important',
              },
            },
            '.DayPicker': !isMobile
              ? {
                  zIndex: 2,
                  ml: ['0!important'],
                  mt: '0!important',
                  position: ['absolute!important'],
                  transform: ['translateX(-98%)'],
                }
              : {},
            '& > svg': {
              color: 'neutral.500',
            },
          }}
        >
          <Box size="16px" as={ChevronDown} />
          {showPicker && (
            <Box
              sx={{ zIndex: 10 }}
              className={isMobile ? 'DateRangePicker_picker__portal DateRangePicker_picker__portal_30' : ''}
            >
              <DatePicker
                useRangeController
                onSelectDates={(selectedDates, options) => {
                  setStartDate(selectedDates);
                  if (options.isPreset) {
                    setIsPreset(true);
                    setShowPicker(false);
                  } else if (isPreset) {
                    setIsPreset(false);
                  }
                  if (!selectedDates.endDate.isSame(dates.endDate.format(FORMAT))) {
                    setShowPicker(false);
                  }
                }}
                onOutsideClick={() => {
                  setTimeout(() => {
                    setShowPicker(false);
                  }, 100);
                }}
                formatDate={false}
                startDate={dates.startDate.format(FORMAT)}
                endDate={dates.endDate.format(FORMAT)}
              />
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

CalenderDatePicker.propTypes = {
  dates: PropTypes.shape({
    startDate: PropTypes.object,
    endDate: PropTypes.object,
  }).isRequired,
  setStartDate: PropTypes.func.isRequired,
};
