import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { FormikTextArea } from '@getro/rombo';
import Modal from 'components/atoms/Modal';
import MemberCard from 'components/molecules/MemberCard';
import { getUserDisplayName } from 'helpers/users';
import MemberSchema from '../../../schemas/member';

const formSchema = Yup.object().shape({
  customMessage: Yup.string()
    // eslint-disable-next-line
    .min(60, 'Please write at least ${min} characters.')
    .required(),
});

const CustomStep = ({ open, onSubmit, onCancel, member, customMessage, isNetworkManager, skillsDeactivated }) => {
  const onFormSubmit = (values) => {
    onSubmit(values);
  };

  return (
    <Modal
      title={`Share ${getUserDisplayName(member)} with companies`}
      saveText="Review and Send"
      cancelText="Go back"
      onSubmit={onFormSubmit}
      formik
      formikProps={{
        initialValues: {
          customMessage,
        },
        validationSchema: formSchema,
      }}
      open={open}
      onCancelClick={onCancel}
      simple={false}
      data-testid="make-intro-custom-step"
    >
      <>
        <FormikTextArea label="Body of your email" name="customMessage" />
        <MemberCard member={member} isNetworkManager={isNetworkManager} skillsDeactivated={skillsDeactivated} />
      </>
    </Modal>
  );
};

CustomStep.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  member: MemberSchema.isRequired,
  customMessage: PropTypes.string,
  isNetworkManager: PropTypes.bool,
  skillsDeactivated: PropTypes.bool,
};

CustomStep.defaultProps = {
  open: false,
  customMessage: '',
  isNetworkManager: false,
  skillsDeactivated: false,
};

export default CustomStep;
