import React from 'react';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';
import { Box, Text, Flex } from 'rebass/styled-components';
import { Tooltip } from '@getro/rombo';

export const EditableActionItem = ({ extendMethods, item, onRemove, ...rest }) => {
  const styledButton = (
    <Flex
      maxWidth="200px"
      display="inline-block"
      pt="1"
      fontSize="1"
      color="neutral.400"
      alignItems="center"
      sx={{
        whiteSpace: 'nowrap',
        marginRight: '1',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderRadius: 'default',
        userSelect: 'none',
        '&&:hover > [data-item]': {
          opacity: 1,
        },
      }}
      {...rest}
    >
      <Text
        color="text.main"
        sx={{
          textDecoration: 'none',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: extendMethods?.onShowDetailView ? 'pointer' : 'auto',
        }}
        onClick={() => extendMethods?.onShowDetailView?.()}
      >
        {item.label}
      </Text>
      {onRemove && (
        <Box
          as={X}
          data-item="close-icon"
          ml={2}
          width="16px"
          height="16px"
          flex="0 0 auto"
          sx={{ opacity: 0, cursor: 'pointer' }}
          onClick={(event) => {
            if (onRemove) {
              event.stopPropagation();
              onRemove();
            }
          }}
        />
      )}
    </Flex>
  );

  return item.label?.length > 20 ? <Tooltip trigger={styledButton} content={item.label} /> : styledButton;
};

EditableActionItem.propTypes = {
  extendMethods: PropTypes.object,
  item: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
};

EditableActionItem.defaultProps = {
  extendMethods: null,
  onRemove: null,
};
