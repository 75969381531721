import { useState } from 'react';
import { getCompanyFundTypeFormOptions } from '../../../services/organizations/organizationFundTypes';

export const useOrganizationStage = () => {
  const defaultOptions = getCompanyFundTypeFormOptions();

  const [options, setOptions] = useState(defaultOptions);

  const search = (query) => {
    setOptions(query ? options.filter((opt) => opt.label.toLowerCase().includes(query.toLowerCase())) : defaultOptions);
  };

  return { options, search, isFetching: false };
};
