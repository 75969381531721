import { Tooltip } from '@getro/rombo';
import { AlertTriangle } from 'lucide-react';
import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const InPortco = ({ showTooltip }) =>
  showTooltip ? (
    <Tooltip
      content="Works at a portfolio company"
      trigger={
        <Flex maxWidth="fit-content" alignItems="center" color="icon.amber">
          <Box as={AlertTriangle} height="16px" width="16px" aria-hidden="true" />
          <Text ml="8px" fontSize="14px" color="text.main">
            Works at a network company
          </Text>
        </Flex>
      }
    />
  ) : (
    <Flex maxWidth="fit-content" alignItems="center" color="icon.amber">
      <Box as={AlertTriangle} height="16px" width="16px" aria-hidden="true" />
      <Text ml="8px" fontSize="14px" color="text.main">
        Works at a network company
      </Text>
    </Flex>
  );

InPortco.propTypes = {
  showTooltip: PropTypes.bool,
};

InPortco.defaultProps = {
  showTooltip: true,
};

export default InPortco;
