import PropTypes from 'prop-types';
import NetworkSchema from './network';

const ContactsSharedListSchema = PropTypes.shape({
  contactsCount: PropTypes.number,
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  passwordProtected: PropTypes.bool,
  shareable: PropTypes.bool,
  slug: PropTypes.string,
  password: PropTypes.string,
  pointOfContact: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
  networkLogoUrl: PropTypes.string,
  network: NetworkSchema,
  contactsTotal: PropTypes.number,
});

export default ContactsSharedListSchema;
