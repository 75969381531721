import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const key = 'job';

const rootSelector = (state) => {
  if (state && state[key]) {
    return state[key];
  }
  return initialState;
};

export const isJobInitializedSelector = createSelector(rootSelector, ({ initialized }) => initialized);
export const isJobErroredSelector = createSelector(rootSelector, ({ error }) => error);
export const jobDetailsSelector = createSelector(rootSelector, ({ details }) => details);
