import { useEffect } from 'react';
import currentVersion from '../buildVersion';
import { semverGreaterThan } from '../helpers/semver';

const useCacheBuster = () => {
  useEffect(() => {
    const headers = new Headers();
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');

    const init = { method: 'GET', headers };

    fetch('/app/meta.json', init)
      .then(async (response) => response.json())
      .then((meta) => {
        const { version: latestVersion } = meta;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          if (caches) {
            caches.keys().then(async (names) => {
              const requests = [];
              for (let i = 0, l = names.length; i < l; i += 1) {
                requests.push(caches.delete(names[i]));
              }

              await Promise.allSettled(requests);
            });
          }

          // delete browser cache and hard reload
          window.location.reload(true);
        }
      });
  }, []);
};

export default useCacheBuster;
