import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Button, ProfilePicture, Tooltip } from '@getro/rombo';
import PropTypes from 'prop-types';
import { Pen, X } from 'lucide-react';

export const PreviewAiMatches = ({ match, updateSmartOption }) => (
  <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', gap: '24px' }}>
    <Flex mt="4px" alignItems="center" sx={{ gap: '8px ' }}>
      <Box width="40px" height="40px">
        <ProfilePicture imageUrl={match.organization.logoUrl} name={match.organization.name} variant="square" />
      </Box>
      <Box>
        <Tooltip
          position="top"
          content={match.job.title}
          trigger={
            <Text
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              fontSize="14px"
              fontWeight="medium"
              color="text.dark"
            >
              {match.job.title}
            </Text>
          }
        />
        <Text fontSize="12px" color="text.subtle">
          {match.organization.name}
        </Text>
      </Box>
    </Flex>
    <Flex sx={{ gap: '8px', minWidth: '230px', justifyContent: 'flex-end' }}>
      <Button
        icon={Pen}
        iconGap="8px"
        variant="secondary"
        size="small"
        onClick={() =>
          updateSmartOption({
            type: 'matches',
            matchType: match.job.id ? 'job' : 'private',
            action: 'edit',
          })
        }
      >
        Edit job
      </Button>
      <Button
        icon={X}
        iconGap="8px"
        variant="secondary"
        size="small"
        onClick={() =>
          updateSmartOption({
            type: 'matches',
            matchType: match.job.id ? 'job' : 'private',
            action: 'disable',
          })
        }
      >
        Disable
      </Button>
    </Flex>
  </Flex>
);

PreviewAiMatches.propTypes = {
  match: PropTypes.object.isRequired,
  updateSmartOption: PropTypes.func.isRequired,
};
