import React, { useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Button, Modal } from '@getro/rombo';
import { CheckCircle, PenTool, PlusCircle, Zap } from 'lucide-react';

export const AddCompanyProcess = () => {
  const [show, setShow] = useState(false);
  const info = [
    {
      icon: PlusCircle,
      title: 'Company added',
      content:
        'Companies are visible on your admin portal, but are not visible on your job board until their information is validated.',
    },
    {
      icon: PenTool,
      title: 'Draft information',
      content: 'We automatically add draft information as a first step, it can take a few minutes.',
    },
    {
      icon: CheckCircle,
      title: 'Verified information and publishing',
      content: (
        <Box>
          Our data team will add the right information and publish the company. <br />
          Optionally, you can edit the company information and publish it (doing this means that our team won’t add
          information anymore).
        </Box>
      ),
    },
    {
      icon: Zap,
      title: 'Verified information and publishing',
      content: (
        <Box>
          Our data team will add the right information and publish the company. <br />
          Optionally, you can edit the company information and publish it (doing this means that our team won’t add
          information anymore).
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box onClick={() => setShow(true)} as="a">
        Learn more.
      </Box>
      {show && (
        <Box sx={{ '& > div > div:last-child': { maxWidth: '800px', padding: '8px' } }}>
          <Modal
            title="Add company process"
            isOpen
            variant="large"
            onCancel={() => setShow(false)}
            actions={
              <Flex justifyContent="flex-end">
                <Button onClick={() => setShow(false)}>Ok</Button>
              </Flex>
            }
          >
            <Text fontSize="16px" lineHeight="22.4px" mb="32px">
              Companies that we already have information about are visible on your job board immediately. Sometimes we
              don’t have, here’s how it works:
            </Text>

            <Flex flexDirection="column" sx={{ rowGap: '24px' }}>
              {info.map(({ title, content, icon }, index) => (
                <Flex flexDirection="column" sx={{ rowGap: '8px' }}>
                  <Flex alignItems="center" sx={{ columnGap: '16px' }}>
                    <Box color="#6D33FF" as={icon} strokeWidth="1.5" />
                    <Text fontSize="16px" fontWeight="500" as="h2">
                      {title}
                    </Text>
                  </Flex>
                  <Flex sx={{ columnGap: '16px' }}>
                    <Flex justifyContent="center" width="100%" maxWidth="24px">
                      <Box width="2px" bg={index !== info.length - 1 ? '#EDF2F7' : 'transparent'} />
                    </Flex>
                    <Text pb="8px" fontSize="14px" lineHeight="19.6px" as="p">
                      {content}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
            <Text mt="32px" fontSize="16px">
              We email you when information is added and jobs are set up. The whole process usually takes less than 2
              business days since the companies are added.
            </Text>
          </Modal>
        </Box>
      )}
    </>
  );
};
