import React from 'react';

import pageHoc from 'hocs/pageHoc';

import MainLayout from 'components/layout/Main';
import DeleteAccount from './DeleteAccount';
import LoginInfo from './LoginInfo';
import RequestData from './RequestData';
import AccountLayout from '../../components/layout/AccountLayout';

const pageTitle = 'My account';

const AccountTab = () => (
  <MainLayout>
    <AccountLayout>
      <LoginInfo />
      <RequestData />
      <DeleteAccount />
    </AccountLayout>
  </MainLayout>
);

export default pageHoc({ title: pageTitle })(AccountTab);
