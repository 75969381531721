import React from 'react';
import { Edit3, Eye, EyeOff, Trash } from 'lucide-react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { marked } from 'marked';
import dompurify from 'dompurify';

import { trackEvent } from '../../../../helpers/analytics';

export const ViewMode = ({ type, currentNote = {}, setShowEditMode, setShowDeleteModal }) => {
  const onEditNote = () => {
    setShowEditMode(true);

    trackEvent('contacts:notes:edit', {
      origin: 'note_view',
    });
  };

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="5" fontWeight="semibold" color="text.dark">
          {currentNote?.title}
        </Text>
        <Flex>
          {type !== 'public' && (
            <Flex alignItems="center" sx={{ cursor: 'pointer' }} onClick={onEditNote}>
              <Box as={Edit3} width="16px" height="16px" aria-hidden="true" />
              <Text ml="8px" fontSize="14px" fontWeight="medium">
                Edit
              </Text>
            </Flex>
          )}
          {type !== 'public' && (
            <Flex ml="20px" alignItems="center" sx={{ cursor: 'pointer' }} onClick={() => setShowDeleteModal(true)}>
              <Box as={Trash} width="16px" height="16px" aria-hidden="true" />
              <Text ml="8px" fontSize="14px" fontWeight="medium">
                Delete
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex mt="8px">
        <Text fontSize="14px">{currentNote?.author.fullName}</Text>
        <Text ml="8px" fontSize="14px" color="text.subtle">
          {new Date(currentNote?.updatedAt).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </Text>
        {type !== 'public' && (
          <>
            {currentNote?.external ? (
              <Flex ml="8px" alignItems="center" color="text.subtle">
                <Box as={Eye} width="16px" height="16px" aria-hidden="true" />
                <Text ml="8px" fontSize="14px">
                  Visible to companies
                </Text>
              </Flex>
            ) : (
              <Flex ml="8px" alignItems="center" color="text.subtle">
                <Box as={EyeOff} width="16px" height="16px" aria-hidden="true" />
                <Text ml="8px" fontSize="14px">
                  Internal
                </Text>
              </Flex>
            )}
          </>
        )}
      </Flex>
      <Text mt="32px" fontSize="14px">
        <Box
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(marked(currentNote?.body || '')),
          }}
          sx={{
            '&& *:first-child': {
              mt: 0,
              marginBlockStart: '0 !important',
            },
            '&& h1, h2': {
              color: 'text',
            },
            '&& strong': {
              fontWeight: 'medium',
            },
          }}
        />
      </Text>
    </Box>
  );
};

ViewMode.propTypes = {
  type: PropTypes.oneOf(['default', 'read', 'matches', 'public']),
  currentNote: PropTypes.object,
  setShowEditMode: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
};

ViewMode.defaultProps = {
  type: 'default',
  currentNote: {},
};
