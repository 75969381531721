/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { Field, FastField, Form, Formik } from 'formik';
import isEqual from 'lodash.isequal';
import {
  Button,
  Checkbox,
  FormInput,
  FormTextEditor,
  FormSelect,
  ReduxSelect,
  Tag,
  FormLabel,
  FormSectionTitle,
  Panel,
} from '@getro/rombo';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import Compensation from './Compensation';
import strings from '../../../strings';
import { EXPIRATION_OPTIONS } from '../../molecules/RenewJob/constants';
import { APPLICATION_METHOD_OPTIONS, EMPTY_VALUES } from './constants';
import { GenericError } from '../../molecules/genericError';
import JobMessage from './JobMessage';
import { emailRegEx, urlWithoutProtocolRegEx } from '../../../helpers/regExs';
import { activeNetworkSelector, userSelector } from '../../../redux/selectors';
import useJobRules from '../../../hooks/jobRules/useJobRules';
import useJobFunctions from '../../../hooks/useJobFunctions';
import { useInjectReducer } from '../../../hooks/useInjectReducer';
import { useInjectSaga } from '../../../hooks/useInjectSaga';
import {
  jobLocationsSelector,
  jobOrganizationsSelector,
  jobSelector,
  key,
  loadingErrorSelector,
  saveSuccessSelector,
} from './redux/selectors';
import reducer from './redux/reducer';
import saga from './redux/saga';
import {
  loadEditedJob,
  loadJobLocations,
  loadJobOrganizations,
  loadSaveEditedJob,
  resetEditedJob,
} from './redux/actions';
import { makeSelectIsLoading } from '../../../redux/loadingSelector';
import { useAllOrganizations } from '../../../hooks/useAllOrganizations';
import { euAndUSRegex } from './helpers';
import ShowJobRulesModal from './ShowJobRulesModal';
import CompensationModal from './CompensationModal';
import JobRulesSection from './JobRulesSection';
import { companySlugParser } from '../../../helpers/companySlugParser';

export const JobEditor = ({ onCancelClick, action, selectCompany, analyticsPage }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const params = useParams();
  const activeNetwork = useSelector(activeNetworkSelector);
  const { jobRules, isJobRulesLoading, loadJobRulesRequest } = useJobRules();
  const [showJobRules, setShowJobRules] = useState(false);
  const loadingJob = useSelector(makeSelectIsLoading('editedJob'));
  const savingJob = useSelector(makeSelectIsLoading('saveEditedJob'));
  const loadingJobOrganizations = useSelector(makeSelectIsLoading('jobOrganizations'));
  const loadingJobLocations = useSelector(makeSelectIsLoading('jobLocations'));
  const success = useSelector(saveSuccessSelector);
  const [selectedExpirationDate, setSelectedExpirationDate] = useState(null);
  const [initialValues, setInitialValues] = useState(EMPTY_VALUES);
  const loadingError = useSelector(loadingErrorSelector);
  const job = useSelector(jobSelector);
  const [jobFunctions] = useJobFunctions();
  const jobOrganizations = useSelector(jobOrganizationsSelector);
  const jobLocations = useSelector(jobLocationsSelector);
  const currentUser = useSelector(userSelector);
  const [showCompensationModal, setShowCompensationModal] = useState(false);
  const theme = useTheme();

  const {
    allOrganizationsList,
    isAllOrganizationsInitialized,
    isAllOrganizationsLoading,
    hasAllOrganizationsInitializationFailed,
  } = useAllOrganizations();

  const boxTitle = useMemo(() => {
    if (action === 'edit-job') {
      return 'Edit job';
    }
    if (action === 'duplicate-job') {
      return 'Duplicate job';
    }

    return 'Post a job';
  }, [action]);

  const saveText = useMemo(() => {
    if (action === 'edit-job') {
      return 'Update job';
    }

    if (action === 'duplicate-job') {
      return 'Duplicate job';
    }

    return 'Post job';
  }, [action]);

  const editing = useMemo(() => action === 'edit-job', [action]);

  useEffect(() => {
    if (isAllOrganizationsInitialized && action !== 'edit-job' && action !== 'duplicate-job' && !selectCompany) {
      const [companyId, companySlug] = companySlugParser(params.companyId);

      let values;
      if (companyId) {
        values = {
          ...initialValues,
          companyId,
        };
      } else {
        const company = allOrganizationsList.find(({ slug }) => slug === companySlug);
        values = {
          ...initialValues,
          companyId: company?.id,
        };
      }

      if (!isEqual(initialValues, values)) {
        setInitialValues(values);
      }
    }
  }, [isAllOrganizationsInitialized, allOrganizationsList, action, selectCompany, params.companyId, initialValues]);

  const eventName = useMemo(() => {
    if (action === 'edit-job') {
      return `${analyticsPage}:edit_job`;
    }

    if (action === 'duplicate-job') {
      return `${analyticsPage}:duplicate_job`;
    }

    return `${analyticsPage}:add_job`;
  }, [action, analyticsPage]);

  const isLoaded = useMemo(() => {
    const { jobId } = params;
    const { id } = initialValues;

    if (!jobId) return true;
    if (loadingJob) return false;

    return parseInt(id, 10) === parseInt(jobId, 10);
  }, [initialValues, loadingJob, params]);

  useEffect(
    () => () => {
      dispatch(resetEditedJob());
    },
    [dispatch],
  );

  useEffect(() => {
    const { jobId } = params;

    if (jobId) {
      dispatch(loadEditedJob({ jobId }));
    }
  }, [dispatch, params]);

  useEffect(() => {
    const { error, id, expirationDate, applicationMethod, notifiedUsers, ...otherJobAttributes } = job;
    let initialApplicationMethod = applicationMethod;
    let initialNotifiedUsers = Array.isArray(notifiedUsers) ? notifiedUsers.join(', ') : notifiedUsers;
    if (action === 'duplicate-job' || !action) {
      initialApplicationMethod = 'on_job_board';
      initialNotifiedUsers = currentUser.email;
    }

    if (!error && !loadingJob) {
      setInitialValues({
        id,
        expirationDate: action === 'duplicate-job' ? EXPIRATION_OPTIONS[1] : expirationDate,
        applicationMethod: initialApplicationMethod,
        notifiedUsers: initialNotifiedUsers,
        ...otherJobAttributes,
      });
    }
  }, [action, currentUser.email, job, loadingJob]);

  const handleSubmit = async (values) => {
    if ((!values.compensationMax || !values.compensationMin) && !showCompensationModal) {
      const isCountryEuOrUSA = values.locations
        .map(({ label }) => {
          if (!label) return '';
          const splittedLabels = label.split(', ');

          return splittedLabels[splittedLabels.length - 1];
        })
        .some((country) => euAndUSRegex.test(country));

      if (isCountryEuOrUSA) {
        setShowCompensationModal(true);
        return;
      }
    }
    if (!editing) {
      const { expirationDate } = values;
      setSelectedExpirationDate(expirationDate);
    }

    dispatch(
      loadSaveEditedJob({
        values,
        editing,
        action,
        eventName,
      }),
    );
  };

  const showJobRulesModal = () => {
    loadJobRulesRequest();
    setShowJobRules(true);
  };

  const employmentTypeOptions = Object.keys(strings.profile.employment)
    .filter((k) => k !== 'employment_type_unspecified')
    .map((k) => ({ value: k, label: strings.profile.employment[k] }));

  const formSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
  });

  const validate = (values) => {
    const errors = {};

    if (!values.applicationPath && values.applicationMethod !== 'on_job_board') {
      errors.applicationPath = 'Required';
    }
    if (selectCompany && !values.companyId) {
      errors.companyId = 'Company is a required field';
    }

    if (values.applicationMethod === 'url' && !urlWithoutProtocolRegEx.test(values.applicationPath?.trim())) {
      errors.applicationPath = 'Invalid URL';
    }

    if (!values.remote && (!values.locations || values.locations.length === 0)) {
      errors.locations = 'At least one location is required for non-remote jobs';
    }

    if (values.applicationMethod === 'on_job_board' && values.notifiedUsers.trim() === '') {
      errors.notifiedUsers = 'Email to send applications to is required';
    }

    if (values.applicationMethod === 'on_job_board' && values.notifiedUsers) {
      const emails = values.notifiedUsers
        .split(/[,;]/)
        .map((email) => email.trim())
        .filter((email) => !!email);

      emails.forEach((email) => {
        if (!emailRegEx.test(email)) {
          errors.notifiedUsers = `${email} is not a valid email address`;
        }
      });

      const duplicatedEmails = emails.filter((email, index) => emails.indexOf(email) !== index);
      if (duplicatedEmails.length) {
        errors.notifiedUsers = `${duplicatedEmails.join(', ')} ${
          duplicatedEmails.length === 1 ? 'is' : 'are'
        } duplicated`;
      }
    }

    return errors;
  };

  const triggerSearchOrganization = (query) => {
    dispatch(loadJobOrganizations({ query }));
  };

  const triggerSearchLocation = (query) => {
    dispatch(loadJobLocations({ query }));
  };

  if (loadingError || hasAllOrganizationsInitializationFailed) {
    return <JobMessage type="loading_error" onClick={onCancelClick} />;
  }

  if (!isLoaded || !jobFunctions.length || isAllOrganizationsLoading) {
    return <JobMessage type="loading" boxTitle={boxTitle} />;
  }

  if (success) {
    return (
      <JobMessage type="success" editing={editing} expirationDate={selectedExpirationDate} onClick={onCancelClick} />
    );
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
        validate={validate}
      >
        {({ values, setFieldValue, status, errors }) => (
          <Form>
            <Panel maxWidth={800}>
              <Flex flexDirection="column" sx={{ rowGap: 24 }}>
                <FormSectionTitle pt={0}>JOB DETAILS</FormSectionTitle>
                <JobRulesSection network={activeNetwork} onShowJobRulesModal={showJobRulesModal} />
                {selectCompany && (
                  <Box width={[1]}>
                    <FormLabel extra="Required" mb="8px">
                      Company
                    </FormLabel>
                    <Field
                      component={ReduxSelect}
                      name="companyId"
                      id="companyId"
                      onChange={(value) => setFieldValue('companyId', value)}
                      value={values.companyId}
                      onInputChange={triggerSearchOrganization}
                      isLoading={loadingJobOrganizations}
                      options={jobOrganizations}
                      orientation="horizontal"
                      placeholder="Start typing"
                      errors={errors.companyId}
                      label="Test field"
                      theme={{ colors: { textPlaceholder: theme.colors.neutral[300] } }}
                    />
                  </Box>
                )}
                {!selectCompany && <FastField type="hidden" name="companyId" />}
                <FastField
                  name="title"
                  component={FormInput}
                  placeholder="Ex: Senior account manager"
                  label="Title"
                  labelExtra="Required"
                />
                <FastField
                  component={FormTextEditor}
                  name="description"
                  label="Description"
                  placeholder="Enter the position details"
                  options={['bold', 'italic', 'h1', 'h2', 'list', 'link']}
                  labelExtra="Required"
                />
                <FastField
                  component={FormSelect}
                  name="jobFunctions"
                  label="Job functions"
                  placeholder="Select"
                  options={jobFunctions}
                  multiple
                />
                <Box width={[1]}>
                  <FormLabel extra="Required" sx={{ mb: '8px' }}>
                    Locations
                  </FormLabel>
                  <ReduxSelect
                    isMulti
                    name="locations"
                    onChange={(value) => setFieldValue('locations', value || [])}
                    value={values.locations}
                    onInputChange={triggerSearchLocation}
                    isLoading={loadingJobLocations}
                    options={jobLocations}
                    orientation="horizontal"
                    placeholder="Start typing"
                    error={errors.locations}
                    theme={{ colors: { textPlaceholder: theme.colors.neutral[300] } }}
                  />
                  {values.locations.length > 0 && (
                    <Flex flexDirection="row" flexWrap="wrap">
                      {values.locations.map((location) => (
                        <Tag
                          key={`job_location_${location.value}`}
                          mt={2}
                          onRemove={() => {
                            setFieldValue(
                              'locations',
                              values.locations.filter((s) => s.value !== location.value),
                            );
                          }}
                        >
                          {location.label}
                        </Tag>
                      ))}
                    </Flex>
                  )}
                </Box>
                <Checkbox
                  label="Remote position"
                  name="remote"
                  value={values.remote}
                  checked={values.remote}
                  onChange={() => setFieldValue('remote', !values.remote)}
                />
                <FastField
                  component={FormSelect}
                  name="employmentTypes"
                  label="Employment type"
                  placeholder="Select"
                  options={employmentTypeOptions}
                  multiple
                />
                <FormSectionTitle>COMPENSATION</FormSectionTitle>
                <Flex flexDirection="column">
                  <Text fontSize="1" lineHeight="20px" mb="24px">
                    Job posts that include a salary range can get up to x3 engagement. It is a legal requirement in some
                    US states and European countries.
                  </Text>
                  <Compensation {...{ values, setFieldValue }} />
                  <Box pt={[3]}>
                    <Checkbox
                      name="compensationPublic"
                      label={strings.jobs.showCompensation}
                      value={values.compensationPublic}
                      checked={values.compensationPublic}
                      onChange={() => setFieldValue('compensationPublic', !values.compensationPublic)}
                    />
                  </Box>
                </Flex>
                <FormSectionTitle>APPLICATION</FormSectionTitle>
                <FastField
                  onChange={({ value }) => {
                    if (value !== values.applicationMethod) {
                      setFieldValue('applicationPath', '');
                    }
                    setFieldValue('applicationMethod', value);
                  }}
                  component={FormSelect}
                  label="Application method"
                  name="applicationMethod"
                  placeholder="Select"
                  value={APPLICATION_METHOD_OPTIONS.find(({ value }) => value === values.applicationMethod)}
                  options={APPLICATION_METHOD_OPTIONS}
                />
                {values.applicationMethod === 'on_job_board' && (
                  <FastField
                    component={FormInput}
                    name="notifiedUsers"
                    placeholder="Start typing..."
                    label="Applicants recipients"
                    labelExtra="Required"
                    helpText="Choose who will receive new applicants daily emails. Add multiple emails separated by commas."
                  />
                )}
                {values.applicationMethod === 'email' && (
                  <FastField
                    component={FormInput}
                    name="applicationPath"
                    placeholder="careers@company.com"
                    label="Email address"
                    labelExtra="Required"
                  />
                )}
                {values.applicationMethod === 'url' && (
                  <FastField
                    component={FormInput}
                    name="applicationPath"
                    placeholder="https://www..."
                    label="Website address"
                    labelExtra="Required"
                  />
                )}

                {!editing && (
                  <Box mb={[2]}>
                    <FormSectionTitle mb={4}>EXPIRATION</FormSectionTitle>
                    <FastField
                      component={FormSelect}
                      id="expirationDate"
                      name="expirationDate"
                      options={EXPIRATION_OPTIONS}
                      helpText={
                        <Text fontSize={[1]} mt={[2]} color="text.main">
                          Expiration dates help you keep your job board fresh.{' '}
                          <Link
                            sx={{ color: 'text.main' }}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://help.getro.com/support/solutions/articles/65000168382-post-a-job-from-the-admin-portal"
                          >
                            Learn more
                          </Link>
                          .
                        </Text>
                      }
                      label="Expiration"
                    />
                  </Box>
                )}
                {status && status.error && <GenericError />}
                <Box paddingTop={[3]} sx={{ borderTop: '1px solid', borderTopColor: 'gray.3' }} mb="-8px" width={1}>
                  <Flex justifyContent="flex-end">
                    <Button data-testid="cancel-text" variant="tertiary" onClick={onCancelClick} sx={{ mr: 2 }}>
                      Cancel
                    </Button>
                    <Button variant="primary" loading={savingJob} sx={{ marginTop: '0!important' }} type="submit">
                      {saveText}
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Panel>
            {showCompensationModal && (
              <CompensationModal onCancel={() => setShowCompensationModal(false)} submitting={savingJob} />
            )}
          </Form>
        )}
      </Formik>
      {showJobRules && (
        <ShowJobRulesModal onClose={() => setShowJobRules(false)} jobRules={jobRules} loading={isJobRulesLoading} />
      )}
    </>
  );
};

JobEditor.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  analyticsPage: PropTypes.oneOf(['employer_profile', 'jobs']).isRequired,
  selectCompany: PropTypes.bool,
  action: PropTypes.oneOf(['edit-job', 'duplicate-job']),
};

JobEditor.defaultProps = {
  selectCompany: false,
  action: null,
};
