import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Loader, Tag, Tooltip } from '@getro/rombo';
import { ChevronDown, Plus } from 'lucide-react';
import { Card } from '../Card';
import { useOptionsNavigation } from './hooks/useOptionsNavigation';

const MultiSelectDropDown = ({
  isMulti,
  options: menuOptions,
  selected,
  onSelect,
  onClose,
  onOpen,
  sx,
  trigger,
  showDropDown,
  children,
  loading,
  placeholder,
  bottomText,
  defaultText,
  isOpened,
  disableAddMore,
  disableAddMoreMessage,
  input,
  showSearch,
  onShowSearch,
  onToggle,
}) => {
  const options = useMemo(
    () =>
      isMulti
        ? menuOptions.filter((option) => !selected?.some?.((selectedOption) => selectedOption.value === option.value))
        : menuOptions,
    [isMulti, menuOptions, selected],
  );

  const { currentOption, toggle, ref } = useOptionsNavigation({
    isMulti,
    showDropDown,
    options,
    selected,
    onSelect,
    onClose,
    onOpen,
    hasInput: !!input,
  });

  return (
    <Box
      ref={ref}
      sx={{
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      {isMulti && trigger}
      {(isOpened || (isMulti && selected && selected.length > 0)) && isMulti && (
        <Flex sx={{ flexDirection: 'column', gap: '8px', pt: '8px' }}>
          {isMulti && selected && selected.length > 0 && (
            <Flex sx={{ gap: '4px', flexWrap: 'wrap', width: '100%', alignItems: 'center' }}>
              <Flex
                sx={{
                  alignItems: 'center',
                  gap: '4px',
                  flexWrap: 'wrap',
                }}
              >
                {selected.map((item) => (
                  <Tag
                    sx={{
                      mr: '0',
                    }}
                    data-testid={`tag-${item.value}`}
                    key={item.label}
                    onRemove={() => {
                      onSelect(selected.filter((selectedItem) => selectedItem.value !== item.value));
                    }}
                  >
                    {item.label}
                  </Tag>
                ))}
                {selected.length > 0 && (
                  <>
                    {disableAddMore ? (
                      <>
                        {disableAddMoreMessage ? (
                          <Tooltip
                            content={disableAddMoreMessage}
                            trigger={<Box sx={{ cursor: 'pointer', color: 'neutral.300' }} as={Plus} size="16px" />}
                          />
                        ) : (
                          <Box sx={{ cursor: 'pointer', color: 'neutral.300' }} as={Plus} size="16px" />
                        )}
                      </>
                    ) : (
                      <Box
                        onClick={(e) => {
                          e.stopPropagation();

                          if (showSearch) {
                            onShowSearch();
                          } else {
                            onToggle();
                          }
                        }}
                        sx={{ cursor: 'pointer', color: 'neutral.300' }}
                        as={Plus}
                        size="16px"
                      />
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          )}
          {isOpened && input}
        </Flex>
      )}
      {isOpened && showDropDown && (options?.length > 0 || (isOpened && showDropDown && loading) || defaultText) && (
        <Card
          sx={{
            position: 'absolute',
            gap: '16px',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '240px',
            p: '8px 0',
            width: '100%',
            zIndex: 1,
            ...(sx || {}),
          }}
          data-testid="multi-select-dropdown"
        >
          {children}
          {loading && (
            <Box data-testid="loader" sx={{ p: '24px' }}>
              <Loader size="small" />
            </Box>
          )}
          {!loading && options.length === 0 && defaultText && (
            <Box
              as="p"
              color="text.subtle"
              fontSize="12px"
              fontStyle="italic"
              sx={{
                px: '16px',
                pt: '8px',
              }}
            >
              {defaultText}
            </Box>
          )}
          {options.length > 0 && !loading && (
            <Flex
              sx={{
                flexDirection: 'column',
                overflowY: 'scroll',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                  color: 'red',
                  width: '3px',
                },
                '&::-webkit-scrollbar-track': {},
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '10px',
                  '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
                },
                '&::-webkit-scrollbar-bar': {
                  width: '12px',
                },
                scrollbarWidth: '3px',
              }}
            >
              {options.map((option, index) => (
                <Box
                  data-testid={`option-${option.value}`}
                  id={`option-${index}`}
                  sx={{
                    cursor: option.disabled ? 'auto' : 'pointer',
                    label: {
                      '& > div:last-child': {
                        lineHeight: '1.4',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: '100%',
                        display: '-webkit-box',
                        '-webkit-box-orient': 'vertical',
                        '-webkit-line-clamp': '2',
                        m: 0,
                      },
                      width: '100%',
                      display: 'flex',
                      alignItems: 'flex-start',
                    },
                  }}
                  onClick={(e) => {
                    if (showSearch) {
                      ref.current.querySelector('input')?.focus();
                    }

                    e.preventDefault();
                    e.stopPropagation();

                    if (option.disabled) {
                      return;
                    }

                    toggle(option);
                  }}
                  key={option.value}
                >
                  <Box
                    sx={{
                      minHeight: '28px',
                      px: '16px',
                      pt: '8px',
                      bg: currentOption === index ? 'neutral.20' : 'transparent',
                      '&:hover': {
                        backgroundColor: 'neutral.20',
                      },
                    }}
                  >
                    <Text
                      as="p"
                      sx={{
                        fontSize: '14px',
                        color: option.disabled ? 'text.placeholder' : 'text.main',
                        '&:first-letter': {
                          textTransform: 'uppercase',
                        },
                      }}
                    >
                      {option.label}
                    </Text>
                    {option.description && (
                      <Text
                        as="p"
                        sx={{
                          fontSize: '14px',
                          color: option.disabled ? 'text.placeholder' : 'text.subtle',
                          '&:first-letter': {
                            textTransform: 'uppercase',
                          },
                        }}
                      >
                        {option.description}
                      </Text>
                    )}
                  </Box>
                </Box>
              ))}
              {bottomText && (
                <Box
                  as="p"
                  sx={{
                    px: '16px',
                    pt: '8px',
                  }}
                  color="text.subtle"
                  fontSize="12px"
                  fontStyle="italic"
                >
                  {bottomText}
                </Box>
              )}
            </Flex>
          )}
        </Card>
      )}
      {isOpened && !isMulti && (selected || placeholder) && (
        <Flex
          onClick={() => (showDropDown ? onClose() : onOpen())}
          sx={{ color: 'text.subtle', alignItems: 'center', cursor: 'pointer' }}
        >
          <Text
            as="p"
            sx={{
              fontSize: '14px',
              lineHeight: '16px',
            }}
          >
            {selected?.label ?? placeholder}
          </Text>
          <Box as={ChevronDown} size="16px" />
        </Flex>
      )}
    </Box>
  );
};

MultiSelectDropDown.propTypes = {
  isMulti: PropTypes.bool,
  options: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  children: PropTypes.node,
  sx: PropTypes.objectOf(PropTypes.any),
  trigger: PropTypes.node,
  showDropDown: PropTypes.bool,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  bottomText: PropTypes.node,
  defaultText: PropTypes.string,
  input: PropTypes.node,
  isOpened: PropTypes.bool,
  disableAddMore: PropTypes.bool,
  disableAddMoreMessage: PropTypes.string,
  showSearch: PropTypes.bool,
  onShowSearch: PropTypes.func,
  onToggle: PropTypes.func,
};

MultiSelectDropDown.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  children: null,
  sx: {},
  trigger: null,
  showDropDown: false,
  loading: false,
  isMulti: true,
  placeholder: '',
  bottomText: null,
  defaultText: '',
  input: null,
  disableAddMore: false,
  disableAddMoreMessage: '',
  isOpened: false,
  showSearch: true,
  onShowSearch: () => {},
  onToggle: () => {},
};

export default MultiSelectDropDown;
