import React, { useEffect, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { networksSelector } from 'redux/selectors';
import flatten from 'helpers/flatten';
import { setActiveNetwork } from 'actions/networksActions';
import NetworkNotFound from 'pages/NetworkNotFound';

import Route from 'router/Route';
import { networkRoutes as routes } from 'router/routes';

const NetworkWrapper = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { networks } = useSelector(networksSelector);
  const { params } = match || {};
  const { networkSlug } = params || {};

  const [validNetwork, setValidNetwork] = useState(null);

  useEffect(() => {
    const currentNetwork = Object.values(networks).find((obj) => obj.slug === networkSlug);

    if (currentNetwork) {
      dispatch(setActiveNetwork(currentNetwork.id));
      setValidNetwork(true);
    } else {
      setValidNetwork(false);
    }
  }, [dispatch, networkSlug, networks]);

  const renderRoutes = () =>
    flatten(
      Object.values(routes).map(
        (route) =>
          Object.values(networks).map((network) => {
            const path = `/networks/${network.slug}${route.path}`;
            return (
              <Route
                {...route}
                path={path}
                visibility="user"
                exact={!!route.exact}
                key={`${route.path}${network.slug}`}
              />
            );
          }),
        /* eslint-disable-next-line function-paren-newline */
      ),
    );

  if (validNetwork === null) return null;

  const renderedRoutes = renderRoutes();

  if (!validNetwork) {
    return <Route component={NetworkNotFound} visibility="all" />;
  }

  return <Switch>{renderedRoutes}</Switch>;
};

export default NetworkWrapper;
