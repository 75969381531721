import { useCallback, useEffect } from 'react';
import { useLazyGetSharedListQuery } from 'services/contacts';
import { reverseParsedFilters } from 'services/contacts/filters';

export const useSharedList = ({ listId, setFormValues, setMatch, setMatchType, setFilters }) => {
  const [getSharedList, { data: { sharedList: sharedListData } = {}, isLoading: isLoadingList }] =
    useLazyGetSharedListQuery();

  const getList = useCallback(async () => {
    if (!Number(listId)) return;
    const { data: { sharedList } = {}, error } = await getSharedList({ listId });

    if (error) return;

    if (sharedList.smartSharedList) {
      setFilters(reverseParsedFilters(sharedList.smartSharedList.filters));
    }

    if (sharedList.matchingProject && sharedList.matchingProject.id) {
      setMatch(sharedList.matchingProject);
    }

    if (sharedList.matchingProject) {
      setMatchType(sharedList.matchingProject.privateJob ? 'private' : 'job');
    }

    setFormValues({
      name: sharedList.name,
      description: sharedList.description,
    });
  }, [listId, getSharedList, setFormValues, setFilters, setMatch, setMatchType]);

  useEffect(() => {
    getList();
  }, [getList]);

  return { isLoadingList, sharedListData };
};
