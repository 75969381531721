/* eslint-disable no-case-declarations */
import { UPDATE_TALENT_GROUP, loadedTalentGroups } from 'actions/talentGroupActions';
import { loadedDeleteBoard } from '../../pages/Members/actions';
import {
  loadedSubscribeAll,
  loadedUnsubscribeAll,
  toggleBoardSubscriptionComplete,
} from '../../pages/manageSubscriptions/actions';

export const initialState = {};

export const key = 'talentGroups';

const talentGroupsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { id } = payload || {};
  const { activeNetwork } = payload || {};

  switch (type) {
    case loadedTalentGroups().type: {
      const { talentGroups } = payload;
      return {
        ...state,
        [id]: talentGroups,
      };
    }

    case UPDATE_TALENT_GROUP: {
      const { talentGroup } = payload;
      const result = [...state[id]];
      const index = result.findIndex((element) => element.value === talentGroup.value);

      if (index !== -1) {
        result[index] = talentGroup;
      }

      return {
        ...state,
        [id]: result,
      };
    }

    case toggleBoardSubscriptionComplete().type:
      const { board, subscribe } = payload;
      let tg = [...state[activeNetwork.id]];
      tg = tg.map((group) => {
        const g = { ...group };
        if (group.id === board.id) {
          // eslint-disable-next-line no-param-reassign
          g.subscribed = subscribe;
        }
        return g;
      });
      return { ...state, [activeNetwork.id]: tg };

    case loadedDeleteBoard().type: {
      const { collectionId, talentGroupId } = payload;

      const talentGroups = [...state[collectionId]];
      const index = talentGroups.findIndex((element) => element.value === talentGroupId);
      if (index > -1) {
        talentGroups.splice(index, 1);
      }
      return {
        ...state,
        [collectionId]: talentGroups,
      };
    }

    case loadedSubscribeAll().type:
      return {
        ...state,
        [activeNetwork.id]: [...state[activeNetwork.id]].map((group) => {
          const g = { ...group };
          g.subscribed = true;
          return g;
        }),
      };
    case loadedUnsubscribeAll().type:
      return {
        ...state,
        [activeNetwork.id]: [...state[activeNetwork.id]].map((group) => {
          const g = { ...group };
          g.subscribed = false;
          return g;
        }),
      };
    default:
      return state;
  }
};

export default talentGroupsReducer;
