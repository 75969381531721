import moment from 'moment/moment';

export const getAuthor = ({ author, source }) => {
  if (source !== 'posted') {
    return '-';
  }
  if (!author || author.email.indexOf('@getro.com') > -1) {
    return 'Unknown';
  }

  const { firstName, lastName } = author;
  if (firstName || lastName) {
    return `${firstName || ''} ${lastName || ''}`.trim();
  }

  return author.email.split('@')[0];
};

export const getExpirationDate = ({ expirationDate, status, source: jobSource }) => {
  if (jobSource !== 'posted') {
    return '-';
  }
  if (status === 'expired') {
    if (!expirationDate) {
      return 'Unknown';
    }
  } else if (status !== 'expired') {
    if (!expirationDate) {
      return 'Never';
    }
  }
  return moment(expirationDate).format('D MMM YYYY');
};
