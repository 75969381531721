import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import { Replay } from '@sentry/replay';

export function loadErrorTracker() {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  const history = createBrowserHistory();

  let tracesSampleRate = 0.033;
  let integrations;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    tracesSampleRate = 1.0;
    integrations = [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ];
  } else {
    integrations = [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Replay(),
    ];
  }

  Sentry.init({
    enabled: true,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations,
    tracesSampleRate,
    environment: process.env.REACT_APP_SENTRY_CURRENT_ENV,
    normalizeDepth: 10,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      /Blocked a frame with origin/,
    ],
  });
}

export const trackException = (error, context = {}) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  if (axios.isCancel(error)) {
    return;
  }

  Sentry.setContext('error', { error, ...context });
  Sentry.captureException(error);
};
