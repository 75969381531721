import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormSelect } from '@getro/rombo';
import { Box } from 'rebass/styled-components';

const TagsFilter = ({ value, onChange, embeddedInModal, tags, onDropDownStateChange }) => {
  const options = useMemo(() => {
    const duplicates = new Set();

    return tags
      .reduce((acc, topic) => {
        if (duplicates.has(topic.id)) return acc;

        acc.push({ label: topic.name, value: topic.id });
        duplicates.add(topic.id);
        return acc;
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [tags]);

  const selectedOption = useMemo(() => {
    const valueSet = new Set(value);

    return options.filter((i) => valueSet.has(i.value));
  }, [options, value]);

  return (
    <Box>
      <FormLabel>Tags</FormLabel>
      <FormSelect
        name="tags"
        placeholder="Add tags"
        value={selectedOption}
        options={options}
        anchorToBody={embeddedInModal}
        field={{
          onChange: ({ target }) => onChange(target.value.map((item) => item.value)),
          value: selectedOption,
        }}
        multiple
        onMenuClose={() => onDropDownStateChange('closed')}
        onMenuOpen={() => onDropDownStateChange('open')}
      />
    </Box>
  );
};

TagsFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  embeddedInModal: PropTypes.bool,
  tags: PropTypes.array.isRequired,
  onDropDownStateChange: PropTypes.func,
};

TagsFilter.defaultProps = {
  value: null,
  embeddedInModal: true,
  onDropDownStateChange: () => {},
};

export default memo(TagsFilter);
