import React, { useRef, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import MultiSelectDropDown from '../MultiSelectDropDown';

const OptionsFilter = ({
  placeholder,
  options,
  selected,
  onSelect,
  title,
  onInitialOpenDropDown,
  loading,
  bottomText,
  height,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const initialOpenedRef = useRef();

  const openDropDown = () => {
    if (!initialOpenedRef.current) {
      onInitialOpenDropDown();
    }

    setShowDropDown(true);
    initialOpenedRef.current = true;
  };

  return (
    <Flex
      sx={{
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '8px',
        width: '100%',
      }}
    >
      <Box key={placeholder} sx={{ position: 'relative', width: '100%' }}>
        <MultiSelectDropDown
          onClose={() => setShowDropDown(false)}
          onOpen={openDropDown}
          loading={loading}
          selected={selected}
          placeholder={placeholder}
          isMulti={false}
          options={options}
          onSelect={onSelect}
          showDropDown={showDropDown}
          sx={{ mt: '16px' }}
          bottomText={bottomText}
          isOpened
          trigger={
            <Box
              height={height}
              data-testid="options-filter-trigger"
              onClick={() => {
                if (!showDropDown) {
                  openDropDown();
                  return;
                }

                setShowDropDown(false);
              }}
              sx={{ cursor: 'pointer' }}
            >
              {title && (
                <Text
                  as="p"
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '1.4',
                  }}
                >
                  {title}
                </Text>
              )}
            </Box>
          }
        />
      </Box>
    </Flex>
  );
};

OptionsFilter.propTypes = {
  placeholder: PropTypes.string,
  selected: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onSelect: PropTypes.func,
  title: PropTypes.string,
  onInitialOpenDropDown: PropTypes.func,
  loading: PropTypes.bool,
  bottomText: PropTypes.string,
  height: PropTypes.string.isRequired,
};

OptionsFilter.defaultProps = {
  onSelect: () => {},
  selected: null,
  options: [],
  title: null,
  placeholder: '',
  onInitialOpenDropDown: () => {},
  loading: false,
  bottomText: null,
};

export default OptionsFilter;
