import { Select } from '@getro/rombo';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';

export const ImportContactColumnSelector = ({ options, columnValues, value, onChange, hideMerge }) => {
  const mergeOptions = [
    {
      label: 'Overwrite existing data',
      value: 'overwrite',
      description:
        'Add, update and delete existing data to match the CSV content. Best when the CSV is the most up-to-date data.',
    },
    {
      label: 'Skip for existing contacts',
      value: 'skip',
      description:
        'No updates for existing contacts, only use this field for new ones. Best for preserving current data in Getro.',
    },
  ];

  const customOnChange = ({ label, value: selectedValue, disabled }) => {
    if (!disabled) {
      onChange({
        label,
        value: selectedValue,
        ...(['lists', 'tags'].includes(selectedValue) ? { mergeMethod: null } : undefined),
      });
    }
  };

  const onChangeMerge = (values) => {
    onChange({ ...value, mergeMethod: values.value });
  };

  const customLabel = ({ label, description, value: customValue, disabled }) => {
    let textColor = 'text.main';

    if (disabled) {
      textColor = 'text.placeholder';
    } else if (customValue === value.value) {
      textColor = 'text.dark';
    }

    return (
      <Flex
        alignItems="center"
        px="16px"
        py="8px"
        sx={{
          cursor: disabled ? 'auto' : 'pointer',
          pointerEvents: disabled ? 'none' : 'auto',
          borderTopWidth: customValue === 'ignore' ? '1px' : 0,
          borderTopStyle: 'solid',
          borderTopColor: 'border.subtle',
          ':hover': { bg: 'neutral.50' },
        }}
      >
        <Box flexGrow="1">
          <Text fontSize="14px" color={textColor}>
            {label}
          </Text>
          {description && (
            <Text fontSize="14px" color="text.subtle">
              {description}
            </Text>
          )}
        </Box>
      </Flex>
    );
  };

  return (
    <Flex flexDirection="column" sx={{ gap: '4px' }}>
      <Select
        size="small"
        options={options.map((option) => ({
          ...option,
          disabled:
            option.value !== 'ignore' &&
            option.value !== value.value &&
            !!columnValues.find((item) => item.value === option.value),
        }))}
        customFormatOptionLabel={customLabel}
        value={value}
        onChange={customOnChange}
      />
      {!hideMerge && value.value !== 'ignore' && (
        <Select
          size="small"
          placeholder="Select merge method"
          options={mergeOptions}
          customFormatOptionLabel={customLabel}
          value={value.mergeMethod ? mergeOptions.find((item) => item.value === value.mergeMethod) : null}
          onChange={onChangeMerge}
        />
      )}
      {hideMerge && (
        <Text fontSize="12px" color="text.subtle">
          {`${value.label} is used to identify contacts.`}
        </Text>
      )}
    </Flex>
  );
};

ImportContactColumnSelector.propTypes = {
  options: PropTypes.array,
  columnValues: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  hideMerge: PropTypes.bool,
};

ImportContactColumnSelector.defaultProps = {
  options: [],
  columnValues: [],
  value: { label: 'Do not import', value: 'ignore' },
  hideMerge: false,
};
