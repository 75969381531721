import React, { useMemo, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import pluralize from 'pluralize';
import { TagPlus, TagRemove, Tag } from 'components/atoms/icon';
import { Box } from 'rebass/styled-components';
import { trackEvent } from '../../../helpers/analytics';
import { activeNetworkSelector } from '../../../redux/selectors';
import { useAddTagToContactsMutation, useRemoveTagFromContactsMutation } from '../../../services/contacts';
import { contactDataSelection } from '../../../services/contacts/selectedRows';
import { useCreateTagMutation, useLazyGetTagsQuery } from '../../../services/tags';
import BulkActionsModal from './bulkActionsModal';

export const ActionTags = ({ queryParams, contactIds, selectedRows, meta }) => {
  const [addTagToContacts, { isLoading: isAdding }] = useAddTagToContactsMutation();
  const [removeTagFromContacts, { isLoading: isRemoving }] = useRemoveTagFromContactsMutation();
  const activeNetwork = useSelector(activeNetworkSelector);
  const [createTag, { isLoading: isCreating }] = useCreateTagMutation();
  const ref = useRef();
  const onAddTagToContacts = async (tag) => {
    try {
      const { selectedDataRows, excludedDataRows, totalCount } = contactDataSelection(
        selectedRows,
        contactIds,
        meta.queryTotal,
      );

      await addTagToContacts({
        queryParams,
        tag,
        contactIds: selectedDataRows,
        excludedContactIds: excludedDataRows,
        listId: queryParams.listId,
      });

      trackEvent('contacts:tags:bulk_add_tags', {
        collection_id: activeNetwork.id,
        list_id: parseInt(queryParams.listId, 10),
      });

      toast(`${totalCount} ${pluralize('contact', totalCount)} added to tag`, {
        icon: 'success',
        id: 'bulk-actions',
      });
      ref.current.close();
    } catch {
      toast('Failed to add tag to contacts. Please try again.', { icon: 'danger', id: 'bulk-actions' });
    }
  };

  const onCreateTag = async (tagName) => {
    const { data } = await createTag({ tagName });

    await onAddTagToContacts(data.tag);
  };

  const onRemoveTagFromContacts = async (tag) => {
    try {
      const { selectedDataRows, excludedDataRows, totalCount } = contactDataSelection(
        selectedRows,
        contactIds,
        meta.queryTotal,
      );

      await removeTagFromContacts({
        queryParams,
        tag,
        contactIds: selectedDataRows,
        excludedContactIds: excludedDataRows,
        listId: queryParams.listId,
      });

      trackEvent('contacts:tags:bulk_remove_tags', {
        collection_id: activeNetwork.id,
      });

      toast(`${totalCount} ${pluralize('contact', totalCount)} removed from tag`, {
        icon: 'success',
        id: 'bulk-actions',
      });
      ref.current.close();
    } catch {
      toast('Failed to remove tag from contacts. Please try again.', { icon: 'danger', id: 'bulk-actions' });
    }
  };

  const [getTags, { data, isFetching }] = useLazyGetTagsQuery();
  const options = useMemo(
    () =>
      (data?.tags ?? []).map((item) => ({
        label: item.tag,
        value: item,
      })),
    [data],
  );

  return (
    <BulkActionsModal
      ref={ref}
      isCreating={isAdding || isCreating || isRemoving}
      onCreateIfNotExists={onCreateTag}
      title="Manage tags"
      icon={Tag}
      isLoading={isFetching}
      options={options}
      newItemText="tag"
      actions={[
        {
          icon: TagPlus,
          onClick: getTags,
          text: 'Add tag',
          title: `Add ${selectedRows.length} contacts from...`,
          onSelect: (tag) => onAddTagToContacts(tag.value),
          showOnCreateIfNotExists: true,
          emptyText: (
            <>
              <Box as="p">No tags created yet.</Box>
              <Box as="p">Start typing to create a new tag.</Box>
            </>
          ),
        },
        {
          icon: TagRemove,
          onClick: getTags,
          text: 'Remove tag',
          title: `Remove ${selectedRows.length} contacts from...`,
          onSelect: (tag) => onRemoveTagFromContacts(tag.value),
          showInputOnEmptyList: false,
          emptyText: <Box as="p">No tags created yet.</Box>,
        },
      ]}
    />
  );
};

ActionTags.propTypes = {
  queryParams: PropTypes.object,
  contactIds: PropTypes.array,
  meta: PropTypes.object,
  selectedRows: PropTypes.array,
};

ActionTags.defaultProps = {
  queryParams: {},
  contactIds: [],
  meta: {},
  selectedRows: [],
};
