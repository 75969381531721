import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetMatchListByIdQuery } from 'services/matches';
import * as Yup from 'yup';
import { getCompanyFundTypeFormOptions } from 'services/organizations/organizationFundTypes';
import { mapOrganizationHeadCount } from 'helpers/organizations';
import strings from 'strings';
import { useProjectSkillsOptions } from 'pages/listCreate/hooks/useProjectSkillsOptions';
import { useSearchGoogleLocations } from 'pages/listCreate/hooks/useProjectSearchGoogleLocations';
import { useOrganizationSize } from 'pages/listCreate/hooks/useOrganizationSize';

export const usePrivateList = ({ onMatchSelected, matchId }) => {
  const [isCompanyFromPortfolio, setIsCompanyFromPortfolio] = useState(true);
  const skills = useProjectSkillsOptions();
  const locations = useSearchGoogleLocations();

  const params = useParams();
  const isEdit = Boolean(params.listId && matchId);
  const {
    data: match,
    isFetching,
    isLoading,
  } = useGetMatchListByIdQuery(
    {
      matchListId: matchId,
    },
    {
      skip: !isEdit || !matchId,
    },
  );
  const { options } = useOrganizationSize();

  const employmentTypes = useMemo(
    () => Object.keys(strings.profile.employment).map((k) => ({ value: k, label: strings.profile.employment[k] })),
    [],
  );

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().notRequired(),
        remote: Yup.bool().required(),
        locations: Yup.array()
          .when('remote', {
            is: true,
            then: (schema) => schema.min(0),
            otherwise: (schema) => schema.min(1),
          })
          .min(1),
        skills: Yup.array().min(1).notRequired(),
        employmentType: Yup.object().required(),
      }),
    [],
  );

  const formatPayload = (values) => ({
    jobTitle: values.title,
    jobDescription: values.description,
    locationIds: values.locations.map((l) => l.value),
    remotePosition: values.remote,
    skillIds: values.skills.map((s) => s.value),
    employmentType: values.employmentType.value,
    ...(values?.company?.value && isCompanyFromPortfolio ? { organizationId: values.company.value } : undefined),
    ...(values?.organizationName ? { organizationName: values.organizationName } : undefined),
    ...(values?.organizationSize?.value ? { organizationSize: values.organizationSize.value } : undefined),
    ...(values?.organizationStage?.value ? { organizationStage: values.organizationStage.value } : undefined),
    ...(values?.organizationIndustryTagIds?.length
      ? { organizationIndustryTagIds: values.organizationIndustryTagIds.map((s) => s.value) }
      : undefined),
  });

  const initialValues = useMemo(() => {
    if (isEdit && match?.matchList && match?.matchList?.privateJob) {
      const { matchList } = match;
      const companySize = mapOrganizationHeadCount(matchList.organization.headCount);
      return {
        title: matchList.job.title,
        description: matchList.job.description ?? '',
        remote: !!matchList.job.locations.find(({ placeId }) => placeId === 'remote'),
        locations:
          matchList.job.locations
            .filter(({ placeId }) => placeId !== 'remote')
            .map(({ placeId, name }) => ({ value: placeId, label: name })) ?? [],
        skills: matchList.job.skills.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        employmentType: {
          value: matchList.job.employmentType,
          label: strings.profile.employment[matchList.job.employmentType],
        },
        company: matchList.organization
          ? {
              showAvatar: true,
              avatarUrl: matchList.organization.logoUrl,
              icon: matchList.organization.logoUrl,
              value: matchList.organization.id,
              label: matchList.organization.name,
              title: matchList.organization.name,
            }
          : null,
        organizationName: matchList.organization.name,
        organizationSize: options.find((s) => s.value === companySize),
        organizationStage: getCompanyFundTypeFormOptions().find((s) => s.value === matchList.organization.stage),
        organizationIndustryTagIds:
          matchList?.organization.industryTags?.map(({ id, description }) => ({ value: id, label: description })) ?? [],
      };
    }

    return {
      company: null,
      organizationName: '',
      skills: [],
      locations: [],
      description: '',
      remote: false,
      organizationIndustryTagIds: [],
      employmentType: {
        value: 'full_time',
        label: 'Full time',
      },
      title: '',
    };
  }, [isEdit, match, options]);

  const onSubmit = (value) => {
    onMatchSelected({
      matchType: 'private',
      payload: formatPayload(value),
      organization: isCompanyFromPortfolio
        ? {
            id: value.company.value,
            name: value.company.label,
            logoUrl: value.company.avatarUrl,
          }
        : {
            id: null,
            name: value.organizationName,
            logoUrl: null,
          },
      isCompanyFromPortfolio,
      job: {
        title: value.title,
        description: value.description,
        skills: value.skills,
        locations: value.locations,
        remote: value.remote,
        employmentType: value.employmentType,
        organizationIndustryTagIds: value?.organizationIndustryTagIds ?? [],
      },
    });
  };

  useEffect(() => {
    if (match && !match?.matchList?.organization?.id) {
      setIsCompanyFromPortfolio(false);
    }
  }, [match]);

  return {
    skills,
    employmentTypes,
    locations,
    formSchema: isCompanyFromPortfolio
      ? formSchema.concat(
          Yup.object().shape({
            company: Yup.object().required(),
          }),
        )
      : formSchema,
    isCompanyFromPortfolio,
    setIsCompanyFromPortfolio,
    match: match?.matchList,
    initialValues,
    loadingMatch: isLoading || isFetching,
    matchIsOutsidePortfolioCompany: !match?.matchList?.organization?.id,
    onSubmit,
    isEdit,
  };
};
