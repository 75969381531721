import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { Button, Panel } from '@getro/rombo';

import { logOut, removeUser } from 'actions/userActions';
import { trackException } from 'helpers/errorTracker';
import { trackEvent } from 'helpers/analytics';
import { del } from 'api';

import Modal from 'components/atoms/Modal';
import { Text, Box } from 'rebass/styled-components';
import { activeNetworkSelector, userSelector } from '../../../redux/selectors';
import { GenericError } from '../../../components/molecules/genericError';

const DeleteAccount = () => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const activeNetwork = useSelector(activeNetworkSelector);
  const { id: networkId, name: networkName } = activeNetwork;
  const currentUser = useSelector(userSelector);

  const cancelTokenSource = useRef(axios.CancelToken.source());

  const { userTalentCollections: memberships } = currentUser;
  const membershipId = memberships.find((utc) => utc.collectionId === networkId).id;

  const deleteAccountButton = (
    <Button mt={3} pl={0} variant="link" color="danger">
      Leave the network
    </Button>
  );

  const deleteAccount = async () => {
    setSubmitting(true);
    setError(false);
    try {
      await del(`/user_talent_collections/${membershipId}`, {}, { cancelToken: cancelTokenSource.current.token });
      trackEvent('delete_account');
      dispatch(removeUser());
      logOut();
    } catch (e) {
      trackException(e);
      setSubmitting(false);
      setError(true);
    }
  };

  useEffect(
    () => () => {
      cancelTokenSource.current.cancel();
    },
    [],
  );

  return (
    <Panel title="Leave network">
      <Text>
        Your profile and personal information will be removed from the {networkName} network. You will no longer receive
        notifications from us.
      </Text>

      <Modal
        title="Are you sure you want to leave the network?"
        saveText="Yes, I want to leave the network"
        cancelText="No, I want to stay stay part of the network"
        loading={submitting}
        onSubmit={deleteAccount}
        trigger={deleteAccountButton}
      >
        <Text mb={3}>We&apos;re sorry you&apos;re thinking of leaving us.</Text>
        <Text mb={3}>
          By leaving this network, your profile and personal information will be removed and you will no longer receive
          notifications from us.
        </Text>
        <Text>
          If you want to rejoin in the future, you can sign up as a new user. We&apos;d love to have you back!
        </Text>
      </Modal>

      {error && <Box as={GenericError} mt={3} />}
    </Panel>
  );
};

export default DeleteAccount;
