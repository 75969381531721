import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setPageTitle } from 'actions/pageActions';
import { trackPage } from 'helpers/analytics';

const pageHoc =
  ({ title }) =>
  (Component) => {
    const Wrapped = (props) => {
      const dispatch = useDispatch();

      useEffect(() => {
        if (title) {
          dispatch(setPageTitle(title));
        }

        // TODO: add more attributes like networkId
        trackPage();
        return () => {
          dispatch(setPageTitle(''));
        };
        // eslint-disable-next-line
    }, [])

      return <Component {...props} />;
    };

    Wrapped.displayName = `Page(${Component.displayName || Component.name || 'Component'})`;

    return Wrapped;
  };

export default pageHoc;
